import markIcon from 'assets/image/ic-location.svg'
import { Input } from 'module/installAndSearch/components/Input'
// import { TextArea } from 'module/installAndSearch/components/_styled/input'
import { TextArea } from 'module/installAndSearch/components/TextArea'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Input as InputReactStrap } from 'reactstrap'
import Button from 'shared/components/Button'
import { NewModal } from '../../_newModal'
import '../../_styled/display.scss'
import './styles.scss'

type Props = {
	onReturn: () => void
	onSubmit: (data: any) => void
	onChangeAddress: () => void
	productName: string
	numSerie: string
	address: any
	title?: string
}

interface FormValues {
	obs: string
	name: string
	address: string
	number: string
	zip_code: string
	city: string
	state_sigla: string
	complement: string
	neighborhood: string
	state: string
	street: string
	district: string
	phone: string
	email: string
	nameContact: string
	cnpj: string
	cpf: string
	addressDefault: boolean
}

export const ModalOpenOs = ({
	onReturn,
	onSubmit,
	onChangeAddress,
	productName,
	numSerie,
	address,
	title
}: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue
	} = useForm<FormValues>()
	const [nCharacters, setnCharacters] = useState(800)
	const [text, setText] = useState('')

	const countObs = (e: any) => {
		if (text.length < 800) {
			setText(e.target.value.substring(0, 800))
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setnCharacters(800 - (text.length + 1))
			} else {
				setnCharacters(800 - (text.length - 1))
			}
		} else {
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setnCharacters(0)
			} else {
				setText(e.target.value.substring(0, 800))
				setnCharacters(800 - (text.length + 1))
			}
		}
	}

	return (
		<NewModal onClose={onReturn} awayClose={true} title={title}>
			<div>
				<div className="title-modal-install">
					<div className="equipName">{productName}</div>
					<div className="serialNum">Número de série: {numSerie}</div>
				</div>
			</div>
			<div>
				<label className="form-label-title">Endereço do equipamento:</label>
				<div className="container-f">
					<img src={markIcon} alt="" className="item-f" />
					<span className="item-f text-align-l">
						{address.street.toUpperCase()},{' '}
						{address.neighborhood
							? `${address.neighborhood.toUpperCase()},`
							: ''}
						<br />
						{address.city.toUpperCase()} - {address.state_sigla.toUpperCase()}
					</span>
				</div>
				<div className="modal-open-os-body">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="name">
									Contato
								</label>
								<Input
									placeholder="Digite o nome *"
									id="name"
									innerRef={{ ...register('name', { required: true }) }}
								/>
								<small className="text-danger">
									{errors.name && <span>O campo é obrigatório</span>}
								</small>
							</div>
						</div>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="phone">
									Telefone
								</label>
								<InputReactStrap
									className="InputMask"
									type="tel"
									mask="(99) 999999999"
									maskChar=""
									placeholder={`Digite o telefone *`}
									name="phone"
									id="phone"
									inputRef={register('phone', {
										required: true,
										minLength: 13,
										maxLength: 16
									})}
									onChange={(e) => setValue('phone', e.target.value)}
									tag={InputMask}
								/>
								<small className="text-danger">
									{errors.phone && errors.phone.type === 'required' && (
										<span>O campo é obrigatório</span>
									)}
									{errors.phone && errors.phone.type === 'minLength' && (
										<span>O campo deve conter no mínimo 10 caracteres</span>
									)}
								</small>
							</div>

							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="email">
									E-mail
								</label>
								<Input
									type="email"
									placeholder={`Digite o email *`}
									id="email"
									innerRef={{
										...register('email', {
											required: true,
											maxLength: 50
										})
									}}
								/>
								<small className="text-danger">
									{errors.email && <span>O campo é obrigatório</span>}
								</small>
							</div>
						</div>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="obs">
									Observação
								</label>
								<TextArea
									placeholder="Observações"
									id="obs"
									innerRef={{ ...register('obs') }}
									onChange={(e) => {
										countObs(e)
									}}
									value={text}
								/>
							</div>
						</div>

						<small className="obs-Characters">
							{nCharacters} caracteres restantes
						</small>
						<div className="row-install-and-search">
							<div className="addressDefault">
								<input
									type="checkbox"
									className="other"
									{...register('addressDefault')}
								/>
								<span>Desejo salvar este endereço como Endereço Padrão</span>
							</div>
						</div>
						<div className="container-address-confirm-actions">
							<Button
								size="lg"
								type="submit"
								variant="confirmation-solid"
								title="Enviar"
							/>
							<Button
								size="lg"
								style={{ fontSize: '14px' }}
								onClick={onReturn}
								variant="cancellation-gradient"
								title="Cancelar"
							/>
						</div>
					</form>
				</div>
			</div>
		</NewModal>
	)
}
