import { ClickAwayListener } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { MdArrowDropUp } from 'react-icons/md'
import CheckBox from '../CheckBox'
import './styles.scss'

export interface SelectProps extends React.ComponentPropsWithoutRef<'div'> {
	checkbox?: boolean
	autoComplete?: 'on' | 'off' | string
	options: ListType[]
	selectedOption: string[]
	onSelectOptions: (selectOptions: string[]) => void
	innerRef?: any
	variant?: 'light' | 'dark'
	tooltip?:
		| 'right'
		| 'left'
		| 'top'
		| 'bottom'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start'
	errors?: any
	placeholder?: string
	disable?: boolean
}

export interface ListType {
	value: string
	label: string
}

const Select = ({
	options,
	autoComplete = 'off',
	checkbox = false,
	selectedOption,
	onSelectOptions,
	innerRef,
	variant = 'light',
	tooltip,
	errors,
	placeholder = 'Selecionar',
	disable = false,
	...rest
}: SelectProps) => {
	const [dropdownOption, setDropdownOption] = useState<boolean>(false)
	const [listOptions, setListOptions] = useState<ListType[]>(options)
	const [selectOption, setSelectOption] = useState<ListType>()

	useEffect(() => {
		setListOptions(options)
	}, [options])

	useEffect(() => {
		if (selectedOption && selectedOption?.length) {
			setSelectOption(
				options.find((option) => option?.value === selectedOption[0])
			)
		} else setSelectOption(undefined)
	}, [selectedOption])

	const handleChange = () => {
		if (dropdownOption && !disable) {
			setDropdownOption(!dropdownOption)
		}
	}

	const handlerUserSelectorToggle = (e: any) => {
		e.stopPropagation()
		!disable && setDropdownOption(!dropdownOption)
	}

	function handleSelect(element: string) {
		const currentIndex = selectedOption.indexOf(element)
		const newChecked = [...selectedOption]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		onSelectOptions(newChecked)
	}

	const optionListContain = () => {
		return (
			<div
				className={`option-list-select ${
					dropdownOption ? 'dropdown-show' : 'dropdown-hide'
				}`}
				{...rest}
			>
				{listOptions.map((item, index) => {
					return (
						<div key={index} title={tooltip ? item.label : ''}>
							{checkbox ? (
								<label
									htmlFor={`${rest.id}-${item.value}`}
									className="content-item-option"
									key={`${item.value}${index}`}
								>
									<CheckBox
										id={`${rest.id}-${item.value}`}
										onChange={() => handleSelect(item.value)}
										type="checkbox"
										value={item.value}
										variant="blue"
										format="square"
										checked={selectedOption.includes(item.value)}
									/>
									<p className="option">{item.label}</p>
								</label>
							) : (
								<label
									htmlFor={`${rest.id}-${item.value}`}
									className="content-item-option"
									key={`${item.value}${index}`}
								>
									<p
										id={`${rest.id}-${item.value}`}
										onClick={() => {
											onSelectOptions([item.value])
											!disable && setDropdownOption(!dropdownOption)
											;(
												document.querySelector(
													`#${rest.id}`
												) as HTMLInputElement
											).value = item.label
										}}
										className="option"
									>
										{item.label}
									</p>
								</label>
							)}
						</div>
					)
				})}
			</div>
		)
	}

	return (
		<div className="select-component">
			<ClickAwayListener onClickAway={handleChange} mouseEvent="onMouseDown">
				<div
					className={`select-${variant}-container ${errors && 'select-error'}`}
				>
					<div className="action-container">
						<input
							type="text"
							// {...innerRef}
							id={`${rest.id}`}
							placeholder={placeholder}
							autoComplete={autoComplete}
							onFocus={() => !disable && setDropdownOption(true)}
							onChange={(text) =>
								setListOptions(
									options.filter(
										(option) =>
											option.label
												?.toLowerCase()
												.includes(text.target.value?.toLowerCase()) ||
											option.value
												?.toLowerCase()
												.includes(text.target.value?.toLowerCase())
									)
								)
							}
							className={`select-${variant} ${
								checkbox ? 'select-checkbox' : 'select-input'
							}`}
							defaultValue={selectOption?.label}
						/>
						<button
							className={'button-selector-toggle'}
							type="button"
							onClick={(e) => handlerUserSelectorToggle(e)}
						>
							<MdArrowDropUp
								style={dropdownOption ? {} : { transform: 'rotate(180deg)' }}
							/>
						</button>
					</div>
					{optionListContain()}
				</div>
			</ClickAwayListener>
			<small>
				{errors?.message && (
					<span className="select-error-span">{errors.message.toString()}</span>
				)}
			</small>
		</div>
	)
}
export default Select
