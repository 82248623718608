import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { FilterData } from 'models/FilterData'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import { FilterSave } from 'module/orderManagement/models/FilterSave'
import { OrderPendencyStatusTypes } from 'module/orderManagement/utils/constants'
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'shared/components/Button'
import { setOrdersFiltersClear } from 'store/modules/orderManagement/actions'
import FilterFields from './FilterFields'
import MyFilters from './Myfilters'
import PendencyField from './PendencyField'
import PeriodFields from './PeriodFields'
import './styles.scss'

interface Params {
	onClose: () => void
}

const OrdersFilter: React.FC<Params> = ({ onClose }) => {
	const { itemFilter, onFilterChange, filtersParams } =
		useContext(OrderContextElement)
	const [initialDate, setInitialDate] = useState<MaterialUiPickersDate>(
		itemFilter?.initialDate as MaterialUiPickersDate | null
	)
	const [finalDate, setFinalDate] = useState<MaterialUiPickersDate>(
		itemFilter?.finalDate as MaterialUiPickersDate | null
	)
	const [chosenDate, setChosenDate] = useState<MaterialUiPickersDate>(
		itemFilter?.chosenDate as MaterialUiPickersDate | null
	)
	const [pendencyStatus, setPendencyStatus] = useState(
		filtersParams?.pendencyStatus?.length
			? filtersParams?.pendencyStatus
			: itemFilter?.pendencyStatus
	)
	const [salesPersonCode, setSalesPersonCode] = useState(
		filtersParams?.salesPersonCode?.length
			? filtersParams?.salesPersonCode
			: itemFilter?.salesPersonCode
	)
	const [federatedState, setFederatedState] = useState(
		filtersParams?.federatedState?.length
			? filtersParams?.federatedState
			: itemFilter?.federatedState
	)
	const [orderStatus, setOrderStatus] = useState(
		filtersParams?.orderStatus?.length
			? filtersParams?.orderStatus
			: itemFilter?.orderStatus
	)
	const [orderCategory, setOrderCategory] = useState(
		filtersParams?.orderCategory?.length
			? filtersParams?.orderCategory
			: itemFilter?.orderCategory
	)
	const [orderBranch, setOrderBranch] = useState(
		filtersParams?.orderBranch?.length
			? filtersParams?.orderBranch
			: itemFilter?.orderBranch
	)
	const [pendencyCode, setPendencyCode] = useState(
		filtersParams?.pendencyCode?.length
			? filtersParams?.pendencyCode
			: itemFilter?.pendencyCode
	)
	const [pendencyType, setPendencyType] = useState(
		filtersParams?.pendencyType?.length
			? filtersParams?.pendencyType
			: itemFilter?.pendencyType
	)
	const [pendencyIssueResolverTeam, setPendencyIssueResolverTeam] = useState(
		filtersParams?.pendencyIssueResolverTeam?.length
			? filtersParams?.pendencyIssueResolverTeam
			: itemFilter?.pendencyIssueResolverTeam
	)
	const [pendencyIssueResolver, setPendencyIssueResolver] = useState(
		filtersParams?.pendencyIssueResolver?.length
			? filtersParams?.pendencyIssueResolver
			: itemFilter?.pendencyIssueResolver
	)
	const [pendencyDate, setPendencyDate] = useState(
		filtersParams?.pendencyDate?.length
			? filtersParams?.pendencyDate
			: itemFilter?.pendencyDate
	)

	const [myFiltersShowModal, setMyFiltersShowModal] = useState<boolean>(false)
	const [saveOnNewFilter, setSaveOnNewFilter] = useState<any[]>([])
	const [saveFilterEditing, setSaveFilterEditing] = useState<FilterSave>()
	const [buttonShow, setButtonShow] = useState<boolean>(false)
	const [hasError, setHasError] = useState<boolean>(false)
	const dispatch = useDispatch()

	const onDateSelect = (value: MaterialUiPickersDate, type: string) => {
		type === 'initialDate' ? setInitialDate(value) : setFinalDate(value)
	}

	const onPendencyStatusSelected = (status: string) => {
		setPendencyStatus(status)
	}

	const onSelectSalesPerson = (status: string[]) => {
		setSalesPersonCode(status)
	}

	const onSelectFederalState = (status: string[]) => {
		setFederatedState(status)
	}

	const onSelectOrderStatus = (status: string[]) => {
		setOrderStatus(status)
	}

	const onSelectOrderCategory = (status: string[]) => {
		setOrderCategory(status)
	}

	const onSelectOrderBranch = (status: string[]) => {
		setOrderBranch(status)
	}

	const onSelectPendencyCode = (status: string[]) => {
		setPendencyCode(status)
	}

	const onSelectPendencyType = (status: string[]) => {
		setPendencyType(status)
	}

	const onSelectPendencyIssueResolverTeam = (status: string[]) => {
		setPendencyIssueResolverTeam(status)
	}

	const onSelectPendencyIssueResolver = (status: string[]) => {
		setPendencyIssueResolver(status)
	}

	const onSelectPendencyDate = (status: string[]) => {
		setPendencyDate(status)
		setPendencyStatus(OrderPendencyStatusTypes.PENDENCY)
	}

	const onChosenDatePendency = (value: MaterialUiPickersDate) => {
		setPendencyDate([])
		setChosenDate(value)
		setPendencyStatus(OrderPendencyStatusTypes.PENDENCY)
	}

	const applyFilterChanges = () => {
		const newFilter = {
			pendencyStatus,
			initialDate,
			finalDate,
			orderCategory,
			orderStatus,
			orderBranch,
			federatedState,
			salesPersonCode,
			pendencyCode,
			pendencyType,
			pendencyIssueResolverTeam,
			pendencyIssueResolver,
			pendencyDate,
			chosenDate
		} as FilterData
		onFilterChange(newFilter)
		onClose()
	}

	const clearFilters = () => {
		dispatch(setOrdersFiltersClear())
		setPendencyStatus('')
		setInitialDate(null)
		setFinalDate(null)
		setChosenDate(null)
		setOrderCategory([])
		setOrderStatus([])
		setFederatedState([])
		setSalesPersonCode([])
		setPendencyCode([])
		setPendencyType([])
		setPendencyIssueResolverTeam([])
		setPendencyIssueResolver([])
		setPendencyDate([])
		setSaveOnNewFilter([])
		setHasError(false)
		const clearFilter = {
			pendencyStatus: undefined,
			orderCategory: [],
			orderStatus: [],
			orderBranch: ['01'],
			federatedState: [],
			salesPersonCode: [],
			pendencyCode: [],
			pendencyType: [],
			pendencyIssueResolverTeam: [],
			pendencyIssueResolver: undefined,
			pendencyDate: undefined
		} as unknown as FilterData
		onFilterChange(clearFilter)
		onClose()
	}

	const onError = (value: any) => {
		if (!value || Date.parse(value)) {
			setHasError(false)
			return
		}
		setHasError(true)
	}

	const filterEditing = (item: any) => {
		setPendencyStatus(item.pendencyStatus)
		setOrderCategory(item.orderCategory)
		setOrderStatus(item.orderStatus)
		setFederatedState(item.federatedState)
		setSalesPersonCode(item.salesPersonCode)
		setPendencyCode(item.pendencyCode)
		setPendencyType(item.pendencyType)
		setPendencyIssueResolverTeam(item.pendencyIssueResolverTeam)
		setPendencyIssueResolver(item.pendencyIssueResolver)
		setPendencyDate(item.pendencyDate)
		setSaveFilterEditing(item)
	}

	const mainContent = () => {
		return (
			<div className="orders-filter">
				<div className="orders-filter-content">
					<div
						className="my-filter"
						onClick={() => {
							setMyFiltersShowModal(true)
							setSaveOnNewFilter([])
							setButtonShow(false)
							setSaveFilterEditing(undefined)
						}}
					>
						<span>Meus filtros</span>
					</div>
					<div className="clear-filter" onClick={clearFilters}>
						<span>Limpar filtros</span>
					</div>
					<PeriodFields
						{...{ initialDate, finalDate, onDateSelect, onError }}
					/>
					<FilterFields
						{...{
							onSelectSalesPerson,
							salesPersonCode,
							onSelectFederalState,
							federatedState,
							onSelectOrderStatus,
							orderStatus,
							onSelectOrderCategory,
							orderCategory,
							onSelectOrderBranch,
							orderBranch,
							onSelectPendencyCode,
							pendencyCode,
							onSelectPendencyType,
							pendencyType,
							onSelectPendencyIssueResolverTeam,
							pendencyIssueResolverTeam,
							onSelectPendencyIssueResolver,
							pendencyIssueResolver,
							onSelectPendencyDate,
							pendencyDate,
							chosenDate,
							onChosenDatePendency,
							onError
						}}
					/>
					<PendencyField {...{ onPendencyStatusSelected, pendencyStatus }} />

					<MyFilters
						myFiltersShowModal={myFiltersShowModal}
						myFiltersCloseModal={(close) => {
							setMyFiltersShowModal(close)
							setSaveOnNewFilter([])
						}}
						saveOnNewFilter={saveOnNewFilter}
						newFilter={setButtonShow}
						onClose={() => {
							onClose()
							setMyFiltersShowModal(false)
						}}
						onEditingFilter={(e) => {
							filterEditing(e)
						}}
					/>
				</div>
				<div className="orders-filter-actions">
					{!!buttonShow && (
						<Button
							variant="standard"
							title="SALVAR FILTRO"
							size="lg"
							onClick={() => {
								setMyFiltersShowModal(true)
								setSaveOnNewFilter([
									{
										id: null,
										salesPersonCode,
										federatedState,
										orderStatus,
										orderCategory,
										orderBranch,
										pendencyStatus,
										pendencyCode,
										pendencyType,
										pendencyIssueResolverTeam,
										pendencyIssueResolver,
										pendencyDate
									}
								])
							}}
						/>
					)}
					{saveFilterEditing?.id && (
						<Button
							variant="standard"
							title="SALVAR"
							size="lg"
							onClick={() => {
								setSaveOnNewFilter([
									{
										id: saveFilterEditing?.id,
										name: saveFilterEditing?.name,
										salesPersonCode,
										federatedState,
										orderStatus,
										orderCategory,
										orderBranch,
										pendencyStatus,
										pendencyCode,
										pendencyType,
										pendencyIssueResolverTeam,
										pendencyIssueResolver,
										pendencyDate
									}
								])
								setSaveFilterEditing(undefined)
								setMyFiltersShowModal(true)
							}}
						/>
					)}
					<Button
						variant="confirmation-gradient"
						title="FILTRAR"
						size="lg"
						disabled={hasError}
						onClick={applyFilterChanges}
					/>
					<Button
						variant="cancellation-gradient"
						title="CANCELAR"
						size="lg"
						onClick={onClose}
					/>
				</div>
			</div>
		)
	}

	return mainContent()
}

export default OrdersFilter
