import {
	AppThemes,
	DeviceType,
	OrientationType,
	ViewType
} from 'utils/Constants'

// Mobile max screen sizes based on iPhone 13 Pro Max and increased
const MOBILE_WIDTH_MAX = 680
const MOBILE_HEIGHT_MAX = 980

// Tablet Portrait min screen based on mobile max screen sizes
const TABLET_PORTRAIT_WIDTH_MIN = 480
const TABLET_PORTRAIT_HEIGHT_MIN = 980

// Tablet Portrait max screen based on iPad 11" and increased
const TABLET_PORTRAIT_WIDTH_MAX = 1024
const TABLET_PORTRAIT_HEIGHT_MAX = 1366

// Tablet Landscape min screen based on mobile landscape max screen sizes
const TABLET_LANDSCAPE_WIDTH_MIN = 980
const TABLET_LANDSCAPE_HEIGHT_MIN = 480

// Tablet Landscape max screen based on iPad 11" and increased
const TABLET_LANDSCAPE_WIDTH_MAX = 1366
const TABLET_LANDSCAPE_HEIGHT_MAX = 1024

// console.log(width, height);
// 1024 – 1366

const isMobile = (width: number, height: number) =>
	width < MOBILE_WIDTH_MAX && height < MOBILE_HEIGHT_MAX

const isTablet = (width: number, height: number) =>
	width > TABLET_PORTRAIT_WIDTH_MIN &&
	width <= TABLET_PORTRAIT_WIDTH_MAX &&
	height > TABLET_PORTRAIT_HEIGHT_MIN &&
	height <= TABLET_PORTRAIT_HEIGHT_MAX

const isTabletL = (width: number, height: number) =>
	width >= TABLET_LANDSCAPE_WIDTH_MIN &&
	width < TABLET_LANDSCAPE_WIDTH_MAX &&
	height >= TABLET_LANDSCAPE_HEIGHT_MIN &&
	height < TABLET_LANDSCAPE_HEIGHT_MAX
const isTabletP = isTablet

export function getViewType() {
	const width = innerWidth
	const height = innerHeight

	applyBodyDeviceClass()

	if (isMobile(width, height) || isTabletP(width, height)) {
		return ViewType.MOBILE
	} else {
		return ViewType.WEB
	}
}

export function getDeviceOrientation() {
	const width = innerWidth
	const height = innerHeight

	if (width >= height) {
		return OrientationType.LANDSCAPE
	} else {
		return OrientationType.PORTRAIT
	}
}

export function getDeviceType() {
	let width = innerWidth
	let height = innerHeight

	if (isMobile(width, height)) {
		return DeviceType.MOBILE
	} else if (isTablet(width, height)) {
		width = outerWidth
		height = outerHeight

		if (isTabletL(width, height)) {
			return DeviceType.WEB
		}

		return DeviceType.MOBILE
	} else {
		return DeviceType.WEB
	}
}

function applyBodyDeviceClass() {
	const deviceType = getDeviceType()
	const body = $('body')
	body.removeClass(DeviceType.MOBILE)
	body.removeClass(DeviceType.WEB)
	body.addClass(deviceType)
	body.addClass(deviceType)
}

const SCROLL_TOP = 0
const SCROLL_MIN_LIMIT = 25

export function onChildrenScroll(event: any, partentClass: string) {
	const currentY = event.target.scrollTop
	const parent = $(`.${partentClass}`)
	if (currentY <= SCROLL_MIN_LIMIT) {
		parent.removeClass('shadow-on-scroll')
		if (currentY < 0) {
			$(`.${event.target.className}`).scrollTop(SCROLL_TOP)
		}
	} else {
		parent.addClass('shadow-on-scroll')
	}
}

export function applyUserTheme(selectedTheme: AppThemes) {
	const bodyComponent = $('body')
	if (selectedTheme === AppThemes.LIGHT) {
		bodyComponent.removeClass(AppThemes.DARK)
		bodyComponent.addClass(AppThemes.LIGHT)
	}
	if (selectedTheme === AppThemes.DARK) {
		bodyComponent.removeClass(AppThemes.LIGHT)
		bodyComponent.addClass(AppThemes.DARK)
	}
	return selectedTheme
}

export function updateBodyForOutsidePages() {
	const bodyComponent = $('body')
	const outsideClass = 'outside-page'
	bodyComponent.removeClass(outsideClass)
	bodyComponent.addClass(outsideClass)
}
