import React, { useState } from 'react'
import Modal from 'shared/components/Modal'
import AlertIcon from 'shared/components/SvgIcons/AlertIcon'
import './styles.scss'

type Props = {
	message: string
}

export default function ExecautoErrorSpan({ message }: Props) {
	const [showErrorModal, serShowErrorModal] = useState<boolean>(false)

	function handleShowModal() {
		serShowErrorModal(!showErrorModal)
	}

	return (
		<>
			<div className="execauto-error-info">
				<div className="execauto-error-icon">
					<AlertIcon height="16" width="16" />
				</div>
				<div>
					<span>
						Erro de integração ao Protheus. Verifique os detalhes do pedido e
						dados do cliente.
					</span>
					<br />
					<span className="execauto-error-link" onClick={handleShowModal}>
						Clique aqui
					</span>
					<span>{` para visualizar o relatório de erros.`}</span>
				</div>
			</div>
			<Modal
				showClose
				enableAwayClose={true}
				show={showErrorModal}
				onClose={handleShowModal}
				title="Relatório de erros"
			>
				<div className="execauto-error-modal-content">
					<textarea defaultValue={message} readOnly rows={16} />
				</div>
			</Modal>
		</>
	)
}
