import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import { toast } from 'react-toastify'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Modal from 'shared/components/Modal'
import { clearNumericField, normalizePhoneNumber } from 'utils/Pipe'
import './styles.scss'

type TParams = {
	show: boolean
	whatsappParams: {
		message: string
		phone?: string
	}
	onClose: (show: boolean) => void
}

interface IFormInput {
	phone: string
}

const WhatsAppSendConfirm = ({
	whatsappParams: { message, phone },
	show,
	onClose
}: TParams) => {
	const {
		register,
		handleSubmit,
		watch,
		setValue,
		formState: { errors }
	} = useForm<IFormInput>({
		defaultValues: { phone: normalizePhoneNumber(phone) }
	})
	const inputValue = watch('phone')

	useEffect(() => {
		setValue('phone', normalizePhoneNumber(inputValue))
	}, [inputValue])

	const onSubmit = (data: FieldValues) => {
		const contactNumber = clearNumericField(data.phone)
		if (contactNumber?.length != 11) {
			toast.error('Numero de telefone inválido!')
		} else {
			window.open(
				`https://api.whatsapp.com/send?phone=55${clearNumericField(
					data.phone
				)}&text=${message}`,
				'_blank'
			)
			onClose(false)
		}
	}

	return (
		<Modal
			title="CONFIRME OS DADOS DO DESTINATÁRIO"
			show={show}
			onClose={() => onClose(false)}
			enableAwayClose={true}
			showClose={true}
		>
			<div className="whatsapp-contain">
				<div className="whatsapp-contain-form">
					<label htmlFor="phone" className="whatsapp-contain-text">
						Whatsapp
					</label>
					<Input
						name="phone"
						type="text"
						variant="light"
						autoComplete="off"
						errors={errors.phone}
						placeholder="(00) 00000-0000"
						innerRef={{
							...register('phone', {
								required: {
									value: true,
									message: 'O campo Whatsapp é obrigatório!'
								},
								minLength: {
									value: 15,
									message: 'O campo Whatsapp está incompleto!'
								}
							})
						}}
					/>
				</div>
				<div className="whatsapp-contain-actions">
					<Button
						size="lg"
						title="CONFIRMAR"
						variant="confirmation-gradient"
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						size="lg"
						title="CANCELAR"
						variant="cancellation-gradient"
						onClick={() => onClose(false)}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default WhatsAppSendConfirm
