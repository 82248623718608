import { clarity } from 'react-microsoft-clarity'

export function useClarity(): void {
	if (
		process.env.NODE_ENV === 'production' &&
		process.env.REACT_APP_CLARITY_KEY
	) {
		clarity.init(process.env.REACT_APP_CLARITY_KEY)
	}
}
