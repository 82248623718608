import React, { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import Input from 'shared/components/Input'
import Modal from 'shared/components/Modal'

import { zodResolver } from '@hookform/resolvers/zod'
import { UsersJacContext } from 'module/adminSystem/context/UsersJacContext'
import { createClientJacSchema } from 'module/adminSystem/schemas/client-jac-schema'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import Select from 'shared/components/Select'
import { optionsStores } from '.'
import './styles.scss'

type UserJacModalProps = {
	title: string
	onClose: () => void
}

export const ModalUserJacCreate = ({ title, onClose }: UserJacModalProps) => {
	const {
		register,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors },
		getFieldState
	} = useForm<createClientJacSchema>({
		defaultValues: { store: [] },
		resolver: zodResolver(createClientJacSchema)
	})

	const { handleCreateUserJAC, isLoading } = useContext(UsersJacContext)

	const [selectedStore, setSelectedStore] = useState<string[]>([])

	const onSubmit = (data: createClientJacSchema) => {
		handleCreateUserJAC(data, onClose)
	}

	const onChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue('username', event.target.value.split('@')[0])
	}

	return (
		<>
			<Modal showClose title={title} show={true} onClose={onClose}>
				<form
					className="modal-user-jac"
					autoComplete="off"
					autoCapitalize="off"
				>
					<div className="labels-row">
						<div>
							<label htmlFor="name">Nome</label>
							<Input
								id="name"
								name="name"
								variant="light-normal"
								innerRef={{ ...register('name') }}
								autoComplete="none"
								errors={errors.name}
							/>
						</div>
						<div>
							<label htmlFor="email">Email</label>
							<Input
								id="email"
								name="email"
								variant="light-normal"
								innerRef={{ ...register('email') }}
								onChange={(event) => onChangeUsername(event)}
								autoComplete="none"
								errors={errors.email}
							/>
						</div>
						<div>
							<label htmlFor="phone">Telefone</label>
							<Input
								id="phone"
								name="phone"
								variant="light-normal"
								innerRef={{ ...register('phone') }}
								errors={errors.phone}
							/>
						</div>
						<div>
							<label htmlFor="emailGestor">Email Gestor</label>
							<Input
								id="emailGestor"
								name="emailGestor"
								variant="light-normal"
								innerRef={{ ...register('emailGestor') }}
								autoComplete="none"
								errors={errors.emailGestor}
							/>
						</div>
						<div>
							<label htmlFor="username">Usuário</label>
							<Input
								id="username"
								name="username"
								variant="light-normal"
								readOnly
								value={getValues('username') || ''}
								innerRef={{ ...register('username') }}
								disabled
							/>
						</div>
						<div>
							<label htmlFor="username">Lojas</label>
							<Select
								innerRef={{ ...register('store') }}
								options={optionsStores}
								selectedOption={selectedStore || getValues('store')}
								onSelectOptions={(value) => {
									if (value.includes('3')) {
										setSelectedStore(['3'])
										setValue('store', ['3'])
									} else {
										setSelectedStore(value)
										setValue('store', value as unknown as any)
									}
								}}
								checkbox
								autoComplete="none"
								errors={getFieldState('store').error}
							/>
						</div>
					</div>
					<div className="action">
						<Button
							variant="confirmation-gradient"
							size="lg"
							title="Salvar"
							onClick={handleSubmit(onSubmit)}
							disabled={isLoading}
						/>
						<Button
							variant="cancellation-gradient"
							size="lg"
							title="Cancelar"
							onClick={onClose}
						/>
					</div>
				</form>
			</Modal>
			<LoadingProgress show={isLoading} />
		</>
	)
}
