import React, { createContext, useRef } from 'react'
import * as io from 'socket.io-client'

export interface SocketContextType {
	socket: any
	connect: () => void
	disconnect: () => void
	eventListener: (topic: string, cd: any) => void
}

export const SocketContextElement = createContext<SocketContextType>({
	socket: {},
	connect: () => {},
	disconnect: () => {},
	eventListener: () => {}
})

const SocketProvider = (props: any) => {
	const SOCKET_URL = process.env.REACT_APP_SOCKET_HOST || ''
	const socketRef = useRef<io.Socket>()

	const connect = async (): Promise<io.Socket> => {
		return new Promise((resolve) => {
			const socketConnection = io.connect(SOCKET_URL)
			socketRef.current = socketConnection
			socketConnection.on('connect', () => {
				resolve(socketConnection)
			})
		})
	}

	const disconnect = () => {
		const socket = socketRef.current
		if (socket && socket.connected) {
			socket.on('disconnect', () => {})
			socket.disconnect()
		}
	}

	const eventListener = async (topic: string, callback: any) => {
		try {
			const socketConnection = await connect()
			if (!socketConnection) {
				throw new Error('No socket connection')
			}
			socketConnection.on(topic, (...data: any) => {
				callback(null, data)
			})
		} catch (error) {
			console.error('Error in eventListener:', error)
			callback(error, null)
		}
	}

	const providerValues = () => {
		return {
			socket: socketRef.current,
			connect,
			disconnect,
			eventListener
		}
	}

	return (
		<SocketContextElement.Provider value={providerValues()}>
			{props.children}
		</SocketContextElement.Provider>
	)
}
export default SocketProvider
