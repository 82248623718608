import { FilterData } from 'models/FilterData'
import RequestResponse from 'models/RequestResponse'
import { RequisitionError } from 'models/RequisitionError'
import { Transfer, TransferInput } from 'models/Transfer'

export interface TransfersContextType {
	transferList: Transfer[]
	hasError?: RequisitionError
	isLoading: boolean
	pageLimit: number
	totalPages: number
	currentPage: number
	itemFilter?: FilterData
	onUpdateList: () => void
	onPageChange: (page: number) => void
	onLimitChange: (newLimit: number) => void
	onSearchEquip: (searchValue: string) => void
	onFilterChange: (newFilter: FilterData) => void
	onCreateTransfer: (
		transferInput: TransferInput,
		callback: (request: RequestResponse) => void
	) => void
	onRemoveTransfer: (
		transferId: number,
		callback: (request: RequestResponse) => void
	) => void
	onMeetTransfer: (
		transferId: number,
		userId: number,
		callback: (request: RequestResponse) => void,
		cancelRefrash?: boolean
	) => void
}

export const TransfersContextValue = {
	transferList: [],
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	itemFilter: {} as FilterData,
	onUpdateList: () => {},
	onPageChange: () => {},
	onLimitChange: () => {},
	onSearchEquip: () => {},
	onFilterChange: () => {},
	onCreateTransfer: () => {},
	onRemoveTransfer: () => {},
	onMeetTransfer: () => {}
}
