import { RequisitionError } from 'models/RequisitionError'
import Sc5010 from 'models/Sc5010'
import { Order, StorageItems } from 'module/orderManagement/domain/models/order'
import {
	IPendencyCreateCloudRequest,
	IPendencyDeleteCloudRequest,
	IPendencyUpdateCloudRequest
} from 'module/orderManagement/models/cloudTotvsRequisitionModels/IPendencyCloudRequisition'
import { OrderProductList } from 'module/orderManagement/models/OrderModel'
import Pendency from 'module/orderManagement/models/Pendency'
import {
	AddNewFollowUpProps,
	PrpOrder
} from 'module/orderManagement/models/PrpOrderModel'

export interface OrderDetailsContextType {
	availableProductChange: OrderProductList[] | undefined
	order: Order | undefined
	sc5010: Sc5010 | null
	prpOrderDetails: PrpOrder | null
	pendencies: Pendency[]
	storage: StorageItems[]
	hasError?: RequisitionError
	orderIsLoading: boolean
	libFinIsLoading: boolean
	orderPrpIsLoading: boolean
	sc5IsLoading: boolean
	onUpdateOrder: () => void
	onUpdateOrderPrp: (protheusOrder: string) => void
	onOrderChange: (order: string, branch: string) => void
	onCreateZZF010: (
		changes: IPendencyCreateCloudRequest,
		callback: (hasError?: RequisitionError) => void
	) => void
	onDeleteZZF010: (
		changes: IPendencyDeleteCloudRequest,
		callback: (hasError?: RequisitionError) => void
	) => void
	onUpdateZZF010: (
		changes: IPendencyUpdateCloudRequest,
		callback: (hasError?: RequisitionError) => void
	) => void
	onAddCommentary: (
		changes: AddNewFollowUpProps,
		callback: (hasError?: RequisitionError) => void
	) => void
	onUpdateC5Libfin: (params: {
		protheusOrder: string
		c5Libfin: string
	}) => void
	searchProduct: (value: string) => void
}

export const OrderDetailsContextValue = {
	availableProductChange: [] as OrderProductList[],
	order: {} as Order | undefined,
	sc5010: {} as Sc5010 | null,
	prpOrderDetails: {} as PrpOrder | null,
	pendencies: [],
	storage: [],
	hasError: {} as RequisitionError,
	orderIsLoading: {} as boolean,
	orderPrpIsLoading: {} as boolean,
	libFinIsLoading: {} as boolean,
	sc5IsLoading: {} as boolean,
	onUpdateOrder: () => {},
	onUpdateOrderPrp: () => {},
	onOrderChange: () => {},
	onCreateZZF010: () => {},
	onDeleteZZF010: () => {},
	onUpdateZZF010: () => {},
	onAddCommentary: () => {},
	onUpdateC5Libfin: () => {},
	searchProduct: () => {}
}
