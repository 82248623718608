import { debounce } from 'lodash'
import { customNormalizeHelper } from 'module/adminSystem/utils/NormalizerHelpers'
import React, { ChangeEvent } from 'react'
// import { customNormalizeHelper } from '../../utils/NormalizerHelpers'

interface ISearchInputProps extends React.ComponentPropsWithoutRef<'input'> {
	onChangeValues: (value: string) => void
	normalize?: boolean
}

export const SearchInput = ({
	onChangeValues,
	normalize = false,
	...rest
}: ISearchInputProps) => {
	const handleSearchInput = debounce((value: string) => {
		onChangeValues(value)
	}, 600)

	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value
		if (normalize) {
			value = customNormalizeHelper(event.target.value)
		}
		handleSearchInput(value)
	}

	return (
		<input
			type="search"
			id="search-input"
			onChange={handleChange}
			className="text-md h-10 w-full bg-transparent pl-2 pr-2 text-primary-pure outline-none placeholder:text-primary-lightest focus:italic"
			{...rest}
		/>
	)
}
