import * as React from 'react'
import './styles.scss'

interface LabelProps {
	htmlFor: string
	title: string
}

const Label = ({ htmlFor, title }: LabelProps) => {
	return (
		<label className="textarea-label" htmlFor={htmlFor}>
			{title}
		</label>
	)
}

interface SpanErrorProps {
	errorMessage?: string
	hasError: boolean
}

const Error = ({
	hasError,
	errorMessage = 'Campo obrigatório'
}: SpanErrorProps) => {
	return (
		<div className="textarea-span-error-container">
			{hasError && (
				<span className="textarea-span-error">{`** ${errorMessage}`}</span>
			)}
		</div>
	)
}

interface FieldProps
	extends React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> {}

const Field = React.forwardRef<HTMLTextAreaElement, FieldProps>(function Field(
	{ ...rest },
	ref
) {
	return (
		<textarea
			autoComplete="none"
			className="textarea-input"
			{...{ ref }}
			{...rest}
		/>
	)
})

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
}

const Textarea = ({ children, ...rest }: ContainerProps) => {
	return (
		<div className="textarea-component">
			<div {...rest}>{children}</div>
		</div>
	)
}

Textarea.Field = Field
Textarea.Label = Label
Textarea.Error = Error

export { Textarea }
