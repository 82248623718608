import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Information, Title } from './styles'

const Error500: React.FC = () => {
	return (
		<>
			<NavLink
				className="os-detail-back-button"
				to="/os"
				onClick={() => window.history.back()}
			>
				&lt; Voltar
			</NavLink>

			<Container>
				<Title>500</Title>
				<Information>
					Ocorreu um problema na comunicação do nosso servidor, entre em contato
					conosco através do chat ou clique em voltar e tente novamente.
				</Information>
			</Container>
		</>
	)
}

export default Error500
