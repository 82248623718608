import not_pendency from 'assets/image/not_pendency.svg'
import pendency from 'assets/image/pendency.svg'
import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import AllOrdersListItem, {
	AllOrdersListItemPendency
} from 'module/orderManagement/models/AllOrdersListItem'
import {
	handleFinancialReleaseTypes,
	handleOrderCategoryType,
	handleOrderStatusTypes,
	handleSalespersonType
} from 'module/orderManagement/utils/constants'
import moment from 'moment'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import LoadingList from 'shared/components/LoadingList'
import './styles.scss'

const OrdersListBody: React.FC = () => {
	const { orderList, isLoading, totalPages } = useContext(OrderContextElement)

	const handlePendencyStatus = (pendencyList: AllOrdersListItemPendency[]) => {
		const hasPendency = pendencyList?.some(
			(pendency) => pendency.status === '1' || pendency.status === '2'
		)
		return hasPendency ? (
			<img src={pendency} style={{ height: '26px', width: '16px' }} />
		) : (
			<img src={not_pendency} style={{ height: '26px', width: '16px' }} />
		)
	}

	const handleOrderStatus = (partOrder: AllOrdersListItem) => {
		const hasItemToBeBilled = partOrder.itens?.some(
			(pendency) => pendency.invoice === ''
		)
		const hasBilledItens = partOrder.itens?.some(
			(pendency) => pendency.invoice !== ''
		)
		if (hasBilledItens) {
			if (hasItemToBeBilled) {
				return handleOrderStatusTypes('5')
			}
			return handleOrderStatusTypes('6')
		} else if (partOrder.financial_status) {
			return handleFinancialReleaseTypes(partOrder.financial_status)
		}
		return '-'
	}

	const listContent = () => {
		return totalPages ? (
			orderList.map((partOrder: AllOrdersListItem, index) => {
				return (
					<NavLink
						className="orders-list-row"
						to={`/order/${partOrder.branch}/${partOrder.order}/details`}
						id={partOrder.order}
						key={index}
					>
						<div className="orders-list-item">
							<span className="info-title">Razão social:</span>
							<span className="info-value">
								{String(partOrder.client.name)}
							</span>
						</div>
						<div className="orders-list-first-line three-lines">
							<div>
								<span className="info-title">Pedido:</span>
								<span className="info-value">{partOrder.order}</span>
							</div>
							<div>
								<span className="info-title">Filial:</span>
								<span className="info-value">{partOrder.branch}</span>
							</div>
							<div>
								<span className="info-title">UF:</span>
								<span className="info-value">{partOrder.client.state}</span>
							</div>
						</div>
						<div className="orders-list-first-line">
							<div>
								<span className="info-title">Emissão:</span>
								<span className="info-value">
									{partOrder.issue_date
										? moment(partOrder.issue_date).format('DD/MM/YY')
										: '-'}
								</span>
							</div>
							<div>
								<span className="info-title">Entrega:</span>
								<span className="info-value">
									{partOrder.delivery_date
										? moment(partOrder.delivery_date).format('DD/MM/YY')
										: '-'}
								</span>
							</div>
						</div>
						<div className="orders-list-first-line">
							<div>
								<span className="info-title">Follow-up:</span>
								<span className="info-value">
									{partOrder.prp_follow_up
										? moment(partOrder.prp_follow_up).format('DD/MM/YY')
										: '-'}
								</span>
							</div>
							<div>
								<span className="info-title">Categoria:</span>
								<span className="info-value">
									{handleOrderCategoryType(partOrder.category)}
								</span>
							</div>
						</div>
						<div className="orders-list-item">
							<span className="info-title">Vendedor:</span>
							<span className="info-value">
								{partOrder.salesperson?.code
									? handleSalespersonType(partOrder.salesperson?.code)
									: '-'}
							</span>
						</div>
						<div className="orders-list-item">
							<span className="info-title">
								{handlePendencyStatus(partOrder.pendency_list)} Status:
							</span>
							<span className="info-value">{handleOrderStatus(partOrder)}</span>
						</div>
					</NavLink>
				)
			})
		) : (
			<NavLink
				className="orders-list-row"
				to={`/orders`}
				id="emptyList"
				key={0}
			>
				<ul className="orders-list-item orders-list-empty-list">
					Nenhum pedido encontrado
				</ul>
			</NavLink>
		)
	}

	return (
		<>
			{isLoading ? (
				<LoadingList show={isLoading} />
			) : (
				<div className="orders-list"> {listContent()}</div>
			)}
		</>
	)
}
export default OrdersListBody
