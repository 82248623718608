import styled from 'styled-components'

export const Container = styled.div`
	align-content: center;
	background-color: #ffffff;
	border: solid 1px #979797;
	border-radius: 30px;
	box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
	justify-items: center;
	margin: 0 auto;
	padding: 50px 0;
	text-align: center;
	width: 754px;
`

export const Title = styled.h1`
	color: #104079;
	font-size: 15em;
	margin-bottom: 0;
	margin-top: 10;
	padding: 0;
`

export const Information = styled.p`
	color: #858585;
	font-size: 20px;
	line-height: 30px;
	margin: 0 auto;
	width: 650px;
	padding: 70px 0;
`
