import React from 'react'
import Button from 'shared/components/Button'

type Props = {
	text: string
	onClick?: () => void
}

export const ButtonHeader = ({ text, onClick }: Props) => {
	return (
		<Button
			{...{ onClick }}
			title={text}
			variant="confirmation-solid"
			size="lg"
		/>
	)
}
