import produce from 'immer'

const INITIAL_STATE = {
	pageInfo: {
		pageLimit: 10,
		offset: null,
		fieldFilter: 'created_at',
		orderFilter: 'desc',
		searchTerm: '',
		statusChecked: [],
		stateChecked: [],
		typeOSChecked: [],
		filterSelected: undefined
	},
	pageNumber: 1,
	url: 'os'
}

export default function serviceOrders(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@serviceOrders/GOT_OS_CHANGE_PAGE':
				draft.pageNumber = action.payload.pageNumber
				break
			case '@serviceOrders/SET_URL_ORDERS':
				draft.url = action.payload.url
				break
			case '@serviceOrders/SET_SEARCH_TERM':
				draft.pageInfo.searchTerm = action.payload.term
				break
			case '@serviceOrders/SET_STATUS_CHECKED':
				draft.pageInfo.statusChecked = action.payload.statusChecked
				break
			case '@serviceOrders/SET_STATE_CHECKED':
				draft.pageInfo.stateChecked = action.payload.stateChecked
				break
			case '@serviceOrders/SET_TYPE_OS_CHECKED':
				draft.pageInfo.typeOSChecked = action.payload.typeOSChecked
				break
			case '@serviceOrders/SET_FILTER_SELECTED_SERVICE_ORDERS':
				draft.pageInfo.filterSelected = action.payload.filterSelected
				break
			case '@serviceOrders/SET_DEFAULT_FILTERS':
				draft.pageInfo.fieldFilter = 'created_at'
				draft.pageInfo.orderFilter = 'desc'
				draft.pageInfo.searchTerm = ''
				draft.pageInfo.statusChecked = []
				draft.pageInfo.stateChecked = []
				draft.pageInfo.typeOSChecked = []
				draft.pageInfo.filterSelected = undefined
				draft.pageInfo.pageNumber = 1
				break
			case '@auth/SIGN_OUT':
				draft.pageInfo.pageLimit = 10
				draft.pageInfo.offset = null
				draft.pageInfo.fieldFilter = 'created_at'
				draft.pageInfo.orderFilter = 'desc'
				draft.pageInfo.searchTerm = ''
				draft.pageInfo.statusChecked = []
				draft.pageInfo.stateChecked = []
				draft.pageInfo.typeOSChecked = []
				draft.pageInfo.filterSelected = undefined
				draft.pageNumber = 1
				draft.url = 'os'
				break
			default:
				return state
		}
	})
}
