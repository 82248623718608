import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import ClientMenuTabsMobile from './Mobile'
import ClientMenuTabsWeb from './Web'

type TParams = {
	path: string
}

export default function ClientMenuTabs({ path }: TParams) {
	const { viewType } = useContext(DeviceContextElement)
	return viewType !== ViewType.MOBILE ? (
		<ClientMenuTabsWeb path={path} />
	) : (
		<ClientMenuTabsMobile path={path} />
	)
}
