export const getTechnicians = (technicians, pageInfo, pageNumber) => {
	return {
		type: '@techs/GOT_TECHNICIANS',
		payload: {
			technicians,
			pageInfo,
			pageNumber
		}
	}
}

export const getTechChangePage = (pageNumber) => {
	return {
		type: '@techs/GOT_TECH_CHANGE_PAGE',
		payload: {
			pageNumber
		}
	}
}

export const changeTechSearchTerm = (term) => {
	return {
		type: '@techs/SET_SEARCH_TERM',
		payload: {
			term
		}
	}
}
