import {
	browserTracingIntegration,
	init,
	replayIntegration,
	rewriteFramesIntegration
} from '@sentry/react'
import packageJson from '../../package.json'

export function useSentry(): void {
	if (process.env.NODE_ENV === 'production') {
		init({
			enabled: true,
			tracesSampleRate: 1.0,
			autoSessionTracking: true,
			environment: process.env.NODE_ENV,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			release: `${packageJson.name}@${packageJson.version}`,
			tracePropagationTargets: ['https://admin.praticabr.com/'],
			dsn: 'https://266361f61852c5abca2651f57e671119@o348794.ingest.us.sentry.io/4507096393580544',
			integrations: [
				rewriteFramesIntegration(),
				browserTracingIntegration(),
				replayIntegration({
					maskAllText: false,
					blockAllMedia: false,
					maskAllInputs: false
				})
			]
		})
	}
}
