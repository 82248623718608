import { RecognitionCard, RecognitionCardForm } from 'models/RecognitionCard'
import React, {
	ReactNode,
	createContext,
	useEffect,
	useRef,
	useState
} from 'react'
import { useSelector } from 'react-redux'
import generatePDF, { Margin, Resolution } from 'react-to-pdf'
import { toast } from 'react-toastify'
import {
	getCardsFromUser,
	getCardsToUser,
	getSentCards,
	postEmailCard,
	postRecognitionCards,
	postSentCards,
	updateSentCards,
	usersPlatforms
} from 'services'

export const RecognitionCardContext = createContext({
	isLoading: false,
	dataCard: {} as RecognitionCard,
	cardsReceived: [] as RecognitionCard[],
	cardsSent: [] as RecognitionCard[],
	targetRef: {} as React.MutableRefObject<undefined>,
	quantCards: 0,
	onCreateNewCard: (data: RecognitionCardForm) => {},
	onSendEmail: (callback?: () => void) => {},
	onResendCard: (data: RecognitionCard, callback?: () => void) => {},
	onDownloadCard: () => {},
	onGetCards: () => {},
	onGetQuantCards: () => {},
	onCleanDataCard: () => {}
})

export const RecognitionCardProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [quantCards, setQuantCards] = useState<number>(4)
	const [dataCard, setDataCard] = useState<RecognitionCard>(
		{} as RecognitionCard
	)
	const [cardsReceived, setCardsReceived] = useState<RecognitionCard[]>([])
	const [cardsSent, setCardsSent] = useState<RecognitionCard[]>([])
	const arrayDate = new Date().toISOString().split('-')
	const user = useSelector((state: any) => state.user)
	const targetRef = useRef()

	const onCleanDataCard = () => {
		setDataCard({} as RecognitionCard)
	}

	const getCards = () => {
		setIsLoading(true)
		Promise.all([getCardsToUser(user.profile), getCardsFromUser(user.profile)])
			.then(([responseCardFromUser, responseCardToUser]) => {
				setCardsSent(responseCardToUser.data)
				setCardsReceived(responseCardFromUser.data)
			})
			.catch((err) => {
				toast.error('Houve um erro ao buscar lista de cartões')
				console.log('Erro> ', err)
			})
			.finally(() => setIsLoading(false))
	}

	const getQtdCard = () => {
		setIsLoading(true)
		getSentCards(user.profile, arrayDate[1].concat(arrayDate[0]))
			.then((response) => {
				if (response.data[0]) {
					setQuantCards(response.data[0].qty_cards)
				}
			})
			.catch((error) => {
				console.log(error, 'No momento o serviço está indisponível.')
			})
			.finally(() => setIsLoading(false))
	}

	useEffect(() => {
		getQtdCard()
	}, [])

	const sendEmail = (callback?: () => void) => {
		if (Object.keys(dataCard).length > 0) {
			generatePDF(targetRef, {
				filename: 'Cartão de Reconhecimento.pdf',
				resolution: Resolution.HIGH,
				method: 'build',
				page: {
					orientation: 'landscape',
					margin: Margin.NONE,
					format: [120, 177]
				}
			})
				.then((responsePDF) => {
					const emailTo = dataCard?.toUser?.email
					const body = `Olá, ${dataCard?.toUser?.name}, <br><br>A realização pessoal é a maior conquista de um profissional,<br>mas o reconhecimento dos demais é o que faz esse acontecimento ser tão especial.<br><br>Parabéns, você faz a diferença!`

					const json = {
						to: emailTo,
						from: 'gente@praticabr.com',
						subject: 'Cartão de Reconhecimento',
						attachments: btoa(responsePDF.output()),
						filename: 'Cartão de Reconhecimento.pdf',
						html: body
					}

					postEmailCard(json)
						.then(() => {
							toast.success('Cartão de Reconhecimento enviado com sucesso!')
							callback && callback()
						})
						.catch((err) => {
							console.log('Erro:', err)
							toast.error('Erro ao enviar e-mail de notificação')
						})
						.finally(() => setIsLoading(false))
				})
				.catch((err) => {
					console.log('Erro ao gerar PDF', err)
					toast.error('Erro ao gerar PDF')
				})
		}
	}

	const downloadCard = () => {
		setIsLoading(true)
		generatePDF(targetRef, {
			filename: 'Cartão de Reconhecimento.pdf',
			resolution: Resolution.HIGH,
			method: 'save',
			page: {
				orientation: 'landscape',
				margin: Margin.NONE,
				format: [120, 177]
			}
		})
			.then(() => {
				toast.success('Cartão baixado com sucesso!')
			})
			.catch((err) => {
				console.log('Erro: ', err)
				toast.error('Erro ao baixar cartão')
			})
			.finally(() => setIsLoading(false))
	}

	const resendCard = (data: RecognitionCard, callback?: () => void) => {
		setIsLoading(true)
		generatePDF(targetRef, {
			filename: 'Cartão de Reconhecimento.pdf',
			resolution: Resolution.HIGH,
			method: 'build',
			page: {
				orientation: 'landscape',
				margin: Margin.NONE,
				format: [120, 177]
			}
		})
			.then((responsePDF) => {
				const emailTo = data?.toUser?.email
				const body = `Olá, ${data?.toUser?.name}, <br><br>A realização pessoal é a maior conquista de um profissional,<br>mas o reconhecimento dos demais é o que faz esse acontecimento ser tão especial.<br><br>Parabéns, você faz a diferença!`

				const json = {
					to: emailTo,
					from: 'gente@praticabr.com',
					subject: 'Cartão de Reconhecimento',
					attachments: btoa(responsePDF.output()),
					filename: 'Cartão de Reconhecimento.pdf',
					html: body
				}

				postEmailCard(json)
					.then(() => {
						toast.success('CARTÃO DE RECONHECIMENTO REENVIADO COM SUCESSO!')
						callback && callback()
					})
					.catch((err) => {
						console.log('Erro:', err)
						toast.error('Erro ao enviar e-mail de notificação')
					})
					.then(() => setIsLoading(false))
			})
			.catch((err) => {
				console.log('Erro ao gerar PDF', err)
				toast.error('Erro ao gerar PDF')
			})
	}

	const updSentCard = async () => {
		const date = new Date()
		const arrayDate = date.toISOString().split('-')

		if (quantCards === 4) {
			const data = {
				userId: user.profile,
				qty_cards: 3,
				date: arrayDate[1].concat(arrayDate[0])
			}

			postSentCards(data).then((response) => {
				if (response.status === 200) {
					setQuantCards(3)
				}
			})
		} else {
			const qtd = quantCards - 1
			const data = {
				qty_cards: qtd
			}

			updateSentCards(
				user.profile,
				arrayDate[1].concat(arrayDate[0]),
				data
			).then(() => {
				setQuantCards(qtd)
			})
		}
	}

	const handleCreateNewCard = (data: RecognitionCardForm) => {
		setIsLoading(true)
		postRecognitionCards({ ...data, to_user: Number(data.to_user) })
			.then((response) => {
				usersPlatforms(Number(response.data.to_user))
					.then((responseUser) => {
						const cardData = {
							...response.data,
							toUser: responseUser.data,
							fromUser: user,
							created_at: new Date()
						}
						updSentCard()
						setDataCard(cardData)
					})
					.catch((err) => {
						console.log('Error fetching user platform:', err)
						toast.error('Erro ao obter plataforma do usuário')
						setIsLoading(false)
					})
			})
			.catch((err) => {
				console.log('Error creating recognition card:', err)
				toast.error('Erro ao criar cartão de reconhecimento')
				setIsLoading(false)
			})
	}

	const value = {
		isLoading,
		dataCard,
		targetRef,
		cardsReceived,
		cardsSent,
		onCreateNewCard: handleCreateNewCard,
		onSendEmail: sendEmail,
		onResendCard: resendCard,
		onDownloadCard: downloadCard,
		onGetCards: getCards,
		onGetQuantCards: getQtdCard,
		quantCards,
		onCleanDataCard
	}

	return (
		<RecognitionCardContext.Provider value={value}>
			{children}
		</RecognitionCardContext.Provider>
	)
}
