import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import brLocale from 'date-fns/locale/pt-BR'
import React from 'react'

interface Props {
	label: string
	placeholder: string
	value?: MaterialUiPickersDate
	minDate?: Date
	maxDate?: Date
	onDateSelect: (value: MaterialUiPickersDate) => void
}

const CustomDatePicker: React.FC<Props> = ({
	label,
	placeholder,
	value,
	minDate,
	maxDate,
	onDateSelect
}) => {
	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
			<KeyboardDatePicker
				format="dd/MM/yyyy"
				id="date-final-picker"
				cancelLabel="CANCELAR"
				clearLabel="LIMPAR"
				okLabel="OK"
				emptyLabel={placeholder}
				label={label}
				minDate={minDate}
				maxDate={maxDate}
				invalidDateMessage="Data invalida"
				value={value}
				onChange={onDateSelect}
				KeyboardButtonProps={{
					'aria-label': 'change date',
					className: 'period-filter-keyboard-button'
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}

export default CustomDatePicker
