import Technician from 'models/Technician'
import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import './styles.css'

interface Props {
	techEdit?: Technician
	cpfCnpj?: string
	personType: string
	errors: any
	register: any
}

const PERSON_TYPE = {
	PJ: 'J',
	PF: 'F'
}

const CpfCnpjField: React.FC<Props> = ({
	techEdit,
	personType,
	register,
	errors
}) => {
	const [formName, setFormName] = useState('')
	const [placeholder, setPlaceholder] = useState('')

	useEffect(() => {
		switch (personType) {
			case 'J':
				setPlaceholder('CNPJ')
				setFormName('cnpj')
				break
			case 'F':
				setPlaceholder('CPF')
				setFormName('cpf')
				break
			default:
				break
		}
	}, [personType])

	const renderCpfCnpjField = () => {
		return personType === 'J' ? (
			<div className={`cpf-cnpj pj-form-content`}>
				<div className="cpf-cnpj-field-label">CNPJ</div>
				<InputMask
					className="cpf-cnpj-field"
					type="tel"
					mask={'99.999.999/9999-99'}
					maskChar=""
					placeholder={'CNPJ'}
					defaultValue={
						techEdit?.person_type === PERSON_TYPE.PJ ? techEdit?.cnpj : ''
					}
					{...register('cnpj', {
						required: true,
						minLength: 18,
						maxLength: 18
					})}
				/>
			</div>
		) : (
			<div className={`cpf-cnpj pf-form-content`}>
				<div className="cpf-cnpj-field-label">CPF</div>
				<InputMask
					className="cpf-cnpj-field"
					type="tel"
					mask={'999.999.999-99'}
					maskChar=""
					placeholder={'CPF'}
					defaultValue={
						techEdit?.person_type === PERSON_TYPE.PF ? techEdit?.cnpj : ''
					}
					{...register('cpf', { required: true, minLength: 14, maxLength: 14 })}
				/>
			</div>
		)
	}

	return (
		<>
			{renderCpfCnpjField()}
			<small>
				{errors[formName] && (
					<span style={{ color: 'red', display: 'flex', margin: 'auto' }}>
						O campo {placeholder} é obrigatório
					</span>
				)}
			</small>
		</>
	)
}

export default CpfCnpjField
