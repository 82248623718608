import React, { useContext, useState } from 'react'

import Client from 'models/Client'

import { IconButton } from '@material-ui/core'
import PrevContracts from 'models/PrevContracts'
import { getClientPreventiveContract } from 'services'
import SuccessMsg from 'shared/SuccessMsg'

import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import ModalEditContract from 'module/technicalServices/components/ModalEditContract'
import { FaPencilAlt } from 'react-icons/fa'
import LoadingProgress from 'shared/components/LoadingProgress'
import { ViewType } from 'utils/Constants'
import './styles.css'

interface Props {
	prevContract: PrevContracts
	hasUpdated: () => void
}

const Contract: React.FC<Props> = ({ prevContract, hasUpdated }) => {
	const { viewType } = useContext(DeviceContextElement)
	const [client, setClient] = useState<Client>()
	const [date, setDate] = useState<string>()
	const [format, setFormat] = useState<boolean>(true)
	const [loading, setLoading] = useState<boolean>(false)

	const formatDate = (end_date: number) => {
		const toString = end_date.toString()
		const arrayData = toString?.split('-')
		const formatedDate =
			arrayData[2]?.slice(0, 2) + '/' + arrayData[1] + '/' + arrayData[0]

		setDate(formatedDate)
	}

	if (format) {
		formatDate(prevContract?.end_date)
		setFormat(false)
	}

	const onEditClick = () => {
		$(`.mod${prevContract?.cpfCnpj}`).toggle()
		setLoading(true)
		getClientPreventiveContract(prevContract?.id_client)
			.then((res) => {
				const clientData = res.data[0].client
				if (clientData) {
					clientData.preventive = prevContract
					setClient(clientData)
				}
				setLoading(false)
			})
			.catch(() => {
				setLoading(false)
				$(`.mod${prevContract?.cpfCnpj}`).toggle()
			})
	}

	return viewType !== ViewType.MOBILE ? (
		<div>
			<div className="table-content">
				<div className="table-item" key={prevContract?.id}>
					<div className="contracts-table-item-razao">
						{prevContract?.razao_social}
					</div>
					<div className="contracts-table-item-cnpj">
						{prevContract?.cpfCnpj?.replace(
							/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
							'$1.$2.$3/$4-$5'
						)}
					</div>
					<div className="contracts-table-item-end-contract">{date}</div>
					<div className="contracts-table-item-equip">
						<div>{prevContract?.equips}</div>
						<IconButton
							color="primary"
							component="span"
							onClick={() => {
								onEditClick()
							}}
							className="icon-button"
						>
							<FaPencilAlt />
						</IconButton>
					</div>
				</div>
			</div>

			<div
				className={`modal mod${prevContract?.cpfCnpj}`}
				style={{ padding: '45px 75px' }}
			>
				{!loading ? (
					client ? (
						<ModalEditContract client={client} hasUpdated={hasUpdated} />
					) : (
						<div className="message">
							<SuccessMsg
								result={true}
								msg="Erro ao buscar informações do contrato!"
								resetStatus={() => {
									$(`.mod${prevContract?.cpfCnpj}`).toggle()
								}}
							/>
						</div>
					)
				) : (
					<div className="loading-list" style={{ color: '#fff' }}>
						<LoadingProgress show={loading} text="Carregando..." />
					</div>
				)}
			</div>
		</div>
	) : (
		<div>
			<div className="table-content">
				<div className="table-item" key={prevContract?.id}>
					<div className="contracts-table-item-razao">
						Cliente: {prevContract?.razao_social}
					</div>
					<div className="contracts-table-item-cnpj">
						CNPJ:{' '}
						{prevContract?.cpfCnpj?.replace(
							/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
							'$1.$2.$3/$4-$5'
						)}
					</div>
					<div className="contracts-table-item-end-contract">
						Fim do contrato: {date}
					</div>
					<div className="contracts-table-item-equip">
						<div>Quantidade de equipamentos: {prevContract?.equips}</div>
						<IconButton
							color="primary"
							component="span"
							onClick={() => {
								onEditClick()
							}}
							className="icon-button"
						>
							<FaPencilAlt />
						</IconButton>
					</div>
				</div>
			</div>

			<div
				className={`modal mod${prevContract?.cpfCnpj}`}
				style={{ padding: '45px 75px' }}
			>
				{!loading ? (
					client ? (
						<ModalEditContract client={client} hasUpdated={hasUpdated} />
					) : (
						<div className="message">
							<SuccessMsg
								result={true}
								msg="Erro ao buscar informações do contrato!"
								resetStatus={() => {
									$(`.mod${prevContract?.cpfCnpj}`).toggle()
								}}
							/>
						</div>
					)
				) : (
					<div className="loading-list" style={{ color: '#fff' }}>
						<LoadingProgress show={loading} text="Carregando..." />
					</div>
				)}
			</div>
		</div>
	)
}

export default Contract
