import React from 'react'
import './styles.scss'

interface AlertIconProps {
	width: string
	height: string
	fill?: string
	onClick?: () => void
}

export const CheckboxChecked = ({
	fill = 'white',
	width,
	height
}: AlertIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 57 57"
			fill={fill}
		>
			<path
				d="M1.98824 0.39761C1.45804 0.596434 0.795296 1.12663 0.530198 1.52428C0.265099 1.92193 0 14.1827 0 28.7632C0 48.5793 0.198824 55.5382 0.795296 56.1346C1.39177 56.7311 8.21806 56.9299 27.4377 56.9299C41.6868 56.9299 54.0139 56.7311 54.8755 56.5323L56.3335 56.1346V28.4318V0.795259L52.8872 0.39761C48.3143 -0.132587 3.31374 -0.132587 1.98824 0.39761ZM55.008 28.4318V54.9417H28.4981H1.98824V28.4318V1.92193H28.4981H55.008V28.4318Z"
				fill="#686868"
			/>
			<path
				d="M34.1977 22.4671C28.4318 28.2993 23.3287 33.071 22.8647 33.071C22.3345 33.071 19.7498 30.8177 17.0988 28.1004C14.3816 25.3832 11.8631 23.1298 11.3992 23.1298C10.0737 23.1298 6.62744 26.9738 6.62744 28.4318C6.62744 30.3538 21.0091 45.0005 22.8647 45.0005C23.9914 45.0005 27.9679 41.2891 37.1801 31.6793C44.2715 24.3228 50.1699 17.7616 50.2362 17.0326C50.5013 15.5082 47.3201 11.8631 45.7295 11.8631C45.133 11.8631 39.9636 16.6349 34.1977 22.4671Z"
				fill="#004687"
			/>
		</svg>
	)
}

export const CheckboxUnchecked = ({
	fill = 'white',
	width,
	height
}: AlertIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 56 55"
			fill={fill}
		>
			<path
				d="M1.41687 1.23776C0.0674699 2.58013 0 3.31844 0 27.5484C0 43.3884 0.26988 52.8522 0.674699 53.7247C1.3494 54.9329 2.36145 55 27.8651 55C47.5663 55 54.5831 54.7986 55.1904 54.1946C55.7976 53.5905 56 46.8115 56 27.7497C56 4.72794 55.8651 2.04318 54.853 1.23776C53.9759 0.499449 48.6458 0.230973 28.2699 0.0967353C3.30602 -0.104621 2.76627 -0.104621 1.41687 1.23776ZM54.0434 5.93608C54.0434 7.61405 54.1108 18.6216 54.1783 30.3002C54.3133 49.9661 54.2458 51.7112 53.0988 52.7851C52.0193 53.9261 50.0627 53.9932 28.0675 53.9932C8.90602 53.9932 4.04819 53.7919 3.17108 53.0536C2.15904 52.2481 2.0241 49.6305 2.0241 27.4141V2.64725L25.5036 2.51302C38.3904 2.37878 50.0627 2.4459 51.4795 2.58013C53.9759 2.78149 53.9759 2.78149 54.0434 5.93608Z"
				fill="#686868"
			/>
		</svg>
	)
}
