import PraticaIcon from 'assets/SvgIcons/PraticaIcon'
import QSLIcon from 'assets/SvgIcons/QSLIcon'
import { RecognitionCard } from 'models/RecognitionCard'
import React from 'react'
import { CheckboxChecked, CheckboxUnchecked } from '../../Checkbox'
import './styles.scss'

interface CardProps {
	dataCard: RecognitionCard
	targetRef: React.MutableRefObject<any>
}

export const Card = ({ dataCard, targetRef }: CardProps) => {
	const guidelines = [
		{ value: dataCard?.perfection, label: 'PERFEIÇÃO' },
		{ value: dataCard?.client, label: 'CLIENTE' },
		{ value: dataCard?.knowledge, label: 'CONHECIMENTO' },
		{ value: dataCard?.pro_solutions, label: 'PRÓ-SOLUÇÕES' },
		{ value: dataCard?.human, label: 'HUMANO' },
		{ value: dataCard?.protagonism, label: 'PROTAGONISMO' },
		{ value: dataCard?.harmony, label: 'HARMONIA' },
		{ value: dataCard?.urgency, label: 'URGÊNCIA' },
		{ value: dataCard?.constancy, label: 'CONSTÂNCIA' },
		{ value: dataCard?.evolution, label: 'EVOLUÇÃO' }
	]

	const date = new Date(dataCard?.created_at)
	const day = date.getDate()
	const month = date.getMonth() + 1
	const year = date.getFullYear()
	const nameTo = `${dataCard?.toUser?.name} ${dataCard?.toUser?.surname}`
	const nameFrom = `${dataCard?.fromUser?.name} ${dataCard?.fromUser?.surname}`

	const countGuidelines = guidelines.filter(
		(guideline) => guideline.value === 'S'
	).length

	return (
		<div className="template-card-background">
			<div className="template-card" ref={targetRef}>
				<div className="template-card-content">
					<div className="template-card-header">
						<label>CARTÃO DE RECONHECIMENTO</label>
					</div>
					<div className="template-card-text">
						<label>
							Quero reconhecer{' '}
							<span className="template-card-span-name">
								{nameTo.length > 17 ? `${nameTo.substring(0, 23)}...` : nameTo}
							</span>{' '}
							por demonstrar
						</label>
						<label>
							a{countGuidelines > 1 && 's'} seguinte{countGuidelines > 1 && 's'}{' '}
							diretriz{countGuidelines > 1 && 'es'} da Qualidade sem Lacunas
							Prática
						</label>
					</div>
					<div className="template-card-checkboxes">
						{guidelines.map((guideline, index) => (
							<div className="template-card-checkbox" key={index}>
								{guideline.value === 'S' ? (
									<CheckboxChecked width="13px" height="14px" />
								) : (
									<CheckboxUnchecked width="13px" height="14px" />
								)}
								<label>{guideline.label}</label>
							</div>
						))}
					</div>
					<div className="template-card-text-block">
						<div className="template-card-text">
							<label>Através da seguinte ação/maneira:</label>
						</div>
						<div className="template-card-text-content">
							<span className="template-card-content-text-line">
								{dataCard?.obs1}
							</span>
							<span className="template-card-content-base-lines"></span>
							<span className="template-card-content-base-lines"></span>
							<span className="template-card-content-base-lines"></span>
							<span className="template-card-content-base-lines"></span>
						</div>
					</div>
					<div className="template-card-text-block">
						<div className="template-card-text">
							<label>
								E que, por isso, impactaram positivamente os seguintes
								resultados:
							</label>
						</div>
						<div className="template-card-text-content">
							<span className="template-card-content-text-line">
								{dataCard?.obs2}
							</span>
							<span className="template-card-content-base-lines"></span>
							<span className="template-card-content-base-lines"></span>
						</div>
						<div className="template-card-text">
							<label>
								Reconhecido por:
								<span className="template-card-name-bottom">
									{nameFrom.length > 22
										? `${nameFrom.substring(0, 23)}...`
										: nameFrom}
								</span>
								Data:{' '}
								<span className="template-card-date">
									{day < 10 ? `0${day}` : `${day}`}
								</span>{' '}
								/
								<span className="template-card-date">
									{month < 10 ? `0${month}` : `${month}`}
								</span>
								/{' '}
								<span className="template-card-date">
									{String(year).slice(2, 4)}
								</span>
							</label>
						</div>
					</div>
				</div>
				<div className="template-card-icons">
					<div className="template-card-icon-qsl">
						<QSLIcon width="83%" height="90%" />
					</div>
					<div className="template-card-icon-pratica">
						<PraticaIcon width="85%" height="85%" fill="#004687" />
					</div>
				</div>
			</div>
		</div>
	)
}
