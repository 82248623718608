import React from 'react'
import './styles.scss'

interface AlertIconProps {
	width: string
	height: string
	fill?: string
	onClick?: () => void
}

const QSLIcon = ({ fill = 'white', width, height }: AlertIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 418 406"
			fill={fill}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M204.355 0.387421C167.935 2.40192 134.844 20.4937 113.324 50.1689C105.158 61.4424 98.346 75.6601 94.8626 88.7157C91.6889 100.686 90.7987 107.466 90.7987 120.483C90.76 131.485 91.1858 136.095 93.1597 145.432C99.0426 173.712 115.337 199.397 138.443 216.83C149.203 224.927 161.201 231.164 174.128 235.31C185.777 239.07 198.076 241.002 210.354 241.096V216.5C209.281 216.496 208.198 216.477 207.103 216.443C176.45 215.552 148.003 199.552 130.818 173.519C119.71 156.744 114.408 137.761 115.066 117.422C115.53 102.933 118.588 90.9239 125.052 77.9071C132.676 62.5271 143.707 50.0139 158.104 40.4838C173.818 30.0239 191.428 24.7552 210.354 24.7552V0.102905C208.839 0.148093 206.997 0.243426 204.355 0.387421Z"
				fill="#707070"
			/>
			<path
				d="M248.439 32.387C236.015 27.1958 224.056 24.7939 210.354 24.7552V0.102905C213.324 0.0143622 215.04 0.118363 219.063 0.426163C245.691 2.32445 269.455 12.1258 289.852 29.5977C291.594 31.0699 292.987 32.387 292.987 32.5033C292.987 32.6195 289.116 36.6097 284.356 41.3748L275.764 49.9752L272.28 47.0309C265.623 41.3748 256.412 35.7575 248.439 32.387Z"
				fill="#555555"
			/>
			<path
				d="M268.294 226.593C261.637 230.351 252.503 234.109 244.259 236.51C233.344 239.659 221.859 241.184 210.354 241.096V216.5C226.444 216.556 240.305 213.203 254.709 205.867C273.906 196.104 289.736 179.291 298.521 159.417C305.991 142.487 308.468 123.001 305.411 104.987L304.714 100.919L313.655 88.6382C318.57 81.8586 322.711 76.3575 322.827 76.3187C322.944 76.3187 323.872 78.7206 324.84 81.6262C329.523 95.7664 331.071 105.297 331.071 120.677C331.071 134.042 329.91 142.565 326.427 154.729C322.479 168.444 314.158 184.443 305.217 195.523C303.824 197.266 302.663 198.855 302.663 199.01C302.663 199.165 307.694 204.356 313.887 210.554L325.111 221.789L311.178 235.736L297.244 249.682L284.743 237.169C277.892 230.312 272.126 224.695 271.971 224.695C271.816 224.695 270.152 225.547 268.294 226.593Z"
				fill="#555555"
			/>
			<path
				d="M297.051 81.355C266.63 111.805 241.55 136.754 241.317 136.754C241.124 136.754 239.963 135.785 238.763 134.584L236.557 132.415L233.886 136.057C229.706 141.713 221.308 153.335 221.23 153.49C221.153 153.722 241.24 178.206 241.511 178.206C241.627 178.167 266.63 143.998 297.051 102.236C327.511 60.4739 352.474 26.2274 352.552 26.1111C352.629 26.0337 352.629 25.9562 352.552 25.9562C352.474 25.9562 327.472 50.8663 297.051 81.355Z"
				fill="#034185"
			/>
			<path
				d="M251.69 81.7424C221.385 111.999 196.499 136.754 196.344 136.754C196.15 136.754 182.991 123.66 167.006 107.699L137.979 78.6432L131.592 85.0353C126.6 90.0329 125.284 91.5437 125.593 92.0086C126.871 93.9069 196.073 178.167 196.305 178.09C196.576 178.012 200.485 172.666 264.54 84.8416C285.595 55.98 303.747 31.0699 304.869 29.5203C306.03 28.0094 306.882 26.7309 306.843 26.7309C306.765 26.7309 281.956 51.4861 251.69 81.7424Z"
				fill="#034185"
			/>
			<path
				d="M295.271 51.9123C291.362 57.2972 287.994 61.9848 287.84 62.3334C287.607 62.7983 288.188 63.9993 290.007 66.6724C291.362 68.6481 293.142 71.4374 293.955 72.7934L295.426 75.234L304.25 66.4012C309.127 61.5199 313.113 57.3359 313.113 57.1035C313.113 56.5611 308.12 49.3166 305.411 45.9462C304.211 44.4353 303.05 43.0019 302.818 42.692C302.508 42.2659 300.844 44.3191 295.271 51.9123Z"
				fill="#555555"
			/>
			<path
				d="M176.528 84.9967L170.18 91.3501L184.655 109.016L199.131 126.681L207.065 118.74L214.999 110.798L198.937 94.7205L182.875 78.6432L176.528 84.9967Z"
				fill="#034185"
			/>
			<path
				d="M169.522 289.198V293.265H172.425H175.328L175.405 321.623L175.521 350.02H180.746H185.971V317.672V285.323L177.766 285.207L169.522 285.13V289.198Z"
				fill="#034185"
			/>
			<path
				d="M246.04 285.362C245.885 285.517 245.769 287.377 245.769 289.469V293.265H248.671H251.574V302.95V312.635L249.562 311.047C243.833 306.514 236.054 305.662 229.552 308.839C224.714 311.202 220.379 316.781 218.87 322.708C218.018 326.001 218.018 332.199 218.87 335.454C220.34 341.187 224.598 346.96 229.01 349.206C235.899 352.654 243.795 351.918 249.562 347.192C250.723 346.262 251.69 345.565 251.768 345.642C251.845 345.72 252.039 346.727 252.232 347.889L252.542 350.02L257.457 350.136C262.295 350.252 262.411 350.214 262.605 349.361C262.721 348.858 262.76 334.253 262.721 316.897L262.605 285.323L254.438 285.207C249.987 285.168 246.194 285.246 246.04 285.362ZM245.265 317.904C247.549 318.989 249.562 321.12 250.839 323.831C251.574 325.265 251.729 326.272 251.768 329.1C251.768 332.161 251.652 332.858 250.684 334.795C246.233 343.86 234.699 343.938 230.287 334.95C229.281 332.897 229.087 332.044 229.049 329.643C229.01 326.078 229.552 324.064 231.177 321.739C234.506 316.897 240.079 315.386 245.265 317.904Z"
				fill="#034185"
			/>
			<path
				d="M349.765 285.362C349.611 285.517 349.494 287.377 349.494 289.469V293.265H352.397H355.3V302.95V312.635L353.287 311.047C347.559 306.514 339.78 305.662 333.278 308.839C328.44 311.163 324.105 316.781 322.595 322.631C321.783 325.846 321.783 332.354 322.595 335.531C324.066 341.187 328.362 346.96 332.736 349.206C339.625 352.654 347.521 351.918 353.287 347.192C354.449 346.262 355.416 345.565 355.494 345.642C355.571 345.72 355.764 346.727 355.958 347.889L356.268 350.02L361.183 350.136C366.021 350.252 366.137 350.214 366.331 349.361C366.447 348.858 366.485 334.253 366.447 316.897L366.331 285.323L358.164 285.207C353.713 285.168 349.92 285.246 349.765 285.362ZM348.991 317.904C351.275 318.989 353.287 321.12 354.565 323.831C355.3 325.265 355.455 326.272 355.494 329.1C355.494 332.161 355.377 332.858 354.41 334.795C349.959 343.86 338.425 343.938 334.013 334.95C333.007 332.897 332.813 332.044 332.774 329.643C332.736 326.078 333.278 324.064 334.903 321.739C338.232 316.897 343.805 315.386 348.991 317.904Z"
				fill="#034185"
			/>
			<path
				d="M29.8018 287.725C20.2419 288.423 10.6821 294.079 5.45712 302.137C0.348234 310.117 -1.0451 320.5 1.89638 329.294C5.2249 339.211 12.5399 346.533 22.4481 349.865C30.8468 352.693 40.9871 351.531 48.3795 346.921L50.5082 345.604L53.024 348.083C54.4173 349.478 55.8106 350.601 56.1203 350.601C56.8943 350.601 63.0869 344.364 63.0869 343.628C63.0869 343.279 61.9645 341.923 60.6099 340.567L58.1716 338.127L60.0294 334.601C64.519 326.04 65.0608 316.742 61.5775 307.793C56.6621 295.047 43.5416 286.757 29.8018 287.725ZM39.9808 299.929C48.3408 303.919 53.3336 313.449 51.9016 322.708C51.4758 325.42 50.1212 329.294 49.5793 329.294C49.4632 329.294 47.6829 327.628 45.6703 325.575C43.6577 323.56 41.7225 321.74 41.3742 321.546C40.9097 321.275 39.9421 322.049 37.1555 324.839L33.5173 328.48L38.0843 333.129L42.6901 337.778L41.3742 338.592C37.5812 340.916 31.2725 341.575 26.6281 340.141C20.1258 338.127 14.746 332.354 12.7334 325.226C11.8432 322.049 12.0368 316.045 13.1205 312.713C14.8621 307.406 18.4229 302.989 22.9512 300.587C26.7055 298.611 28.0214 298.302 32.7045 298.418C36.7297 298.495 37.1168 298.573 39.9808 299.929Z"
				fill="#034185"
			/>
			<path
				d="M199.208 289.236C199.053 289.391 198.937 292.026 198.937 295.086V300.626H204.356H209.813L209.697 294.892L209.581 289.198L204.51 289.081C201.762 289.043 199.363 289.12 199.208 289.236Z"
				fill="#034185"
			/>
			<path
				d="M138.83 307.444C135.037 308.142 130.393 309.924 127.49 311.822C123.929 314.146 123.813 314.34 123.929 318.098L124.045 321.352H128.109H132.173L132.289 319.493L132.405 317.594L134.805 316.471C137.94 315.037 141.152 314.418 144.597 314.689C150.325 315.115 152.686 317.556 153.15 323.56C153.421 327.047 153.576 326.969 150.79 324.955C148.158 323.057 144.403 322.127 139.333 322.166C134.534 322.166 131.902 322.747 128.38 324.529C119.672 328.906 117.891 339.754 124.819 346.301C125.903 347.347 127.722 348.664 128.883 349.245C135.927 352.809 146.532 351.647 151.873 346.727C152.841 345.797 153.654 345.139 153.692 345.216C153.731 345.294 153.925 346.417 154.195 347.696L154.621 350.02H159.266H163.91L163.794 347.967C163.717 346.843 163.523 339.521 163.368 331.696L163.097 317.478L162.014 315.037C159.459 309.459 153.847 306.786 144.945 306.863C143.242 306.863 140.494 307.134 138.83 307.444ZM148.932 331.967C151.215 332.974 153.267 335.066 153.267 336.383C153.267 338.049 151.873 339.638 149.241 340.916C139.914 345.526 126.213 338.979 133.295 333.284C135.734 331.347 137.862 330.805 142.623 330.96C146.068 331.037 147.267 331.27 148.932 331.967Z"
				fill="#034185"
			/>
			<path
				d="M289.697 307.406C284.743 308.335 278.628 311.008 276.034 313.41C274.796 314.495 274.796 314.573 274.796 318.02V321.546H278.86H282.924V319.648V317.711L285.362 316.587C291.361 313.72 298.521 313.953 301.618 317.052C303.166 318.602 303.824 320.655 303.824 324.064V326.698L302.972 325.885C300.65 323.754 294.806 321.933 290.006 321.933C287.026 321.933 282.498 322.902 279.944 324.103C276.963 325.497 273.557 328.79 272.435 331.347C271.08 334.369 271.119 339.134 272.551 342.194C274.525 346.34 278.782 349.516 284.162 350.795C290.819 352.344 298.908 350.524 302.895 346.495L304.559 344.868L304.791 346.65C305.256 350.291 305.178 350.214 310.132 350.214H314.506L314.39 338.475C314.196 319.57 314.119 318.137 313.151 315.696C311.951 312.558 310.055 310.582 306.804 308.955C302.43 306.863 295.851 306.243 289.697 307.406ZM300.031 332.122C302.74 333.478 303.824 334.718 303.824 336.616C303.824 338.359 302.237 340.064 299.373 341.381C297.709 342.156 296.818 342.272 292.987 342.272C289.155 342.272 288.265 342.117 286.484 341.342C284.124 340.296 282.15 338.204 282.15 336.81C282.15 335.299 283.156 333.71 284.665 332.781C287.181 331.231 289.039 330.844 293.567 330.96C297.36 331.037 298.057 331.153 300.031 332.122Z"
				fill="#034185"
			/>
			<path
				d="M391.41 307.406C384.173 308.994 377.98 315.115 376.006 322.592C375.194 325.575 375.232 332.277 376.006 335.26C380.031 350.446 397.332 356.257 409.64 346.572C411.072 345.41 414.129 341.846 414.129 341.303C414.129 341.148 412.272 339.947 410.027 338.63L405.963 336.228L403.641 338.359C401.125 340.722 399.654 341.303 396.21 341.303C393.655 341.303 391.875 340.645 389.746 338.979C388.159 337.739 386.263 334.408 386.263 332.897V332.006L401.86 331.928L417.419 331.812L417.381 328.868C417.303 324.877 416.684 322.398 414.671 318.137C413.433 315.58 412.349 313.953 410.762 312.403C406.079 307.677 398.803 305.817 391.41 307.406ZM401.241 317.749C403.138 318.64 405.034 320.655 406.04 322.863L406.737 324.451L396.635 324.568C391.062 324.606 386.456 324.568 386.379 324.49C386.108 324.219 387.85 321.003 388.933 319.88C391.836 316.703 397.139 315.812 401.241 317.749Z"
				fill="#034185"
			/>
			<path
				d="M68.7764 308.219C68.6216 308.374 68.5055 310.195 68.5055 312.287C68.5055 316.587 68.4281 316.51 72.0275 316.51H74.3111V327.667C74.3111 334.098 74.5046 339.831 74.7368 341.148C75.3174 344.364 76.7881 347.269 78.6846 348.858C83.4451 352.926 93.5081 351.996 99.7394 346.882C100.63 346.146 101.404 345.565 101.52 345.565C101.636 345.565 101.829 346.378 101.984 347.386C102.139 348.432 102.449 349.478 102.642 349.71C103.107 350.291 112.821 350.446 113.17 349.865C113.286 349.71 113.17 348.16 112.937 346.495C112.744 344.829 112.434 335.492 112.279 325.807L112.047 308.18L103.803 308.064L95.5594 307.987L95.6755 312.132L95.7916 316.316L98.617 316.432L101.442 316.548L101.326 325.536C101.21 333.943 101.171 334.64 100.359 336.112C97.6494 341.226 89.5603 343.395 86.2705 339.909L85.3416 338.902L85.1481 323.56L84.9546 308.18L76.9816 308.064C72.6081 308.025 68.9312 308.103 68.7764 308.219Z"
				fill="#034185"
			/>
			<path
				d="M192.977 308.606C192.551 309.73 192.706 315.541 193.209 316.045C193.48 316.316 194.757 316.51 196.305 316.51H198.937L199.014 333.245L199.13 350.02H204.355H209.58V329.1V308.18L201.414 308.064C194.176 307.987 193.209 308.064 192.977 308.606Z"
				fill="#034185"
			/>
			<path
				d="M18.5389 362.223C14.4363 362.959 11.1465 365.206 9.59835 368.344C8.39853 370.824 8.39853 375.279 9.55964 377.526C11.1465 380.548 13.8945 382.446 19.9322 384.693C26.7828 387.25 28.9889 388.877 29.8017 392.015C30.8467 395.966 28.8728 399.957 25.0024 401.545C23.0672 402.358 18.3067 402.358 15.8683 401.545C14.8233 401.196 13.1978 400.228 12.2302 399.375L10.4498 397.826L8.86297 398.717L7.27612 399.608L9.59835 401.777C13.0817 405.07 17.8035 406.387 23.2608 405.613C26.6667 405.109 29.2985 403.792 31.2337 401.584C33.0528 399.492 33.8656 397.09 33.8269 393.758C33.7882 390.543 32.8206 388.179 30.7693 386.281C28.7567 384.422 27.2085 383.608 22.7576 382.058C18.0358 380.393 15.8296 379.192 14.2428 377.41C11.8819 374.775 11.8819 371.172 14.2428 368.344C15.7909 366.524 17.9196 365.71 21.2482 365.71C24.8476 365.71 26.7054 366.252 28.6406 367.918C30.15 369.235 30.1887 369.313 30.1887 371.753V374.272L31.6595 374.155L33.0915 374.039L33.2076 370.707L33.3237 367.376L31.4272 365.632C28.4083 362.804 23.1059 361.41 18.5389 362.223Z"
				fill="#707070"
			/>
			<path
				d="M394.661 362.223C391.023 362.843 387.501 365.245 386.03 368.151C385.14 369.855 384.869 374.155 385.527 376.441C386.418 379.734 390.675 382.949 396.713 384.886C404.105 387.288 406.776 390.116 406.273 395.036C405.847 399.027 403.215 401.545 398.919 402.087C394.661 402.63 390.404 401.39 388.082 398.911L386.998 397.748L385.643 398.484C384.869 398.872 384.173 399.337 384.095 399.492C383.708 400.111 386.882 402.94 389.127 404.063C395.126 407.007 404.028 406 407.395 402.01C411.033 397.671 411.149 390.388 407.627 386.669C406.079 385.041 402.557 383.221 397.371 381.4C393.733 380.121 391.062 378.417 389.823 376.557C388.585 374.698 388.701 370.785 390.094 368.848C391.488 366.911 394.352 365.71 397.68 365.71C401.28 365.71 403.021 366.252 404.879 367.918C406.35 369.235 406.389 369.352 406.389 371.792V374.272L407.859 374.155L409.291 374.039L409.408 370.591L409.524 367.182L408.014 365.749C406.156 363.967 403.757 362.804 400.777 362.262C398.261 361.797 397.371 361.797 394.661 362.223Z"
				fill="#707070"
			/>
			<path
				d="M226.804 363.114C221.966 364.78 218.753 367.298 215.967 371.56C211.4 378.649 211.516 388.799 216.315 396.16C220.186 402.203 226.881 405.768 234.351 405.729C240.157 405.729 245.807 403.521 249.446 399.802C251.497 397.748 251.458 397.245 249.291 396.354C248.323 395.927 248.091 396.005 246.504 397.477C245.575 398.329 243.524 399.685 241.937 400.499C239.421 401.739 238.686 401.932 235.512 402.048C227.771 402.397 221.966 399.027 218.676 392.286C213.916 382.601 217.205 371.676 226.185 367.26C228.623 366.059 229.281 365.904 232.803 365.787C236.209 365.632 237.06 365.749 239.731 366.64C243.098 367.802 245.42 369.235 247.239 371.327C248.439 372.644 248.478 372.877 248.478 376.015V379.308L249.949 379.192L251.381 379.075L251.497 374.891L251.613 370.669L249.097 368.228C244.917 364.122 239.847 362.223 233.151 362.223C230.21 362.223 228.739 362.456 226.804 363.114Z"
				fill="#707070"
			/>
			<path
				d="M38.3167 364.354V365.71H40.8324H43.3481V385.468V405.225L55.0754 405.148L66.7639 405.032V403.482V401.932L57.0105 401.816L47.2185 401.739V393.603V385.468L56.0429 385.39L64.8287 385.274V383.531V381.787L56.0429 381.671L47.2185 381.594V374.039V366.485L56.6235 366.407L65.9898 366.291L66.1059 364.625L66.222 362.998H52.2887H38.3167V364.354Z"
				fill="#707070"
			/>
			<path
				d="M71.2146 364.315V365.671L73.6529 365.787L76.0526 365.904L76.1687 385.584L76.2461 405.264L78.1039 405.148L79.9229 405.031L80.1165 386.436L80.31 367.841L88.5926 379.385L96.9139 390.891H98.9265H100.939L109.222 379.424L117.466 367.996L117.659 386.514L117.853 405.031H119.594H121.336L121.452 383.995L121.529 362.998H119.052H116.537L108.409 374.349C103.919 380.625 99.9327 386.126 99.5457 386.63L98.8103 387.521L90.1407 375.356L81.4324 363.192L76.3235 363.076L71.2146 362.959V364.315Z"
				fill="#707070"
			/>
			<path
				d="M144.752 364.354V365.71H147.267H149.783L149.861 385.351L149.977 405.032L159.962 405.148L169.909 405.225V403.482V401.739H161.781H153.654V382.368V362.998H149.203H144.752V364.354Z"
				fill="#707070"
			/>
			<path
				d="M181.907 364.354V365.71H184.423C185.817 365.71 186.939 365.787 186.939 365.865C186.939 365.942 183.378 374.698 179.005 385.274C174.67 395.889 171.187 404.683 171.303 404.877C171.612 405.419 174.476 405.303 174.825 404.722C174.979 404.489 176.257 401.467 177.65 398.058L180.166 391.86L190.074 391.744L199.982 391.666L202.614 398.136C204.085 401.739 205.478 404.76 205.672 404.954C206.407 405.458 209.194 405.109 209.077 404.528C208.961 403.869 192.899 364.819 192.357 363.773C191.97 363.076 191.506 362.998 186.939 362.998H181.907V364.354ZM194.486 377.913C196.692 383.414 198.473 387.986 198.395 388.063C198.318 388.141 194.525 388.141 189.958 388.102L181.637 387.986L185.468 378.688C190.074 367.376 190.035 367.492 190.229 367.724C190.345 367.802 192.241 372.412 194.486 377.913Z"
				fill="#707070"
			/>
			<path
				d="M255.057 364.354V365.71H257.534H260.05L260.166 380.315L260.282 394.959L261.521 397.555C263.649 402.049 267.133 404.722 272.087 405.613C278.899 406.852 285.13 403.831 288.033 397.942L289.31 395.346L289.426 379.192L289.542 362.998H287.568H285.633V378.029C285.633 395.734 285.594 396.044 282.421 399.182C280.098 401.506 278.241 402.203 274.448 402.165C269.726 402.126 265.894 399.22 264.54 394.688C264.075 393.061 263.959 390.039 263.959 377.836V362.998H259.508H255.057V364.354Z"
				fill="#707070"
			/>
			<path
				d="M295.696 364.354V365.71H298.212H300.728L300.805 385.351L300.921 405.031H302.663H304.405L304.521 386.514C304.559 376.364 304.753 368.034 304.908 368.034C305.063 368.034 310.752 375.937 317.564 385.545C324.376 395.153 330.375 403.521 330.878 404.14C331.729 405.186 331.962 405.264 334.168 405.148L336.529 405.031L336.645 383.995L336.722 362.998H334.787H332.852V381.671C332.852 395.656 332.736 400.228 332.426 399.918C332.155 399.647 326.195 391.317 319.112 381.322L306.262 363.192L300.999 363.076L295.696 362.959V364.354Z"
				fill="#707070"
			/>
			<path
				d="M353.365 364.354V365.71H355.919C358.242 365.71 358.435 365.749 358.203 366.485C358.087 366.872 354.487 375.627 350.269 385.932C346.05 396.199 342.683 404.76 342.76 404.915C343.147 405.535 346.011 405.225 346.437 404.528C346.669 404.14 347.946 401.119 349.262 397.826L351.662 391.86H361.648H371.633L374.11 398.019C375.503 401.429 376.819 404.45 377.052 404.722C377.477 405.264 380.303 405.419 380.612 404.915C380.767 404.683 364.434 364.625 363.699 363.463C363.467 363.153 361.957 362.998 358.358 362.998H353.365V364.354ZM366.292 378.61C368.343 383.647 370.008 387.869 370.008 387.986C370.008 388.102 366.253 388.179 361.686 388.179C357.119 388.179 353.365 388.024 353.365 387.869C353.365 387.676 355.068 383.376 357.158 378.262C359.248 373.187 361.106 368.654 361.26 368.228C361.531 367.492 361.57 367.492 362.073 368.422C362.344 368.964 364.241 373.535 366.292 378.61Z"
				fill="#707070"
			/>
		</svg>
	)
}

export default QSLIcon
