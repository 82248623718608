import User from 'models/User'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { updateUser } from 'services'
import RenewPassForm from 'shared/RenewPass'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import { updateUserInfo } from 'store/modules/user/actions'
import LockIcon from './ConfigUserModalLockIcon'
import './styles.scss'

interface Props {
	onUpdateUser: (userInfo: User) => void
	onClose: () => void
}

const ConfigUserModal: React.FC<Props> = ({ onUpdateUser, onClose }) => {
	const [loading, setLoading] = useState<boolean>(false)
	const [editMode, setEditMode] = useState<boolean>(false)
	const [showRenewPassContent, setShowRenewPassContent] =
		useState<boolean>(false)
	const dispatch = useDispatch()

	const user = store.getState().user
	const userName = user.name
	const userSurname = user.surname
	const userPhone = user.cel
	const userEmail = user.email
	const userRegister = user.register
	const userDepartment = user.department

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<User>({
		defaultValues: {
			name: user.name,
			surname: user.surname,
			cel: user.cel,
			email: user.email,
			register: user.register,
			department: user.department
		}
	})

	const handleEditMode = () => {
		setEditMode(!editMode)
	}

	const onSubmitInfo = (userData: any) => {
		setLoading(true)
		updateUser(userData, user.profile)
			.then((response) => {
				const { data } = response
				setLoading(false)
				if (data.name === 'ValidationError') {
					toast.error('Houve um erro ao atualizar os dados do usuário!')
				} else {
					setEditMode(false)
					dispatch(
						updateUserInfo(
							data.userUpdated.name,
							data.userUpdated.surname,
							data.userUpdated.cel,
							data.userUpdated.email,
							data.userUpdated.lastVersion
						)
					)
					onUpdateUser(data)
					toast.success('Dados editados com sucesso!')
				}
			})
			.catch((error) => {
				setLoading(false)
				toast.error('Houve um erro ao atualizar os dados do usuário!')
			})
	}

	const userInfoContain = () => {
		return (
			<>
				<div className="config-user-main-content">
					<div className="config-user-info">
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Matrícula:</span>
							<span className="user-information">{userRegister}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Área:</span>
							<span className="user-information">{userDepartment}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">E-mail:</span>
							<span className="user-information">{userEmail}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Nome:</span>
							<span className="user-information">{userName}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Sobrenome:</span>
							<span className="user-information">{userSurname}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Celular:</span>
							<span className="user-information">{userPhone}</span>
						</div>
					</div>
					<button
						className="change-password-action"
						type="submit"
						onClick={() => setShowRenewPassContent(true)}
					>
						<LockIcon />
						Alterar Senha
					</button>
				</div>
				<div className="config-user-actions">
					<Button
						title="EDITAR"
						size="lg"
						variant="confirmation-gradient"
						onClick={handleEditMode}
					/>
				</div>
			</>
		)
	}

	const editUserInfoContain = () => {
		return (
			<>
				<div className="config-user-main-content">
					<div className="config-user-info">
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Matrícula</span>
							<span className="user-information">{userRegister}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Área</span>
							<span className="user-information">{userDepartment}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">E-mail</span>
							<span className="user-information">{userEmail}</span>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Nome</span>
							<Input
								name="name"
								variant="light"
								type="text"
								errors={errors.name}
								innerRef={{
									...register('name', {
										required: {
											value: true,
											message: '*O campo Nome é obrigatório.'
										}
									})
								}}
							/>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Sobrenome</span>
							<Input
								name="surname"
								variant="light"
								type="text"
								errors={errors.surname}
								innerRef={{
									...register('surname', {
										required: {
											value: true,
											message: '*O campo Sobrenome é obrigatório.'
										}
									})
								}}
							/>
						</div>
						<div className="config-user-info-item">
							<span className="user-info-descriptive">Celular</span>
							<Input
								name="cel"
								variant="light"
								type="text"
								errors={errors.cel}
								innerRef={{
									...register('cel', {
										required: {
											value: true,
											message: '*O campo Celular é obrigatório.'
										}
									})
								}}
							/>
						</div>
					</div>
				</div>
				<div className="config-user-actions">
					<Button
						title="SALVAR"
						size="lg"
						variant="confirmation-gradient"
						onClick={handleSubmit(onSubmitInfo)}
					/>
					<Button
						title="CANCELAR"
						size="lg"
						variant="cancellation-gradient"
						onClick={handleEditMode}
					/>
				</div>
			</>
		)
	}

	return (
		<Modal show={true} title={'DADOS CADASTRAIS'} onClose={onClose} showClose>
			{editMode ? editUserInfoContain() : userInfoContain()}
			<RenewPassForm
				show={showRenewPassContent}
				email={user.email}
				handleCancel={() => setShowRenewPassContent(false)}
			/>
			<LoadingProgress show={loading} text="..." />
		</Modal>
	)
}

export default ConfigUserModal
