import produce from 'immer'

const INITIAL_STATE = {
	profile: null,
	lastVersion: null,
	roles: [],
	menuShow: null,
	tutorials: [],
	modalStatus: null,
	listTransferParams: null
}

export default function user(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@auth/SIGN_IN_SUCCESS': {
				draft.profile = action?.payload?.user
				draft.name = action?.payload?.name
				draft.lastVersion = action?.payload?.lastVersion
				draft.email = action?.payload?.email
				draft.cel = action?.payload?.cel
				draft.register = action?.payload?.register
				draft.department = action?.payload?.department?.description
				draft.depCode = action?.payload?.department?.code_protheus
				draft.surname = action?.payload?.surname
				draft.tutorials = action?.payload?.tutorials
				break
			}
			case '@auth/SIGN_OUT': {
				draft.profile = null
				draft.name = null
				draft.lastVersion = null
				draft.surname = null
				draft.email = null
				draft.cel = null
				draft.register = null
				draft.department = null
				draft.depCode = null
				draft.roles = null
				draft.listTransferParams = null
				draft.tutorials = null
				break
			}
			case '@user/UPDATE_USER_INFO': {
				draft.name = action?.payload?.name
				draft.email = action?.payload?.email
				draft.lastVersion = action?.payload?.lastVersion
				draft.cel = action?.payload?.cel
				draft.surname = action?.payload?.surname
				break
			}
			case '@user/MODAL_STATUS': {
				draft.modalStatus = action?.payload?.modalStatus
				break
			}
			case '@user/SAVE_ROLES':
				draft.roles = action?.payload?.roles
				break
			case '@user/UPDATE_TRANSFER_LIST_PARAMS':
				draft.listTransferParams = action?.payload?.listTransferParams
				break
			case '@user/UPDATE_TUTORIALS':
				draft.tutorials = action?.payload?.tutorials
				break
			case '@user/UPDATE_MENU_SHOW_STATUS':
				draft.menuShow = action?.payload?.status
				break
			default:
				return state
		}
	})
}
