import React from 'react'
import './styles.scss'

interface Props extends React.ComponentPropsWithoutRef<'textarea'> {
	innerRef?: any
}

export const TextArea = ({ innerRef, ...rest }: Props) => {
	return (
		<textarea
			{...innerRef}
			{...rest}
			className="textarea-install-and-search"
		></textarea>
	)
}
