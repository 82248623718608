import RequestResponse from 'models/RequestResponse'
import { Transfer, TransferUpdate } from 'models/Transfer'
import ClientContext from 'module/technicalServices/context/Clients/ClientContext/ClientContext'
import EquipmentContext from 'module/technicalServices/context/Equipments/EquipmentContext/EquipmentContext'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import LoadingProgress from 'shared/components/LoadingProgress'
import TransferEditClient from './TransferEditClient'
import TransferEditEquipment from './TransferEditEquipment'
import './styles.scss'

interface Props {
	transfer: Transfer
	type: 'Equipment' | 'Client'
	onUpdateComplete: (transfer?: Transfer) => void
}

const TransferEdit: React.FC<Props> = ({
	transfer,
	type,
	onUpdateComplete
}) => {
	const { onTransferUpdate } = useContext(TransfersDetailsContextElement)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onUpdate = (transferUpdate: TransferUpdate) => {
		setIsLoading(true)
		onTransferUpdate(transferUpdate, onCompleted)
	}

	const onCompleted = (request: RequestResponse) => {
		setIsLoading(false)
		let message = ''
		if (request.reportError) {
			switch (type) {
				case 'Equipment':
					message = 'Erro ao alterar equipamento.'
					break
				case 'Client':
					message = 'Erro ao alterar client.'
					break
			}
		} else {
			message = 'Transferencia alterada com sucesso!'
			onUpdateComplete(request.response)
		}

		toast.success(message)
	}

	const formContent = () => {
		switch (type) {
			case 'Equipment':
				return (
					<EquipmentContext>
						<TransferEditEquipment {...{ transfer, onUpdate }} />
					</EquipmentContext>
				)
			case 'Client':
				return (
					<ClientContext>
						<TransferEditClient {...{ transfer, onUpdate }} />
					</ClientContext>
				)
		}
	}

	const loadingContent = () => {
		return <LoadingProgress show={isLoading} text="Carregando..." />
	}

	return (
		<>
			<section className="transfer-edit-form-content">{formContent()}</section>
			{loadingContent()}
		</>
	)
}

export default TransferEdit
