import styled from 'styled-components'

export const Wrapper = styled.div`
	height: 100%;
`

export const Content = styled.div`
	button {
		margin-top: 20px;
		display: inline-block;
		padding: 9px 15px;
		background-color: #3898ec;
		color: white;
		border: 0;
		line-height: inherit;
		text-decoration: none;
		cursor: pointer;
		border-radius: 0;
		-webkit-appearance: button;
	}
`
