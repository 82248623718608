import { Tooltip } from 'module/orderManagement/components/Tooltip'
import { EquipmentLegendStatus } from 'module/technicalServices/utils/constants'
import React from 'react'
import { FaCircle } from 'react-icons/fa'
import { getClientInfo, getLastTechnician } from 'services'
import Modal from 'shared/components/Modal'
import { Filial, handleOptionTpRequest, linkSelect, toTitle } from './functions'
import PartInfo from './info'
import Itens from './itens'
import {
	Cliente,
	Cnpj,
	ColInfo,
	ColItem,
	ColResumo,
	Description,
	Div,
	Div1,
	Equipment,
	Fil,
	Header,
	Info,
	ItensSelect,
	Pendecia,
	RequestOSInfo,
	RequestPanel,
	Serial,
	TipoSol
} from './styles'
import './styles.css'
import Summary from './summary'

interface state {
	enablesummary: any
	lastTechId: string
	techAddress: string
	enableRender: boolean
}

interface Props {
	equipment: any
	client: any
	serviceOrderNumber: any
	type: any
	osProtheus?: string
	handleChanges: (type: any, changes: any, worked: boolean) => void
	handleCancel: () => void
}

class PartRequest extends React.Component<Props, state> {
	constructor(props: Props) {
		super(props)

		this.state = {
			enablesummary: false,
			lastTechId: '',
			techAddress: '',
			enableRender: true
		}
	}

	render() {
		const { equipment } = this.props
		const { client } = this.props
		const { serviceOrderNumber } = this.props
		const { type } = this.props
		const { osProtheus } = this.props
		const clientName = `DO CLIENTE ${client?.nome_fantasia}`

		$('#obs').val(
			`NOTA FISCAL REF A OS ${serviceOrderNumber} / ${
				osProtheus ? osProtheus : ''
			} ${clientName}`
		)

		const { handleChanges } = this.props
		const { handleCancel } = this.props

		const cnpj = client?.cpfCnpj.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
			'$1.$2.$3/$4-$5'
		)
		const codProtheus = client?.cod_protheus
		let typePart = ''
		let filial = ''
		const client_address = `Cliente: ${toTitle(client?.street)} - ${toTitle(
			client?.city
		)}, ${client?.state}`

		const clientCode = ('000000' + client?.cod_protheus).slice(-6)

		if (this.state.enableRender) {
			getClientInfo(clientCode).then((resp) => {
				const pendency = resp.data.pendency
				if (resp.status === 200) {
					if (pendency === 'S') {
						$(`.pendency`).css('display', 'block')
					}
				}
			})

			getLastTechnician(serviceOrderNumber).then((resp) => {
				if (resp.status === 200) {
					const technician = resp.data[0]
					if (technician) {
						this.setState({ lastTechId: technician?.code_prp })
						this.setState({
							techAddress: `Técnico: ${toTitle(technician?.address)}, ${toTitle(
								technician?.city
							)} - ${toTitle(technician?.state)}`
						})
					} else {
						$(`#tech-address`).css('display', 'none')
					}
					this.setState({ enableRender: false })
				}
			})
		}

		const onClose = () => {
			$(`.newPart`).css('display', 'none')
			$('.actionOpt').css('display', 'none')
			handleCancel()

			document.location.reload()
		}

		return (
			<Modal
				show={true}
				onClose={onClose}
				title="Solicitação de peça"
				showClose={true}
			>
				<div className="service-order-part-request">
					<RequestOSInfo>
						<Equipment>
							{equipment?.legend &&
								EquipmentLegendStatus.map((legend) => {
									return (
										legend?.value === equipment?.legend && (
											<Tooltip info={legend?.label} mobile={false}>
												<FaCircle color={legend?.color} size={15} />
											</Tooltip>
										)
									)
								})}
							{equipment?.name}
						</Equipment>
						<Serial>
							<Description>Número de Série:</Description>
							<Info id="serial_number">{equipment?.serial_number}</Info>
						</Serial>
						<Div>
							<Cliente>
								<Description>Cliente:</Description>
								<Info id="clientName">{`${client?.cod_protheus} - ${client?.nome_fantasia}`}</Info>
							</Cliente>
							<Cnpj>
								<Description>CNPJ:</Description>
								<Info id="cnpj">{cnpj}</Info>

								<Info id="codProtheus" style={{ display: 'none' }}>
									{codProtheus}
								</Info>
								<Info id="lastTechID" style={{ display: 'none' }}>
									{this.state.lastTechId}
								</Info>
							</Cnpj>
							<TipoSol>
								<Description>Tipo de solicitação:</Description>
								<ItensSelect>
									<select id="tpPart" name="tpPart" className="w-select_">
										{handleOptionTpRequest(type).map((item) => (
											<option key={item.value} value={item.value}>
												{(typePart = item.label)}
											</option>
										))}
									</select>
								</ItensSelect>
							</TipoSol>
							<Fil>
								<Description>Filial:</Description>
								<ItensSelect>
									<select id="filial" name="filial" className="w-select_">
										{Filial.map((item) => (
											<option key={item.value} value={item.value}>
												{(filial = item.label)}
											</option>
										))}
									</select>
								</ItensSelect>
							</Fil>
						</Div>
						<Div className="pendency" style={{ display: 'none' }}>
							<Pendecia>
								<Info>Pendência financeira</Info>
							</Pendecia>
						</Div>
					</RequestOSInfo>
					<RequestPanel>
						<Header>
							<ColInfo
								id="link1"
								className="LinkSelect_"
								onClick={() => {
									linkSelect(1)
								}}
							>
								<Div1>Informações gerais</Div1>
							</ColInfo>
							<ColItem
								id="link2"
								onClick={(typePart) => {
									linkSelect(2)
								}}
							>
								<Div1>Itens</Div1>
							</ColItem>
							<ColResumo
								id="link3"
								onClick={() => {
									this.setState({ enablesummary: linkSelect(3) })
								}}
							>
								<Div1>Resumo</Div1>
							</ColResumo>
						</Header>

						<Div className={`part-info`} style={{ display: 'inline' }}>
							<PartInfo
								client_address={client_address}
								lastTechAddress={this.state.techAddress}
							/>
						</Div>

						<Div className={`new-item`} style={{ display: 'none' }}>
							<Itens type={type} />
						</Div>

						{this.state.enablesummary && (
							<Div className={`summary`} style={{ display: 'block' }}>
								<Summary handleChanges={handleChanges} />
							</Div>
						)}
					</RequestPanel>
				</div>
			</Modal>
		)
	}
}
export default PartRequest
