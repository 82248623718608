import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'

export type FilterField = 'filter' | 'limit' | 'page' | 'search' | 'count'
export type FilterValue = number | string | FilterData

export const INITIAL_PAGE = 1
export const DEFAULT_LIMIT = 10

/**
 * @description Return a clear or saved list params.
 * @param storedListParams Saved List Params Object
 * @param clearAll Flag to clear all values
 * @returns Returns a new List Params Object
 */
export function defaultListParams(
	storedListParams?: ListParams,
	clearAll = true
): ListParams {
	if (!clearAll) {
		return {
			pageLimit: storedListParams?.pageLimit || DEFAULT_LIMIT,
			currentPage: storedListParams?.currentPage || INITIAL_PAGE,
			totalPages: storedListParams?.totalPages || INITIAL_PAGE,
			itemFilter: storedListParams?.itemFilter || undefined
		}
	} else {
		return {
			pageLimit: DEFAULT_LIMIT,
			currentPage: INITIAL_PAGE,
			totalPages: INITIAL_PAGE
		}
	}
}

/**
 * @description Update a List Params Object
 * @param value Value to update in object
 * @param field Field to be updated
 * @param listParams List Params Object to be updated
 * @returns Returns a new List Params Object
 */
export const updateListParams = (
	value: FilterValue,
	field: FilterField,
	listParams: ListParams
) => {
	const newListParams = { ...listParams }

	switch (field) {
		case 'filter':
			newListParams.itemFilter = value as FilterData
			break
		case 'limit':
			newListParams.pageLimit = value as number
			break
		case 'page':
			newListParams.currentPage = value as number
			break
		case 'count':
			newListParams.totalPages = value as number
			break
		case 'search':
			newListParams.searchValue = value as string
			break
	}

	return newListParams
}

/**
 * @description Clear basic attributes from List Params Object
 * @param listParams List Params Object
 * @param clearAll Flag to clear all values
 * @returns Returns a new List Params Object
 */
export const clearListParams = (listParams: ListParams, clearAll = false) => {
	if (clearAll) {
		return defaultListParams()
	} else {
		const newListParams = { ...listParams }
		newListParams.currentPage = INITIAL_PAGE
		newListParams.totalPages = INITIAL_PAGE
		return newListParams
	}
}
