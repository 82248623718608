import { Checkbox, FormControlLabel } from '@material-ui/core'
import React, { useState } from 'react'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import Modal from 'shared/components/Modal'
import { osTypes, osTypesChefs } from 'utils/os-types.constants'

interface Props {
	show: boolean
	userSelectedOsTypes: string[]
	onSubmit: (selectedZones: string[]) => void
	onClose: () => void
}

const notAllowedTypes = [
	'',
	'PREVENTIVA',
	'CORRETIVA PREVENTIVA',
	'FORA DE GARANTIA'
]

const OrderTypesList: React.FC<Props> = ({
	show,
	userSelectedOsTypes,
	onSubmit,
	onClose
}) => {
	const [selectedOsTypes, setSelectedOsTypes] =
		useState<string[]>(userSelectedOsTypes)
	const notificationsOrdersTypes = osTypes.filter((i) => {
		if (!notAllowedTypes.includes(i.value)) {
			return i
		}
	})
	const notificationsOrdersChefsTypes = osTypesChefs.filter((i) => {
		if (!notAllowedTypes.includes(i.value)) {
			return i
		}
	})

	const handleSubmit = () => {
		onSubmit(selectedOsTypes)
		onClose()
	}

	const notificationsOsTypesOptions = (
		options: { value: string; label: string }[]
	) => {
		return (
			<>
				{options.map((osType, index) => {
					return (
						<FormControlLabel
							key={index}
							className="checkbox-item"
							label={osType.label}
							control={
								<Checkbox
									color="secondary"
									onClick={() => {
										handleSelectionOsTypes(osType.value)
									}}
									checked={selectedOsTypes.includes(osType.value)}
									value={osType}
								/>
							}
						/>
					)
				})}
			</>
		)
	}

	const handleSelectionOsTypes = (itemSelected: string) => {
		const currentIndex = selectedOsTypes.findIndex((i) => i === itemSelected)
		const newSelectedList = [...selectedOsTypes]

		if (currentIndex === -1) {
			newSelectedList.push(itemSelected)
		} else {
			newSelectedList.splice(currentIndex, 1)
		}

		setSelectedOsTypes(newSelectedList)
	}

	const handleAllChecks = (
		types: {
			value: string
			label: string
		}[]
	) => {
		const wildCardLabel =
			selectedOsTypes?.length === types?.length
				? 'Desmarcar todos'
				: 'Marcar todos'

		return (
			<div className="wildcard-item-option">
				<FormControlLabel
					className="checkbox-item"
					control={
						<Checkbox
							color="secondary"
							onClick={() => checkAllItems(types)}
							checked={selectedOsTypes?.length === types?.length}
						/>
					}
					label={wildCardLabel}
				/>
			</div>
		)
	}

	const checkAllItems = (
		types: {
			value: string
			label: string
		}[]
	) => {
		if (selectedOsTypes?.length === types?.length) {
			setSelectedOsTypes([])
		} else {
			const newSelectedList = types.map((osType) => osType.value)
			setSelectedOsTypes(newSelectedList)
		}
	}

	return (
		<Modal
			title="Selecionar Tipos de Atendimentos"
			show={show}
			showClose={true}
			onClose={onClose}
		>
			<p>Marque os tipos de atendimentos que deseja acompanhar:</p>
			<div className="notifications-config-main-contain-select">
				<Divider title="Serviços técnicos" />
				<div className="notifications-types-list">
					{notificationsOsTypesOptions(notificationsOrdersTypes)}
					{handleAllChecks(notificationsOrdersTypes)}
				</div>
				<Divider title="Serviços canal chef:" />
				<div className="notifications-types-chefs-list">
					{notificationsOsTypesOptions(notificationsOrdersChefsTypes)}
					{handleAllChecks(notificationsOrdersChefsTypes)}
				</div>
			</div>
			<div className="notifications-config-footer-contain">
				<Button
					style={{ width: '126px' }}
					size="lg"
					title="Confirmar"
					variant="confirmation-gradient"
					disabled={!selectedOsTypes?.length}
					onClick={handleSubmit}
				/>
			</div>
		</Modal>
	)
}

export default OrderTypesList
