import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import OrderMenuTabsMobile from './Mobile'
import OrderMenuTabsWeb from './Web'

type TParams = {
	path: string
}
const OrderMenuTabs = ({ path }: TParams) => {
	const { viewType } = useContext(DeviceContextElement)
	return (
		<>
			{viewType !== ViewType.MOBILE ? (
				<OrderMenuTabsWeb path={path} />
			) : (
				<OrderMenuTabsMobile path={path} />
			)}
		</>
	)
}

export default OrderMenuTabs
