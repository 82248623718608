import React, { ReactNode } from 'react'
import Section from 'shared/components/Section'
import './styles.scss'

type Props = {
	children: ReactNode
}

export const OrderItemsSectionRoot = ({ children }: Props) => (
	<Section title="Itens do pedido">{children}</Section>
)
