import { z } from 'zod'

export const updateClientJacSchema = z.object({
	id: z.number().min(1),
	name: z.string().min(1, 'O campo nome é obrigatório!'),
	email: z.string().email('O campo email é obrigatório!'),
	phone: z.string().min(1, 'O campo telefone é obrigatório!'),
	emailGestor: z.string().min(1, 'O campo email de gestor é obrigatório!'),
	username: z.string().nullable(),
	store: z.string().array().min(1, { message: 'O campo loja é obrigatório!' })
})

export type updateClientJacSchema = z.infer<typeof updateClientJacSchema>

export const createClientJacSchema = z.object({
	name: z.string().min(1, 'O campo nome é obrigatório!'),
	email: z.string().email('O campo email é obrigatório!'),
	phone: z.string().min(1, 'O campo telefone é obrigatório!'),
	emailGestor: z.string().min(1, 'O campo email de gestor é obrigatório!'),
	username: z.string().nullable(),
	store: z.string().array().min(1, { message: 'O campo loja é obrigatório!' })
})

export type createClientJacSchema = z.infer<typeof createClientJacSchema>
