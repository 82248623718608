import { FederalStatesList } from 'module/orderManagement/utils/constants'
import React from 'react'
import Select from 'shared/components/Select'
import { store } from 'store'
import './styles.scss'

interface Props {
	salesPersonCode?: string[]
	onSelectSalesPerson: (selectOptions: any[]) => void
	federatedState?: string[]
	onSelectFederalState: (selectOptions: any[]) => void
}

const FilterFields = ({
	onSelectSalesPerson,
	salesPersonCode,
	onSelectFederalState,
	federatedState
}: Props) => {
	const { salespersonList } = store.getState().baseLists

	const mainContent = () => {
		return salesPersonCode && federatedState ? (
			<div className="client-filter-fields">
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label htmlFor="salesperson-select">
							Representante
							<Select
								id="salesperson-select"
								options={salespersonList}
								checkbox={true}
								selectedOption={salesPersonCode}
								onSelectOptions={onSelectSalesPerson}
							/>
						</label>
					</div>
					<div className="filter-fields-section-option">
						<label htmlFor="federal-state-select">
							Estado
							<Select
								checkbox={true}
								id="federal-state-select"
								options={FederalStatesList}
								selectedOption={federatedState}
								onSelectOptions={onSelectFederalState}
							/>
						</label>
					</div>
				</div>
			</div>
		) : (
			<></>
		)
	}

	return mainContent()
}

export default FilterFields
