import styled from 'styled-components'

export const Container = styled.div`
    display flex;
    flex-direction: row;
    padding-left: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`
export const EquipNameCol = styled.div`
	width: 40%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const SerialNumCol = styled.div`
	width: 10%;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const PreventiveCol = styled.div`
	width: 18%;
	text-align: center;
`

export const LocationCol = styled.div`
    width: 29%;
    padding-left:10px
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`

export const EditImg = styled.div`
	width: 3%;
	cursor: pointer;
`
