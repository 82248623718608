import React, { useState } from 'react'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import AlertIcon from '../SvgIcons/AlertIcon'
import CheckIcon from '../SvgIcons/CheckIcon'
import './styles.scss'

interface InputProps extends React.ComponentPropsWithRef<'input'> {
	name: string
	variant: 'light' | 'dark' | 'light-normal' | 'light-normal-check'
	innerRef?: any
	type?: React.HTMLInputTypeAttribute
	readOnly?: boolean
	errors?: any
	money?: boolean
	percentage?: boolean
	icon?: boolean
	onValueInput?: () => void
}

const Input = ({
	variant,
	readOnly = false,
	type = 'text',
	money = false,
	innerRef,
	errors,
	onValueInput,
	percentage = false,
	icon = true,
	...rest
}: InputProps) => {
	const [showPass, setShowPass] = useState<boolean>(false)

	const handleFieldType = (): string => {
		return type === 'password' ? (showPass ? 'text' : 'password') : type
	}

	const showPassToggle = (): void => {
		setShowPass(!showPass)
	}

	const showPassToggleContent = (): React.ReactNode => {
		return (
			type === 'password' && (
				<div onClick={showPassToggle}>
					{!showPass ? (
						<MdVisibility size={20} color="var(--primary-pure)" />
					) : (
						<MdVisibilityOff size={20} color="var(--primary-pure)" />
					)}
				</div>
			)
		)
	}

	const showCheckIcon = (): React.ReactNode => {
		return (
			<div className={variant} onClick={onValueInput}>
				<CheckIcon width="30" height="20" fill="#048243" />
			</div>
		)
	}

	const showAlertIcon = (): React.ReactNode => {
		return (
			errors &&
			icon && (
				<div className="container-alert-icon">
					<AlertIcon width="20" height="20" fill="#c21618" />
				</div>
			)
		)
	}

	return (
		<div className="input-component">
			<div
				className={`input-container input-${variant} ${
					errors && 'input-error'
				} ${readOnly && 'read-only'}`}
			>
				{money && <span>R$</span>}
				{percentage && <span>%</span>}
				<input {...innerRef} {...rest} type={handleFieldType()} />
				{showCheckIcon()}
				{showAlertIcon()}
				{showPassToggleContent()}
			</div>
			<div className="span-error-container">
				{errors?.message && (
					<span className="input-error-span">{errors.message.toString()}</span>
				)}
			</div>
		</div>
	)
}

export default Input
