/* eslint-disable prettier/prettier */
import jwt from 'jsonwebtoken'
import { routePublic } from 'module/installAndSearch/context/InstallAndSearchContext'
import { useEffect } from 'react'
import { store } from 'store'

interface ZendeskOptions {
	// Defina as opções do Zendesk, se necessário
}
const windowZendesk = (window as any)

export default function useZendeskWidget(
	key: string,
	options: ZendeskOptions,
	{ contact, email, language, token }: any
) {

	const { signed } = store.getState().auth

	useEffect(() => {
		const { pathname } = window.location

		if (routePublic.includes(pathname)) return

		const existingScript = document.getElementById('ze-snippet')
		if (existingScript) {
			existingScript.remove()
		}

		const zendeskInitScript = document.createElement('script')
		zendeskInitScript.id = 'ze-snippet'
		zendeskInitScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`
		zendeskInitScript.async = true

		document.body.appendChild(zendeskInitScript)
		windowZendesk.zESettings = { ...options }

		zendeskInitScript.onload = () => {
			const interval = setInterval(() => {
				if (windowZendesk.zE) {
					clearInterval(interval)
					authZendesk({ contact, email }, signed)
				}
			}, 100)

		}

		return () => {
			document.body.removeChild(zendeskInitScript)
		}
	}, [key, options, contact, email, token])
}


const authZendesk = async ({ contact, email }: any, signed: boolean) => {
	const response = handleUserConfig({
		name: contact,
		email
	})
	windowZendesk.zE("messenger:on", "open", function () {
		if (signed) {
			windowZendesk.zE('messenger', 'loginUser', function (callback: any) {
				callback(response.jwt)

				windowZendesk.zE('webWidget:on', 'loadingError', (data: any) => {
					if (data && data.type === 'messages') {
						console.error("Erro ao carregar mensagens: ", data);
					}
				});
			})
		} else {
			windowZendesk.zE("messenger", "logoutUser", function () {
			})
		}
	})
}

export const ZendeskWidget = (...args: any) => {
	if (canUseDOM() && (window as any).zE) {
		windowZendesk.zE.apply(null, args)
	} else {
		console.warn('Zendesk has not finished booting')
	}
}

const canUseDOM = () => {
	return !(
		typeof window === 'undefined' ||
		!window.document ||
		!window.document.createElement
	)
}



const handleUserConfig = (user: any): { externalId: string; jwt: string } => {

	const payload = {
		name: user.name,
		external_id: user.email,
		scope: 'user'
	}

	const privateKey = process.env.REACT_APP_SECRET_SUNCO || ''

	const token = jwt.sign(payload, privateKey, {
		algorithm: 'HS256',
		keyid: process.env.REACT_APP_KID_SUNCO
	})

	return { jwt: token, externalId: payload.external_id }
}
