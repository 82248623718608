import filesize from 'filesize'
import { uniqueId } from 'lodash'
import { customDeleteFile } from 'module/productManagement/services'
import React, { useEffect, useState } from 'react'
import { LuTrash2 } from 'react-icons/lu'
import { toast } from 'react-toastify'
import pdfIcon from '../../../../assets/image/PDF.svg'
import './styles.scss'

const acceptedFileTypes = [
	'image/png',
	'image/jpeg',
	'image/jpg',
	'application/pdf'
]
const MAX_SIZE = 30 * 1024 * 1024

interface UploadSingleFileProps {
	file?: File
	id: string
	name: string
	readableSize: string
	preview: string
	size: number
	type: string
	progress?: number
	uploaded: boolean
	error?: boolean
	url: string | null
	attachmentCategory?: string
	key?: string
	file_type?: string
	link?: string
}

type Props = {
	product?: any
	id: string
	processUpload: (
		uploadedFile: any,
		event: string,
		callback: (error: any) => void
	) => void
}

export const UploadSingleFile = ({ product, id, processUpload }: Props) => {
	const [uploadedFiles, setUploadedFiles] = useState<UploadSingleFileProps[]>(
		[]
	)
	const addItem = () => {
		const element = document.getElementById(id)
		element?.click()
	}

	useEffect(() => {
		if (!product) return
		setUploadedFiles(product?.files?.filter((file: any) => file?.event === id))
	}, [product])

	const filePreview = (file: any) => {
		switch (file.type) {
			case 'application/pdf':
				return pdfIcon
			default:
				return URL.createObjectURL(file)
		}
	}

	const onUploadFile = (files: File[]) => {
		const newFiles = Array.from(files).map((file) => {
			if (file.size > MAX_SIZE) {
				toast.error('Arquivo muito grande.')
			}

			if (!acceptedFileTypes.includes(file.type)) {
				toast.error('Tipo de arquivo inválido.')
			}

			return {
				id: uniqueId(),
				name: file.name,
				readableSize: filesize(file.size),
				preview: filePreview(file),
				size: file.size,
				progress: 0,
				url: '',
				error: false,
				uploaded: false,
				type: file.type,
				file
			}
		})

		const acceptedFiles = newFiles.filter(
			(file) => acceptedFileTypes.includes(file.type) && file.size <= MAX_SIZE
		)
		const newUploadedFiles = acceptedFiles
		setUploadedFiles(newUploadedFiles)

		newUploadedFiles.forEach((file) => {
			if (!file) {
				return
			}
			processUpload(file, id, () => {})
		})
	}

	const onDeleteFile = async (key: string) => {
		customDeleteFile({ key, event: id })
		setUploadedFiles([])
	}

	const processPreview = () => {
		if (uploadedFiles?.length === 0) {
			return ''
		}
		if (uploadedFiles[0]?.file_type === 'application/pdf') {
			return pdfIcon
		}
		if (uploadedFiles[0]?.link) {
			return uploadedFiles[0]?.link
		}
		if (uploadedFiles[0]?.preview) {
			return uploadedFiles[0]?.preview
		}
	}

	const getKeyUrl = (): string => {
		if (
			product?.files?.filter((file: any) => file.event === id).length &&
			uploadedFiles[0].key
		) {
			return uploadedFiles[0].key
		} else if (uploadedFiles.length === 1 && uploadedFiles[0].url) {
			console.log('uploadedFile')
			return uploadedFiles[0].url?.substring(1).split('.com/')[1]
		}
		return ''
	}

	return (
		<div className="upload-single-file">
			{processPreview() || uploadedFiles[0] ? (
				<div className="preview">
					<img src={processPreview()} alt="" />
					<div
						className="delete-image"
						onClick={() => onDeleteFile(getKeyUrl())}
					>
						<LuTrash2 color="red" size={20} />
					</div>
				</div>
			) : (
				<div className="empty" onClick={addItem}>
					<div className="plus">+</div>
					<input
						{...{
							id
						}}
						type="file"
						accept=".jpg, .png, .jpeg, .pdf"
						style={{ display: 'none' }}
						onChange={(e) => onUploadFile(e.target.files as unknown as File[])}
					/>
				</div>
			)}
		</div>
	)
}
