import attach from 'assets/image/Attach.svg'
import DeleteIcon from 'assets/image/Trash.svg'
import States from 'assets/js/fs.json'
import filesize from 'filesize'
import $ from 'jquery'
import { uniqueId } from 'lodash'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { toast } from 'react-toastify'
import { Input as InputReactStrap } from 'reactstrap'
import { api, uploadFile } from 'services'
import Button from 'shared/components/Button'
import { Input } from '../../Input'
import { Select } from '../../Select/selects'
import { NewModal } from '../../_newModal'
import '../../_styled/display.scss'
import { LoadingProgress } from '../../loadProgress'
import './styles.scss'

type ModalInstallProps = {
	onReturn: () => void
	product: any
	client: any
	submitNewOs: (data: any) => void
	title: string
}

interface Forms {
	nameContact: string
	phone: string
	email: string
	address: string
	number: string
	neighborhood: string
	city: string
	state_sigla: string
	zip_code: string
	complement: string
	cnpj: string
}

export const ModalInstall = ({
	onReturn,
	product,
	client,
	submitNewOs,
	title
}: ModalInstallProps) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue
	} = useForm<Forms>()
	const [disableAddress, setDisableAddress] = useState(false)
	const [imgName, setImgName] = useState()
	const [uploadedFiles, setUploadedFiles] = useState<any>()
	const [show, setShow] = useState(false)

	async function onChangeZipCode(event: any) {
		let zipCode = event.target.value
		zipCode = zipCode.replace(/[^0-9]/g, '')
		setValue('zip_code', zipCode)
	}

	async function getAddressByZipCode(event: any) {
		let zipCode = event.target.value

		//zipcode somente com dígitos.
		zipCode = zipCode.replace(/\D/g, '')

		if (zipCode !== '') {
			//Expressão regular para validar o CEP.
			const validacep = /^[0-9]{8}$/

			if (validacep.test(zipCode)) {
				// disable address
				setDisableAddress(true)

				const { data } = await api.get(`prp/address/zipcode?zipcode=${zipCode}`)
				const { code, address } = data
				if (code === 200) {
					setDisableAddress(false)
					setValue('address', address.logradouro)
					setValue('neighborhood', address.bairro)
					setValue('city', address.localidade)
					setValue('state_sigla', address.uf)
				} else {
					toast.error('Erro ao encontrar o cep informado, favor verificar.')
					setDisableAddress(false)

					clearAddressFields()
				}
			} else {
				clearAddressFields()
			}
		} else {
			clearAddressFields()
		}
	}

	function clearAddressFields() {
		setValue('address', '')
		setValue('neighborhood', '')
		setValue('city', '')
		setValue('state_sigla', '')
	}

	function handleUpload(e: any) {
		const formattedFiles = {
			file: e.currentTarget.files[0],
			id: uniqueId(),
			name: e.currentTarget.files[0].name,
			readableSize: filesize(e.currentTarget.files[0].size),
			size: e.currentTarget.files[0].size,
			type: e.currentTarget.files[0].type,
			progress: 0,
			uploaded: false,
			error: false,
			url: null
		}

		processUpload(formattedFiles)
	}

	async function processUpload(uploadedFile: any) {
		try {
			const data = new FormData()

			data.append('file', uploadedFile.file, uploadedFile.name)

			setShow(true)

			const response = await uploadFile.post('upload', data)

			uploadedFile.uploaded = true
			uploadedFile.id = response.data.key
			uploadedFile.url = response.data.url

			setUploadedFiles(uploadedFile)
			setShow(false)
		} catch (error) {
			uploadedFile.error = true
			setUploadedFiles(undefined)
			setImgName(undefined)
			setShow(false)
		}
	}

	function fileTypeCheck(e: any) {
		if (e.currentTarget.files[0]) {
			const file = e.currentTarget.files[0].name
			const ext = file.split('.')

			if (
				ext[ext.length - 1] == 'jpg' ||
				ext[ext.length - 1] == 'jpeg' ||
				ext[ext.length - 1] == 'png' ||
				ext[ext.length - 1] == 'pdf'
			) {
				uploadImage(e)
				handleUpload(e)
			} else {
				toast.info('Atenção, apenas arquivos jpg, jpeg, png e pdf são aceitos.')
				$('.inputImage').val('')
				$(`.inputImage`).find('input').focus()
			}
		}
	}

	function onSubmit(data: any) {
		if (!uploadedFiles) {
			toast.info('Escolha uma imagem para fazer o upload.')
		} else if (uploadedFiles.error == true) {
			toast.info('Erro ao subir arquivo tente novamente.')
		} else {
			const os_info: any = {}

			os_info.equipment = {
				serial_number: product.serial_number,
				name: product.model,
				code_prp: product.product_code,
				date_end_warranty: product.date_end_warranty,
				model: product.model
			}
			os_info.service = {
				created_by: 'BUSCA_APP',
				originOS: 'BUSCA_APP',
				client_code: client.code,
				service_order: '',
				contato: data.nameContact,
				phone: data.phone,
				email: data.email,
				equipment_id: '',
				type: 'PRE-INSTALACAO',
				obs: '',
				complement: data.complement,
				address: data.address + ', ' + data.number,
				neighborhood: data.neighborhood,
				city: data.city,
				state_sigla: data.state_sigla,
				zip_code: data.zip_code
			}
			os_info.client = {
				cod_protheus: client.code,
				razao_social: client.razao_social,
				street: client.street,
				neighborhood: client.neighborhood,
				city: client.city,
				zip_code: client.zip_code,
				state: client.state_sigla,
				nome_fantasia: client.name,
				cpfCnpj: client.cpf_cnpj,
				keyAccount: client.key_account,
				data_criacao: Date.now()
			}
			os_info.file = uploadedFiles
			os_info.msg = data.cnpj
			os_info.step_type = 'create_os'
			os_info.file_type = 'NF'
			submitNewOs(os_info)
		}
	}

	function uploadImage(e: any) {
		if (e.currentTarget.files[0]) {
			setImgName(e.currentTarget.files[0].name)
		} else {
			setImgName(undefined)
		}
	}

	async function handleDelete(id: any) {
		await uploadFile.delete(`upload/${id}`)
		setUploadedFiles(undefined)
		setImgName(undefined)
	}

	return (
		<NewModal onClose={onReturn} awayClose={true} title={title}>
			<div className="modal-installation">
				<div className="title-modal-install">
					<div className="equipName">{product.model}</div>
					<div className="serialNum">
						Número de série: {product.serial_number}
					</div>
				</div>
				<div className="body-modal-install">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="cnpj">
									Cliente
								</label>
								<Input
									type="number"
									placeholder={`CPF ou CNPJ (apenas numeros)*`}
									autoComplete="disabled"
									name="cnpj"
									innerRef={{ ...register('cnpj', { required: true }) }}
									id="cnpj"
								/>
								<small className="text-danger">
									{errors.address && <span>O campo é obrigatório</span>}
								</small>
							</div>
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="nameContact">
									Contato
								</label>
								<Input
									type="text"
									placeholder={`Digite o nome *`}
									autoComplete="disabled"
									name="nameContact"
									innerRef={{
										...register('nameContact', { required: true })
									}}
									id="nameContact"
								/>

								<small className="text-danger">
									{errors.address && <span>O campo é obrigatório</span>}
								</small>
							</div>
						</div>

						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="email">
									E-mail
								</label>

								<Input
									type="text"
									placeholder={`Digite o email *`}
									autoComplete="disabled"
									name="email"
									innerRef={{ ...register('email', { required: true }) }}
									id="email"
								/>
								<small className="text-danger">
									{errors.address && <span>O campo é obrigatório</span>}
								</small>
							</div>

							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="phone">
									Telefone
								</label>
								<InputReactStrap
									className="InputMask"
									type="tel"
									mask="(99) 999999999"
									maskChar=""
									placeholder={`Digite o telefone *`}
									name="phone"
									inputRef={{
										...register('phone', {
											required: true,
											minLength: 13,
											maxLength: 16
										})
									}}
									onChange={(e) => {
										setValue('phone', e.target.value)
									}}
									tag={InputMask}
									id="phone"
								/>
								<small className="text-danger">
									{errors.phone && errors.phone.type === 'required' && (
										<span>O campo é obrigatório</span>
									)}
									{errors.phone && errors.phone.type === 'minLength' && (
										<span>O campo deve conter no mínimo 10 caracteres</span>
									)}
								</small>
							</div>
						</div>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="zipcode">
									CEP
								</label>
								<Input
									id="zipcode"
									type="text"
									placeholder={`Digite o CEP *`}
									name="zip_code"
									autoComplete="disabled"
									maxLength={8}
									innerRef={{
										...register('zip_code', {
											required: true,
											minLength: 8,
											maxLength: 8
										})
									}}
									onBlur={getAddressByZipCode}
									onChange={onChangeZipCode}
								/>

								<small className="text-danger">
									{errors.zip_code && errors.zip_code.type === 'required' && (
										<span>O campo é obrigatório</span>
									)}
									{errors.zip_code && errors.zip_code.type === 'maxLength' && (
										<span>O campo deve conter no máximo 9 caracteres</span>
									)}
									{errors.zip_code && errors.zip_code.type === 'minLength' && (
										<span>O campo deve conter no mínimo 8 caracteres</span>
									)}
								</small>
							</div>

							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="city">
									Cidade
								</label>
								<Input
									type="text"
									placeholder={`Digite a cidade *`}
									name="city"
									autoComplete="disabled"
									disabled={disableAddress}
									innerRef={{
										...register('city', { required: true, maxLength: 100 })
									}}
									id="city"
								/>
								<small className="text-danger">
									{errors.city && <span>O campo é obrigatório</span>}
								</small>
							</div>

							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="state_sigla">
									Estado
								</label>
								<Select
									name="state_sigla"
									id="state_sigla"
									// disabled={disableAddress}
									innerRef={{ ...register('state_sigla', { required: true }) }}
								>
									<option value="">Select the state</option>
									{States.map((item) => (
										<option key={item.value} value={item.initials}>
											{item.initials}
										</option>
									))}
								</Select>
								<small className="text-danger">
									{errors.state_sigla && <span>O campo é obrigatório</span>}
								</small>
							</div>
						</div>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="address">
									Endereço
								</label>

								<Input
									type="text"
									placeholder={`Digite o endereço *`}
									autoComplete="disabled"
									name="address"
									disabled={disableAddress}
									innerRef={{ ...register('address', { required: true }) }}
									id="address"
								/>
								<small className="text-danger">
									{errors.address && <span>O campo é obrigatório</span>}
								</small>
							</div>
						</div>
						<div className="row-install-and-search">
							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="number">
									Número
								</label>

								<Input
									type="text"
									placeholder={`Digite o número *`}
									autoComplete="disabled"
									name="number"
									id="number"
									maxLength={5}
									innerRef={{
										...register('number', {
											required: true,
											pattern: /^(0|[1-9][0-9]*)$/
										})
									}}
								/>
								<small className="text-danger">
									{errors.number && errors.number.type === 'required' && (
										<span>O campo é obrigatório</span>
									)}
									{errors.number && errors.number.type === 'pattern' && (
										<span>O campo deve conter apenas números</span>
									)}
								</small>
							</div>

							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="neighborhood">
									Bairro
								</label>

								<Input
									type="text"
									placeholder="Digite o bairro *"
									autoComplete="disabled"
									name="neighborhood"
									id="neighborhood"
									disabled={disableAddress}
									innerRef={{
										...register('neighborhood', { required: true })
									}}
								/>
								<small className="text-danger">
									{errors.neighborhood &&
										errors.neighborhood.type === 'required' && (
											<span>O campo é obrigatório</span>
										)}
								</small>
							</div>

							<div className="column-install-and-search">
								<label className="form-label-title" htmlFor="complement">
									Complemento
								</label>
								<Input
									type="text"
									placeholder="Digite o complemento"
									name="complement"
									id="complement"
									autoComplete="disabled"
									innerRef={{ ...register('complement') }}
								/>
							</div>
						</div>
						<div className="row-install-and-search">
							<div
								className="container-file-upload"
								style={{ backgroundColor: !imgName ? '' : '#d0dff0' }}
								onClick={() => {
									$('#inputImage').trigger('click')
								}}
							>
								{!imgName ? (
									<>
										<button
											className="btn-attachment"
											type="button"
											id="selectImg"
										>
											<img
												src={attach}
												style={{
													width: '15px',
													marginRight: '5px'
												}}
											></img>
											Anexar nota fiscal de compra.
										</button>
										<input
											style={{ display: 'none' }}
											type="file"
											id="inputImage"
											className="inputImage Myimage"
											name="myImage"
											onChange={fileTypeCheck}
										/>
									</>
								) : (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div
											style={{
												width: '160px',
												whiteSpace: 'nowrap',
												overflow: 'hidden',
												textOverflow: 'ellipsis',
												height: '20px'
											}}
										>
											Anexo: {imgName}
										</div>
										<LoadingProgress show={show} />
										{!!uploadedFiles && uploadedFiles.uploaded && (
											<button
												onClick={() => handleDelete(uploadedFiles.id)}
												className="btn-delete"
												type="button"
											>
												<img width={'15px'} src={DeleteIcon} />
											</button>
										)}
									</div>
								)}
							</div>
						</div>
						<div className="container-address-confirm-actions">
							<Button
								size="lg"
								variant="confirmation-solid"
								title="Enviar"
								type="submit"
							/>
							<Button
								size="lg"
								onClick={() => {
									if (uploadedFiles?.id) handleDelete(uploadedFiles.id)
									onReturn()
								}}
								variant="cancellation-gradient"
								title="Cancelar"
							/>
						</div>
					</form>
				</div>
			</div>
		</NewModal>
	)
}
