import styled from 'styled-components'

export const Container = styled.div`
	padding: 20px;
	background: white;
	width: auto;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	font-size: 20px;
	border-radius: 3px;
`

export const Message = styled.div`
	text-align: center;
`

export const Footer = styled.div`
	display: flex;
	margin-top: 20px;
	flex-direction: row-reverse;
`
