import React, { useState } from 'react'
import Button from 'shared/components/Button'
import './styles.scss'

export const InstallAndSearch = () => {
	const [showIframe, setShowIframe] = useState<string>('/installation')
	return (
		<div className="main-install-and-search">
			<div className="header">
				<Button
					variant="confirmation-solid"
					title="Solicitar instalação"
					size="md"
					style={{
						backgroundColor:
							showIframe === '/installation' ? '#025cb7' : '#004687'
					}}
					onClick={() => setShowIframe('/installation')}
				/>
				<Button
					variant="confirmation-solid"
					title="Solicitar serviço"
					size="md"
					style={{
						backgroundColor: showIframe === '/service' ? '#025cb7' : '#004687'
					}}
					onClick={() => setShowIframe('/service')}
				/>
			</div>
			<div className="content">
				<iframe src={showIframe} width="100%" height="100%" />
			</div>
		</div>
	)
}
