import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext } from 'react'
import CheckBox from 'shared/components/CheckBox'
import { normalizeCGC } from 'utils/Pipe'

interface Props {
	isEquipChecked: boolean
	onEquipCheckedChange: () => void
}

const EquipmentSelectField: React.FC<Props> = ({
	isEquipChecked,
	onEquipCheckedChange
}) => {
	const { transferSelected } = useContext(TransfersDetailsContextElement)

	const currentClient =
		transferSelected?.transferPayloadParsed.prpInfo.currentClient
	const equipment = transferSelected?.transferPayloadParsed.prpInfo.equipment
	return (
		<div className="transfer-confirm-action">
			<CheckBox
				format="square"
				variant="blue"
				checked={isEquipChecked}
				onChange={onEquipCheckedChange}
			/>
			<div className="transfer-confirm-action-text">
				<div>
					<span title={equipment?.name || ''}>
						<b>Equipamento:</b>
						{equipment?.name}
					</span>
					<span>
						<b>Número de Série:</b>
						{equipment?.serial_number}
					</span>
				</div>
				<div>
					<span title={currentClient?.nome_fantasia || ''}>
						<b>Cliente Atual:</b>
						{currentClient?.nome_fantasia}
					</span>
					<span>
						<b>CPF/CNPJ:</b>
						{normalizeCGC(currentClient?.cpfCnpj)}
					</span>
				</div>
			</div>
		</div>
	)
}

export default EquipmentSelectField
