import React from 'react'
import './styles.scss'

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
	variant: 'standard' | 'blue'
}

const Radio = ({ variant, ...rest }: InputProps) => {
	return (
		<div className={`radio-zero-height radio-variant-${variant}`}>
			<input {...rest} />
			<label htmlFor={`${rest.id}`}>{rest.name}</label>
		</div>
	)
}
export default Radio
