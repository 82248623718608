import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { updateClientSchema } from 'module/orderManagement/schemas/update-client-schema'
import { TaxpayerTypes } from 'module/orderManagement/utils/constants'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import Select from 'shared/components/Select'
import { store } from 'store'
import './styles.scss'

export const ClientAccountingInformationEditingMode = () => {
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const {
		register,
		setValue,
		getValues,
		formState: { errors }
	} = useFormContext<updateClientSchema>()

	const { natureList, ledgerAccountList, costCenterList } =
		store.getState().baseLists

	return (
		<div className="client-accounting-information">
			<div className="client-accounting-information-contain-information">
				<header>Natureza</header>
				<Select
					id="a1Naturez"
					options={natureList}
					errors={errors.a1Naturez}
					innerRef={{ ...register('a1Naturez') }}
					selectedOption={[getValues('a1Naturez')]}
					onSelectOptions={(option) => setValue('a1Naturez', option[0])}
				/>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Conta contábil</header>
				<Select
					id="a1Conta"
					tooltip="right"
					errors={errors.a1Conta}
					options={ledgerAccountList}
					innerRef={{ ...register('a1Conta') }}
					selectedOption={[getValues('a1Conta')]}
					onSelectOptions={(option) => setValue('a1Conta', option[0])}
				/>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Centro de custo</header>
				<Select
					id="a1Ccusto"
					tooltip="right"
					errors={errors.a1Ccusto}
					options={costCenterList}
					innerRef={{ ...register('a1Ccusto') }}
					selectedOption={[getValues('a1Ccusto')]}
					onSelectOptions={(option) => setValue('a1Ccusto', option[0])}
				/>
			</div>
			{sa1010?.a1Conta && (
				<div className="client-accounting-information-contain-information">
					<header>Item conta</header>
					<span>{sa1010?.a1Conta}</span>
				</div>
			)}
			{sa1010?.a1Lc && (
				<div className="client-accounting-information-contain-information">
					<header>Limite de crédito</header>
					<span>
						{Number(sa1010?.a1Lc).toLocaleString('pt-br', {
							currency: 'BRL',
							minimumFractionDigits: 2
						})}
					</span>
				</div>
			)}
			<div className="client-accounting-information-contain-information">
				<header>Contribuinte</header>
				<Select
					id="a1Contrib"
					options={TaxpayerTypes}
					errors={errors.a1Contrib}
					innerRef={{ ...register('a1Contrib') }}
					selectedOption={[getValues('a1Contrib')]}
					onSelectOptions={(option) => setValue('a1Contrib', option[0])}
				/>
			</div>
		</div>
	)
}
