import React, { useEffect, useState } from 'react'
import Paginator from 'shared/Paginator'
import { Card } from '../card'
import './styles.scss'

type TechniciansProps = {
	techniciansList: any
	serialNumber: string
}

export const List = ({ techniciansList, serialNumber }: TechniciansProps) => {
	const [paginatedList, setPaginatedList] = useState<any[]>([])
	const [currentPage, setCurrentPage] = useState<number>(1)
	const ITEMS_PER_PAGE = 10

	useEffect(() => {
		if (techniciansList.length > 0) {
			setupPaginatedList(1)
		}
	}, [techniciansList])

	/**
	 * Method that will mount the list, diving in parts defined in the variable
	 * ITEMS_PER_PAGE.
	 */
	const setupPaginatedList = (currentPage: number) => {
		setCurrentPage(currentPage)
		if (techniciansList.length > ITEMS_PER_PAGE) {
			const element = document.querySelector(
				`.container-address`
			) as HTMLDetailsElement
			const indexOfLast = currentPage * ITEMS_PER_PAGE
			const indexOfFirst = indexOfLast - ITEMS_PER_PAGE
			setPaginatedList(techniciansList.slice(indexOfFirst, indexOfLast))
			if (!element) return
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			})
		} else {
			setPaginatedList(techniciansList)
		}
	}

	return (
		<>
			{paginatedList.length > 0 && (
				<>
					<div id="list-items">
						<ul className="list-techs">
							{paginatedList.map((item) => (
								<Card item={item} serialNumber={serialNumber} key={item.id} />
							))}
						</ul>
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								marginTop: '10px'
							}}
						>
							<Paginator
								total={Math.ceil(techniciansList.length / ITEMS_PER_PAGE)}
								selected={currentPage}
								onPageChange={setupPaginatedList}
							/>
						</div>
					</div>
				</>
			)}
		</>
	)
}
