import Client from 'models/Client'
import Equipment from 'models/Equipment'
import React, { useContext } from 'react'
import { FaCircle } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { Tooltip } from 'module/orderManagement/components/Tooltip'
import { EquipmentLegendStatus } from 'module/technicalServices/utils/constants'
import { ViewType } from 'utils/Constants'
import './styles.css'
interface Props {
	equipment?: Equipment
	client?: Client
	type?: string
	warranty?: string
	osProtheus?: string
}

const EquipmentInfo: React.FC<Props> = ({
	client,
	equipment,
	type,
	warranty,
	osProtheus
}) => {
	const { viewType } = useContext(DeviceContextElement)
	const isMobile = viewType === ViewType.MOBILE
	return (
		<>
			<div className="page-os-equip-name">
				<div>
					{equipment?.legend &&
						EquipmentLegendStatus.map((legend) => {
							return (
								legend?.value === equipment?.legend && (
									<Tooltip
										info={legend?.label}
										mobile={isMobile}
										key={legend?.value}
									>
										<FaCircle color={legend?.color} size={isMobile ? 15 : 20} />
									</Tooltip>
								)
							)
						})}
				</div>
				<span>{equipment?.name ? equipment?.name : <Skeleton />}</span>
			</div>
			<div className="page-os-equip-info">
				<div className="page-os-equip-info-item">
					<p>Tipo de OS:</p>
					<span>{type ? type : <Skeleton width={160} />}</span>
				</div>
				<div className="page-os-equip-info-item">
					<p>Número de série:</p>
					<span>
						{equipment?.serial_number ? (
							equipment?.serial_number
						) : (
							<Skeleton width={160} />
						)}
					</span>
				</div>
				<div className="page-os-equip-info-item">
					<p>Garantia:</p>
					<span>{warranty ? warranty : <Skeleton width={120} />}</span>
				</div>
				<div className="page-os-equip-info-item">
					<p>OS Protheus:</p>
					<span>{osProtheus}</span>
				</div>
			</div>
		</>
	)
}

export default EquipmentInfo
