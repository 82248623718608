import React from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Information, Title } from './styles'

const Error404: React.FC = () => {
	return (
		<>
			<NavLink
				className="os-detail-back-button"
				to="/os"
				onClick={() => window.history.back()}
			>
				&lt; Voltar
			</NavLink>
			<Container>
				<Title>404</Title>
				<Information>
					Não encontramos nenhuma ordem de serviço referente ao seu link. Entre
					em contato conosco através do chat ou clique em voltar e tente
					novamente.
				</Information>
			</Container>
		</>
	)
}

export default Error404
