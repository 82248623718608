import produce from 'immer'

const INITIAL_STATE = {
	employees: {
		employees: [],
		listEmployeesParams: {
			pageLimit: 19,
			totalPages: null,
			currentPage: 1,
			searchValue: '',
			itemFilter: {
				status: true
			}
		}
	}
}

const humanResources = (state = INITIAL_STATE, action) => {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@auth/SIGN_OUT':
				draft.employees.employees = []
				draft.employees.listEmployeesParams.pageLimit = 19
				draft.employees.listEmployeesParams.totalPages = null
				draft.employees.listEmployeesParams.currentPage = 1
				draft.employees.listEmployeesParams.searchValue = ''
				draft.employees.listEmployeesParams.itemFilter.status = true
				break
			case '@humanResources/SET_EMPLOYEES_LIST_PARAMS':
				draft.employees.listEmployeesParams = action.payload.listEmployeesParams
				break
			default:
				return state
		}
	})
}

export default humanResources
