import { Checkbox, IconButton } from '@material-ui/core'
import Equipment from 'models/Equipment'
import Location from 'models/Locations'
import React from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { MdCheck } from 'react-icons/md'
import './styles.css'

interface Props {
	equips: Equipment[]
	equipsSelected: Equipment[]
	locations: Location[]
	onSelectItem: (equip: Equipment) => void
	onLocationChanges: (equip: Equipment) => void
}
const PreventiveEquipsList: React.FC<Props> = ({
	equips,
	equipsSelected,
	locations,
	onSelectItem,
	onLocationChanges
}) => {
	const [isEdit, setEdit] = React.useState<number>()

	/**
	 * @description Handles a edit equipment button click.
	 * @param equipment
	 */
	const onEquipEditClick = (equipment: Equipment) => {
		if (isEdit) {
			if (isEdit !== equipment.id) {
				return
			}
			onSaveEdtionClick(equipment)
		} else {
			setEdit(equipment.id)
		}
	}

	/**
	 * @description handles edit button view
	 * @param equipment Equipment that will be changed
	 */
	const onSaveEdtionClick = (equipment: Equipment) => {
		setEdit(0)
	}

	/**
	 * @description Handles a location select action.
	 * @param value Value selected
	 * @param equipment Equipment to be changed.
	 */
	const setSelectedLocation = (value: any, equipment: Equipment) => {
		const location = locations?.find((l) => l.id.toString() === value)
		if (location) {
			equipment.location = location
			equipment.location_id = location.id
			onLocationChanges(equipment)
		}
	}

	const locationFieldContent = (equip: Equipment) => {
		return (
			<div>
				{isEdit === equip.id ? (
					<div>
						<select
							className="select-base"
							value={equip.location_id || 0}
							defaultValue={locations[0].id}
							onChange={(e) => {
								setSelectedLocation(e.target.value, equip)
							}}
						>
							<option key={0} value={0}>
								Selecionar localização
							</option>
							{locations?.map((location: Location) => (
								<option key={location.id} value={location.id}>
									{location.name}
								</option>
							))}
						</select>
					</div>
				) : (
					<div>
						{equip.location ? equip.location.name : `Selecionar localização`}
					</div>
				)}
			</div>
		)
	}

	return (
		<div className="table">
			<div className="table-header equips">
				<div className="equips-table-header-item-name">Equipamento</div>
				<div className="equips-table-header-item-sn">N/S</div>
				<div className="equips-table-header-item-address">Localização</div>
			</div>
			<div className="table-content">
				{equips.map((equipment, index) => {
					return (
						<div className="table-item" key={equipment.id}>
							<div className="equips-table-item-name">
								<Checkbox
									checked={equipment.preventive === 1}
									value={equipment}
									onChange={(e) => {
										onSelectItem(equipment)
									}}
									inputProps={{ 'aria-label': 'primary checkbox' }}
								/>
								{equipment.name}
							</div>
							<div className="equips-table-item-sn">
								{equipment.serial_number}
							</div>
							<div className="equips-table-item-address">
								<div>{locationFieldContent(equipment)}</div>
								<IconButton
									color="primary"
									aria-label="upload picture"
									component="span"
									onClick={() => {
										onEquipEditClick(equipment)
									}}
									className="icon-button"
								>
									{isEdit === equipment.id ? <MdCheck /> : <FaPencilAlt />}
								</IconButton>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

export default PreventiveEquipsList
