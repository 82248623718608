import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import {
	OrderChecklistUpdateStatus,
	OrderChecklistUpsert
} from 'module/orderManagement/services'
import {
	checklistPaymentFormOptions,
	handlePaymentFormType,
	paymentFormCheckboxOptions
} from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import Button from 'shared/components/Button'
import CheckBox from 'shared/components/CheckBox'
import EmptyList from 'shared/components/EmptyList'
import LoadingProgress from 'shared/components/LoadingProgress'
import Select from 'shared/components/Select'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
}

export default function OrderChecklist({ match }: TParams) {
	const {
		order,
		prpOrderDetails,
		onOrderChange,
		onUpdateOrderPrp,
		orderPrpIsLoading
	} = useContext(OrderDetailsContextElement)
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const { viewType } = useContext(DeviceContextElement)
	const { orderCode, branch } = match.params
	const [editOn, setEditOn] = useState(false)
	const [paymentForm, setPaymentForm] =
		useState<keyof typeof paymentFormCheckboxOptions>('')
	const [selectedCheckbox, setSelectedCheckbox] = useState<any[]>([])
	const [modalOn, setModalOn] = useState(false)
	const [text, setText] = useState<string>('')
	const [status, setStatus] = useState<string>('')

	useEffect(() => {
		if (order?.code !== orderCode) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	useEffect(() => {
		if (prpOrderDetails?.checklistPaymentForm) {
			setPaymentForm(
				prpOrderDetails.checklistPaymentForm as keyof typeof paymentFormCheckboxOptions
			)
		}
	}, [prpOrderDetails?.checklistPaymentForm])

	useEffect(() => {
		if (prpOrderDetails?.checklists) {
			const filteredChecklists = prpOrderDetails.checklists.filter(
				(checklist) => checklist.paymentForm === paymentForm
			)[0]

			if (filteredChecklists) {
				setSelectedCheckbox(
					paymentFormCheckboxOptions[
						filteredChecklists.paymentForm as keyof typeof paymentFormCheckboxOptions
					].filter((checklist) => filteredChecklists[checklist.value])
				)
			}
		}
	}, [paymentForm])

	function transformArrayToInfo(arr: any[]): { [key: string]: boolean } {
		const result: { [key: string]: boolean } = {}

		arr.forEach((obj) => {
			result[obj.value] = true
		})
		return result
	}

	const actionButton = () => {
		if (editOn === false) {
			setEditOn(true)
		} else {
			setPaymentForm(paymentForm)
			if (prpOrderDetails?.id) {
				const data = {
					...transformArrayToInfo(selectedCheckbox),
					paymentForm: paymentForm,
					orderId: prpOrderDetails.id
				}
				OrderChecklistUpsert(data)
					.then(() => {
						onUpdateOrderPrp(`${branch}${orderCode}`)
						toast.success('Checklist atualizado com sucesso')
					})
					.catch(() => toast.error('Erro ao atualizar checklist'))
					.finally(() => {
						setEditOn(false)
					})
			}
		}
	}

	const handleConfirmInfos = () => {
		const filteredChecklists = prpOrderDetails?.checklists.filter(
			(checklist) => checklist?.paymentForm === paymentForm
		)[0]

		//Se o número de checkbox clicado for o mesmo que o número total de checkboxes o checklist está completo
		if (
			paymentFormCheckboxOptions[
				filteredChecklists?.paymentForm as keyof typeof paymentFormCheckboxOptions
			]?.length ===
			paymentFormCheckboxOptions[
				filteredChecklists?.paymentForm as keyof typeof paymentFormCheckboxOptions
			]?.filter((checklist) => filteredChecklists[checklist.value])?.length
		) {
			setText('Deseja prosseguir com a solicitação?')
			setStatus('Solicitação de Liberação')
		} else {
			setText(
				'Existem informações pendentes no checklist, deseja prosseguir com a solicitação mesmo assim ?'
			)
			setStatus('Solicitação de Liberação com Pendência')
		}
	}

	const handleUpdateStatus = () => {
		if (prpOrderDetails?.id && paymentForm) {
			const data = {
				status: status,
				id: prpOrderDetails.id,
				protheusOrder: prpOrderDetails.protheusOrder,
				statusUpdatedAt: new Date(),
				createdAt: prpOrderDetails.createdAt,
				updatedAt: new Date(),
				checklistPaymentForm: paymentForm,
				erpSync: prpOrderDetails.erpSync
			}

			OrderChecklistUpdateStatus(data)
				.then(() => {
					onUpdateOrderPrp(`${branch}${orderCode}`)
					toast.success('Solicitação realizada com sucesso')
				})
				.catch(() => toast.error('Erro ao realizar solicitação'))
				.finally(() => setModalOn(false))
		}
	}

	return order && sa1010 && prpOrderDetails ? (
		<>
			<OrderMenuTabs path={match.path} />
			<TabTitles />

			<div className="order-checklist">
				<div className="order-checklist-header">
					<label>
						Habilite o seletor de checklist e escolha o mode de acordo com a
						forma de pagamento definida.
						<br />
						Lembre-se de salvar as alterações realizadas.
					</label>

					{viewType <= ViewType.MOBILE ? (
						<label>
							<span className="order-checklist-dropdown-payment-form">
								Forma de Pagamento:
							</span>
							{order?.payment_code
								? handlePaymentFormType(order?.payment_code)
								: '-'}
						</label>
					) : (
						<Button
							size="lg"
							title={editOn ? 'Salvar' : 'Editar'}
							disabled={orderPrpIsLoading}
							variant={editOn ? 'confirmation-solid' : 'outline'}
							onClick={actionButton}
						/>
					)}
				</div>

				<div className="order-checklist-dropdown">
					<div className="order-checklist-dropdown-size">
						<Select
							id="order-checklist"
							checkbox={false}
							options={checklistPaymentFormOptions}
							selectedOption={[paymentForm]}
							onSelectOptions={(selectedForm) => {
								setPaymentForm(
									selectedForm[0] as keyof typeof paymentFormCheckboxOptions
								)
								setSelectedCheckbox([])
							}}
							variant="light"
							tooltip="left"
							placeholder="Selecionar Checklist"
							disable={!editOn}
						/>
					</div>

					{viewType <= ViewType.MOBILE ? (
						<div className="order-checklist-button">
							<Button
								size="lg"
								title={editOn ? 'Salvar' : 'Editar'}
								disabled={orderPrpIsLoading}
								variant={editOn ? 'confirmation-solid' : 'outline'}
								onClick={actionButton}
							/>
						</div>
					) : (
						<label>
							<span className="order-checklist-dropdown-payment-form">
								Forma de Pagamento
							</span>
							<br />
							{order?.payment_code
								? handlePaymentFormType(order?.payment_code)
								: '-'}
						</label>
					)}
				</div>

				<div className="order-checklist-checkbox">
					{paymentForm &&
						paymentFormCheckboxOptions[paymentForm].map(
							(currentCheckbox, index) => {
								return (
									<div key={index}>
										<span className="order-checklist-checkbox-position">
											<CheckBox
												checked={selectedCheckbox.includes(currentCheckbox)}
												variant={editOn ? 'blue' : 'gray'}
												format="square"
												disabled={!editOn}
												onChange={(value) => {
													if (value.target.checked) {
														setSelectedCheckbox((prevSelectCheckbox) => {
															return [...prevSelectCheckbox, currentCheckbox]
														})
													} else {
														setSelectedCheckbox((selectCheck) => {
															return selectCheck.filter(
																(check) => check !== currentCheckbox
															)
														})
													}
												}}
												id={`${index}`}
											/>
											<label
												className={`order-checklist-edit-${
													editOn ? 'on' : 'off'
												}`}
												htmlFor={`${index}`}
											>
												{currentCheckbox.label}
											</label>
										</span>
									</div>
								)
							}
						)}
				</div>
				{/* {!editOn && (
					<div className="order-checklist-solicitation">
						<Button
							size="lg"
							title="Solicitar Liberação"
							disabled={orderPrpIsLoading}
							variant="confirmation-gradient"
							onClick={() => {
								handleConfirmInfos()
								setModalOn(true)
							}}
						/>

						<ConfirmModal
							show={modalOn}
							onConfirm={() => handleUpdateStatus()}
							onCancel={() => setModalOn(false)}
							title="Confirmação"
							text={text}
							confirmLabel="Sim"
							cancelLabel="Não"
							buttonWidth="100px"
						/>
					</div>
				)} */}
			</div>
			<LoadingProgress show={orderPrpIsLoading} text="Carregando..." />
		</>
	) : (
		<>
			<OrderMenuTabs path={match.path} />
			<EmptyList
				show={!(order && sa1010)}
				text={'Nenhuma informação do pedido encontrada!'}
			/>
		</>
	)
}
