import styled from 'styled-components'

export const Container = styled.div`
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
`

export const Panel = styled.div`
	position: relative;
	width: 750px;
	height: 710px;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding: 20px;
	border-radius: 3px;
	background-color: #fff;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.37);
	border-radius: 3px;
`

export const Footer = styled.div`
	display: flex;
	flex-direction: row;
	padding-bottom: 30px;
	place-content: flex-end;
	padding: 20px;
	position: absolute;
	right: 0;
	bottom: 0;
`

export const LabelName = styled.div`
	margin-bottom: 0px;
	color: rgba(51, 51, 51, 0.9);
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	display: flex;
`

export const LabelRadio = styled.div`
	margin-bottom: 0px;
	color: rgba(51, 51, 51, 0.9);
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	display: flex;
	margin-left: 5px;
`

export const Input = styled.input`
	width: 100%;
	padding: 4px 5px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(51, 51, 51, 0.65);
	border-radius: 4px;
	color: rgba(51, 51, 51, 0.7);
	font-weight: 500;
`

export const InputArea = styled.textarea`
	display: flex;
	margin-top: 5px;
	width: 100%;
	flex-direction: column;
	height: 75px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	width: 100%;
	padding: 3px 5px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(51, 51, 51, 0.65);
	border-radius: 4px;
	color: rgba(51, 51, 51, 0.7);
	font-weight: 500;
`

export const Div1 = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 45px;
	margin-top: 20px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-start;
	flex-direction: column;
`

export const DivContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`
export const DivLeft = styled.div`
	width: 340px;
	padding-right: 20px;
`

export const DivCenter = styled.div`
	width: 340px;
`

export const DivRight = styled.div`
	width: 340px;
	padding-left: 20px;
`

export const DivLeft2 = styled.div`
	width: 380px;
	padding-right: 10px;
`

export const DivRight2 = styled.div`
	width: 380px;
	padding-left: 10px;
`

export const Link = styled.a`
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: rgb(55, 119, 208);
	color: rgb(55, 119, 208) !important;
	margin-top: 20px;
	:hover {
		cursor: pointer;
	}
`

export const Select = styled.select`
	display: block;
	width: 100%;
	height: 38px;
	padding: 3px 12px;
	margin-bottom: 0px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333;
	vertical-align: middle;
	border: 1px solid #ccc;
	height: 30px;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(51, 51, 51, 0.65);
	border-radius: 4px;
	padding-left: 8px;
`

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;

	.pf-form-content,
	.pj-form-content {
		display: none;
	}
`

export const ButtonIntegrate = styled.button`
	background-color: #004687;
	color: #ffffff;
	border: none;
	height: 28px;
	border-radius: 4px;
	margin-top: 20px;
	&:disabled {
		background-color: #4d4d4d;
		&:hover {
			cursor: not-allowed;
		}
	}
`

export const ButtonContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: right;
`

export const Header = styled.div`
	margin-bottom: 10px;
	font-size: 28px;
	line-height: 30px;
	font-weight: 500;
`
export const Abas = styled.div`
	font-size: 16px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	cursor: pointer;
`

export const PanelInfo = styled.div`
	display: flex;
	margin-right: 25px;
	align-items: center;
`
export const Code = styled.div`
	margin-bottom: 0px;
	color: rgba(51, 51, 51, 0.9);
	font-size: 12px;
	line-height: 16px;
	font-weight: 600;
	margin-right: 10px;
`
export const Message = styled.div`
	margin: 0 0 0px;
	margin-top: 10px;
	display: flex;
	flex-direction: column;
`
export const Characters = styled.div`
	font-size: 9px;
	line-height: small;
	align-self: flex-end;
	padding-right: 30px;
	@media screen and (max-width: 479px) {
		padding-left: 215px !important;
		position: static !important;
		margin-bottom: -10px !important;
		margin-top: 10px !important;
	}
`

export const PersonTypeSelector = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	width: 50%;
	cursor: pointer;
`

export const TechTypeSelector = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: row;
	width: 50%;
	cursor: pointer;
`
