import AddIcon from 'assets/image/addIcon.svg'
import $ from 'jquery'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import { StepType } from 'models/StepTypeEnum'
import Technician from 'models/Technician'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { getSerialInfo, techAssist, updateEquipPrev } from 'services/index'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import SelectTech, { ITechnicianForm } from '../NewOrder/SelectTech'
import './styles.scss'

interface Props {
	equipment?: Equipment
	client?: Client
	addressOS?: string
	technician?: Technician
	state_sigla?: string
	serviceOrderNumber?: string
	type: string
	handleChanges: (type: any, changes: any, worked: boolean) => void
	deviation?: any
}

interface IDateVisitForm {
	date: string
	technician: ITechnicianForm
}

const DateVisit: React.FC<Props> = ({
	client,
	equipment,
	addressOS,
	technician,
	state_sigla,
	serviceOrderNumber,
	type,
	handleChanges,
	deviation
}) => {
	const [type_code, setType_Code] = useState<string>('')
	const user = store.getState().user
	const [familyProd, setFamilyProd] = useState<boolean>(!equipment?.family_prod)
	const [selectedTechnician, setSelectedTechnician] = useState<
		ITechnicianForm | Technician | null
	>(null)
	const [selectTechnicianModal, setSelectTechnicianModal] =
		useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const { viewType } = useContext(DeviceContextElement)

	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
		setError
	} = useForm<IDateVisitForm>()

	useEffect(() => {
		setFamilyProd(!equipment?.family_prod)
	}, [!equipment?.family_prod])

	useEffect(() => {
		if (
			!!equipment?.serial_number &&
			!!client?.cod_protheus &&
			!!deviation?.id
		) {
			getSerialInfo(
				{
					serialNumber: equipment.serial_number,
					clientCode: client?.cod_protheus,
					equipmentRecno: deviation?.id
				},
				'ADMIN_APP'
			)
				.then((response) => {
					const data = response?.data

					if (data?.code === 200) {
						setType_Code(data.equipment.type_code)
					} else if (data?.code === 404) {
						toast.error(
							'Tivemos um problema localizando o equipamento. Tente novamente mais tarde'
						)
					}
				})
				.catch((error) => {
					if (error.message === 'Network Error') {
						$(`.form-1`).css('display', 'none')
						toast.error(
							'O serviço de busca da base instalada está indisponível no momento'
						)
					}
				})

			if (technician) {
				setValue('technician.name', technician?.name)
				setValue('technician.code_prp', technician?.code_prp as string)
				setValue('technician.phone', technician?.phone)
				setValue('technician.email', technician?.email)
				setValue('technician.address', technician?.address)
				setSelectedTechnician(technician)
			}
		}
	}, [equipment?.serial_number, deviation?.id])

	function onSubmit(data: IDateVisitForm) {
		setIsLoading(true)
		if (data?.technician?.code_prp)
			setError('technician.code_prp', { type: 'required' })

		const newVisit = {
			service_order: serviceOrderNumber,
			technician: {
				code_prp: data?.technician?.code_prp,
				phone: data?.technician?.phone,
				name: data?.technician?.name
			},
			appointment_date: data?.date,
			part_shipping_date: '',
			user_id: user.profile,
			step_type: StepType.NEW_VISIT
		}
		techAssist(newVisit)
			.then((resp) => {
				if (resp?.data?.statusCode === 422) {
					handleChanges('newVisit', 'user not found', false)
					return
				}
				resp.data.assistanceSaved.user = user
				const newData = resp?.data?.assistanceSaved
				newData.technician = resp?.data?.tech

				if (type === 'PREVENTIVA') {
					const app_date =
						newVisit.appointment_date === undefined
							? ''
							: newVisit.appointment_date
					const prevDate = new Date(app_date.toString())
					prevDate.setDate(prevDate.getDate() + 90)
					const update = {
						next_preventive: prevDate
					}
					const number =
						equipment?.serial_number === undefined
							? ''
							: equipment.serial_number
					updateEquipPrev(update, number).catch((error) => {
						console.log(error)
					})
				}
				setIsLoading(false)
				onClose()
				toast.success('Visita agendada com sucesso.')
				setTimeout(() => {
					window.location.reload()
				}, 1000)
			})
			.catch(() => {
				setIsLoading(false)
				toast.error('Já existe uma visita para essa data.')
			})
	}

	const onClose = () => {
		$(`.newVisit`).toggle()
		$('.data').find('input').val('')
		$('body').removeClass('modal-open')
	}

	const handleSelectTechnician = () => {
		setSelectTechnicianModal(true)
	}

	const handleOnSelectTechnician = (technician: ITechnicianForm) => {
		setValue('technician', technician)
		setSelectedTechnician(technician)
	}
	const selectedTechnicianContain = () => {
		return selectedTechnician ? (
			<div className="new-visit-select-technician">
				<div className="selected-technician-information">
					<div className="selected-technician-information-name">
						{selectedTechnician?.name}
					</div>
					<div className="selected-technician-information-contact">
						<span>{`ID: ${selectedTechnician?.code_prp}`}</span>
						<span>{selectedTechnician?.phone}</span>
						<span>{selectedTechnician?.email}</span>
					</div>
				</div>
				<div className="change-selected-technician-button">
					<Button
						size="sm"
						title="Trocar"
						variant="outline"
						onClick={handleSelectTechnician}
					/>
				</div>
			</div>
		) : (
			<div className="new-visit-select-technician-action">
				<img
					src={AddIcon}
					alt="Add technician icon"
					id="addTechnicianIcon"
					onClick={handleSelectTechnician}
				/>
				<label htmlFor="addTechnicianIcon" onClick={handleSelectTechnician}>
					Selecionar técnico
				</label>
			</div>
		)
	}

	return (
		<>
			<Modal
				show={true}
				onClose={onClose}
				title="Agendar nova visita"
				showClose={true}
			>
				<div className="header-new-visit-contain">
					<div className="form-header">
						<Equipments
							showFamilyProduct={true}
							client={client}
							equipment={equipment}
							onSelectProductFamily={(value) => {
								setFamilyProd(!value)
							}}
						/>
					</div>
					<Divider title="Informações da visita" />
					<div
						style={{
							flexDirection: viewType !== ViewType.MOBILE ? 'row' : 'column'
						}}
						className="new-visit-technician-information"
					>
						<div className="data-contain">
							<span>Data:</span>
							<Input
								variant="light"
								name="date"
								id="date"
								type="date"
								innerRef={{ ...register('date', { required: true }) }}
								errors={errors.date}
							/>
						</div>
						{selectedTechnicianContain()}
					</div>
					<div className="new-visit-button-action">
						<Button
							isLoading={isLoading}
							style={{ width: '120px' }}
							variant="confirmation-gradient"
							size="lg"
							title="Salvar"
							disabled={familyProd || !selectedTechnician?.code_prp}
							onClick={handleSubmit(onSubmit)}
						/>
						<Button
							isLoading={isLoading}
							style={{ width: '120px' }}
							variant="cancellation-gradient"
							size="lg"
							title="Cancelar"
							onClick={onClose}
						/>
					</div>
				</div>
				<LoadingProgress show={isLoading} text="Carregando..." />
			</Modal>
			<SelectTech
				onSelectTechnician={handleOnSelectTechnician}
				selectedTechnician={selectedTechnician && selectedTechnician}
				onClose={setSelectTechnicianModal}
				show={selectTechnicianModal}
				type={type}
				type_code={type_code}
				state_sigla={state_sigla}
				currentAddress={addressOS ? addressOS : ''}
				buttonReturn={false}
			/>
		</>
	)
}

export default DateVisit
