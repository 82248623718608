import React from 'react'
import './styles.scss'

/**
 * Component for the loading
 */
export const Loader = () => {
	return (
		<div className="loader-container">
			<div className="loader" />
		</div>
	)
}
