import styled from 'styled-components'

export const Container = styled.div`
	position: static;
	display: flex;
	overflow: hidden;
	width: 100%;
	max-height: 700px;
	margin-right: auto;
	margin-left: auto;
	align-items: stretch;
	border-radius: 3px;
	background-color: #f2f2f2;
	box-shadow: 12px 12px 20px -18px #000;

	@media screen and (max-width: 991px) {
		display: block;
		width: auto;
		height: auto;
	}
	@media screen and (max-width: 767px) {
		width: 100%;
		padding-top: 10px;
	}
	@media screen and (max-width: 479px) {
		position: relative;
		overflow: auto;
	}
`

export const RequestPanel = styled.div`
	width: 75%;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	@media screen and (max-width: 991px) {
		width: auto;
	}
	background-color: rgba(242, 242, 242, 0.32);
`

export const RequestOSInfo = styled.div`
	position: relative;
	overflow: auto;
	width: 28%;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	@media screen and (max-width: 991px) {
		width: auto;
	}
	@media screen and (max-width: 767px) {
		padding-right: 15px;
	}
	@media screen and (max-width: 479px) {
		position: static;
	}
`

export const Description = styled.div`
	padding-right: 5px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-align: left;
`

export const Info = styled.div`
	font-size: 15px;
	line-height: 20px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
`

export const Serial = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 15px;
	padding-left: 2px;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
`

export const Equipment = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	font-size: 28px;
	font-weight: 700;
	text-align: left;
`

export const Div = styled.div``

export const Cliente = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 14px;
	margin-bottom: 14px;
	padding-left: 2px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: 17px;
`

export const Cnpj = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 6px;
	padding-left: 2px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	font-size: 14px;
	font-weight: 300;
`

export const TipoSol = styled.div`
	margin-top: 15px;
	padding-right: 22px;
	padding-left: 2px;
`

export const Fil = styled.div`
	margin-top: 10px;
	padding-right: 22px;
	padding-left: 2px;
`

export const ItensSelect = styled.div`
	margin-top: 10px;
`

export const Title = styled.div`
	margin-left: 2px;
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const Section = styled.div`
	padding: 90px 100px 120px;
	text-align: center;
	text-transform: none;

	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	@media screen and (max-width: 767px) {
		width: 100%;
		padding: 0px 0px 0px;
		top: 100%;
		left: 100%;
		transform: translate(-100%, -100%);
	}

	@media screen and (max-width: 990px) and (min-width: 768px) {
		width: 100%;
		top: 100%;
		left: 100%;
		transform: translate(-100%, -100%);
	}
`

export const Pendecia = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 55px;
	margin-bottom: 6px;
	padding-left: 2px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	color: #b42020;
	font-size: 14px;
	font-weight: 300;
`

export const Header = styled.div`
	display: flex;
	position: center;
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 5px;
	margin-bottom: 5px;
`

export const ColInfo = styled.div`
	display: flex;
	width: 40%;
	position: center;
	padding-left: 20px;
	padding-right: 10px;
	cursor: pointer;
`
export const ColItem = styled.div`
	display: flex;
	width: 30%;
	position: center;
	padding-left: 20px;
	padding-right: 10px;
	cursor: pointer;
`
export const ColResumo = styled.div`
	display: flex;
	width: 30%;
	position: center;
	padding-left: 20px;
	padding-right: 10px;
	cursor: pointer;
`

export const ColClose = styled.div`
	display: flex;
	width: 5%;
	position: right;
	padding-right: 0px;
	cursor: pointer;
`

export const Div1 = styled.div`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	@media screen and (max-width: 767px) {
		font-weight: 600;
	}
`

export const TabLink = styled.div`
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	background-color: transparent;
	max-width: 100%;
	display: inline-block;
	position: center;
	display: inline-block;
	vertical-align: top;
	text-decoration: none;
	padding: 7px 90px;
	text-align: center;
	cursor: pointer;
	color: #222222;
	white-space: nowrap;
`

export const Info_Title = styled.div`
	font-size: 16px;
	font-weight: 700;
	text-align: left;
	text-transform: none;
	padding-left: 25px;
	margin-top: 20px;
`

export const AddressLabel = styled.label`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 15px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-weight: 500;
	text-align: left;
	display: block;
	margin-bottom: 5px;
	padding-left: 45px;
`

export const AddressSpan = styled.span`
	line-height: 16px;
	font-weight: 500;
	display: inline-block;
	cursor: pointer;
	font-weight: normal;
	margin-bottom: 0px;
`

export const Padding = styled.div`
	margin-top: 15px;
	padding-left: 25px;
	padding-right: 25px;
`

export const TextCust = styled.textarea`
	width: 100%;
	border-radius: 4px;
	background-color: white;
	height: auto;
	padding-top: 10.5px;
	padding-right: 14px;
	padding-bottom: 10.5px;
	padding-left: 14px;
	margin-bottom: 30px;

	@media screen and (max-width: 767px) {
		min-height: 174px;
	}
`

export const Div_Info = styled.div`
	overflow-y: auto;
	height: 560px;
`

export const ItensLine = styled.div`
	padding-top: 30px;
`
export const ItensLine1 = styled.div`
	padding-top: 10px;
`
export const ItemDescri = styled.div`
	margin-bottom: 15px;
	padding-top: 20px;
	padding-left: 20px;
	font-size: 16px;
	line-height: 20px;
	font-weight: 700;
	text-align: left;
`

export const Info_Resume = styled.div`
	font-size: 16px;
	font-weight: 600;
	text-align: left;
	text-transform: none;
	padding-left: 25px;
	margin-top: 10px;
`
export const Info2 = styled.div`
	font-size: 15px;
	line-height: 20px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
	padding-left: 25px;
	margin-top: 5px;
`

export const Table = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const Header1 = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-bottom: 2px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
`

export const ColItens = styled.div`
	width: 100%;
	min-height: 1px;
	padding-left: 10px;
	padding-right: 10px;
`

export const ItensTable = styled.div`
	display: flex;
	position: center;
	margin-left: 10px;
	margin-right: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
	font-weight: 500;
`

export const ItensAdded = styled.div`
	display: flex;
	position: center;
	margin-left: 10px;
	margin-right: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
	overflow-y: auto;
`

export const Col1 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 10%;
	position: center;
`

export const Col2 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 8%;
`

export const Col3 = styled.span`
	display: flex;
	padding-left: 10px;
	width: 7%;
	position: center;
`

export const Col4 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 7%;
	position: center;
`
export const Col5 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 45%;
	position: center;
`
export const Col6 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 6%;
	position: center;
`

export const Col7 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 9%;
	position: center;
`
export const Col8 = styled.div`
	display: flex;
	padding-left: 10px;
	width: 10%;
	position: center;
`
export const Col9 = styled.div`
	display: flex;
	padding-left: 15px;
	width: 7%;
	position: center;
`

export const Info_Summary = styled.div`
	font-size: 15px;
	line-height: 20px;
	font-style: normal;
	font-weight: 400;
	display: flex;
	padding-left: 20px;
`

export const Title_Summary = styled.div`
	display: flex;
	padding-left: 20px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
`

export const Resumo = styled.div`
	padding-top: 20px;
`

export const Total = styled.div`
	padding-top: 30px;
`

export const ItensResumo = styled.div`
	overflow-y: auto;
	display: flex;
	position: center;
	margin-left: 10px;
	margin-right: 0px;
	margin-top: 5px;
	margin-bottom: 5px;
`

export const ItensResumo2 = styled.div`
	height: 108px;
	overflow-y: auto;
`
