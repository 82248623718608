import styled from 'styled-components'

export const Container = styled.div`
    display flex;
    flex-direction: row;
    padding-left: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
export const LocalName = styled.div`
	width: 19%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const ZipCode = styled.div`
	width: 10%;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const Street = styled.div`
	width: 28%;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const Neighborhood = styled.div`
	width: 17.5%;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const City = styled.div`
	width: 16.5%;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const State = styled.div`
	width: 6%;
	padding-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`

export const EditImg = styled.div`
	width: 3%;
	cursor: pointer;
`

export const ModalCont = styled.div`
	background: white;
	font-size: 20px;
	width: auto;
	height: auto;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 3px;
	padding: 20px;
	font-size: 20px;
`

export const Message = styled.div`
	margin-bottom: 20px;
`

export const Footer = styled.div`
	display: flex;
	flex-direction: row-reverse;
`
