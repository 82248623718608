import styled from 'styled-components'

export const Protocol = styled.div`
	font-size: 30px;
	font-weight: 700;
	display: flex;
	margin-bottom: 16px;

	@media screen and (max-width: 991px) {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0px;
		margin-bottom: 25px;
	}

	@media screen and (max-width: 767px) {
		padding: 0px;
		margin-bottom: 25px;
	}
`
