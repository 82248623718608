import React, { ReactNode } from 'react'
import './styles.scss'

interface Props {
	title: string
	children: ReactNode
}

const Section = ({ title, children }: Props) => {
	return (
		<div className="section-component">
			<div className="section-component-title">
				<header>{title}</header>
			</div>
			<div className="section-component-body">
				<div className="section-component-body-div">{children}</div>
			</div>
		</div>
	)
}
export default Section
