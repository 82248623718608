import { all } from 'redux-saga/effects'
import adminSystem from './adminSystem/sagas'
import auth from './auth/sagas'
import baseLists from './baseLists/sagas'
import humanResources from './humanResources/sagas'
import orderManagement from './orderManagement/sagas'
import serviceOrders from './serviceOrders/sagas'
import techs from './technician/sagas'
import user from './user/sagas'

export default function* rootSaga() {
	return yield all([
		adminSystem,
		auth,
		baseLists,
		humanResources,
		techs,
		serviceOrders,
		orderManagement,
		user
	])
}
