import TechnicalAssists from 'models/TechnicalAssists'
import PropTypes from 'prop-types'
import React from 'react'
import Service from '../Service'

interface Props {
	services: TechnicalAssists[]
	type?: string
	originOS?: string
	equipment: any
	obs: any
	client: any
	lastTechAddress: any
	lastTechID: any
	firstUser?: any
	defect: string
}

const Timeline: React.FC<Props> = ({
	services,
	type,
	originOS,
	equipment,
	client,
	lastTechAddress,
	lastTechID,
	firstUser,
	obs,
	defect
}) => {
	return (
		<>
			{services.map(
				(
					{
						id,
						technician,
						service_order_id,
						appointment_date,
						closing_date,
						created_at,
						message,
						step_type,
						order_id,
						tech_assistance_files,
						files,
						user,
						id_field_control
					},
					index
				) => (
					<Service
						key={id}
						technician={technician}
						serviceOrderNumber={service_order_id}
						appointmentDate={appointment_date}
						dateCloseService={closing_date}
						tech_assistance_files={tech_assistance_files}
						createdAt={created_at}
						type={type}
						obs={obs}
						msg={message}
						originOS={originOS}
						stepType={step_type}
						order_id={order_id}
						isLast={index === services?.length - 1}
						equipment={equipment}
						client={client}
						lastTechAddress={lastTechAddress}
						lastTechID={lastTechID}
						user_name={
							services?.length === 1
								? firstUser
								: user?.name?.concat(' ')?.concat(user?.surname)
						}
						index={index}
						id_field_control={id_field_control}
						files={files}
						defect={defect}
					/>
				)
			)}
		</>
	)
}

Timeline.propTypes = {
	services: PropTypes.any
}

export default Timeline
