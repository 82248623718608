import Client from 'models/Client'
import { RequisitionError } from 'models/RequisitionError'

export interface ClientContextType {
	onRequestClient: (
		cpfCnpj: string,
		callback: (client?: Client, hasError?: RequisitionError) => void
	) => void
}

export const ClientContextValue = {
	onRequestClient: () => {}
}
