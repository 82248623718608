import React, { ReactNode } from 'react'
import './styles.scss'

type Props = {
	children: ReactNode
}

export const HeaderSectionRoot = ({ children }: Props) => {
	return <header className="header-section">{children}</header>
}
