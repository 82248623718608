import styled from 'styled-components'

export const CreatedAt = styled.div`
	font-size: 13px;
	text-align: left;
`

export const OrderActions = styled.div`
	margin-right: 20px;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: rgb(9, 86, 193);
	color: rgb(9, 86, 193);
	font-size: 13px;
	font-weight: 600;
	text-align: left;
	cursor: pointer;
`

export const DivActions = styled.div`
	display: flex;
	justify-content: flex-start;
`
