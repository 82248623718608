import $ from 'jquery'
import Location from 'models/Locations'
import React, { useEffect, useState } from 'react'
import { FaPencilAlt } from 'react-icons/fa'
import { MdCheck } from 'react-icons/md'
import { toast } from 'react-toastify'
import { updateEquipPrev } from 'services/index'
import {
	Container,
	EditImg,
	EquipNameCol,
	LocationCol,
	PreventiveCol,
	SerialNumCol
} from './styles'
interface Props {
	name: string
	serial: string
	preventive: number
	location: number
	locations?: Location[]
	updateCount: (value: any, previous: any) => void
	updateEquip: (preventive: number, location: number, serial: string) => void
}

const EquipModalPrev: React.FC<Props> = ({
	name,
	serial,
	preventive,
	location,
	locations,
	updateCount,
	updateEquip
}) => {
	const [edit, setEdit] = useState<boolean>(false)
	const [prev, setPrev] = useState<number>(0)
	const [local, setLocal] = useState<string>('')
	const [updated, setUpdated] = useState<boolean>(false)

	useEffect(() => {
		setPrev(preventive)
		const selectedLocation = locations?.find((e) => e.id === Number(location))
		selectedLocation ? setLocal(selectedLocation.name) : setLocal('')
	})

	function updateLayout(data: any) {
		setPrev(data.preventive)
		const selectedLocation = locations?.find(
			(e) => e.id === Number(data.location_id)
		)
		selectedLocation ? setLocal(selectedLocation.name) : setLocal('')
	}

	const onEditClick = () => {
		if (edit) {
			const preventiveOpt = $(`.selectPrev${serial}`).val()
			const locationOpt = $(`.selectLocation${serial}`).val()

			const update = {
				preventive: preventiveOpt,
				location_id: locationOpt
			}
			updateEquipPrev(update, serial)
				.then((res) => {
					updateLayout(update)
					setEdit(false)
					updateCount(preventiveOpt, prev)
					updateEquip(Number(preventiveOpt), Number(locationOpt), serial)
				})
				.catch((err) => {
					console.log(err)
					toast.error('Houve um erro ao atualizar informações do equipamento!')
					setEdit(false)
				})
		} else {
			setEdit(true)
		}
	}

	const selectLocationsContent = () => {
		return (
			<div style={{ width: '29%', display: '-webkit-box' }}>
				<select
					className={`selectLocation${serial}`}
					defaultValue={location}
					style={{
						padding: '0px 0px 0px 5px',
						display: 'block',
						margin: '0px auto 0px 0px'
					}}
					id={`select${serial}`}
				>
					{locations?.map((item, index) => {
						if (item.id == location) {
							return (
								<option key={index} value={item.id} selected>
									{item.name}
								</option>
							)
						} else {
							return (
								<option key={index} value={item.id}>
									{item.name}
								</option>
							)
						}
					})}
				</select>
			</div>
		)
	}

	const locationsContent = () => {
		return edit ? selectLocationsContent() : <LocationCol>{local}</LocationCol>
	}

	const selectPrevContent = () => {
		return (
			<div style={{ width: '18%', display: '-webkit-box' }}>
				{prev == 0 ? (
					<select
						className={`selectPrev${serial}`}
						style={{ padding: '0px', display: 'block', margin: '0px auto' }}
						id={`select${serial}`}
					>
						<option value={1}>Sim</option>
						<option value={0} selected>
							Nâo
						</option>
					</select>
				) : (
					<select
						className={`selectPrev${serial}`}
						style={{ padding: '0px', display: 'block', margin: '0px auto' }}
						id={`select${serial}`}
					>
						<option value={1} selected>
							Sim
						</option>
						<option value={0}>Nâo</option>
					</select>
				)}
			</div>
		)
	}

	const prevContent = () => {
		return edit ? (
			selectPrevContent()
		) : (
			<PreventiveCol>{!prev ? 'Não' : 'Sim'}</PreventiveCol>
		)
	}

	return (
		<Container key={serial}>
			<EquipNameCol>{name}</EquipNameCol>
			<SerialNumCol>{serial}</SerialNumCol>
			{prevContent()}
			{locationsContent()}
			<EditImg onClick={onEditClick}>
				{edit ? <MdCheck /> : <FaPencilAlt />}
			</EditImg>
		</Container>
	)
}

export default EquipModalPrev
