import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import { ItensDetailsListAccessoriesBodyMobileEditingMode } from './Mobile/ItensDetailsListAccessoriesBodyMobileEditingMode'
import { ItensDetailsListAccessoriesBodyWebEditingMode } from './Web/ItensDetailsListAccessoriesBodyWebEditingMode'

interface Props {
	accessories: any[]
	itemSelect?: any
	onChangeAccessories: (accessories: any) => void
	onClose: () => void
}

export const ItensDetailsListAccessoriesBodyEditingMode = ({
	accessories,
	itemSelect,
	onChangeAccessories,
	onClose
}: Props) => {
	const { viewType } = useContext(DeviceContextElement)
	return viewType !== ViewType.MOBILE ? (
		<ItensDetailsListAccessoriesBodyWebEditingMode
			{...{ accessories, itemSelect, onChangeAccessories, onClose }}
		/>
	) : (
		<ItensDetailsListAccessoriesBodyMobileEditingMode
			{...{ accessories, itemSelect, onChangeAccessories, onClose }}
		/>
	)
}
