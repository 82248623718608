import React from 'react'

const LockIcon: React.FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="12px"
			height="16px"
			x="0px"
			y="0px"
			viewBox="0 0 19.1 25.4"
			fill="#004992"
		>
			<style type="text/css"></style>
			<g transform="translate(-1603 -7423)">
				<path
					d="M1619.6,7431.6h-1.2v-2.4c0-3.2-2.5-5.9-5.8-5.9c-3.2,0-5.9,2.5-5.9,5.8c0,0.1,0,0.1,0,0.2v2.4h-1.2
        c-1.3,0-2.4,1.1-2.3,2.4v11.9c0,1.3,1,2.4,2.3,2.4h14.1c1.3,0,2.4-1.1,2.3-2.4v-11.9C1621.9,7432.6,1620.9,7431.6,1619.6,7431.6z
        M1612.6,7442.3c-1.3,0-2.4-1-2.4-2.3c0-1.3,1-2.4,2.3-2.4s2.4,1,2.4,2.3c0,0,0,0,0,0C1614.9,7441.2,1613.9,7442.3,1612.6,7442.3z
        M1616.2,7431.6h-7.3v-2.4c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6c0,0,0,0,0,0V7431.6z"
				/>
			</g>
		</svg>
	)
}

export default LockIcon
