import { Menu, MenuItem } from '@material-ui/core'
import OptionIcon from 'assets/image/Option.svg'
import { MoreOptionsItem } from 'models/MoreOptionsItem'
import React from 'react'
import './styles.scss'

interface Params {
	id: string
	options: MoreOptionsItem[]
}

const MoreOptions: React.FC<Params> = ({ id, options }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onClick = (option: MoreOptionsItem) => {
		option.onClick(option.item)
		handleClose()
	}

	const menuListContent = () => {
		return options.map((option: MoreOptionsItem, index: number) => {
			return (
				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						onClick(option)
					}}
					key={index}
				>
					{option.label}
				</MenuItem>
			)
		})
	}

	const menuContent = () => {
		return (
			<Menu
				id={id}
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={(e: any) => {
					e.stopPropagation()
					handleClose()
				}}
			>
				{menuListContent()}
			</Menu>
		)
	}

	const mainContent = () => {
		return (
			<>
				<img
					className="more-option-item-click"
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={(event) => handleClick(event)}
					src={OptionIcon}
					alt=""
				/>
				{menuContent()}
			</>
		)
	}

	return mainContent()
}

export default MoreOptions
