import { TextField } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { MdSearch } from 'react-icons/md'
import './styles.scss'

interface Params {
	id?: string
	className?: string
	selectedItem?: any
	listItems: any[]
	loadingText: string
	placeHolder: string
	keyField: string
	filterField: string
	filterOptions?: any
	onChangeValue: (value: string) => void
	onSelectValue: (item: any) => void
}

const AutoCompleteInput: React.FC<Params> = ({
	id,
	className,
	listItems,
	filterField,
	keyField,
	selectedItem,
	loadingText,
	placeHolder,
	filterOptions,
	onSelectValue,
	onChangeValue
}) => {
	const getItemSelect = () => {
		if (selectedItem && listItems?.length) {
			return (
				listItems?.find((d) => d[keyField] === selectedItem[keyField]) || ''
			)
		} else {
			return ''
		}
	}

	const inputProps = (params: any) => {
		const props = { ...params.InputProps }
		props.startAdornment = (
			<InputAdornment position="start">
				<MdSearch />
			</InputAdornment>
		)

		return props
	}

	return (
		<div className="auto-complete-form-content">
			<Autocomplete
				freeSolo
				id="auto-complete-popup-selector"
				disableClearable
				options={listItems}
				getOptionLabel={(item: any) =>
					(item && item[filterField]?.trim()) || ''
				}
				onChange={(e: React.ChangeEvent<object>, item: any) =>
					onSelectValue(item)
				}
				className={
					className
						? `auto-complete-selector ${className}`
						: 'auto-complete-selector'
				}
				loadingText={loadingText}
				value={getItemSelect()}
				filterOptions={filterOptions}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={placeHolder}
						margin="normal"
						onChange={(e: any) => {
							onChangeValue(e.target.value)
						}}
						variant="outlined"
						InputProps={{ ...inputProps(params), type: 'search' }}
					/>
				)}
			/>
		</div>
	)
}

export default AutoCompleteInput
