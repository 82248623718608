import React from 'react'

const TechnicalServiceCardIcon: React.FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="50"
			viewBox="10 5 30 30"
			fill="url(#gradient)"
			className="techservice-icon"
		>
			<linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
				<stop offset="0%" stopColor="#4F8FE9" stopOpacity="0.6" />
				<stop offset="45%" stopColor="#396BC9" stopOpacity="0.6" />
				<stop offset="100%" stopColor="#5e50ae" stopOpacity="0.6" />
			</linearGradient>
			<rect fill="url(#gradient)" />
			<g transform="translate(-247 -447)">
				<g transform="translate(-0.501 -235)">
					<path
						d="M15,7.278A45.806,45.806,0,0,1,4.394,6.18C1.56,5.473,0,4.531,0,3.529S1.56,1.585,4.394.877A36.591,36.591,0,0,1,9.9,0l1.941,1.862a32.789,32.789,0,0,0-5.156.626c-1.329.3-2.032.663-2.032,1.041,0,.965,4.641,1.75,10.345,1.75s10.344-.785,10.344-1.75c0-.377-.7-.737-2.03-1.04a32.7,32.7,0,0,0-5.15-.626L20.107,0a36.59,36.59,0,0,1,5.5.876C28.44,1.585,30,2.527,30,3.529S28.44,5.473,25.607,6.18A45.805,45.805,0,0,1,15,7.278Z"
						transform="translate(257.501 709.728)"
					/>
					<path
						d="M11.5,26.176a1.045,1.045,0,0,1-.69-.259L6.723,21.841A11.416,11.416,0,0,1,.466,8.214,11.346,11.346,0,0,1,4.638,2.26,11.527,11.527,0,0,1,11.492,0a11.668,11.668,0,0,1,1.649.118,11.521,11.521,0,0,1,7.054,3.835,11.413,11.413,0,0,1-3.917,17.889L12.19,25.917A1.046,1.046,0,0,1,11.5,26.176Zm0-24.089a9.358,9.358,0,1,0,9.409,9.358A9.394,9.394,0,0,0,11.5,2.087Z"
						transform="translate(261.001 687)"
					/>
					<path
						d="M7.219,9V8.438H3.781V9H1.719V8.438H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0h9a1,1,0,0,1,1,1V7.438a1,1,0,0,1-1,1H9.282V9ZM.71,6.74a1,1,0,0,0,1,1H9.29a1,1,0,0,0,1-1V2.025H.71ZM1.759,3.376a.337.337,0,1,1,0-.675H9.242a.337.337,0,1,1,0,.675Z"
						transform="translate(267 694.3)"
					/>
				</g>
			</g>
		</svg>
	)
}

export default TechnicalServiceCardIcon
