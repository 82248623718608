import logoPratica from 'assets/image/new-logo-prp.webp'
import VersionArea from 'module/entryPoint/components/Version'
import React from 'react'
import { history } from 'routes/history'
import { RouteList } from 'utils/Constants'
import './styles.scss'
interface Params {
	children?: React.ReactNode
}

const SideBar: React.FC<Params> = ({ children }: Params) => {
	return (
		<section className="side-bar-content">
			<div className="logo-pratica">
				<img
					src={logoPratica}
					onClick={() => {
						history.push(RouteList.HOME)
					}}
				/>
			</div>
			{children}
			<VersionArea />
		</section>
	)
}

export default SideBar
