import React from 'react'
import { Step } from 'react-joyride'

export const StepsOrderlist: Step[] = [
	{
		title: <p>Alerta de Notificações</p>,
		content: (
			<p>Ao receber uma notificação, você receberá um alerta por aqui</p>
		),
		target: '#joyride-ringbell',
		placement: 'left',
		disableOverlayClose: true
	},
	{
		title: <p>Informações do usuário</p>,
		content: (
			<p>
				Aqui é possível editar suas informações de usuário, trocar seus dados de
				contato, alterar sua senha, etc.
			</p>
		),
		target: '#joyride-userinfo',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		title: <p>Filtrar</p>,
		content: (
			<p>
				Você pode customizar seus filtros de Ordens de Serviço e utiliza-los
				posteriormente. Exemplo: Filtrar ordens de serviço em garantia do estado
				do Amazonas, etc..
			</p>
		),
		target: '#joyride-filterbutton-servorders',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		title: <p>Barra de pesquisa</p>,
		content: (
			<p>
				É possível buscar suas ordens de serviços por aqui. Você pode buscar
				pelo número do protocolo PRP, número de série do equipamento ou nome
				fantasia do cliente
			</p>
		),
		target: '#joyride-searchbar-servorders',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		title: <p>Transferência de titularidade</p>,
		content: (
			<p>
				Ao acessar esse menu, você poderá realizar transferências de
				titularidade do equipamento sem precisar acessar o Protheus. Também
				poderá transferir titularidades de equipamentos que foram solicitados
				por nossos clientes no APP Prática.
			</p>
		),
		target: '#joyride-equipment-transfers',
		placement: 'auto',
		disableOverlayClose: true
	}
]

export const StepsEquipTransfer: Step[] = [
	{
		title: <p>Nova transfência</p>,
		content: (
			<p>
				Vamos começar pelo botão de nova transferência. Por aqui você poderá
				criar Novas transferências para os equipamentos que precisar transferir
				titularidade (Exemplo: equipamento está em nome de uma revenda e precisa
				transferir para o cliente final).
			</p>
		),
		target: '#joyride-newtransfer-button',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		title: <p>Barra de pesquisa</p>,
		content: (
			<p>
				Por aqui você poderá pesquisar equipamentos que tiveram transferências
				solicitadas ou registradas na lista abaixo. A barra de pesquisa aceita o
				nome fantasia do cliente atual do equipamento e o número de série.
			</p>
		),
		target: '#joyride-transfer-searchbar',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		title: <p>Lista de transferências</p>,
		content: (
			<p>
				Aqui serão exibidas todas as transferências solicitadas ou registradas.
				Transferências solicitadas pelo aplicativo do cliente APP Prática, serão
				listadas com o canal Aplicativo e transferências feitas manualmente
				serão registradas com o canal Manual.
			</p>
		),
		target: '#joyride-transfer-listview',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		title: <p>Menu de opções</p>,
		content: (
			<p>
				Nesse menu você conseguirá Atender ou Excluir uma solicitação de
				transferência. IMPORTANTE: Excluir uma solicitação não desfaz uma
				transferência concluída. É preciso realizar uma nova transferência para
				o cliente anterior caso queira reverter uma transferência realizada.
			</p>
		),
		target: '#joyride-transfer-options',
		placement: 'auto',
		disableOverlayClose: true
	}
]

export const StepsTransferDetailsInfoTab: Step[] = [
	{
		title: <p>Janela de transferência</p>,
		content: (
			<p>
				{' '}
				Por aqui você encontrará os detalhes da transferência que precisa ser
				realizada. Utilize o botão <strong>Alterar cliente</strong> para editar
				as informações do Cliente de destino, e utilize o{' '}
				<strong>Alterar equipamento</strong> para editar o equipamento da
				transferência.{' '}
			</p>
		),
		target: '#joyride-transfer-details',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		content: (
			<p>
				Aqui é possível alterar o cliente de destino, note que somente é
				possível clicar neste campo se a solicitação de transferência estiver{' '}
				<strong>Atendida</strong>.
			</p>
		),
		target: '#joyride-client-transferdetail',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		content: (
			<p>
				Aqui é possível alterar o equipamento da solicitação de transferência,
				note que somente é possível clicar neste campo se a solicitação de
				transferência estiver <strong>Atendida</strong>.
			</p>
		),
		target: '#joyride-equipment-transferdetail',
		placement: 'auto',
		disableOverlayClose: true
	},
	{
		content: (
			<p>
				Clique em <strong>Transferir</strong> e aperte Próximo para seguir com o
				tutorial.
			</p>
		),
		target: '#joyride-transfercontent-button',
		placement: 'auto',
		disableOverlayClose: true,
		spotlightClicks: true
	}
]

export const StepsTransferDetailsTransferTab: Step[] = [
	{
		content: (
			<p>
				Aqui estão os detalhes de sua transferência. Se estiver tudo ok,
				selecione as duas caixas de seleção, corrija data da venda e data de
				garantia, e acrescente as observações inerentes a transferência em
				questão.
			</p>
		),
		target: '#joyride-transfercontent-form',
		placement: 'auto',
		disableOverlayClose: true
	}
]

export const handleStepTitles = (steps: any[]) =>
	`"${steps.map(
		(step: { title: { props: any } }) => step?.title?.props?.children
	)}"`
