import { Order } from 'module/orderManagement/domain/models/order'
import { sumOrderTaxes } from 'module/orderManagement/utils/sumOrderTaxes'
import React, { useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Divider from 'shared/components/Divider'
import DiscountPolicy from '../DiscountPolicy'
import './styles.scss'

export const PaymentDiscountsContent = ({ order }: { order?: Order }) => {
	const [showDiscountPolicy, setShowDiscountPolicy] = useState<boolean>(false)

	return (
		<>
			<div className="new-order-payment-contain-information">
				<div className="new-order-payment-contain-discount">
					<Divider style={{ marginBottom: '15px' }} title="DESCONTOS" />
					{order?.total_with_taxes ? (
						<div className="discounts">
							<div>
								<label htmlFor="">
									SUFRAMA:{' '}
									<span>
										{sumOrderTaxes(order).suframa.toLocaleString('pt-br', {
											minimumFractionDigits: 2,
											style: 'currency',
											currency: 'BRL'
										})}
									</span>
								</label>
								<label htmlFor="">
									Desconto 1:
									<span>
										{` ${order?.discounts.one.toLocaleString('pt-br', {
											minimumFractionDigits: 2
										})} %`}
									</span>
								</label>
								<label htmlFor="">
									Desconto 2:
									<span>
										{` ${order?.discounts.two.toLocaleString('pt-br', {
											minimumFractionDigits: 2
										})} %`}
									</span>
								</label>
								<label htmlFor="">
									Desconto 3:
									<span>
										{` ${order?.discounts.three.toLocaleString('pt-br', {
											minimumFractionDigits: 2
										})} %`}
									</span>
								</label>
								<label htmlFor="">
									Descontos totais:{' '}
									<span>
										{order?.discounts.total.toLocaleString('pt-br', {
											minimumFractionDigits: 2,
											style: 'currency',
											currency: 'BRL'
										})}
									</span>
								</label>
							</div>
						</div>
					) : (
						<div className="discounts">
							<Skeleton height={18} />
							<Skeleton height={18} />
							<Skeleton height={18} />
							<Skeleton height={18} />
							<Skeleton height={18} />
						</div>
					)}
					<a onClick={() => setShowDiscountPolicy(true)}>
						Acessar a política de desconto
					</a>
				</div>
			</div>
			{showDiscountPolicy && (
				<DiscountPolicy
					show={showDiscountPolicy}
					onClose={() => setShowDiscountPolicy(false)}
				/>
			)}
		</>
	)
}
