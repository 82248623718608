import React, { ReactNode } from 'react'
import Section from 'shared/components/Section'
import './styles.scss'

export const ClientAccountingInformationRoot = ({
	children
}: {
	children: ReactNode
}) => (
	<Section title="Informações contábeis">
		<div className="new-accounting-information">{children}</div>
	</Section>
)
