import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import brLocale from 'date-fns/locale/pt-BR'
import {
	FederalStatesList,
	OrderBranchTypes,
	OrderCategoryTypes,
	OrderStatusTypes,
	PendencyTypes,
	ResponsibleSolutionTypes
} from 'module/orderManagement/utils/constants'
import React from 'react'
import Divider from 'shared/components/Divider'
import Select from 'shared/components/Select'
import { store } from 'store'
import './styles.scss'

interface Props {
	salesPersonCode?: string[]
	onSelectSalesPerson: (selectOptions: string[]) => void
	federatedState?: string[]
	onSelectFederalState: (selectOptions: string[]) => void
	orderStatus?: string[]
	onSelectOrderStatus: (selectOptions: string[]) => void
	orderCategory?: string[]
	onSelectOrderCategory: (selectOptions: string[]) => void
	orderBranch?: string[]
	onSelectOrderBranch: (selectOptions: string[]) => void
	pendencyCode?: string[]
	onSelectPendencyCode: (selectOptions: string[]) => void
	pendencyType?: string[]
	onSelectPendencyType: (selectOptions: string[]) => void
	pendencyIssueResolverTeam?: string[]
	onSelectPendencyIssueResolverTeam: (selectOptions: string[]) => void
	pendencyIssueResolver?: string[]
	onSelectPendencyIssueResolver: (selectOptions: string[]) => void
	pendencyDate?: string[]
	onSelectPendencyDate: (selectOptions: string[]) => void
	chosenDate?: MaterialUiPickersDate
	onChosenDatePendency: (value: MaterialUiPickersDate) => void
	onError: (value: any) => void
}

export const listChangeData = [
	{ value: 'overdue', label: 'Vencidas' },
	{ value: 'today', label: 'Hoje' },
	{ value: 'future', label: 'Futuras' }
]

const FilterFields = ({
	onSelectSalesPerson,
	salesPersonCode,
	onSelectFederalState,
	federatedState,
	onSelectOrderStatus,
	orderStatus,
	onSelectOrderCategory,
	orderCategory,
	onSelectOrderBranch,
	orderBranch,
	onSelectPendencyCode,
	pendencyCode,
	onSelectPendencyType,
	pendencyType,
	onSelectPendencyIssueResolverTeam,
	pendencyIssueResolverTeam,
	onSelectPendencyIssueResolver,
	pendencyIssueResolver,
	onSelectPendencyDate,
	pendencyDate,
	chosenDate,
	onChosenDatePendency,
	onError
}: Props) => {
	const { salespersonList, availablePendencyList, protheusUserList } =
		store.getState().baseLists

	const mainContent = () => {
		return salesPersonCode &&
			federatedState &&
			orderStatus &&
			orderCategory &&
			orderBranch ? (
			<div className="orders-filter-fields">
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Representante</label>
						<Select
							id="salesperson-select"
							tooltip="right-start"
							options={salespersonList}
							checkbox={true}
							selectedOption={salesPersonCode}
							onSelectOptions={onSelectSalesPerson}
						/>
					</div>
					<div className="filter-fields-section-option">
						<label>Estado</label>
						<Select
							checkbox={true}
							id="federal-state-select"
							options={FederalStatesList}
							selectedOption={federatedState}
							onSelectOptions={onSelectFederalState}
						/>
					</div>
				</div>
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Status</label>
						<Select
							id="order-status-select"
							options={OrderStatusTypes}
							selectedOption={orderStatus}
							onSelectOptions={onSelectOrderStatus}
						/>
					</div>

					<div className="filter-fields-section-option">
						<label>Filial</label>
						<Select
							id="order-branch-select"
							options={OrderBranchTypes}
							selectedOption={orderBranch}
							onSelectOptions={onSelectOrderBranch}
						/>
					</div>
				</div>
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Categoria</label>
						<Select
							checkbox={true}
							id="order-category-select"
							options={OrderCategoryTypes}
							selectedOption={orderCategory}
							onSelectOptions={onSelectOrderCategory}
							tooltip="right-start"
						/>
					</div>
					<div className="filter-fields-section-option"></div>
				</div>
				<Divider title="Pendências" style={{ marginBottom: '20px' }} />
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Nome da Pendência</label>
						<Select
							id="pendency-code-select"
							tooltip="right-start"
							options={[
								...availablePendencyList.commercial_pending,
								...availablePendencyList.financial_pending,
								...availablePendencyList.administrative_pending,
								...availablePendencyList.shipment_pending
							]}
							checkbox={true}
							selectedOption={pendencyCode || []}
							onSelectOptions={onSelectPendencyCode}
						/>
					</div>
					<div className="filter-fields-section-option">
						<label>Tipo de Pendência</label>
						<Select
							id="pendency-type-select"
							options={PendencyTypes}
							selectedOption={pendencyType || []}
							onSelectOptions={onSelectPendencyType}
							checkbox={true}
						/>
					</div>
				</div>
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Responsável da Solução</label>
						<Select
							id="pendency-resolver-select"
							options={protheusUserList}
							selectedOption={pendencyIssueResolver || []}
							onSelectOptions={onSelectPendencyIssueResolver}
						/>
					</div>
					<div className="filter-fields-section-option">
						<label>Setor Resp. da Solução</label>
						<Select
							id="pendency-resolver-team-select"
							options={ResponsibleSolutionTypes}
							selectedOption={pendencyIssueResolverTeam || []}
							onSelectOptions={onSelectPendencyIssueResolverTeam}
							checkbox={true}
						/>
					</div>
				</div>
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Prazo de pendência</label>
						<Select
							id="pendency-date-select"
							options={listChangeData}
							selectedOption={pendencyDate || []}
							onSelectOptions={onSelectPendencyDate}
							checkbox
						/>
					</div>
					<div className="filter-fields-section-option">
						<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
							<div className="period-filter-fields-div">
								<span>Data pendência</span>
								<KeyboardDatePicker
									cancelLabel="CANCELAR"
									clearLabel="LIMPAR"
									okLabel="OK"
									emptyLabel="DD/MM/AAAA"
									format="dd/MM/yyyy"
									id="date-chosen-picker"
									invalidDateMessage="Data invalida"
									value={chosenDate || null}
									onChange={(value: MaterialUiPickersDate) => {
										onChosenDatePendency(value)
									}}
									onError={(e, value) => {
										onError(value)
									}}
									KeyboardButtonProps={{
										'aria-label': 'change date',
										className: 'period-filter-keyboard-button'
									}}
								/>
							</div>
						</MuiPickersUtilsProvider>
					</div>
				</div>
			</div>
		) : (
			<></>
		)
	}

	return mainContent()
}

export default FilterFields
