import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import Pendency from 'module/orderManagement/models/Pendency'
import { handlePaymentFormType } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { RouteComponentProps } from 'react-router-dom'
import Button from 'shared/components/Button'
import EmptyList from 'shared/components/EmptyList'
import Modal from 'shared/components/Modal'
import Radio from 'shared/components/Radio'
import AllPendencyListBody from './AllPendencyListBody'
import AllPendencyListHeader from './AllPendencyListHeader'
import NewPendency from './NewPendency'
import SendPendency from './SendPendency'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
}

const OrderPendencyTab: React.FC<TParams> = ({ match, ...rest }) => {
	const { order, pendencies, onOrderChange } = useContext(
		OrderDetailsContextElement
	)
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const [selectedContact, setSelectedContact] = useState<{
		target: 'client' | 'salesperson' | ''
		phone?: string
		email?: string
	}>({ target: '', phone: '', email: '' })
	const [showSendPendencyModal, setShowSendPendencyModal] =
		useState<boolean>(false)
	const [showNewPendencyModal, setShowNewPendencyModal] =
		useState<boolean>(false)
	const [checkedPendency, setCheckedPendency] = useState<Pendency[]>([])
	const { orderCode, branch } = match.params

	useEffect(() => {
		if (order?.code !== orderCode) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	const listPendencyContain = () => {
		return order && pendencies?.length ? (
			<div className="order-pendency-tab-main-contain">
				<AllPendencyListHeader />
				<AllPendencyListBody
					pendencies={pendencies}
					onSelectPendency={setCheckedPendency}
					{...rest}
				/>
			</div>
		) : (
			<div className="order-pendency-tab-main-contain">
				<EmptyList
					show={!pendencies?.length}
					text={'Nenhuma pendência encontrada!'}
				/>
			</div>
		)
	}

	const pendencyFooterActionsContain = () => {
		return (
			<div className="order-pendency-tab-footer">
				<span>Selecione uma ação</span>
				<form className="order-pendency-tab-footer-options">
					<div className="order-pendency-tab-footer-options-option">
						<Radio
							type={'radio'}
							variant="blue"
							id={'pendency'}
							checked={selectedContact.target === 'client'}
							onChange={() =>
								setSelectedContact({
									target: 'client',
									email: sa1010?.a1Email,
									phone: `${sa1010?.a1Ddd} ${sa1010?.a1Tel}`
								})
							}
							name="enviar para cliente"
						/>
					</div>
					<div className="order-pendency-tab-footer-options-option">
						<Radio
							type={'radio'}
							variant="blue"
							id={'not-pendency'}
							checked={selectedContact.target === 'salesperson'}
							onChange={() =>
								setSelectedContact({
									target: 'salesperson',
									email: order?.seller.email,
									phone: order?.seller.phone
								})
							}
							name="enviar para representante"
						/>
					</div>
				</form>
				<div className="order-pendency-tab-footer-actions">
					<div className="order-pendency-tab-footer-actions-button">
						<Button
							title="CONFIRMAR"
							variant="confirmation-gradient"
							size="lg"
							onClick={() => setShowSendPendencyModal(true)}
						/>
					</div>
				</div>
			</div>
		)
	}

	const mainContain = () => {
		return (
			<div className="order-pendency-tab">
				<div className="order-pendency-tab-header">
					<div className="order-pendency-tab-header-titles">
						<span>Forma de Pagamento: </span>
						<span>
							{order?.payment_code ? (
								handlePaymentFormType(order?.payment_code)
							) : (
								<Skeleton />
							)}
						</span>
					</div>
					<div className="order-pendency-tab-header-actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							title="NOVA PENDÊNCIA"
							onClick={() => setShowNewPendencyModal(true)}
						></Button>
					</div>
				</div>
				{listPendencyContain()}
				{checkedPendency?.length > 0 && pendencyFooterActionsContain()}
			</div>
		)
	}

	return order && sa1010 ? (
		<>
			<OrderMenuTabs path={match.path} />
			<TabTitles />
			{mainContain()}
			<Modal
				showClose={true}
				title="ENVIAR PENDÊNCIAS"
				show={showSendPendencyModal}
				onClose={() => setShowSendPendencyModal(false)}
			>
				{order && (
					<SendPendency
						onCancel={setShowSendPendencyModal}
						checkedPendency={checkedPendency}
						order={order}
						contacts={selectedContact}
					/>
				)}
			</Modal>
			<Modal
				showClose={true}
				title="ADICIONAR PENDÊNCIA"
				show={showNewPendencyModal}
				onClose={() => setShowNewPendencyModal(false)}
				enableAwayClose={true}
			>
				{order && (
					<NewPendency onCancel={setShowNewPendencyModal} order={order} />
				)}
			</Modal>
		</>
	) : (
		<EmptyList
			show={!(order && sa1010)}
			text={'Nenhuma informação do pedido encontrada!'}
		/>
	)
}
export default OrderPendencyTab
