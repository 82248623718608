import React from 'react'
import Skeleton from 'react-loading-skeleton'
import LoadingProgress from 'shared/components/LoadingProgress'
import './styles.scss'

type TabTitlesProps = {
	title: string
	subtitle: string
	isLoading: boolean
}

const TabTitles = ({ title, subtitle, isLoading }: TabTitlesProps) => {
	return (
		<>
			<div className="product-titles">
				<>
					<div className="product-titles-div">
						<span className="description-title">
							{isLoading ? <Skeleton width={700} height={18} /> : title}
						</span>
					</div>
					<span className="family-product-title">
						{isLoading ? <Skeleton width={200} height={18} /> : `${subtitle}`}
					</span>
				</>
			</div>
			{isLoading && <LoadingProgress show text="carregando..." />}
		</>
	)
}
export default TabTitles
