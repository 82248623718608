import OpenedOS from 'models/OpenedOS'
import NewServiceOrder from 'module/technicalServices/components/NewOrder'
import OpenOS from 'module/technicalServices/components/OpenOS'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getOpenOs, getSerialInfo, searchMultipleEquipments } from 'services'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { updateModalStatus } from 'store/modules/user/actions'
import { KeyedMutator } from 'swr'
import './styles.scss'

interface Props {
	show: boolean
	mutate: KeyedMutator<any>
	onClose: (value: boolean) => void
}
interface Form {
	serial: string
}

export const SelectSerial = ({ mutate, show, onClose }: Props) => {
	const [showNewOrder, setShowNewOrder] = useState<boolean>(false)
	const [showOpenOs, setShowOpenOs] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [protocolos, setProtocolos] = useState<OpenedOS[]>([])
	const [equipments, setEquipments] = useState([])
	const [selectedEquipment, setSelectedEquipment] = useState<{
		serialNumber: string
		clientCode: string
		equipmentRecno: string
	}>({} as { serialNumber: string; clientCode: string; equipmentRecno: string })
	const [equipment, setEquipment] = useState<any>()
	const [client, setClient] = useState<any>()
	const dispatch = useDispatch()

	const {
		reset,
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<Form>()

	useEffect(() => {
		reset()
		dispatch(updateModalStatus(false))
	}, [show])

	useEffect(() => {
		if (show && !!selectedEquipment.serialNumber) {
			const promises = [
				getOpenOs(selectedEquipment, undefined),
				getSerialInfo(selectedEquipment, 'ADMIN_APP')
			]
			Promise.all(promises)
				.then((responses) => {
					setIsLoading(false)
					const { os } = responses[0].data
					const { client, equipment, code } = responses[1].data

					if (code === 404) {
						toast.error('Numero de série não localizado.')
						return
					}

					setClient(client)
					setEquipment(equipment)

					if (responses[0]?.status === 200 && os?.length > 0) {
						setProtocolos(os)
						setShowOpenOs(true)
					} else {
						setShowNewOrder(true)
					}
				})
				.catch(() => {
					setIsLoading(false)
					toast.error('Erro ao buscar informações do numero de série.')
				})
		}
	}, [selectedEquipment])

	const onSubmit = async (data: Form) => {
		setIsLoading(true)
		try {
			const { serial } = data
			const equipmentsFounded = await searchMultipleEquipments(serial)
			if (equipmentsFounded?.data?.length === 0) {
				throw new Error('Numero de série não localizado.')
			}
			if (equipmentsFounded?.data?.length === 1) {
				setSelectedEquipment({
					serialNumber: equipmentsFounded?.data[0]?.serialNumber,
					clientCode: equipmentsFounded?.data[0]?.clientCode,
					equipmentRecno: equipmentsFounded?.data[0]?.equipmentRecno
				})
			} else {
				setEquipments(equipmentsFounded?.data)
				setIsLoading(false)
			}
		} catch (error) {
			toast.error('Numero de série não localizado.')
			setIsLoading(false)
		}
	}

	function handleToggleStatus() {
		setShowNewOrder(false)
		setShowOpenOs(false)
		onClose(false)
		setClient(undefined)
		setEquipment(undefined)
		setSelectedEquipment(
			{} as { serialNumber: string; clientCode: string; equipmentRecno: string }
		)
		setEquipments([])
	}

	const onContinueOS = () => {
		setShowOpenOs(false)
		setShowNewOrder(true)
	}

	return (
		<Modal
			show={show}
			onClose={handleToggleStatus}
			title="Solicitação de atendimento"
			showClose={true}
		>
			<div className="select-serial">
				<div className="select-serial-main-contain">
					<span>Número de série:</span>
					<Input
						id="serial"
						name="serial"
						variant="light"
						placeholder="Informe um N/S"
						errors={errors.serial}
						innerRef={{
							...register('serial', {
								required: {
									value: true,
									message: 'O campo numero de série é obrigatório!'
								}
							})
						}}
					/>
					{!!equipments?.length && (
						<>
							<div className="select-serial-equipment-header">
								<ul className="list-item-equipment">Equipamento</ul>
								<ul className="list-item-client">Cliente</ul>
							</div>
							<div className="select-serial-equipment-list">
								{equipments.map((equip: any, index) => (
									<div
										key={equip.serialNumber + equip.serialNumber + index}
										onClick={() =>
											setSelectedEquipment({
												serialNumber: equip.serialNumber,
												clientCode: equip.clientCode,
												equipmentRecno: equip.equipmentRecno
											})
										}
										className="select-serial-equipment-item"
									>
										<ul className="list-item-equipment">{`${equip.equipmentDescription}`}</ul>
										<ul className="list-item-client">{`${equip.clientCode} - ${equip.clientName} - ${equip.clientCgc}`}</ul>
									</div>
								))}
							</div>
						</>
					)}
				</div>
				<div className="select-serial-actions">
					<Button
						isLoading={isLoading}
						style={{ width: '120px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Buscar"
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						isLoading={isLoading}
						style={{ width: '120px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={handleToggleStatus}
					/>
				</div>
			</div>
			<OpenOS
				show={showOpenOs}
				protocolos={protocolos}
				onContinue={onContinueOS}
				onClose={handleToggleStatus}
			/>
			{!!client && !!equipment && (
				<NewServiceOrder
					client={client}
					equipment={equipment}
					onClose={handleToggleStatus}
					show={showNewOrder}
					mutate={mutate}
				/>
			)}
			<LoadingProgress show={isLoading} />
		</Modal>
	)
}
