import { z } from 'zod'

export const NewEmployeePrpSchema = z.object({
	cel: z.string(),
	department: z.string().min(1, 'O campo departamento é obrigatório!'),
	email: z.string().min(1, 'O campo email é obrigatório!'),
	name: z.string().min(1, 'O campo nome é obrigatório!'),
	phone: z.string(),
	register: z.string().min(1, 'O campo matrícula é obrigatório!'),
	surname: z.string().min(1, 'O campo sobrenome é obrigatório!')
})

export type NewEmployeePrpSchema = z.infer<typeof NewEmployeePrpSchema>
