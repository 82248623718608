import React from 'react'
import './styles.scss'

export const AccessoriesListHeader = () => {
	return (
		<div className="new-accessories-header-list">
			<div className="accessories-details-list">
				<ul className="accessories-details-list-item accessories-details-list-code">
					<span>Código</span>
				</ul>
				<ul className="accessories-details-list-item accessories-details-list-description">
					<span>Descrição</span>
				</ul>
				<ul className="accessories-details-list-item accessories-details-list-amount">
					<span>Quantidade</span>
				</ul>
				<ul className="accessories-details-list-item accessories-details-list-commission">
					<span>Base Comissa</span>
				</ul>
				<ul className="accessories-details-list-item accessories-details-list-unit-price">
					<span>Valor unitário</span>
				</ul>
				<ul className="accessories-details-list-item accessories-details-list-table-price">
					<span>Valor tabela</span>
				</ul>
				<ul className="accessories-details-list-item accessories-details-list-total-price">
					<span>Total merc.</span>
				</ul>
			</div>
		</div>
	)
}
