import styled from 'styled-components'

export const Header = styled.div`
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const RequestDetails = styled.div`
	margin-top: 10px;
	position: relative;
	padding: 0px;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
`

export const Obs = styled.div`
	margin-bottom: 2px;
	font-weight: 500;
	margin-right: 5px;
	font-size: 14px;
	font-weight: 500;
`

export const Footer = styled.div`
	display: flex;
	column-gap: 20px;
	justify-content: center;
`
