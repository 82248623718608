import React from 'react'
import CheckBox from 'shared/components/CheckBox'
import './styles.scss'

type TableBodyProps<T, K extends keyof T> = {
	data: any[] | undefined
	columns: any[]
	onRowClicked?: (data: any) => void
}

export const GenericTableBody = <T extends { id: number }, K extends keyof T>({
	data,
	columns,
	onRowClicked = () => {}
}: TableBodyProps<T, K>) => {
	return (
		<div className="generic-table-body">
			{data?.map((object, index) => (
				<div
					className={`generic-table-body-row ${
						object.disabledTable && 'disabled'
					}`}
					key={object.id || index}
				>
					{columns.map((column, index) => (
						<div
							style={{ width: column.width, minWidth: column.width }}
							key={`cell-${index}`}
							className={`generic-table-body-row-data ${column.key}`}
							onClick={() => {
								if (column.key !== 'action') {
									!column.input &&
										!object?.disabledTable &&
										onRowClicked(object)
								}
							}}
						>
							{column.key !== 'action' ? (
								<>
									{column.checkbox && (
										<CheckBox
											id={`${column.id}`}
											variant={'blue'}
											format={'square'}
											checked={column.selected.some(
												(item: any) => item.id === object.id
											)}
											onChange={() => {}}
											disabled={object?.disabledTable}
										/>
									)}
									<span
										title={
											typeof object[column.key] === 'number' ||
											typeof object[column.key] === 'string'
												? object[column.key]
												: ''
										}
									>
										{object[column.key]}
									</span>
								</>
							) : (
								<>{object[column.key]}</>
							)}
						</div>
					))}
				</div>
			))}
		</div>
	)
}
