import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { Order } from 'module/orderManagement/domain/models/order'
import { ItemsFields } from 'module/orderManagement/domain/usecases/add-order'
import React, { useContext } from 'react'
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form'
import { ViewType } from 'utils/Constants'
import { OrderItemsContentEditingMobile } from './mobile/OrderItemsContentEditingMobile'
import { OrderItemsContentEditingWeb } from './web/OrderItemsContentEditingWeb'

type OrderItemsContentEditingModeProps = {
	table: string
	order?: Order
	form: UseFormReturn<ItemsFields, any, any>
	fieldArray: UseFieldArrayReturn<ItemsFields, 'items', 'id'>
	isEditing?: boolean
	branch: string | undefined
}
export const OrderItemsContentEditingMode = ({
	order,
	form,
	fieldArray,
	table,
	isEditing = false,
	branch = '01'
}: OrderItemsContentEditingModeProps) => {
	const { viewType } = useContext(DeviceContextElement)

	return (
		<>
			{viewType === ViewType.MOBILE ? (
				<OrderItemsContentEditingMobile
					{...{ order, form, fieldArray, table, isEditing, branch }}
				/>
			) : (
				<OrderItemsContentEditingWeb
					{...{ order, form, fieldArray, table, isEditing, branch }}
				/>
			)}
		</>
	)
}
