import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import ClientContext from 'module/orderManagement/context/ClientContext/ClientContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import ClientListMobile from './Mobile'
import ClientListWeb from './Web'

const ClientsContextEnvolve: React.FC = () => {
	const { viewType } = useContext(DeviceContextElement)
	return (
		<ClientContext>
			{viewType !== ViewType.MOBILE ? <ClientListWeb /> : <ClientListMobile />}
		</ClientContext>
	)
}

export default ClientsContextEnvolve
