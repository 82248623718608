import React from 'react'
import { history } from 'routes/history'
import AdminSystemIcon from './adminSystemCardIcon'
import './styles.scss'

export default function AdminSystemCard() {
	return (
		<div className="feature-card" onClick={() => history.push(`/cs/users`)}>
			<div className="admin-system-card-title">
				<h4>ADMINISTRAÇÃO DE SISTEMA</h4>
				<div className="admin-system-config-icon">
					<AdminSystemIcon />
				</div>
			</div>
			<div className="admin-system-card-main-contain">
				{/* <div>
					<p className="subtext">Resumo da semana: </p>
					<div className="info-card">
						{countUserWeek} colabores cadastrados.
					</div>
					<div className="info-card">
						{countUser} usuários ativos na plataforma.
					</div>
				</div> */}
			</div>
			<div className="admin-system-card-actions">
				{/* <Button
					title="Cadastrar Colaborador"
					variant="confirmation-solid"
					size="md"
					onClick={() => {
						handleRegisterCollaborator()
					}}
				/> */}
			</div>
		</div>
	)
}
