import React from 'react'
import CheckBox from 'shared/components/CheckBox'
import './styles.scss'

interface Props {
	title: string
	items?: string[]
	checked: string[]
	onClick: (element: string) => void
}

const ListFilterOs = ({ items, checked, onClick }: Props) => {
	const handleChange = (element: string) => {
		onClick(element)
	}

	return (
		<>
			{items &&
				items.map((element, index) => (
					<div className="list-filter-item" key={index}>
						<CheckBox
							variant="blue"
							format="square"
							type="checkbox"
							id={`${element}`}
							onChange={() => handleChange(element)}
							checked={checked.includes(element)}
							value={element}
							className="list-filter-item-checkbox"
						/>
						<label className="list-filter-item-label" htmlFor={`${element}`}>
							{element}
						</label>
					</div>
				))}
		</>
	)
}

export { ListFilterOs }
