import React from 'react'

const RecognitionCardIcon: React.FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="50"
			viewBox="10 5 30 30"
			fill="url(#gradient)"
			className="recognition-icon"
		>
			<linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
				<stop offset="0%" stopColor="#4F8FE9" stopOpacity="0.6" />
				<stop offset="45%" stopColor="#396BC9" stopOpacity="0.6" />
				<stop offset="100%" stopColor="#5e50ae" stopOpacity="0.6" />
			</linearGradient>
			<rect fill="url(#gradient)" />
			<g transform="translate(-301 -403)">
				<path
					d="M13.11,7.661,9.06.911A1.875,1.875,0,0,0,7.452,0H.939A.938.938,0,0,0,.171,1.475l6.52,9.314A12.127,12.127,0,0,1,13.11,7.661ZM29.061,0H22.548A1.875,1.875,0,0,0,20.94.911L16.89,7.661a12.13,12.13,0,0,1,6.42,3.128l6.52-9.314A.938.938,0,0,0,29.061,0ZM15,9.375A10.313,10.313,0,1,0,25.313,19.688,10.313,10.313,0,0,0,15,9.375Zm5.421,9.214L18.2,20.755l.526,3.06a.673.673,0,0,1-.976.708L15,23.079l-2.748,1.444a.672.672,0,0,1-.976-.708l.526-3.06L9.58,18.59a.673.673,0,0,1,.372-1.148l3.072-.448L14.4,14.21a.674.674,0,0,1,1.208,0l1.373,2.784,3.072.448a.673.673,0,0,1,.372,1.148Z"
					transform="translate(311 408)"
				/>
			</g>
		</svg>
	)
}

export default RecognitionCardIcon
