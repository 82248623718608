export function setStoredAvailablePendencyList(availablePendencyList) {
	return {
		type: '@baseLists/SET_AVAILABLE_PENDENCY_LIST',
		payload: { availablePendencyList }
	}
}

export function setStoredPaymentFormList(paymentFormList) {
	return {
		type: '@baseLists/SET_STORED_PAYMENT_FORM_LIST',
		payload: { paymentFormList }
	}
}

export function setStoredPriceTableList(priceTableList) {
	return {
		type: '@baseLists/SET_STORED_PRICE_TABLE_LIST',
		payload: { priceTableList }
	}
}

export function setStoredSalespersonList(salespersonList) {
	return {
		type: '@baseLists/SET_STORED_PERSON_SALES_LIST',
		payload: { salespersonList }
	}
}

export function setStoredNatureList(natureList) {
	return {
		type: '@baseLists/SET_STORED_NATURE_LIST',
		payload: { natureList }
	}
}

export function setStoredClientGroups(clientGroups) {
	return {
		type: '@baseLists/SET_STORED_CLIENT_GROUPS',
		payload: { clientGroups }
	}
}

export function setStoredClientSegments(clientSegments) {
	return {
		type: '@baseLists/SET_STORED_CLIENT_SEGMENTS',
		payload: { clientSegments }
	}
}

export function setStoredOperationTypes(operationTypes) {
	return {
		type: '@baseLists/SET_STORED_OPERATION_TYPES',
		payload: { operationTypes }
	}
}

export function setStoredCarrierList(carrierList) {
	return {
		type: '@baseLists/SET_STORED_CARRIER_LIST',
		payload: { carrierList }
	}
}

export function setStoredProtheusUserList(protheusUserList) {
	return {
		type: '@baseLists/SET_STORED_PROTHEUS_USER_LIST',
		payload: { protheusUserList }
	}
}

export function setStoredCountyList(countyList) {
	return {
		type: '@baseLists/SET_STORED_COUNTY_LIST',
		payload: { countyList }
	}
}

export function setStoredLedgerAccountList(ledgerAccountList) {
	return {
		type: '@baseLists/SET_STORED_LEDGER_ACCOUNT_LIST',
		payload: { ledgerAccountList }
	}
}

export function setStoredCostCenterList(costCenterList) {
	return {
		type: '@baseLists/SET_STORED_COST_CENTER_LIST',
		payload: { costCenterList }
	}
}

export function setStoredStorageList(storageList) {
	return {
		type: '@baseLists/SET_STORED_STORAGE_LIST',
		payload: { storageList }
	}
}

export function setStoredRegionList(regionList) {
	return {
		type: '@baseLists/SET_STORED_REGION_LIST',
		payload: { regionList }
	}
}

export function setDefectList(defectList) {
	return {
		type: '@baseLists/SET_STORED_DEFECT_LIST',
		payload: { defectList }
	}
}
