import React from 'react'
import './styles.scss'

interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
	innerRef?: any
}

export const Input = ({ innerRef, ...rest }: InputProps) => {
	return (
		<>
			<input {...innerRef} {...rest} className="input-install-and-search" />
		</>
	)
}
