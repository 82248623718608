import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import brLocale from 'date-fns/locale/pt-BR'
import React from 'react'
import './styles.scss'

interface Params {
	initialDate?: MaterialUiPickersDate
	finalDate?: MaterialUiPickersDate
	onDateSelect: (value: MaterialUiPickersDate, type: string) => void
	onError: (value: any) => void
}

const PeriodFields: React.FC<Params> = ({
	initialDate,
	finalDate,
	onDateSelect,
	onError
}) => {
	const mainContent = () => {
		return (
			<div className="period-filter">
				<div className="period-filter-header">
					<span>Período</span>
				</div>
				<div className="period-filter-fields">
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
						<div className="period-filter-fields-div">
							<span>De:</span>
							<KeyboardDatePicker
								cancelLabel="CANCELAR"
								clearLabel="LIMPAR"
								okLabel="OK"
								emptyLabel="DD/MM/AAAA"
								format="dd/MM/yyyy"
								id="date-initial-picker"
								invalidDateMessage="Data invalida"
								value={initialDate || null}
								onChange={(value: MaterialUiPickersDate) => {
									onDateSelect(value, 'initialDate')
								}}
								onError={(e, value) => {
									onError(value)
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
									className: 'period-filter-keyboard-button'
								}}
							/>
						</div>
						<div className="period-filter-fields-div">
							<span>A:</span>
							<KeyboardDatePicker
								format="dd/MM/yyyy"
								id="date-final-picker"
								cancelLabel="CANCELAR"
								clearLabel="LIMPAR"
								okLabel="OK"
								emptyLabel="DD/MM/AAAA"
								minDate={new Date(initialDate || '')}
								invalidDateMessage="Data invalida"
								value={finalDate || null}
								onChange={(value: MaterialUiPickersDate) => {
									onDateSelect(value, 'finalDate')
								}}
								KeyboardButtonProps={{
									'aria-label': 'change date',
									className: 'period-filter-keyboard-button'
								}}
							/>
						</div>
					</MuiPickersUtilsProvider>
				</div>
			</div>
		)
	}

	return mainContent()
}

export default PeriodFields
