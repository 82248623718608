import React from 'react'
import { Popper } from '../Popper'
import './styles.scss'

interface TooltipProps {
	position?: 'top' | 'bottom' | 'left' | 'right'
	children: React.ReactNode
	mobile?: boolean
	info: string | string[] | number | number[]
}

export const Tooltip = ({
	position = 'bottom',
	children,
	mobile = false,
	info
}: TooltipProps) => {
	const [show, setShow] = React.useState(false)
	const containerRef = React.useRef<HTMLDivElement>(null)

	const hasValue = () => {
		if (
			(typeof info === 'string' && info) ||
			(typeof info === 'number' && info)
		) {
			return true
		} else if (Array.isArray(info) && info.length > 0) {
			return true
		} else {
			return false
		}
	}

	return (
		<div className="tooltip-component">
			<div
				className="tooltip-children"
				onMouseEnter={() => !mobile && setShow(true)}
				onMouseLeave={() => setShow(false)}
				onClick={() => mobile && setShow(!show)}
				ref={containerRef}
			>
				{children}
			</div>
			{show && hasValue() && (
				<Popper reference={containerRef} placement={position}>
					<div className="tooltip-info">
						{typeof info === 'string' || typeof info === 'number' ? (
							info
						) : (
							<>{info?.map((item, index) => <p key={index}>{item}</p>)}</>
						)}
					</div>
				</Popper>
			)}
		</div>
	)
}
