const ServiceTypesChefs = [
	{ value: 'ATENDIMENTO DE URGENCIA' },
	{ value: 'ATENDIMENTO GERAL CONCESSIONARIA' },
	{ value: 'ATENDIMENTO CONCESSIONARIA DEMO' },
	{ value: 'ATENDIMENTO CONCESSIONARIA TESTE' },
	{ value: 'ATENDIMENTO EPP DEMONSTRACAO' },
	{ value: 'DEMONSTRACAO OU TESTE PARA FILIAIS' },
	{ value: 'OUTRAS SOLICITACOES CANAL CHEF' },
	{ value: 'SOLICITACAO DE ATENDIMENTO VIA ASSISTENCIA TECNICA' },
	{ value: 'SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO' },
	{ value: 'SOLICITACAO DE ATENDIMENTO VIA QUALIDADE' },
	{ value: 'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF+DESPESAS)' },
	{ value: 'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF)' },
	{ value: 'SOLICITACAO DE TREINAMENTO POLITICA' },
	{ value: 'SOLICITACAO DE TREINAMENTO REVENDA' },
	{ value: 'SOLICITACAO DE TREINAMENTO REVENDA (CHEF+DESPESAS)' },
	{ value: 'SOLICITACAO DE TREINAMENTO REVENDA (CHEF)' },
	{ value: 'VISITA DO CHEF VIA GERENTE/COORDENADOR' },
	{ value: 'VISITA DO CHEF VIA CONCESSIONARIA' }
]

export default ServiceTypesChefs
