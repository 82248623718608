import React from 'react'
import './styles.scss'
interface DividerProps extends React.HTMLAttributes<HTMLDivElement> {
	title: string
	side?: 'left' | 'right'
}
const Divider = ({ title, side = 'left', ...rest }: DividerProps) => {
	return (
		<div
			className="divider-component"
			style={{ flexDirection: side === 'left' ? 'row' : 'row-reverse' }}
			{...rest}
		>
			<span
				className="divider-component-text"
				style={
					side === 'left' ? { marginRight: '10px' } : { marginLeft: '10px' }
				}
			>
				{title}
			</span>
			<div className="divider-component-line" />
		</div>
	)
}
export default Divider
