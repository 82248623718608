import { TransferClient, TransferEquipment } from 'models/Transfer'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import TransferClientInfo from 'module/technicalServices/components/Shared/Transfers/TransferClientInfo'
import TransferEquipmentInfo from 'module/technicalServices/components/Shared/Transfers/TransferEquipmentInfo'
import React from 'react'

interface Props {
	client?: TransferClient
	equipment?: TransferEquipment
	onConfirmTransfer: () => void
}

const TransferSummaryStep: React.FC<Props> = ({
	client,
	equipment,
	onConfirmTransfer
}) => {
	const clientContent = () => {
		return (
			<div className="transfer-summary-client">
				<div className="transfer-summary-header">
					<h6>Cliente de Destino</h6>
				</div>
				<TransferClientInfo {...{ client }} />
			</div>
		)
	}

	const equipmentContent = () => {
		return (
			<div className="transfer-summary-equipment">
				<div className="transfer-summary-header">
					<h6>Equipamento</h6>
				</div>
				<TransferEquipmentInfo {...{ equipment }} />
			</div>
		)
	}

	const mainContent = () => {
		return (
			<div className="transfer-summary-content">
				{clientContent()}
				{equipmentContent()}
				<div className="transfer-summary-footer">
					<CustomButtom
						type="button"
						label="Avançar"
						context="primary"
						onClick={onConfirmTransfer}
						disabled={!equipment || !client}
					/>
				</div>
			</div>
		)
	}

	const emptyContent = () => {
		return (
			<div className="transfer-summary-content-error">
				<div className="transfer-summary-content-error-message">
					<h3>Solicitação incompleta</h3>
					<p>Verifique os dados do equipamento e cliente de destino!</p>
				</div>
			</div>
		)
	}

	return client && equipment ? mainContent() : emptyContent()
}

export default TransferSummaryStep
