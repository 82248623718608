import ClientContext from 'module/orderManagement/context/ClientContext/ClientContext'
import React from 'react'
import { NewClient } from './Web'

const NewClientContextEnvolve: React.FC = () => {
	return (
		<ClientContext>
			<NewClient />
		</ClientContext>
	)
}

export default NewClientContextEnvolve
