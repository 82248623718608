import styled from 'styled-components'

export const Container = styled.div`
	background-color: #fff;
	min-height: calc(100% - 175px);
	max-width: 1200px;
	margin: auto;
	border-radius: 3px;
	padding: 20px;
	overflow: hidden;
`

export const Name = styled.div`
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
	color: rgb(51, 51, 51);
	width: 100%;
`

export const ContractContent = styled.div``

export const LocationContent = styled.div``

export const Abas = styled.div`
	font-size: 14px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	margin-top: 10px;
	cursor: pointer;
`

export const EquipmentsContract = styled.div`
	color: rgb(51, 51, 51);
	font-size: 14px;
	line-height: 20px;
	margin: 10px 0px;
`

export const SearchEquip = styled.div`
	color: rgb(51, 51, 51);
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	text-decoration: underline;
	margin-bottom: 5px;
	position: relative;
	transition: left 500ms ease;
`

export const TabelaEquips = styled.div`
	font-size: 13px;
	margin-top: 10px;
	color: rgb(51, 51, 51);
	line-height: 30px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-bottom: none;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	background-color: rgba(221, 221, 221, 0.3);
	position: relative;
	transition: left 500ms ease;
`

export const Header = styled.div`
    font-weight: 500;
    display: flex;
    flex-direction: row;
    padding-left: 5px
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`

export const EquipNameCol = styled.div`
	width: 39%;
`

export const SerialNumCol = styled.div`
	width: 10%;
	text-align: center;
`

export const PreventiveCol = styled.div`
	width: 18%;
	text-align: center;
`

export const LocationCol = styled.div`
	width: 32%;
	padding-left: 10px;
`

export const TabelaEquipsContent = styled.div`
	font-weight: 400;
	display: block;
	background-color: #fff;
	max-height: 434px;
	overflow: scroll;
`

export const LocalName = styled.div`
	width: 18.75%;
`

export const ZipCode = styled.div`
	width: 9.85%;
	padding-left: 10px;
`

export const Street = styled.div`
	width: 27.6%;
`

export const Neighborhood = styled.div`
	width: 17.2%;
	padding-left: 10px;
`

export const City = styled.div`
	width: 16.15%;
	padding-left: 10px;
`

export const State = styled.div`
	width: 3%;
	padding-left: 10px;
`

export const TitleAddForm = styled.div`
	font-size: 25px;
	color: rgb(51, 51, 51);
	margin-bottom: 20px;
`
export const HeaderTitle = styled.div`
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
	display: flex;
	flex-direction: row;
	width: 100%;
	position: relative;
`
