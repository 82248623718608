import styled from 'styled-components'

export const Header = styled.div`
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const Footer = styled.div`
	width: 100%;
	bottom: 0px;
	display: flex;
	column-gap: 20px;
	position: absolute;
	justify-content: center;
`
