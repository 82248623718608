import React from 'react'
import robot from './images/robot.png'

import './styles.scss'

interface Params {
	show: boolean
}

const HasError: React.FC<Params> = ({ show }) => {
	const refreshPage = () => {
		window.location.reload()
	}

	const mainContent = () => {
		return (
			<div className="has-error-content">
				<div className="has-error-main">
					<img src={robot} />
					<div className="has-error-message">
						<h4>
							Ops!
							<br />
							{`Encontramos um erro :(`}
						</h4>
						<span>
							Recarregue a pagina ou tente
							<br />
							novamente mais tarde.
						</span>
						<div className="refresh-button" onClick={refreshPage}>
							RECARREGAR
						</div>
					</div>
				</div>
			</div>
		)
	}

	return show ? mainContent() : <></>
}

export default HasError
