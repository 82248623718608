import Files from 'models/OsFiles'
import moment from 'moment'
import { store } from 'store'
import { lastEl, StepTypes } from 'utils/Constants'

export function clearNumericField(value: string) {
	return value?.replace(/[\D]/g, '')
}

const MIN_CEP = 8
const MAX_CEP = 9
const INI_CEP = 0
const STP_CEP = 5
const END_CEP = 8

/**
 * @description Receive a CEP value and verify if is masked, or put a mask on it.
 * @param value CEP to verify format and mask
 * @returns CEP with mask
 */
export function cepMaskFormat(value: string) {
	if (value?.length < MIN_CEP || value?.length > MAX_CEP) {
		return value
	}

	if (value.includes('-')) {
		return value
	} else {
		const startValues = value.substring(INI_CEP, STP_CEP)
		const endValues = value.substring(STP_CEP, END_CEP)

		return `${startValues}-${endValues}`
	}
}

const invalidNameList = [
	{ old: 'PORTAS/ARMARIO', new: 'PORTAS COM ARMARIO' },
	{ old: 'FERMENTACAO/RETARDO', new: 'FERMENTACAO COM RETARDO' },
	{ old: 'RETARDO/C/PISO/STANDARD/V2', new: 'RETARDO COM PISO STANDARD V2' },
	{ old: 'CFM-2C/FERMENTACAO', new: 'CFM-2C COM FERMENTACAO' },
	{ old: 'MOD.CANOVA300', new: 'MOD. CANOVA300' },
	{ old: 'MOD.AUTOMEC350', new: 'MOD. AUTOMEC350' },
	{ old: 'RESFRIADO/', new: 'RESFRIADO COM' },
	{ old: '  ', new: ' ' }
]

/**
 * @description Receive a equipment name and replace some values,
 * based on invalidNameList
 * @returns a new equipment name.
 */
export function parseEquipName(value?: string) {
	let newName = ''
	if (value) {
		for (let index = 0; index < invalidNameList?.length; index++) {
			const name = invalidNameList[index]
			const foundValue = value.includes(name.old)
			if (foundValue) {
				newName = value.replace(name.old, name.new)
				break
			}
		}
	}

	return newName || value
}

const MIN_PHONE_SIZE = 10
const MAX_PHONE_SIZE = 11

const INI_DDD = 0
const END_DDD = 2
const ESPECIAL_NUM = 2
const INI_PHONE = 3
const STP_PHONE = 7

/**
 * @description Receive a Phone/Tel string and returns on masked format.
 * @param value Phone or Telephone number.
 * @returns Phone or Telephone on masked format.
 */
export function phoneMask(value?: string) {
	if (value) {
		const clearValue = value.replace(/\D/g, '')

		if (
			clearValue?.length < MIN_PHONE_SIZE ||
			clearValue?.length > MAX_PHONE_SIZE
		) {
			return value
		}

		if (clearValue?.length === MAX_PHONE_SIZE) {
			const dddValues = clearValue.substring(INI_DDD, END_DDD)
			const especialNum = clearValue.substring(ESPECIAL_NUM, ESPECIAL_NUM + 1)
			const phoneStartValue = clearValue.substring(INI_PHONE, STP_PHONE)
			const phoneEndValue = clearValue.substring(
				STP_PHONE,
				clearValue?.length + 1
			)

			return `(${dddValues}) ${especialNum} ${phoneStartValue}-${phoneEndValue}`
		} else {
			const dddValues = clearValue.substring(INI_DDD, END_DDD)
			const phoneStartValue = clearValue.substring(INI_PHONE - 1, STP_PHONE - 1)
			const phoneEndValue = clearValue.substring(
				STP_PHONE - 1,
				clearValue?.length + 1
			)

			return `(${dddValues}) ${phoneStartValue}-${phoneEndValue}`
		}
	} else return ''
}

/**
 * @description Receive a Technician string and returns on masked format.
 * @param value Technician string.
 * @returns Technician on masked format.
 */
export function stringMask(value?: any) {
	if (value) {
		return value.replace(/[#?!$%^&*'"<>¨+]+/g, '')
	}
}

/**
 * @description Method that returns a real file type, based on his name and data.
 * @param fileName File name
 * @param fileType File Type that was inputed by other services.
 * @returns Returns a File Type.
 */
export function parseFileFormat(fileName: string, fileType: string) {
	if (fileName) {
		const fileNameTexts = fileName?.split('.')
		const textsLength = fileNameTexts?.length

		return fileNameTexts[textsLength - 1].toUpperCase()
	} else {
		return fileType.toUpperCase()
	}
}

/**
 * @description Method that returns a real file type, based on his name and data.
 * @param fileName File name
 * @param fileType File Type that was inputed by other services.
 * @returns Returns a File Type.
 */
export function parseFileUploadedType(fileType: string) {
	return lastEl(fileType?.split('/')).toUpperCase()
}

/**
 * @description Method that returns a name for a file.
 * @param type StepType that file is related.
 * @param file File to be named
 * @returns Returns a parsed name for a file.
 */
export function parseFileNameByStepType(file: Files, type?: StepTypes) {
	if (file.name) {
		return file.name
	} else {
		switch (type) {
			case StepTypes.QUOTE:
				return `orcamento_${moment(file.created_date).format(
					'DD-MM-YYYY'
				)}.${file.file_type.toLowerCase()}`
			case StepTypes.INVOICE:
				return `nfe_${moment(file.created_date).format(
					'DD-MM-YYYY'
				)}.${file.file_type?.toLowerCase()}`
			default:
				return `anexo.${file.file_type?.toLowerCase()}`
		}
	}
}

/**
 * @description Convert a file from Input to base64
 * @param file File selected by user on Input Field
 */
export function toBase64(file: File) {
	return new Promise<string | ArrayBuffer>((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = function () {
			if (reader.result) {
				resolve(reader.result)
			} else {
				reject()
			}
		}
		reader.onerror = function (error) {
			reject(error)
		}
	})
}

export function parseDate(value: string | Date, format: string) {
	return moment(value).format(format)
}

export const normalizePhoneNumber = (value: string | undefined) => {
	if (!value) return ''

	return value
		.replace(/[\D]/g, '')
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{4})(\d+?)/, '$1')
}

export const normalizeCepNumber = (value: string | undefined) => {
	if (!value) return ''

	return value
		.replace(/[\D]/g, '')
		.replace(/(\d{5})(\d)/, '$1-$2')
		.replace(/(-\d{3})(\d+?)/, '$1')
}

export const normalizeCGC = (value: string | undefined) => {
	if (!value) return ''

	const cleanValue = value.replace(/[\D]/g, '')

	if (cleanValue?.length <= 11) {
		return cleanValue
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1-$2')
	} else if (cleanValue?.length > 11 && cleanValue?.length <= 14) {
		return cleanValue
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{2})(\d+?)/, '$1')
	} else {
		return cleanValue
	}
}

export const normalizeTelNumber = (value: string | undefined) => {
	if (!value) return ''
	const cleanValue = value.replace(/[\D]/g, '')

	if (cleanValue?.length <= 10) {
		return cleanValue
			.replace(/(\d{2})(\d)/, '($1) $2')
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{4})(\d)/, '$1-$2')
	} else if (cleanValue?.length > 10 && cleanValue?.length < 13) {
		return cleanValue
			.replace(/(\d{2})(\d)/, '($1) $2')
			.replace(/(\d{5})(\d)/, '$1-$2')
			.replace(/(-\d{4})(\d+?)/, '$1')
	} else {
		return cleanValue
	}
}
export const normalizeTelNumberWithoutDDD = (value: string | undefined) => {
	if (!value) return ''
	const cleanValue = value.replace(/[\D]/g, '')

	if (cleanValue?.length <= 8) {
		return cleanValue
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{4})(\d)/, '$1-$2')
	} else if (cleanValue?.length > 8 && cleanValue?.length < 10) {
		return cleanValue
			.replace(/(\d{5})(\d)/, '$1-$2')
			.replace(/(-\d{4})(\d+?)/, '$1')
	} else {
		return cleanValue
	}
}

export const normalizeDate = (date: string) => {
	if (date) {
		const year = date.substring(0, 4)
		const month = date.substring(4, 6)
		const day = date.substring(6, 8)
		const newData = new Date(`${year} ${month} ${day}`)
		return newData
	}
	return null
}

export const formatDateAndHour = (date: any, hour: string) => {
	const year =
		date?.split('/')[2].length === 2
			? `20${date?.split('/')[2]}`
			: date?.split('/')[2]
	const month = date?.split('/')[1]
	const day = date?.split('/')[0]
	const [hours, minutes, seconds] = hour.split(':').map(Number)

	const newDate = new Date(year, month - 1, day, hours, minutes, seconds)

	return newDate
}

export function handleDefect(defectCode?: string) {
	const { defectList } = store.getState().baseLists
	if (defectList && defectList?.length) {
		const defectType = defectList.filter(
			(defectType: { value: string }) => defectType.value === defectCode
		)
		if (defectType && defectType?.length) {
			return defectType[0].label
		} else return defectType
	}
}

export function removeAccents(text: string) {
	return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const filterByUniqueDatas = (firstArray: any[], secondArray: any[]) => {
	const equalDates = new Set(
		firstArray.map((item) => new Date(item.createdAt).getTime())
	)

	const secondArrayFiltrado = secondArray.filter(
		(item) => !equalDates.has(new Date(item.createdAt).getTime())
	)

	const result = [...firstArray, ...secondArrayFiltrado]

	return result
}
