import Client from 'models/Client'
import { TransferInfo } from 'models/Transfer'
import React, { useMemo, useState } from 'react'
import JoyRide from 'shared/components/JoyRide'
import { store } from 'store'
import { TransferOrigin } from 'utils/Constants'
import {
	StepsTransferDetailsInfoTab,
	handleStepTitles
} from 'utils/Constants/joyrideSteps'
import { normalizeCGC, phoneMask } from 'utils/Pipe'

interface Props {
	client: Client
	transferInfo: TransferInfo
	onEditClick?: () => void
}

const TransferFoundClient: React.FC<Props> = ({
	client,
	transferInfo,
	onEditClick
}) => {
	const [tutorials, setTutorials] = useState(store.getState().user.tutorials)

	useMemo(() => {
		store.subscribe(() => setTutorials(store.getState().user.tutorials))
	}, [tutorials])

	const transferOptionsContent = () => {
		const disabledClass = !transferInfo.userId ? 'disabled' : ''

		return transferInfo?.origin === TransferOrigin.PRP ? (
			<div className={`transfer-options ${disabledClass}`}>
				<span id="joyride-client-transferdetail" onClick={onEditClick}>
					Alterar cliente
				</span>
				{!tutorials?.includes(
					handleStepTitles(StepsTransferDetailsInfoTab)
				) && <JoyRide steps={StepsTransferDetailsInfoTab} />}
			</div>
		) : (
			<></>
		)
	}

	return (
		<div className="found-client-content">
			<div className="transfer-contact-info">
				<div>
					<span title={client.nome_fantasia}>
						<b>Nome Fantasia:</b>
						{client.nome_fantasia}
					</span>
					<span title={client.razao_social}>
						<b>Razão Social:</b>
						{client.razao_social}
					</span>
					<span>
						<b>CPF/CNPJ:</b>
						{normalizeCGC(client.cpfCnpj)}
					</span>
				</div>
				<div>
					<span>
						<b>Contato:</b>
						{transferInfo.name}
					</span>
					<span title={transferInfo.email}>
						<b>E-mail:</b>
						{transferInfo.email}
					</span>
					<span>
						<b>Telefone:</b>
						{phoneMask(transferInfo.phone)}
					</span>
				</div>
			</div>
			{transferOptionsContent()}
		</div>
	)
}

export default TransferFoundClient
