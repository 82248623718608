/* eslint-disable @typescript-eslint/no-empty-function */
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import EmptyList from 'shared/components/EmptyList'
import { ViewType } from 'utils/Constants'
import { GenericTableBody } from './GenericTableBody'
import { GenericTableBodyMobile } from './GenericTableBody/mobile'
import { GenericTableHeader } from './GenericTableHeader'
import './styles.scss'

export type ColumnDefinitionType<T, K extends keyof T> = {
	key: K
	header: any
	width?: string
	isSortable?: boolean
	gridArea?: string
	checkbox?: boolean
	radio?: boolean
	selected?: any
	filter?: boolean
	input?: boolean
}

type TableProps<T, K extends keyof T> = {
	data?: T[] | undefined
	columns: ColumnDefinitionType<T, K>[]
	onRowClicked?: (data: ColumnDefinitionType<T, K>) => void
	onActionRadio?: (active: boolean) => void
}

export const GenericTable = <
	T extends { id: number | string },
	K extends keyof T
>({
	data = [],
	columns,
	onRowClicked,
	onActionRadio
}: TableProps<T, K>) => {
	const { viewType } = useContext(DeviceContextElement)

	return (
		<div className="generic-table">
			{data.length ? (
				viewType === ViewType.MOBILE ? (
					<GenericTableBodyMobile
						data={data}
						columns={columns}
						onRowClicked={onRowClicked}
					/>
				) : (
					<>
						<GenericTableHeader columns={columns} {...{ onActionRadio }} />
						<GenericTableBody
							data={data}
							columns={columns}
							onRowClicked={onRowClicked}
						/>
					</>
				)
			) : (
				<EmptyList show={true} text="Nenhum resultado encontrado" />
			)}
		</div>
	)
}
