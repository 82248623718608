import PrevIcon from 'assets/image/ADM-Order-1.svg'
import ClientsIcon from 'assets/image/Clients.svg'
import TransIcon from 'assets/image/Download-3.svg'
import RhIcon from 'assets/image/HR.svg'
import OrdersIcon from 'assets/image/Orders.svg'
import ServiceIcon from 'assets/image/Service.svg'
import Family from 'assets/image/family.svg'
import JacIcon from 'assets/image/jac.svg'
import LogsIcon from 'assets/image/logs.svg'
import Product from 'assets/image/miniconv.svg'
import Logo from 'assets/image/new-logo-branco.webp'
import VersionIcon from 'assets/image/version.svg'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { history } from 'routes/history'
import CompNotification from 'shared/Notification'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import MainOptions from './MainOptions'
import MobileHelpUser from './MobileHelpUser'
import './styles.scss'

interface MobileMenuProps {
	module:
		| 'order_management'
		| 'technical_services'
		| 'human_resources'
		| 'home'
		| 'config_user'
		| 'register'
		| 'cs'
		| 'install-and-search'
		| 'products_management'
}

const MobileMenu = ({ module }: MobileMenuProps) => {
	const { viewType } = useContext(DeviceContextElement)
	const ordersUrl = `/os/${
		store.getState().serviceOrders.url === 'os'
			? ''
			: store.getState().serviceOrders.url
	}`
	const currentOrderManagementMenu = location.pathname.split('/')[1]

	const [showHelpUser, setShowHelpUser] = useState<boolean>(false)
	const [showMenuOptions, setShowMenuOptions] = useState<boolean>(false)
	const [showNotificationsCenter, setNotificationsCenter] =
		useState<boolean>(false)
	const [showAppSettings, setShowAppSettings] = useState(false)

	const onSettingsClick = () => {
		if (showMenuOptions) {
			setShowMenuOptions(!showMenuOptions)
		}
		if (showNotificationsCenter) {
			setNotificationsCenter(!showNotificationsCenter)
		}
		if (showHelpUser) {
			setShowHelpUser(!showHelpUser)
		}
		setShowAppSettings(!showAppSettings)
	}

	const onHelpClick = () => {
		if (showMenuOptions) {
			setShowMenuOptions(!showMenuOptions)
		}
		if (showNotificationsCenter) {
			setNotificationsCenter(!showNotificationsCenter)
		}
		if (showAppSettings) {
			setShowAppSettings(!showAppSettings)
		}
		setShowHelpUser(!showHelpUser)
	}

	const helpUserContent = () => {
		return showHelpUser ? <MobileHelpUser /> : <></>
	}

	const optionsMenuShow = () => {
		switch (module) {
			case 'human_resources':
				return (
					<NavLink
						to={`/human_resources/collaborators`}
						className="user-select-options-item"
						activeClassName="active"
					>
						<img width="30px" src={RhIcon} alt="collaborators" />
					</NavLink>
				)
			case 'technical_services':
				return (
					<>
						<div className="user-select-options-item">
							<CompNotification />
						</div>
						<NavLink
							to={ordersUrl}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img
								className="icons"
								src={ServiceIcon}
								alt="service order list"
							/>
						</NavLink>
						<NavLink
							to="/transfers"
							activeClassName="active"
							id="joyride-equipment-transfers"
							className="user-select-options-item"
						>
							<img
								src={TransIcon}
								className="icons"
								alt="equipment transfers"
							/>
						</NavLink>
						<NavLink
							to="/preventive"
							activeClassName="active"
							className="user-select-options-item"
						>
							<img
								src={PrevIcon}
								className="icons"
								alt="maintenance of preventive contracts"
							/>
						</NavLink>
					</>
				)
			case 'order_management':
				return (
					<>
						<div className="user-select-options-item">
							<CompNotification />
						</div>
						<NavLink to="/orders">
							<div
								className={`user-select-options-item ${
									currentOrderManagementMenu === 'orders' ? 'active' : ''
								}`}
							>
								<img
									src={OrdersIcon}
									className="icons"
									alt="sales orders icon"
								/>
							</div>
						</NavLink>
						<NavLink to="/clients">
							<div
								className={`user-select-options-item ${
									currentOrderManagementMenu === 'clients' ? 'active' : ''
								}`}
							>
								<img
									className="icons"
									src={ClientsIcon}
									alt="client list icon"
								/>
							</div>
						</NavLink>
					</>
				)
			case 'cs':
				return (
					<>
						<NavLink
							to={`/cs/logs`}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img width="30px" src={LogsIcon} alt="logs" />
						</NavLink>
						<NavLink
							to={`/cs/users`}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img width="30px" src={ClientsIcon} alt="users" />
						</NavLink>
						<NavLink
							to={`/cs/usersJAC`}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img width="30px" src={JacIcon} alt="users-jac" />
						</NavLink>
						<NavLink
							to={`/cs/versionApps`}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img width="30px" src={VersionIcon} alt="versions" />
						</NavLink>
					</>
				)
			case 'products_management':
				return (
					<>
						<NavLink
							to={`/products`}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img width="30px" src={Product} alt="versions" />
						</NavLink>
						<NavLink
							to={`/productFamily`}
							activeClassName="active"
							className="user-select-options-item"
						>
							<img width="30px" src={Family} alt="versions" />
						</NavLink>
					</>
				)
			default:
				return (
					<NavLink to="/home" activeClassName="active">
						<img src={Logo} alt="Prática" className="menu-logo-pratica" />
					</NavLink>
				)
		}
	}

	const mainManuContent = () => {
		const menu = showMenuOptions || showHelpUser || showAppSettings

		return (
			<section className="main-mobile-menu">
				<div className="user-select-options">
					<div
						className={`user-select-options-item ${menu ? 'active' : ''}`}
						onClick={() => history.push('/home')}
					>
						<img src={Logo} className="menu-icon" alt="menu-icon" />
					</div>
					{optionsMenuShow()}
				</div>
			</section>
		)
	}

	const menuOptionsContent = () =>
		showMenuOptions ? (
			<MainOptions {...{ onSettingsClick, onHelpClick }} />
		) : (
			<></>
		)

	return viewType === ViewType.MOBILE ? (
		<>
			{mainManuContent()}
			{menuOptionsContent()}
			{helpUserContent()}
		</>
	) : (
		<></>
	)
}

export default MobileMenu
