export enum StepType {
	CREATE_OS = 'create_os',
	NEW_VISIT = 'new_visit',
	FOLLOW_UP = 'follow-up',
	DELETED_OS = 'deleted_os',
	QUOTE = 'quote',
	ORDER = 'order',
	INVOICE = 'invoice',
	CLOSE_OS = 'close_os',
	INTERNAL_OBS = 'internal_obs',
	INTERNAL_OBS_FIELD_CONTROL = 'internal_obs_field_control',
	INVENTORY_ORDER = 'inventory_order',
	INVENTORY_INVOICE = 'inventory_invoice',
	AUTH = 'internal_auth',
	CANCEL_REQUEST = 'cancel_request',
	REPORTED_OS_FIELD_CONTROL = 'reported_os_field_control',
	IN_PROGRESS_OS_FIELD_CONTROL = 'in_progress_os_field_control',
	CANCELED_OS_FIELD_CONTROL = 'canceled_os_field_control'
}
