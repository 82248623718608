export const setClientsParams = (listClientParams) => {
	return {
		type: '@orderManagement/SET_CLIENTS_LIST_PARAMS',
		payload: {
			listClientParams
		}
	}
}

export const setClientsFilters = (clientFilter) => {
	return {
		type: '@orderManagement/SET_CLIENTS_LIST_PARAMS_FILTERS',
		payload: {
			clientFilter
		}
	}
}

export const setClientsFiltersClear = () => {
	return {
		type: '@orderManagement/SET_CLIENTS_LIST_PARAMS_FILTERS_CLEAR'
	}
}

export const setOrdersParams = (listOrderParams) => {
	return {
		type: '@orderManagement/SET_ORDERS_LIST_PARAMS',
		payload: {
			listOrderParams
		}
	}
}

export const setOrdersFilters = (orderFilter) => {
	return {
		type: '@orderManagement/SET_ORDERS_LIST_PARAMS_FILTERS',
		payload: {
			orderFilter
		}
	}
}

export const setOrdersFiltersClear = () => {
	return {
		type: '@orderManagement/SET_ORDERS_LIST_PARAMS_FILTERS_CLEAR'
	}
}

export const setOrderHistoryFilters = (listOrderHistoryParams) => {
	return {
		type: '@orderManagement/SET_ORDER_HISTORY_LIST_PARAMS',
		payload: {
			listOrderHistoryParams
		}
	}
}
