import UserIcon from 'assets/image/UserIcon.svg'
import User from 'models/User'
import React, { useEffect, useState } from 'react'
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { clearTutorialsList, userInfo } from 'services'
import ConfigUserModal from 'shared/ConfigUserModal'
import { updateReducerTutorials } from 'store/modules/user/actions'
import { store } from '../../store'
import { UserSelector } from './UserSelector'
import './styles.css'

const UserDropdown: React.FC = () => {
	const [showConfigModal, setShowConfigModal] = useState<boolean>(false)
	const [showUserSelector, setShowUserSelector] = useState<boolean>(false)
	const [user, setUser] = useState<User>()
	const userId = store.getState().user.profile
	const dispatch = useDispatch()

	const handleClearTutorials = () => {
		setShowUserSelector(false)
		clearTutorialsList(userId)
		dispatch(updateReducerTutorials([]))
	}

	useEffect(() => {
		if (!user) {
			getUserInfo()
		}
	}, [user])

	const getUserInfo = () => {
		userInfo(userId)
			.then((res) => {
				setUser(res.data)
			})
			.catch(() => {
				toast.error('Houve um erro ao buscar as informações do usuário!')
			})
	}

	const handleUserDataUpdate = (userInfo: User) => {
		const userData = { ...user } as User

		userData.name = userInfo.name
		userData.surname = userInfo.surname
		userData.cel = userInfo.cel

		setUser(userData)
	}

	const handleConfigModalToggle = () => {
		setShowConfigModal(!showConfigModal)
	}

	const configModalContent = () => (
		<ConfigUserModal
			onClose={handleConfigModalToggle}
			onUpdateUser={handleUserDataUpdate}
		/>
	)

	const handlerUserSelectorToggle = () => {
		setShowUserSelector(!showUserSelector)
	}

	const handleClientDropDownIcon = () => {
		return showUserSelector ? <MdArrowDropUp /> : <MdArrowDropDown />
	}

	const userSelectorContent = () => {
		return showUserSelector ? (
			<UserSelector
				{...{
					handleConfigModalToggle,
					handleClearTutorials,
					onClose: handlerUserSelectorToggle
				}}
			/>
		) : (
			<></>
		)
	}

	const userContentBadge = () => {
		return (
			<div
				id="joyride-userinfo"
				className="user-area"
				onClick={handlerUserSelectorToggle}
			>
				<img
					width="30px"
					src={UserIcon}
					alt="Usuário ícone"
					style={{ border: 'solid #red' }}
				/>
				{handleClientDropDownIcon()}
			</div>
		)
	}

	return (
		<>
			{userContentBadge()}
			{showConfigModal && configModalContent()}
			{userSelectorContent()}
		</>
	)
}

export default UserDropdown
