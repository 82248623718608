import React from 'react'
import { ClientAccountingInformationSection } from '../ClientAccountingInformationSection'
import { ClientAddressInformationSection } from '../ClientAddressInformationSection'
import { ClientInformationSection } from '../ClientInformationSection'

export function ClientViewModeComposition() {
	return (
		<>
			<ClientInformationSection.Root>
				<ClientInformationSection.ViewingMode />
			</ClientInformationSection.Root>
			<ClientAddressInformationSection.Root>
				<ClientAddressInformationSection.ViewingMode />
			</ClientAddressInformationSection.Root>
			<ClientAccountingInformationSection.Root>
				<ClientAccountingInformationSection.ViewingMode />
			</ClientAccountingInformationSection.Root>
		</>
	)
}
