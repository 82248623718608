import FormControl from '@material-ui/core/FormControl'
import NativeSelect from '@material-ui/core/NativeSelect'
import React, { useEffect, useState } from 'react'
import { getTypeByKey, getTypes, updateEquipmentById } from 'services'

interface ProductFamilyProps {
	show?: any
	equipment: any
	innerRef?: any
	onSelectProductFamily: (familyProd: any) => void
}

const ProductFamily = ({
	show,
	equipment,
	onSelectProductFamily,
	innerRef
}: ProductFamilyProps) => {
	const [types, setTypes] = useState<any>()
	const [typeSelected, setTypeSelected] = useState<any>(null)

	useEffect(() => {
		if (equipment?.family_prod) {
			getTypesFamilyProd(equipment.family_prod)
		} else {
			getTypes('X2')
				.then((response) => setTypes(response.data))
				.catch((error) => console.log(`Error on search types on PRP: `, error))
		}
	}, [equipment?.family_prod])

	function getTypesFamilyProd(familyProd: any) {
		getTypeByKey('X2', familyProd)
			.then((response) => {
				setTypeSelected(response.data[0])
				onSelectProductFamily(familyProd)
			})
			.catch((error) => console.log(`Error on search types on PRP: `, error))
	}

	const handleChangeFamilyProd = (familyProd: any) => {
		const familyProdPayload = {
			family_prod: familyProd
		}
		onSelectProductFamily(familyProd)
		updateEquipmentById(equipment.id, familyProdPayload)
			.then(() => {})
			.catch((error) => console.log('Error on update family product', error))
	}

	const familyProdContent = () => {
		return typeSelected?.key ? (
			<FormControl
				variant="outlined"
				className="ProductFamily"
				style={{ width: '100%' }}
			>
				<NativeSelect
					ref={innerRef}
					style={{ fontSize: '10px', fontWeight: '500' }}
					value={typeSelected?.key}
					disabled={typeSelected?.key ? true : false}
				>
					<option value={typeSelected?.key}>{typeSelected?.description}</option>
				</NativeSelect>
			</FormControl>
		) : (
			<FormControl
				variant="outlined"
				className="ProductFamily"
				style={{ width: '100%' }}
			>
				<NativeSelect
					ref={innerRef}
					style={{ fontSize: '8px', fontWeight: '500' }}
					onChange={(event: any) => handleChangeFamilyProd(event.target.value)}
				>
					<option key="0" aria-label="None" value="">
						Selecione a familia o produto
					</option>
					{types?.map((item: any, index: any) => (
						<option key={index} value={item?.key}>
							{item.description}
						</option>
					))}
				</NativeSelect>
			</FormControl>
		)
	}

	return show ? familyProdContent() : <></>
}

export default ProductFamily
