import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import OrderContext from 'module/orderManagement/context/OrderContext/OrderContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import OrderListMobile from './Mobile'
import OrderListWeb from './Web'

const OrdersContextEnvolve: React.FC = () => {
	const { viewType } = useContext(DeviceContextElement)
	return (
		<OrderContext>
			{viewType !== ViewType.MOBILE ? <OrderListWeb /> : <OrderListMobile />}
		</OrderContext>
	)
}

export default OrdersContextEnvolve
