/**
 *
 * @description takes the value and returns with mask referring to the input
 * @param receives input id and value
 * @returns returns unaccented and non-characterized value for that field in real time
 */
export function maskOnInput(id: any, value: any) {
	switch (id) {
		case 'address':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ,]/g, '')
			return value
		case 'name-tech':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9]/g, '')
			return value
		case 'full-name':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 .-]/g, '')
			return value
		case 'state-register':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ./-]/g, '')
			return value
		case 'district':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9]/g, '')
			return value
		case 'complement':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ,/.-]/g, '')
			return value
		case 'contact':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ,/.-]/g, '')
			return value
		case 'email':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ._@-]/g, '')
			return value
		case 'email2':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ._@-]/g, '')
			return value
		case 'email3':
			value = value.normalize('NFD').replace(/[^a-z A-Z 0-9 ._@-]/g, '')
			return value
		default:
			return console.error('ID not found')
	}
}
