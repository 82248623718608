import React from 'react'

import markIcon from 'assets/image/ic-location.svg'
import Button from 'shared/components/Button'
import { NewModal } from '../../_newModal'
import './styles.scss'

type Props = {
	onReturn: () => void
	client: any
	onConfirmAddress: () => void
	onNotConfirmAddress: () => void
	title: string
	disableButton?: boolean
}

export const ModalConfirmeAddress = ({
	client,
	onConfirmAddress,
	onNotConfirmAddress,
	onReturn,
	title,
	disableButton
}: Props) => {
	return (
		<NewModal onClose={onReturn} awayClose={true} title={title}>
			<div className="title-modal">
				Por favor, confirme o endereço do equipamento.
			</div>
			<div className="container-address-confirm">
				<div className="container-address">
					<div className="container-f">
						<img src={markIcon} alt="" className="item-f" />
						<span className="item-f text-align-l">
							{client && client.street}, {client && client.neighborhood}
							<br />
							{client && client.city} - {client && client.state_sigla}
						</span>
					</div>
				</div>
				<div className="not-address-action" onClick={onNotConfirmAddress}>
					<span>Informar outro endereço</span>
				</div>
				<div className="container-address-confirm-actions">
					<Button
						variant="confirmation-solid"
						size="lg"
						type="button"
						onClick={onConfirmAddress}
						title="Confirmar"
						disabled={disableButton}
					/>
					<Button
						title="Cancelar"
						variant="cancellation-gradient"
						size="lg"
						type="button"
						onClick={onReturn}
					/>
				</div>
			</div>
		</NewModal>
	)
}
