import React from 'react'
import Button from 'shared/components/Button'
import { Container, Footer, Msg } from './styles'

interface Props {
	deleteDataStatus?: boolean
	result?: boolean
	msg?: string
	title?: string
	resetStatus: () => void
}
const SuccessMsg: React.FC<Props> = ({ title, result, msg, resetStatus }) => {
	return (
		<Container>
			{title ? <h1>{title}</h1> : <></>}
			{result ? (
				<Msg>{msg}</Msg>
			) : (
				<Msg>Ocorreu um problema, tente novamente mais tarde.</Msg>
			)}
			<Footer>
				<Button
					title="Ok"
					size="md"
					variant="confirmation-gradient"
					onClick={() => {
						$(`.resultMsg`).css('display', 'none')
						resetStatus()
						if (msg === 'OS já foi atribuída para outro usuário') {
							document.location.reload()
						}
					}}
				/>
			</Footer>
		</Container>
	)
}

export default SuccessMsg
