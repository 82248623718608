import React from 'react'
import CheckBox from 'shared/components/CheckBox'
import { ColumnDefinitionType } from '..'
import './styles.scss'

type TableHeaderProps<T, K extends keyof T> = {
	columns: ColumnDefinitionType<T, K>[]
	onActionRadio?: (active: boolean) => void
}

export const GenericTableHeader = <T, K extends keyof T>({
	columns,
	onActionRadio
}: TableHeaderProps<T, K>) => (
	<div className="generic-table-header">
		{columns.map((column) => (
			<div
				style={{ width: column.width }}
				className={`generic-table-data ${String(column.key)} `}
				key={String(column.key)}
				title={
					['number', 'string'].includes(typeof column?.header)
						? column?.header
						: ''
				}
			>
				{column.radio ? (
					<CheckBox
						variant="standard"
						format="round"
						onChange={(e) => onActionRadio && onActionRadio(e.target.checked)}
					/>
				) : (
					column.header
				)}
			</div>
		))}
	</div>
)
