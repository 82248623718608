import Client from 'models/Client'
import Equipment from 'models/Equipment'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { Tooltip } from 'module/orderManagement/components/Tooltip'
import ProductFamily from 'module/technicalServices/components/ProductFamily'
import { EquipmentLegendStatus } from 'module/technicalServices/utils/constants'
import React, { useContext } from 'react'
import { FaCircle } from 'react-icons/fa'
import { ViewType } from 'utils/Constants'
import { normalizeCGC } from 'utils/Pipe'
import './styles.scss'

interface Props {
	equipment?: Equipment
	client?: Client
	showFamilyProduct?: boolean
	onSelectProductFamily?: (type: boolean) => void
	equipmentProductFamily?: any
}
const onChangeFamilyProd = (familyProd: any) => {
	const familyProduct = familyProd
}

const Equipments: React.FC<Props> = ({
	client,
	equipment,
	showFamilyProduct,
	onSelectProductFamily,
	equipmentProductFamily
}) => {
	const { viewType } = useContext(DeviceContextElement)
	const isMobile = viewType === ViewType.MOBILE
	return (
		<>
			<div className="equipment-header-information">
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: !isMobile ? 'row' : 'column'
					}}
				>
					<div>
						<span className="align-header">
							{equipment?.legend &&
								EquipmentLegendStatus.map((legend) => {
									return (
										legend?.value === equipment?.legend && (
											<Tooltip
												info={legend.label}
												mobile={isMobile}
												key={legend.value}
											>
												<FaCircle color={legend.color} size={15} />
											</Tooltip>
										)
									)
								})}
							{equipment?.name}
						</span>
						<div className="serial-content">
							<span>N/S:</span>
							<span>{equipment?.serial_number}</span>
						</div>
					</div>
					<div
						style={{
							width: viewType !== ViewType.MOBILE ? '30%' : '100%',
							position: 'relative'
						}}
					>
						{showFamilyProduct &&
							!!onSelectProductFamily &&
							!equipment?.family_prod && (
								<ProductFamily
									show={true}
									equipment={equipment}
									onSelectProductFamily={onSelectProductFamily}
								/>
							)}
					</div>
				</div>
			</div>
			<div className="client-header-information">
				<div>
					<span>Cliente:</span>
					<span>{`${client?.cod_protheus} - ${client?.nome_fantasia}`}</span>
				</div>

				<div>
					<span>CNPJ:</span>
					<span>{normalizeCGC(client?.cpfCnpj)}</span>
				</div>
			</div>
		</>
	)
}

export default Equipments
