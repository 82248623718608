import styled from 'styled-components'

export const Container = styled.div`
	width: 60%;
	background: #fff;
	left: 50%;
	top: 28px;
	transform: translate(-50%, 0%);
	position: absolute;
	margin-bottom: 100px;
`
