import Equipment from 'models/Equipment'
import RequestResponse from 'models/RequestResponse'
import { RequisitionError } from 'models/RequisitionError'
import { Transfer, TransferClient, TransferUpdate } from 'models/Transfer'

export interface TransfersDetailsContextType {
	transferClients: TransferClient[]
	transferEquipment?: Equipment
	transferSelected?: Transfer
	hasError?: RequisitionError
	isLoading: boolean
	onSelectTransfer: (transfer?: Transfer) => void
	onRequestClients: (search: string) => void
	onRequestEquipments: (
		search: string,
		callback: (equip?: Equipment, hasError?: RequisitionError) => void
	) => void
	onTransferUpdate: (
		transferInfo: TransferUpdate,
		callback: (request: RequestResponse) => void
	) => void
	verifyClient: (
		cpfCnpj: string,
		transferId: number,
		callback: (request: RequestResponse) => void
	) => void
}

export const TransfersDetailsContextValue = {
	transferClients: [],
	transferEquipment: {} as Equipment,
	transferSelected: {} as Transfer,
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	onSelectTransfer: () => {},
	onRequestClients: () => {},
	onRequestEquipments: () => {},
	onTransferUpdate: () => {},
	verifyClient: () => {}
}
