import {
	OrderBranchTypes,
	OrderCategoryTypes,
	PendencyTypes,
	ResponsibleSolutionTypes
} from 'module/orderManagement/utils/constants'
import React from 'react'
import {
	FieldErrorsImpl,
	UseFormClearErrors,
	UseFormRegister,
	UseFormSetValue
} from 'react-hook-form'
import CheckBox from 'shared/components/CheckBox'
import Divider from 'shared/components/Divider'
import Select from 'shared/components/Select'
import { store } from 'store'
import './styles.scss'

interface Props {
	orderStatus?: any[]
	onSelectOrderStatus: (selectOptions: number[]) => void
	orderCategory?: string[]
	onSelectOrderCategory: (selectOptions: string[]) => void
	orderBranch?: string[]
	onSelectOrderBranch: (selectOptions: string[]) => void
	pendencyCode?: string[]
	onSelectPendencyCode: (selectOptions: string[]) => void
	pendencyType?: string[]
	onSelectPendencyType: (selectOptions: string[]) => void
	pendencyIssueResolverTeam?: string[]
	onSelectPendencyIssueResolverTeam: (selectOptions: string[]) => void
	pendencyIssueResolver?: string[]
	onSelectPendencyIssueResolver: (selectOptions: string[]) => void
	register: UseFormRegister<FilterHooks>
	errors: Partial<
		FieldErrorsImpl<{
			history_branch_select: string
		}>
	>
	setValue: UseFormSetValue<FilterHooks>
	clearErrors: UseFormClearErrors<FilterHooks>
}

type FilterHooks = {
	history_branch_select: string
}

const FilterFields = ({
	onSelectOrderCategory,
	orderCategory,
	onSelectOrderStatus,
	orderStatus = [],
	onSelectOrderBranch,
	orderBranch = [],
	onSelectPendencyCode,
	pendencyCode = [],
	onSelectPendencyType,
	pendencyType = [],
	onSelectPendencyIssueResolverTeam,
	pendencyIssueResolverTeam = [],
	onSelectPendencyIssueResolver,
	pendencyIssueResolver = [],
	register,
	errors,
	setValue,
	clearErrors
}: Props) => {
	const { availablePendencyList, protheusUserList } = store.getState().baseLists

	function handleSelect(element: string) {
		const currentIndex = orderStatus.indexOf(element)
		const listFilterStatus = [...orderStatus]

		if (currentIndex === -1) {
			listFilterStatus.push(element)
		} else {
			listFilterStatus.splice(currentIndex, 1)
		}
		onSelectOrderStatus(listFilterStatus)
	}

	const mainContent = () => {
		return (
			<div className="history-filter-fields">
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Status</label>
						<div className="history-billing-select">
							<label htmlFor="opened">
								<CheckBox
									id="opened"
									type="checkbox"
									checked={orderStatus.includes('0')}
									variant="blue"
									format="square"
									onChange={() => handleSelect('0')}
								/>
								<span>A faturar</span>
							</label>
							<label htmlFor="billed">
								<CheckBox
									id="billed"
									type="checkbox"
									checked={orderStatus.includes('6')}
									variant="blue"
									format="square"
									onChange={() => handleSelect('6')}
								/>
								<span>Faturado</span>
							</label>
							<label htmlFor="partially-billed">
								<CheckBox
									id="partially-billed"
									type="checkbox"
									checked={orderStatus.includes('5')}
									variant="blue"
									format="square"
									onChange={() => handleSelect('5')}
								/>
								<span>Faturado parcialmente</span>
							</label>
						</div>
					</div>
				</div>
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Categoria</label>
						<Select
							checkbox={true}
							id="history-category-select"
							options={OrderCategoryTypes}
							selectedOption={orderCategory ? orderCategory : []}
							onSelectOptions={onSelectOrderCategory}
						/>
					</div>
					<div className="filter-fields-section-option">
						<label>Filial</label>
						<Select
							id="history_branch_select"
							options={OrderBranchTypes}
							selectedOption={orderBranch ? orderBranch : []}
							onSelectOptions={(e) => {
								onSelectOrderBranch(e)
								setValue('history_branch_select', e[0])
								clearErrors('history_branch_select')
							}}
							innerRef={{
								...register('history_branch_select', {
									required: {
										value:
											!!pendencyIssueResolverTeam?.length ||
											!!pendencyIssueResolver?.length ||
											!!pendencyType?.length ||
											!!pendencyCode?.length,
										message: 'Escolha uma filial'
									}
								})
							}}
							errors={errors.history_branch_select}
						/>
					</div>
				</div>
				<Divider title="Pendências" style={{ marginBottom: '20px' }} />
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Nome da Pendência</label>
						<Select
							id="pendency-code-select"
							tooltip="right-start"
							options={[
								...availablePendencyList.commercial_pending,
								...availablePendencyList.financial_pending,
								...availablePendencyList.administrative_pending,
								...availablePendencyList.shipment_pending
							]}
							checkbox={true}
							selectedOption={pendencyCode || []}
							onSelectOptions={onSelectPendencyCode}
						/>
					</div>
					<div className="filter-fields-section-option">
						<label>Tipo de Pendência</label>
						<Select
							id="pendency-type-select"
							options={PendencyTypes}
							selectedOption={pendencyType || []}
							onSelectOptions={onSelectPendencyType}
							checkbox={true}
						/>
					</div>
				</div>
				<div className="filter-fields-section">
					<div className="filter-fields-section-option">
						<label>Responsável da Solução</label>
						<Select
							id="pendency-resolver-select"
							options={protheusUserList}
							selectedOption={pendencyIssueResolver || []}
							onSelectOptions={onSelectPendencyIssueResolver}
						/>
					</div>
					<div className="filter-fields-section-option">
						<label>Setor Resp. da Solução</label>
						<Select
							id="pendency-resolver-team-select"
							options={ResponsibleSolutionTypes}
							selectedOption={pendencyIssueResolverTeam || []}
							onSelectOptions={onSelectPendencyIssueResolverTeam}
							checkbox={true}
						/>
					</div>
				</div>
			</div>
		)
	}

	return mainContent()
}

export default FilterFields
