import markIcon from 'assets/image/ic-location.svg'
import React, { useEffect, useState } from 'react'

import { List } from 'module/installAndSearch/components/List'
import { useLocation } from 'react-router'
import { history } from 'routes/history'
import Button from 'shared/components/Button'
import { Loader } from 'shared/components/Loader'
import './styles.scss'

export const ResponseByAddress = () => {
	const location = useLocation()

	const { address, techList } = location.state as {
		address: any
		techList: any[]
	}

	// UseStates
	const [client, setClient] = useState<any>(null)
	const [finished, setFinished] = useState(false)
	const [techniciansList, setTechniciansList] = useState<any[]>([])

	useEffect(() => {
		if (address && !!techList?.length) {
			setClient(address)
			setTechniciansList(techList)
			setFinished(true)
		}
	}, [address, techList])

	return (
		<div className="response-by-address">
			{client && (
				<div className="msg msg-container">
					<div className="main-body-message">
						<div className="container-address">
							<div className="container-f">
								<img src={markIcon} alt="" className="item-f" />
								<span className="item-f text-align-l">
									{client.address.trim() !== ',' &&
										client.address.toUpperCase()}
									{client.neighborhood.trim() !== ''
										? `, ${client.neighborhood.toUpperCase()},`
										: ''}
									<br />
									{client.city.toUpperCase()},{' '}
									{client.state_sigla.toUpperCase()}.
								</span>
							</div>
						</div>
					</div>
					<section>
						Veja os técnicos mais próximos do endereço de seu equipamento:
					</section>
				</div>
			)}

			{finished ? (
				techniciansList.length > 0 ? (
					<List techniciansList={techniciansList} serialNumber={''} />
				) : (
					<div
						className="modal problem"
						style={{ backgroundColor: 'rgba(0,0,0,0.5)', display: 'block' }}
					>
						<div
							style={{
								position: 'absolute',
								fontSize: '20px',
								top: '50%',
								left: '50%',
								transform: 'translate(-50%, -50%)',
								padding: '40px',
								borderRadius: '3px',
								backgroundColor: '#fff',
								textAlign: 'center'
							}}
						>
							Sinto muito. <br></br>
							<div style={{ fontSize: '16px' }}>
								Tivemos um problema nessa solicitação.
							</div>
							<div
								style={{
									display: 'block',
									marginTop: '40px',
									width: 'fit-content',
									marginLeft: 'auto',
									marginRight: 'auto'
								}}
							>
								<Button
									onClick={() => history.push(`/service`)}
									title="Tentar Novamente"
									variant="confirmation-radius"
									size="lg"
								/>
							</div>
						</div>
					</div>
				)
			) : (
				<Loader />
			)}
		</div>
	)
}
