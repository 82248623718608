export function handleProtheusDateToDate(date?: string | null): Date | null {
	if (!date || !date.trim()) {
		return null
	}

	const year = Number(date.slice(0, 4))
	const month = Number(date.slice(4, 6))
	const day = Number(date.slice(6, 8))

	if (!year || !month || !day) {
		const year = Number(date.slice(0, 4))
		const month = Number(date.slice(5, 7))
		const day = Number(date.slice(8, 10))

		return new Date(year, month - 1, day) || null
	}

	return new Date(year, month - 1, day)
}

export function handleDateToProtheusDate(date: Date): string {
	const year = date.getFullYear()
	const month = (date.getMonth() + 1).toString().padStart(2, '0')
	const day = date.getDate().toString().padStart(2, '0')

	return `${year}${month}${day}`
}

export function handleType(
	value: any,
	types: {
		value: string
		label: string
	}[]
): string {
	if (!value) return '-'
	return types?.filter((type) => type?.value === value)[0]?.label || '-'
}
