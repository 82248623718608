import React from 'react'

import './styles.scss'

interface Params {
	type?: 'button' | 'submit' | 'reset'
	label: string
	context: 'primary' | 'secondary'
	customClass?: string
	disabled?: boolean
	onClick?: () => void
}

const CustomButtom: React.FC<Params> = ({
	type,
	label,
	context,
	customClass,
	disabled,
	onClick
}) => {
	const mainClass =
		context === 'primary' ? 'custom-button-primary' : 'custom-button-secondary'

	return (
		<button
			className={customClass || mainClass}
			onClick={onClick}
			type={type}
			disabled={disabled}
		>
			{label}
		</button>
	)
}

export default CustomButtom
