import styled from 'styled-components'

export const Header = styled.div`
	display: flex;
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 20px;
	align-items: center;
`

export const Abas = styled.div`
	font-size: 16px;
	font-weight: 500;
	display: flex;
	flex-direction: row;
	cursor: pointer;
`

export const ContractContent = styled.div`
	height: auto;
`

export const ScheduleContent = styled.div`
	height: auto;
`
