import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { Order } from 'module/orderManagement/domain/models/order'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import { OrderItemsContentViewingMobile } from './mobile/OrderItemsContentViewingMobile'
import { OrderItemsContentViewingWeb } from './web/OrderItemsContentViewingWeb'

type OrderItemsContentViewingModeProps = {
	order: Order
}

export const OrderItemsContentViewingMode = ({
	order
}: OrderItemsContentViewingModeProps) => {
	const { viewType } = useContext(DeviceContextElement)

	return (
		<>
			{viewType === ViewType.MOBILE ? (
				<OrderItemsContentViewingMobile {...{ order }} />
			) : (
				<OrderItemsContentViewingWeb {...{ order }} />
			)}
		</>
	)
}
