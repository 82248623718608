import React from 'react'

import { Radio } from '@material-ui/core'
import Filter from 'models/Filter'

import './styles.css'

interface Props {
	show: boolean
	filters?: Filter[]
	selected?: Filter
	onSelect: (item: Filter) => void
	onClear: () => void
	onCreate: () => void
}

const FilterManager: React.FC<Props> = ({
	show,
	filters,
	selected,
	onSelect,
	onClear,
	onCreate
}) => {
	const handleSelect = (item: Filter) => {
		onSelect(item)
	}

	const handleClear = () => {
		onClear()
	}

	const handleCreate = () => {
		onCreate()
	}

	return show ? (
		<div className="filter-modal">
			<div className="filter-title">Meus Filtros</div>
			<div className="filter-list">
				{filters?.map((item, index) => (
					<div
						className="filter-item"
						key={index}
						onClick={() => {
							handleSelect(item)
						}}
					>
						<Radio
							checked={item.id === selected?.id}
							onChange={() => {
								handleSelect(item)
							}}
							value={item.id}
							name={item.name}
						/>
						<label>{item.name}</label>
					</div>
				))}
			</div>
			<div className="filter-footer">
				<div onClick={handleClear}>Limpar</div>
				<div onClick={handleCreate}>Gerenciar</div>
			</div>
		</div>
	) : (
		<></>
	)
}

export { FilterManager }
