import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import {
	handleCostCenterType,
	handleLedgerAccountType,
	handleNature,
	handleTaxpayerType
} from 'module/orderManagement/utils/constants'
import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import './styles.scss'
export const ClientAccountingInformationViewingMode = () => {
	const { sa1010, sa1010IsLoading } = useContext(ClientDetailsContextElement)
	return (
		<div className="client-accounting-information">
			<div className="client-accounting-information-contain-information">
				<header>Natureza</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Naturez ? (
						handleNature(sa1010?.a1Naturez)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Conta contábil</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Conta ? (
						handleLedgerAccountType(sa1010?.a1Conta)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Centro de custo</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Ccusto ? (
						handleCostCenterType(sa1010?.a1Ccusto)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Item conta</header>
				<span>{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Itconta ?? '-'}</span>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Limite de crédito</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Lc ? (
						Number(sa1010?.a1Lc).toLocaleString('pt-br', {
							currency: 'BRL',
							minimumFractionDigits: 2
						})
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-accounting-information-contain-information">
				<header>Contribuinte</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Contrib ? (
						handleTaxpayerType(sa1010?.a1Contrib)
					) : (
						'-'
					)}
				</span>
			</div>
		</div>
	)
}
