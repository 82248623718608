import { PaymentDetailsEditingMode } from './PaymentDetailsEditingMode'
import { PaymentDetailsRoot } from './PaymentDetailsRoot'
import { PaymentDetailsViewingMode } from './PaymentDetailsViewingMode'
import { PaymentDiscountsContent } from './PaymentDiscountsContent'
import { PaymentTaxesContent } from './PaymentTaxesContent'
import { PaymentTotalsContent } from './PaymentTotalsContent'

export const PaymentDetailsSection = {
	Root: PaymentDetailsRoot,
	DiscountsContent: PaymentDiscountsContent,
	TaxesContent: PaymentTaxesContent,
	TotalsContent: PaymentTotalsContent,
	ViewingMode: PaymentDetailsViewingMode,
	EditingMode: PaymentDetailsEditingMode
}
