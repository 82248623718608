import styled from 'styled-components'

export const Header = styled.div`
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const Footer = styled.div`
	width: 100%;
	bottom: 0px;
	display: flex;
	column-gap: 20px;
	position: absolute;
	justify-content: center;
`
export const RequestInfo = styled.div`
	width: 100%;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	display: flex;
`

export const Form = styled.div`
	margin: 0 0 15px;
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const LabelName = styled.div`
	display: flex;
	margin-bottom: 0px;
	padding-right: 5px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-align: left;
`

export const InputArea = styled.textarea`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100%;
	flex-direction: column;
	width: 100%;
	height: 100px;
	padding: 8px 12px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const Message = styled.div`
	margin: 0px;
	display: flex;
	flex-direction: column;
`
export const ObservationAuth = styled.div`
	width: 50%;
	text-align: center;
`

export const DateCreation = styled.div`
	width: 50%;
	padding-left: 10px;
	text-align: center;
`
export const ImageFile = styled.div`
	width: 50%;
	text-align: center;
`

export const TableTechAssist = styled.div`
	font-size: 13px;
	margin-top: 10px;
	color: rgb(51, 51, 51);
	line-height: 30px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	position: relative;
	transition: left 500ms ease;
`
export const HeaderTechAssist = styled.div`
	font-weight: 500;
	display: flex;
	flex-direction: row;
	padding-left: 5px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`
export const AddAuth = styled.div`
	color: rgb(51, 51, 51);
	font-size: 14px;
	line-height: 20px;
	cursor: pointer;
	text-decoration: underline;
	margin-bottom: 5px;
	position: relative;
	transition: left 500ms ease;
	text-align: left;
	top: 5px;
	left: 5px;
`

export const TableContent = styled.div`
	font-weight: 400;
	display: block;
	background-color: #fff;
	max-height: 280px;
	overflow: scroll;
	overflow-x: hidden;
`

export const Characters = styled.div`
	font-size: 9px;
	line-height: small;
	padding-right: 0px;
	margin-bottom: 10px;
	align-self: flex-end;
`
