import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export default (reducers) => {
	const persistedReducers = persistReducer(
		{
			key: 'prp',
			storage,
			whitelist: [
				'adminSystem',
				'auth',
				'baseLists',
				'humanResources',
				'serviceOrders',
				'orderManagement',
				'techs',
				'user'
			]
		},
		reducers
	)

	return persistedReducers
}
