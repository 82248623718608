import styled from 'styled-components'

export const Header = styled.div`
	font-size: 20px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const Footer = styled.div`
	width: 100%;
	bottom: 0px;
	display: flex;
	column-gap: 20px;
	position: absolute;
	justify-content: center;
`
export const SerialNumber = styled.div`
	display: flex;
	font-size: 14px;
`

export const Line = styled.div`
	display: flex;
	flex-direction: row;
`

export const Title = styled.div`
	font-size: 20px;
	line-height: 23px;
	font-weight: 700;
	text-align: left;
	overflow: hidden;
	margin-bottom: 15px;
	display: flex;
	align-items: center;
	gap: 10px;
`

export const Description = styled.div`
	font-weight: 600;
	text-align: left;
	color: #333333;
`

export const Info = styled.div`
	font-weight: 500;
	font-size: 14px;
	text-align: left;
`

export const Change = styled.div`
	display: flex;
	flex-direction: column;
	font-size: 16px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const Input = styled.input`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100px;
	height: 38px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`
