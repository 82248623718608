import React from 'react'
import './styles.scss'

export interface InputProps extends React.ComponentPropsWithoutRef<'input'> {
	variant: 'standard' | 'blue' | 'gray'
	format: 'square' | 'round'
}

const CheckBox = ({ variant, format, ...rest }: InputProps) => {
	return (
		<div
			className={`checkbox-zero-height checkbox-variant-${variant} checkbox-format-${format}`}
		>
			<input type="checkbox" {...rest} />
		</div>
	)
}
export default CheckBox
