import React from 'react'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { MdCheckCircle, MdError, MdLink } from 'react-icons/md'
import './styles.scss'

interface FileListProps {
	files: Array<{
		id: string
		name: string
		readableSize: string
		type: string
		url: string | null
		uploaded: boolean
		error?: boolean
		progress?: number
	}>
	onDelete: (id: string) => void
}

const FileList: React.FC<FileListProps> = ({
	files,
	onDelete
}: FileListProps) => {
	return (
		<ul className="file-list-container">
			{files.map((uploadedFile) => (
				<li key={uploadedFile.id}>
					<div className="file-info">
						<div>
							<strong>{uploadedFile.name}</strong>
							<span>
								{uploadedFile.readableSize}{' '}
								{!!uploadedFile.url && (
									<button
										type="button"
										onClick={() => onDelete(uploadedFile.id)}
									>
										Excluir
									</button>
								)}
							</span>
						</div>
					</div>
					<div>
						{!uploadedFile.uploaded && !uploadedFile.error && (
							<CircularProgressbar
								styles={{ root: { width: 24 }, path: { stroke: '#004687' } }}
								strokeWidth={20}
								value={uploadedFile.progress || 0}
							/>
						)}
						{uploadedFile.url && (
							<a
								href={uploadedFile.url}
								target="_blank"
								rel="noopener noreferrer"
							>
								<MdLink style={{ marginRight: 8 }} size={24} color="#222" />
							</a>
						)}

						{uploadedFile.uploaded && (
							<MdCheckCircle size={24} color="#78e5d5" />
						)}
						{uploadedFile.error && <MdError size={24} color="#e57878" />}
					</div>
				</li>
			))}
		</ul>
	)
}

export default FileList
