import { CircularProgress } from '@material-ui/core'
import React from 'react'

import './styles.scss'

interface Params {
	show: boolean
	text?: string
}

const LoadingProgress: React.FC<Params> = ({ show, text }) => {
	const mainContent = () => {
		return (
			<div className="loading-main-content">
				<div className="loading-main">
					<CircularProgress />
					<span>{text ? text : 'Carregando informações...'}</span>
				</div>
			</div>
		)
	}

	return show ? mainContent() : <></>
}

export default LoadingProgress
