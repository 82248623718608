import styled from 'styled-components'

export const Section = styled.div`
	position: relative;
	text-align: center;
	text-transform: none;
	height: 100%;

	@media screen and (max-width: 767px) {
		width: 100%;
		padding: 0px 0px 0px;
	}

	@media screen and (max-width: 990px) and (min-width: 768px) {
		width: 100%;
	}
`

export const Opt = styled.div`
	line-height: 18px;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	margin-top: 6px;
	margin-bottom: 6px;
	padding-right: 10px;
	padding-left: 10px;
	cursor: pointer;
`
