export const OsTypesList = [
	'ASSISTENCIA',
	'ATENDIMENTO GERAL CONCESSIONARIA',
	'ATENDIMENTO CONCESSIONARIA DEMO',
	'ATENDIMENTO CONCESSIONARIA TESTE',
	'ATENDIMENTO DE URGENCIA',
	'ATENDIMENTO EPP DEMONSTRACAO',
	'CONEXÃO PREMIUM',
	'CORRETIVA PREVENTIVA',
	'CORTESIA 1 MES',
	'CORTESIA COMERCIAL',
	'CORTESIA SERVICO TECNICO',
	'DEMONSTRACAO OU TESTE PARA FILIAIS',
	'ERRO ADMINISTRATIVO',
	'FORA DE GARANTIA',
	'GARANTIA ESTENDIDA',
	'GARANTIA REVENDA',
	'GARANTIA',
	'IMPRODUTIVO',
	'INSTALACAO REVENDA',
	'INSTALACAO',
	'ORCAMENTOS',
	'OUTRAS SOLICITACOES CANAL CHEF',
	'OUTRAS SOLICITACOES',
	'PRE-INSTALACAO',
	'PREVENTIVA',
	'RECALL',
	'RETORNO AO CLIENTE',
	'SEMI NOVOS',
	'SOLICITACAO DE ATENDIMENTO CONCESSIONARIA DEMO',
	'SOLICITACAO DE ATENDIMENTO CONCESSIONARIA TESTE',
	'SOLICITACAO DE ATENDIMENTO EPP DEMONSTRACAO',
	'SOLICITACAO DE ATENDIMENTO VIA ASSISTENCIA TECNICA',
	'SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO',
	'SOLICITACAO DE ATENDIMENTO VIA QUALIDADE',
	'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF)',
	'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF+DESPESA)',
	'SOLICITACAO DE TREINAMENTO POLITICA',
	'SOLICITACAO DE TREINAMENTO REVENDA (CHEF)',
	'SOLICITACAO DE TREINAMENTO REVENDA (CHEF+DESPESAS)',
	'SOLICITACAO DE TREINAMENTO REVENDA',
	'VISITA DO CHEF VIA CONCESSIONARIA',
	'VISITA DO CHEF VIA GERENTE/COORDENADOR'
]

export const OsStatusList = [
	'Agendar',
	'Aguardar Visita',
	'Fazer Follow-up',
	'Faturar Pedido',
	'Efetivar Pedido',
	'Pedido Faturado',
	'Finalizado',
	'Em acompanhamento',
	'Excluída'
]

export const EquipmentLegendStatus = [
	{
		value: '01',
		label: 'EQUIPAMENTO EM CLIENTE',
		color: '#9DDF65',
		borderColor: '#9DDF65'
	},
	{
		value: '02',
		label: 'EQUIPAMENTO EM ESTOQUE',
		color: '#D9473B',
		borderColor: '#D9473B'
	},
	{
		value: '03',
		label: 'EQUIPAMENTO EM MANUTENCAO',
		color: '#E7E95F',
		borderColor: '#E7E95F'
	},
	{
		value: ' 04',
		label: 'EQUIPAMENTO EM NOSSO PODER',
		color: '#5E89C0',
		borderColor: '#5E89C0'
	},
	{
		value: '05',
		label: 'EQUIPAMENTO EXTRAVIADO',
		color: '#E1A645',
		borderColor: '#E1A645'
	},
	{
		value: '06',
		label: 'EQUIPAMENTO DETERIORADO',
		color: '#606060',
		borderColor: '#606060'
	},
	{
		value: '07',
		label: 'EQUIPAMENTO SEPARADO',
		color: '#E2E2E2',
		borderColor: '#E2E2E2'
	},
	{
		value: '08',
		label: 'EQUIPAMENTO GRANEL',
		color: '#194391',
		borderColor: '#999B9E'
	}
]
