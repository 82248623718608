import React, { ReactNode } from 'react'
import './styles.scss'

type Props = {
	children: ReactNode
}

export const OrderItemsMainContain = ({ children }: Props) => (
	<div className="items-information">{children}</div>
)
