import { Button, Menu, MenuItem } from '@material-ui/core'
import { MoreOptionsItem } from 'models/MoreOptionsItem'
import { MdMoreVert } from 'react-icons/md'

import React from 'react'
import './styles.scss'

interface Params {
	id?: string
	options: MoreOptionsItem[]
}

const MoreOptions: React.FC<Params> = ({ id, options }) => {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const onClick = (option: MoreOptionsItem) => {
		option.onClick(option.item)
		handleClose()
	}

	const menuListContent = () => {
		return options.map((option: MoreOptionsItem, index: number) => {
			return (
				<MenuItem
					onClick={(e) => {
						e.stopPropagation()
						onClick(option)
					}}
					key={index}
				>
					{option.label}
				</MenuItem>
			)
		})
	}

	const menuContent = () => {
		return (
			<Menu
				id={id}
				className="menu-box-item"
				keepMounted
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				{menuListContent()}
			</Menu>
		)
	}

	const mainContent = () => {
		return (
			<>
				<Button
					className="more-option-item-click"
					aria-controls="simple-menu"
					aria-haspopup="true"
					onClick={handleClick}
				>
					<MdMoreVert />
				</Button>
				{menuContent()}
			</>
		)
	}

	return mainContent()
}

export default MoreOptions
