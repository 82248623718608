import React from 'react'
import { MdArrowBackIos } from 'react-icons/md'
import { RouteList } from 'utils/Constants'
import { history } from '../../../routes/history'
import './styles.scss'

interface Params {
	action: () => void
}

const MobileNavigator: React.FC<Params> = ({ action }) => {
	const onGoBackAction = () => {
		if (action) {
			action()
		} else {
			switch (history?.length) {
				case 1:
					history.push(RouteList.HOME)
					break
				case 2:
					history.push(RouteList.EQUIPMENTS)
					break

				default:
					history.goBack()
					break
			}
		}
	}

	return (
		<div className="mobile-navigator-component" onClick={onGoBackAction}>
			<MdArrowBackIos size={20} />
		</div>
	)
}

export default MobileNavigator
