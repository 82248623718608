import { VersionContextElement } from 'module/entryPoint/context/VersionContext/VersionContextType'
import React, { useContext } from 'react'
import pack from '../../../../../package.json'
import './styles.scss'

const VersionArea: React.FC = () => {
	const { onUpdateClick } = useContext(VersionContextElement)

	return (
		<div className="version-area-content">
			<div className="update-area-content" onClick={onUpdateClick}>
				<span id="version">Versão {pack.version}</span>
			</div>
		</div>
	)
}

export default VersionArea
