import React, { useState } from 'react'

import { ModalChangeAddress } from 'module/installAndSearch/components/Modals/ModalChangeAddress'
import { ModalConfirmeAddress } from 'module/installAndSearch/components/Modals/ModalConfirmeAddress/index.tsx'
import { ModalSearchTech } from 'module/installAndSearch/components/Modals/ModalSearchTech'
import { ModalNumSerieNotFound } from 'module/installAndSearch/components/Modals/ModalSerialNumberNotFound'
import {
	SearchSerial,
	ValuesForm
} from 'module/installAndSearch/components/SearchSerial'
import {
	allowedCloseClients,
	sortList
} from 'module/installAndSearch/utils/functions'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { history } from 'routes/history'
import { api } from 'services'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import './styles.scss'

export const RequestService = () => {
	const methods = useForm<ValuesForm>()

	const { handleSubmit } = methods

	const [listResponse, setListResponse] = useState<any>(null)
	const [showModalSearchTech, setShowModalSearchTech] = useState(false)
	const [showModalConfirmAddress, setShowModalConfirmAddress] = useState(false)
	const [showModalChangeAddress, setShowModalChangeAddress] = useState(false)
	const [showModalNumSerieNotFound, setShowModalNumSerieNotFound] =
		useState(false)
	const [isLoading, setIsLoading] = useState(false)

	async function onSubmit({ numserie }: any) {
		setIsLoading(true)
		const { data } = await api.get(
			`installedBases/search/?numserie=${numserie}&origin=ADMIN_APP`
		)
		const { client, equipment, code } = data

		if (code === 200) {
			setListResponse({ client, product: equipment })
			onReturn()
			setShowModalConfirmAddress(true)
			setIsLoading(false)
		} else {
			onReturn()
			setShowModalNumSerieNotFound(true)
			setIsLoading(false)
		}
	}

	async function saveNewAddress() {
		if (listResponse) {
			const params = {
				serialNumber: listResponse.product.serial_number,
				client: {
					address: listResponse.client.street,
					neighborhood: listResponse.client.neighborhood,
					city: listResponse.client.city,
					state_sigla: listResponse.client.state_sigla,
					zip_code: listResponse.client.zip_code
				}
			}

			await api.put(`PrpSearchLogs/log`, params)
		}
	}

	const onConfirmAddress = () => {
		setIsLoading(true)
		if (
			listResponse.product &&
			!isCloseLocation() &&
			listResponse.client &&
			listResponse.client.state_sigla !== 'EX'
		) {
			const origin = parseAddress(listResponse.client)
			api
				.get(
					`technicians/getTechListBySpeciality?code=${
						listResponse.product.type_code
					}&state=${
						listResponse.client.state_sigla
					}&origin=${encodeURIComponent(origin)}&from=busca`
				)
				.then((resp: any) => {
					const { techList } = resp.data
					listResponse.techList = sortList(
						techList.filter(
							(e: any) => e.technician_profile !== 'Field_chef_autonomous'
						)
					)
					setIsLoading(false)
					if (techList?.length > 0) {
						return history.push(`/result`, listResponse)
					} else {
						toast.info(
							'Atenção! Não encontramos nenhum técnico tente novamente.'
						)
						return history.push(`/service`)
					}
				})
				.catch((err: any) => {
					setIsLoading(false)
					toast.info('Atenção! Não encontramos nenhum técnico tente novamente.')
					return history.push(`/service`)
				})
		} else {
			history.push(`/result`, listResponse)
		}
	}

	function parseAddress(data: any) {
		const address = data.number
			? `${data.address}, ${data.number}`
			: data.address
		const neighborhood = data.neighborhood ? data.neighborhood : ''
		return `${address}, ${neighborhood}, ${data.city}, ${data.state_sigla}, ${data.zip_code}`
	}

	const isCloseLocation = () => {
		return (
			(listResponse.client &&
				listResponse.client.city
					.toLowerCase()
					.normalize('NFD')
					.replace(/[\u0300-\u036f]/g, '') === 'sao paulo') ||
			(listResponse.client && allowedCloseClients(listResponse.client))
		)
	}

	const onNotConfirmAddress = () => {
		onReturn()
		setShowModalChangeAddress(true)
	}

	const onSubmitLoadTechnicals = ({
		zip_code,
		city,
		state_sigla,
		address,
		neighborhood,
		number
	}: any) => {
		const aux = listResponse
		aux.client.address = `${address}, ${number}`
		aux.client.zip_code = zip_code
		aux.client.neighborhood = neighborhood
		aux.client.city = city
		aux.client.state_sigla = state_sigla

		// Update search log with new address
		saveNewAddress()

		setListResponse(aux)
		onConfirmAddress()
	}

	const onSubmitLoadTechnicalsNoNumserie = ({
		zip_code,
		city,
		state_sigla,
		address,
		neighborhood,
		number
	}: any) => {
		setIsLoading(true)
		const addressComplete: any = {}
		addressComplete.address = number ? `${address}, ${number}` : address
		addressComplete.zip_code = zip_code
		addressComplete.neighborhood = neighborhood
		addressComplete.city = city
		addressComplete.state_sigla = state_sigla

		if (addressComplete) {
			setIsLoading(true)
			const query = `city=${addressComplete.city}&state=${addressComplete.state_sigla}&zip_code=${addressComplete.zip_code}&neighborhood=${addressComplete.neighborhood}&address=${addressComplete.address}`
			api
				.get(`/technicians/getTechListByAddress?${query}`)
				.then((resp: any) => {
					const { data } = resp
					const { techList } = data

					const technicalListSorted = sortList(
						techList.filter(
							(e: any) => e.technician_profile !== 'Field_chef_autonomous'
						)
					)
					setIsLoading(false)
					if (techList?.length > 0) {
						return history.push(`/result-address`, {
							address: addressComplete,
							techList: technicalListSorted
						})
					} else {
						toast.info(
							'Atenção! Não encontramos nenhum técnico tente novamente.'
						)
						return history.push(`/service`)
					}
				})
				.catch((err: any) => {
					setIsLoading(false)
					toast.info('Atenção! Não encontramos nenhum técnico tente novamente.')
					return history.push(`/service`)
				})
		} else {
			toast.info('Atenção! Endereço incompleto ou inválido tente novamente.')
			return history.push(`/service`)
		}
	}

	/**
	 * Method that close all modals
	 */
	const onReturn = () => {
		setShowModalSearchTech(false)
		setShowModalChangeAddress(false)
		setShowModalConfirmAddress(false)
		setShowModalNumSerieNotFound(false)
	}

	/**
	 * Method that open the modal which will change the address
	 */
	const onOpenChangeAddress = () => {
		onReturn()
		setShowModalChangeAddress(true)
	}

	/**
	 * Method that open the modal to found tech by Address.
	 * @see components/modal/BodyModalSearchTech.js
	 */
	const onOpenSearchTechnical = () => {
		onReturn()
		setShowModalSearchTech(true)
	}

	return (
		<div className="request-service">
			<div className="msg msg-container msg-default">
				<strong>
					{`Para solicitar atendimento técnico para um equipamento, informe seu
					número de série no campo abaixo e encontre a assistência mais próxima
					de você ou clique em 'Buscar técnico' e localize por endereço.`}
				</strong>
			</div>
			<FormProvider {...methods}>
				<SearchSerial>
					<div className="container-address-confirm-actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							onClick={handleSubmit(onSubmit)}
							title="Enviar"
						/>
						<Button
							size="lg"
							variant="search"
							title="buscar técnico"
							icon="pin"
							onClick={onOpenSearchTechnical}
						/>
					</div>
				</SearchSerial>
			</FormProvider>
			{showModalConfirmAddress && (
				<ModalConfirmeAddress
					client={listResponse && listResponse.client}
					onConfirmAddress={onConfirmAddress}
					onNotConfirmAddress={onNotConfirmAddress}
					onReturn={onReturn}
					title="Solicitação de atendimento"
					disableButton={isLoading}
				/>
			)}
			{showModalChangeAddress && (
				<ModalChangeAddress
					onSubmit={
						listResponse
							? onSubmitLoadTechnicals
							: onSubmitLoadTechnicalsNoNumserie
					}
					onReturn={onReturn}
					title="Solicitação de atendimento"
				/>
			)}
			{showModalSearchTech && (
				<ModalSearchTech
					onSubmit={onSubmitLoadTechnicalsNoNumserie}
					onReturn={onReturn}
					title="Solicitação de atendimento"
				/>
			)}
			{showModalNumSerieNotFound && (
				<ModalNumSerieNotFound
					onConfirm={onOpenChangeAddress}
					onReturn={onReturn}
					requester={'search'}
				/>
			)}
			{isLoading && <LoadingProgress show={true} />}
		</div>
	)
}
