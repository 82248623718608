import markIcon from 'assets/image/ic-location-black.svg'
import React from 'react'
import './styles.scss'

type CardProps = {
	item: any
	serialNumber: string
}

export const Card = ({ item, serialNumber }: CardProps) => {
	const technical = item

	// async function callTechnicianClick() {
	// 	const params = {
	// 		serialNumber: serialNumber,
	// 		technicianCode: technical.code_prp
	// 	}

	// 	await api.put(`PrpSearchLogs/log`, params)
	// }

	return technical ? (
		<div className="card-prp">
			<div className="card-flex">
				<div>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						<span className="title-card">{technical.name}</span>
						<span className="title-card">{`ID: ${technical.code_prp}`}</span>
					</div>
					<p className="label-distance-mobile">
						<img src={markIcon} alt="" /> {technical.distance} km de distância
					</p>
				</div>
				<p className="label-distance">
					<img src={markIcon} alt="" /> {technical.distance} km de distância
				</p>
			</div>

			<div className="body-card-container">
				<div className="label-info">
					<div className="container-phone">
						{technical.phone && (
							<a href={`tel: ${technical.phone}`}>
								{technical.phone.trim().length >= 8 && `${technical.phone}`}
							</a>
						)}
						{technical.phone1 && (
							<a href={`tel: ${technical.phone1}`}>
								{technical.phone1.trim().length >= 8 && `${technical.phone1}`}
							</a>
						)}
						{technical.cel && (
							<a href={`tel: ${technical.cel}`}>
								{technical.cel.trim().length >= 8 && `${technical.cel}`}
							</a>
						)}
						<a href={`mailto: ${technical.email}`}>{technical.email}</a>
					</div>
					<span>
						{`${technical.address}, ${technical.district}`} <br />
						{`${technical.city} / ${technical.zip_code}`}
					</span>
				</div>
			</div>

			<div className="card-foot">
				<span className="label-specialization">
					Especializado em:
					<div className="list-specialization">
						{technical.prodType
							?.split(', ')
							.map((item: string, index: number) => (
								<div className="item-specialization" key={index}>
									{item}
								</div>
							))}
					</div>
				</span>
			</div>
		</div>
	) : (
		<></>
	)
}
