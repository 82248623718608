import { api } from 'services'
import { store } from 'store'
import useSWR from 'swr'

const fetcher = async (url: string) =>
	api
		.get(url, {
			headers: {
				Authorization: store.getState().auth.token
			}
		})
		.then((r) => r.data)

export function useFetch<Data = any, Error = any>(url: string) {
	const { data, error, mutate, isValidating } = useSWR<Data, Error>(
		url,
		fetcher
	)
	return { data, error, mutate, isValidating }
}
