import { AxiosProgressEvent } from 'axios'
import filesize from 'filesize'
import { uniqueId } from 'lodash'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { uploadFile } from 'services'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Modal from 'shared/components/Modal'
import { ProgressBarCircular } from 'shared/components/ProgressBarCircular'
import Select from 'shared/components/Select'
import CloseCircleIcon from 'shared/components/SvgIcons/CloseCircleIcon'
import movIcon from '../../../../assets/image/MOV.svg'
import mp4Icon from '../../../../assets/image/MP4.svg'
import pdfIcon from '../../../../assets/image/PDF.svg'
import './styles.scss'

export interface UploadedFilesProps {
	file?: File
	id: string
	name: string
	readableSize: string
	preview: string
	size: number
	type: string
	progress?: number
	uploaded: boolean
	error?: boolean
	url: string | null
	attachmentCategory?: string
}

interface UpdateFileData {
	name?: string
	progress?: number
	id?: string
	uploaded?: boolean
	error?: boolean
	url?: string
	attachmentCategory?: string
}

interface UploadModalProps {
	files: UploadedFilesProps[]
	onClose: () => void
	title: string
	onUploadFiles: (files: UploadedFilesProps[]) => void
	activeCategory?: boolean
}

const acceptedFileTypes = [
	'image/png',
	'image/jpeg',
	'image/jpg',
	'application/pdf',
	'video/mp4',
	'video/mov',
	'video/quicktime'
]

const MAX_SIZE = 30 * 1024 * 1024

export const fileAttachmentCategories = [
	{ value: '', label: 'Geral' },
	{ value: 'attachmentsFinance', label: 'Financeiro' },
	{ value: 'attachmentsShipping', label: 'Expedição' },
	{ value: 'attachmentsOrder', label: 'ADM de Pedidos' }
]

export const UploadModal = ({
	files,
	onClose,
	title,
	onUploadFiles,
	activeCategory = false
}: UploadModalProps) => {
	const [uploadedFiles, setUploadedFiles] = useState<UploadedFilesProps[]>(
		files || []
	)

	const addItem = () => {
		const element = document.getElementById('file-upload')
		element?.click()
	}

	const filePreview = (file: any) => {
		switch (file.type) {
			case 'video/mp4':
				return mp4Icon
			case 'video/mov':
				return movIcon
			case 'video/quicktime':
				return movIcon
			case 'application/pdf':
				return pdfIcon
			default:
				return URL.createObjectURL(file)
		}
	}

	const onUploadFile = (files: File[]) => {
		const newFiles = Array.from(files).map((file) => {
			if (file.size > MAX_SIZE) {
				toast.error('Arquivo muito grande.')
			}

			if (!acceptedFileTypes.includes(file.type)) {
				toast.error('Tipo de arquivo inválido.')
			}

			return {
				id: uniqueId(),
				name: file.name,
				readableSize: filesize(file.size),
				preview: filePreview(file),
				size: file.size,
				progress: 0,
				url: '',
				error: false,
				uploaded: false,
				type: file.type,
				file
			}
		})

		const acceptedFiles = newFiles.filter(
			(file) => acceptedFileTypes.includes(file.type) && file.size <= MAX_SIZE
		)
		const newUploadedFiles = [...uploadedFiles, ...acceptedFiles]
		setUploadedFiles(newUploadedFiles)

		newUploadedFiles.forEach((file) => {
			if (!file) {
				return
			}
			processUpload(file)
		})
	}

	function updateFile(id: string, data: UpdateFileData) {
		setUploadedFiles((state) =>
			state.map((uploadedFile) => {
				if (id === uploadedFile.id) {
					return { ...uploadedFile, ...data }
				}
				return uploadedFile
			})
		)
	}

	async function processUpload(uploadedFile: UploadedFilesProps) {
		try {
			const data = new FormData()

			if (!uploadedFile.file) return

			data.append('file', uploadedFile.file, uploadedFile.name)

			const response = await uploadFile.post('upload', data, {
				onUploadProgress: (event: AxiosProgressEvent) => {
					if (!event?.total) return
					const progress = Math.round((event.loaded * 100) / event.total)
					updateFile(uploadedFile.id, {
						progress
					})
				}
			})

			updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data.key,
				url: response.data.url
			})
		} catch (error) {
			updateFile(uploadedFile.id, {
				error: true
			})
		}
	}

	return (
		<Modal show={true} title={title} onClose={onClose} showClose={true}>
			<div className="modal-upload-files">
				<header>Escreva uma breve descrição para cada anexo abaixo:</header>
				<div className="upload-file-container">
					{uploadedFiles?.map((file, index) => (
						<div
							key={file.id}
							className={`preview-file-container ${
								activeCategory ? 'has-category' : ''
							}`}
						>
							<div className="preview-files">
								{!!file?.progress && file?.progress < 100 && (
									<ProgressBarCircular progress={file.progress} width={50} />
								)}
								{!!file.preview && (
									<>
										<img
											id="file-preview"
											src={file.preview || file.url || ''}
											alt=""
										/>
										<div
											className="delete-file-action"
											onClick={() =>
												setUploadedFiles(
													uploadedFiles.filter((f) => f.id !== file.id)
												)
											}
										>
											<CloseCircleIcon
												width={'15px'}
												height={'15px'}
												fill="red"
											/>
										</div>
									</>
								)}
							</div>
							<Input
								placeholder="Descreva o anexo"
								name={`files.${index}.name`}
								variant="light"
								defaultValue={file.name}
								onChange={(e) => updateFile(file.id, { name: e.target.value })}
							/>
							{activeCategory && (
								<div className="has-third-column">
									<Select
										id={`files-${index}-attachmentCategory`}
										options={fileAttachmentCategories}
										selectedOption={[file?.attachmentCategory || '']}
										onSelectOptions={(value) => {
											updateFile(file.id, { attachmentCategory: value[0] })
										}}
									/>
								</div>
							)}
						</div>
					))}
					<div className="preview-file-container">
						<div className="preview-files-empty" onClick={addItem}>
							<div>+</div>
							<input
								id="file-upload"
								type="file"
								multiple
								accept=".jpg, .png, .jpeg, .mp4, .mov, .pdf"
								style={{ display: 'none' }}
								onChange={(e) =>
									onUploadFile(e.target.files as unknown as File[])
								}
							/>
						</div>
						<Input
							placeholder="Descreva o anexo"
							name={''}
							variant="light"
							disabled
						/>
					</div>
				</div>
				<span className="upload-files-actions">
					<Button
						variant="confirmation-gradient"
						size="lg"
						title="Confirmar"
						onClick={() => onUploadFiles(uploadedFiles)}
						disabled={uploadedFiles.some((f) => !f.uploaded)}
					/>
					<Button
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={onClose}
					/>
				</span>
			</div>
		</Modal>
	)
}
