import { TextField } from '@material-ui/core'
import AddIcon from 'assets/image/addIcon.svg'
import Axios from 'axios'
import { StepType } from 'models/StepTypeEnum'
import { Tooltip } from 'module/orderManagement/components/Tooltip'
import { handleClientType } from 'module/orderManagement/utils/constants'
import ProductFamily from 'module/technicalServices/components/ProductFamily'
import OptionsTypeOs from 'module/technicalServices/components/TypeOsSelector'
import { EquipmentLegendStatus } from 'module/technicalServices/utils/constants'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaCircle } from 'react-icons/fa'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'react-toastify'
import {
	createNewServiceOrder,
	getClientAddress,
	getClientLendingByClientId,
	techAssist,
	updateEquipPrev
} from 'services'
import Button from 'shared/components/Button'
import CheckBox from 'shared/components/CheckBox'
import Divider from 'shared/components/Divider'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import AlertIcon from 'shared/components/SvgIcons/AlertIcon'
import { store } from 'store'
import { KeyedMutator } from 'swr'
import {
	clearNumericField,
	normalizeCGC,
	normalizeCepNumber,
	normalizeTelNumber
} from 'utils/Pipe'
import DefectSelector from '../DefectSelector'
import SelectTech, { ITechnicianForm } from './SelectTech'
import './styles.scss'

interface NewServiceOrderProps {
	client: any
	equipment: any
	show: boolean
	onClose: (value: boolean) => void
	mutate: KeyedMutator<any>
}

export interface FormNewServiceOrder {
	equipment: {
		serial_number: string
		name: string
		code_prp: string
		date_end_warranty: string
		sale_date: string
		family_prod: string
		clientLendingId: string
	}
	service: {
		created_by: string
		origin: string
		originOS: string
		client_code: string
		service_order: ''
		contato: string
		phone: string
		email: string
		equipment_id: ''
		type: string
		obs: string
		address: string
		address_number: string
		neighborhood: string
		city: string
		state_sigla: string
		zip_code: string
		complement: string
		defect: string
		addressDefault: boolean
		requestBy: boolean
	}
	client: {
		cod_protheus: string
		razao_social: string
		street: string
		neighborhood: string
		city: string
		zip_code: string
		state: string
		nome_fantasia: string
		cpfCnpj: string
		keyAccount: string
		data_criacao: number
		type_client: string
	}
	msg: string
	user_id: string
	step_type: string
	selectedAddress: 'default' | 'other'
	newVisit: {
		technician: ITechnicianForm
		appointment_date: Date
		part_shipping_date: ''
		closing_date: ''
		user_id: string
		step_type: StepType.NEW_VISIT
		scheduledServiceDay: string
	}
}

const NewServiceOrder = ({
	show,
	onClose,
	mutate,
	equipment,
	client
}: NewServiceOrderProps) => {
	moment.tz.setDefault('UTC')
	const [selectedTechnician, setSelectedTechnician] =
		useState<ITechnicianForm | null>(null)
	const [selectTechnicianModal, setSelectTechnicianModal] =
		useState<boolean>(false)
	const [financialBlock, setFinancialBlock] = useState<boolean>(false)
	const [currentAddress, setCurrentAddress] = useState<{
		street: string
		neighborhood: string
		city: string
		state_sigla: string
		zip_code: string
	}>({
		street: '',
		neighborhood: '',
		city: '',
		state_sigla: '',
		zip_code: ''
	})
	const [generalBlock, setGeneralBlock] = useState<boolean>(false)
	const [outWarranty, setOutWarranty] = useState<boolean>(false)
	const [newAddress, setNewAddress] = useState<string>('')
	const [loading, setLoading] = useState(false)
	const user = store.getState().user

	const {
		watch,
		reset,
		setValue,
		register,
		setFocus,
		setError,
		getValues,
		resetField,
		clearErrors,
		handleSubmit,
		formState: { errors }
	} = useForm<FormNewServiceOrder>({
		reValidateMode: 'onChange',
		defaultValues: {
			equipment: {
				family_prod: equipment?.family_prod,
				clientLendingId: equipment?.client_lending_id,
				serial_number: equipment?.serial_number,
				name: equipment?.description,
				code_prp: equipment?.product_code,
				date_end_warranty: equipment?.date_end_warranty,
				sale_date: equipment?.sale_date
			},
			service: {
				phone: normalizeTelNumber(`${client?.ddd}${client?.tel}`),
				client_code: client?.code,
				contato: client?.contact,
				email: client?.email,
				created_by: 'ADMIN_APP',
				origin: 'ADMIN_APP',
				originOS: 'ADMIN_APP',
				service_order: '',
				equipment_id: '',
				addressDefault: false,
				requestBy: false
			},
			client: {
				razao_social: client?.razao_social,
				nome_fantasia: client?.name,
				cod_protheus: client?.code,
				cpfCnpj: client?.cpf_cnpj,
				keyAccount: client?.key_account,
				data_criacao: Date.now(),
				type_client: client?.type_client
			},
			user_id: user.profile,
			step_type: StepType.CREATE_OS,
			newVisit: {
				part_shipping_date: '',
				closing_date: '',
				user_id: user.profile,
				step_type: StepType.NEW_VISIT
			}
		},
		mode: 'onTouched'
	})

	const hasAddressSelected = watch('selectedAddress')
	const watchedZipCode = watch('service.zip_code')
	const watchedStateSigla = watch('service.state_sigla')
	const watchedCity = watch('service.city')
	const watchedNeighborhood = watch('service.neighborhood')
	const watchedAddress = watch('service.address')
	const watchedAddressNumber = watch('service.address_number')
	const watchedPhone = watch('service.phone')

	useEffect(() => {
		// Verifica se o cliente esta com bloqueio no cadastro
		setGeneralBlock(client?.general_status === '1' ? true : false)

		// Verifica se o cliente esta com bloqueio financeiro
		setFinancialBlock(client?.financial_status === 'S' ? true : false)

		// Verifica se o equipamento esta com o prazo de garantia ativo
		if (
			equipment?.date_end_warranty !== undefined &&
			equipment?.date_end_warranty !== '' &&
			equipment?.date_end_warranty !== null
		) {
			const momentVariable = moment(equipment?.date_end_warranty, 'YYYYMMDD')
			const stringValue = momentVariable.format('YYYY-MM-DD')
			const dateWarranty = new Date(stringValue)
			const currentDate = new Date()
			setOutWarranty(currentDate > dateWarranty ? true : false)
		}

		// Busca o endereço do cliente no PRP depois caso não encontre busca no Protheus
		getClientAddress(client?.code)
			.then((getClientAddressResponse) => {
				const clientPrp = getClientAddressResponse.data.client
				const addressPrp = {
					street: clientPrp.street.trim(),
					neighborhood: clientPrp.neighborhood.trim(),
					city: clientPrp.city.trim(),
					state_sigla: clientPrp.state.trim(),
					zip_code: clientPrp.zip_code.trim()
				}
				const addressProtheus = {
					street: client?.street,
					neighborhood: client?.neighborhood,
					city: client?.city,
					state_sigla: client?.state,
					zip_code: client?.zip_code
				}
				setCurrentAddress(clientPrp?.street ? addressPrp : addressProtheus)
				setValue('client.city', clientPrp ? clientPrp.city : client?.city)
				setValue('client.street', clientPrp ? clientPrp.street : client?.street)
				setValue(
					'client.state',
					clientPrp ? clientPrp.state : client?.state_sigla
				)
				setValue(
					'client.zip_code',
					clientPrp ? clientPrp.zip_code : client?.zip_code
				)
				setValue(
					'client.neighborhood',
					clientPrp ? clientPrp.neighborhood : client?.neighborhood
				)
				if (equipment?.client_lending_id) {
					getClientLendingByClientId(Number(equipment?.client_lending_id))
						.then((getClientLendingResponse) => {
							const { cidade, rua, estado, bairro, zip_code } =
								getClientLendingResponse.data[0].data
							const clientLendingAddress = {
								street: rua,
								neighborhood: bairro,
								city: cidade,
								state_sigla: estado,
								zip_code: zip_code
							}
							setValue('client.street', rua)
							setValue('client.city', cidade)
							setValue('client.state', estado)
							setValue('client.zip_code', zip_code)
							setValue('client.neighborhood', bairro)
							setCurrentAddress(clientLendingAddress)
						})
						.catch(() => {
							toast.error('Erro ao localizar endereço padrão do cliente.')
						})
				}
			})
			.catch(() => {
				toast.error('Erro ao localizar endereço padrão do cliente.')
			})
	}, [equipment?.serial_number])

	useEffect(() => {
		if (errors.service?.type) setFocus('service.type')
		if (errors.service?.defect) setFocus('service.defect')
		if (errors.selectedAddress) setFocus('selectedAddress')
		if (errors.equipment?.family_prod) setFocus('equipment.family_prod')
	}, [setFocus])

	useEffect(() => {
		setValue('service.phone', normalizeTelNumber(watchedPhone))
	}, [watchedPhone])

	useEffect(() => {
		const newFullAddress = `${watchedAddress}, ${watchedAddressNumber} ${watchedNeighborhood} - ${watchedCity} ${watchedZipCode} / ${watchedStateSigla}`
		setNewAddress(newFullAddress)
	}, [
		watchedZipCode,
		watchedStateSigla,
		watchedCity,
		watchedNeighborhood,
		watchedAddress,
		watchedAddressNumber
	])

	useEffect(() => {
		if (hasAddressSelected === 'other') {
			setValue('service.zip_code', normalizeCepNumber(watchedZipCode))
			if (watchedZipCode && watchedZipCode?.length === 9) {
				setLoading(true)
				Axios.get(
					'https://viacep.com.br/ws/' +
						clearNumericField(watchedZipCode) +
						'/json/'
				)
					.then((response) => {
						clearErrors('service.zip_code')
						setValue(
							'service.city',
							response.data.localidade
								.normalize('NFD')
								.replace(/[^a-z A-Z]/g, '')
						)
						clearErrors('service.city')
						setValue('service.state_sigla', response.data.uf)
						clearErrors('service.state_sigla')
						setValue(
							'service.address',
							response.data.logradouro
								.normalize('NFD')
								.replace(/[^a-z A-Z]/g, '')
						)
						clearErrors('service.address')
						setValue(
							'service.neighborhood',
							response.data.bairro.normalize('NFD').replace(/[^a-z A-Z]/g, '')
						)
						clearErrors('service.neighborhood')
						setLoading(false)
					})
					.catch(() => {
						toast.error('Erro ao encontrar endereço pelo CEP.')
						setLoading(false)
					})
			}
		}
	}, [watchedZipCode, hasAddressSelected])

	const handleOnClose = () => {
		reset()
		mutate()
		setLoading(false)
		onClose(false)
	}

	const onSubmit = (form: FormNewServiceOrder) => {
		form.service.phone = clearNumericField(form?.service?.phone)
		form.service.zip_code = clearNumericField(form?.service?.zip_code)
		form.client.zip_code = clearNumericField(form.client?.zip_code)

		if (getValues('selectedAddress') === 'default') {
			form.service.address = `${form?.service?.address.trim()}`
		} else {
			form.service.address = `${form?.service?.address}, ${form?.service?.address_number}`
		}

		if (!form.equipment?.family_prod) {
			setError(
				'equipment.family_prod',
				{
					type: 'required',
					message: 'O campo Familia do produto é obrigatório!'
				},
				{ shouldFocus: true }
			)
			setFocus('equipment.family_prod')
			return
		}

		if (!form.service?.type) {
			setError(
				'service.type',
				{ type: 'required', message: 'O campo Tipo de serviço é obrigatório!' },
				{ shouldFocus: true }
			)
			setFocus('service.type')
			return
		}

		if (!form.service?.defect) {
			setError(
				'service.defect',
				{
					type: 'required',
					message: 'O campo Defeito relatado é obrigatório!'
				},
				{ shouldFocus: true }
			)
			setFocus('service.defect')
			return
		}

		if (hasAddressSelected !== 'default' && hasAddressSelected !== 'other') {
			setError(
				'selectedAddress',
				{ type: 'required', message: 'É obrigatório informar um endereço!' },
				{ shouldFocus: true }
			)
			return
		}

		setLoading(true)
		createNewServiceOrder(form)
			.then((resp) => {
				if (resp.status === 200 && !!selectedTechnician) {
					const newVisitPayload = {
						service_order: resp?.data?.serviceOrderCode,
						technician: {
							name: form?.newVisit?.technician?.name,
							phone: form?.newVisit?.technician?.phone,
							code_prp: form?.newVisit?.technician?.code_prp
						},
						appointment_date: form?.newVisit?.appointment_date,
						part_shipping_date: form?.newVisit?.part_shipping_date,
						closing_date: form?.newVisit?.closing_date,
						user_id: form?.newVisit?.user_id,
						step_type: form?.newVisit?.step_type
					}
					techAssist(newVisitPayload)
						.then(() => {
							if (form?.service?.type === 'PREVENTIVA') {
								const app_date =
									newVisitPayload?.appointment_date === undefined
										? ''
										: newVisitPayload?.appointment_date
								const prevDate = new Date(app_date.toString())
								prevDate.setDate(prevDate.getDate() + 90)
								const update = {
									next_preventive: prevDate
								}
								const number =
									form.equipment?.serial_number === undefined
										? ''
										: form.equipment?.serial_number
								updateEquipPrev(update, number)
							}
							handleOnClose()
							toast.success(
								'Ordem de Serviço criada com sucesso. N°: ' +
									resp?.data?.serviceOrderCode
							)
						})
						.catch(() => {
							setLoading(false)
							toast.error('Erro ao registrar novo atendimento')
						})
				} else {
					handleOnClose()
					toast.success(
						'Ordem de Serviço criada com sucesso. N°: ' +
							resp?.data?.serviceOrderCode
					)
				}
			})
			.catch(() => {
				setLoading(false)
				toast.error('Erro ao adicionar nova ordem de serviço.')
			})
	}

	const newServiceOrderHeaderRequestContain = () => {
		const hasAlert = outWarranty || generalBlock || financialBlock
		const equipmentHistory = equipment?.equip_history
		const hasFamilyProduct =
			equipment?.family_prod || equipment?.family_prod !== ''

		const onSelectProductFamily = (value: string) => {
			setValue('equipment.family_prod', value)
			clearErrors('equipment.family_prod')
		}

		const handleAlertIcon = (clientType: string) => {
			if (clientType !== 'F')
				return <AlertIcon height="30" width="30" fill="red" />
			return ''
		}

		return (
			<div className="new-order-contain-header-request">
				<div className="header-request-equipment">
					<div className="header-request-equipment-description">
						<span className="equipment-description-name">
							{equipment?.legend &&
								EquipmentLegendStatus.map((legend) => {
									return (
										legend?.value === equipment?.legend && (
											<Tooltip info={legend.label} mobile={false}>
												<FaCircle color={legend.color} size={15} />
											</Tooltip>
										)
									)
								})}
							{equipment?.description}
						</span>
						{!hasFamilyProduct && (
							<div
								className={`equipment-family-product-field ${
									errors.equipment?.family_prod &&
									'equipment-family-product-field-error'
								} `}
							>
								<ProductFamily
									show={!hasFamilyProduct}
									key={'equipment?.family_prod'}
									equipment={{
										family_prod: equipment?.family_prod,
										id: equipment?.equipment_id
									}}
									onSelectProductFamily={onSelectProductFamily}
									innerRef={{ ...register('equipment.family_prod') }}
								/>
								<small>
									{errors.equipment?.family_prod && (
										<span className="input-error-span">
											{errors.equipment?.family_prod.message}
										</span>
									)}
								</small>
							</div>
						)}
					</div>
					<div className="equipment-complement-information">
						<div>
							<div className="new-service-order-form-field-title">
								Número de Série:
							</div>
							<div className="complement-information-value">
								{equipment?.serial_number}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">
								Garantia:
							</div>
							<div className="complement-information-value">
								{equipment?.date_end_warranty?.slice(6, 8) +
									'/' +
									equipment?.date_end_warranty?.slice(4, 6) +
									'/' +
									equipment?.date_end_warranty?.slice(0, 4)}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">
								Data de Venda:{' '}
							</div>
							<div className="complement-information-value">
								{equipment?.sale_date?.slice(6, 8) +
									'/' +
									equipment?.sale_date?.slice(4, 6) +
									'/' +
									equipment?.sale_date?.slice(0, 4)}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">
								Total de chamados abertos:
							</div>
							<div className="complement-information-value">
								{equipmentHistory?.all_os_count ? (
									equipmentHistory?.all_os_count
								) : (
									<Skeleton />
								)}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">
								Chamados nos últimos 3 meses:
							</div>
							<div className="complement-information-value">
								{equipmentHistory?.last_os_count ? (
									equipmentHistory?.last_os_count
								) : (
									<Skeleton />
								)}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">
								Valor gasto com atendimentos:
							</div>
							<div className="complement-information-value">
								{equipmentHistory?.total_price.toFixed(2) ? (
									'R$' + equipmentHistory?.total_price.toFixed(2)
								) : (
									<Skeleton />
								)}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">Cliente:</div>
							<div className="complement-information-value">
								{`${client?.code} - ${client?.razao_social}`}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">CNPJ:</div>
							<div className="complement-information-value">
								{client?.cpf_cnpj ? normalizeCGC(client?.cpf_cnpj) : '-'}
							</div>
						</div>
						<div>
							<div className="new-service-order-form-field-title">
								Tipo de cliente:
							</div>
							<div className="complement-information-value">
								{client?.client_type
									? handleClientType(client?.client_type)
									: '-'}{' '}
								{handleAlertIcon(client?.client_type)}
							</div>
						</div>
					</div>
				</div>
				{!!hasAlert && (
					<div className="new-service-order-alerts">
						<span>Atenção:</span>
						{outWarranty && (
							<p id="is_warranty">Equipamento fora de garantia;</p>
						)}
						{generalBlock && (
							<p id="is_warranty">Cliente com bloqueio geral;</p>
						)}
						{financialBlock && (
							<p id="is_warranty">Cliente com bloqueio financeiro;</p>
						)}
					</div>
				)}
			</div>
		)
	}

	const newServiceOrderContactContain = () => {
		return (
			<div className="new-service-order-contact-form">
				<div className="new-service-order-form-field">
					<label
						htmlFor="contact"
						className="new-service-order-form-field-title"
					>
						Nome:
					</label>
					<Input
						name="name"
						variant="light"
						errors={errors.service?.contato}
						readOnly={generalBlock || financialBlock}
						onChange={(event) =>
							setValue('service.contato', event.target.value)
						}
						innerRef={{
							...register('service.contato', {
								required: {
									value: true,
									message: 'O campo Nome é obrigatório!'
								}
							})
						}}
					/>
				</div>
				<div className="new-service-order-form-field">
					<label htmlFor="email" className="new-service-order-form-field-title">
						Email:
					</label>
					<Input
						name="email"
						variant="light"
						errors={errors.service?.email}
						readOnly={generalBlock || financialBlock}
						onChange={(event) => setValue('service.email', event.target.value)}
						innerRef={{
							...register('service.email', {
								required: {
									value: true,
									message: 'O campo Email é obrigatório!'
								}
							})
						}}
					/>
				</div>
				<div className="new-service-order-form-field">
					<label htmlFor="phone" className="new-service-order-form-field-title">
						Telefone:
					</label>
					<Input
						name="phone"
						type="text"
						variant="light"
						placeholder="(00) 0000-0000"
						errors={errors.service?.phone}
						readOnly={generalBlock || financialBlock}
						onChange={(event) => setValue('service.phone', event.target.value)}
						innerRef={{
							...register('service.phone', {
								required: {
									value: true,
									message: 'O campo Telefone é obrigatório!'
								},
								minLength: {
									value: 14,
									message: 'O campo Telefone está incompleto!'
								}
							})
						}}
					/>
				</div>
			</div>
		)
	}

	const newServiceOrderScheduleDayContain = () => {
		const handleSelectTechnician = () => {
			if (!getValues('service.type')) {
				setError(
					'service.type',
					{
						type: 'required',
						message: 'O campo Tipo de serviço é obrigatório!'
					},
					{ shouldFocus: true }
				)
				setFocus('service.type')
				return
			}
			if (!getValues('equipment.family_prod')) {
				setError(
					'equipment.family_prod',
					{
						type: 'required',
						message: 'O campo Familia do produto é obrigatório!'
					},
					{ shouldFocus: true }
				)
				setFocus('equipment.family_prod')
				return
			}
			if (hasAddressSelected !== 'default' && hasAddressSelected !== 'other') {
				setError(
					'selectedAddress',
					{ type: 'required', message: 'É obrigatório informar um endereço!' },
					{ shouldFocus: true }
				)
				return
			}
			if (hasAddressSelected === 'other') {
				if (getValues('service.zip_code') === '') {
					setError(
						'service.zip_code',
						{ type: 'required', message: 'O campo CEP é obrigatório.' },
						{ shouldFocus: true }
					)
					return
				}
				if (getValues('service.city') === '') {
					setError(
						'service.city',
						{ type: 'required', message: 'O campo Cidade é obrigatório.' },
						{ shouldFocus: true }
					)
					return
				}
				if (getValues('service.state_sigla') === '') {
					setError(
						'service.state_sigla',
						{ type: 'required', message: 'O campo Estado é obrigatório.' },
						{ shouldFocus: true }
					)
					return
				}
				if (getValues('service.neighborhood') === '') {
					setError(
						'service.neighborhood',
						{ type: 'required', message: 'O campo Bairro é obrigatório.' },
						{ shouldFocus: true }
					)
					return
				}
				if (getValues('service.address') === '') {
					setError(
						'service.address',
						{ type: 'required', message: 'O campo Endereço é obrigatório.' },
						{ shouldFocus: true }
					)
					return
				}
			}
			setSelectTechnicianModal(true)
		}

		const selectedTechnicianContain = () => {
			return selectedTechnician ? (
				<div className="new-service-order-select-technician">
					<div className="selected-technician-information">
						<div className="selected-technician-information-name">
							{selectedTechnician.name}
						</div>
						<div className="selected-technician-information-contact">
							<span>{`ID: ${selectedTechnician?.code_prp}`}</span>
							<span>{selectedTechnician.phone}</span>
							<span>{selectedTechnician.email}</span>
						</div>
					</div>
					<div className="change-selected-technician-button">
						<Button
							size="sm"
							title="Trocar"
							variant="outline"
							onClick={handleSelectTechnician}
						/>
					</div>
				</div>
			) : (
				<div className="new-service-order-select-technician-action">
					<img
						src={AddIcon}
						alt="Add technician icon"
						id="addTechnicianIcon"
						onClick={handleSelectTechnician}
					/>
					<label htmlFor="addTechnicianIcon" onClick={handleSelectTechnician}>
						Selecionar técnico
					</label>
				</div>
			)
		}

		const handleAddressDefault = () => {
			setValue('selectedAddress', 'default')
			setValue('service.city', getValues('client.city'))
			setValue('service.address', getValues('client.street'))
			setValue('service.zip_code', getValues('client.zip_code'))
			setValue('service.state_sigla', getValues('client.state'))
			setValue('service.neighborhood', getValues('client.neighborhood'))
			setValue('service.address_number', '')
		}

		const handleNewAddress = () => {
			resetField('service.city')
			resetField('service.address')
			resetField('service.zip_code')
			resetField('service.state_sigla')
			resetField('service.neighborhood')
			resetField('service.address_number')
			setValue('service.city', '')
			setValue('service.address', '')
			setValue('service.zip_code', '')
			setValue('service.state_sigla', '')
			setValue('service.neighborhood', '')
			setValue('service.address_number', '')
			setValue('selectedAddress', 'other')
		}

		const defaultAddressCheckbox = () => {
			return (
				<label
					className="radio-button-field w-radio"
					style={{ marginBottom: '20px' }}
				>
					<div
						className="w-form-formradioinput radio-button w-radio-input"
						style={{ display: 'flex', flexDirection: 'row' }}
					>
						<CheckBox
							variant="standard"
							format="square"
							onChange={(e) => {
								setValue('service.addressDefault', e.target.checked)
								setValue(
									'client.city',
									e.target.checked ? getValues('service.city') : client?.city
								)
								setValue(
									'client.street',
									e.target.checked
										? getValues('service.address')
										: client?.street
								)
								setValue(
									'client.state',
									e.target.checked
										? getValues('service.state_sigla')
										: client?.state_sigla
								)
								setValue(
									'client.zip_code',
									e.target.checked
										? getValues('service.zip_code')
										: client?.zip_code
								)
								setValue(
									'client.neighborhood',
									e.target.checked
										? getValues('service.neighborhood')
										: client?.neighborhood
								)
							}}
						/>
						<span className="radio-button-label w-form-label">
							Salvar como endereço padrão
						</span>
					</div>
				</label>
			)
		}

		return (
			<div className="new-service-order-visit-information">
				<div className="new-service-order-form-field-title">Endereço:</div>
				<div
					className={`new-service-order-visit-address-information ${
						!!errors.selectedAddress &&
						'new-service-order-visit-address-information-error'
					} `}
				>
					<label className="radio-button-field w-radio">
						<div className="w-form-formradioinput radio-button w-radio-input">
							<input
								type="radio"
								id="default"
								value="default"
								className="address1"
								onClick={handleAddressDefault}
								{...register('selectedAddress')}
							/>
							<span className="radio-button-label w-form-label">{`${currentAddress.street}, ${currentAddress.neighborhood}, ${currentAddress.city} - ${currentAddress.state_sigla} - ${currentAddress.zip_code}`}</span>
						</div>
					</label>
					<label className="radio-button-field w-radio">
						<div className="w-form-formradioinput radio-button w-radio-input">
							<input
								id="other"
								value="other"
								className="other"
								type="radio"
								onClick={handleNewAddress}
								{...register('selectedAddress')}
							/>
							<span className="radio-button-label w-form-label">Outro</span>
						</div>
					</label>

					{hasAddressSelected === 'other' && (
						<div className="new-address-form">
							<div className="new-address-form-first-line">
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										CEP:
									</label>
									<Input
										maxLength={9}
										name="zip_code"
										variant="light"
										errors={errors.service?.zip_code}
										readOnly={generalBlock || financialBlock}
										onChange={(event) =>
											setValue('service.zip_code', event.target.value)
										}
										innerRef={{
											...register('service.zip_code', {
												required: {
													value: hasAddressSelected === 'other',
													message: 'O campo CEP é obrigatório!'
												},
												maxLength: {
													value: 9,
													message:
														'O campo CEP passou seu limite de caracteres!'
												},
												minLength: {
													value: 9,
													message: 'O campo CEP está incompleto!'
												}
											})
										}}
									/>
								</div>
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										Cidade:
									</label>
									<Input
										name="city"
										variant="light"
										readOnly={true}
										errors={errors.service?.city}
										onChange={(event) =>
											setValue('service.city', event.target.value)
										}
										innerRef={{
											...register('service.city', {
												required: {
													value: hasAddressSelected === 'other',
													message: 'O campo Cidade é obrigatório!'
												}
											})
										}}
									/>
								</div>
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										Estado:
									</label>
									<Input
										variant="light"
										readOnly={true}
										name="state_sigla"
										errors={errors.service?.state_sigla}
										onChange={(event) =>
											setValue('service.state_sigla', event.target.value)
										}
										innerRef={{
											...register('service.state_sigla', {
												required: hasAddressSelected === 'other'
											})
										}}
									/>
								</div>
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										Bairro:
									</label>
									<Input
										variant="light"
										name="neighborhood"
										errors={errors.service?.neighborhood}
										readOnly={generalBlock || financialBlock}
										onChange={(event) =>
											setValue('service.neighborhood', event.target.value)
										}
										innerRef={{
											...register('service.neighborhood', {
												required: {
													value: hasAddressSelected === 'other',
													message: 'O campo Bairro é obrigatório!'
												}
											})
										}}
									/>
								</div>
							</div>
							<div className="new-address-form-last-line">
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										Endereço:
									</label>
									<Input
										variant="light"
										name="address"
										errors={errors.service?.address}
										readOnly={generalBlock || financialBlock}
										onChange={(event) =>
											setValue('service.address', event.target.value)
										}
										innerRef={{
											...register('service.address', {
												required: {
													value: hasAddressSelected === 'other',
													message: 'O campo Endereço é obrigatório!'
												}
											})
										}}
									/>
								</div>
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										N°:
									</label>
									<Input
										name="address_number"
										variant="light"
										errors={errors.service?.address_number}
										readOnly={generalBlock || financialBlock}
										onChange={(event) =>
											setValue('service.address_number', event.target.value)
										}
										innerRef={{
											...register('service.address_number', {
												required: hasAddressSelected === 'other'
											})
										}}
									/>
								</div>
								<div className="new-service-order-form-field">
									<label
										htmlFor="contact"
										className="new-service-order-form-field-title"
									>
										Complemento:
									</label>
									<Input
										name="complement"
										variant="light"
										errors={errors.service?.complement}
										readOnly={generalBlock || financialBlock}
										onChange={(event) =>
											setValue('service.complement', event.target.value)
										}
										innerRef={{
											...register('service.complement')
										}}
									/>
								</div>
							</div>
							{defaultAddressCheckbox()}
						</div>
					)}
				</div>
				<small>
					{errors.selectedAddress && (
						<span className="input-error-span">
							{errors.selectedAddress.message}
						</span>
					)}
				</small>

				<div className="new-service-order-visit-technician-information">
					<div className="visit-date-field">
						<div className="new-service-order-form-field-title">Data:</div>
						<TextField
							id="date"
							type="date"
							className="data"
							InputLabelProps={{ shrink: true }}
							{...register('newVisit.appointment_date', {
								required: {
									value: !!selectedTechnician,
									message:
										'A data é obrigatória quando um técnico é selecionado.'
								}
							})}
						/>
						<small>
							{errors.newVisit?.appointment_date && (
								<span className="input-error-span">
									{errors.newVisit.appointment_date.message}
								</span>
							)}
						</small>
					</div>
					{selectedTechnicianContain()}
				</div>
			</div>
		)
	}

	const newServiceOrderDefectTypesContain = () => {
		const onSelectType = (value: any) => {
			const data = JSON.parse(value)
			if (
				data.value === 'INSTALACAO' ||
				data.value === 'CORTESIA SERVICO TECNICO' ||
				data.value === 'FORA DE GARANTIA' ||
				data.value === 'INSTALACAO REVENDA' ||
				data.value === 'CORTESIA COMERCIAL'
			) {
				setValue('service.requestBy', true)
			}
			setValue('service.type', data.value)
			clearErrors('service.type')
		}

		const onSelectDefect = (value: string) => {
			setValue('service.defect', value)
			clearErrors('service.defect')
		}

		return (
			<div className="new-service-order-complement-details">
				<div className="complement-details">
					<div
						className={`complement-details-type-defect ${
							!!errors.service?.type && 'complement-details-type-error'
						}`}
					>
						<span className="new-service-order-form-field-title">
							Tipo de serviço:
						</span>
						<OptionsTypeOs
							selectedType={getValues('service.type')}
							serialNumber={equipment?.serial_number}
							onSelectType={onSelectType}
							innerRef={{ ...register('service.type') }}
							key={'service.type'}
						/>
						<small>
							{errors.service?.type && (
								<span className="input-error-span">
									O campo Tipo de serviço é obrigatório!
								</span>
							)}
						</small>
					</div>
					<div
						className={`complement-details-type-defect ${
							!!errors.service?.defect && 'complement-details-defect-error'
						}`}
					>
						<span className="new-service-order-form-field-title">
							Defeito relatado:
						</span>
						<DefectSelector
							selectedDefect={getValues('service.defect')}
							onSelectDefect={onSelectDefect}
							innerRef={{ ...register('service.defect') }}
						/>
						<small>
							{errors.service?.defect && (
								<span className="input-error-span">
									{errors.service.defect.message}
								</span>
							)}
						</small>
					</div>
				</div>
				<div className="complement-details-observation-field">
					<span className="new-service-order-form-field-title">
						Observação:
					</span>
					<textarea
						id="observation"
						cols={30}
						rows={10}
						placeholder="Digite aqui sua mensagem opcional"
						{...register('service.obs')}
					/>
				</div>
			</div>
		)
	}

	const newServiceOrderFooterActionsContain = () => {
		return (
			<div className="new-service-order-footer-actions">
				<Button
					variant="confirmation-gradient"
					size="lg"
					title="Enviar"
					disabled={generalBlock || financialBlock}
					onClick={handleSubmit(onSubmit)}
				/>
				<Button
					variant="cancellation-gradient"
					size="lg"
					title="Cancelar"
					onClick={handleOnClose}
				/>
			</div>
		)
	}

	const handleOnSelectTechnician = (technician: ITechnicianForm) => {
		setValue('newVisit.technician', technician)
		setSelectedTechnician(technician)
	}

	return (
		<Modal
			show={show}
			onClose={handleOnClose}
			title="Solicitação de atendimento"
			showClose={true}
		>
			<div className="new-service-order">
				<div className="new-service-order-main-contain">
					{newServiceOrderHeaderRequestContain()}
					<Divider title="Informações de contato" />
					{newServiceOrderContactContain()}
					<Divider title="Informações do serviço" />
					{newServiceOrderDefectTypesContain()}
					<Divider title="Informações da visita" />
					{newServiceOrderScheduleDayContain()}
				</div>
				{newServiceOrderFooterActionsContain()}
			</div>
			<LoadingProgress show={loading} text="Carregando..." />

			<SelectTech
				show={selectTechnicianModal}
				onClose={setSelectTechnicianModal}
				currentAddress={
					hasAddressSelected === 'default'
						? `${currentAddress.street} ${currentAddress.neighborhood} ${currentAddress.city} - ${currentAddress.state_sigla} - ${currentAddress.zip_code}`
						: newAddress
				}
				selectedTechnician={selectedTechnician && selectedTechnician}
				onSelectTechnician={handleOnSelectTechnician}
				type={getValues('service.type')}
				type_code={equipment?.type_code}
				buttonReturn={false}
				state_sigla={getValues('service.state_sigla')}
			/>
		</Modal>
	)
}
export default NewServiceOrder
