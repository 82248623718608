import React from 'react'
import Divider from 'shared/components/Divider'
import Modal from 'shared/components/Modal'
import './styles.scss'

type DiscountPolicyProps = {
	show: boolean
	onClose: () => void
}

const DiscountPolicy = ({ show, onClose }: DiscountPolicyProps) => {
	return (
		<Modal
			title="POLíTICA DE DESCONTOS"
			onClose={onClose}
			show={show}
			enableAwayClose={true}
			showClose={true}
		>
			<div className="discount-policy-contain">
				<div className="discount-policy-section">
					<div className="discount-policy-topic">
						<Divider title="REGRAS GERAIS" />
						<span>
							A) Autonomia gerente: 20% + 5% + 10%, qualquer desconto fora desse
							padrão deve ser solicitada para o Diretor comercial
						</span>
						<br />
						<span>
							B) Caso desconto ultrapasse 5% adicional para cartão de crédito
							não será necessário aprovação do Diretor Financeiro
						</span>
						<br />
						<span>
							C) Caso seja aplicado 5% de desconto em qualquer condição de
							pagamento não será necessário autorização do gerente comercial
						</span>
						<br />
						<span>
							D) Para cliente KA não será necessário autorização de desconto,
							quando pedido estiver dentro da tabela
						</span>
						<br />
						<span>
							E) Para Seminovos deve seguir politica de desconto localizado:
							Z:Tabelas/ano vigente/política de desconto seminovos
						</span>
						<br />
						<span>
							F) Gerentes KA tem autonomia para desconto conforme política de
							Revenda
						</span>
						<br />
						<span>
							G) Descontos promocional estão autorizados, desde que sigam o
							padrão estabelecido e divulgado por e-mail/ portal Marketing.
						</span>
						<br />
					</div>
				</div>
				<div className="discount-policy-section">
					<div className="discount-policy-topic">
						<Divider title="REVENDAS" />
						<span>
							A) Deve seguir politica de desconto conforme: Z: Tabelas/ano
							vigente/política de desconto revenda
						</span>
						<br />
						<span>
							B) Qualquer preço que esteja fora do padrão e não esteja em
							nenhuma promoção vigente será necessário autorização do Diretor
							Comercial
						</span>
						<br />
						<span>
							C) Para revendas que não possuem limite de crédito disponível, ou
							que não realizem a compra por um período de 3 meses, será
							necessário analise da Diretoria Financeira. Quando houver desconto
							adicional de 5% para revendas onde tiver representante regional ou
							concessionária, ou venda tenha sido realizada por um de nossos
							representantes de revenda, deve ter autorização do diretor da
							área.
						</span>
					</div>
				</div>
				<div className="discount-policy-combine-section">
					<div className="discount-policy-section">
						<div className="discount-policy-topic">
							<Divider title="CONSUMIDOR FINAL" />
							<span>Pagamento a vista: 20% + 5%</span>
							<br />
							<span>Parcelamento pela empresa: em até 6 x 20%</span>
						</div>
						<div className="discount-policy-topic">
							<Divider title="BIZ" />
							<span>Sinal 10% + 09x - 15%</span>
							<br />
							<span>Sinal de 10% + 12x - 15%</span>
							<br />
							<span>Sinal de 20% + 18x - 10% </span>
						</div>
						<div className="discount-policy-topic">
							<Divider title="BNDES" />
							<span>20%</span>
						</div>
					</div>
					<div className="discount-policy-section">
						<div className="discount-policy-topic">
							<Divider title="SANTANDER" />
							<span>Sinal mínimo 20% e saldo em até 11x - 15%</span>
							<br />
							<span>Financiamentos 20%</span>
						</div>
						<div className="discount-policy-topic">
							<Divider title="CARTÃO DE CRÉDITO" />
							<span>Em até 6x: 20%</span>
							<br />
							<span>De 6x a 12x: 15%</span>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	)
}
export default DiscountPolicy
