import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import { UsersJacContext } from 'module/adminSystem/context/UsersJacContext'
import { changeUserJac } from 'module/adminSystem/models/users-jac'
import { GenericTable } from 'module/orderManagement/components/GenericTable'
import React, { useContext, useEffect, useState } from 'react'
import { MdDeleteOutline } from 'react-icons/md'
import ConfirmModal from 'shared/Modals/ConfirmModal'
import Paginator from 'shared/Paginator'
import LoadingProgress from 'shared/components/LoadingProgress'
import { ModalUsersJac } from './modalUserJac'
import './styles.scss'

export const UsersJac = () => {
	const [showModal, setShowModal] = useState<boolean>()
	const [selectDeleteUser, setSelectDeleteUser] = useState<changeUserJac>()

	const {
		list,
		onGetUsersJAC,
		currentPage,
		totalPages,
		onPageChange,
		isLoading,
		onGetUserJAC,
		userJac,
		setUserJac,
		handleDeleteUserJAC,
		onSearchParams
	} = useContext(UsersJacContext)
	const onSearch = (search: string) => {
		onSearchParams(search)
	}

	useEffect(() => {
		onGetUsersJAC()
	}, [currentPage, totalPages])

	return (
		<>
			<article className="jac-users-app-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={(search) => {
							onSearch(search)
						}}
						text="Buscar por nome ou email"
					/>
					<HeaderSection.button
						onClick={() => setShowModal(true)}
						text="NOVO USUÁRIO"
					/>
				</HeaderSection.root>
				<div className="jac-users-app-table">
					<GenericTable
						onRowClicked={(user) =>
							onGetUserJAC(user as unknown as changeUserJac)
						}
						data={
							list && [
								...list.map((user) => ({
									...user,
									id: user.id,
									name: user.name,
									email: user.email,
									action: (
										<MdDeleteOutline
											size={24}
											color="var(--primary-light)"
											onClick={() => {
												if (!user) return
												setSelectDeleteUser(user)
											}}
										/>
									)
								}))
							]
						}
						columns={[
							{
								key: 'id',
								header: 'ID',
								width: '15%'
							},
							{
								key: 'name',
								header: 'Nome',
								width: '40%'
							},
							{
								key: 'email',
								header: 'Email',
								width: 'calc(45% - 36px)'
							},
							{
								key: 'action',
								header: '',
								width: '36px'
							}
						]}
					/>
				</div>
				<Paginator
					total={totalPages || 1}
					selected={currentPage}
					onPageChange={onPageChange}
				/>
			</article>
			{showModal && (
				<ModalUsersJac.create
					title="NOVO USUÁRIO JAC"
					onClose={() => setShowModal(false)}
				/>
			)}
			{userJac && (
				<ModalUsersJac.editing
					title="EDITAR USUÁRIO JAC"
					onClose={() => setUserJac(null)}
				/>
			)}
			{selectDeleteUser && (
				<ConfirmModal
					title="AVISO"
					confirmLabel="SIM"
					show={true}
					onConfirm={() => {
						if (!selectDeleteUser) return
						handleDeleteUserJAC(selectDeleteUser?.id, () =>
							setSelectDeleteUser(undefined)
						)
					}}
					onCancel={() => setSelectDeleteUser(undefined)}
					buttonWidth="68px"
				>
					<span style={{ textAlign: 'center' }}>
						Deseja excluir o usuário{' '}
						<strong>{`${selectDeleteUser?.name}`}</strong>? <br />
						Essa ação não poderá ser revertida.
					</span>
				</ConfirmModal>
			)}
			<LoadingProgress show={isLoading} />
		</>
	)
}
