import React from 'react'
import { useDispatch } from 'react-redux'
import { history } from 'routes/history'
import { signOut } from 'store/modules/auth/actions'
import { RouteList } from 'utils/Constants'
import iconHelp from '../../../../../assets/image/help-24px.png'
import iconLogout from '../../../../../assets/image/logout-24px.png'
import logoPratica from '../../../../../assets/image/new-logo-prp.webp'
import iconTools from '../../../../../assets/image/tools-24px.png'

import './styles.scss'

interface Props {
	onHelpClick: () => void
	onSettingsClick: () => void
}

const MainOptions: React.FC<Props> = ({ onSettingsClick, onHelpClick }) => {
	const dispatch = useDispatch()

	const handleLogout = () => {
		dispatch(signOut())
		history.push(RouteList.HOME)
	}

	return (
		<section className="main-mobile-menu-options transitions">
			<div className="mobile-menu-logo">
				<img src={logoPratica} alt="Prática Klimaquip" />
			</div>
			<div className="menu-option-list">
				<div className="menu-option-item" onClick={onSettingsClick}>
					<img src={iconTools} alt="config-icon" />
					<span>Configurações</span>
				</div>
				<div className="menu-option-item" onClick={onHelpClick}>
					<img src={iconHelp} alt="help-icon" />
					<span>Ajuda</span>
				</div>
				<div className="menu-option-item logout" onClick={handleLogout}>
					<img src={iconLogout} alt="notify-icon" />
					<span>Sair</span>
				</div>
			</div>
		</section>
	)
}

export default MainOptions
