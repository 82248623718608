import React from 'react'
import './styles.scss'

interface AlertIconProps {
	width: string
	height: string
	fill?: string
	onClick?: () => void
}

const PraticaIcon = ({ fill = 'white', width, height }: AlertIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 653 140"
			fill={fill}
		>
			<path
				d="M439.671 139.049H414.644C423.583 105.595 432.861 72.1204 441.532 38.6047H466.467L439.671 139.049Z"
				fill={fill}
			/>
			<path
				d="M126.543 56.699C120.988 75.9654 103.306 90.081 82.291 90.2661C66.3369 90.2661 57.7066 90.2661 41.7524 90.2661L28.7196 139.028H0L37.1647 0H105.826C121.06 0 133.404 12.3371 133.404 27.563C133.404 29.9071 133.106 32.1791 132.561 34.3484L126.543 56.6682V56.699ZM70.544 75.1429C84.7906 75.1429 96.8051 65.6537 100.621 52.6484L100.827 51.8773L105.394 34.8625C105.559 34.2559 105.703 33.6596 105.816 33.0736C106.094 32.3231 106.31 31.5417 106.443 30.7398C106.68 29.7837 106.803 28.7864 106.803 27.7584C106.803 20.8188 101.177 15.1849 94.2232 15.1849H93.7809H93.3797C92.8963 15.1849 92.4128 15.2054 91.9397 15.2671C91.343 15.2157 90.7464 15.1849 90.1292 15.1849H61.8314L45.8052 75.1327H70.544V75.1429Z"
				fill={fill}
			/>
			<path
				d="M427.636 50.3454H397.054L373.344 139.049H348.359C356.125 110.005 364.282 79.4506 372.058 50.3968L338.257 50.3557L341.446 38.6252C370.207 38.7486 402.167 38.6252 430.938 38.6252C429.714 43.0357 428.849 45.9349 427.636 50.3454Z"
				fill={fill}
			/>
			<path
				d="M220.026 87.4081L225.508 66.8978C225.961 65.0883 226.187 63.2275 226.187 61.3564C226.187 48.7829 215.993 38.5945 203.413 38.5945C203.31 38.5945 203.207 38.5945 203.094 38.5945H144.318L117.47 139.028C125.823 139.028 134.176 139.028 142.538 139.028C150.294 110.005 158.163 79.3685 166.248 50.3353H191.553H191.79H192.036C197.591 50.3353 202.086 54.828 202.086 60.3694C202.086 61.0274 202.024 61.6648 201.901 62.2919C201.839 62.734 201.736 63.1864 201.613 63.6284C199.782 70.1876 198.147 76.8805 196.316 83.378C194.577 89.5979 188.549 93.8851 182.151 93.8851C181.956 93.8851 181.771 93.8851 182.007 93.8748C178.736 93.8748 175.218 93.8748 171.505 93.8748L158.328 107.045H183.375L187.428 139.028H200.564C203.804 139.018 211.149 138.247 217.31 132.079L298.984 50.3353H302.306L281.734 127.277H253.282L241.37 139.028H303.993L307.532 125.787L310.536 114.539L330.841 38.5842H310.731H294.057L207.836 124.882L205.985 104.022C212.743 100.629 217.804 94.615 219.995 87.3875L220.026 87.4081Z"
				fill={fill}
			/>
			<path
				d="M469.851 65.5818C474.14 49.667 488.572 38.615 505.061 38.615H535.859C535.962 38.615 536.064 38.615 536.178 38.615C548.748 38.615 558.952 48.8034 558.952 61.3769C558.952 63.248 558.725 65.0986 558.232 66.9491L557.265 70.6502H532.485C533.153 67.9772 533.966 65.1808 534.542 62.4975C535.941 56.3804 531.014 50.2633 524.708 50.3558H524.554H524.317L509.978 50.2838C503.302 50.253 497.686 54.8177 496.061 60.9656C491.35 78.7104 486.525 96.4552 481.855 114.19C480.107 120.862 485.095 127.298 491.895 127.288C500.381 127.288 508.867 127.288 517.343 127.288C524.05 127.257 530.129 124.522 534.521 120.122L615.969 38.6047H652.825C643.876 72.0793 634.927 105.564 625.978 139.039C605.137 139.039 584.307 139.039 563.478 139.039L575.225 127.288H603.728L624.301 50.3455H621.02L545.806 125.797C540.745 130.824 530.89 139.039 514.041 139.039H480.395C466.096 139.039 453.208 127.966 458.793 107.055L469.872 65.5715L469.851 65.5818Z"
				fill={fill}
			/>
			<path
				d="M347.484 12.7175H319.886L306.668 25.9593H334.256L347.484 12.7175Z"
				fill={fill}
			/>
		</svg>
	)
}

export default PraticaIcon
