import { IAccessories } from 'module/orderManagement/models/IAccessories'
import { NewAccessories } from 'module/orderManagement/models/OrderAccessoriesModel'
import { AccessoriesTypes } from 'module/orderManagement/utils/constants'
import React, { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import Button from 'shared/components/Button'
import CheckBox from 'shared/components/CheckBox'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import './styles.scss'

interface Props {
	accessories: any[]
	itemSelect?: any
	onChangeAccessories: (accessories: any) => void
	onClose: () => void
}

type FormValues = {
	accessories: IAccessories[]
}

export const ItensDetailsListAccessoriesBodyWebEditingMode = ({
	accessories,
	itemSelect,
	onChangeAccessories,
	onClose
}: Props) => {
	const [accessoriesSelected, setAccessoriesSelected] = useState<any[]>(
		itemSelect?.accessories ? itemSelect?.accessories : []
	)

	const {
		register,
		getValues,
		setValue,
		getFieldState,
		handleSubmit,
		control
	} = useForm<FormValues>()

	const { fields, remove, append, replace } = useFieldArray({
		control,
		name: 'accessories'
	})

	function handleRemoveAccessories(element: any, index: number) {
		const currentIndex = fields.findIndex(
			(accessories) =>
				accessories?.code === element?.code &&
				accessories?.table_price === element.table_price &&
				accessories?.table === element.table &&
				accessories?.unit === element.unit
		)
		const newChecked = [...accessoriesSelected]

		fields.splice(index, 1)
		remove(index)
		newChecked.splice(currentIndex, 1)

		setAccessoriesSelected(newChecked)
	}

	function handleInsertAccessories(element: any) {
		const newChecked = [...accessoriesSelected]

		newChecked.push(element)
		append({
			code: element?.code,
			description: element?.description,
			amount: 0,
			table_price: element?.table_price || 0,
			unit_price: element?.table_price || 0,
			commission: '1',
			item: itemSelect?.id,
			table: element?.table || '',
			unit: element?.unit || '',
			total_price: 0
		})

		setAccessoriesSelected(newChecked)
	}

	useEffect(() => {
		replace(
			itemSelect?.accessories.map((item: IAccessories) => {
				const newAccessories = {
					code: item?.code,
					description: item?.description,
					amount: item?.amount,
					table_price: item?.table_price,
					unit_price: item?.table_price,
					commission: item?.commission,
					item: itemSelect?.id,
					table: item?.table,
					unit: item?.unit,
					total_price: item?.total_price
				}
				return newAccessories
			})
		)
	}, [])

	const onSubmit = (data: any) => {
		const { accessories } = data
		onChangeAccessories(accessories)
	}

	const onChangePriceTotal = (
		item: NewAccessories,
		event: React.ChangeEvent<HTMLInputElement>,
		index: number
	) => {
		if (
			item.amount &&
			event.target.name === `accessories.${index}.unit_price`
		) {
			setValue(
				`accessories.${index}.total_price`,
				Number(event.target.value) * item.amount
			)
		}
		if (
			item.unit_price &&
			event.target.name === `accessories.${index}.amount`
		) {
			setValue(
				`accessories.${index}.total_price`,
				Number(event.target.value) * item.unit_price
			)
		}
	}

	return (
		<>
			<div className="new-accessories-body-list">
				{fields?.map((item: IAccessories, index: number) => {
					return (
						<div key={index} className="new-accessories-body-item">
							<div className="accessories-details-list">
								<ul className="accessories-details-list-item accessories-details-list-code">
									<CheckBox
										id={`selected-checkbox-${index}`}
										variant="blue"
										format="square"
										checked={true}
										onChange={() => handleRemoveAccessories(item, index)}
									/>
									<span>{item?.code}</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-description">
									<span>{item.description}</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-amount">
									<Input
										variant="light"
										name={`accessories.${index}.amount`}
										defaultValue={
											itemSelect?.accessories[index]
												? itemSelect?.accessories[index].amount
												: item.amount
										}
										onChange={(event) => {
											item.amount = Number(event.target.value)
											onChangePriceTotal(item, event, index)
											item.total_price =
												Number(event.target.value) * item.unit_price
										}}
										innerRef={{
											...register(`accessories.${index}.amount`, {
												required: true,
												valueAsNumber: true,
												min: 0.01
											})
										}}
										maxLength={9}
										errors={getFieldState(`accessories.${index}.amount`).error}
									/>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-commission">
									<Select
										id={`commission-${index}`}
										options={AccessoriesTypes}
										selectedOption={[
											itemSelect?.accessories[index]
												? itemSelect?.accessories[index].commission
												: item.commission
										]}
										onSelectOptions={(option) => {
											setValue(`accessories.${index}.commission`, option[0])
											item.commission = option[0]
										}}
										innerRef={register(`accessories.${index}.commission`)}
									/>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-unit-price">
									<Input
										variant="light"
										money={true}
										type="number"
										name={`accessories.${index}.unit_price`}
										defaultValue={item.unit_price}
										onChange={(value) => {
											onChangePriceTotal(item, value, index)
											item.total_price =
												Number(value.target.value) * item.amount
										}}
										innerRef={{
											...register(`accessories.${index}.unit_price`, {
												required: true,
												valueAsNumber: true
											})
										}}
										errors={
											getFieldState(`accessories.${index}.unit_price`).error
										}
									/>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-table-price">
									<span>
										{Number(
											itemSelect?.accessories[index]
												? itemSelect?.accessories[index]?.table_price
												: item.table_price
										).toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
											minimumFractionDigits: 2
										})}
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-total-price">
									<Input
										money={true}
										variant="light"
										disabled={true}
										name={`accessories.${index}.total_price`}
										defaultValue={getValues(`accessories.${index}.total_price`)}
										innerRef={{
											...register(`accessories.${index}.total_price`)
										}}
									/>
								</ul>
							</div>
						</div>
					)
				})}
				{accessories?.map((item: IAccessories, index: number) => {
					return (
						<div
							key={index}
							className="new-accessories-body-item"
							onClick={() => handleInsertAccessories(item)}
						>
							<div className="accessories-details-list">
								<ul className="accessories-details-list-item accessories-details-list-code">
									<CheckBox variant="blue" format="square" checked={false} />
									<span>{item?.code}</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-description">
									<span>{item.description}</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-amount" />
								<ul className="accessories-details-list-item accessories-details-list-commission" />
								<ul className="accessories-details-list-item accessories-details-list-unit-price" />
								<ul className="accessories-details-list-item accessories-details-list-table-price">
									<span>
										{Number(
											itemSelect?.accessories[index]
												? itemSelect?.accessories[index]?.table_price
												: item.table_price
										).toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
											minimumFractionDigits: 2
										})}
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-total-price" />
							</div>
						</div>
					)
				})}
			</div>
			<div className="new-accessories-body-action">
				<Button
					size="lg"
					title="Confirmar"
					variant="confirmation-gradient"
					onClick={handleSubmit(onSubmit)}
				/>
				<Button
					size="lg"
					title="Cancelar"
					onClick={onClose}
					variant="cancellation-gradient"
				/>
			</div>
		</>
	)
}
