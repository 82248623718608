import React from 'react'
import { history } from 'routes/history'
import { ProductsCardIcon } from './ProductsCardIcon'
import './styles.scss'

export const ProductsCard = () => {
	return (
		<div className="feature-card" onClick={() => history.push('/products')}>
			<div className="products-card-title">
				<h4>PRODUTOS</h4>
				<div className="products-config-icon">
					<ProductsCardIcon />
				</div>
			</div>
		</div>
	)
}
