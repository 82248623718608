import AddIcon from 'assets/image/addIcon.svg'
import React from 'react'
import './styles.scss'

type Props = {
	showModal: (boolean: boolean) => void
}

export const OrderItemsSectionAddItem = ({ showModal }: Props) => (
	<div className="items-information-contain">
		<div className="items-information-contain-information">
			<div className="new-order-select-item-action">
				<img
					src={AddIcon}
					alt="Add item icon"
					id="addItemIcon"
					onClick={() => showModal(true)}
				/>
				<label htmlFor="addItemIcon" onClick={() => showModal(true)}>
					Adicionar item
				</label>
			</div>
		</div>
	</div>
)
