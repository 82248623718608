import { Order } from '../domain/models/order'

export const sumOrderTaxes = (order?: Order) => {
	let difal = 0
	let icm = 0
	let pis = 0
	let confins = 0
	let ipi = 0
	let suframa = 0

	order?.items.forEach((item: any) => {
		if (item.taxes.difal) {
			difal = difal + item.taxes.difal.value
		}
		if (item.taxes.icm) {
			icm = icm + item.taxes.icm.value
		}
		if (item.taxes.pis) {
			pis = pis + item.taxes.pis.value
		}
		if (item.taxes.confins) {
			confins = confins + item.taxes.confins.value
		}
		if (item.taxes.ipi) {
			ipi = ipi + item.taxes.ipi.value
		}
		if (item.suframa_value) {
			suframa = suframa + item.suframa_value
		}
	})
	return { difal, icm, pis, confins, ipi, suframa }
}
