import { BilletsModal } from 'module/orderManagement/components/BilletsModal'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import React, { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import Divider from 'shared/components/Divider'
import Section from 'shared/components/Section'

export function FinancePositionDetails() {
	const {
		sa1010,
		financePosition,
		groupFinancePosition,
		financePositionLoading,
		groupFinancePositionLoading
	} = useContext(ClientDetailsContextElement)
	const [showBilletsModal, setShowBilletsModal] = useState(false)

	const handleTransformDateProtheus = (date?: string) => {
		if (!date) return '-'
		return new Date(
			Number(date.substring(0, 4)),
			Number(date.substring(4, 6)) - 1,
			Number(date.substring(6, 8))
		).toLocaleDateString('pt-BR')
	}

	return (
		<div className="client-finance-details-tab">
			<Section title="Cliente">
				<div className="client-finance-position-section">
					<div className="finance-position-section-contain-information">
						<header>Limite de crédito</header>
						<span>
							{sa1010?.a1Lc?.toLocaleString('pt-br', {
								style: 'currency',
								currency: 'BRL'
							})}
						</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Venc. lim. Crédito</header>
						<span>{handleTransformDateProtheus(sa1010?.a1Venclc)}</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Saldo</header>
						{financePositionLoading ? (
							<Skeleton />
						) : (
							<span>
								{financePosition
									? (
											(sa1010?.a1Lc ?? 0) -
											(financePosition.saldoDuplicata ?? 0) +
											(financePosition.raNcc ?? 0)
									  ).toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL'
									  })
									: 'N/A'}
							</span>
						)}
					</div>
					<div className="finance-position-section-contain-information">
						<header>Crédito comprometido</header>
						{financePositionLoading ? (
							<Skeleton />
						) : (
							<span>
								{financePosition
									? (financePosition.creditoComprometido ?? 0).toLocaleString(
											'pt-br',
											{
												style: 'currency',
												currency: 'BRL'
											}
									  )
									: 'N/A'}
							</span>
						)}
					</div>
					<div className="finance-position-section-contain-information">
						<header>Valor faturado</header>
						{financePositionLoading ? (
							<Skeleton />
						) : (
							<span>
								{financePosition
									? (financePosition.valorFaturado ?? 0).toLocaleString(
											'pt-br',
											{
												style: 'currency',
												currency: 'BRL'
											}
									  )
									: 'N/A'}
							</span>
						)}
					</div>
					<div className="finance-position-section-contain-information">
						<header>Valor devolvido</header>
						{financePositionLoading ? (
							<Skeleton />
						) : (
							<span>
								{financePosition
									? (financePosition.valorDevolvido ?? 0).toLocaleString(
											'pt-br',
											{
												style: 'currency',
												currency: 'BRL'
											}
									  )
									: 'N/A'}
							</span>
						)}
					</div>
					<div className="finance-position-section-contain-information">
						<header>Primeira compra</header>
						<span>{handleTransformDateProtheus(sa1010?.a1Pricom)}</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Última compra</header>
						<span>{handleTransformDateProtheus(sa1010?.a1Ultcom)}</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Maior atraso (dias)</header>
						<span>{sa1010?.a1Matr ?? 0}</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Média de atraso (dias)</header>
						<span>{sa1010?.a1Metr ? (sa1010?.a1Metr * -1).toFixed(0) : 0}</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Maior compra</header>
						<span>
							{sa1010?.a1Mcompra?.toLocaleString('pt-br', {
								style: 'currency',
								currency: 'BRL'
							}) ?? '-'}
						</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Maior saldo</header>
						<span>
							{sa1010?.a1Msaldo?.toLocaleString('pt-br', {
								style: 'currency',
								currency: 'BRL'
							}) ?? '-'}
						</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Grau de risco</header>
						<span>{sa1010?.a1Risco ?? '-'}</span>
					</div>
					<div className="finance-position-section-contain-information">
						<header>Observações</header>
						<span title={sa1010?.a1Obscli ?? ''}>
							{sa1010?.a1Obscli ?? '-'}
						</span>
					</div>
				</div>
				<div>
					<Divider title="Títulos" />
					<div className="client-finance-position-section">
						<div className="finance-position-section-contain-information">
							<header>Vencidos</header>
							{financePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{financePosition
										? (financePosition.saldoDupVencido ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>A vencer</header>
							{financePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{financePosition
										? (financePosition.saldoDupAVencer ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>Títulos</header>
							{financePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{financePosition
										? (financePosition.saldoDuplicata ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>RA/NCC</header>
							{financePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{financePosition
										? (financePosition.raNcc ?? 0).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL'
										  })
										: 'N/A'}
								</span>
							)}
						</div>
						{!!sa1010?.se1010?.length && (
							<div className="finance-position-section-contain-information">
								<div className="billet-action">
									<button onClick={() => setShowBilletsModal(true)}>
										Boletos
									</button>
								</div>
							</div>
						)}
					</div>
				</div>
			</Section>
			{!!sa1010?.acy010?.acyGrpven && (
				<Section title="Grupo Econômico">
					<div className="client-finance-position-section">
						<div className="finance-position-section-contain-information">
							<header>Grupo econômico</header>
							<span>
								{sa1010?.acy010
									? `${sa1010?.acy010?.acyGrpven} - ${sa1010?.acy010?.acyDescri}`
									: '-'}
							</span>
						</div>
						<div className="finance-position-section-contain-information">
							<header>Limite de crédito</header>
							<span>
								{(sa1010?.acy010?.acyLc ?? 0).toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL'
								})}
							</span>
						</div>
						<div className="finance-position-section-contain-information">
							<header>Venc. lim. Crédito</header>
							<span>
								{handleTransformDateProtheus(sa1010?.acy010?.acyVenclc)}
							</span>
						</div>
						<div className="finance-position-section-contain-information">
							<header>Saldo grupo</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (groupFinancePosition.saldoGrupo ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>Crédito comprometido</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (
												groupFinancePosition.creditoComprometido ?? 0
										  ).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL'
										  })
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>Valor faturado</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (groupFinancePosition.valorFaturado ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>Valor devolvido</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (groupFinancePosition.valorDevolvido ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
					</div>
					<Divider title="Títulos" />
					<div className="client-finance-position-section">
						<div className="finance-position-section-contain-information">
							<header>Vencidos</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (
												groupFinancePosition.saldoDupVencido ?? 0
										  ).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL'
										  })
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>A vencer</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (
												groupFinancePosition.saldoDupAVencer ?? 0
										  ).toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL'
										  })
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>Títulos</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (groupFinancePosition.saldoDuplicata ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
						<div className="finance-position-section-contain-information">
							<header>RA/NCC</header>
							{groupFinancePositionLoading ? (
								<Skeleton />
							) : (
								<span>
									{groupFinancePosition
										? (groupFinancePosition.raNcc ?? 0).toLocaleString(
												'pt-br',
												{
													style: 'currency',
													currency: 'BRL'
												}
										  )
										: 'N/A'}
								</span>
							)}
						</div>
					</div>
				</Section>
			)}
			{!!sa1010 && showBilletsModal && (
				<BilletsModal
					sa1010={sa1010}
					onClose={() => setShowBilletsModal(false)}
				/>
			)}
		</div>
	)
}
