/* eslint-disable prettier/prettier */
import DateFnsUtils from '@date-io/date-fns'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import brLocale from 'date-fns/locale/pt-BR'
import React from 'react'
import Input from 'shared/components/Input'

interface OrderClientInstallmentProps {
	parcel: any
	index: number
}

export const OrderClientInstallmentViewingMode = ({
	parcel,
	index,
}: OrderClientInstallmentProps) => {

	return (
		<div className="installments-details-item">
			<h5>{`Parcela ${index + 1}`}</h5>
			<div className="installments-details-item-values">
				<div className="value-container">
					<span>Valor</span>
					<Input
						placeholder=" 0,00"
						name={`value-${index + 1}`}
						variant="light"
						disabled
						defaultValue={parcel?.value ? parcel.value : '0,00'}
						
						maxLength={12}
						money={true}
					/>
				</div>
				<div className="date-container">
					<span>Vencimento</span>
					<MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
						<KeyboardDatePicker
							disabled
							cancelLabel="CANCELAR"
							clearLabel="LIMPAR"
							okLabel="OK"
							emptyLabel="DD/MM/AAAA"
							format="dd/MM/yyyy"
							id="date-initial-picker"
							invalidDateMessage="Data invalida"
							value={parcel?.date || null}
							onChange={(value: any) => {}}
							onError={() => {}}
							KeyboardButtonProps={{
								'aria-label': 'change date',
								className: 'period-filter-keyboard-button'
							}}
						/>
					</MuiPickersUtilsProvider>
				</div>
			</div>
		</div>
	)
}
