import styled from 'styled-components'

export const Container = styled.div`
	padding: 20px 20px;
	background: #fff;
	min-width: 250px;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	border-radius: 6px;
	h1 {
		padding-bottom: 0px;
		margin-bottom: 0px;
	}
`

export const Msg = styled.div`
	text-align: center;
	font-size: 14px;
	font-weight: 500;
`

export const Footer = styled.div`
	margin-top: 20px;
`
