import Tooltip from '@material-ui/core/Tooltip'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { Items } from 'module/orderManagement/domain/models/order'
import React, { useContext, useLayoutEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { RouteComponentProps } from 'react-router-dom'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
	billed: boolean
}

const AllBalanceStorageListBodyMobile: React.FC<TParams> = ({
	match,
	billed
}) => {
	const { order, onOrderChange, orderIsLoading } = useContext(
		OrderDetailsContextElement
	)
	const { orderCode, branch } = match.params

	useLayoutEffect(() => {
		if (order?.code.trim() !== orderCode.trim()) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	const hasBilled = () => {
		const billedOrderItemsList = order?.items.filter(
			(item) => item.invoice.number !== ''
		)
		const toProfitOrderItemsList = order?.items.filter(
			(item) => item.invoice.number === ''
		)
		const orderItemsList = billed
			? billedOrderItemsList
			: toProfitOrderItemsList
		return orderItemsList?.length ? (
			<div className="all-balance-storage-list-body">
				{listContent(orderItemsList)}
			</div>
		) : (
			<div className="all-balance-storage-list-body">
				<a className="all-balance-storage-list" id="emptyList" key={0}>
					<ul className="all-balance-storage-list-item all-balance-storage-list-empty-list">
						Nenhum item encontrado
					</ul>
				</a>
			</div>
		)
	}

	const listContent = (orderItemsList: Items[]) => {
		return orderItemsList.map((item: Items, index: number) => {
			return (
				<a
					className="device-mobile all-balance-storage-list-mobile"
					id={item?.item}
					key={index}
				>
					<div>
						<ul className="all-balance-storage-list-item all-balance-storage-list-product">
							<span>Produto:</span> {item?.code}
						</ul>
						<ul className="all-balance-storage-list-item all-balance-storage-list-amount">
							<span>Quantidade:</span>
							{item.amount}
						</ul>
					</div>
					<Tooltip
						title={`${item?.description}`}
						interactive
						arrow={true}
						placement={'right-start'}
					>
						<ul className="all-balance-storage-list-item all-balance-storage-list-description">
							<span>Descrição:</span>
							{item?.description}
						</ul>
					</Tooltip>
					{!billed && <div className="storage-list"></div>}
					{!!billed && (
						<ul className="all-balance-storage-list-item all-balance-storage-list-origin">
							<span>Nota fiscal:</span>
							{item?.invoice.number}
						</ul>
					)}
				</a>
			)
		})
	}

	return <>{orderIsLoading ? <Skeleton height={32} /> : hasBilled()}</>
}
export default AllBalanceStorageListBodyMobile
