import TransfersContext from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import Transfers from 'module/technicalServices/pages/Transfers'
import React from 'react'

const TransferContextInvolve: React.FC = () => {
	return (
		<TransfersContext>
			<Transfers />
		</TransfersContext>
	)
}

export default TransferContextInvolve
