import TextField from '@material-ui/core/TextField'
import ServiceOrdersResponse from 'models/ServiceOrderResponse'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { getDeleteOS } from 'services'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import { KeyedMutator } from 'swr'
import {
	ClientName,
	ClientNameTitle,
	Description,
	Footer,
	FormError,
	Header,
	Info,
	Line,
	SerialNumber,
	Title
} from './styles'
import './styles.scss'

interface Props {
	mutate: KeyedMutator<any>
	show: boolean
	serviceOrder: ServiceOrdersResponse
	onClose: () => void
}

const DeleteOS: React.FC<Props> = ({ mutate, show, serviceOrder, onClose }) => {
	const [obsErrorMessage, setObsErrorMessage] = useState<boolean>(false)

	function DelOS() {
		const obs = $('#obsdelete').val()
		if (obs) {
			const { profile } = store.getState().user

			const json = {
				os_code: serviceOrder.service_order,
				order_id: '#id#',
				user_id: profile,
				origin: 'admin',
				obs
			}

			const resp = getDeleteOS(json)
			resp
				.then((resp) => {
					const { data } = resp

					if (data.statusCode == '200') {
						toast.success('Ordem de serviço excluída com sucesso.')
						mutate()
						onClose()
					}
				})
				.catch(() => {
					toast.error('Erro ao excluir ordem de serviço.')
				})

			$('#obsdelete').val('')
		} else {
			setObsErrorMessage(true)
		}
	}

	const onObsChange = () => {
		if ($('#obsdelete').val()) {
			setObsErrorMessage(false)
		} else {
			setObsErrorMessage(true)
		}
	}

	return (
		<Modal
			title="Excluir atendimento"
			show={show}
			showClose={true}
			onClose={onClose}
		>
			<Header>
				<div>Protocolo: {serviceOrder.service_order}</div>
				<Title className="align-header">{serviceOrder.equipment.name}</Title>

				<SerialNumber>
					<Description>Número de série:</Description>
					<Info>{serviceOrder.equipment.serial_number}</Info>
				</SerialNumber>
				<Line>
					<ClientNameTitle>Cliente:</ClientNameTitle>
					<ClientName>{serviceOrder.client.nome_fantasia}</ClientName>
				</Line>
			</Header>

			<div className="delete-os-warning">
				Ao excluir este atendimento, tenha em mente que o mesmo também será
				excluído no aplicativo do cliente, veja a necessidade de reporta-lo
				antes de seguir.
			</div>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<TextField
					id="obsdelete"
					label="Motivo da Exclusão"
					style={{ width: '650px' }}
					fullWidth={true}
					margin="normal"
					multiline={true}
					InputLabelProps={{
						shrink: true
					}}
					variant="outlined"
					onChange={onObsChange}
				/>

				{obsErrorMessage && (
					<FormError>Motivo da Exclusão é obrigatório!</FormError>
				)}
			</div>

			<Footer>
				<Button
					style={{ width: '126px' }}
					size="lg"
					title="Confirmar"
					variant="confirmation-gradient"
					onClick={() => {
						DelOS()
					}}
				/>
				<Button
					style={{ width: '126px' }}
					size="lg"
					title="Cancelar"
					variant="cancellation-gradient"
					onClick={onClose}
				/>
			</Footer>
		</Modal>
	)
}

export default DeleteOS
