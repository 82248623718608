import { TextField } from '@material-ui/core'
import React from 'react'
import { MdDeleteOutline, MdDone } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
	getAverageCost,
	getPrice,
	getProdDescri,
	getWarehouseBalance
} from 'services'
import {
	checkNumber,
	createData,
	formValidator,
	handleOptionTO
} from './functions'
import {
	Col1,
	Col2,
	Col3,
	Col4,
	Col5,
	Col6,
	Col7,
	Col8,
	Col9,
	Div,
	Div_Info,
	ItemDescri,
	ItensAdded,
	ItensLine,
	ItensTable,
	Total
} from './styles'
import './styles.css'

interface Props {
	type: string
}
interface state {
	codigo: string
	descri: string
	quant: string
	price: string
	armaz: string
	desc: string
	total: string
	enableAddItens: boolean
	totItens: string
	totFrete: string
	frete: string
}

let rows: any[]
let typeoper = ''
let armazem = ''
let OptArmazem = [{ value: '', label: '' }]

class Itens extends React.Component<Props, state> {
	constructor(props: Props) {
		super(props)

		this.state = {
			codigo: '',
			descri: '',
			quant: '0',
			price: '0.00',
			armaz: '',
			desc: '0',
			total: '0.00',
			enableAddItens: false,
			totItens: '0.00',
			frete: '0.00',
			totFrete: '0.00'
		}
	}

	StateInicial = async () => {
		this.setState({ codigo: '' })
		this.setState({ descri: '' })
		this.setState({ quant: '0' })
		this.setState({ price: '0.00' })
		$('#armazem').val('')
		this.setState({ desc: '0' })
		this.setState({ total: '0.00' })
		$('#typeoper').val('')

		OptArmazem = [{ value: '', label: '' }]
	}

	addItem = async () => {
		if (formValidator()) {
			const nTotal =
				parseFloat(this.state.total) + parseFloat(this.state.totItens)

			if (rows?.length >= 1) {
				rows.push(
					createData(
						this.state.codigo,
						this.state.descri,
						this.state.quant,
						this.state.price,
						$('#armazem').val(),
						$('#typeoper').val(),
						this.state.desc,
						this.state.total
					)
				)
			} else {
				rows = [
					createData(
						this.state.codigo,
						this.state.descri,
						this.state.quant,
						this.state.price,
						$('#armazem').val(),
						$('#typeoper').val(),
						this.state.desc,
						this.state.total
					)
				]
			}

			this.StateInicial()

			this.setState({ enableAddItens: false })

			this.setState({ totItens: nTotal.toFixed(2).toString() })
			this.setState({
				totFrete: (nTotal + parseFloat(this.state.frete)).toFixed(2)
			})
		}
	}

	removeItem = async (element: any) => {
		const index = rows.indexOf(element)

		const nTotal = parseFloat(this.state.totItens) - parseFloat(rows[index].tot)
		this.setState({ totItens: nTotal.toString() })

		this.setState({
			totFrete: (nTotal + parseFloat(this.state.frete)).toFixed(2).toString()
		})

		//$('#cod1' ).attr('id', 'xxx');
		rows.splice(index, 1)
		this.setState({ total: '' })
	}

	handleCode = async () => {
		const resp = getProdDescri(this.state.codigo)
		resp
			.then((resp) => {
				const { b1Desc } = resp.data[0]
				if (resp.status === 200 && b1Desc?.trim() !== '') {
					this.setState({ descri: b1Desc?.substring(0, 20) })
				}
			})
			.catch((error) => {
				toast.error('Produto não localizado.')

				$(`.codigo`).find('input').focus()
			})

		const tab = $('#tabprice').val()?.toString().substring(0, 3)

		if (tab !== '') {
			const resp2 = getPrice(tab, this.state.codigo)
			resp2
				.then((resp2) => {
					const { data } = resp2

					if (resp2?.status === 200) {
						if (data[0]?.da1Prcven > 0) {
							this.setState({ price: data[0].da1Prcven })
						}
					}
				})
				.catch(() => {
					toast.error('Produto não encontrado na tabela de preço.')
					this.setState({ price: '0.00' })
					this.setState({ total: '0.00' })
				})
		}

		OptArmazem = [{ value: '', label: '' }]
		if (this.state.codigo) {
			getWarehouseBalance($('#filial').val(), this.state.codigo).then(
				(resp2) => {
					const { data } = resp2

					for (let i = 0; i < data?.length; i++) {
						OptArmazem.push({
							value: data[i].B2_LOCAL,
							label: data[i].B2_LOCAL + ' - Saldo: ' + data[i].B2_QATU
						})
					}
					if ($('#filial').val() === '01') {
						if (!OptArmazem.find((item) => item.value === '09'))
							OptArmazem.push({ value: '09', label: '09 - Saldo: 0' })
					}
					if ($('#filial').val() === '02' || $('#filial').val() === '06') {
						if (!OptArmazem.find((item) => item.value === '01'))
							OptArmazem.push({ value: '01', label: '01 - Saldo: 0' })
					}
				}
			)
		}
	}

	handleQuant = async () => {
		if (checkNumber(this.state.quant)) {
			const total = (
				parseFloat(this.state.price) * parseFloat(this.state.quant)
			)
				.toFixed(2)
				.toString()
				.trim()

			if (this.state.price !== '') {
				if (this.state.desc !== '') {
					this.handleDesc()
				} else {
					this.setState({ total: total })
				}
			}
		} else {
			this.setState({ quant: '0' })
			this.setState({ total: '0.00' })
		}
	}

	handlePrice = async () => {
		if (checkNumber(this.state.price.toString())) {
			const total = (
				parseFloat(this.state.price) * parseFloat(this.state.quant)
			)
				.toFixed(2)
				.toString()
				.trim()

			if (this.state.price !== '') {
				if (this.state.desc !== '') {
					this.handleDesc()
				} else {
					this.setState({ total: total })
				}
			}
		} else {
			this.setState({ price: '0.00' })
			this.setState({ total: '0.00' })
		}
	}

	handleDesc = async () => {
		if (checkNumber(this.state.desc)) {
			const total = parseFloat(this.state.price) * parseFloat(this.state.quant)

			const total2 = (total - total * (parseFloat(this.state.desc) / 100))
				.toFixed(2)
				.toString()
				.trim()

			this.setState({ total: total2 })
		} else {
			this.setState({ desc: '0' })
			$(`.desc`).find('input').focus()
		}
	}

	handleFrete = async (e: any) => {
		if (checkNumber(this.state.frete)) {
			let frete = ''

			if (this.state.frete?.trim() === '') {
				frete = '0.00'
			} else {
				frete = this.state.frete
			}

			const total = (parseFloat(this.state.totItens) + parseFloat(frete))
				.toFixed(2)
				.toString()

			this.setState({ totFrete: total })
		} else {
			$(`.frete`).find('input').focus()
		}
	}

	RemoveItem = async () => {
		this.StateInicial()
		this.setState({ enableAddItens: false })
	}

	handleArmazem = async () => {
		const local = $('#armazem').val()
		const tpPart = $('#tpPart').val()
		if (
			(tpPart == 'PED' || tpPart == 'REP') &&
			this.state.codigo.trim() !== '' &&
			local !== ''
		) {
			const local = $('#armazem').val()

			const resp = getAverageCost($('#filial').val(), this.state.codigo, local)
			resp.then((resp) => {
				const { data } = resp
				this.setState({ price: data[0]?.B2_CM1 ? data[0].B2_CM1 : 0 })

				if (checkNumber(this.state.quant.toString())) {
					const total = (
						parseFloat(this.state.price) * parseFloat(this.state.quant)
					)
						.toFixed(2)
						.toString()
						.trim()

					if (this.state.quant !== '') {
						this.setState({ total: total })
					}
				}
			})
		}
	}

	render() {
		return (
			<>
				<Div_Info>
					<ItensLine>
						<Div
							id="newitem"
							className="div-block-26_ button-2_ w-button_"
							onClick={() => {
								this.setState({ enableAddItens: true })
							}}
						>
							<Div>Adicionar item +</Div>
						</Div>
						<ItemDescri>Itens:</ItemDescri>
					</ItensLine>

					<ItensTable>
						<Col1>Código</Col1>
						<Col2>Qnt.</Col2>
						<Col3>Armaz.</Col3>
						<Col4>TO</Col4>
						<Col5>Descrição</Col5>
						<Col6>Desc</Col6>
						<Col7>Preço</Col7>
						<Col8>Total</Col8>
						<Col9></Col9>
					</ItensTable>

					{rows?.map((row, index) => (
						<ItensAdded key={index}>
							<Col1>
								<div id={'cod' + rows.indexOf(row)}>{row.cod}</div>
							</Col1>
							<Col2>
								<div id={'quant' + rows.indexOf(row)}>{row.qtd.trim()}</div>
							</Col2>
							<Col3>
								<div id={'armaz' + rows.indexOf(row)}>{row.armaz.trim()}</div>
							</Col3>
							<Col4>
								<div id={'tpoper' + rows.indexOf(row)}>{row.tpoper.trim()}</div>
							</Col4>
							<Col5>
								<div id={'descri' + rows.indexOf(row)}>
									{' '}
									{row.descri?.substring(0, 20)}
								</div>
							</Col5>
							<Col6>
								<div id={'desc' + rows.indexOf(row)}>
									{row.desc.trim() + '%'}
								</div>
							</Col6>
							<Col7>
								<div id={'price' + rows.indexOf(row)}>{'R$' + row.price}</div>
							</Col7>
							<Col8>
								<div id={'tot' + rows.indexOf(row)}>{'R$' + row.tot}</div>
							</Col8>
							<Col9>
								<MdDeleteOutline
									color="grey"
									onClick={() => this.removeItem(row)}
								/>
							</Col9>
						</ItensAdded>
					))}

					{this.state.enableAddItens && (
						<ItensTable>
							<Col1>
								<TextField
									id="codigo"
									className={`codigo`}
									name="codigo"
									value={this.state.codigo}
									size="small"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
									onChange={(e) => {
										this.setState({ codigo: e.target.value })
									}}
									onBlur={this.handleCode}
								/>
							</Col1>
							<Col2>
								<TextField
									id="quant"
									className={`quant`}
									name="quant"
									value={this.state.quant}
									size="small"
									variant="outlined"
									style={{ width: '100%' }}
									type="number"
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
									onChange={(e) => {
										this.setState({ quant: e.target.value })
									}}
									onBlur={this.handleQuant}
								/>
							</Col2>
							<Col3>
								<select
									id="armazem"
									name="armazem"
									className="w-select-tpoper_"
									onChange={this.handleArmazem}
								>
									{OptArmazem.map((item) => (
										<option key={item.value} value={item.value}>
											{(armazem = item.label)}
										</option>
									))}
								</select>
							</Col3>
							<Col4>
								<select
									id="typeoper"
									name="typeoper"
									className="w-select-tpoper_"
								>
									{handleOptionTO(this.props.type).map((item) => (
										<option key={item.value} value={item.value}>
											{(typeoper = item.label)}
										</option>
									))}
								</select>
							</Col4>
							<Col5>
								{' '}
								<TextField
									id="descri"
									className={`descri`}
									value={this.state.descri}
									name="descri"
									size="small"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
								/>
							</Col5>
							<Col6>
								{' '}
								<TextField
									id="desc"
									className={`desc`}
									value={this.state.desc}
									name="desc"
									size="small"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
									onChange={(e) => {
										this.setState({ desc: e.target.value })
									}}
									onBlur={this.handleDesc}
								/>
							</Col6>
							<Col7>
								<TextField
									id="price"
									className={`price`}
									value={this.state.price}
									name="price"
									size="small"
									style={{ width: '100%' }}
									variant="outlined"
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
									onChange={(e) => {
										this.setState({ price: e.target.value })
									}}
									onBlur={this.handlePrice}
								/>
							</Col7>
							<Col8>
								{' '}
								<TextField
									id="total"
									className={`total`}
									value={this.state.total}
									name="total"
									size="small"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
								/>
							</Col8>
							<Col9>
								<MdDone
									style={{ color: 'green' }}
									onClick={() => {
										this.addItem()
									}}
								/>
							</Col9>
						</ItensTable>
					)}

					<Total>
						<ItensTable>
							<Col1></Col1>
							<Col2></Col2>
							<Col3></Col3>
							<Col4></Col4>
							<Col5></Col5>
							<Col6></Col6>
							<Col7>FRETE</Col7>
							<Col8>
								<TextField
									id="frete"
									className={`frete`}
									name="frete"
									type="number"
									size="small"
									variant="outlined"
									style={{ width: '100%' }}
									InputProps={{
										style: { fontSize: 12, backgroundColor: 'white' }
									}}
									onChange={(e) => {
										this.setState({ frete: e.target.value })
									}}
									onBlur={this.handleFrete}
								/>
							</Col8>
							<Col9></Col9>
						</ItensTable>

						<ItensTable>
							<Col1></Col1>
							<Col2></Col2>
							<Col3></Col3>
							<Col4></Col4>
							<Col5></Col5>
							<Col6></Col6>
							<Col7>TOTAL</Col7>
							<Col8 id="totfrete">{'R$' + this.state.totFrete}</Col8>
							<Col9></Col9>
						</ItensTable>
					</Total>
				</Div_Info>
			</>
		)
	}
}
export default Itens
