import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import OrderHistoryListBodyMobile from './Mobile'
import OrderHistoryListBodyWeb from './Web'

const OrderHistoryListBody: React.FC = () => {
	const { viewType } = useContext(DeviceContextElement)
	return viewType !== ViewType.MOBILE ? (
		<OrderHistoryListBodyWeb />
	) : (
		<OrderHistoryListBodyMobile />
	)
}

export default OrderHistoryListBody
