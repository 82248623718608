import { PrpOrderLogs } from 'module/orderManagement/models/PrpOrderModel'
import React, { useState } from 'react'
import { ModalCompare } from '../ModalCompare'

type Props = {
	logs: PrpOrderLogs[]
}

export const OrderLogsList = ({ logs }: Props) => {
	const [compare, setCompare] = useState<any[]>([])

	const handleCompareLogs = (event: any) => {
		const index = logs?.findIndex((log) => log.id === event?.id)

		if (index === -1) return

		setCompare([logs[index]])

		const latestLog = logs.filter(
			(log) => log.createdAt < logs[index].createdAt
		)

		if (logs[index]?.oldPayload) {
			setCompare((e) => [
				...e,
				{ ...logs[index], payload: logs[index].oldPayload }
			])
		} else {
			setCompare((e) => [...e, ...latestLog])
		}
	}

	return (
		<>
			{logs
				?.filter((log) => log.status)
				?.map((log, index) => (
					<div
						key={index}
						className="event"
						onClick={() => handleCompareLogs(log)}
					>
						<div className="map">
							<div className="line" />
							<div className="ball" />
						</div>
						<div className="info">
							<span className="event-date">
								{new Date(log?.createdAt)?.toLocaleString()}
							</span>
							<span className="event-title">Pedido Alterado</span>
							<span className="event-user">
								{`Usuário da Alteração: ${log?.user?.name}`}
							</span>
						</div>
					</div>
				))}
			<ModalCompare compare={compare} onClose={() => setCompare([])} />
		</>
	)
}
