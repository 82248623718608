import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import { ItensDetailsListAccessoriesBodyMobileViewingMode } from './Mobile/ItensDetailsListAccessoriesBodyMobileViewingMode'
import { ItensDetailsListAccessoriesBodyWebViewingMode } from './Web/ItensDetailsListAccessoriesBodyWebViewingMode'

interface Props {
	accessoriesList: any[]
}

export const ItensDetailsListAccessoriesBodyViewingMode = ({
	accessoriesList
}: Props) => {
	const { viewType } = useContext(DeviceContextElement)
	return (
		<>
			{viewType !== ViewType.MOBILE ? (
				<ItensDetailsListAccessoriesBodyWebViewingMode
					{...{ accessoriesList }}
				/>
			) : (
				<ItensDetailsListAccessoriesBodyMobileViewingMode
					{...{ accessoriesList }}
				/>
			)}
		</>
	)
}
