import { Notification } from 'models/Notification'
import moment from 'moment'
import React from 'react'
import { MdClose } from 'react-icons/md'
import Skeleton from 'react-loading-skeleton'
import { history } from 'routes/history'
import './styles.css'
interface Props {
	notifications: Notification[]
	disableUpdateAll: boolean
	onClose: () => void
	changeStatus: (notifyId: number, isReading?: boolean) => void
	updateAll: () => void
}

const NotificationsModal: React.FC<Props> = ({
	notifications,
	disableUpdateAll,
	onClose,
	changeStatus,
	updateAll
}) => {
	const changeTitle = (title: string, readAt?: Date) => {
		if (readAt) {
			return <span>{title}</span>
		} else {
			return (
				<span>
					<b>{title}</b>
				</span>
			)
		}
	}

	const updateAllNotifications = () => {
		if (!disableUpdateAll) {
			updateAll()
		}
	}

	const handleUpdateAllAction = () => {
		const disableOption = disableUpdateAll ? 'disabled' : ''
		return (
			<div
				className={`notify-center-option ${disableOption}`}
				onClick={() => {
					updateAllNotifications()
				}}
			>
				<span>Marcar todas como lidas</span>
			</div>
		)
	}

	const handleNotificationClick = (notification: Notification) => {
		changeStatus(notification.id, true)
		if (notification.osNumber) {
			history.push(`/os/${notification.osNumber}`)
		} else {
			history.push(`/transfers`)
		}
		onClose()
	}

	const handleNotificationCenterClick = () => {
		history.push(`/notifications`)
		onClose()
	}

	const changeAction = (notifyId: number, readAt?: Date) => {
		const actionName = !readAt ? 'Marcar como lida' : 'Marcar como não lida'
		return (
			<>
				<span
					className="mark-as"
					onClick={() => {
						changeStatus(notifyId)
					}}
				>
					{actionName}
				</span>
			</>
		)
	}

	const handleCancelNotification = (notify: any) => {
		const isRequestCancelNotify =
			notify.title === 'Nova solicitação de cancelamento de OS registrada!'
		return isRequestCancelNotify && !notify.readAt ? 'requestCancelNotify' : ''
	}

	const notificationList = () => {
		return notifications ? (
			<>
				{notifications.map((notify, index) => (
					<div
						className={`notification-item  ${handleCancelNotification(notify)}`}
						key={index}
					>
						<div
							className="notification-item-title"
							onClick={() => {
								handleNotificationClick(notify)
							}}
						>
							{changeTitle(notify.title, notify.readAt)}
							<small>{notify.osNumber}</small>
						</div>
						<div className="notification-item-options">
							{changeAction(notify.id, notify.readAt)}
							<span className="notify-time">
								{parseDate(new Date(notify.createdAt))}
							</span>
						</div>
					</div>
				))}
			</>
		) : (
			<div className={`notification-item`}>
				<div className="notification-item-title">
					<Skeleton width={230} />
					<small>
						<Skeleton width={90} />
					</small>
				</div>
				<div className="notification-item-options">
					<Skeleton width={150} />
					<span className="notify-time">
						<Skeleton width={60} />
					</span>
				</div>
			</div>
		)
	}

	const parseDate = (value: Date): string => {
		let mills = value.getTime()
		const zoneTime = value.getTimezoneOffset()
		if (zoneTime) {
			mills = mills - zoneTime * 60000
			const date = new Date(mills)
			return moment(date).format('HH:mm - DD/MM/YYYY')
		}

		return moment(value).format('HH:mm - DD/MM/YYYY')
	}

	return (
		<>
			<div
				id="joyride-notifysettings"
				className="notificationsContent"
				onClick={(e: any) => {
					e.stopPropagation()
				}}
			>
				<div className="notify-center-header">
					<span>Notificações</span>
					<div className="notify-center-close">
						<MdClose onClick={onClose}></MdClose>
					</div>
				</div>
				{handleUpdateAllAction()}
				<div className="notify-center-content">{notificationList()}</div>
				<div
					className="notify-center-footer"
					onClick={handleNotificationCenterClick}
				>
					<span>Abrir central de Notificações</span>
				</div>
			</div>
		</>
	)
}

export default NotificationsModal
