import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import { OrderItemsSection } from 'module/orderManagement/components/NewItensSection'
import { OrderItemsSearch } from 'module/orderManagement/components/NewItensSection/OrderItemsSearch'
import { OrderDetailsSection } from 'module/orderManagement/components/OrderDetailsSection'
import { PaymentDetailsSection } from 'module/orderManagement/components/PaymentSection'
import { ShippingDetailsSection } from 'module/orderManagement/components/ShippingDetailsSection'
import { Order } from 'module/orderManagement/domain/models/order'
import { AddOrder } from 'module/orderManagement/domain/usecases/add-order'
import React, { useContext, useEffect, useState } from 'react'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { toast } from 'react-toastify'
import { createOrder, simulateOrder } from 'services'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import { ViewType } from 'utils/Constants'
import './styles.scss'

function isObjectEmpty(obj: object): boolean {
	return Object.keys(obj)?.length === 0
}

export const NewOrder = () => {
	const [searchProductShowModal, setSearchProductShowModal] =
		useState<boolean>(false)
	const [simulatedOrder, setSimulatedOrder] = useState<Order>()
	const [hasSimulated, setHasSimulated] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [progress, setProgress] = useState<number>(0)

	const { viewType } = useContext(DeviceContextElement)

	const detailForm = useForm<AddOrder.OrderDetailsForm>({
		defaultValues: {
			type: 'I',
			category: '',
			price_table: '',
			fair_order: 'NF',
			salesperson_code: '000027',
			message_invoice: '',
			client_code: '',
			client_branch: '',
			service_order_code: '',
			financial_release: '',
			order_origin: 'PRP'
		}
	})

	const { client_code, price_table } = useWatch({ control: detailForm.control })

	const shippingForm = useForm<AddOrder.ShippingForm>({
		defaultValues: {
			freight_type: 'C',
			re_delivery_shipping_company: '',
			shipping_company: '',
			partial_delivery: '',
			release_shipment: '',
			freight_cost: 0,
			embedded_shipping: 0,
			commercial_freight: 0,
			package_type: '',
			package_amount: 0,
			net_weight: 0,
			gross_weight: 0
		}
	})

	const { freight_cost, embedded_shipping, commercial_freight } = useWatch({
		control: shippingForm.control
	})

	const itemsForm = useForm<AddOrder.ItemsForm>()
	const itemsFieldArray = useFieldArray({
		control: itemsForm.control,
		name: 'items'
	})

	const { items } = useWatch({ control: itemsForm.control })

	const paymentForm = useForm<AddOrder.PaymentForm>({
		defaultValues: {
			payment_code: '',
			branch: '01',
			discount: 0,
			discount_one: 0,
			discount_two: 0,
			discount_three: 0,
			addition: 0,
			price_total: 0
		}
	})

	const onSelectBranch = (value: string) => {
		paymentForm.setValue('branch', value)
	}

	const { branch } = useWatch({
		control: paymentForm.control
	})

	const installmentForm = useForm<AddOrder.InstallmentForm>()

	const installmentFieldArray = useFieldArray({
		control: installmentForm.control,
		name: 'installments'
	})

	useEffect(() => {
		if (hasSimulated) {
			setHasSimulated(false)
		}
	}, [
		client_code,
		price_table,
		freight_cost,
		embedded_shipping,
		commercial_freight,
		items,
		branch
	])

	const handleClick = async (isCreate?: boolean) => {
		await detailForm.handleSubmit(() => {})()
		progress > 0 && (await shippingForm.handleSubmit(() => {})())
		progress > 1 && (await itemsForm.handleSubmit(() => {})())
		progress > 2 && (await paymentForm.handleSubmit(() => {})())
		progress > 2 && installmentForm.handleSubmit(() => {})

		const detailsErrors = isObjectEmpty(detailForm.formState.errors)
		const itemsErrors = isObjectEmpty(itemsForm.formState.errors)
		const shippingErrors = isObjectEmpty(shippingForm.formState.errors)
		const paymentErrors = isObjectEmpty(paymentForm.formState.errors)
		const installmentErrors = isObjectEmpty(installmentForm.formState.errors)
		if (
			detailsErrors &&
			itemsErrors &&
			shippingErrors &&
			paymentErrors &&
			installmentErrors
		) {
			if (progress < 2) {
				setProgress((e) => e + 1)
				return
			}
			if (progress === 2 && itemsFieldArray.fields?.length === 0) {
				toast.error('Adicione pelo menos um item')
				return
			}
			if (progress < 3 && itemsFieldArray.fields?.length > 0) {
				setProgress((e) => e + 1)
				return
			}
			if (progress === 3 && itemsFieldArray.fields?.length > 0) {
				const detailsValues = detailForm.getValues()
				const shippingValues = shippingForm.getValues()
				const itemsValues = itemsForm.getValues()
				const paymentValues = paymentForm.getValues()
				const installmentValues = installmentForm.getValues()

				const newOrder = {
					...detailsValues,
					...shippingValues,
					...itemsValues,
					...paymentValues,
					...installmentValues
				}

				hasSimulated && isCreate ? onCreate(newOrder) : onSimulate(newOrder)
				return
			}
		} else if (!itemsErrors) {
			toast.error('Preencha todos os campos obrigatórios dos itens')
			if (
				itemsForm &&
				itemsForm.formState &&
				itemsForm.formState.errors &&
				Array.isArray(itemsForm.formState.errors.items)
			) {
				let isFirstItemProcessed = false
				itemsForm.formState.errors.items.forEach((item, index) => {
					if (item !== undefined && !isFirstItemProcessed) {
						const detailsElement = document.getElementById(
							`items-list-details-${index}`
						) as HTMLDetailsElement
						if (detailsElement) {
							detailsElement.open = true
							detailsElement.scrollIntoView({
								behavior: 'smooth',
								block: 'start'
							})
						}
						isFirstItemProcessed = true
					}
				})
			}
		}
	}

	const onSimulate = (newOrder: any) => {
		setIsLoading(true)
		simulateOrder(newOrder)
			.then((res) => {
				const { data } = res.data
				if (data) {
					setSimulatedOrder(data)
				}
				setHasSimulated(true)
				toast.success('Pedido simulado com sucesso!')
			})
			.catch((error) => {
				const toastMessage = error.response.data?.error?.message
				toast.error(
					toastMessage ||
						'Não foi possível simular o pedido, revise os dados e tente novamente'
				)
			})
			.finally(() => setIsLoading(false))
	}

	const onCreate = (newOrder: any) => {
		setIsLoading(true)
		createOrder(newOrder)
			.then((res) => {
				const { data } = res.data
				if (!!data?.branch && !!data?.code) {
					toast.success('Pedido cadastrado com sucesso!')
					setTimeout(() => {
						window.location.replace(
							`/order/${data.branch}/${data?.code}/details`
						)
					}, 500)
				}
			})
			.catch((error) => {
				const toastMessage = error.response.data?.error?.message
				toast.error(
					toastMessage ||
						'Não foi possível criar o pedido, revise os dados e tente novamente'
				)
			})
			.finally(() => setIsLoading(false))
	}

	const renderConfirm = () => (
		<div
			style={{
				marginTop: '20px',
				width: '100%',
				display: 'grid',
				gridTemplateColumns: '128px 128px',
				justifyContent: 'center',
				gap: '20px'
			}}
		>
			<Button
				size="lg"
				title={progress > 3 ? 'PROSSEGUIR' : 'CONFIRMA'}
				variant="confirmation-gradient"
				disabled={isLoading || (progress === 3 && !hasSimulated)}
				onClick={() => {
					handleClick(true)
				}}
			/>
			<Button
				variant="cancellation-gradient"
				title="CANCELAR"
				size="lg"
				disabled={isLoading}
				onClick={() => {
					setProgress(0)
					detailForm.reset()
					shippingForm.reset()
					itemsForm.reset()
					itemsFieldArray.replace([])
					paymentForm.reset()
					installmentForm.reset()
					window.location.replace(`/orders`)
				}}
			/>
		</div>
	)

	return (
		<>
			<div className="new-order-details-tab">
				<OrderDetailsSection.Root>
					<OrderDetailsSection.EditingMode
						order={simulatedOrder}
						form={detailForm}
					/>
				</OrderDetailsSection.Root>
				{progress > 0 && (
					<ShippingDetailsSection.Root>
						<ShippingDetailsSection.EditingMode
							order={simulatedOrder}
							form={shippingForm}
						/>
					</ShippingDetailsSection.Root>
				)}
				{progress > 1 && (
					<OrderItemsSection.Root>
						<OrderItemsSection.MainContain>
							{!(viewType === ViewType.MOBILE) && <OrderItemsSection.Header />}
							<OrderItemsSection.EditingMode
								form={itemsForm}
								order={simulatedOrder}
								table={price_table || ''}
								fieldArray={itemsFieldArray}
								branch={branch}
							/>
						</OrderItemsSection.MainContain>
						<OrderItemsSection.AddItemButton
							showModal={(e) => setSearchProductShowModal(e)}
						/>
					</OrderItemsSection.Root>
				)}
				{progress > 2 && (
					<PaymentDetailsSection.Root>
						<PaymentDetailsSection.EditingMode
							order={simulatedOrder}
							paymentForm={paymentForm}
							installmentForm={installmentForm}
							fieldArray={installmentFieldArray}
							handleSimulateClick={() => handleClick(false)}
							branch={branch}
							{...{ onSelectBranch }}
						>
							{simulatedOrder && (
								<PaymentDetailsSection.TaxesContent order={simulatedOrder} />
							)}
							{simulatedOrder && (
								<PaymentDetailsSection.DiscountsContent
									order={simulatedOrder}
								/>
							)}
							{simulatedOrder && (
								<PaymentDetailsSection.TotalsContent order={simulatedOrder} />
							)}
						</PaymentDetailsSection.EditingMode>
					</PaymentDetailsSection.Root>
				)}
				{renderConfirm()}
			</div>
			<LoadingProgress show={isLoading} text="Atualizando..." />
			{searchProductShowModal && (
				<OrderItemsSearch
					table={price_table || ''}
					fieldArray={itemsFieldArray}
					show={searchProductShowModal}
					onClose={() => setSearchProductShowModal(false)}
				/>
			)}
		</>
	)
}
