import Client from 'models/Client'
import Equipment from 'models/Equipment'
import DefectSelector from 'module/technicalServices/components/DefectSelector'
import Equipments from 'module/technicalServices/components/Equipments'
import OptionsTypeOs from 'module/technicalServices/components/TypeOsSelector'
import React, { useState } from 'react'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import { Footer, Header, Obs, RequestDetails } from './styles'

interface Props {
	equipment?: Equipment
	client?: Client
	type?: string
	origin_os?: string
	obs?: string
	onClick: (selectedTypeOS: string, defect: string) => void
	onClose: () => void
}

const TakeOS: React.FC<Props> = ({
	client,
	type,
	obs,
	origin_os,
	equipment,
	onClick,
	onClose
}) => {
	const [selectedTypeOS, setSelectedTypeOS] = useState<string>('')
	const [defect, setDefect] = useState<string>('')

	//set value to selectedTypeOS by clicking on one of the OS Types from the list
	function onSelectType(selectedTypeOS: string) {
		const data = JSON.parse(selectedTypeOS)
		setSelectedTypeOS(data.value)
	}

	//set value to defect by clicking on one of the Defects from the list
	function handleDefectSelect(defect: string) {
		setDefect(defect)
	}

	//validates that the OS type and the defect are not empty and sends values via onClick
	function handleType() {
		if (selectedTypeOS !== '' && defect !== '') {
			onClick(selectedTypeOS, defect)
			onClose()
		}
	}

	//checks if equipment exists and returns OS Type select
	const optionsTypeContent = () => {
		return equipment ? (
			<OptionsTypeOs
				selectedType={selectedTypeOS}
				serialNumber={equipment?.serial_number}
				onSelectType={onSelectType}
			/>
		) : (
			<></>
		)
	}

	return (
		<Modal show={true} onClose={onClose} title="Atender OS" showClose={true}>
			<Header>
				<Equipments client={client} equipment={equipment} />
				<Obs>
					{type == 'INSTALACAO' &&
					(origin_os == 'BUSCA_APP' || origin_os == 'INSTALL_APP') ? (
						<div style={{ display: 'flex', paddingBottom: '5px' }}>
							<div style={{ fontSize: '16px', fontWeight: '700' }}>
								CPF/CNPJ comprador{' '}
							</div>
							<div style={{ paddingLeft: '5px', width: '100%' }}>: {obs} </div>
						</div>
					) : (
						obs && (
							<div style={{ display: 'flex', paddingBottom: '5px' }}>
								<div style={{ fontSize: '16px', fontWeight: '700' }}>
									Observação:
								</div>
								<div style={{ paddingLeft: '5px', width: '100%' }}> {obs}</div>
							</div>
						)
					)}
				</Obs>
			</Header>

			<RequestDetails>
				<div className="field-title">Tipo de serviço:</div>
				{optionsTypeContent()}
				<div className="form-area">
					<div className="field-title">Def. Relatado:</div>
					<DefectSelector
						selectedDefect={defect}
						onSelectDefect={handleDefectSelect}
					/>
				</div>
			</RequestDetails>

			<Footer>
				<Button
					style={{ width: '120px' }}
					variant="confirmation-gradient"
					size="lg"
					title="Atender OS"
					onClick={handleType}
				/>
				<Button
					style={{ width: '120px' }}
					variant="cancellation-gradient"
					size="lg"
					title="Cancelar"
					onClick={onClose}
				/>
			</Footer>
		</Modal>
	)
}

export default TakeOS
