import { TransferInfo } from 'models/Transfer'
import React from 'react'
import { TransferOrigin } from 'utils/Constants'
import { normalizeCGC, phoneMask } from 'utils/Pipe'

interface Props {
	transferInfo: TransferInfo
	onEditClick: () => void
	onSearchClient: () => void
}

const TransferNewClient: React.FC<Props> = ({
	transferInfo,
	onEditClick,
	onSearchClient
}) => {
	const transferOptionsContent = () => {
		const disabledClass = !transferInfo.userId ? 'disabled' : ''

		return transferInfo?.origin === TransferOrigin.PRP ? (
			<div className={`transfer-options ${disabledClass}`}>
				<span onClick={onEditClick}>Alterar cliente</span>
				<span onClick={onSearchClient}>Buscar cliente</span>
			</div>
		) : (
			<div className={`transfer-options`}>
				<span onClick={onSearchClient}>Buscar cliente</span>
			</div>
		)
	}

	return (
		<div className="new-client-content">
			<div className="transfer-info">
				<div className="transfer-contact-info">
					<div>
						<span>
							<b>Nome:</b>
							{transferInfo.name}
						</span>
						<span>
							<b>CPF/CNPJ:</b>
							{normalizeCGC(transferInfo.cpfCnpj)}
						</span>
					</div>
					<div>
						<span>
							<b>E-mail:</b>
							{transferInfo.email}
						</span>
						<span>
							<b>Telefone:</b>
							{phoneMask(transferInfo.phone)}
						</span>
					</div>
				</div>
			</div>
			<div className="new-client-warning">
				<div className="new-client-warning-header">
					<h6>Alerta</h6>
				</div>
				<div className="new-client-warning-body">
					<p>
						Não foi possivel encontrar o dados deste cliente no Protheus!
						Realize o cadastro no Protheus para prosseguir com a transferência!
					</p>
				</div>
			</div>
			{transferOptionsContent()}
		</div>
	)
}

export default TransferNewClient
