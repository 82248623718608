import React from 'react'
import ReactJoyride from 'react-joyride'
import { useDispatch } from 'react-redux'
import { updateTutorialsList } from 'services'
import { store } from 'store'
import { updateReducerTutorials } from 'store/modules/user/actions'
import { handleStepTitles } from 'utils/Constants/joyrideSteps'

const JoyRide = ({ ...rest }) => {
	const dispatch = useDispatch()
	const { profile } = store.getState().user
	const handleJoyrideCallback = async (e: any) => {
		if (
			e.action === 'next' &&
			e.status === 'finished' &&
			e.type === 'tour:end'
		) {
			if (rest?.steps?.length > 0) {
				const stepTitles = handleStepTitles(rest?.steps)
				try {
					const response = await updateTutorialsList({
						stepTitles: stepTitles,
						profile: profile
					})
					dispatch(updateReducerTutorials(response.data.tutorials))
				} catch (error) {
					console.error(error)
				}
			}
		}
	}

	return (
		<ReactJoyride
			continuous
			hideCloseButton
			scrollToFirstStep
			showProgress
			showSkipButton
			styles={{
				options: {
					arrowColor: '#fff',
					backgroundColor: '#fff',
					beaconSize: 36,
					overlayColor: 'rgba(0, 0, 0, 0.5)',
					primaryColor: '#C21618',
					spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
					textColor: '#333',
					width: undefined,
					zIndex: 100
				}
			}}
			steps={rest.steps}
			callback={(e) => handleJoyrideCallback(e)}
			locale={{
				back: 'Voltar',
				close: 'Fechar',
				last: 'Fim',
				next: 'Próximo',
				open: 'Abrir',
				skip: 'Pular tutorial'
			}}
			{...rest}
		/>
	)
}

export default JoyRide
