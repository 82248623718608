import Pagination from '@material-ui/lab/Pagination'
import React from 'react'

import './styles.scss'

interface Params {
	selected: number
	total: number
	isLoading?: boolean
	onPageChange: (pageSelected: number) => void
}

const Paginator: React.FC<Params> = ({
	selected,
	total,
	isLoading = false,
	onPageChange
}) => {
	const onChange = (event: React.ChangeEvent<unknown>, value: number) => {
		onPageChange(value)
	}

	const mainContent = () => {
		return (
			<section className="paginator-main-content">
				<Pagination
					count={total}
					page={selected}
					onChange={onChange}
					siblingCount={1}
				/>
			</section>
		)
	}

	return !isLoading ? mainContent() : <></>
}

export default Paginator
