import React from 'react'
import './styles.scss'

const AllPendencyListHeader = () => {
	return (
		<div className="all-pendency-list-header">
			<ul className="all-pendency-list-item all-pendency-list-pendency">
				<span>Pendência</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-inclusion-date">
				<span>Data de inclusão</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-origin">
				<span>Origem</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-pendency-type">
				<span>Tipo de pendência</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-resp-solution">
				<span>Setor Solução</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-resp-solution">
				<span>Resp. Solução</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-expected-date">
				<span>Data Prevista</span>
			</ul>
			<ul className="all-pendency-list-item all-pendency-list-status">
				<span>Status</span>
			</ul>
			<ul className="all-pendency-list-item-actions" />
			<ul className="all-pendency-list-item-actions" />
		</div>
	)
}
export default AllPendencyListHeader
