import { OrderItemsContentEditingMode } from './ItensListBody/OrderItemsContentEditingMode'
import { OrderItemsContentViewingMode } from './ItensListBody/OrderItemsContentViewingMode'
import { OrderItemsHeader } from './OrderItemsHeader/OrderItemsHeader'
import { OrderItemsSectionAddItem } from './OrderItemsSectionAddItem'
import { OrderItemsMainContain } from './OrderItemsSectionMainContain'
import { OrderItemsSectionRoot } from './OrderItemsSectionRoot'

export const OrderItemsSection = {
	AddItemButton: OrderItemsSectionAddItem,
	EditingMode: OrderItemsContentEditingMode,
	Header: OrderItemsHeader,
	Root: OrderItemsSectionRoot,
	ViewingMode: OrderItemsContentViewingMode,
	MainContain: OrderItemsMainContain
}
