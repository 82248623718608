import React from 'react'

const OrderManagementIcon: React.FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="50"
			viewBox="10 5 30 30"
			fill="url(#gradient)"
		>
			<linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
				<stop offset="0%" stopColor="#4F8FE9" stopOpacity="0.6" />
				<stop offset="45%" stopColor="#396BC9" stopOpacity="0.6" />
				<stop offset="100%" stopColor="#5e50ae" stopOpacity="0.6" />
			</linearGradient>
			<g id="Pedidos" transform="translate(-247 -403)">
				<path
					id="Orders"
					d="M-1146.017-5977c-1.737,0-2.441-.08-2.445-2.1h2.962v-.02h20.272v-25.761H-1146.7v11.78h-1.767v-11.78c0-2.038.706-2.12,2.445-2.12h20.109c1.74,0,2.445.082,2.445,2.12v25.761c0,2.038-.706,2.12-2.445,2.12Zm2.005-3v-.748h-4.5v.748h-2.7v-.748h-1.251a1,1,0,0,1-1-1V-5991a1,1,0,0,1,1-1h12.4a1,1,0,0,1,1,1v9.249a1,1,0,0,1-1,1h-1.25v.748Zm-8.521-2.679a1,1,0,0,0,1,1h10.539a1,1,0,0,0,1-1v-6.62h-12.541Zm17.082-1.319a1,1,0,0,1-1-1,1,1,0,0,1,1-1h7a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm-15.7-3.5a.451.451,0,0,1-.451-.451.449.449,0,0,1,.451-.448h9.78a.446.446,0,0,1,.448.448.448.448,0,0,1-.448.451Zm15.7-.5a1,1,0,0,1-1-1,1,1,0,0,1,1-1h7a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h7a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm-8-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h15a1,1,0,0,1,1,1,1,1,0,0,1-1,1Zm0-4a1,1,0,0,1-1-1,1,1,0,0,1,1-1h15a1,1,0,0,1,1,1,1,1,0,0,1-1,1Z"
					transform="translate(1410.461 6415)"
				/>
			</g>
		</svg>
	)
}

export default OrderManagementIcon
