import Client from 'models/Client'
import ServiceOrder from 'models/ServiceOrder'
import TechnicalServiceCardIcon from 'module/entryPoint/pages/FeaturesHome/technicalServicesCard/TechnicalServiceCardIcon'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { history } from 'routes/history'
import Button from 'shared/components/Button'
import { store } from 'store'
import { updateModalStatus } from 'store/modules/user/actions'
import './styles.scss'

const TechnicalServicesCard = () => {
	const [countOS, setCountOS] = useState<number>()
	const [client, setClient] = useState<Client>()
	const [lastOS, setLastOS] = useState<ServiceOrder>()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(updateModalStatus(false))
		// getTodayOS()
		// .then((resp) => {
		// 	const { data } = resp
		// 	setCountOS(data.arr?.length)
		// 	setLastOS(data.arr[data.arr?.length - 1])
		// 	getClientInfo(data.arr[data.arr?.length - 1].client_code)
		// 		.then((respClient) => {
		// 			const { data } = respClient
		// 			setClient(data[0])
		// 		})
		// 		.catch((err) => {
		// 			console.log(err)
		// 		})
		// })
		// .catch((err) => {
		// 	console.log(err)
		// })
	}, [])

	const handleNewServiceOrder = () => {
		dispatch(updateModalStatus(true))
		history.push('/os')
	}

	return (
		<div
			className="feature-card"
			onClick={() => {
				history.push(
					`/os/${
						store.getState().serviceOrders.url === 'os'
							? ''
							: store.getState().serviceOrders.url
					}`
				)
			}}
		>
			<div className="service-management-card-title">
				<h4>ATENDIMENTOS TÉCNICOS</h4>
				<TechnicalServiceCardIcon />
			</div>
			<div className="service-management-card-main-contain">
				<div className="service-content">
					<p className="subtext">Resumo da semana:</p>
					{countOS ? (
						<div className="info-card">{countOS} atendimentos cadastrados.</div>
					) : (
						<div className="info-card">0 atendimentos cadastrados.</div>
					)}
				</div>
				<div className="summary2">
					<strong>Ultimo atendimento:</strong>
					<br />
					<div className="last-service">{client?.name}</div>
					<div className="last-service">{`${lastOS?.service_order} - ${lastOS?.equipments.name}`}</div>
				</div>
			</div>

			<div className="service-management-card-actions">
				<Button
					title="NOVO ATENDIMENTO"
					variant="confirmation-solid"
					size="md"
					onClick={() => {
						handleNewServiceOrder()
					}}
				/>
			</div>
		</div>
	)
}

export default TechnicalServicesCard
