import Equipment from 'models/Equipment'
import { RequisitionError } from 'models/RequisitionError'
import { Transfer, TransferEquipment, TransferUpdate } from 'models/Transfer'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import FormValidator from 'module/technicalServices/components/Shared/Forms/FormValidator'
import LoadingProgress from 'module/technicalServices/components/Shared/Loadings/LoadingProgress'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdErrorOutline } from 'react-icons/md'
import { toast } from 'react-toastify'
import { TransferUpdateTypes } from 'utils/Constants'
import { normalizeCGC, parseDate } from 'utils/Pipe'

interface Props {
	transfer: Transfer
	onUpdate: (transferUpdate: TransferUpdate) => void
}

const TransferEditEquipment: React.FC<Props> = ({ transfer, onUpdate }) => {
	const { onRequestEquipments } = useContext(TransfersDetailsContextElement)
	const [equipData, setEquipData] = useState<TransferEquipment>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()

	useEffect(() => {
		setEquipData(getTransferEquipment())
	}, [])

	const getTransferEquipment = () => {
		const currentClientPrp =
			transfer.transferPayloadParsed.prpInfo.currentClient
		const equipmentPrp = transfer.transferPayloadParsed.prpInfo.equipment

		const newEquipData = {
			id: equipmentPrp?.id,
			name: equipmentPrp.name,
			serialNumber: equipmentPrp.serial_number,
			saleDate: equipmentPrp.sale_date,
			currentClient: currentClientPrp.nome_fantasia,
			cpfCnpj: currentClientPrp.cpfCnpj,
			clientCode: currentClientPrp.cod_protheus
		} as TransferEquipment

		return newEquipData
	}

	const onSearch = (data: any) => {
		const serialNumber = data.serialNumber
		setIsLoading(true)
		onRequestEquipments(serialNumber, onCompleted)
	}

	const onCompleted = (equipment?: Equipment, hasError?: RequisitionError) => {
		if (hasError) {
			switch (hasError?.code) {
				case 404:
					toast.error('Equipamento não encontrado')
					break
				case 400:
					toast.error('Erro ao buscar equipamento')
					break
			}
		} else {
			const newEquipData = {
				name: equipment?.name,
				serialNumber: equipment?.serial_number,
				saleDate: equipment?.sale_date,
				currentClient: equipment?.client.nome_fantasia,
				cpfCnpj: equipment?.client.cpfCnpj,
				clientCode: equipment?.client.cod_protheus
			} as TransferEquipment

			setEquipData(newEquipData)
		}
		setIsLoading(false)
	}

	const onSubmit = () => {
		if (equipData) {
			const transferUpdate = {
				id: transfer?.id,
				serialNumber: equipData?.serialNumber,
				cpfCnpj: equipData?.cpfCnpj,
				type: TransferUpdateTypes.EQUIP_UPDATE
			} as TransferUpdate
			onUpdate(transferUpdate)
		} else {
			toast.error('Nenhum equipamento foi selecionado!')
		}
	}

	const formContent = () => {
		const serialNumber = transfer.serialNumber
		return (
			<form
				className="main-form-content transfer-equip-form"
				onSubmit={handleSubmit(onSearch)}
			>
				<div>
					<div className="form-item-content">
						<label>Número de Série</label>
						<input
							placeholder="Informe o número de série"
							defaultValue={serialNumber}
							{...register('serialNumber', { required: true })}
						/>
					</div>
					<CustomButtom type="submit" label="Buscar" context="secondary" />
				</div>
				<FormValidator {...{ errors, formName: 'serialNumber' }} />
			</form>
		)
	}

	const equipContent = () => {
		return (
			<div className="edit-equip-info">
				<span title={equipData?.name || ''}>
					<b>Equipamento:</b>
					{equipData?.name}
				</span>
				<span>
					<b>Número de Série:</b>
					{equipData?.serialNumber}
				</span>
				<span>
					<b>Data de Venda:</b>
					{equipData?.saleDate
						? parseDate(equipData?.saleDate, 'DD/MM/YYYY')
						: ''}
				</span>
				<span title={equipData?.currentClient || ''}>
					<b>Cliente Atual:</b>
					{equipData?.currentClient}
				</span>
				<span>
					<b>Código do Cliente:</b>
					{equipData?.clientCode}
				</span>
				<span>
					<b>CPF/CNPJ:</b>
					{normalizeCGC(equipData?.cpfCnpj)}
				</span>
			</div>
		)
	}

	const equipValidatorWarning = () => {
		return (
			<div className="edit-equip-info-warning">
				<MdErrorOutline />
				<h5>Equipamento é obrigatório</h5>
			</div>
		)
	}

	const handleEquipContent = () => {
		if (equipData && !isLoading) {
			return equipContent()
		} else if (isLoading) {
			return loadingContent()
		} else {
			return equipValidatorWarning()
		}
	}

	const loadingContent = () => {
		return <LoadingProgress text="Buscando equipamento..." show={isLoading} />
	}

	const handleDisableButtom = () => {
		return !equipData
	}

	const mainContent = () => {
		return (
			<div className="transfer-edit-equip-content">
				{formContent()}
				{handleEquipContent()}
				<div className="edit-equip-footer">
					<CustomButtom
						type="button"
						label="Alterar"
						context="primary"
						onClick={onSubmit}
						disabled={handleDisableButtom()}
					/>
				</div>
			</div>
		)
	}

	return mainContent()
}

export default TransferEditEquipment
