import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext } from 'react'
import { TransferTabNames, TransferTabOptions } from 'utils/Constants'
import './styles.scss'

interface Props {
	tabSelected: TransferTabOptions
	onTabClick: (tab: TransferTabOptions) => void
}

const TransfersTabs: React.FC<Props> = ({ tabSelected, onTabClick }) => {
	const { transferSelected } = useContext(TransfersDetailsContextElement)

	const handleDisabledTab = () => {
		return (
			!transferSelected?.transferPayloadParsed.prpInfo.newClient ||
			!transferSelected?.userId
		)
	}

	const onClick = (tabOption: TransferTabOptions) => {
		const tabDisabled = handleDisabledTab()
		if (!tabDisabled) {
			onTabClick(tabOption)
		}
	}

	const tabContent = (
		tabOption: TransferTabOptions,
		tabName: TransferTabNames
	) => {
		const tabDisabled = handleDisabledTab()
		const selectedClass = tabSelected == tabOption ? 'selected' : ''
		const disabledClass =
			tabOption == TransferTabOptions.INIT_TRANSFER && tabDisabled
				? 'disabled'
				: ''

		return (
			<div
				{...{ className: `${selectedClass} ${disabledClass}` }}
				onClick={() => {
					onClick(tabOption)
				}}
			>
				{tabName}
			</div>
		)
	}

	return (
		<div className="transfers-tabs-content">
			{tabContent(TransferTabOptions.INFO, TransferTabNames.INFO)}
			<div id="joyride-transfercontent-button">
				{' '}
				{tabContent(
					TransferTabOptions.INIT_TRANSFER,
					TransferTabNames.INIT_TRANSFER
				)}
			</div>
		</div>
	)
}

export default TransfersTabs
