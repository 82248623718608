import React, { useEffect } from 'react'
import { MdEmail, MdPhone } from 'react-icons/md'

import './styles.scss'

const MobileHelpUser: React.FC = () => {
	useEffect(() => {
		$('body').addClass('show-chat')
		return () => {
			$('body').removeClass('show-chat')
		}
	}, [])

	const formContent = () => {
		return (
			<section className="mobile-help-main-content">
				<div className="help-contact-area">
					<div className="help-contact-area-title">
						<h5>Central de atendimentos Prática</h5>
					</div>
					<div className="help-contact-area-content">
						<div className="email-content">
							<span>ajuda@praticabr.com</span>
							<a href="mailto:ajuda@praticabr.com" className="email-button">
								<MdEmail />
								<span>E-mail</span>
							</a>
						</div>
						<div className="phone-content">
							<span>(35) 3449.1200</span>
							<a href="tel:3534491200" className="email-button">
								<MdPhone />
								<span>Ligar</span>
							</a>
						</div>
					</div>
				</div>
			</section>
		)
	}

	return <>{formContent()}</>
}

export default MobileHelpUser
