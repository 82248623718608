import { TablePagination } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import { Description, Footer } from './styles'

interface Props {
	show: boolean
	protocolos: any
	onClose: (value: boolean) => void
	onContinue: () => void
}

interface Column {
	id: 'service_order' | 'osProtheus' | 'created_at' | 'days_open'
	label: string
	minWidth?: number
	align?: 'center'
	format?: (value: number) => string
}

const columns: Column[] = [
	{ id: 'service_order', label: 'Protocolo', minWidth: 50 },
	{ id: 'osProtheus', label: 'OS Protheus', minWidth: 50, align: 'center' },
	{
		id: 'created_at',
		label: 'Data da Abertura',
		minWidth: 50,
		align: 'center'
	},
	{
		id: 'days_open',
		label: 'N° de dias em aberto',
		minWidth: 50,
		align: 'center'
	}
]

interface Data {
	service_order: string
	osProtheus: string
	created_at: string
	days_open: string
}

function createData(
	service_order: string,
	osProtheus: string,
	created_at: string,
	days_open: string
): Data {
	return { service_order, osProtheus, created_at, days_open }
}

const useStyles = makeStyles({
	root: {
		width: '100%',
		backgroundColor: '#f7f7f7'
	},
	container: {
		maxHeight: 200
	},
	header: {
		lineHeight: 1,
		fontWeight: 700
	},
	line: {
		lineHeight: 1
	}
})

const OpenOS: React.FC<Props> = ({ show, protocolos, onContinue, onClose }) => {
	const classes = useStyles()
	const [page, setPage] = useState(0)
	const [rows, setRows] = useState<Data[]>([])
	const [rowsPerPage, setRowsPerPage] = useState(10)

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage)
	}

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(+event.target.value)
		setPage(0)
	}

	useEffect(() => {
		const rowList = []
		for (let i = 0; i < protocolos?.length; i++) {
			const protocol = protocolos[i]
			const row = createData(
				protocol.service_order,
				protocol.os_protheus,
				protocol.dateopen,
				protocol.daysopen
			)
			rowList.push(row)
		}
		setRows(rowList)
	}, [protocolos])

	return (
		<>
			<Modal
				show={show}
				onClose={() => onClose(false)}
				title="Protocolos Abertos"
				showClose={true}
			>
				<Description>
					Já existem protocolos em aberto para este número de série:
				</Description>

				<Paper className={classes.root}>
					<TableContainer className={classes.container}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<TableCell
											className={classes.header}
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows
									.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
									.map((row) => {
										return (
											<TableRow
												hover
												role="checkbox"
												tabIndex={-1}
												key={row.service_order}
											>
												{columns.map((column) => {
													const value = row[column.id]
													return (
														<TableCell
															key={column.id}
															align={column.align}
															className={classes.line}
														>
															{column.format && typeof value === 'number'
																? column.format(value)
																: value}
														</TableCell>
													)
												})}
											</TableRow>
										)
									})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10, 25, 50, 100]}
						component="div"
						count={rows?.length || 0}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
						labelRowsPerPage="Protocolos por página"
					/>
				</Paper>

				<Footer>
					<Button
						style={{ width: '120px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Prosseguir"
						onClick={onContinue}
					/>
					<Button
						style={{ width: '120px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Fechar"
						onClick={() => onClose(false)}
					/>
				</Footer>
			</Modal>
		</>
	)
}

export default OpenOS
