import React from 'react'
import CheckBox from 'shared/components/CheckBox'
import './styles.scss'

type TableBodyProps<T, K extends keyof T> = {
	data: any[] | undefined
	columns: any[]
	onRowClicked?: (data: any) => void
}

export const GenericTableBodyMobile = <
	T extends { id: number },
	K extends keyof T
>({
	data,
	columns,
	onRowClicked = () => {}
}: TableBodyProps<T, K>) => {
	const bodyRowStyle: React.CSSProperties = {
		padding:
			columns.map((column) => column.key).includes('status') ||
			columns.some((column) => column?.checkbox)
				? '20px 10px 20px 30px'
				: '20px 10px'
	}

	return (
		<div className="generic-table-body-mobile">
			{data?.map((object, index) => (
				<div
					className="generic-table-body-row"
					key={object.id || index}
					style={bodyRowStyle}
				>
					{columns.map((column, index) => (
						<div
							style={{
								flex: `1 0 ${column.flex}`
							}}
							key={`cell-${index}`}
							className={`generic-table-body-row-data ${column.key}`}
							onClick={() => {
								if (column.key !== 'action') {
									!column.input && onRowClicked(object)
								}
							}}
						>
							{column.key !== 'action' ? (
								<>
									{column.checkbox && (
										<div className="checkbox">
											<CheckBox
												id={column.id}
												variant="blue"
												format="square"
												checked={column.selected?.some(
													(item: any) => item.id === object.id
												)}
												readOnly
											/>
										</div>
									)}
									<label>
										{column.key !== 'status' ? `${column.header}:` : ''}
										<span
											title={
												typeof object[column.key] === 'number' ||
												typeof object[column.key] === 'string'
													? object[column.key]
													: ''
											}
										>
											{object[column.key]}
										</span>
									</label>
								</>
							) : (
								<>{object[column.key]}</>
							)}
						</div>
					))}
				</div>
			))}
		</div>
	)
}
