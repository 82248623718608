import Equipment from 'models/Equipment'
import RequestResponse from 'models/RequestResponse'
import { RequisitionError } from 'models/RequisitionError'
import { Transfer, TransferClient, TransferUpdate } from 'models/Transfer'
import React, { createContext, useEffect, useState } from 'react'
import {
	getTransferClient,
	getTransferEquip,
	updateTransfer,
	verifyClientTransfer
} from 'services'
import { store } from 'store'
import { clearNumericField } from 'utils/Pipe'
import {
	TransfersDetailsContextType,
	TransfersDetailsContextValue
} from './TransfersDetailsContextType'

export const TransfersDetailsContextElement =
	createContext<TransfersDetailsContextType>(TransfersDetailsContextValue)

const TransfersProvider = (props: any) => {
	const [transferClients, setTransferSelectedClients] = useState<
		TransferClient[]
	>([])
	const [transferEquipment, setTransferSelectedEquipment] =
		useState<Equipment>()
	const [transferSelected, setTransferSelected] = useState<Transfer>()
	const [isLoading, setLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<RequisitionError>()

	useEffect(() => {
		if (transferSelected) {
			const cpfCnpj =
				transferSelected.transferPayloadParsed.prpInfo.newClient?.cpfCnpj || ''
			onRequestClients(clearNumericField(cpfCnpj))
		}
	}, [transferSelected])

	const onRequestClients = (search: string) => {
		setLoading(true)
		getTransferClient(search)
			.then((res) => {
				setTransferSelectedClients(res.data.clients)
				setLoading(false)
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error?.message,
					code: error?.statusCode,
					error: error?.request
				}
				setLoading(false)
				setHasError(reportError)
			})
	}

	const onRequestEquipments = (
		search: string,
		callback: (equip?: Equipment, hasError?: RequisitionError) => void
	) => {
		getTransferEquip(search)
			.then((res) => {
				const equip = res.data.equipment
				setTransferSelectedEquipment(equip)
				callback(equip)
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				callback(undefined, reportError)
			})
	}

	const onTransferUpdate = (
		transferInfo: TransferUpdate,
		callback: (request: RequestResponse) => void
	) => {
		const userId = store.getState().auth.id
		transferInfo.userId = userId

		updateTransfer(transferInfo)
			.then((res) => {
				const transfer = res.data.transfer as Transfer
				transfer.transferPayloadParsed = JSON.parse(transfer.transferPayload)
				setTransferSelected(transfer)
				callback({ response: transfer })
			})
			.catch((err) => {
				const error = err?.request?.response?.error
				const reportError = {
					message: error?.message,
					code: error?.statusCode,
					error: error?.request
				}
				callback({ reportError })
			})
	}

	const onSelectTransfer = (transfer?: Transfer) => {
		if (transfer) {
			transfer.transferPayloadParsed = JSON.parse(transfer.transferPayload)
			setTransferSelected(transfer)
		} else {
			setTransferSelected(undefined)
		}
	}

	const verifyClient = (
		cpfCnpj: string,
		transferId: number,
		callback: (request: RequestResponse) => void
	) => {
		verifyClientTransfer(cpfCnpj, transferId)
			.then((res) => {
				const transfer = res.data.transfer as Transfer
				transfer.transferPayloadParsed = JSON.parse(transfer.transferPayload)
				setTransferSelected(transfer)
				callback({ response: transfer })
			})
			.catch((err) => {
				const error = err?.request?.response?.error
				const reportError = {
					message: error?.message,
					code: error?.statusCode,
					error: error?.request
				}
				callback({ reportError })
			})
	}

	const providerValues = () => {
		return {
			transferClients,
			transferEquipment,
			transferSelected,
			isLoading,
			hasError,
			onSelectTransfer,
			onTransferUpdate,
			onRequestClients,
			onRequestEquipments,
			verifyClient
		}
	}

	return (
		<TransfersDetailsContextElement.Provider value={providerValues()}>
			{props.children}
		</TransfersDetailsContextElement.Provider>
	)
}

export default TransfersProvider
