import Tooltip from '@material-ui/core/Tooltip'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { StorageItems } from 'module/orderManagement/domain/models/order'
import React, { useContext, useLayoutEffect } from 'react'
import Skeleton from 'react-loading-skeleton'
import { RouteComponentProps } from 'react-router-dom'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
	billed: boolean
}

const AllBalanceStorageListBodyMobile: React.FC<TParams> = ({
	match,
	billed
}) => {
	const { order, onOrderChange, orderIsLoading, storage } = useContext(
		OrderDetailsContextElement
	)
	const { orderCode, branch } = match.params

	useLayoutEffect(() => {
		if (order?.code.trim() !== orderCode.trim()) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	const hasBilled = () => {
		const billedOrderItensList = storage?.filter(
			(item) => item.invoice.number !== ''
		)
		const toProfitOrderItensList = storage?.filter(
			(item) => item.invoice.number === ''
		)
		const orderItemsList = billed
			? billedOrderItensList
			: toProfitOrderItensList
		return orderItemsList?.length ? (
			<div className="all-balance-storage-list-body">
				{listContent(orderItemsList)}
			</div>
		) : (
			<div className="all-balance-storage-list-body">
				<a className="all-balance-storage-list" id="emptyList" key={0}>
					<ul className="all-balance-storage-list-item all-balance-storage-list-empty-list">
						Nenhum item encontrado
					</ul>
				</a>
			</div>
		)
	}

	const listContent = (orderItensList: StorageItems[]) => {
		return orderItensList.map((item, index: number) => {
			return (
				<a
					className={`all-balance-storage-list`}
					id={`${item?.code}${index}`}
					key={index}
				>
					<ul className="all-balance-storage-list-item all-balance-storage-list-ul" />
					<ul className="all-balance-storage-list-item all-balance-storage-list-product">
						{item?.code}
					</ul>
					<Tooltip
						title={`${item?.description}`}
						interactive
						arrow={true}
						placement={'right-start'}
					>
						<ul className="all-balance-storage-list-item all-balance-storage-list-description">
							{item?.description}
						</ul>
					</Tooltip>
					<ul className="all-balance-storage-list-item all-balance-storage-list-amount">
						{item?.amount}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.a09}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.a01}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.a06}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.a07}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.a50}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.aE2}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.aSP}
					</ul>
					<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
						{item?.storage.aPE}
					</ul>
					{!!billed && (
						<ul className="all-balance-storage-list-item all-balance-storage-list-origin">
							{item?.invoice.number}
						</ul>
					)}
					<ul className="all-balance-storage-list-item all-balance-storage-list-more-options"></ul>
				</a>
			)
		})
	}

	return <>{orderIsLoading ? <Skeleton height={32} /> : hasBilled()}</>
}
export default AllBalanceStorageListBodyMobile
