import { z } from 'zod'

export const UpdateEmployeeSchema = z.object({
	departmentId: z.number(),
	register: z.string().max(6, 'Máximo de 6 caracteres atingido!'),
	name: z
		.string()
		.min(1, 'O campo nome é obrigatório!')
		.max(30, 'Máximo de 30 caracteres atingido!'),
	surname: z
		.string()
		.min(1, 'O campo sobrenome é obrigatório!')
		.max(50, 'Máximo de 50 caracteres atingido!'),
	email: z
		.string()
		.min(1, 'O campo email é obrigatório!')
		.max(128, 'Máximo de 128 caracteres atingido!')
		.toLowerCase(),
	is_active: z.string().min(1, 'O campo status é obrigatório!'),
	phone: z.string().max(30, 'Máximo de 30 caracteres atingido!'),
	cel: z.string().max(30, 'Máximo de 30 caracteres atingido!'),
	department: z.string().min(1, 'O campo departamento é obrigatório!')
})

export type UpdateEmployeeSchema = z.infer<typeof UpdateEmployeeSchema>
