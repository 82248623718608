import React, { ReactNode, createContext, useEffect } from 'react'
import { useParams } from 'react-router'
import { uploadFile } from 'services'
import {
	getProductFamilyDetails,
	getSzr010Details,
	postProductFamilyFile
} from '../services'

export const ProductFamilyDetailsContext = createContext({
	szr010: {} as any,
	sb1010: [] as any[],
	productFamilyDetails: {} as any,
	isLoading: false as boolean,
	processUpload: (
		uploadedFile: any,
		event: string,
		callback: (error: any) => void
	) => {}
})

export const ProductFamilyDetailsProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const [szr010, setSzr010] = React.useState<any>()
	const [sb1010, setSb1010] = React.useState<any[]>([])
	const [productFamilyDetails, setProductFamilyDetails] = React.useState<any>()
	const [isLoading, setIsLoading] = React.useState<any>()

	const { codeProtheus } = useParams<{ codeProtheus: string }>()

	useEffect(() => {
		handleSzr010Details()
		handleProductFamilyDetails()
	}, [codeProtheus])

	const handleSzr010Details = async () => {
		setIsLoading(true)
		getSzr010Details(codeProtheus)
			.then((response) => {
				setSzr010(response?.data?.szr010)
				setSb1010(response?.data?.sb1010)
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const handleProductFamilyDetails = async () => {
		setIsLoading(true)
		getProductFamilyDetails(codeProtheus)
			.then((response) => {
				setProductFamilyDetails(response.data.productFamily)
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const processUpload = async (
		uploadedFile: any,
		event: string,
		callback: (error: any) => void
	) => {
		try {
			const data = new FormData()

			data.append('file', uploadedFile.file, uploadedFile.name)

			const response = await uploadFile.post('upload', data)

			uploadedFile.uploaded = true
			uploadedFile.id = response.data.key
			uploadedFile.url = response.data.url
			uploadedFile.event = event
			postProductFamilyFile({ productFamily: szr010, file: uploadedFile })
		} catch (error) {
			uploadedFile.error = true
		}
	}

	const value = {
		szr010,
		sb1010,
		productFamilyDetails,
		isLoading,
		processUpload
	}

	return (
		<ProductFamilyDetailsContext.Provider value={value}>
			{children}
		</ProductFamilyDetailsContext.Provider>
	)
}
