import { AxiosResponse } from 'axios'
import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
	getPrpUsers,
	getUserPrpOverview,
	requestUserIdFieldControl,
	updateUserById
} from 'services'
import { store } from 'store'
import { setUsersPrpParams } from 'store/modules/adminSystem/actions'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'

export interface UsersPrpContextType {
	user: any | null
	users: any[]
	isLoading: boolean
	pageLimit: number
	totalPages: number
	currentPage: number
	onUpdateList: () => void
	filtersParams?: FilterData
	onSelectUser: (userId: number) => void
	onUpdateUser: (data: {
		userId: number
		data: any
		callback?: () => void
	}) => void
	onIntegrateFieldControl: (
		email: string,
		callBack: (data: any) => void
	) => void
	onChangeParams: ({
		page,
		limit,
		search,
		filter
	}: {
		page?: number
		limit?: number
		search?: string
		filter?: FilterData
	}) => void
}

export const UsersPrpContextElement = createContext<UsersPrpContextType>({
	user: null,
	users: [],
	isLoading: false,
	pageLimit: 10,
	totalPages: 1,
	currentPage: 0,
	onUpdateList: () => {},
	onSelectUser: () => {},
	onUpdateUser: () => {},
	onIntegrateFieldControl: () => {},
	onChangeParams: () => {}
})

const defaultParams = defaultListParams(
	store.getState().adminSystem.usersPrp.listUsersParams,
	false
)

export function UsersPrpProvider(props: any) {
	const [user, setUser] = useState<any | null>(null)
	const [users, setUsers] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const listParams = useRef<ListParams>(defaultParams)

	const dispatch = useDispatch()
	useEffect(() => onRequesUsers(listParams.current), [])

	const onRequesUsers = ({
		currentPage,
		pageLimit,
		searchValue
	}: ListParams) => {
		setIsLoading(true)
		getPrpUsers({
			limit: pageLimit,
			offset: currentPage - 1,
			search: searchValue
		})
			.then((response) => {
				const { users, count } = response.data
				setUsers(users)
				updateParams(Math.ceil(count / pageLimit), 'count')
			})
			.catch(() => {
				toast.error('Erro ao buscar lista de usuários')
				clearParams(true)
			})
			.finally(() => setIsLoading(false))
	}

	const onSelectUser = (userId: number) => {
		setUser(null)
		setIsLoading(true)
		getUserPrpOverview(userId)
			.then((response) => setUser(response.data))
			.catch(() => toast.error('Erro ao buscar informações do usuário'))
			.finally(() => setIsLoading(false))
	}

	const onUpdateUser = ({
		userId,
		data,
		callback
	}: {
		userId: number
		data: any
		callback?: () => void
	}) => {
		setIsLoading(true)
		updateUserById({ data, userId })
			.then(() => {
				onRequesUsers(listParams.current)
				toast.success('Usuário atualizado com sucesso!')
				callback && callback()
			})
			.catch(() => toast.error('Erro ao atualizar informações do usuário!'))
			.finally(() => setIsLoading(false))
	}

	const onIntegrateFieldControl = (
		email: string,
		callBack: (response: AxiosResponse<any, any>) => void
	) => {
		setIsLoading(true)
		requestUserIdFieldControl(email)
			.then((response) => {
				callBack(response.data)
			})
			.catch((err) => {
				console.log(err)
				toast.error(
					'Erro ao integrar usuário com a Field Control. Verifique se o usuário está cadastrado com o mesmo e-mail informado.'
				)
			})
			.finally(() => setIsLoading(false))
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(setUsersPrpParams(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(setUsersPrpParams(listParams.current))
	}

	const onChangeParams = (newParams: {
		page?: number
		limit?: number
		search?: string
		filter?: FilterData
	}) => {
		updateParams(newParams.search || '', 'search')
		newParams.page && updateParams(newParams.page, 'page')
		newParams.limit && updateParams(newParams.limit, 'limit')
		newParams.filter && updateParams(newParams.filter, 'filter')

		onRequesUsers({ ...listParams.current, ...newParams })
	}

	const providerValues = () => ({
		user,
		users,
		isLoading,
		onSelectUser,
		onUpdateUser,
		onIntegrateFieldControl,
		onChangeParams,
		...listParams.current,
		onUpdateList: () => onRequesUsers(listParams.current)
	})

	return (
		<UsersPrpContextElement.Provider value={providerValues()}>
			{props.children}
		</UsersPrpContextElement.Provider>
	)
}
