import { Transfer } from 'models/Transfer'
import CreateTransfer from 'module/technicalServices/components/Transfers/CreateTransfer'
import TransferFinished from 'module/technicalServices/components/Transfers/TransfersDetails/TransferFinished'
import TransferInProgress from 'module/technicalServices/components/Transfers/TransfersDetails/TransferInProgress'
import TransfersHeader from 'module/technicalServices/components/Transfers/TransfersHeader'
import TransfersList from 'module/technicalServices/components/Transfers/TransfersList'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import TransfersDetailsContext from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useMemo, useState } from 'react'
import Paginator from 'shared/Paginator'
import JoyRide from 'shared/components/JoyRide'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import { TransferStatus } from 'utils/Constants'
import {
	StepsEquipTransfer,
	handleStepTitles
} from 'utils/Constants/joyrideSteps'
import './styles.scss'

const Transfers: React.FC = () => {
	const { totalPages, currentPage, isLoading, onPageChange, onUpdateList } =
		useContext(TransfersContextElement)
	const [showCreateTransfer, setShowCreateTransfer] = useState<boolean>(false)
	const [showTransferDetails, setShowTransferDetails] = useState<boolean>(false)
	const [transfer, setTransfer] = useState<Transfer>()
	const [tutorials, setTutorials] = useState(store.getState().user.tutorials)

	useMemo(() => {
		store.subscribe(() => setTutorials(store.getState().user.tutorials))
	}, [tutorials])

	const onActionClick = () => {
		setShowCreateTransfer(true)
	}

	const onItemClick = (transfer: Transfer) => {
		setShowTransferDetails(true)
		setTransfer(transfer)
	}

	const onCloseCreateContent = () => {
		setShowCreateTransfer(false)
		onUpdateList()
	}

	const onCloseDetailsContent = () => {
		setShowTransferDetails(false)
		setTransfer(undefined)
		onUpdateList()
	}

	const onSuccessCreate = () => {
		onCloseCreateContent()
	}

	const onSuccessUpdate = () => {
		onCloseDetailsContent()
	}

	const createTransferContent = () => {
		const title = 'Solicitar Transferência'
		const show = (!isLoading && showCreateTransfer) as boolean
		return (
			<Modal {...{ show, title }} onClose={onCloseCreateContent} showClose>
				<TransfersDetailsContext>
					<CreateTransfer {...{ onSuccessCreate }} />
				</TransfersDetailsContext>
			</Modal>
		)
	}

	const handleModalContent = () => {
		if (transfer) {
			return transfer?.status === TransferStatus.FINISHED ? (
				<TransferFinished {...{ transfer }} />
			) : (
				<TransferInProgress {...{ transfer, onSuccessUpdate }} />
			)
		}
	}

	const transferDetailsContent = () => {
		const title = 'Detalhes da Transferência'
		const show = (!isLoading && transfer && showTransferDetails) as boolean
		return transfer ? (
			<Modal {...{ show, title }} onClose={onCloseDetailsContent} showClose>
				<TransfersDetailsContext transfer={transfer}>
					{handleModalContent()}
				</TransfersDetailsContext>
			</Modal>
		) : (
			<></>
		)
	}

	const mainContent = () => {
		return (
			<section className="transfers-main-content">
				<TransfersHeader {...{ onActionClick }} />
				<div id="joyride-transfer-listview" className="list-view">
					<TransfersList {...{ onItemClick }} />
				</div>
				<Paginator
					selected={currentPage}
					total={totalPages}
					isLoading={isLoading}
					onPageChange={onPageChange}
				/>
			</section>
		)
	}

	return (
		<>
			<>
				{mainContent()}
				{createTransferContent()}
				{transferDetailsContent()}
			</>
			{!showTransferDetails &&
				!tutorials?.includes(handleStepTitles(StepsEquipTransfer)) && (
					<JoyRide steps={StepsEquipTransfer} />
				)}
		</>
	)
}

export default Transfers
