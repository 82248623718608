import LogsProvider from 'module/adminSystem/context/LogsContext'
import { UsersPrpProvider } from 'module/adminSystem/context/UsersPrpContext'
import Logs from 'module/adminSystem/pages/Logs'
import { PrpUsers } from 'module/adminSystem/pages/PrpUsers'
import { UsersJac } from 'module/adminSystem/pages/UsersJac'
import { VersionApps } from 'module/adminSystem/pages/VersionApps'
import Login from 'module/entryPoint/pages/Login'
import NewPass from 'module/entryPoint/pages/NewPass'
import ResetPass from 'module/entryPoint/pages/ResetPass'
import EmployeesProvider from 'module/humanResources/context/EmployeesContext'
import Employees from 'module/humanResources/pages/Employees'
import { InstallAndSearch } from 'module/installAndSearch/pages/Main'
import { OrderInstallation } from 'module/installAndSearch/pages/OrderInstallation'
import { RequestService } from 'module/installAndSearch/pages/RequestService'
import { Response } from 'module/installAndSearch/pages/Response'
import { ResponseByAddress } from 'module/installAndSearch/pages/ResponseByAddress'
import ClientAttachments from 'module/orderManagement/pages/ClientAttachments'
import ClientDetails from 'module/orderManagement/pages/ClientDetails'
import { ClientFinanceDetails } from 'module/orderManagement/pages/ClientFinanceDetails'
import ClientsContextEnvolve from 'module/orderManagement/pages/ClientList'
import ClientOrdersHistory from 'module/orderManagement/pages/ClientOrdersHistory'
import ClientUpdateLogs from 'module/orderManagement/pages/ClientUpdateLogs'
import NewClientContextEnvolve from 'module/orderManagement/pages/NewClient'
import NewOrderContextEnvolve from 'module/orderManagement/pages/NewOrder'
import OrderChecklist from 'module/orderManagement/pages/OrderChecklist'
import OrderClientDetails from 'module/orderManagement/pages/OrderClientDetails'
import OrderClientOrdersHistory from 'module/orderManagement/pages/OrderClientOrdersHistory'
import { OrderDetails } from 'module/orderManagement/pages/OrderDetails'
import OrderFollowUpTab from 'module/orderManagement/pages/OrderFollowUpTab'
import OrdersContextEnvolve from 'module/orderManagement/pages/OrderList'
import OrderPendencyTab from 'module/orderManagement/pages/OrderPendencyTab'
import OrderStorageTab from 'module/orderManagement/pages/OrderStorageTab'
import { ProductsProvider } from 'module/productManagement/context/ProductContext'
import { ProductDetailsProvider } from 'module/productManagement/context/ProductDetailsContext'
import { ProductFamilyProvider } from 'module/productManagement/context/ProductFamilyContext'
import { ProductFamilyDetailsProvider } from 'module/productManagement/context/ProductFamilyDetailsContext'
import { ProductDetails } from 'module/productManagement/pages/ProductDetails'
import { ProductFamilyDetails } from 'module/productManagement/pages/ProductFamilyDetails'
import { ProductsFamilyList } from 'module/productManagement/pages/ProductsFamilyList'
import { ProductsList } from 'module/productManagement/pages/ProductsList'
import TransferContextInvolve from 'module/technicalServices/pages/Transfers/TransferContextEnvolv'
import React from 'react'
import { Switch } from 'react-router'
import ConfigUser from '../module/entryPoint/pages/ConfigUser'
import Error404 from '../module/entryPoint/pages/Error404'
import Error500 from '../module/entryPoint/pages/Error500'
import FeaturesPage from '../module/entryPoint/pages/FeaturesHome'
import Register from '../module/entryPoint/pages/RegisterByUser'
import AllTechnicians from '../module/technicalServices/pages/AllTechnicians/AllTechnicians'
import Equipments from '../module/technicalServices/pages/Equipment/Equipments'
import Notifications from '../module/technicalServices/pages/Notifications'
import ServicePage from '../module/technicalServices/pages/ServiceOrder/ServiceOrder'
import ServiceOrderList from '../module/technicalServices/pages/ServiceOrderList'
import RouterWrapper from './RouterWrapper'

export default function Routes() {
	return (
		<Switch>
			<RouterWrapper
				exact={true}
				path="/"
				component={(props: any) => <Login {...props} />}
			/>
			<RouterWrapper
				exact={true}
				path="/home"
				component={(props: any) => <FeaturesPage {...props} />}
				module="home"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/error/404"
				component={(props: any) => <Error404 {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/error/500"
				component={(props: any) => <Error500 {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/restaurarSenha"
				component={(props: any) => <ResetPass {...props} />}
			/>
			<RouterWrapper
				exact={true}
				path="/configUser"
				component={(props: any) => <ConfigUser {...props} />}
				module="config_user"
				isPrivate
			/>
			<RouterWrapper
				exact={false}
				path="/register"
				component={(props: any) => <Register {...props} />}
				module="register"
			/>
			<RouterWrapper
				exact={false}
				path="/novaSenha"
				component={(props: any) => <NewPass {...props} />}
			/>
			<RouterWrapper
				exact={true}
				path="/human_resources/collaborators"
				component={(props: any) => (
					<EmployeesProvider>
						<Employees {...props} />
					</EmployeesProvider>
				)}
				module="human_resources"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/os"
				component={(props: any) => <ServiceOrderList {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/os/:orderCode"
				component={(props: any) => <ServicePage {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/transfers"
				component={(props: any) => <TransferContextInvolve {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/preventive"
				component={(props: any) => <Equipments {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/technicians"
				component={(props: any) => <AllTechnicians {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/notifications"
				component={(props: any) => <Notifications {...props} />}
				module="technical_services"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/clients"
				component={(props: any) => <ClientsContextEnvolve {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/orders"
				component={(props: any) => <OrdersContextEnvolve {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/client/:clientCode/:clientStoreCode"
				component={(props: any) => <ClientDetails {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/client/:clientCode/:clientStoreCode/financeDetails"
				component={(props: any) => <ClientFinanceDetails {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/client/:clientCode/:clientStoreCode/history"
				component={(props: any) => <ClientOrdersHistory {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/client/:clientCode/:clientStoreCode/logs"
				component={(props: any) => <ClientUpdateLogs {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/client/:clientCode/:clientStoreCode"
				component={(props: any) => <OrderClientDetails {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/details"
				component={(props: any) => <OrderDetails {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/client/:clientCode/:clientStoreCode/history"
				component={(props: any) => <OrderClientOrdersHistory {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/followUp"
				component={(props: any) => <OrderFollowUpTab {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/pendency"
				component={(props: any) => <OrderPendencyTab {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/storage"
				component={(props: any) => <OrderStorageTab {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/order/:branch/:orderCode/checklist"
				component={(props: any) => <OrderChecklist {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/orders/new-order"
				component={(props: any) => <NewOrderContextEnvolve {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/clients/new-client"
				component={(props: any) => <NewClientContextEnvolve {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/client/:clientCode/:clientStoreCode/attachments"
				component={(props: any) => <ClientAttachments {...props} />}
				module="order_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/cs/users"
				component={(props: any) => (
					<UsersPrpProvider>
						<PrpUsers {...props} />
					</UsersPrpProvider>
				)}
				module="cs"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/cs/logs"
				component={(props: any) => (
					<LogsProvider>
						<Logs {...props} />
					</LogsProvider>
				)}
				module="cs"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/cs/versionApps"
				component={(props: any) => <VersionApps {...props} />}
				module="cs"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/cs/usersJAC"
				component={(props: any) => <UsersJac {...props} />}
				module="cs"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/cs/busca"
				component={(props: any) => <InstallAndSearch {...props} />}
				module="cs"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/installation"
				component={(props: any) => <OrderInstallation {...props} />}
				module="install-and-search"
			/>
			<RouterWrapper
				exact={true}
				path="/service"
				component={(props: any) => <RequestService {...props} />}
				module="install-and-search"
			/>
			<RouterWrapper
				exact={true}
				path="/result"
				component={(props: any) => <Response {...props} />}
				module="install-and-search"
			/>
			<RouterWrapper
				exact={true}
				path="/result-address"
				component={(props: any) => <ResponseByAddress {...props} />}
				module="install-and-search"
			/>
			<RouterWrapper
				exact={true}
				path="/products"
				component={(props: any) => (
					<ProductsProvider>
						<ProductsList {...props} />
					</ProductsProvider>
				)}
				module="products_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/product/:codeProtheus"
				component={(props: any) => (
					<ProductDetailsProvider>
						<ProductDetails {...props} />
					</ProductDetailsProvider>
				)}
				module="products_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/productFamily"
				component={(props: any) => (
					<ProductFamilyProvider>
						<ProductsFamilyList {...props} />
					</ProductFamilyProvider>
				)}
				module="products_management"
				isPrivate
			/>
			<RouterWrapper
				exact={true}
				path="/productFamily/:codeProtheus"
				component={(props: any) => (
					<ProductFamilyDetailsProvider>
						<ProductFamilyDetails {...props} />
					</ProductFamilyDetailsProvider>
				)}
				module="products_management"
				isPrivate
			/>
		</Switch>
	)
}
