import { Transfer } from 'models/Transfer'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { ViewType } from 'utils/Constants'
import TransfersListMobile from './Mobile'
import TransfersListWeb from './Web'
import './styles.scss'

interface Params {
	onItemClick: (transfer: Transfer) => void
}

const TransfersList: React.FC<Params> = ({ onItemClick }) => {
	const { viewType } = useContext(DeviceContextElement)
	return viewType !== ViewType.MOBILE ? (
		<TransfersListWeb {...{ onItemClick }} />
	) : (
		<TransfersListMobile {...{ onItemClick }} />
	)
}

export default TransfersList
