import PinIcon from 'assets/image/Pin.svg'
import EmailIcon from 'assets/image/email.svg'
import WhatsAppIcon from 'assets/image/whatsapp.svg'

import React from 'react'
import './styles.scss'

export interface ButtonProps extends React.ComponentPropsWithoutRef<'button'> {
	title: any
	variant:
		| 'outline'
		| 'standard'
		| 'outline-dark'
		| 'dark'
		| 'cancellation-gradient'
		| 'confirmation-gradient'
		| 'outline-highlight'
		| 'confirmation-solid'
		| 'confirmation-radius'
		| 'whatsApp'
		| 'email'
		| 'search'
	size: 'sm' | 'md' | 'lg'
	icon?: 'e-mail' | 'whatsApp' | 'pin'
	isLoading?: boolean
}

const Button = ({
	variant,
	title,
	size,
	icon,
	isLoading = false,
	...rest
}: ButtonProps) => {
	const handleIcon = (icon?: string) => {
		if (icon) {
			switch (icon) {
				case 'whatsApp':
					return <img src={WhatsAppIcon} alt="whatsApp icon" />
				case 'e-mail':
					return <img src={EmailIcon} alt="e-mail icon" />
				case 'pin':
					return <img src={PinIcon} alt="" className="item-f" />
				default:
					return ''
			}
		}
	}
	return (
		<button
			className={`button-${variant} button-${size}`}
			disabled={isLoading}
			{...rest}
		>
			{handleIcon(icon)}
			{title}
		</button>
	)
}
export default Button
