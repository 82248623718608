import Client from 'models/Client'
import { RequisitionError } from 'models/RequisitionError'
import React, { createContext } from 'react'
import { ClientContextType, ClientContextValue } from './ClientContextType'

export const ClientContextElement =
	createContext<ClientContextType>(ClientContextValue)

const ClientProvider = (props: any) => {
	const onRequestClient = (
		cpfCnpj: string,
		callback: (client?: Client, hasError?: RequisitionError) => void
	) => {}

	const providerValues = () => {
		return { onRequestClient }
	}

	return (
		<ClientContextElement.Provider value={providerValues()}>
			{props.children}
		</ClientContextElement.Provider>
	)
}

export default ClientProvider
