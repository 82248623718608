import styled from 'styled-components'

export const Header = styled.div`
	font-size: 14px;
	line-height: initial;
	font-weight: 700;
	text-align: left;
`

export const Footer = styled.div`
	display: flex;
	column-gap: 20px;
	justify-content: center;
`
export const Title = styled.div`
	font-size: 12px;
	line-height: initial;
	font-weight: 700;
	text-align: left;
	overflow: hidden;
`

export const SerialNumber = styled.div`
	display: flex;
	margin-top: 15px;
	font-size: 12px;
`

export const Description = styled.div`
	padding-right: 5px;
	font-weight: 700;
	text-align: left;
	color: #333333;
`

export const Info = styled.div`
	font-weight: 400;
	font-size: 12px;
	text-align: left;
`

export const ClientNameTitle = styled.div`
	font-weight: 700;
	font-size: 12px;
	display: flex;
`

export const TitleInfo = styled.div`
	font-weight: 700;
	font-size: 12px;
	display: flex;
`

export const Line = styled.div`
	display: flex;
	flex-direction: row;
`

export const ClientName = styled.div`
	font-size: 12px;
`

export const FormError = styled.span`
	font-size: 12px;
	color: red;
`
