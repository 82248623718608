import { utils, write } from 'xlsx'

export function handleDownloadToExcelFile(data: any, filename: string) {
	const worksheet = utils.json_to_sheet(data)
	const workbook = utils.book_new()
	utils.book_append_sheet(workbook, worksheet, 'Sheet1')
	const excelBuffer = write(workbook, {
		bookType: 'xlsx',
		type: 'array'
	})
	const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
	const url = URL.createObjectURL(blob)
	const a = document.createElement('a')
	a.href = url
	a.download = filename
	a.click()
	URL.revokeObjectURL(url)
}
