import $ from 'jquery'
import Client from 'models/Client'
import ClientLending from 'models/ClientLending'
import { EquipDeviation } from 'models/EquipDeviation'
import Equipment from 'models/Equipment'
import OpenedOS from 'models/OpenedOS'
import { ServiceOrderUpdate } from 'models/ServiceOrderUpdate'
import ServiceTypesChefs from 'models/ServiceTypesChefs'
import TechnicalAssists from 'models/TechnicalAssists'
import Technician from 'models/Technician'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import AuthOs from 'module/technicalServices/components/AuthOs'
import ChangeAddress, {
	IChangeAddressForm
} from 'module/technicalServices/components/ChangeAddress'
import Change_OS_Type from 'module/technicalServices/components/Change_OS_Type'
import ChangeTech from 'module/technicalServices/components/Change_Tech'
import ClosingOs from 'module/technicalServices/components/ClosingOS'
import OS_Info from 'module/technicalServices/components/Comp_OSPage/OS_Info'
import Timeline from 'module/technicalServices/components/Comp_OSPage/Timeline'
import FollowUp from 'module/technicalServices/components/FollowUp'
import InternalObs from 'module/technicalServices/components/InternalObs'
import DateVisit from 'module/technicalServices/components/NewVisit'
import OpenOS from 'module/technicalServices/components/OpenOS'
import PartRequest from 'module/technicalServices/components/PartRequest'
import ServiceOrderEquipEditModal from 'module/technicalServices/components/ServiceOrderEquipEditModal'
import ResendEmail from 'module/technicalServices/components/modalResendEmail'
import TakeOS from 'module/technicalServices/components/modalTakeOS'
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
	getOpenOs,
	isTokenValid,
	selectAllServicesOrdersByCode
} from 'services'
import {
	getClientInfo,
	getClientLendingByClientId,
	takeOs,
	updateServiceOrder,
	userInfo
} from 'services/index'
import Button from 'shared/components/Button'
import { store } from 'store'
import { signOut } from 'store/modules/auth/actions'
import { setUrlOrders } from 'store/modules/serviceOrders/actions'
import { ViewType } from 'utils/Constants'
import { clearNumericField } from 'utils/Pipe'
import { Opt, Section } from './styles'
import './styles.css'
import './styles.scss'

type TParams = { history: string; orderCode: string }

const ServicePage: React.FC<RouteComponentProps<TParams>> = ({
	history,
	match
}: RouteComponentProps<TParams>) => {
	const { viewType } = useContext(DeviceContextElement)
	const [technicalAssists, setTechnicalAssists] = useState<TechnicalAssists[]>()
	const [equipment, setEquipment] = useState<Equipment>()
	const [deviation, setDeviation] = useState<EquipDeviation>()
	const [serviceOrderNumber, setServiceOrderNumber] = useState<string>()
	const [osProtheus, setOsProtheus] = useState<string>()
	const [technician, setTechnician] = useState<Technician>()
	const [addressOS, setAddressOS] = useState<string>()
	const [appointmentDate, setAppointmentDate] = useState<string>()
	const [clientInfo, setClientInfo] = useState<Client>()
	const [type, setType] = useState<string>('')
	const [contato, setContato] = useState<string>()
	const [email, setEmail] = useState<string>()
	const [phone, setPhone] = useState<string>()
	const [state, setState] = useState<string>()
	const [lastTechAddress, setLastTechAddress] = useState<string>()
	const [lastTechID, setLastTechID] = useState<string>()
	const [lastAssistId, setLastAssistId] = useState<any>()
	const [serviceOrderId, setServiceOrderId] = useState<number>(0)
	const [obs, setObs] = useState<string>('')
	const [origin, setOrigin] = useState<string>('')
	const [followUpEnabled, setFollowUpEnabled] = useState<boolean>(false)
	const [showResendEmail, setShowResendEmail] = useState<boolean>(false)
	const [observationEnabled, setObservationEnabled] = useState<boolean>(false)
	const [closingOsEnabled, setClosingOsEnabled] = useState<boolean>(false)
	const [authEnabled, setAuthEnabled] = useState<boolean>(false)
	const [enablePartModal, setEnablePartModal] = useState<boolean>(false)
	const [ddd, setDdd] = useState<string>('')
	const [defect, setDefect] = useState<string>('')
	const [firstUser, setFirstUser] = useState<any>()
	const [lastAppointmentId, setLastAppointmentId] = useState<number>(0)
	const [technicalAssistsAuthType, setTechnicalAssistsAuthType] = useState<
		TechnicalAssists[]
	>([])
	const [generalBlock, setGeneralBlock] = useState<boolean>(false)
	const [technicalAssistCount, setTechnicalAssistCount] = useState<number>(0)
	const [financialBlock, setFinancialBlock] = useState<boolean>(false)
	const [openOS, setOpenOS] = useState<boolean>(false)
	const [showChangeAddressModal, setShowChangeAddressModal] =
		useState<boolean>(false)
	const [clientLendingId, setClientLendingId] = useState<string | null>(null)
	const [clientType, setClientType] = useState<string>()
	const [protocolos, setProtocolos] = useState<OpenedOS[]>()
	const [serial, setSerial] = useState<string>()
	const [clientLending, setClientLending] = useState<ClientLending>({
		name: '',
		CNPJ: '',
		street: '',
		city: '',
		state: '',
		neighborhood: '',
		zipCode: ''
	})
	const [deletedOs, setDeletedOs] = useState<boolean>(false)
	const [showModalTakeOS, setShowModalTakeOS] = useState<boolean>(false)
	const [showChangeOSType, setShowChangeOSType] = useState<boolean>(false)
	const [showOpenServicesForEquip, setShowOpenServicesForEquip] =
		useState<boolean>(false)
	const [showEquipEditModal, setShowEquipEditModal] = useState<boolean>(false)

	const dispatch = useDispatch()

	const [userFieldControl, setUserFieldControl] = useState<boolean>(false)

	useEffect(() => {
		const userId = store.getState().user.profile
		userInfo(userId).then((response) => {
			response.data.id_field_control
				? setUserFieldControl(true)
				: setUserFieldControl(false)
		})
	}, [])

	useEffect(() => {
		const callApi = (code: string) => {
			selectAllServicesOrdersByCode(code)
				.then((response) => {
					const { data } = response
					const { instanceOS, client, deviation } = data
					setDeviation(deviation)
					const {
						technicalAssistances,
						equipments,
						service_order,
						os_protheus,
						address,
						neighborhood,
						city,
						state_sigla,
						type,
						contato,
						email,
						phone,
						ddd,
						originOS,
						id,
						zip_code,
						defect,
						deleted_at
					} = instanceOS
					setDeletedOs(deleted_at !== null)
					setDefect(defect)
					setTechnicalAssistCount(
						data?.instanceOS?.technicalAssistances?.length
					)
					const addressOS = `${address}, ${neighborhood}, ${city} - ${state_sigla}, ${zip_code}`
					const index = technicalAssistances?.length - 1
					const lastAppointmentDate =
						technicalAssistances[index]?.appointment_date
					setTechnician(technicalAssistances[index].technician)
					setLastAssistId(technicalAssistances[index].id)
					const aux = technicalAssistances.sort((a, b) =>
						a.id < b.id ? -1 : 1
					)
					setTechnicalAssists(aux)
					setFirstUser(
						technicalAssistances[0]?.user !== undefined
							? technicalAssistances[0].user.name +
									' ' +
									technicalAssistances[0].user.surname
							: ''
					)
					setState(state_sigla)
					setEquipment({ ...equipments, legend: deviation?.legend })
					setSerial(equipments.serial_number)
					technicalAssistances.map((item) => {
						if (
							item.step_type === 'new_visit' &&
							Number(item.id) > lastAppointmentId
						) {
							setLastAppointmentId(Number(item.id))
						}
					})

					setOrigin(originOS)
					setServiceOrderNumber(service_order)
					setOsProtheus(os_protheus)
					setAddressOS(addressOS)
					setAppointmentDate(lastAppointmentDate)
					setType(type)

					setClientInfo({ ...client, contact: contato, email, phone })

					setContato(contato)
					setEmail(email)
					setPhone(phone)
					setDdd(ddd)
					setObs(instanceOS.obs || '')
					setServiceOrderId(id)
					const authTechAssists = aux.filter(
						(tech) => tech.step_type === 'internal_auth'
					)
					setTechnicalAssistsAuthType(
						authTechAssists.sort((a, b) => (a.id < b.id ? 1 : -1))
					)
					const { id_client_lending } = equipments
					if (id_client_lending) {
						setClientLendingId(id_client_lending)
						getClientLendingByClientId(Number(id_client_lending))
							.then((getClientLendingResponse) => {
								const data = getClientLendingResponse.data[0]
								const clientLending: ClientLending = {
									name: data.nome_fantasia,
									CNPJ: data.cpfCnpj,
									street: data.rua,
									neighborhood: data.bairro,
									city: data.cidade,
									state: data.estado,
									zipCode: data.zip_code
								}
								setClientLending(clientLending)
							})
							.catch((getClientLendingError) => {
								console.log(getClientLendingError)
							})
					}
				})
				.catch((error) => {
					if (error.message === 'Network Error') {
						history.push('/error/500') // Network Error
					} else if (error?.response?.status === 404) {
						history.push('/error/404')
					} else if (error?.response?.status === 401) {
						isTokenValid(store.getState().auth.token)
							.then((resp) => {
								if (resp?.data?.exists === true) {
									history.push('/home')
								} else {
									dispatch(signOut())
									history.push('/')
								}
							})
							.catch((err) => {
								dispatch(signOut())
								history.push('/')
							})
					} else {
						history.push('/error/500')
					}
				})
		}
		const { orderCode } = match.params
		callApi(orderCode)
	}, [history, match])

	useEffect(() => {
		if (clientInfo) {
			// Verifica se o cliente está bloqueado
			getClientInfo(clientInfo.cod_protheus)
				.then((resp) => {
					const gStatus = resp.data[0].general_status
					const fStatus = resp.data[0].financial_status
					setGeneralBlock(gStatus === '1' ? true : false)
					setFinancialBlock(fStatus === 'S' ? true : false)
					setClientType(resp.data[0].client_type)
				})
				.catch((error) => {
					console.log('Erro ao verificar bloqueio do cliente: ', error)
					toast.error('Erro ao verificar bloqueio do cliente.')
				})
		}
	}, [clientInfo])

	const onSubmit = (data: any, form: string) => {
		let os_code = ''
		serviceOrderNumber ? (os_code = serviceOrderNumber) : (os_code = '')

		if (form == 'takeOs') {
			data.userId = store.getState().user.profile
			data.os_code = os_code
			takeOs(data)
				.then((resp) => {
					if (resp.data.message === 'OS successfully attributed') {
						setType(data.type)
						setFirstUser(
							store.getState().user.name + ' ' + store.getState().user.surname
						)
						toast.success(
							'Tipo de serviço e responsável atualizados com sucesso.'
						)
					} else if (resp.data.message === 'OS already has a owner') {
						toast.error(`OS já foi atribuída para outro usuário.`)
					}
				})
				.catch(() => {
					toast.error('Erro ao atribuir responsável para o chamado.')
				})
		} else {
			data.serviceOrderId = serviceOrderId
			updateServiceOrder(data)
				.then((resp) => {
					const { serviceOrderUpdated } = resp.data
					setEquipment(serviceOrderUpdated.equipments)
					setClientInfo({
						...serviceOrderUpdated.client,
						contact: serviceOrderUpdated.contato,
						email: serviceOrderUpdated.email,
						phone: `(${serviceOrderUpdated.ddd}) ${serviceOrderUpdated.phone}`
					})
					setSerial(serviceOrderUpdated.equipments.serial_number)
					setType(serviceOrderUpdated.type)
					setEmail(serviceOrderUpdated.email)
					setContato(serviceOrderUpdated.contato)
					setDdd(serviceOrderUpdated.ddd)
					setPhone(serviceOrderUpdated.phone)
					setAddressOS(
						serviceOrderUpdated.address +
							', ' +
							serviceOrderUpdated.neighborhood +
							', ' +
							serviceOrderUpdated.city +
							'-' +
							serviceOrderUpdated.state_sigla
					)
					toast.success('Atendimento atualizado com sucesso.')
					window.location.reload()
				})
				.catch(() => {
					toast.error('Erro ao atualizar atendimento.')
				})
		}
	}

	const handleChanges = (type: any, changes: any, worked: boolean) => {
		if (worked) {
			if (type == 'newVisit' && technicalAssists) {
				technicalAssists.push(changes)
				setTechnicalAssists(technicalAssists)
				setTechnician(technicalAssists[technicalAssists?.length - 1].technician)
			} else if (type == 'changeTech' && technicalAssists) {
				technicalAssists[technicalAssists?.length - 1].technician = changes
				setTechnician(changes)
				setTechnicalAssists(technicalAssists)
				toast.success('Tecnico alterado com sucesso!')
			} else if (type == 'follow-up') {
				changes.assistanceSaved.files = changes.techFiles
				technicalAssists?.push(changes.assistanceSaved)
				setTechnicalAssists(technicalAssists)
				toast.success('Follow-up adicionado com sucesso!')
				setFollowUpEnabled(false)
			} else if (type == 'resend-email') {
				toast.success(
					'O e-mail do atendimento será reenviado para o atendente responsável e para o técnico!'
				)
			} else if (type == 'internalObs') {
				changes.assistanceSaved.files = changes.techFiles
				technicalAssists?.push(changes.assistanceSaved)
				setTechnicalAssists(technicalAssists)
				toast.success('Observação interna adicionada com sucesso!')
				setObservationEnabled(false)
			} else if (type == 'closingOs') {
				changes.assistanceSaved.files = changes.techFiles
				technicalAssists?.push(changes.assistanceSaved)
				setTechnicalAssists(technicalAssists)
				toast.success('Encerramento de atendimento adicionado com sucesso!')
				setClosingOsEnabled(false)
			} else if (type == 'quote') {
				changes.assistanceSaved.files = changes.techFiles
				technicalAssists?.push(changes.assistanceSaved)
				setTechnicalAssists(technicalAssists)
				toast.success('Solicitação de peça realizada com sucesso!')
				setEnablePartModal(false)
			} else if (type == 'internal_auth') {
				changes.assistanceSaved.files = changes.techFiles
				technicalAssistsAuthType?.push(changes.assistanceSaved)
				technicalAssists?.push(changes.assistanceSaved)
				setTechnicalAssists(technicalAssists)
				setTechnicalAssistsAuthType(
					technicalAssistsAuthType.sort((a, b) => (a.id < b.id ? 1 : -1))
				)
				toast.success('Autorização adicionada com sucesso!')
			}
		} else {
			setFollowUpEnabled(false)
			setObservationEnabled(false)
			setClosingOsEnabled(false)
			setEnablePartModal(false)
			if (type === 'newVisit' && changes === 'none') {
				toast.error(
					'Já existe uma visita agendada para este dia, utilize o Alterar técnico em caso de novo técnico necessário para realizar a visita em questão ou agende para uma outra data.'
				)
			} else if (type === 'newVisit' && changes === 'user not found') {
				toast.error(
					'Seu usuário não possui integração com a Field Control, por gentileza entre em contato com a Digital Endeavors através do chat.'
				)
			} else {
				toast.error('fail')
			}
		}
	}

	const handleCancel = () => {
		setFollowUpEnabled(false)
		setObservationEnabled(false)
		setClosingOsEnabled(false)
		setAuthEnabled(false)
		setEnablePartModal(false)
	}

	const meetServiceOrder = () => {
		getOpenOs(
			{ serialNumber: serial, clientCode: clientInfo?.cod_protheus },
			serviceOrderNumber
		).then((resp) => {
			const { data } = resp
			if (resp.status === 200 && data?.os?.length > 0) {
				setProtocolos(data.os)
				setOpenOS(true)
				setShowOpenServicesForEquip(true)
			} else {
				setShowModalTakeOS(true)
			}
		})
	}

	const onContinueOS = () => {
		setOpenOS(false)
		setShowOpenServicesForEquip(false)
		setShowModalTakeOS(true)
	}

	const onCloseServiceOrderList = () => {
		setOpenOS(false)
		setShowOpenServicesForEquip(false)
	}

	const onTakeOs = (type: string, defect: string) => {
		const updated = { type, defect }
		onSubmit(updated, 'takeOs')
	}

	const onChangeOsType = (type: string, defect: string) => {
		const updated = { type, defect }
		onSubmit(updated, 'updateType')
	}

	const onUpdate = (updatedPayload: ServiceOrderUpdate) => {
		onEquipEditModalClose()
		onSubmit(updatedPayload, 'updateEquip')
	}

	const showEquipEditModalClick = () => {
		$('.actionOpt').css('display', 'none')
		setShowEquipEditModal(true)
	}

	const onEquipEditModalClose = () => {
		setShowEquipEditModal(false)
	}

	const handleEquipModalContent = () => {
		return (
			showEquipEditModal &&
			equipment &&
			clientInfo && (
				<ServiceOrderEquipEditModal
					{...{
						equipment: equipment,
						onClose: onEquipEditModalClose,
						client: clientInfo,
						onUpdate,
						gBlock: generalBlock,
						fBlock: financialBlock,
						deviation
					}}
				/>
			)
		)
	}

	const handleOpenServiceList = () => {
		return showOpenServicesForEquip && protocolos ? (
			<OpenOS
				show={showOpenServicesForEquip}
				protocolos={protocolos}
				onContinue={onContinueOS}
				onClose={onCloseServiceOrderList}
			/>
		) : (
			<></>
		)
	}

	const onUpdateAddress = (
		data: IChangeAddressForm,
		addressDefault: boolean
	) => {
		const updated = {
			address: `${data.street}, ${data.number}`,
			zip_code: clearNumericField(data.zip_code),
			neighborhood: data.neighborhood,
			city: data.city,
			state_sigla: data.state_sigla,
			complement: data.complement,
			addressDefault,
			clientLendingId
		}
		onSubmit(updated, 'update')
	}

	const partRequestIsActive = (type: string) => {
		if (
			type === 'INSTALACAO' ||
			type === 'OUTRAS SOLICITACOES' ||
			type === 'PRE-INSTALACAO' ||
			type === 'INSTALACAO REVENDA'
		) {
			$('.partRequest').css('display', 'none')
		} else $('.partRequest').css('display', 'block')
	}

	const serviceOrderPageActionContain = () => {
		if (technicalAssists?.length)
			return (
				!deletedOs &&
				!generalBlock &&
				(firstUser ? (
					<div
						className="service-order-page-action-button"
						onClick={() => {
							if ($('.actionOpt').css('display') == 'none') {
								$('.actionOpt').css('display', 'block')
							} else {
								$('.actionOpt').css('display', 'none')
							}
						}}
					>
						<div>+</div>
					</div>
				) : (
					<Button
						style={{ width: 'auto', position: 'absolute', right: '0px' }}
						size="lg"
						title="ATENDER OS"
						variant="confirmation-solid"
						onClick={meetServiceOrder}
					/>
				))
			)
	}

	const mainContent = () => {
		partRequestIsActive(type)
		return (
			<>
				<div className="service-order-page-contain">
					<div className="service-order-page-contain-header">
						<OS_Info
							client={clientInfo}
							technician={technician}
							equipment={equipment}
							serviceOrderNumber={serviceOrderNumber}
							addressOS={addressOS}
							type={type}
							lastAppointmentDate={appointmentDate}
							contato={contato}
							email={email}
							telephone={phone}
							ddd={ddd}
							osProtheus={osProtheus}
							deviation={deviation}
							generalBlock={generalBlock}
							financialBlock={financialBlock}
							clientLending={clientLending}
							clientType={clientType}
							deletedOs={deletedOs}
						/>
					</div>
					<div className="service-order-page-contain-body">
						{!!technicalAssists && (
							<Timeline
								firstUser={firstUser ? firstUser : null}
								obs={obs}
								originOS={origin}
								type={type}
								services={technicalAssists}
								equipment={equipment}
								client={clientInfo}
								lastTechAddress={lastTechAddress}
								lastTechID={lastTechID}
								defect={defect}
							/>
						)}
					</div>
				</div>

				{serviceOrderPageActionContain()}

				<div className="actionOpt">
					{!financialBlock && (
						<Opt
							className="Opt"
							onClick={() => {
								if (!userFieldControl) {
									$('.actionOpt').css('display', 'none')
									toast.error(
										'Usuário sem integração, favor contatar administrador do sistema.'
									)
								} else {
									$('.newVisit').toggle()
									$('body').addClass('modal-open')
									$('.actionOpt').css('display', 'none')
								}
							}}
						>
							Agendar nova visita
						</Opt>
					)}
					<Opt
						className="Opt"
						onClick={() => {
							$('.followUp').toggle()
							$('body').addClass('modal-open')
							$('.actionOpt').css('display', 'none')
							setFollowUpEnabled(true)
						}}
					>
						Follow-up
					</Opt>
					<Opt
						className="Opt"
						onClick={() => {
							$('.internalObs').toggle()
							$('body').addClass('modal-open')
							$('.actionOpt').css('display', 'none')
							setObservationEnabled(true)
						}}
					>
						Observação Interna
					</Opt>
					<Opt className="Opt" onClick={() => setShowChangeOSType(true)}>
						Editar tipo OS
					</Opt>
					<Opt
						className="Opt"
						onClick={() => {
							$('.closingOs').toggle()
							$('body').addClass('modal-open')
							$('.actionOpt').css('display', 'none')
							setClosingOsEnabled(true)
						}}
					>
						Encerrar OS
					</Opt>
					{technician ? (
						<Opt
							className="Opt"
							onClick={() => {
								$('.changeTech').toggle()
								$('body').addClass('modal-open')
								$('.actionOpt').css('display', 'none')
							}}
						>
							Alterar técnico
						</Opt>
					) : (
						<div></div>
					)}
					<Opt className="Opt" onClick={showEquipEditModalClick}>
						Alterar equipamento
					</Opt>
					<Opt
						className="Opt"
						onClick={() => {
							setShowChangeAddressModal(true)
							$('.actionOpt').css('display', 'none')
						}}
					>
						Alterar endereço
					</Opt>
					{!ServiceTypesChefs.find((e) => e.value === type) && (
						<Opt
							className="Opt partRequest"
							onClick={() => {
								if (viewType !== ViewType.MOBILE) {
									setEnablePartModal(true)
									$('.newPart').toggle()
									$('.actionOpt').css('display', 'none')
								} else {
									$('.actionOpt').css('display', 'none')
									toast.error(
										'Pedidos de peças não podem ser acessados via mobile'
									)
								}
							}}
						>
							Solicitação de peça
						</Opt>
					)}
					<Opt
						className="Opt"
						onClick={() => {
							setShowResendEmail(true)
							$('.actionOpt').css('display', 'none')
						}}
					>
						Reenviar e-mail Técnico
					</Opt>
					<Opt
						className="Opt"
						onClick={() => {
							$('.newAuth').toggle()
							$('body').addClass('modal-open')
							$('.actionOpt').css('display', 'none')
							setAuthEnabled(true)
						}}
					>
						Autorizações
					</Opt>
				</div>
			</>
		)
	}

	return (
		<>
			{viewType !== ViewType.MOBILE && (
				<NavLink
					className="os-detail-back-button"
					to="/os"
					onClick={() => dispatch(setUrlOrders('os'))}
				>
					&lt; Voltar
				</NavLink>
			)}
			<Section>
				{mainContent()}
				{showChangeOSType && (
					<Change_OS_Type
						onClick={onChangeOsType}
						client={clientInfo}
						equipment={equipment}
						infoOs={{ defect: defect, type: type }}
						onClose={() => setShowChangeOSType(false)}
					/>
				)}
				<FollowUp
					show={followUpEnabled}
					handleCancel={handleCancel}
					handleChanges={handleChanges}
					client={clientInfo}
					equipment={equipment}
					serviceOrderId={serviceOrderId}
				/>
				{showModalTakeOS && technicalAssists && (
					<TakeOS
						type={type}
						obs={technicalAssists[0].message}
						origin_os={origin}
						client={clientInfo}
						equipment={equipment}
						onClick={onTakeOs}
						onClose={() => setShowModalTakeOS(false)}
					/>
				)}
				<ResendEmail
					show={!!technicalAssists && showResendEmail}
					onClose={setShowResendEmail}
					client={clientInfo}
					equipment={equipment}
					techAssistId={lastAppointmentId}
				/>
				<InternalObs
					show={observationEnabled}
					handleCancel={handleCancel}
					handleChanges={handleChanges}
					client={clientInfo}
					equipment={equipment}
					serviceOrderId={serviceOrderId}
					count={technicalAssistCount}
				/>
				<ClosingOs
					show={closingOsEnabled}
					handleCancel={handleCancel}
					handleChanges={handleChanges}
					client={clientInfo}
					equipment={equipment}
					serviceOrderId={serviceOrderId}
				/>
				<div className="modal newAuth">
					{authEnabled && (
						<AuthOs
							handleCancel={handleCancel}
							handleChanges={handleChanges}
							client={clientInfo}
							equipment={equipment}
							serviceOrderId={serviceOrderId}
							authTechAssists={technicalAssistsAuthType}
						/>
					)}
				</div>
				<ChangeAddress
					show={showChangeAddressModal}
					onClose={setShowChangeAddressModal}
					client={clientInfo}
					equipment={equipment}
					onClick={onUpdateAddress}
				/>
				<div className="modal newVisit" style={{ display: 'none' }}>
					<DateVisit
						handleChanges={handleChanges}
						serviceOrderNumber={serviceOrderNumber}
						state_sigla={state}
						technician={technician}
						addressOS={addressOS}
						client={clientInfo}
						equipment={equipment}
						type={type}
						deviation={deviation}
					/>
				</div>
				<div className="modal changeTech" style={{ display: 'none' }}>
					{technician && (
						<ChangeTech
							type={type}
							assist_id={lastAssistId}
							appointmentDate={appointmentDate}
							handleChanges={handleChanges}
							serviceOrderNumber={serviceOrderNumber}
							state_sigla={state}
							technician={technician}
							addressOS={addressOS}
							client={clientInfo}
							equipment={equipment}
							deviation={deviation}
						/>
					)}
				</div>
				{enablePartModal && (
					<div className="modal newPart" style={{ display: 'block' }}>
						<PartRequest
							equipment={equipment}
							client={clientInfo}
							osProtheus={osProtheus}
							serviceOrderNumber={serviceOrderNumber}
							handleCancel={handleCancel}
							handleChanges={handleChanges}
							type={type}
						/>
					</div>
				)}
				{handleOpenServiceList()}
				{handleEquipModalContent()}
			</Section>
		</>
	)
}

export default ServicePage
