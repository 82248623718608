export const printConsoleError = (message: any) => {
	if (process.env.NODE_ENV === 'development') {
		console.error(message)
	}
}

export const printConsoleDebug = (message: any, header = 'Debug') => {
	if (process.env.NODE_ENV === 'development') {
		console.debug(header, message)
	}
}

export const printConsoleLog = (message: any, header = 'Log') => {
	if (process.env.NODE_ENV === 'development') {
		console.log(header, message)
	}
}
