import $ from 'jquery'
import TechnicalAssists from 'models/TechnicalAssists'
import React from 'react'
import { toast } from 'react-toastify'
import { postPartRequest } from 'services/index'
import { store } from 'store'
import { createData } from './functions'
import {
	Col1,
	Col2,
	Col3,
	Col4,
	Col5,
	Col6,
	Col7,
	Col8,
	Col9,
	Div,
	Div_Info,
	Info_Summary,
	ItemDescri,
	ItensResumo,
	ItensResumo2,
	ItensTable,
	Resumo,
	Title_Summary
} from './styles'
import './styles.css'

const retOtherAddress = (html: any) => {
	if (html !== '') {
		return $(html)[1].innerHTML?.split('value="')[1]?.split('">')[0]
	}
}

const retDescription = (html: any) => {
	if (html !== '' && html !== ' - Selecione') {
		if (html.includes(' - ')) {
			return (
				html?.split(' - ')[1]?.charAt(0)?.toUpperCase() +
				html?.split(' - ')[1]?.slice(1)?.toLowerCase()
			)
		}
		return html?.charAt(0)?.toUpperCase() + html?.slice(1)?.toLowerCase()
	}
}

const retCod = (html: any) => {
	if (html !== '') {
		return html?.split(' - ')[0]?.charAt(0) + html?.split(' - ')[0]?.slice(1)
	} else {
		return ''
	}
}

let address: any
let rows: any[]

type TechAssist = {
	assistanceSaved: TechnicalAssists
	techFiles: File[]
}

interface Props {
	handleChanges: (type: any, changes: any, worked: boolean) => void
}

const Summary: React.FC<Props> = ({ handleChanges }) => {
	const transp = retDescription($('#transp').val())

	const typeService = retDescription($('#tpService').val())

	const paymentType = retDescription($('#condpag').val())

	const tabPrice = retDescription($('#tabprice').val())

	const invoiceMsg = $('#obs').val()

	const user = store.getState().user

	let itens = 0
	let codCli = ''

	if ($('#tech-address').prop('checked')) {
		address = document.getElementById('tech-address-val')?.innerHTML
		codCli = $('#lastTechID')[0].innerHTML
	} else if ($('#main-address').prop('checked')) {
		address = document.getElementById('main-address-val')?.innerHTML
		codCli = $('#codProtheus')[0].innerHTML
	} else if ($('#other-address').prop('checked')) {
		address =
			retOtherAddress(`.street`) +
			',' +
			retOtherAddress(`.number`) +
			' - ' +
			retOtherAddress(`.city`) +
			' - ' +
			retOtherAddress(`.state`)
	}

	const retItens = (html: any) => {
		return document.getElementById(html)?.innerHTML
	}

	rows = []
	rows?.pop()

	while (itens < 99) {
		if ($('#cod' + itens)[0]?.textContent !== undefined) {
			if (rows?.length >= 1) {
				rows.push(
					createData(
						retItens('cod' + itens),
						retItens('descri' + itens),
						retItens('quant' + itens),
						retItens('price' + itens),
						retItens('armaz' + itens),
						retItens('tpoper' + itens),
						retItens('desc' + itens),
						retItens('tot' + itens)
					)
				)
			} else {
				rows = [
					createData(
						retItens('cod' + itens),
						retItens('descri' + itens),
						retItens('quant' + itens),
						retItens('price' + itens),
						retItens('armaz' + itens),
						retItens('tpoper' + itens),
						retItens('desc' + itens),
						retItens('tot' + itens)
					)
				]
			}
		}

		itens += 1
	}

	const validForm = () => {
		if (
			address == undefined ||
			transp == undefined ||
			typeService == undefined ||
			paymentType == undefined ||
			$('#tpPart').val()?.toString().trim() == ''
		) {
			toast.error('Preencha as informações gerais do pedido.')
			return false
		} else if (rows?.length == 0) {
			toast.error('Preencha os itens.')
			return false
		} else {
			return true
		}
	}

	function refresh() {
		setTimeout(function () {
			window.location.reload()
		}, 2500)
	}

	const savePart = () => {
		if (validForm() === true) {
			const cnpj = $('#cnpj')[0]
				.innerHTML.replace('-', '')
				.replace('/', '')
				.replace('.', '')
				.replace('.', '')
			const type = $('#tpPart').val()
			const transp = retCod($('#transp').val())
			const typeService = retCod($('#tpService').val())
			const paymentType = retCod($('#condpag').val())
			const tabPrice = retCod($('#tabprice').val())
			const serial_number = $('#serial_number')[0].innerHTML
			const order_number = $('#order_number')[0].innerHTML
			const freight = $('#frete').val()
			const filial = $('#filial').val()
			const user_id = user.profile

			const dataRequest = {
				partOrder: {
					cpfCnpj: cnpj,
					order: order_number,
					client_request: codCli,
					carrier_code: transp,
					service_type: typeService,
					payment_type: paymentType,
					tab_price: tabPrice,
					invoice_message: invoiceMsg,
					filial,
					user_id,
					serial_number,
					freight,
					address,
					type
				},
				items: rows.map((item) => {
					return {
						orders_quotation_id: 0,
						product_code: item.cod.trim(),
						descri: item.descri.trim(),
						quant: Number(item.qtd.trim()),
						storehouse: item.armaz.trim(),
						type_oper: item.tpoper.trim(),
						price: Number(item.price.trim().replace('R$', '')),
						total_price: Number(item.tot.trim().replace('R$', '')),
						discount: Number(item.desc.trim().replace('%', ''))
					}
				})
			}

			postPartRequest(dataRequest)
				.then((resp) => {
					if (resp.status === 200) {
						resp.data.assistanceSaved.user = user
						const newData: TechAssist = {
							assistanceSaved: resp.data.assistanceSaved,
							techFiles: []
						}

						handleChanges('quote', newData, true)
					}
				})
				.catch((err) => {
					handleChanges('quote', 'none', false)
				})

			$(`.newPart`).css('display', 'none')
			refresh()
		}
	}

	return (
		<>
			<Div_Info>
				<Resumo>
					<Title_Summary>Endereço de entrega:</Title_Summary>
					<Info_Summary>{address}</Info_Summary>

					<Title_Summary>Transportadora:</Title_Summary>
					<Info_Summary>{transp}</Info_Summary>

					<Title_Summary>Tipo de serviço do pedido:</Title_Summary>
					<Info_Summary>{typeService}</Info_Summary>

					<Title_Summary>Forma de pagamento:</Title_Summary>
					<Info_Summary>{paymentType}</Info_Summary>

					<Title_Summary>Tabela de Preço:</Title_Summary>
					<Info_Summary>{tabPrice}</Info_Summary>

					<Title_Summary>Mensagem para a nota fiscal:</Title_Summary>
					<Info_Summary>{invoiceMsg}</Info_Summary>

					<ItemDescri>Itens:</ItemDescri>

					<ItensTable>
						<Col1>Código</Col1>
						<Col2>Qnt.</Col2>
						<Col3>Armaz.</Col3>
						<Col4>TO</Col4>
						<Col5>Descrição</Col5>
						<Col6>Desc</Col6>
						<Col7>Preço</Col7>
						<Col8>Total</Col8>
						<Col9></Col9>
					</ItensTable>

					<ItensResumo2>
						{rows?.map((row, index) => (
							<ItensResumo key={index}>
								<Col1>{row.cod}</Col1>
								<Col2>{row.qtd.trim()}</Col2>
								<Col3>{row.armaz.trim()}</Col3>
								<Col4>{row.tpoper.trim()}</Col4>
								<Col5>{row.descri?.substring(0, 20)}</Col5>
								<Col6>{row.desc.trim()}</Col6>
								<Col7>{row.price}</Col7>
								<Col8>{row.tot}</Col8>
								<Col9></Col9>
							</ItensResumo>
						))}
					</ItensResumo2>

					<ItensTable>
						<Col1></Col1>
						<Col2></Col2>
						<Col3></Col3>
						<Col4></Col4>
						<Col5></Col5>
						<Col6></Col6>
						<Col7>TOTAL</Col7>
						<Col8>{document.getElementById('totfrete')?.innerHTML}</Col8>
						<Col9></Col9>
					</ItensTable>

					<div className="order-button---line_ ">
						<Div
							className="button-3_ w-button_"
							onClick={() => {
								savePart()
							}}
						>
							Criar pedido
						</Div>
					</div>
				</Resumo>
			</Div_Info>
		</>
	)
}
export default Summary
