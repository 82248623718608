import React, { useEffect, useState } from 'react'
import { StepItem, TransferSteps } from 'utils/Constants'
import StepProgressItem from './StepProgressIItem'
import './styles.scss'

interface Props {
	startStep: number
	actualStep: number
	stepList: StepItem[]
	onStepChange?: (step: number) => void
}

const StepProgress: React.FC<Props> = ({
	startStep,
	actualStep,
	stepList,
	onStepChange
}) => {
	const [step, setStep] = useState<number>(startStep)

	useEffect(() => {
		if (onStepChange) {
			onStepChange(step)
		}
	}, [step])

	const goToStep = (newStep: TransferSteps) => {
		setStep(newStep)
	}

	const stepsContent = () => {
		return stepList.map((stepItem: StepItem, index: number) => {
			const position = index
			const isStart = position === 0
			const isFinish = position === stepList?.length - 1
			return (
				<StepProgressItem
					{...{
						position,
						isStart,
						isFinish,
						stepItem,
						onStepClick: goToStep,
						actualStep
					}}
					key={index}
				/>
			)
		})
	}

	return <div className="step-progress-content">{stepsContent()}</div>
}

export default StepProgress
