import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import { RequisitionError } from 'models/RequisitionError'
import ClientListItem from 'module/orderManagement/models/ClientListItem'
import { getClients } from 'module/orderManagement/services'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { history } from 'routes/history'
import { store } from 'store'
import { setClientsParams } from 'store/modules/orderManagement/actions'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'
import { ClientContextType, ClientContextValue } from './ClientContextType'

export const ClientContextElement =
	createContext<ClientContextType>(ClientContextValue)
const defaultParams = defaultListParams(
	store.getState().orderManagement.clientsMenu.listClientParams,
	false
)

const OrderProvider = (props: any) => {
	const [clientList, setClientList] = useState<ClientListItem[]>([])
	const [isLoading, setLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<RequisitionError>()
	const listParams = useRef<ListParams>(defaultParams)

	const filtersParams: FilterData =
		store.getState().orderManagement.clientsMenu.filterSelected

	const dispatch = useDispatch()

	useEffect(() => {
		requestClients()
	}, [])

	const requestClients = () => {
		setLoading(true)
		const itemFilter = store.getState().orderManagement.clientsMenu
			.filterSelected?.id
			? store.getState().orderManagement.clientsMenu.filterSelected
			: listParams.current.itemFilter
		const { currentPage, pageLimit, searchValue } = { ...listParams.current }
		const offset = (currentPage - 1) * pageLimit
		getClients(
			pageLimit,
			offset,
			searchValue,
			itemFilter?.financialStatus,
			itemFilter?.federatedState,
			itemFilter?.salesPersonCode,
			itemFilter?.initialDate,
			itemFilter?.finalDate
		)
			.then((res) => {
				const { count, clients } = res.data.response
				updateParams(Math.ceil(count / pageLimit), 'count')
				setClientList(clients)
				setLoading(false)
			})
			.catch((err) => {
				const { error } = err.request.response
				error?.code === 'AUTHORIZATION_REQUIRED' && history.push('/home')
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				clearParams(true)
				setHasError(reportError)
				setLoading(false)
			})
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(setClientsParams(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(setClientsParams(listParams.current))
	}

	const onUpdateList = () => {
		requestClients()
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		requestClients()
	}

	const onLimitChange = (newLimit: number) => {
		updateParams(newLimit, 'limit')
		clearParams()
		requestClients()
	}

	const onSearchClient = (search: string) => {
		updateParams(search, 'search')
		clearParams()
		requestClients()
	}

	const onFilterChange = (newFilter: FilterData) => {
		updateParams(newFilter, 'filter')
		clearParams()
		requestClients()
	}

	const providerValues = () => {
		return {
			clientList,
			isLoading,
			hasError,
			...listParams.current,
			onUpdateList,
			onPageChange,
			onLimitChange,
			onSearchClient,
			onFilterChange,
			filtersParams
		}
	}

	return (
		<ClientContextElement.Provider value={providerValues()}>
			{props.children}
		</ClientContextElement.Provider>
	)
}

export default OrderProvider
