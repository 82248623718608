import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { history } from 'routes/history'
import MobileNavigator from 'shared/components/MobileNavigator'
import { store } from 'store'
import closeIcon from '../../../../../assets/image/close-icon.svg'
import menuIcon from '../../../../../assets/image/menu-icon.svg'
import './styles.scss'

type TParams = {
	path: string
}

const ClientMenuTabsMobile = ({ path }: TParams) => {
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const [financeDetailsTab, setFinanceDetailsTab] = useState<boolean>(false)
	const [partOrderHistoryTab, setPartOrderHistoryTab] = useState<boolean>(false)
	const [partOrderClientTab, setPartOrderClientTab] = useState<boolean>(false)
	const [partOrderLogsTab, setPartOrderLogsTab] = useState<boolean>(false)
	const [attachmentsTab, setAttachmentsTab] = useState<boolean>(false)
	const [showSidebar, setShowSidebar] = useState<boolean>(false)
	const { roles } = store.getState().user
	const digitalEndeavourManager = roles.includes('digitalEndeavourManager')
	const orderManager = roles.includes('orderManager')

	useEffect(() => {
		switch (path) {
			case `/client/:clientCode`:
				setPartOrderClientTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/history`:
				setPartOrderHistoryTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/logs`:
				setPartOrderLogsTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/financeDetails`:
				setFinanceDetailsTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/attachments`:
				setAttachmentsTab(true)
				break
			default:
				setPartOrderClientTab(true)
				break
		}
	}, [path])

	return (
		<div className="client-menu-tabs">
			<div className="client-menu-button">
				<MobileNavigator action={() => history.push('/clients')} />
				<img
					src={showSidebar ? closeIcon : menuIcon}
					alt="menu"
					onClick={() => setShowSidebar(!showSidebar)}
				/>
			</div>

			<div
				className={`client-menu-container ${showSidebar ? 'open' : ''}`}
				onClick={() => setShowSidebar(false)}
			>
				<div className="client-menu-sidebar">
					<div
						className={`client-menu-options ${
							partOrderClientTab && 'client-menu-option-selected'
						}`}
						onClick={() =>
							history.push(`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}`)
						}
					>
						<span>DADOS CADASTRAIS</span>
					</div>
					<div
						className={`client-menu-options ${
							partOrderHistoryTab && 'client-menu-option-selected'
						}`}
						onClick={() =>
							history.push(`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/history`)
						}
					>
						<span>HISTÓRICO</span>
					</div>
					<div
						className={`client-menu-options ${
							financeDetailsTab && 'client-menu-option-selected'
						}`}
						onClick={() =>
							history.push(
								`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/financeDetails`
							)
						}
					>
						<span>FINANCEIRO</span>
					</div>
					<div
						className={`client-menu-options ${
							attachmentsTab && 'client-menu-option-selected'
						}`}
						onClick={() =>
							history.push(
								`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/attachments`
							)
						}
					>
						<span>ANEXOS</span>
					</div>
					{(digitalEndeavourManager || orderManager) && (
						<div
							className={`client-menu-options ${
								partOrderLogsTab && 'client-menu-option-selected'
							}`}
							onClick={() =>
								history.push(`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/logs`)
							}
						>
							<span>LOGS</span>
						</div>
					)}
				</div>
			</div>
		</div>
	)
}
export default ClientMenuTabsMobile
