import NotificationIconWhite from 'assets/image/Notification-full-white.svg'
import NotificationIcon from 'assets/image/Notification-full.svg'
import { Notification } from 'models/Notification'
import User from 'models/User'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import NotificationsModal from 'module/technicalServices/components/Menu/NotificationsModal'
import { SocketContextElement } from 'module/technicalServices/context/SocketContext/SocketContext'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
	getAllNotifications,
	updateAllNotificationStatus,
	updateNotificationStatus,
	userInfo
} from 'services'
import ConfigUserModal from 'shared/ConfigUserModal'
import { DeviceType } from 'utils/Constants'
import { store } from '../../store'
import './styles.css'

const SHOW_NOTIFY_CARD_INTERVAL = 60000 * 15
const HIDE_NOTIFY_CARD_TIME = 20000

const CompNotification: React.FC = () => {
	const { eventListener } = useContext(SocketContextElement)
	const { deviceType } = useContext(DeviceContextElement)

	const [showNotify, setShowNotify] = useState<boolean>(false)
	const [showConfigModal, setShowConfigModal] = useState<boolean>(false)
	const [showNotifyCard, setShowNotifyCard] = useState<boolean>(false)
	const [showNotifyBadge, setShowNotifyBadge] = useState<boolean>(true)
	const [disableUpdateAll, setDisableUpdateAll] = useState<boolean>(true)
	const [notifications, setNotifications] = useState<Notification[]>([])
	const [notifyTimer, setNotifyTimer] = useState<number>()
	const [user, setUser] = useState<User>()
	const userEmail = store.getState().user.email
	const userId = store.getState().user.profile

	useEffect(() => {
		socketEvents()
		if (!user) {
			getUserInfo()
			getNotifications(true)
		}
	}, [user, userEmail])

	useEffect(() => {
		handleNewNotificationsCard()
	}, [showNotify, disableUpdateAll])

	const socketEvents = () => {
		if (userEmail) {
			const socketTopic = `notify_prp_report/${userEmail}`
			eventListener(socketTopic, (err: boolean, data: any) => {
				if (!err) {
					getNotifications(true)
				} else {
					toast.error('Houve um erro ao buscar as últimas notificações!')
				}
			})
		} else {
			console.log('Socket not connected yet!')
		}
	}

	const getNotifications = (reload = false) => {
		getAllNotifications(userId, 20)
			.then((res) => {
				const { response } = res.data
				const notificationList = response.notifications as Notification[]
				const hasNotReadNotification = notificationList.find(
					(n) => n.readAt === null
				)
					? true
					: false
				setDisableUpdateAll(!hasNotReadNotification)
				setNotifications(notificationList)
				if (reload && hasNotReadNotification) {
					showNewNotificationsCard()
				}
			})
			.catch(() => {
				toast.error('Houve um erro ao buscar as últimas notificações!')
			})
	}

	const getUserInfo = () => {
		userInfo(userId)
			.then((res) => {
				setUser(res.data)
			})
			.catch(() => {
				toast.error('Houve um erro ao buscar as informações do usuário!')
			})
	}

	const changeStatus = (id: number, isReading?: boolean) => {
		updateNotificationStatus(id, isReading)
			.then(() => {
				getNotifications()
			})
			.catch(() => {
				toast.error('Houve um erro ao atualizar status da notificação!')
			})
	}

	const updateAllNotifications = () => {
		updateAllNotificationStatus(store.getState().user.profile)
			.then(() => {
				getNotifications()
			})
			.catch(() => {
				toast.error('Houve um erro ao atualizar os status das notificações!')
			})
	}

	/**
	 * Controll Notification Card
	 */
	const handleNewNotificationsCard = (
		isFirtLoad = false,
		userIsViewing = false
	) => {
		let notifyInterval = 0
		clearInterval(notifyTimer)
		setNotifyTimer(notifyInterval)

		let runner = 1
		const showNotifications = disableUpdateAll
		notifyInterval = window.setInterval(() => {
			if (!showNotifications && !userIsViewing) {
				showNewNotificationsCard()
			} else {
				setShowNotifyCard(false)
			}
			runner++
		}, SHOW_NOTIFY_CARD_INTERVAL)
		setNotifyTimer(notifyInterval)
	}

	/**
	 * Show Notification Card
	 */
	const showNewNotificationsCard = () => {
		setShowNotifyCard(true)
		closeNewNotificationsCard()
	}

	/**
	 * Close Notification Card
	 */
	const closeNewNotificationsCard = (delay = HIDE_NOTIFY_CARD_TIME) => {
		setTimeout(() => {
			setShowNotifyCard(false)
		}, delay)
	}

	/**
	 * @description Handle notification card shows
	 */
	const toggleNotificationCard = (e: any) => {
		e.stopPropagation()
		closeNewNotificationsCard(0)
		handleNewNotificationsCard()
	}

	/**
	 * @description Handle notification modal shows
	 */
	const toggleNotificationsModal = () => {
		handleNewNotificationsCard(false, !showNotify)
		setShowNotify(!showNotify)
		setShowNotifyCard(false)
	}

	/**
	 * @description Handle notification modal shows
	 */
	const toggleOutsideNotificationsModal = (e: any) => {
		e.stopPropagation()
		setShowNotify(!showNotify)
	}

	/**
	 * @description handle a notifications icon on new data received or notifications no read.
	 * @returns HTML Component that will show or not.
	 */
	const handleNotificationsCount = () => {
		const hasShow = disableUpdateAll ? 'hide' : 'show'
		return (
			<>
				<div className={`notification-count ${hasShow}`}></div>
			</>
		)
	}

	const handleConfigModalToogle = () => {
		setShowConfigModal(!showConfigModal)
	}

	const handleUserDataUpdate = (userInfo: User) => {
		const userData = { ...user } as User

		userData.name = userInfo.name
		userData.surname = userInfo.surname
		userData.cel = userInfo.cel

		setUser(userData)
	}

	const notifyContentBadge = () => {
		return (
			<>
				<div
					id="joyride-ringbell"
					className="notification-area"
					onClick={toggleNotificationsModal}
				>
					<img
						width="30px"
						height="30px"
						src={
							deviceType !== DeviceType.MOBILE
								? NotificationIcon
								: NotificationIconWhite
						}
						alt="Notification icon"
					/>
					{handleNotificationsCount()}
				</div>
			</>
		)
	}

	const notifyContentModal = () => {
		return (
			<div
				className="modal notifications-center"
				style={{ display: 'block', textAlign: 'center' }}
				onClick={toggleOutsideNotificationsModal}
			>
				<NotificationsModal
					notifications={notifications}
					disableUpdateAll={disableUpdateAll}
					onClose={toggleNotificationsModal}
					changeStatus={changeStatus}
					updateAll={updateAllNotifications}
				></NotificationsModal>
			</div>
		)
	}

	const configModalContent = () => (
		<ConfigUserModal
			onClose={handleConfigModalToogle}
			onUpdateUser={handleUserDataUpdate}
		/>
	)

	const newNotificationsCardContent = () => {
		return (
			<div
				className={`new-notifications-card ${showNotifyCard ? 'show' : 'hide'}`}
				onClick={toggleNotificationsModal}
			>
				<div className="new-notifications-card-text">
					<p>Você possui notificações não lidas.</p>
					<span>Clique aqui e se atualize!</span>
				</div>
			</div>
		)
	}

	return (
		<>
			{showNotifyBadge ? notifyContentBadge() : <></>}
			{newNotificationsCardContent()}
			{showNotify ? notifyContentModal() : <></>}
			{showConfigModal && configModalContent()}
		</>
	)
}

export default CompNotification
