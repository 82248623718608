import ListParams from 'models/ListParams'
import React, { createContext, ReactNode, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { getListProducts } from 'services'
import {
	defaultListParams,
	FilterField,
	FilterValue,
	updateListParams
} from 'utils/ContextUtils'

export const ProductsContext = createContext({
	isLoading: false,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	productsList: [] as any[],
	handleGetProductsList: () => {},
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {}
})

const defaultParams = defaultListParams()

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [productsList, setProductsList] = useState<any[]>([] as any[])
	const listParams = useRef<ListParams>(defaultParams)

	const handleGetProductsList = () => {
		const { currentPage, searchValue } = { ...listParams.current }
		setIsLoading(true)
		getListProducts({
			term: searchValue || '',
			limit: 20,
			offset: currentPage - 1
		})
			.then((response) => {
				updateParams(Math.ceil(response?.data?.total / 20), 'count')
				setProductsList(response?.data?.products)
			})
			.catch(() => toast.error('Erro ao buscar lista de produtos'))
			.finally(() => setIsLoading(false))
	}

	const onSearchParams = (term: string) => {
		updateParams(1, 'page')
		updateParams(term, 'search')
		handleGetProductsList()
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		handleGetProductsList()
	}

	const value = {
		isLoading,
		productsList,
		handleGetProductsList,
		onSearchParams,
		onPageChange,
		...listParams.current
	}

	return (
		<ProductsContext.Provider value={value}>
			{children}
		</ProductsContext.Provider>
	)
}
