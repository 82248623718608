export function updateUserInfo(name, surname, cel, email, lastVersion) {
	return {
		type: '@user/UPDATE_USER_INFO',
		payload: { name, surname, cel, email, lastVersion }
	}
}

export function updateMenuShowStatus(status) {
	return {
		type: '@user/UPDATE_MENU_SHOW_STATUS',
		payload: { status }
	}
}

export const saveRoles = (roles) => {
	return {
		type: '@user/SAVE_ROLES',
		payload: {
			roles
		}
	}
}

export const updateTransferFilters = (listTransferParams) => {
	return {
		type: '@user/UPDATE_TRANSFER_LIST_PARAMS',
		payload: {
			listTransferParams
		}
	}
}

export const updateReducerTutorials = (tutorials) => {
	return {
		type: '@user/UPDATE_TUTORIALS',
		payload: {
			tutorials
		}
	}
}

export function updateModalStatus(modalStatus) {
	return {
		type: '@user/MODAL_STATUS',
		payload: { modalStatus }
	}
}
