import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import LogModal from 'module/adminSystem/components/LogModal'
import { LogsContextElement } from 'module/adminSystem/context/LogsContext'
import { GenericTable } from 'module/orderManagement/components/GenericTable'
import { handleLogEntity } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import Paginator from 'shared/Paginator'
import LoadingProgress from 'shared/components/LoadingProgress'
import './styles.scss'

export default function Logs() {
	const {
		log,
		logs,
		onChangeParams,
		onSelectLog,
		totalPages,
		currentPage,
		isLoading
	} = useContext(LogsContextElement)
	const [showLogModal, setShowLogModal] = useState<boolean>(false)
	const [searchParams, setSearchParams] = useState<string>('')

	useEffect(() => {
		if (log) {
			setShowLogModal(true)
		}
	}, [log])

	const onSearch = (search: string) => {
		setSearchParams(search)
		onChangeParams({ search: search, page: 1 })
	}

	const handleSelectLog = (user: any) => {
		onSelectLog(user.id)
	}

	const handleCloseModal = () => {
		showLogModal && setShowLogModal(!showLogModal)
	}

	return (
		<>
			<article className="logs-list">
				<HeaderSection.root>
					<HeaderSection.search
						onChangeValues={onSearch}
						text="Buscar por registro"
					/>
				</HeaderSection.root>
				<div className="logs-table">
					<GenericTable
						onRowClicked={handleSelectLog}
						data={[
							...logs.map((log) => ({
								...log,
								entityName: handleLogEntity(log.entity),
								createdAt: new Date(log.createdAt).toLocaleDateString(),
								userName: `${log.user?.name} ${log.user?.surname}`,
								status: log.status ? 'Sucesso' : 'Erro'
							}))
						]}
						columns={[
							{
								key: 'userName',
								header: 'Usuário',
								width: '30%'
							},
							{
								key: 'externalId',
								header: 'Registro',
								width: '25%'
							},
							{
								key: 'entityName',
								header: 'Tabela',
								width: '20%'
							},
							{
								key: 'createdAt',
								header: 'Data',
								width: '15%'
							},
							{
								key: 'status',
								header: 'Status',
								width: '10%'
							}
						]}
					/>
				</div>
				<Paginator
					total={totalPages || 1}
					selected={currentPage}
					onPageChange={(e) =>
						onChangeParams({ page: e, search: searchParams })
					}
				/>
			</article>
			{log && showLogModal && <LogModal onClose={handleCloseModal} />}
			{isLoading && <LoadingProgress show={isLoading} />}
		</>
	)
}
