import States from 'assets/js/fs.json'
import { Input } from 'module/installAndSearch/components/Input'
import { Select } from 'module/installAndSearch/components/Select/selects'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { api } from 'services'
import Button from 'shared/components/Button'
import { NewModal } from '../../_newModal'
import '../../_styled/display.scss'
import './styles.scss'

type Props = {
	onReturn: () => void
	onSubmit: (data: any) => void
	title?: string
}

interface FormValues {
	zip_code: string
	street: string
	number: string
	district: string
	city: string
	state: string
	address: string
	neighborhood: string
	state_sigla: string
	complement: string
}

export const ModalChangeAddress = ({ onReturn, onSubmit, title }: Props) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		getValues
	} = useForm<FormValues>()
	const [disableAddress, setDisableAddress] = useState(false)

	async function onChangeZipCode(event: any) {
		let zipCode = event.target.value
		zipCode = zipCode.replace(/[^0-9]/g, '')
		setValue('zip_code', zipCode)
	}

	async function getAddressByZipCode(event: any) {
		let zipCode = event.target.value

		//zipcode somente com dígitos.
		zipCode = zipCode.replace(/\D/g, '')

		if (zipCode !== '') {
			//Expressão regular para validar o CEP.
			const validacep = /^[0-9]{8}$/

			if (validacep.test(zipCode)) {
				// disable address
				setDisableAddress(true)

				const { data } = await api.get(`prp/address/zipcode?zipcode=${zipCode}`)
				const { code, address } = data
				if (code === 200) {
					setDisableAddress(false)
					setValue('address', address.logradouro)
					setValue('neighborhood', address.bairro)
					setValue('city', address.localidade)
					setValue('state_sigla', address.uf)
				} else {
					toast.error('Erro ao encontrar o cep informado, favor verificar.')
					setDisableAddress(false)
					clearAddressFields()
				}
			} else {
				clearAddressFields()
			}
		} else {
			clearAddressFields()
		}
	}

	function clearAddressFields() {
		setValue('address', '')
		setValue('neighborhood', '')
		setValue('city', '')
		setValue('state_sigla', '')
	}

	return (
		<NewModal onClose={onReturn} awayClose={true} title={title}>
			<div className="body-modal-change-address">
				<form>
					<div className="row-install-and-search">
						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="zipcode">
								CEP
							</label>
							<Input
								id="zipcode"
								type="text"
								placeholder={`Digite o CEP *`}
								name="zip_code"
								autoComplete="disabled"
								maxLength={8}
								innerRef={{
									...register('zip_code', {
										required: true,
										minLength: 8,
										maxLength: 8
									})
								}}
								onBlur={getAddressByZipCode}
								onChange={onChangeZipCode}
							/>
							<small className="text-danger">
								{errors.zip_code && errors.zip_code.type === 'required' && (
									<span>O campo é obrigatório</span>
								)}
								{errors.zip_code && errors.zip_code.type === 'maxLength' && (
									<span>O campo deve conter no máximo 9 caracteres</span>
								)}
								{errors.zip_code && errors.zip_code.type === 'minLength' && (
									<span>O campo deve conter no mínimo 8 caracteres</span>
								)}
							</small>
						</div>

						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="city">
								Cidade
							</label>
							<Input
								type="text"
								placeholder={`Digite a cidade *`}
								name="city"
								id="city"
								autoComplete="disabled"
								disabled={disableAddress}
								innerRef={{
									...register('city', {
										required: true,
										maxLength: 100
									})
								}}
							/>
							<small className="text-danger">
								{errors.city && <span>O campo é obrigatório</span>}
							</small>
						</div>

						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="state_sigla">
								Estado
							</label>
							<Select
								id="state_sigla"
								disabled={disableAddress}
								innerRef={{ ...register('state_sigla', { required: true }) }}
								defaultValue={getValues('state_sigla')}
							>
								<option value="">Selecione o estado *</option>
								{States.map((item) => (
									<option key={item.value} value={item.initials}>
										{item.initials}
									</option>
								))}
							</Select>
							<small className="text-danger">
								{errors.state_sigla && <span>O campo é obrigatório</span>}
							</small>
						</div>
					</div>
					<div className="row-install-and-search">
						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="address">
								Endereço
							</label>
							<Input
								type="text"
								placeholder={`Digite o endereço *`}
								autoComplete="disabled"
								name="address"
								id="address"
								disabled={disableAddress}
								innerRef={{ ...register('address', { required: true }) }}
							/>
							<small className="text-danger">
								{errors.address && <span>O campo é obrigatório</span>}
							</small>
						</div>
					</div>
					<div className="row-install-and-search">
						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="number">
								Número
							</label>
							<Input
								type="text"
								placeholder={`Digite o número *`}
								autoComplete="disabled"
								name="number"
								id="number"
								maxLength={5}
								innerRef={{
									...register('number', {
										required: true,
										pattern: /^(0|[1-9][0-9]*)$/
									})
								}}
							/>
							<small className="text-danger">
								{errors.number && errors.number.type === 'required' && (
									<span>O campo é obrigatório</span>
								)}
								{errors.number && errors.number.type === 'pattern' && (
									<span>O campo deve conter apenas números</span>
								)}
							</small>
						</div>

						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="neighborhood">
								Bairro
							</label>
							<Input
								type="text"
								placeholder="Digite o bairro *"
								autoComplete="disabled"
								name="neighborhood"
								id="neighborhood"
								disabled={disableAddress}
								innerRef={{ ...register('neighborhood', { required: true }) }}
							/>
						</div>

						<div className="column-install-and-search">
							<label className="form-label-title" htmlFor="complement">
								Complemento
							</label>
							<Input
								type="text"
								placeholder="Digite o complemento"
								name="complement"
								id="complement"
								autoComplete="disabled"
								innerRef={{ ...register('complement') }}
							/>
						</div>
					</div>
					<div className="container-address-confirm-actions">
						<Button
							size="lg"
							variant="confirmation-solid"
							onClick={handleSubmit(onSubmit)}
							title="Enviar"
						/>
						<Button
							size="lg"
							onClick={onReturn}
							variant="cancellation-gradient"
							title="Cancelar"
						/>
					</div>
				</form>
			</div>
		</NewModal>
	)
}
