import universidadeLogo from 'assets/image/universidade-pratica_logo.png'
import React from 'react'
import './styles.scss'

const UniversityCard = () => {
	return (
		<div
			className="feature-card university-pratica-card"
			onClick={() => {
				window.open('https://up.praticabr.com/login/index.php', '_blank')
			}}
		>
			<div className="university-pratica-card-title">
				<img
					width="220px"
					height="62"
					src={universidadeLogo}
					alt="university pratica logo"
				/>
			</div>
			<div className="university-pratica-card-main-contain">
				<p className="subtext">Tem conteúdo novo por aqui!</p>
				<div className="info-card">
					Reserve alguns minutos do seu dia para aprender :)
				</div>
				<div className="courses-container">
					<div className="courses">
						<p>Superar desafios e compartilhar sucessos, cresça com a gente!</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default UniversityCard
