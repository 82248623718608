import Button from '@material-ui/core/Button'
import { BaseOrderManagementElement } from 'module/orderManagement/context/BaseOrderManagementContext/BaseOrderManagementContext'
import React, { useContext } from 'react'
import { updatePartRequest } from 'services/index'
import {
	CondPag,
	createData,
	tpService,
	Transp
} from '../PartRequest/functions'
import {
	Cliente,
	Cnpj,
	Container,
	Description,
	Div,
	Div_Info,
	Equipment,
	Header,
	Info,
	Pendecia,
	RequestOSInfo,
	RequestPanel,
	Section,
	Serial,
	TabLink,
	Title
} from '../PartRequest/styles'
import '../PartRequest/styles.css'

interface Props {
	equipment: any
	client: any
	lastTechAddress: any
	lastTechID: any
	partRequest: any
	handleCancel: () => void
	handleChanges: (worked: boolean) => void
}

let rows: any[]

const retCod = (html: any) => {
	if (html !== '') {
		return html?.split(' - ')[0]?.charAt(0) + html?.split(' - ')[0]?.slice(1)
	} else {
		return ''
	}
}

const retDescription = (html: any) => {
	if (html !== '') {
		return html?.charAt(0)?.toUpperCase() + html?.slice(1)?.toLowerCase()
	}
}

function pad(n: any) {
	return n < 10 ? '0' + n : n
}

const Budgeting: React.FC<Props> = ({
	equipment,
	client,
	lastTechAddress,
	lastTechID,
	partRequest,
	handleCancel,
	handleChanges
}) => {
	const { priceTableList } = useContext(BaseOrderManagementElement)

	const cnpj = client?.cpfCnpj.replace(
		/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
		'$1.$2.$3/$4-$5'
	)
	const codProtheus = client?.cod_protheus
	const typePart = ''
	const client_address = partRequest?.data[0]?.address.trim()
	const transp = retDescription(
		Transp.find(
			(item) => retCod(item.value) === partRequest?.data[0]?.carrier_code.trim()
		)?.label
	)
	const typeService = retDescription(
		tpService.find(
			(item) => retCod(item.value) === partRequest?.data[0]?.service_type.trim()
		)?.label
	)
	const paymentType = retDescription(
		CondPag.find(
			(item) => retCod(item.value) === partRequest?.data[0]?.payment_type.trim()
		)?.label
	)
	const tabPrice = retDescription(
		priceTableList.find(
			(item) => item.value === partRequest?.data[0]?.tab_price
		)?.label
	)
	const invoiceMsg = partRequest?.data[0]?.invoice_message.trim()
	let itens = 0
	let total = partRequest?.data[0]?.freight

	rows = []
	rows?.pop()

	while (itens < 999) {
		if (partRequest?.data[0]?.PartRequest_itens[itens]?.id !== undefined) {
			const cod = partRequest?.data[0]?.PartRequest_itens[itens]?.product_code
			const quant = partRequest?.data[0]?.PartRequest_itens[itens]?.quant
			const price = partRequest?.data[0]?.PartRequest_itens[itens]?.price
			const storeHouse =
				partRequest?.data[0]?.PartRequest_itens[itens]?.storehouse
			const tpOper = partRequest?.data[0]?.PartRequest_itens[itens]?.type_oper
			const discount = partRequest?.data[0]?.PartRequest_itens[itens]?.discount
			const total_price =
				partRequest?.data[0]?.PartRequest_itens[itens]?.total_price
			const descri = partRequest?.data[0]?.PartRequest_itens[itens]?.descri

			total = total + total_price

			if (rows?.length >= 1) {
				rows.push(
					createData(
						cod,
						descri,
						quant,
						'R$' + price.toFixed(2),
						storeHouse,
						tpOper,
						discount + '%',
						'R$' + total_price.toFixed(2)
					)
				)
			} else {
				rows = [
					createData(
						cod,
						descri,
						quant,
						'R$' + price.toFixed(2),
						storeHouse,
						tpOper,
						discount + '%',
						'R$' + total_price.toFixed(2)
					)
				]
			}
		} else {
			break
		}

		itens += 1
	}

	function Close() {
		$(`.resumo`).css('display', 'none')
		handleCancel()
	}

	function Save(id: any, created: any, order: any) {
		let dataRequest = ''
		const nDate = new Date()

		if (order == null) {
			dataRequest =
				' {"order_date": "' +
				nDate
					.getFullYear()
					.toString()
					.concat(
						pad((nDate.getMonth() + 1).toString()),
						nDate.getDate().toString()
					) +
				'"}'

			updatePartRequest(id, dataRequest)
				.then((resp) => {
					if (resp.status === 200) {
						handleChanges(true)
					}
				})
				.catch((err) => {
					handleChanges(false)
				})
		} else {
			$(`.resumo`).css('display', 'none')
		}
	}

	return (
		<Section>
			<Container>
				<RequestOSInfo>
					<Title>Solicitação de peça</Title>
					<Serial>
						<Description>Número de Série:</Description>
						<Info id="serial_number">{equipment?.serial_number}</Info>
					</Serial>
					<Equipment>{equipment?.name}</Equipment>
					<Div>
						<Cliente>
							<Description>Cliente:</Description>
							<Info>{client?.nome_fantasia}</Info>
						</Cliente>
						<Cnpj>
							<Description>CNPJ:</Description>
							<Info id="cnpj">{cnpj}</Info>

							<Info id="codProtheus" style={{ display: 'none' }}>
								{codProtheus}
							</Info>
							<Info id="lastTechID" style={{ display: 'none' }}>
								{lastTechID}
							</Info>
						</Cnpj>
					</Div>
					<Div className="pendency" style={{ display: 'none' }}>
						<Pendecia>
							<Info>Pendência financeira</Info>
						</Pendecia>
					</Div>
				</RequestOSInfo>
				<RequestPanel>
					<Header>
						<TabLink className="LinkSelect_">Efetivar Orçamento</TabLink>
					</Header>

					<Div_Info>
						<Div data-w-tab="Tab 3" className="tab-pane-tab-3_ w-tab-pane_">
							<Div className="description_">Endereço de entrega:</Div>
							<Div className="info-order_">{client_address}</Div>

							<Div className="description_">Transportadora:</Div>
							<Div className="info-order_">{transp}</Div>

							<Div className="description_">Tipo de serviço do pedido:</Div>
							<Div className="info-order_">{typeService}</Div>

							<Div className="description_">Forma de pagamento:</Div>
							<Div className="info-order_">{paymentType}</Div>

							<Div className="description_">Tabela de Preço:</Div>
							<Div className="info-order_">{tabPrice ? tabPrice : '-'}</Div>

							<Div className="description_">Mensagem para a nota fiscal:</Div>
							<Div className="info-order_">{invoiceMsg}</Div>

							<Div className="itens-block_ order-summary_">
								<Div className="form-address_">Itens:</Div>

								<Div className="itens-header_ w-row_">
									<Div className="column-12_ w-col_ w-col-1_">
										<Div>Código</Div>
									</Div>
									<Div className="w-col_ w-col-3_">
										<Div className="w-row_">
											<Div className="w-col_ w-col-4_">
												<Div className="text-block-26_">Qnt.</Div>
											</Div>
											<Div className="w-col_ w-col-4_">
												<Div className="text-block-26_">Armaz.</Div>
											</Div>
											<Div className="w-col_ w-col-4_">
												<Div className="text-block-26_">TO</Div>
											</Div>
										</Div>
									</Div>
									<Div className="w-col_ w-col-3_">
										<Div>Descrição</Div>
									</Div>
									<Div className="w-col_ w-col-1_">
										<Div className="text-block-33_">Desc.</Div>
									</Div>
									<Div className="w-col_ w-col-2_">
										<Div className="text-block-40_">Preço</Div>
									</Div>
									<Div className="w-col_ w-col-2_">
										<Div className="text-block-41_">Total</Div>
									</Div>
								</Div>

								<Div className="text-block-efetivar">
									{rows?.map((row, index) => (
										<Div className="item_ w-row_" key={index}>
											<Div className="column-13_ w-col_ w-col-1_">
												<Div className="text-block-68_">{row.cod}</Div>
											</Div>
											<Div className="w-col_ w-col-3_">
												<Div className="w-row_">
													<Div className="w-col_ w-col-4_">
														<Div className="text-block-61_">{row.qtd}</Div>
													</Div>
													<Div className="w-col_ w-col-4_">
														<Div className="text-block-62_">{row.armaz}</Div>
													</Div>
													<Div className="w-col_ w-col-4_">
														<Div className="text-block-63_">{row.tpoper}</Div>
													</Div>
												</Div>
											</Div>
											<Div className="w-col_ w-col-3_">
												<Div className="text-block-64_"> {row.descri}</Div>
											</Div>
											<Div className="w-col_ w-col-1_">
												<Div className="text-block-65_">{row.desc}</Div>
											</Div>
											<Div className="w-col_ w-col-2_">
												<Div className="text-block-66_">{row.price}</Div>
											</Div>
											<Div className="w-col_ w-col-2_">
												<Div className="text-block-67_">{row.tot}</Div>
											</Div>
										</Div>
									))}
								</Div>

								<Div className="total_resumo">
									<Div className="column-13_ w-col_ w-col-2_"></Div>
									<Div className="w-col_ w-col-1_"></Div>
									<Div className="w-col_ w-col-4_"></Div>
									<Div className="w-col_ w-col-1_"></Div>
									<Div className="w-col_ w-col-2_">
										<Div className="text-block-70_">FRETE</Div>
									</Div>
									<Div className="w-col_ w-col-2_">
										<Div className="text-block-71_">
											{'R$' + partRequest?.data[0]?.freight.toFixed(2)}
										</Div>
									</Div>
								</Div>

								<Div className="total_resumo">
									<Div className="column-13_ w-col_ w-col-2_"></Div>
									<Div className="w-col_ w-col-1_"></Div>
									<Div className="w-col_ w-col-4_"></Div>
									<Div className="w-col_ w-col-1_"></Div>
									<Div className="w-col_ w-col-2_">
										<Div className="text-block-70_">TOTAL</Div>
									</Div>
									<Div className="w-col_ w-col-2_">
										<Div className="text-block-71_">
											{'R$' + total.toFixed(2)}
										</Div>
									</Div>
								</Div>
							</Div>
							<div className="button-resumo">
								<Button
									style={{ marginLeft: '10px' }}
									color="secondary"
									onClick={Close}
								>
									Cancelar
								</Button>

								{partRequest?.data[0]?.type === 'PED' && (
									<div className="submit-button-2 w-button" onClick={Close}>
										Fechar
									</div>
								)}

								{partRequest?.data[0]?.type === 'ORC' && (
									<div
										className="submit-button-2 w-button"
										onClick={() => {
											Save(
												partRequest?.data[0]?.id,
												partRequest?.data[0]?.created_at,
												partRequest?.data[0]?.order_date
											)
										}}
									>
										Efetivar
									</div>
								)}
							</div>
						</Div>
					</Div_Info>
				</RequestPanel>
			</Container>
		</Section>
	)
}
export default Budgeting
