import React, { ReactNode, createContext, useEffect } from 'react'
import { useParams } from 'react-router'
import { uploadFile } from 'services'
import {
	getProductDetails,
	getSb1010Details,
	postProductFile
} from '../services'

export const ProductDetailsContext = createContext({
	sb1010: {} as any,
	szr010: {} as any,
	sx5010: {} as any,
	productDetails: {} as any,
	isLoading: false as boolean,
	processUpload: (
		uploadedFile: any,
		event: string,
		callback: (error: any) => void
	) => {}
})

export const ProductDetailsProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const [sb1010, setSb1010] = React.useState<any>()
	const [szr010, setSzr010] = React.useState<any>()
	const [sx5010, setSx5010] = React.useState<any>()
	const [productDetails, setProductDetails] = React.useState<any>()
	const [isLoading, setIsLoading] = React.useState<any>()

	const { codeProtheus } = useParams<{ codeProtheus: string }>()

	useEffect(() => {
		handleSb1010Details()
		handleProductDetails()
	}, [codeProtheus])

	const handleSb1010Details = async () => {
		setIsLoading(true)
		getSb1010Details(codeProtheus)
			.then((response) => {
				setSb1010({ ...response?.data?.sb1010, ...response?.data?.sb5010 })
				setSzr010(response?.data?.szr010[0])
				setSx5010(response?.data?.sx5010)
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const handleProductDetails = async () => {
		setIsLoading(true)
		getProductDetails(codeProtheus)
			.then((response) => {
				setProductDetails(response.data.product)
			})
			.catch((error) => {
				console.log(error)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	const processUpload = async (
		uploadedFile: any,
		event: string,
		callback: (error: any) => void
	) => {
		try {
			const data = new FormData()

			data.append('file', uploadedFile.file, uploadedFile.name)

			const response = await uploadFile.post('upload', data)

			uploadedFile.uploaded = true
			uploadedFile.id = response.data.key
			uploadedFile.url = response.data.url
			uploadedFile.event = event
			postProductFile({ product: sb1010, file: uploadedFile })
		} catch (error) {
			uploadedFile.error = true
		}
	}

	const value = {
		sb1010,
		szr010,
		sx5010,
		productDetails,
		isLoading,
		processUpload
	}

	return (
		<ProductDetailsContext.Provider value={value}>
			{children}
		</ProductDetailsContext.Provider>
	)
}
