import { createFilterOptions } from '@material-ui/lab/Autocomplete'
import { TransferClient } from 'models/Transfer'
import AutoCompleteInput from 'module/technicalServices/components/Shared/Forms/AutoCompleteInput'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import LoadingProgress from 'module/technicalServices/components/Shared/Loadings/LoadingProgress'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import TransferClientInfo from '../../../../Shared/Transfers/TransferClientInfo'

interface Props {
	client?: TransferClient
	onSelectClient: (clientSelected: any) => void
}

const TransferClientStep: React.FC<Props> = ({ client, onSelectClient }) => {
	const { transferClients, isLoading, onRequestClients } = useContext(
		TransfersDetailsContextElement
	)

	const [clientSelected, setClientSelected] = useState<TransferClient>()

	useEffect(() => {
		setClientSelected(client)
	}, [])

	const onSubmit = () => {
		onSelectClient(clientSelected)
	}

	const onSearchClients = (value: string) => {
		setClientSelected(undefined)
		onRequestClients(value)
	}

	const onSelectItem = (item: any) => {
		const transferClient = {
			id: item.id,
			name: item.name,
			razaoSocial: item.razaoSocial,
			contact: item.contact,
			cpfCnpj: item.cpfCnpj,
			email: item.email,
			phone: item.phone,
			isOnDataBase: true
		} as TransferClient

		setClientSelected(transferClient)
	}

	const formContent = () => {
		const filterOptions = createFilterOptions({
			matchFrom: 'any',
			stringify: (value: any) => value['name'] + ' ' + value['cpfCnpj']
		})

		return (
			<AutoCompleteInput
				listItems={transferClients}
				keyField="id"
				filterField="name"
				placeHolder="Pesquisar cliente"
				loadingText="Buscando clientes..."
				onChangeValue={onSearchClients}
				onSelectValue={onSelectItem}
				filterOptions={filterOptions}
			/>
		)
	}

	const clientContent = () => {
		return <TransferClientInfo {...{ client: clientSelected }} />
	}

	const clientValidatorWarning = () => {
		return (
			<div className="transfer-client-info-warning">
				<MdErrorOutline />
				<h5>Cliente é obrigatório</h5>
			</div>
		)
	}

	const handleClientContent = () => {
		if (clientSelected && !isLoading) {
			return clientContent()
		} else if (isLoading) {
			return loadingContent()
		} else {
			return clientValidatorWarning()
		}
	}

	const loadingContent = () => {
		return <LoadingProgress text="Buscando clientes..." show={isLoading} />
	}

	const mainContent = () => {
		return (
			<div className="transfer-client-content">
				<div className="transfer-equip-header">
					<h4>Cliente de Destino</h4>
					<p>Informe o novo proprietário do equipamento.</p>
				</div>
				{formContent()}
				{handleClientContent()}
				<div className="transfer-client-footer">
					<CustomButtom
						type="button"
						label="Avançar"
						context="primary"
						onClick={onSubmit}
						disabled={!clientSelected}
					/>
				</div>
			</div>
		)
	}

	return mainContent()
}

export default TransferClientStep
