export function handleOptionTpRequest(type: string) {
	let tpRequest = [{ value: '', label: '' }]
	switch (type) {
		case 'ORCAMENTOS':
			tpRequest = [{ value: 'ORC', label: 'Orçamento' }]
			return tpRequest
		default:
			tpRequest = [
				{ value: '', label: 'Selecione' },
				{ value: 'ORC', label: 'Orçamento' },
				{ value: 'PED', label: 'Pedido' },
				{ value: 'REP', label: 'Reposição' }
			]
			return tpRequest
	}
}

export const Filial = [
	{ value: '01', label: 'Matriz' },
	{ value: '02', label: 'São Paulo' },
	{ value: '06', label: 'Recife' }
]

export const tpService = [
	{ value: '', label: 'Selecione' },
	{
		value: '000001 - PRESTACAO DE SERVICO FORA DE GARANTIA',
		label: 'PRESTACAO DE SERVICO FORA DE GARANTIA'
	},
	{
		value: '000002 - REMESSA EM GARANTIA TECHNICOOK',
		label: 'REMESSA EM GARANTIA TECHNICOOK'
	},
	{
		value: '000003 - REMESSA EM GARANTIA TECHNIPAN',
		label: 'REMESSA EM GARANTIA TECHNIPAN'
	},
	{
		value: '000004 - REM. PECAS FORA DA GARANTIA P/ TERCEIROS',
		label: 'REM. PECAS FORA DA GARANTIA P/ TERCEIROS'
	},
	{
		value: '000005 - REMESSA PECAS 3 FORA DA GARANTIA P/ REVENDA',
		label: 'REMESSA PECAS 3 FORA DA GARANTIA P/ REVENDA'
	},
	{
		value: '000007 - REMESSA FABRI FORA DA GARANTIA P/ TERCEIROS',
		label: 'REMESSA FABRI FORA DA GARANTIA P/ TERCEIROS'
	},
	{
		value: '000008 - REMESSA FABRI FORA DA FARANTIA P/ REVENDA',
		label: 'REMESSA FABRI FORA DA FARANTIA P/ REVENDA'
	},
	{ value: '000009 - DESLOCAMENTO EXCEDENTE', label: 'DESLOCAMENTO EXCEDENTE' },
	{
		value: '000010 - PRESTACAO DE SERVICO EM GARANTIA',
		label: 'PRESTACAO DE SERVICO EM GARANTIA'
	},
	{
		value: '000000 - REMESSA DE PECA EM GARANTIA',
		label: 'REMESSA DE PECA EM GARANTIA'
	},
	{
		value: '000011 - REMESSA EM GARANTIA KLIMAQUIP',
		label: 'REMESSA EM GARANTIA KLIMAQUIP'
	},
	{
		value: '000012 - REMESSA GAR.TECHNICOOK - PLACAS RECONDICIONADAS',
		label: 'REMESSA GAR.TECHNICOOK - PLACAS RECONDICIONADAS'
	},
	{
		value: '000013 - REMESSA GAR.TECHNIPAN - PLACAS RECONDICIONADAS',
		label: 'REMESSA GAR.TECHNIPAN - PLACAS RECONDICIONADAS'
	}
]

export const CondPag = [
	{ value: '', label: 'Selecione' },
	{ value: '000 - A VISTA', label: 'A VISTA' },
	{ value: '001 - 30/60/90/120', label: '30/60/90/120' },
	{ value: '008 - 30,60', label: '30,60' },
	{ value: '014 - 30 DD', label: '30 DD' },
	{ value: '020 - 7 DIAS', label: '7 DIAS' },
	{ value: '021 - 00/30/60', label: '00/30/60' },
	{ value: '022 - 30/60/90DD', label: '30/60/90DD' },
	{ value: '039 - 15 DIAS', label: '15 DIAS' },
	{ value: '047 - 60DD', label: '60DD' },
	{ value: '057 - 10DD', label: '10DD' },
	{ value: '060 - 20DD', label: '20DD' },
	{ value: '082 - 40 DIAS', label: '40 DIAS' },
	{ value: '107 - 6X - SEM SINAL', label: '6X - SEM SINAL' },
	{ value: '158 - S20%+30DD', label: 'S20%+30DD' },
	{ value: '173 - 15/30', label: '15/30' }
]

export const Transp = [
	{ value: '', label: 'Selecione' },
	{ value: '75 - AGUIA BRANCA', label: 'AGUIA BRANCA' },
	{ value: '991285 - AZUL CARGO', label: 'AZUL CARGO' },
	{
		value: '10 - GARDENIA EXPRESS LOGISTICA LTDA',
		label: 'GARDENIA EXPRESS LOGISTICA LTDA'
	},
	{ value: '137 - JADLOG', label: 'JADLOG' },
	{
		value: '990949 - JAD LOGISTICA LTDA (UNIDADE SAO 22)',
		label: 'JAD LOGISTICA LTDA (UNIDADE SAO 22)'
	},
	{ value: '991206 - LATAM', label: 'LATAM' },
	{
		value: '303044 - NOVO VALE TRANSPORTES LTDA',
		label: 'NOVO VALE TRANSPORTES LTDA'
	},
	{ value: '991197 - MOTOBOY', label: 'MOTOBOY' },
	{ value: '49 - O DESTINATARIO', label: 'O DESTINATARIO' },
	{ value: '990628 - PASSARO MARROM', label: 'PASSARO MARROM' },
	{ value: '000008 - PAULINERIS', label: 'PAULINERIS' },
	{
		value: '28 - PRATICA KLIMAQUIP INDUSTRIA E COMERCIO S',
		label: 'PRATICA KLIMAQUIP INDUSTRIA E COMERCIO S'
	},
	{ value: '000000 - PROPRIO', label: 'PROPRIO' },
	{
		value: '990195 -  TNT MERCURIO CARGAS E ENCOMENDAS EXPRESS',
		label: 'TNT MERCURIO CARGAS E ENCOMENDAS EXPRESS'
	},
	{ value: '000011 - SEDEX', label: 'SEDEX' },
	{ value: '990072 - SEDEX 10', label: 'SEDEX 10' }
]

export function handleOptionTO(type: string) {
	let TpOper = [{ value: '', label: '' }]
	switch (type) {
		case 'PREVENTIVA':
		case 'CORRETIVA PREVENTIVA':
		case 'FORA DE GARANTIA':
			TpOper = [{ value: '01', label: '01 - VENDA / REVENDA' }]
			return TpOper
		case 'GARANTIA':
		case 'GARANTIA REVENDA':
		case 'GARANTIA ESTENDIDA':
		case 'SEMI NOVOS':
			TpOper = [{ value: '08', label: '08 - GARANTIA MANUTENCAO' }]
			return TpOper
		case 'CORTESIA SERVICO TECNICO':
		case 'CORTESIA 1 MES':
			TpOper = [{ value: '28', label: '28 - CORTESIA' }]
			return TpOper
		case 'RECALL':
			TpOper = [{ value: '41', label: '41 - RECALL' }]
			return TpOper
		case 'CORTESIA COMERCIAL':
			TpOper = [{ value: '47', label: '47 - CORTESIA COMERCIAL' }]
			return TpOper
		case 'LOCACOES':
			TpOper = [
				{ value: '01', label: '01 - VENDA / REVENDA' },
				{ value: '08', label: '08 - GARANTIA MANUTENCAO' },
				{ value: '28', label: '28 - CORTESIA' },
				{ value: '47', label: '47 - CORTESIA COMERCIAL' }
			]
			return TpOper
		case 'RETORNO AO CLIENTE':
			TpOper = [
				{ value: '01', label: '01 - VENDA / REVENDA' },
				{ value: '08', label: '08 - GARANTIA MANUTENCAO' },
				{ value: '28', label: '28 - CORTESIA' },
				{ value: '41', label: '41 - RECALL' },
				{ value: '47', label: '47 - CORTESIA COMERCIAL' }
			]
			return TpOper
		case 'ERRO ADMINISTRATIVO':
			TpOper = [
				{ value: '01', label: '01 - VENDA / REVENDA' },
				{ value: '28', label: '28 - CORTESIA' },
				{ value: '47', label: '47 - CORTESIA COMERCIAL' }
			]
			return TpOper
		case 'ORCAMENTOS':
			TpOper = [{ value: '01', label: '01 - VENDA / REVENDA' }]
			return TpOper
		default:
			return TpOper
	}
}

export function toTitle(word: any) {
	return word
		?.trim()
		?.toLowerCase()
		?.substring(0, 1)
		?.toUpperCase()
		?.concat(word?.trim()?.toLowerCase()?.substring(1))
}

export function formValidator() {
	if ($(`.codigo`).find('input').val() === '') {
		$(`.codigo`).find('input').focus()
		return false
	}

	if ($(`.descri`).find('input').val() === '') {
		$(`.descri`).find('input').focus()
		return false
	}

	if ($(`.quant`).find('input').val() === '') {
		$(`.quant`).find('input').focus()
		return false
	}

	if (
		$(`.price`).find('input').val() === '' ||
		$(`.price`).find('input').val() === '0.00'
	) {
		$(`.price`).find('input').focus()
		return false
	}

	if ($(`.armazem`).val() == '') {
		$(`.armazem`).focus()
		return false
	}

	if (
		$(`.total`).find('input').val() === '' ||
		$(`.total`).find('input').val() === '0.00'
	) {
		$(`.total`).find('input').focus()
		return false
	}

	if ($('#typeoper').val() == '') {
		$('#typeoper').focus()
		return false
	}

	return true
}

export function linkSelect(nOpc: any) {
	if (nOpc == 1) {
		$('#link1').addClass('LinkSelect_')
		$('#link2').removeClass('LinkSelect_')
		$('#link3').removeClass('LinkSelect_')

		$(`.summary`).css('display', 'none')
		$(`.new-item`).css('display', 'none')
		$(`.part-info`).css('display', 'inline')
	} else if (nOpc == 2) {
		$('#link2').addClass('LinkSelect_')
		$('#link1').removeClass('LinkSelect_')
		$('#link3').removeClass('LinkSelect_')

		$(`.summary`).css('display', 'none')
		$(`.part-info`).css('display', 'none')
		$(`.new-item`).css('display', 'inline')
	} else if (nOpc == 3) {
		$('#link3').addClass('LinkSelect_')
		$('#link1').removeClass('LinkSelect_')
		$('#link2').removeClass('LinkSelect_')

		$(`.summary`).css('display', 'block')
		$(`.new-item`).css('display', 'none')
		$(`.part-info`).css('display', 'none')

		return true
	}
}

export function createData(
	cod: any,
	descri: any,
	qtd: any,
	price: any,
	armaz: any,
	tpoper: any,
	desc: any,
	tot: any
) {
	return { cod, descri, qtd, price, armaz, tpoper, desc, tot }
}

export function checkNumber(valor: any) {
	if (valor?.trim() !== '') {
		const regra = /^[0-9-.]+$/
		if (valor.match(regra)) {
			return true
		} else {
			return false
		}
	} else {
		return false
	}
}
