import React from 'react'
import './styles.scss'

interface SvgProps extends React.SVGProps<SVGSVGElement> {
	width: string
	height: string
	fill?: string
	id?: string
}

const ArrowIcon: React.FC<SvgProps> = ({
	fill,
	width,
	height,
	id,
	...rest
}) => {
	return (
		<svg
			id={id}
			xmlns="http://www.w3.org/2000/svg"
			width="60"
			height="40"
			viewBox="0 0 60 40"
			{...rest}
		>
			<g id="Filter" transform="translate(1690 7366) rotate(180)">
				<rect
					id="area"
					width="60"
					height="40"
					transform="translate(1630 7326)"
					fill="none"
				/>
				<path
					id="Icon_ionic-ios-arrow-back"
					data-name="Icon ionic-ios-arrow-back"
					d="M14.7,16.191,22.265,8.63a1.429,1.429,0,0,0-2.024-2.018l-8.574,8.568a1.426,1.426,0,0,0-.042,1.971l8.609,8.627a1.429,1.429,0,0,0,2.024-2.018Z"
					transform="translate(1676.193 7328.998) rotate(90)"
					fill="#767676"
				/>
			</g>
		</svg>
	)
}

export default ArrowIcon
