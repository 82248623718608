import React, { useState } from 'react'
import PropTypes from 'prop-types'

import List from '@material-ui/core/List'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TechSpecialty from 'models/TechSpecialty'

interface Props {
	items?: TechSpecialty[]
	checked: number[]
	onClick: (element: number) => void
}

const useStyles = makeStyles({
	root: {
		textAlign: 'start',
		fontSize: '14px !important',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap'
	},
	rootLabel: {
		width: '20%',
		marginRight: '10px'
	},
	listLabel: {
		fontSize: '12px !important',
		display: 'flex',
		padding: '0px',
		paddingLeft: '5px'
	},
	check: {
		fontSize: '12px !important',
		display: 'flex',
		padding: '0px',
		paddingRight: '5px',
		marginLeft: '10px',
		'&:hover': {
			backgroundColor: 'transparent'
		}
	}
})

const ListTechSpecialties: React.FC<Props> = ({ items, checked, onClick }) => {
	const classes = useStyles()

	const [open, setOpen] = useState(false)

	const handleChange = (element: number) => {
		onClick(element)
	}

	const handleClick = () => {
		setOpen(!open)
	}

	return (
		<>
			<List
				component="div"
				disablePadding
				classes={{
					root: classes.root
				}}
			>
				{items &&
					items.map((element, index) => (
						<FormControlLabel
							key={index}
							classes={{
								root: classes.rootLabel,
								label: classes.listLabel
							}}
							control={
								<Checkbox
									onChange={() => handleChange(element.id)}
									color="secondary"
									classes={{
										root: classes.check
									}}
									checked={checked.includes(element.id)}
									value={element.id}
								/>
							}
							label={element.description}
						/>
					))}
			</List>
		</>
	)
}

ListTechSpecialties.propTypes = {
	items: PropTypes.any
}

export { ListTechSpecialties }
