import { GenericTable, LoadingProgress, Paginator } from '@praticabr/ppsa'
import { HeaderSection } from 'module/adminSystem/components/HeaderSection'
import { ProductFamilyContext } from 'module/productManagement/context/ProductFamilyContext'
import React, { useContext, useEffect } from 'react'
import { history } from 'routes/history'
import './styles.scss'

export const ProductsFamilyList = () => {
	const {
		handleGetFamilyList,
		currentPage,
		familyList,
		isLoading,
		onPageChange,
		onSearchParams,
		totalPages
	} = useContext(ProductFamilyContext)

	useEffect(() => {
		handleGetFamilyList()
	}, [])

	return (
		<div className="product-family-list">
			<HeaderSection.root>
				<HeaderSection.search
					onChangeValues={(value) => onSearchParams(value)}
					text="Pesquisar familia"
				/>
			</HeaderSection.root>
			<div className="product-family-list-table">
				<GenericTable
					columns={[
						{
							key: 'zrCodigo',
							header: 'Código',
							width: '10%'
						},
						{
							key: 'zrFamilia',
							header: 'Familia',
							width: '45%'
						},
						{
							key: 'zrTitulo',
							header: 'Descrição',
							width: '45%'
						}
					]}
					data={familyList}
					onRowClicked={(e: any) =>
						history.push(`/productFamily/${e?.zrCodigo}`)
					}
				/>
			</div>
			<div className="product-family-paginator">
				<Paginator
					currentPage={currentPage}
					totalPages={totalPages}
					onPageChange={(e) => onPageChange(e)}
				/>
			</div>
			{isLoading && <LoadingProgress />}
		</div>
	)
}
