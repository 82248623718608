import { ButtonHeader } from './ButtonHeader'
import { HeaderSectionRoot } from './HeaderSectionRoot'
import { LegendHeader } from './LegendHeader'
import { SearchHeader } from './SearchHeader'

export const HeaderSection = {
	root: HeaderSectionRoot,
	search: SearchHeader,
	legend: LegendHeader,
	button: ButtonHeader
}
