import React from 'react'

const HumanResourcesIcon: React.FC = () => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="50"
			viewBox="10 5 30 30"
			fill="url(#gradient)"
		>
			<linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
				<stop offset="0%" stopColor="#4F8FE9" stopOpacity="0.6" />
				<stop offset="45%" stopColor="#396BC9" stopOpacity="0.6" />
				<stop offset="100%" stopColor="#5e50ae" stopOpacity="0.6" />
			</linearGradient>
			<rect fill="url(#gradient)" />
			<g transform="translate(-301 -403)">
				<g transform="translate(-74.291 -112.294)">
					<path
						d="M-1502.429-3353.972a96.41,96.41,0,0,1-13.865-1.181v-4.562c0-2.8,2.833-4.626,5.21-5.666a23.815,23.815,0,0,1,8.654-1.9,23.819,23.819,0,0,1,8.655,1.9c2.377,1.04,5.21,2.867,5.21,5.666v4.562l-.015,0A95.481,95.481,0,0,1-1502.429-3353.972Zm5.327-8a1.138,1.138,0,0,0-.749.274,1.258,1.258,0,0,0-.443.907,1.279,1.279,0,0,0,.354.949l1.727,1.784a.28.28,0,0,0,.2.086.28.28,0,0,0,.2-.086l1.727-1.784a1.279,1.279,0,0,0,.355-.949,1.256,1.256,0,0,0-.442-.907,1.136,1.136,0,0,0-.748-.274,1.289,1.289,0,0,0-.918.395l-.176.182-.176-.182A1.286,1.286,0,0,0-1497.1-3361.972Zm-5.327-6.86c-3.822,0-6.932-3.4-6.932-7.57s3.11-7.57,6.932-7.57,6.933,3.4,6.933,7.57S-1498.606-3368.832-1502.429-3368.832Z"
						transform="translate(1902.719 3904.266)"
					/>
				</g>
			</g>
		</svg>
	)
}

export default HumanResourcesIcon
