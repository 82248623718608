import React from 'react'
import './styles.scss'

const OrderHistoryListHeader = () => {
	return (
		<div className="order-history-list-header">
			<ul className="order-history-list-item order-history-list-order-status" />
			<ul className="order-history-list-item order-history-list-order">
				<span>Pedido</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-category">
				<span>Categoria</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-issue-date">
				<span>Emissão</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-delivery-date">
				<span>Dt. entrega</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-last-billing">
				<span>Último faturamento</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-salesperson">
				<span>Representante</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-financial-status">
				<span>Status financeiro</span>
			</ul>
			<ul className="order-history-list-item order-history-list-order-more-options" />
		</div>
	)
}
export default OrderHistoryListHeader
