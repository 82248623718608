const TOTAL_ITEMS = 15

/**
 * Method that order the list.
 * @param {Object} a
 * @param {Object} b
 */
function compare(a: any, b: any) {
	const distanceA = Number(a.distance)
	const distanceB = Number(b.distance)
	let comparison = 0
	if (distanceA > distanceB) {
		comparison = 1
	} else if (distanceA < distanceB) {
		comparison = -1
	}
	return comparison
}

/**
 * Method that will mount the list, setting the distance and the types
 * of the products that the technical works.
 * @param { Array } technicals
 * @param { Function } setResults
 */
export const orderList = (
	technicals: any[],
	setResults: (data: any) => void
) => {
	if (technicals.length > 0) {
		;(async () => {
			let listItems = technicals.sort(compare)

			if (listItems.length > TOTAL_ITEMS)
				listItems = listItems.slice(0, TOTAL_ITEMS)

			setResults(listItems)
		})()
	}
}

export const sortList = (list: any[]) => {
	if (list.length > 0) {
		let listItems = list.sort(compare)

		if (listItems.length > TOTAL_ITEMS)
			listItems = listItems.slice(0, TOTAL_ITEMS)

		return listItems
	}
}

/**
 * Function that remove all duplicates of the array and set in Hook function.
 * @param { Array } arr
 * @param { Function } setList
 */
export const removeDuplicated = (arr: any[], setList: (data: any) => void) => {
	const unique = arr.reduce((acc, current) => {
		const x = acc.find((item: any) => item.code === current.code)
		if (!x) {
			return acc.concat([current])
		}
		return acc
	}, [])
	setList(unique)
}

export const allowedCloseClients = (client: any) => {
	const state = client.state_sigla
	const ddd = client.ddd
	const city = client.city
		.toLowerCase()
		.normalize('NFD')
		.replace(/[\u0300-\u036f]/g, '')

	return closeCities().includes(city) && state.toLowerCase() === 'mg'
}

const closeCities = () => {
	return [
		'Aguanil',
		'Aiuruoca',
		'Alagoa',
		'Albertina',
		'Alfenas',
		'Alpinópolis',
		'Alterosa',
		'Andradas',
		'Andrelândia',
		'Arceburgo',
		'Areado',
		'Baependi',
		'Bandeira Do Sul',
		'Boa Esperança',
		'Bom Jesus Da Penha',
		'Bom Repouso',
		'Bom Sucesso',
		'Borda Da Mata',
		'Botelhos',
		'Brasópolis',
		'Bueno Brandão',
		'Cabo Verde',
		'Cachoeira De Minas',
		'Caldas',
		'Camanducaia',
		'Cambuí',
		'Cambuquira',
		'Campanha',
		'Campestre',
		'Campo Belo',
		'Campo Do Meio',
		'Campos Gerais',
		'Cana Verde',
		'Candeias',
		'Capetinga',
		'Careaçu',
		'Carmo Da Cachoeira',
		'Carmo De Minas',
		'Carmo Do Rio Claro',
		'Carrancas',
		'Carvalhópolis',
		'Carvalhos',
		'Cássia',
		'Caxambu',
		'Conceição Da Aparecida',
		'Conceição Das Pedras',
		'Conceição Do Rio Verde',
		'Conceição Dos Ouros',
		'Congonhal',
		'Consolação',
		'Coqueiral',
		'Cordislândia',
		'Córrego Do Bom Jesus',
		'Cristais',
		'Cristina',
		'Cruzília',
		'Delfim Moreira',
		'Delfinópolis',
		'Divisa Nova',
		'Dom Viçoso',
		'Elói Mendes',
		'Espírito Santo Do Dourado',
		'Estiva',
		'Extrema',
		'Fama',
		'Fortaleza De Minas',
		'Gonçalves',
		'Guapé',
		'Guaranésia',
		'Guaxupé',
		'Heliodora',
		'Ibiraci',
		'Ibitiúra De Minas',
		'Ibituruna',
		'Ijaci',
		'Ilicínea',
		'Inconfidentes',
		'Ingaí',
		'Ipuiúna',
		'Itajubá',
		'Itamogi',
		'Itamonte',
		'Itanhandu',
		'Itapeva',
		'Itaú De Minas',
		'Itumirim',
		'Itutinga',
		'Jacuí',
		'Jacutinga',
		'Jesuânia',
		'Juruaia',
		'Lambari',
		'Lavras',
		'Luminárias',
		'Machado',
		'Maria Da Fé',
		'Marmelópolis',
		'Minduri',
		'Monsenhor Paulo',
		'Monte Belo',
		'Monte Santo De Minas',
		'Monte Sião',
		'Munhoz',
		'Muzambinho',
		'Natércia',
		'Nazareno',
		'Nepomuceno',
		'Nova Resende',
		'Olímpio Noronha',
		'Ouro Fino',
		'Paraguaçu',
		'Paraisópolis',
		'Passa Quatro',
		'Passos',
		'Pedralva',
		'Perdões',
		'Piranguçu',
		'Piranguinho',
		'Poço Fundo',
		'Poços De Caldas',
		'Pouso Alegre',
		'Pouso Alto',
		'Pratápolis',
		'Ribeirão Vermelho',
		'Santa Rita De Caldas',
		'Santa Rita Do Sapucaí',
		'Santana Da Vargem',
		'Santana Do Jacaré',
		'Santo Antônio Do Amparo',
		'São Bento Abade',
		'São Gonçalo Do Sapucaí',
		'São João Batista Do Glória',
		'São João Da Mata',
		'São José Da Barra',
		'São José Do Alegre',
		'São Lourenço',
		'São Pedro Da União',
		'São Sebastião Da Bela Vista',
		'São Sebastião Do Paraíso',
		'São Sebastião Do Rio Verde',
		'São Tomás De Aquino',
		'São Vicente De Minas',
		'Sapucaí-Mirim',
		'Senador Amaral',
		'Senador José Bento',
		'Seritinga',
		'Serrania',
		'Serranos',
		'Silvianópolis',
		'Soledade De Minas',
		'Tocos Do Mogi',
		'Toledo',
		'Três Corações',
		'Três Pontas',
		'Turvolândia',
		'Varginha',
		'Virgínia',
		'Wenceslau Braz'
	].map((c) =>
		c
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
	)
}
