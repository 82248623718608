import styled from 'styled-components'

export const Header = styled.div`
	background-color: rgba(112, 153, 209, 0.32);
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
	padding-bottom: 10px;
`
export const Container = styled.div`
	width: 55%;
	background-color: rgb(242, 242, 242);
	position: relative;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
`

export const Footer = styled.div`
	display: flex;
	flex-direction: row-reverse;
	padding: 20px;
	padding-bottom: 30px;
`

export const Tech = styled.div`
	display: block;
	width: auto;
	margin-right: 20px;
	margin-left: 20px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 15px;
	margin-top: 10px;
	font-size: 20px;
	background: #fff;
	color: rgb(11, 58, 125);

	@media screen and (max-width: 991px) {
		padding: 0px 15px;
		margin-bottom: 25px;
	}
	@media screen and (max-width: 767px) {
		padding: 0px 15px;
		margin-bottom: 25px;
	}
`

export const IdTech = styled.div`
	color: rgb(51, 51, 51);
	margin-bottom: 10px;
	padding-left: 2px;
`

export const InfoTech = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	@media screen and (max-width: 767px) {
		width: 100%;
		padding: 3px 0px;
		&:hover {
			background-color: rgba(0, 0, 0, 0.07);
		}
	}
`

export const TechName = styled.div`
	position: relative;
	font-weight: 500;
	font-size: 14px;
	text-align: left;
	width: 100%;
	font-size: 30px;
	line-height: 30px;
	text-align: left;
	color: rgb(51, 51, 51);
	font-weight: 400;
	@media screen and (max-width: 767px) {
		width: 100%;
		&:hover {
			background-color: rgba(0, 0, 0, 0.07);
		}
	}
`

export const Description = styled.div`
	padding-right: 5px;
	font-weight: 600;
	text-align: left;
	color: #333333;
`
