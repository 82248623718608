import React from 'react'
import Button from 'shared/components/Button'
import './styles.scss'

interface Params {
	show: boolean
	text?: string
	title: string
	buttonWidth?: string
	children?: JSX.Element
	confirmLabel?: string
	cancelLabel?: string
	onCancel?: () => void
	onConfirm: () => void
}

const ConfirmModal: React.FC<Params> = ({
	show,
	text,
	title,
	buttonWidth = '108px',
	children,
	cancelLabel,
	confirmLabel,
	onCancel,
	onConfirm
}) => {
	const handleDefaultBody = () => {
		return text ? (
			<div className="confirm-modal-body">
				<p>{text}</p>
			</div>
		) : (
			<div className="confirm-modal-body">{children}</div>
		)
	}

	const handleButtons = () => {
		const cancelButtonText = cancelLabel ? cancelLabel : 'Cancelar'
		const confirmButtonText = confirmLabel ? confirmLabel : 'Confirmar'
		const cancelButtonContent = onCancel ? (
			<Button
				style={{ width: `${buttonWidth}` }}
				size="lg"
				title={cancelButtonText}
				type="button"
				variant="cancellation-gradient"
				onClick={onCancel}
			/>
		) : (
			<></>
		)

		return (
			<>
				<Button
					style={{ width: `${buttonWidth}` }}
					size="lg"
					title={confirmButtonText}
					type="button"
					variant="confirmation-gradient"
					onClick={onConfirm}
				/>
				{cancelButtonContent}
			</>
		)
	}

	const mainContent = () => {
		return (
			<div className="confirm-modal-component">
				<div className="confirm-modal-header">
					<span>{title}</span>
				</div>
				<div className="confirm-modal-content">
					{handleDefaultBody()}
					<div className="confirm-modal-footer">{handleButtons()}</div>
				</div>
			</div>
		)
	}

	return show ? (
		<div className="modal-main-backdrop">{mainContent()}</div>
	) : (
		<></>
	)
}

export default ConfirmModal
