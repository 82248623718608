import { HelpSvg } from 'assets/SvgIcons/HelpSvg'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '../Input'
import { TooltipInfo } from '../TooltipInfo'
import './styles.scss'

export interface ValuesForm {
	numserie: string
}

type Props = {
	children: React.ReactElement
}

export const SearchSerial = ({ children }: Props) => {
	const [toggleTooltip, setToggleTooltip] = useState<boolean>(false)

	const {
		register,
		formState: { errors }
	} = useFormContext<ValuesForm>()

	const onToggleTooltip = () => {
		if (!toggleTooltip) {
			setToggleTooltip(true)
		} else {
			setToggleTooltip(false)
		}
	}
	return (
		<div className="container-main">
			<form>
				<div className="wrap-form">
					<div className="input-icon-wrap">
						{toggleTooltip && <TooltipInfo onToggleTooltip={onToggleTooltip} />}
						<span className="input-icon" onClick={onToggleTooltip}>
							<HelpSvg />
						</span>
						<Input
							type="text"
							placeholder="Digite o número de série"
							innerRef={{ ...register('numserie', { required: true }) }}
						/>
					</div>
					<small className="text-danger">
						{errors.numserie && (
							<span>Por favor preencha o campo corretamente.</span>
						)}
					</small>
				</div>
			</form>
			{children}
		</div>
	)
}
