import { AxiosProgressEvent } from 'axios'
import filesize from 'filesize'
import { uniqueId } from 'lodash'
import React, { useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { toast } from 'react-toastify'
import { uploadFile } from 'services'
import FileList from './FileList'
import './styles.scss'

interface UploadedFilesProps {
	file?: File
	id: string
	name: string
	readableSize: string
	size: number
	type: string
	progress?: number
	uploaded: boolean
	error?: boolean
	url: string | null
}

interface UpdateFileData {
	progress?: number
	id?: string
	uploaded?: boolean
	error?: boolean
	url?: string
}

interface Props {
	onUploaded: (uploadedFiles: any[]) => void
	keyUpload: string
}

const MAX_SIZE = 30 * 1024 * 1024

const UploadComponent: React.FC<Props> = ({ onUploaded, keyUpload }) => {
	const [uploadedFiles, setUploadedFiles] = useState<UploadedFilesProps[]>([])

	useEffect(() => {
		onUploaded(uploadedFiles)
	}, [uploadedFiles])

	const handleUpload = (files: File[]) => {
		const formattedFiles = files.map((file: any) => ({
			file,
			id: uniqueId(),
			name: file.name,
			readableSize: filesize(file.size),
			size: file.size,
			type: file.type,
			test: keyUpload,
			progress: 0,
			uploaded: false,
			error: false,
			url: null
		}))

		onUploaded(uploadedFiles)

		const newUploadedFiles = [...uploadedFiles, ...formattedFiles]

		let soma = 0
		for (let i = 0; i < newUploadedFiles?.length; i++) {
			soma += newUploadedFiles[i].size
		}

		if (soma < MAX_SIZE) {
			setUploadedFiles(newUploadedFiles)
			formattedFiles.forEach(processUpload)
		} else {
			toast.error(
				'Tamanho máximo excedido. Seus arquivos não devem ultrapassar 30MB.'
			)
		}
	}

	function updateFile(id: string, data: UpdateFileData) {
		setUploadedFiles((state) =>
			state.map((uploadedFile) => {
				if (id === uploadedFile.id) {
					return { ...uploadedFile, ...data }
				}

				return uploadedFile
			})
		)
	}

	async function processUpload(uploadedFile: UploadedFilesProps) {
		try {
			const data = new FormData()

			if (!uploadedFile.file) return

			data.append('file', uploadedFile.file, uploadedFile.name)

			const response = await uploadFile.post('upload', data, {
				onUploadProgress: (event: AxiosProgressEvent) => {
					if (!event?.total) return

					const progress = Math.round((event.loaded * 100) / event.total)
					updateFile(uploadedFile.id, {
						progress
					})
				}
			})

			updateFile(uploadedFile.id, {
				uploaded: true,
				id: response.data.key,
				url: response.data.url
			})
		} catch (error) {
			updateFile(uploadedFile.id, {
				error: true
			})
		}
	}

	async function handleDelete(id: string) {
		await uploadFile.delete(`upload/${id}`)

		setUploadedFiles(uploadedFiles.filter((file) => file.id !== id))
	}

	const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
		if (!isDragActive) {
			return <p>Selecionar arquivos...</p>
		}

		if (isDragReject) {
			return <p>Arquivo não suportado</p>
		}

		return <p>Solte os arquivos aqui</p>
	}

	return (
		<>
			<Dropzone
				accept="image/jpg,
			image/jpeg,
			image/pjpeg,
			image/png,
			video/mp4,
			video/avi,
			video/mkv,
			.mov,
			video/quicktime,
			application/pdf,
			.msg,
			audio/mp3,
			audio/mpeg,
			audio/ogg,
			"
				onDropAccepted={handleUpload}
			>
				{({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
					<div
						className={`dropzone ${
							isDragReject ? 'dragReject' : isDragActive ? 'dragActive' : ''
						}`}
						{...getRootProps()}
					>
						<input {...getInputProps()} />
						{renderDragMessage(isDragActive, isDragReject)}
					</div>
				)}
			</Dropzone>
			<span>Ou arraste e solte os aquivos aqui</span>
			<div className="file-list">
				{!!uploadedFiles?.length && (
					<FileList files={uploadedFiles} onDelete={handleDelete} />
				)}
			</div>
		</>
	)
}

export default UploadComponent
