import React, { ReactNode } from 'react'
import Modal from 'shared/components/Modal'
import './styles.scss'

interface Props {
	show: boolean
	onClose: () => void
	itemSelect?: any
	children: ReactNode
}

export const ItensDetailsListAccessoriesRoot = ({
	show,
	onClose,
	children
}: Props) => {
	return (
		<Modal
			title="ACESSÓRIOS"
			showClose={true}
			enableAwayClose={true}
			show={show}
			onClose={onClose}
		>
			{children}
		</Modal>
	)
}
