import produce from 'immer'

const INITIAL_STATE = {
	availablePendencyList: {
		administrative_pending: [],
		commercial_pending: [],
		financial_pending: [],
		shipment_pending: []
	},
	paymentFormList: [],
	salespersonList: [],
	priceTableList: [],
	natureList: [],
	clientGroups: [],
	clientSegments: [],
	operationTypes: [],
	carrierList: [],
	protheusUserList: [],
	countyList: [],
	ledgerAccountList: [],
	costCenterList: [],
	storageList: [],
	regionList: [],
	defectList: []
}

const baseLists = (state = INITIAL_STATE, action) => {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@auth/SIGN_OUT': {
				draft.availablePendencyList.administrative_pending = []
				draft.availablePendencyList.commercial_pending = []
				draft.availablePendencyList.financial_pending = []
				draft.availablePendencyList.shipment_pending = []
				draft.paymentFormList = []
				draft.salespersonList = []
				draft.priceTableList = []
				draft.natureList = []
				draft.clientGroups = []
				draft.clientSegments = []
				draft.operationTypes = []
				draft.carrierList = []
				draft.protheusUserList = []
				draft.countyList = []
				draft.ledgerAccountList = []
				draft.costCenterList = []
				draft.storageList = []
				draft.regionList = []
				draft.defectList = []
				break
			}
			case '@baseLists/SET_AVAILABLE_PENDENCY_LIST':
				draft.availablePendencyList = action.payload.availablePendencyList
				break

			case '@baseLists/SET_STORED_PAYMENT_FORM_LIST':
				draft.paymentFormList = action.payload.paymentFormList
				break

			case '@baseLists/SET_STORED_PRICE_TABLE_LIST':
				draft.priceTableList = action.payload.priceTableList
				break

			case '@baseLists/SET_STORED_PERSON_SALES_LIST':
				draft.salespersonList = action.payload.salespersonList
				break

			case '@baseLists/SET_STORED_NATURE_LIST':
				draft.natureList = action.payload.natureList
				break

			case '@baseLists/SET_STORED_CLIENT_GROUPS':
				draft.clientGroups = action.payload.clientGroups
				break

			case '@baseLists/SET_STORED_CLIENT_SEGMENTS':
				draft.clientSegments = action.payload.clientSegments
				break

			case '@baseLists/SET_STORED_OPERATION_TYPES':
				draft.operationTypes = action.payload.operationTypes
				break

			case '@baseLists/SET_STORED_CARRIER_LIST':
				draft.carrierList = action.payload.carrierList
				break

			case '@baseLists/SET_STORED_PROTHEUS_USER_LIST':
				draft.protheusUserList = action.payload.protheusUserList
				break

			case '@baseLists/SET_STORED_COUNTY_LIST':
				draft.countyList = action.payload.countyList
				break

			case '@baseLists/SET_STORED_LEDGER_ACCOUNT_LIST':
				draft.ledgerAccountList = action.payload.ledgerAccountList
				break

			case '@baseLists/SET_STORED_COST_CENTER_LIST':
				draft.costCenterList = action.payload.costCenterList
				break

			case '@baseLists/SET_STORED_STORAGE_LIST':
				draft.storageList = action.payload.storageList
				break

			case '@baseLists/SET_STORED_REGION_LIST':
				draft.regionList = action.payload.regionList
				break

			case '@baseLists/SET_STORED_DEFECT_LIST':
				draft.defectList = action.payload.defectList
				break
			default:
				return state
		}
	})
}

export default baseLists
