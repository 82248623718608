import { Button } from '@material-ui/core'
import $ from 'jquery'
import React from 'react'
import { Container, Footer, Message } from './styles'

interface Props {
	changeType: string
	status: string
	id: number
	client?: string
}

const ConfirmChanges: React.FC<Props> = ({
	changeType,
	status,
	id,
	client
}) => {
	return (
		<Container>
			{changeType == 'delete' ? (
				status == 'true' ? (
					<Message>
						Localização deletada com sucesso!
						<Footer>
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									$(`.confirmChange${id}`).toggle()
								}}
							>
								Ok
							</Button>
						</Footer>
					</Message>
				) : (
					<Message>
						Ocorreu um problema!<br></br>
						Verifique se a Localização está sendo usada por algum equipamento.
						<Footer>
							<Button
								color="primary"
								variant="contained"
								onClick={() => {
									$(`.confirmChange${id}`).toggle()
								}}
							>
								Ok
							</Button>
						</Footer>
					</Message>
				)
			) : status == 'true' ? (
				<Message>
					Localização adicionada com sucesso!
					<Footer>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								$(`.addBtn${client}`).css('left', '0px')
								$(`.equips${client}`).css('left', '0px')
								$(`.addLocal${client}`).css('left', '2000px')
								$(`.addBtn${client}`).css('height', 'auto')
								$(`.equips${client}`).css('height', 'auto')
								$(`.confirmAdd`).toggle()
							}}
						>
							Ok
						</Button>
					</Footer>
				</Message>
			) : (
				<Message>
					Ocorreu um problema!<br></br>
					Verifique se todas as informações foram preenchdas corretamente.
					<Footer>
						<Button
							color="primary"
							variant="contained"
							onClick={() => {
								$(`.confirmAdd`).toggle()
							}}
						>
							Ok
						</Button>
					</Footer>
				</Message>
			)}
		</Container>
	)
}

export default ConfirmChanges
