import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import { ItensDetailsListAccessoriesBodyViewingMode } from './ItensDetailsListAccessoriesBody/ItensDetailsListAccessoriesBodyViewingMode'
import { AccessoriesListHeader } from './ItensDetailsListAccessoriesHeader'
import './styles.scss'

interface Props {
	itemSelect?: any
}

export const ItensDetailsListAccessoriesViewingMode = ({
	itemSelect
}: Props) => {
	const { viewType } = useContext(DeviceContextElement)

	return (
		<>
			<div className="new-accessories-container">
				<div className="accessories-search-container">
					<div className="order-item-name">
						<span>Produto: </span>
						<p className="order-item-name-value">
							{String(`${itemSelect?.code} - ${itemSelect?.description}`)}
						</p>
					</div>
				</div>

				{viewType !== ViewType.MOBILE && <AccessoriesListHeader />}
				<ItensDetailsListAccessoriesBodyViewingMode
					accessoriesList={itemSelect.accessories}
				/>
			</div>
		</>
	)
}
