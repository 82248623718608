import styled from 'styled-components'

export const Container = styled.div`
	margin-bottom: 50px;
`
export const Header1 = styled.div`
	display: flex;
	height: 4.25vh;
	background-color: hsla(0, 0%, 86.7%, 0.3);
	border: 1px solid rgba(0, 0, 0, 0.3);
	border-radius: 3px 3px 0px 0px;
	font-weight: 500;
	font-size: 13px;
	line-height: 30px;
`

export const TechNameCol = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: left;
	width: 30%;
	padding-left: 20px;
	padding-right: 10px;
`

export const StatusCol = styled.div`
	display: flex;
	width: 10%;
`

export const StateCol = styled.div`
	display: flex;
	width: 10%;
`

export const CityCol = styled.div`
	display: flex;
	width: 20%;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: left;
`

export const AddressCol = styled.div`
	display: flex;
	width: 30%;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: left;
`

export const Toolbar = styled.div`
	display: flex;
	margin-bottom: 10px;
`
export const SearchBar = styled.div`
	display: flex;
	flex-direction: row-reverse;
	flex-grow: 1;
`

export const Content = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 1;
`

export const Table = styled.div`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
`

export const Header = styled.div`
	font-size: 30px;
	line-height: 30px;
	font-weight: 700;
	margin-bottom: 40px;
`

export const Link = styled.div`
	color: #333;
	cursor: auto;
	:hover {
		color: #333;
	}
`

export const Div1 = styled.div`
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	@media screen and (max-width: 767px) {
		font-weight: 600;
	}
`
export const Line = styled.div`
	border-style: none solid solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.3);
	background-color: #fff;
	line-height: 40px;
	height: auto;
`
export const ContainerTech = styled.div`
	display: flex;
	background-color: #fff;
	${Line}:hover & {
		background-color: rgba(0, 0, 0, 0.21);
	}
`

export const CodPRP = styled.div`
	display: flex;
	width: 10%;
	padding-left: 10px;
	padding-right: 10px;
	justify-content: left;
`
