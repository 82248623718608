import React from 'react'
import MobileHeader from 'shared/components/MobileHeader'

interface Params {
	show: boolean
	title: string
	subTitle?: string
	defaultTitle?: string
	onClose: () => void
	children?: React.ReactNode
}

const ModalMobile: React.FC<Params> = ({
	show,
	title,
	subTitle,
	defaultTitle,
	children,
	onClose
}) => {
	const mainContent = () => {
		return (
			<div className="modal-main-backdrop">
				<div className="modal-mobile-component">
					<div className="modal-mobile-header-content">
						<MobileHeader
							{...{ title, subTitle, defaultTitle, action: onClose }}
						/>
					</div>
					<div className="modal-mobile-main-content">{children}</div>
				</div>
			</div>
		)
	}

	return show ? mainContent() : <></>
}

export default ModalMobile
