import React from 'react'
import './styles.scss'

interface SelectProps extends React.ComponentPropsWithoutRef<'select'> {
	innerRef?: any
}

export const Select = ({ innerRef, ...rest }: SelectProps) => (
	<select className="select-install-and-search" {...innerRef} {...rest} />
)
