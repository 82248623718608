import { createContext } from 'react'
import { VersionType } from './VersionContext'

export interface VersionContextType {
	version: VersionType | null
	newVersion: boolean
	show: boolean
	onUpdateClick: () => void
}

export const VersionContextElement = createContext<VersionContextType>({
	version: {} as VersionType,
	newVersion: {} as boolean,
	show: {} as boolean,
	onUpdateClick: (): void => {}
})
