import React from 'react'
import './styles.scss'

const OrdersListHeader = () => {
	return (
		<div className="orders-list-header">
			<ul className="orders-list-item orders-list-legend" />
			<ul className="orders-list-item orders-list-orders">
				<span>Pedido</span>
			</ul>
			<ul className="orders-list-item orders-list-company-name">
				<span>Razão Social</span>
			</ul>
			<ul className="orders-list-item orders-list-category">
				<span>Categoria</span>
			</ul>
			<ul className="orders-list-item orders-list-state">
				<span>UF</span>
			</ul>
			<ul className="orders-list-item orders-list-issue-date">
				<span>Emissão</span>
			</ul>
			<ul className="orders-list-item orders-list-delivery-date">
				<span>Entrega</span>
			</ul>
			<ul className="orders-list-item orders-list-salesperson">
				<span>Representante</span>
			</ul>
			<ul className="orders-list-item orders-list-status">
				<span>Status</span>
			</ul>
			<ul className="orders-list-item orders-list-follow-up">
				<span>Follow up</span>
			</ul>
			<ul className="orders-list-item orders-list-more-options" />
		</div>
	)
}
export default OrdersListHeader
