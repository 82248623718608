import { Checkbox, FormControlLabel } from '@material-ui/core'
import zonesList from 'assets/js/fs.json'
import React, { useState } from 'react'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'

interface Props {
	show: boolean
	userSelectedZones: string[]
	onSubmit: (selectedZones: string[]) => void
	onClose: () => void
}

const ZonesList: React.FC<Props> = ({
	show,
	userSelectedZones,
	onClose,
	onSubmit
}) => {
	const [selectedZones, setSelectedZones] =
		useState<string[]>(userSelectedZones)
	const notificationsZones = zonesList

	const handleSubmit = () => {
		onSubmit(selectedZones)
		onClose()
	}

	const notificationsZonesOptions = () => {
		return (
			<>
				{notificationsZones.map((zone, index) => {
					return (
						<FormControlLabel
							key={index}
							className="checkbox-item"
							control={
								<Checkbox
									color="secondary"
									onClick={() => {
										handleSelectionZones(zone.initials)
									}}
									checked={selectedZones.includes(zone.initials)}
									value={zone}
								/>
							}
							label={zone.label}
						/>
					)
				})}
			</>
		)
	}

	const handleSelectionZones = (itemSelected: string) => {
		const currentIndex = selectedZones.findIndex((i) => i === itemSelected)
		const newSelectedList = [...selectedZones]

		if (currentIndex === -1) {
			newSelectedList.push(itemSelected)
		} else {
			newSelectedList.splice(currentIndex, 1)
		}

		setSelectedZones(newSelectedList)
	}

	const handleAllChecks = () => {
		const wildCardLabel =
			selectedZones?.length === notificationsZones?.length
				? 'Desmarcar todos'
				: 'Marcar todos'

		return (
			<div className="wildcard-item-option">
				<FormControlLabel
					className="checkbox-item"
					control={
						<Checkbox
							color="secondary"
							onClick={checkAllItems}
							checked={selectedZones?.length === notificationsZones?.length}
						/>
					}
					label={wildCardLabel}
				/>
			</div>
		)
	}

	const checkAllItems = () => {
		if (selectedZones?.length === notificationsZones?.length) {
			setSelectedZones([])
		} else {
			const newSelectedList = notificationsZones.map((zone) => zone.initials)
			setSelectedZones(newSelectedList)
		}
	}

	return (
		<Modal
			title="Selecionar Áreas de Follow-up"
			show={show}
			showClose={true}
			onClose={onClose}
		>
			<div className="notifications-config-main-contain-select-zone">
				<p>Marque as regiões que deseja acompanhar:</p>
				<div className="notifications-zones-list">
					{notificationsZonesOptions()}
					{handleAllChecks()}
				</div>
			</div>
			<div className="notifications-config-footer-contain">
				<Button
					style={{ width: '126px' }}
					size="lg"
					title="Confirmar"
					variant="confirmation-gradient"
					disabled={!selectedZones?.length}
					onClick={handleSubmit}
				/>
			</div>
		</Modal>
	)
}

export default ZonesList
