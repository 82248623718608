import { Transfer } from 'models/Transfer'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext } from 'react'
import TransferClientContent from './TransferClientContent'
import TransferEquipmentContent from './TransferEquipmentContent'
import './styles.scss'

interface Props {
	onNextClick: () => void
	onMeetClick: () => void
}

const TransferInfo: React.FC<Props> = ({ onNextClick, onMeetClick }) => {
	const { transferSelected } = useContext(TransfersDetailsContextElement)

	const disableNextStep = () => {
		const newClient = transferSelected?.transferPayloadParsed.prpInfo.newClient

		return !newClient || !transferSelected?.userId
	}

	const handleButtonContent = () => {
		return transferSelected?.userId ? (
			<CustomButtom
				type="button"
				onClick={onNextClick}
				label="Próximo"
				context="primary"
				disabled={disableNextStep()}
			/>
		) : (
			<CustomButtom
				type="button"
				onClick={onMeetClick}
				label="Atender"
				context="primary"
			/>
		)
	}

	const mainContent = (transfer: Transfer) => {
		return (
			<section className="transfers-info-content">
				<TransferClientContent {...{ transfer }} />
				<TransferEquipmentContent {...{ transfer }} />
				<div className="transfers-info-footer">{handleButtonContent()}</div>
			</section>
		)
	}

	return transferSelected ? mainContent(transferSelected) : <></>
}

export default TransferInfo
