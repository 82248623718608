// import CheckBox from 'shared/components/CheckBox';
import {
	ClientBlockFinancialStatusLabels,
	ClientBlockFinancialStatusTypes
} from 'module/orderManagement/utils/constants'
import React from 'react'
import Radio from 'shared/components/Radio'
import './styles.scss'

interface Params {
	financialStatus?: string[]
	setFinancialStatus: (statusList: string[]) => void
}

const BlockField: React.FC<Params> = ({
	financialStatus,
	setFinancialStatus
}) => {
	const isChecked = (value: string) => {
		if (financialStatus?.length) {
			return financialStatus.includes(value)
		}
		return false
	}

	const onClick = (statusSelected: string) => {
		setFinancialStatus([statusSelected])
	}

	return (
		<div className="block-filter">
			<div className="block-filter-header">
				<span>Bloqueio financeiro</span>
			</div>

			<form className="block-filter-fields">
				<div>
					<Radio
						type="radio"
						variant="blue"
						id={'block'}
						checked={isChecked(ClientBlockFinancialStatusTypes.BLOCK)}
						onChange={() => onClick(ClientBlockFinancialStatusTypes.BLOCK)}
						name={ClientBlockFinancialStatusLabels.BLOCK}
					/>
				</div>
				<div>
					<Radio
						type={'radio'}
						variant="blue"
						id={'not-block'}
						checked={isChecked(ClientBlockFinancialStatusTypes.NOT_BLOCK)}
						onChange={() => onClick(ClientBlockFinancialStatusTypes.NOT_BLOCK)}
						name={ClientBlockFinancialStatusLabels.NOT_BLOCK}
					/>
				</div>
			</form>
		</div>
	)
}

export default BlockField
