import { ClickAwayListener } from '@material-ui/core'
import React from 'react'
import { MdClose } from 'react-icons/md'
import './styles.scss'

type NewModalProps = {
	width?: number
	onClose: () => void
	title?: string
	children: React.ReactNode
	awayClose: boolean
	show?: boolean
}

export const NewModal = ({
	width,
	onClose,
	title,
	children,
	awayClose
}: NewModalProps) => {
	const mainChildren = () => (
		<div
			className="modal__component-install-and-search"
			style={{ width: `${width}px` }}
		>
			<div className="modal__component-header">
				<span className="modal__component-header-title">{title}</span>
				<div className="modal__component-header-close-action" onClick={onClose}>
					<MdClose />
				</div>
			</div>
			<div className="modal__component-main">{children}</div>
			<div className="modal__component-footer">
				<div style={{ width: '16.7%', backgroundColor: '#103451' }} />
				<div style={{ width: '16.7%', backgroundColor: '#0B5186' }} />
				<div style={{ width: '16.7%', backgroundColor: '#E47F2E' }} />
				<div style={{ width: '16.7%', backgroundColor: '#D75741' }} />
				<div style={{ width: '16.7%', backgroundColor: '#B51C1C' }} />
				<div style={{ width: '16.7%', backgroundColor: '#93143D' }} />
			</div>
		</div>
	)

	const handleAwayContent = () => {
		return awayClose ? (
			<ClickAwayListener onClickAway={onClose} mouseEvent="onMouseUp">
				{mainChildren()}
			</ClickAwayListener>
		) : (
			mainChildren()
		)
	}

	return (
		<div className="modal-main-backdrop-install-and-search">
			{handleAwayContent()}
		</div>
	)
}
