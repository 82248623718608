import helpIcon from 'assets/image/helpIcon.svg'
import { Order } from 'module/orderManagement/domain/models/order'
import { InstallmentsList } from 'module/orderManagement/utils/constants'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmModal from 'shared/Modals/ConfirmModal'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import Select from 'shared/components/Select'
import { OrderClientInstallmentEditingMode } from './NewOrderClientInstallment/OrderClientInstallmentEditingMode'
import './styles.scss'

interface OrderClientInstallmentsProps {
	show: boolean
	onClose: () => void
	order?: Order
	installmentForm: any
	fieldArray: any
}

export const OrderClientInstallmentsEditingMode = ({
	show,
	onClose,
	order,
	installmentForm,
	fieldArray
}: OrderClientInstallmentsProps) => {
	const [selectedQtyParcel, setSelectedQtyParcel] = useState<string>()
	const [installments, setInstallments] = useState<any[]>()
	const [totalPriceInstallment, setTotalPriceInstallment] = useState<any>('-')
	const [showConfirmInstallmentsModal, setShowConfirmInstallmentsModal] =
		useState<boolean>(false)

	const { watch, setValue } = installmentForm
	const watchedInstallments = watch('installments')

	const { getValues, reset, handleSubmit } = installmentForm

	const { fields, replace } = fieldArray

	const handleSumInstallment = () => {
		if (!selectedQtyParcel) return 0
		let sumTotalInstallment = 0
		for (let index = 0; index < parseInt(selectedQtyParcel); index++) {
			const element = getValues(`installments.${index}.value`)
				? getValues(`installments.${index}.value`)
				: 0
			sumTotalInstallment = sumTotalInstallment + element
			setTotalPriceInstallment(Number(sumTotalInstallment.toFixed(2)))
		}
	}

	const separateArray = (array2: any, max?: string) => {
		const array = array2 || []
		const newArray = []
		if (max) {
			for (let index = 0; index < parseInt(max); index++) {
				newArray.push(array[index])
			}
		}
		replace(newArray)
		setInstallments(newArray)
	}

	useEffect(() => {
		if (order?.installments) {
			setSelectedQtyParcel(order.installments?.length?.toString())
		}
	}, [order?.installments])

	useEffect(() => {
		setSelectedQtyParcel(watchedInstallments?.length?.toString())
	}, [watchedInstallments])

	const validationDateValue = () => {
		if (!selectedQtyParcel) return 0
		let parcelDate = 0
		for (let index = 0; index < parseInt(selectedQtyParcel); index++) {
			const element = getValues(`installments.${index}.date`)
			if (!element) {
				parcelDate += 1
			}
		}
		if (parcelDate > 0) {
			return true
		}
		return false
	}

	const onChangeParcels = (data: any) => {
		setValue('installments', data)
	}

	const onSubmit = (data: any) => {
		setInstallments(data.installments)
		replace(data.installments)
		setShowConfirmInstallmentsModal(false)
		onChangeParcels(data.installments)
		onClose()
	}

	const installmentValidation = () => {
		return (
			<ConfirmModal
				title="PARCELAS"
				confirmLabel="Sim"
				cancelLabel="Não"
				onCancel={() => setShowConfirmInstallmentsModal(false)}
				show={showConfirmInstallmentsModal}
				onConfirm={handleSubmit(onSubmit)}
			>
				<span style={{ textAlign: 'center' }}>
					O valor da soma das parcelas não coincide com o valor total do pedido.
					<br />
					Deseja prosseguir mesmo assim?
				</span>
			</ConfirmModal>
		)
	}

	const handleOnConfirmInstallment = (data: any) => {
		if (validationDateValue()) {
			toast.error(`Para prosseguir, preencha a data de todas as parcelas.`)
		} else {
			order?.total_with_taxes === totalPriceInstallment
				? onSubmit(data)
				: setShowConfirmInstallmentsModal(true)
		}
	}

	return (
		<>
			<Modal
				title="PARCELAS"
				showClose={true}
				enableAwayClose={true}
				show={show}
				onClose={onClose}
			>
				<div className="installments-container">
					<div className="installments-details-header">
						<div className="installments-header-contain">
							<span>
								Defina o número de parcelas e em seguida informe valores e datas
								personalizadas para cada uma:
							</span>
							<div className="installments-details-header-contain">
								<div>
									<Select
										options={InstallmentsList}
										onSelectOptions={(value) => {
											reset()
											separateArray(installments, value[0])
											setSelectedQtyParcel(value[0])
										}}
										selectedOption={[
											selectedQtyParcel ? selectedQtyParcel : ''
										]}
									/>
								</div>
								<div className="compare-price">
									<div>
										<label htmlFor="">Preço total: </label>
										<span>
											{order?.total_with_taxes.toLocaleString('pt-br', {
												minimumFractionDigits: 2,
												style: 'currency',
												currency: 'BRL'
											})}
											<img
												title="Valor total sem frete e sem impostos"
												src={helpIcon}
												alt="observation icon"
											/>
										</span>
									</div>
									<div>
										<label htmlFor="">Parcelas: </label>
										<span>
											{totalPriceInstallment.toLocaleString('pt-br', {
												minimumFractionDigits: 2,
												style: 'currency',
												currency: 'BRL'
											})}
											<img
												title="Valor total sem frete e sem impostos"
												src={helpIcon}
												alt="observation icon"
											/>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="installments-details-body">
						{fields?.map((item: any, index: number) => {
							return (
								<OrderClientInstallmentEditingMode
									parcel={item}
									key={index}
									index={index}
									form={installmentForm}
									selectedQtyParcel={selectedQtyParcel}
									{...{ handleSumInstallment }}
								/>
							)
						})}
					</div>
					<div className="installments-details-action">
						<Button
							variant="confirmation-gradient"
							title="SALVAR"
							size="lg"
							onClick={handleSubmit(handleOnConfirmInstallment)}
						/>
						<Button
							variant="cancellation-gradient"
							title="CANCELAR"
							size="lg"
							onClick={onClose}
						/>
					</div>
				</div>
			</Modal>
			{installmentValidation()}
		</>
	)
}
