import React from 'react'
import './styles.scss'

type Props = {
	billed: boolean
}
const AllBalanceStorageListHeader = ({ billed }: Props) => {
	return (
		<div className="all-balance-storage-list-header">
			<ul className="all-balance-storage-list-item all-balance-storage-list-ul" />
			<ul className="all-balance-storage-list-item all-balance-storage-list-product">
				<span>Produto</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-description">
				<span>Descrição</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-amount">
				<span>Quantidade</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-09</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-01</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-06</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-07</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-E2</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-50</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-SP</span>
			</ul>
			<ul className="all-balance-storage-list-item all-balance-storage-list-storage">
				<span>A-PE</span>
			</ul>
			{!!billed && (
				<ul className="all-balance-storage-list-item all-balance-storage-list-origin">
					<span>Nota fiscal</span>
				</ul>
			)}
			<ul className="all-balance-storage-list-item all-balance-storage-list-more-options" />
		</div>
	)
}
export default AllBalanceStorageListHeader
