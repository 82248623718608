import { TransferEquipment } from 'models/Transfer'
import React from 'react'
import { TransferStatus } from 'utils/Constants'
import { normalizeCGC, parseDate } from 'utils/Pipe'
import './styles.scss'

interface Props {
	equipment?: TransferEquipment
	status?: TransferStatus
}

const TransferEquipmentInfo: React.FC<Props> = ({ equipment, status }) => {
	const handleWarrantDate = () => {
		return equipment?.warrantDate ? (
			<span>
				<b>Data de Garantia:</b>
				{parseDate(equipment.warrantDate, 'DD/MM/YYYY')}
			</span>
		) : (
			<span>
				<b>Código do Cliente:</b>
				{equipment?.clientCode}
			</span>
		)
	}

	const clientContent = () => {
		const label =
			status === TransferStatus.FINISHED ? 'Cliente Anterior' : 'Cliente Atual'
		return (
			<span title={equipment?.currentClient || ''}>
				<b>{label}:</b>
				{equipment?.currentClient}
			</span>
		)
	}

	return (
		<div className="transfer-equip-info">
			<div>
				<span title={equipment?.name || ''}>
					<b>Equipamento:</b>
					{equipment?.name}
				</span>
				<span>
					<b>Número de Série:</b>
					{equipment?.serialNumber}
				</span>
				<span>
					<b>Data de Venda:</b>
					{equipment?.saleDate
						? parseDate(equipment?.saleDate, 'DD/MM/YYYY')
						: ''}
				</span>
			</div>
			<div>
				{clientContent()}
				<span>
					<b>CPF/CNPJ:</b>
					{normalizeCGC(equipment?.cpfCnpj)}
				</span>
				{handleWarrantDate()}
			</div>
		</div>
	)
}

export default TransferEquipmentInfo
