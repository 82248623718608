import OrderContext from 'module/orderManagement/context/OrderContext/OrderContext'
import React from 'react'
import { NewOrder } from './Web'

const NewOrderContextEnvolve: React.FC = () => {
	return (
		<OrderContext>
			<NewOrder />
		</OrderContext>
	)
}

export default NewOrderContextEnvolve
