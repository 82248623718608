export const firstIndex = 0
export const lastIndex = (list: any[]) => list?.length - 1

export const firstEl = (list: any[]) => list[firstIndex]
export const lastEl = (list: any[]) => list[lastIndex(list)]

export enum RouteList {
	DEFAULT = '/index.html',
	HOME = '/',
	EQUIPMENTS = '/equipamentos',
	EQUIPMENTS_ORDERS = '/equipamentos/:serial/atendimentos',
	EQUIPMENTS_ORDERS_VIEW = '/equipamentos/:serial/atendimentos/:orderCode',
	ORDERS = '/atendimentos',
	ORDERS_TIMELINE = '/atendimentos/:orderCode',
	REDEFINE_PASS = '/nova-senha/:auth',
	RESET_PASS = '/restaurarSenha',
	NEW_ACCOUNT = '/nova-conta',
	CONFIG_USER = '/configuracoes',
	NOTIFICATIONS = '/notificacoes',
	STORES_SETTINGS = '/configurar-lojas'
}

export enum StepTypes {
	CREATE_OS = 'create_os',
	NEW_VISIT = 'new_visit',
	FOLLOW_UP = 'follow-up',
	QUOTE = 'quote',
	ORDER = 'order',
	INVOICE = 'invoice',
	CLOSE_OS = 'close_os',
	INTERNAL_OBS = 'internal_obs',
	INVENTORY_ORDER = 'inventory_order',
	INVENTORY_INVOICE = 'inventory_invoice',
	AUTH = 'internal_auth'
}

export enum TransferTabOptions {
	INFO = 0,
	INIT_TRANSFER = 1
}

export enum TransferTabNames {
	INFO = 'Informações',
	INIT_TRANSFER = 'Transferir'
}

export enum TransferSteps {
	EQUIPMENT = 0,
	CLIENT = 1,
	CONTACT = 2,
	SUMMARY = 3
}

export enum TransferOrigin {
	APP = 'APP',
	PRP = 'PRP'
}

export enum TransferStatus {
	OPEN = 'OPEN',
	IN_PROGRESS = 'IN_PROGRESS',
	FINISHED = 'FINISHED',
	REMOVED = 'REMOVED'
}

export const TransferStepsItemList: StepItem[] = [
	{ step: TransferSteps.EQUIPMENT, label: 'Equipamento' },
	{ step: TransferSteps.CLIENT, label: 'Cliente' },
	{ step: TransferSteps.CONTACT, label: 'Contato' },
	{ step: TransferSteps.SUMMARY, label: 'Resumo' }
]

export enum TransferUpdateTypes {
	CLIENT_UPDATE = 'CLIENT_UPDATE',
	EQUIP_UPDATE = 'EQUIP_UPDATE',
	TYPE_UPDATE = 'TYPE_UPDATE',
	MEET_UPDATE = 'MEET_UPDATE',
	REMOVE = 'REMOVE'
}

export interface StepItem {
	step: number
	label: string
}

export enum ViewType {
	WEB = 'web',
	TABLET = 'tablet',
	MOBILE = 'mobile'
}

export enum DeviceType {
	WEB = 'device-web',
	MOBILE = 'device-mobile'
}

export enum OrientationType {
	PORTRAIT = 'port',
	LANDSCAPE = 'land'
}

export enum AppThemes {
	LIGHT = 'light-theme',
	DARK = 'dark-theme',
	TRANSITION = 'changing-theme'
}
