import closePic from 'assets/image/close.png'
import clientImage from 'assets/image/headercliazul.png'
import equipImage from 'assets/image/headerequipazul.png'
import itemsImage from 'assets/image/headeritensorcazul.png'
import headerImage from 'assets/image/headerorcatazul.jpg'
import paymentImage from 'assets/image/headerpgtoazul.png'
import footerImage from 'assets/image/rodapeazul.jpg'
import Files from 'models/OsFiles'
import { StepType } from 'models/StepTypeEnum'
import Technician from 'models/Technician'
import FileModal from 'module/technicalServices/components/FileModal'
import moment from 'moment'
import 'moment/locale/pt-br'
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { getBase64, getPartRequest } from 'services/index'
import SuccessMsg from 'shared/SuccessMsg'
import LoadingProgress from 'shared/components/LoadingProgress'
import { handleDefect } from 'utils/Pipe'
import 'utils/locale/pt'
import Budgeting from '../../Budgeting'
import { CreatedAt, DivActions, OrderActions } from './styles'
import './styles.css'
import './styles.scss'

interface Props {
	id?: string
	technician?: Technician
	serviceOrderNumber: number
	appointmentDate?: string
	datePartShipping?: string
	dateCloseService?: string
	createdAt?: string
	stepType?: string
	order_id?: number
	isLast?: boolean
	msg?: string
	obs: string
	type?: string
	originOS?: string
	tech_assistance_files: Files[]
	equipment: any
	client: any
	lastTechAddress: any
	lastTechID: any
	user_name?: string
	index: number
	id_field_control?: string
	files: any[]
	defect: string
}

const Service: React.FC<Props> = ({
	id,
	technician,
	serviceOrderNumber,
	appointmentDate,
	datePartShipping,
	dateCloseService,
	createdAt,
	type,
	obs,
	msg,
	originOS,
	tech_assistance_files,
	files,
	stepType,
	order_id,
	isLast = true,
	equipment,
	client,
	lastTechAddress,
	lastTechID,
	user_name,
	index,
	id_field_control,
	defect
}) => {
	const [base64, setBase64] = useState<string>('')
	const [loading, setLoading] = useState<boolean>(true)
	const [id_protheus, setId_protheus] = useState<string>('')
	const [enablePartModal, setEnablePartModal] = useState<boolean>(false)
	const [partRequest, setPartRequest] = useState<any>()
	const [worked, setWorked] = useState<boolean>()
	const [message, setMessage] = useState<string>('')
	const [status, setStatus] = useState<boolean>(false)

	moment.tz.setDefault('UTC')

	useEffect(() => {
		if (order_id) {
			getPartRequest(order_id).then((response) => {
				if (response.status === 200) {
					setId_protheus(response.data[0]?.code_protheus)
					setPartRequest(response)
				}
			})
		}
	}, [])

	/**
	 * Mount the layout of the date of the creation of the element.
	 * @see Moment
	 */
	const showTheDateOfCreation = () => {
		return (
			<div
				className="template-date-of-creation"
				style={{ display: 'flex', flexDirection: 'row' }}
			>
				<Moment
					format="DD/MM/YYYY"
					locale="pt"
					element={CreatedAt}
					tz={moment.tz.guess()}
				>
					{createdAt}
				</Moment>
				<div>
					{user_name ? <span>&nbsp;- {user_name}</span> : <span>&nbsp;</span>}
				</div>
			</div>
		)
	}

	/**
	 * Mount the layout of the date of the appointment of the element.
	 */
	const showAppointmentDate = () => {
		return (
			<Moment format="DD/MM/YYYY" locale="pt">
				{appointmentDate}
			</Moment>
		)
	}

	const mountItemOpenOsFiles = () => {
		if (
			(type == 'INSTALACAO' ||
				type == 'PRE-INSTALACAO' ||
				type == 'INSTALACAO REVENDA' ||
				type == 'OUTRAS SOLICITACOES') &&
			(originOS == 'INSTALL_APP' || originOS == 'BUSCA_APP')
		) {
			return (
				<div>
					<div>CPF/CNPJ comprador: {msg}</div>
					{!!tech_assistance_files && (
						<>
							{tech_assistance_files.map((file, index) => (
								<button
									key={index}
									style={{
										border: 'none',
										padding: '0px',
										backgroundColor: 'rgba(0,0,0,0)',
										color: 'hsla(215.05, 62.91%, 49.93%, 1)'
									}}
									onClick={() => {
										getBase64(file.id).then((response) => {
											if (response.status === 200) {
												const { data } = response
												const doc = data[0].img_base64
												const name = data[0].name
												const type = data[0].file_type

												downloadBase64File(type, doc, name)
												setLoading(false)
											}
										})
									}}
								>
									{file.name}
								</button>
							))}
							{!!files && (
								<>
									{files.map((file, index) => (
										<>
											<div key={index}>
												<a
													style={{
														border: 'none',
														padding: '0px',
														backgroundColor: 'rgba(0,0,0,0)',
														color: 'hsla(215.05, 62.91%, 49.93%, 1)',
														marginRight: '10px'
													}}
													href={file.link}
													target="_blank"
													rel="noreferrer"
												>
													{file.name}
												</a>
											</div>
										</>
									))}
								</>
							)}
						</>
					)}
				</div>
			)
		} else {
			const message = obs || msg
			return (
				<div>
					{message && <div>Observação: {message}</div>}
					{defect && <>Defeito: {handleDefect(defect)}</>}
					{!!tech_assistance_files && (
						<>
							{tech_assistance_files.map((file, index) => (
								<button
									key={index}
									style={{
										border: 'none',
										padding: '0px',
										backgroundColor: 'rgba(0,0,0,0)',
										color: 'hsla(215.05, 62.91%, 49.93%, 1)',
										marginRight: '10px'
									}}
									onClick={() => {
										getBase64(file.id).then((response) => {
											if (response.status === 200) {
												const { data } = response
												const doc = data[0].img_base64
												const name = data[0].name
												const type = data[0].file_type

												downloadBase64File(type, doc, name)
												setLoading(false)
											}
										})
									}}
								>
									{file.name}
								</button>
							))}
						</>
					)}
					{!!files && (
						<>
							{files.map((file, index) => (
								<div key={index}>
									<a
										style={{
											border: 'none',
											padding: '0px',
											backgroundColor: 'rgba(0,0,0,0)',
											color: 'hsla(215.05, 62.91%, 49.93%, 1)',
											marginRight: '10px'
										}}
										href={file.link}
										target="_blank"
										rel="noreferrer"
									>
										{file.name}
									</a>
								</div>
							))}
						</>
					)}
				</div>
			)
		}
	}

	/**
	 * Mount the layout of the item that represents the Open OS.
	 */
	const mountItemOpenOs = () => {
		return (
			<>
				<div className="template-title">
					Requisição de atendimento técnico recebida
				</div>
				{showTheDateOfCreation()}
				<div className="template-body">{mountItemOpenOsFiles()}</div>
			</>
		)
	}

	const mountItemDeletedOs = () => {
		return (
			<>
				<div className="template-title">Ordem de serviço excluída</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<p>
						Motivo: <span>{msg}</span>
					</p>
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the Appointed OS.
	 */
	const mountItemAppointedOsWithTechnical = () => {
		let internalObs
		try {
			msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
			internalObs = JSON.parse(msg !== undefined ? msg : '')
		} catch (error) {
			internalObs = 'Houve um erro ao buscar a Observação Interna!'
		}
		return (
			<>
				<div className="template-title">Visita técnica agendada</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div>
						<p>
							Seu atendimento técnico foi agendado para o dia{' '}
							<strong>{showAppointmentDate()}</strong> com{' '}
							<strong>{technician?.name}</strong> - celular
							{technician?.phone}
						</p>
						{internalObs?.link ? (
							<div>
								<p>
									Utilize o link abaixo para acompanhar seu atendimento em tempo
									real:
								</p>
								<a
									href={internalObs?.link}
									target="_blank"
									rel="noopener noreferrer"
								>
									{internalObs?.link}
								</a>
							</div>
						) : (
							<>
								<p>
									Um link para acompanhamento em tempo real será disponibilizada
									logo abaixo assim que sua ordem de serviço for recebida e
									atribuída ao técnico.
								</p>
							</>
						)}
					</div>
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents when has to send a piece for the product in OS.
	 */
	const mountItemSendPieceEquipmentOs = () => {
		return (
			<>
				<div className="template-title">Peça enviada</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					A peça foi enviada e em breve um de nossos atendentes entrará em
					contato para agendar o serviço
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the Stop OS.
	 */
	const mountItemStopService = () => {
		return (
			<>
				<div className="template-title">Pausa no atendimento</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					O atendimento foi pausado pois será solicitado o pedido de uma nova
					peça.
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the Rescheduled OS.
	 */
	const mountItemRescheduledOs = () => {
		return (
			<>
				<div className="template-title">
					Visita agendada para o dia {showAppointmentDate()}
				</div>
				{showTheDateOfCreation()}
				<div className="template-body">O serviço foi reagendado.</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the Closed OS.
	 * */
	const mountItemCloseOs = () => {
		msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
		const m = JSON.parse(msg !== undefined ? msg : '')
		return (
			<>
				<div className="template-title">
					Atendimento finalizado com sucesso!
				</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div>
						<div>Contato: {m?.contact}</div>
						<div>Cargo: {m?.occupation}</div>
						<div>Mensagem: {m?.message}</div>
					</div>
					<div>
						{!!tech_assistance_files && (
							<>
								{tech_assistance_files.map((file, index) => (
									<>
										<button
											key={index}
											id={file.id.toString()}
											style={{
												border: 'none',
												padding: '0px',
												backgroundColor: 'rgba(0,0,0,0)',
												color: 'hsla(215.05, 62.91%, 49.93%, 1)',
												marginRight: '10px'
											}}
											onClick={() => {
												getBase64(file.id).then((response) => {
													if (response.status === 200) {
														const { data } = response
														const doc = data[0].img_base64
														const name = data[0].name
														const type = data[0].file_type

														downloadBase64File(type, doc, name)
													}
												})
											}}
										>
											{file.name}
										</button>
									</>
								))}
							</>
						)}
						{!!files && (
							<div>
								{files.map((file, index) => (
									<>
										<div key={index}>
											<a
												style={{
													border: 'none',
													padding: '0px',
													backgroundColor: 'rgba(0,0,0,0)',
													color: 'hsla(215.05, 62.91%, 49.93%, 1)',
													marginRight: '10px'
												}}
												href={file.link}
												target="_blank"
												rel="noreferrer"
											>
												{file.name}
											</a>
										</div>
									</>
								))}
							</div>
						)}
					</div>
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the Follow-up.
	 * */
	const mountItemFollowUp = () => {
		msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
		const m = JSON.parse(msg !== undefined ? msg : '')
		return (
			<>
				<div className="template-title">Follow-up</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div>
						<div>Contato: {m?.contact}</div>
						<div>Cargo: {m?.occupation}</div>
						<div>Mensagem: {m?.message}</div>
					</div>
					<div>
						{!!tech_assistance_files && (
							<>
								{tech_assistance_files.map((file, index) => (
									<>
										<button
											key={index}
											id={file.id.toString()}
											style={{
												border: 'none',
												padding: '0px',
												backgroundColor: 'rgba(0,0,0,0)',
												color: 'hsla(215.05, 62.91%, 49.93%, 1)',
												marginRight: '10px'
											}}
											onClick={() => {
												getBase64(file.id).then((response) => {
													if (response.status === 200) {
														const { data } = response
														const doc = data[0].img_base64
														const name = data[0].name
														const type = data[0].file_type

														downloadBase64File(type, doc, name)
													}
												})
											}}
										>
											{file.name}
										</button>
									</>
								))}
							</>
						)}
						{!!files && (
							<div>
								{files.map((file, index) => (
									<>
										<div key={index}>
											<a
												style={{
													border: 'none',
													padding: '0px',
													backgroundColor: 'rgba(0,0,0,0)',
													color: 'hsla(215.05, 62.91%, 49.93%, 1)',
													marginRight: '10px'
												}}
												href={file.link}
												target="_blank"
												rel="noreferrer"
											>
												{file.name}
											</a>
										</div>
									</>
								))}
							</div>
						)}
					</div>
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the Follow-up.
	 * */
	const mountItemInternalObsFieldControl = () => {
		let internalObs
		try {
			msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
			internalObs = JSON.parse(msg !== undefined ? msg : '')
		} catch (error) {
			internalObs.message = 'Houve um erro ao buscar a Observação Interna!'
		}

		return internalObs.fieldControl ? (
			<>
				<div className="template-title">Visita técnica realizada</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div className="internal-message">
						<p>{internalObs?.message}</p>
						<a
							href={internalObs?.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							{internalObs?.link}
						</a>
						{internalObs?.ratingLink && (
							<>
								<p style={{ marginTop: '10px' }}>
									{internalObs?.ratingMessage}
								</p>
								<a
									href={internalObs?.ratingLink}
									target="_blank"
									rel="noopener noreferrer"
								>
									{internalObs?.ratingLink}
								</a>
							</>
						)}
					</div>
				</div>
			</>
		) : (
			<></>
		)
	}

	/**
	 * Mount the layout of the item that represents the Follow-up.
	 * */
	const mountItemCanceledObsFieldControl = () => {
		let internalObs
		try {
			msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
			internalObs = JSON.parse(msg !== undefined ? msg : '')
		} catch (error) {
			internalObs.message = 'Houve um erro ao buscar a Observação Interna!'
		}

		return internalObs.fieldControl ? (
			<>
				<div className="template-title">
					Visita técnica cancelada no Field Control
				</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div className="internal-message">
						<p>
							O agendamento <strong>{internalObs?.identifier}</strong> foi
							cancelado devido ao não atendimento e retorno do técnico{' '}
							<strong>{technician?.name}</strong> - celular {technician?.phone}.
						</p>
						<a
							href={internalObs?.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							{internalObs?.link}
						</a>
					</div>
				</div>
			</>
		) : (
			<></>
		)
	}

	const mountItemInProgressMaintenance = () => {
		let internalObs
		try {
			msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
			internalObs = JSON.parse(msg !== undefined ? msg : '')
		} catch (error) {
			internalObs.message = 'Houve um erro ao buscar a Observação Interna!'
		}

		return (
			<>
				<div className="template-title">Atendimento em progresso</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div className="internal-message">
						<p>
							Atendimento inicializado por <b>{technician?.name}</b>.
						</p>
					</div>
				</div>
			</>
		)
	}
	const mountItemReportedMaintenance = () => {
		let internalObs
		try {
			msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
			internalObs = JSON.parse(msg !== undefined ? msg : '')
		} catch (error) {
			internalObs.message = 'Houve um erro ao buscar a Observação Interna!'
		}

		return internalObs.fieldControl ? (
			<>
				<div className="template-title">
					Impedimento registrado pelo técnico
				</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div className="internal-message">
						<p>
							Para verificar o impedimento registrado por{' '}
							<b>{technician?.name}</b>, acesse o link:
						</p>
						<a
							href={internalObs?.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							{internalObs?.link}
						</a>
					</div>
				</div>
			</>
		) : (
			<></>
		)
	}

	/**
	 * Mount the layout of the item that represents the ServiceOrderCancelRequest.
	 * */
	const mountItemServiceOrderCancelRequest = () => {
		const m = JSON.parse(msg !== undefined ? msg : '')
		return (
			<>
				<div className="template-title">Solicitação de cancelamento:</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div>
						<div>Solicitante: {m?.message[1]}</div>
						<div>Mensagem do cliente: {m?.message[0]}</div>
					</div>
				</div>
			</>
		)
	}

	const mountItemInternalObs = () => {
		let internalObs
		try {
			msg = String(msg).replace(/\t/g, ' ').replace(/\n/g, ' ')
			internalObs = JSON.parse(msg !== undefined ? msg : '')
		} catch (error) {
			internalObs.message = 'Houve um erro ao buscar a Observação Interna!'
		}

		return (
			<>
				<div className="template-title">Observação Interna</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div className="internal-message">
						<p>Mensagem: {internalObs?.message}</p>
					</div>
					{!!tech_assistance_files && (
						<>
							{tech_assistance_files.map((file, index) => (
								<button
									key={index}
									id={file.id.toString()}
									style={{
										border: 'none',
										padding: '0px',
										backgroundColor: 'rgba(0,0,0,0)',
										color: 'hsla(215.05, 62.91%, 49.93%, 1)',
										marginRight: '10px'
									}}
									onClick={() => {
										getBase64(file.id).then((response) => {
											if (response.status === 200) {
												const { data } = response
												const doc = data[0].img_base64
												const name = data[0].name
												const type = data[0].file_type

												downloadBase64File(type, doc, name)
											}
										})
									}}
								>
									{file.name}
								</button>
							))}
						</>
					)}
					{!!files && (
						<div>
							{files.map((file, index) => (
								<div key={index}>
									<a
										style={{
											border: 'none',
											padding: '0px',
											backgroundColor: 'rgba(0,0,0,0)',
											color: 'hsla(215.05, 62.91%, 49.93%, 1)',
											marginRight: '10px'
										}}
										href={file.link}
										target="_blank"
										rel="noreferrer"
									>
										{file.name}
									</a>
								</div>
							))}
						</div>
					)}
				</div>
			</>
		)
	}

	const mountItemInternalAuth = () => {
		const m = msg
		return (
			<>
				<div className="template-title">Autorização</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div>
						<div>Mensagem: {m}</div>
					</div>
					<div>
						{!!tech_assistance_files && (
							<>
								{tech_assistance_files.map((file, index) => (
									<>
										<button
											key={index}
											id={file.id.toString()}
											style={{
												border: 'none',
												padding: '0px',
												backgroundColor: 'rgba(0,0,0,0)',
												color: 'hsla(215.05, 62.91%, 49.93%, 1)',
												marginRight: '10px'
											}}
											onClick={() => {
												getBase64(file.id).then((response) => {
													if (response.status === 200) {
														const { data } = response
														const doc = data[0].img_base64
														const name = data[0].name
														const type = data[0].file_type

														downloadBase64File(type, doc, name)
													}
												})
											}}
										>
											{file.name}
										</button>
									</>
								))}
							</>
						)}
						{!!files && (
							<>
								{files.map((file, index) => (
									<>
										<div key={index}>
											<a
												style={{
													border: 'none',
													padding: '0px',
													backgroundColor: 'rgba(0,0,0,0)',
													color: 'hsla(215.05, 62.91%, 49.93%, 1)',
													marginRight: '10px'
												}}
												href={file.link}
												target="_blank"
												rel="noreferrer"
											>
												{file.name}
											</a>
										</div>
									</>
								))}
							</>
						)}
					</div>
				</div>
			</>
		)
	}

	/**
	 * Mount the layout of the item that represents the order quotation.
	 * */
	const mountItemOrc = () => {
		if (id_protheus?.trim() !== undefined) {
			return (
				<>
					<div className="template-title">Orçamento criado</div>
					{showTheDateOfCreation()}
					<div className="template-body">
						<div>Orçamento número {id_protheus} criado com sucesso!</div>
						<DivActions>
							<OrderActions
								onClick={() => {
									$(`.resumo`).toggle()
									setEnablePartModal(true)
								}}
							>
								Visualizar
							</OrderActions>

							{!!tech_assistance_files?.length &&
								tech_assistance_files.map((file, index) => (
									<OrderActions
										key={index}
										id={file.id.toString()}
										onClick={() => {
											getBase64(file.id).then((response) => {
												if (response.status === 200) {
													const { data } = response

													let doc = data[0].img_base64
													doc = doc.replace(
														'http://www.praticafornos.com.br/imgti/headerorcatazul.png',
														headerImage
													)
													doc = doc.replace(
														'http://www.praticafornos.com.br/imgti/headercliazul.png',
														clientImage
													)
													doc = doc.replace(
														'http://www.praticafornos.com.br/imgti/headerequipazul.png',
														equipImage
													)
													doc = doc.replace(
														'http://www.praticafornos.com.br/imgti/headeritensorcazul.png',
														itemsImage
													)
													doc = doc.replace(
														'http://www.praticafornos.com.br/imgti/headerpgtoazul.png',
														paymentImage
													)
													doc = doc.replace(
														'http://www.praticafornos.com.br/imgti/rodapeazul.png',
														footerImage
													)

													const base64Str = btoa(doc)
													const byteCharacters = atob(base64Str)
													const byteNumbers = new Array(byteCharacters?.length)
													for (let i = 0; i < byteCharacters?.length; i++) {
														byteNumbers[i] = byteCharacters?.charCodeAt(i)
													}
													const byteArray = new Uint8Array(byteNumbers)
													const file = new Blob([byteArray], {
														type: 'text/html'
													})
													const fileURL = URL.createObjectURL(file)
													window.open(fileURL)
												}
											})
										}}
									>
										Orçamento
									</OrderActions>
								))}
						</DivActions>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="template-title">Orçamento criado</div>
					{showTheDateOfCreation()}
					<div className="template-body">Gerando orçamento no Protheus</div>
				</>
			)
		}
	}

	/**
	 * Mount the layout of the item that represents the order quotation.
	 * */
	const mountItemPed = () => {
		const title =
			stepType === StepType.ORDER
				? 'Pedido Efetivado'
				: 'Pedido de Reposição Efetivado'

		if (id_protheus?.trim() !== undefined) {
			return (
				<>
					<div className="template-title">{title}</div>
					{showTheDateOfCreation()}
					<div className="template-body">
						<div>Número do pedido: {id_protheus}</div>
						<DivActions>
							<OrderActions
								onClick={() => {
									$(`.resumo`).toggle()
									setEnablePartModal(true)
								}}
							>
								{' '}
								Visualizar
							</OrderActions>
						</DivActions>
					</div>
				</>
			)
		} else {
			return (
				<>
					<div className="template-title">{title}</div>
					{showTheDateOfCreation()}
					<div className="template-body">Gerando Pedido no Protheus</div>
				</>
			)
		}
	}

	/**
	 * Mount the layout of the item that represents the invoice.
	 * */
	const mountItemNF = () => {
		const title =
			stepType === StepType.INVOICE
				? 'Pedido Faturado'
				: 'Pedido de Reposição Faturado'

		return (
			<>
				<div className="template-title">{title}</div>
				{showTheDateOfCreation()}
				<div className="template-body">
					<div>O pedido {id_protheus} foi faturado.</div>
					<DivActions>
						{tech_assistance_files.map((file, index) => (
							<OrderActions
								key={index}
								id={file.id.toString()}
								onClick={() => {
									getBase64(file.id).then((response) => {
										if (response.status === 200) {
											const { data } = response

											const base64Str = data[0].img_base64
											const byteCharacters = atob(base64Str)
											const byteNumbers = new Array(byteCharacters?.length)
											for (let i = 0; i < byteCharacters?.length; i++) {
												byteNumbers[i] = byteCharacters?.charCodeAt(i)
											}
											const byteArray = new Uint8Array(byteNumbers)
											const file = new Blob([byteArray], {
												type: 'application/pdf;base64'
											})
											const fileURL = URL.createObjectURL(file)
											window.open(fileURL)
										}
									})
								}}
							>
								Nota Fiscal
							</OrderActions>
						))}
					</DivActions>
				</div>
			</>
		)
	}

	/**
	 * Method that chooses which layout to display according to the submitted object.
	 */
	const chooserTheTemplate = () => {
		switch (stepType) {
			case StepType.FOLLOW_UP:
				return mountItemFollowUp()
			case StepType.INTERNAL_OBS:
				return mountItemInternalObs()
			case StepType.INTERNAL_OBS_FIELD_CONTROL:
				return mountItemInternalObsFieldControl()
			case StepType.CANCELED_OS_FIELD_CONTROL:
				return mountItemCanceledObsFieldControl()
			case StepType.REPORTED_OS_FIELD_CONTROL:
				return mountItemReportedMaintenance()
			case StepType.IN_PROGRESS_OS_FIELD_CONTROL:
				return mountItemInProgressMaintenance()
			case StepType.QUOTE:
				return mountItemOrc()
			case StepType.CLOSE_OS:
				return mountItemCloseOs()
			case StepType.CANCEL_REQUEST:
				return mountItemServiceOrderCancelRequest()
			case StepType.ORDER:
			case StepType.INVENTORY_ORDER:
				return mountItemPed()
			case StepType.INVOICE:
			case StepType.INVENTORY_INVOICE:
				if (id_protheus?.trim() !== undefined) return mountItemNF()
			// eslint-disable-next-line no-fallthrough
			case StepType.AUTH:
				return mountItemInternalAuth()
			case StepType.DELETED_OS:
				return mountItemDeletedOs()
			default:
				if (technician && appointmentDate) {
					return mountItemAppointedOsWithTechnical()
				} else if (appointmentDate) {
					return mountItemRescheduledOs()
				} else if (datePartShipping) {
					mountItemStopService()
					return mountItemSendPieceEquipmentOs()
				} else if (id_protheus?.trim() !== undefined) {
					return mountItemOpenOs()
				}
		}
	}

	const resetStatus = () => {
		setStatus(false)
	}

	const handleCancel = () => {
		setEnablePartModal(false)
	}

	const handleChanges = (worked: boolean) => {
		if (worked) {
			setWorked(true)
			setStatus(true)

			setMessage('Pedido efetivado com sucesso!')
			setEnablePartModal(false)
		} else {
			setStatus(true)
			setWorked(false)
			setEnablePartModal(false)
			setMessage('fail')
		}
	}

	const downloadBase64File = (
		contentType: string,
		base64Data: string,
		fileName: string
	) => {
		const linkSource = base64Data
		const downloadLink = document.createElement('a')
		downloadLink.href = linkSource
		downloadLink.download = fileName
		downloadLink.click()
	}

	return (
		<div className="timeline-service">
			<div className={`service-indicators ${dateCloseService ? 'closed' : ''}`}>
				<div
					className={`circle-indicator ${index === 0 ? 'first' : ''} `}
				></div>
				<div
					className={`line-indicator ${index === 0 && !isLast ? 'first' : ''} ${
						index === 0 && isLast ? 'first-last' : ''
					}
				${index !== 0 && isLast ? 'last' : ''}`}
				></div>
				<div className={`dots-indicator ${isLast ? 'last' : ''}`}></div>
			</div>
			<div className="timeline-service-template">{chooserTheTemplate()}</div>
			{tech_assistance_files ? (
				tech_assistance_files.map((file) => (
					<>
						<div
							style={{ overflow: 'auto' }}
							className={`modal ${file.id_tech_assist}${file.id}`}
						>
							{loading ? (
								<div>
									<LoadingProgress show={loading} text="Carregando..." />
								</div>
							) : (
								<>
									<FileModal
										base64={base64}
										id_img={file.id}
										id_tech_assist={file.id_tech_assist}
									/>
									<button
										style={{
											backgroundColor: 'rgba(0,0,0,0)',
											position: 'fixed',
											top: '18px',
											right: '250px'
										}}
										onClick={() => {
											$('body').removeClass('modal-open')
											setLoading(true)
											$(`.${file.id_tech_assist}${file.id}`).toggle()
										}}
									>
										<img src={closePic}></img>
									</button>
								</>
							)}
						</div>
					</>
				))
			) : (
				<div></div>
			)}

			{enablePartModal && (
				<div className="modal resumo" style={{ display: 'block' }}>
					<Budgeting
						equipment={equipment}
						client={client}
						lastTechAddress={lastTechAddress}
						lastTechID={lastTechID}
						partRequest={partRequest}
						handleCancel={handleCancel}
						handleChanges={handleChanges}
					/>
				</div>
			)}

			{status && (
				<div className="modal message" style={{ display: 'block' }}>
					<SuccessMsg result={worked} msg={message} resetStatus={resetStatus} />
				</div>
			)}
		</div>
	)
}

export default Service
