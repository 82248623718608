import back from 'assets/image/back.png'
import User from 'models/User'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { NavLink, RouteComponentProps } from 'react-router-dom'
import { toast } from 'react-toastify'
import { requestNewPass, updateUser, userInfo } from 'services'
import VersionInfo from 'shared/VersionInfo'
import { store } from 'store'
import { signOut } from 'store/modules/auth/actions'
import { updateUserInfo } from 'store/modules/user/actions'
import {
	Contact,
	ContactPanel,
	ContactTitle,
	Container,
	Description,
	DescriptionInfo,
	Detail,
	EditButton,
	FixedInfo,
	Info,
	Input,
	PanelUser,
	SaveButton,
	Title,
	TitleText
} from './styles'
import './styles.css'

type TParams = { history: string; clientCode: string }

const ConfigUser: React.FC<RouteComponentProps<TParams>> = ({
	history,
	match
}: RouteComponentProps<TParams>) => {
	const [user, setUser] = useState<User>()
	const [editedInfo, setEditedInfo] = useState<boolean>(false)
	const [disableInput, setDisableInput] = useState<boolean>(true)
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()
	const profile = store.getState().user.profile

	useEffect(() => {
		const callOpenApi = () => {
			const response = userInfo(profile)
			response
				.then((response) => setUser(response.data))
				.catch((error) => {
					if (error.message === 'Network Error') {
						history.push('/error/500') // Network Error
					} else if (error.message === '_data$ is undefined') {
						history.push('/error/404')
					} else if (error.message === 'Request failed with status code 401') {
						dispatch(signOut())
						history.push('/')
					}
				})
		}

		callOpenApi()
	}, [history, match])

	const handleEditClick = () => {
		setEditedInfo(true)
		setDisableInput(false)
	}

	const onChangePassClick = () => {
		const info = { email: store.getState().user.email }

		requestNewPass(info)
			.then(() => toast.success('E-mail de alteração de senha enviado!'))
			.catch(() => toast.error('Houve um erro ao alterar a senha de acesso!'))
	}

	const onSubmit = (userData: any) => {
		const userId = user?.id !== undefined ? user.id : 0
		userData.email = store.getState().user.email
		userData.area = store.getState().user.depCode
		const response = updateUser(userData, userId)
		response
			.then((response) => {
				const { data } = response
				if (data.name === 'ValidationError') {
					toast.error('Erro ao atualizar o usuário.')
				} else {
					setEditedInfo(false)
					setDisableInput(true)
					dispatch(
						updateUserInfo(
							data.userUpdated.name,
							data.userUpdated.surname,
							data.userUpdated.cel,
							data.userUpdated.email,
							data.userUpdated.lastVersion
						)
					)
					toast.success('Sucesso ao atualizar o usuário.')
				}
			})
			.catch((error) => {
				toast.error('Erro ao atualizar o usuário.')
				console.log(error)
			})
	}

	return (
		<>
			<Container>
				<PanelUser>
					<Title>
						<NavLink
							to="#"
							style={{ color: '#333', borderLeft: 'none' }}
							onClick={() => history.goBack()}
						>
							<img
								src={back}
								style={{
									height: '20px',
									width: '20px',
									marginBottom: '2px',
									marginRight: '2px'
								}}
							></img>
						</NavLink>
						<TitleText>Configurações</TitleText>
					</Title>
					<Info>
						<Contact>
							<ContactPanel>
								<ContactTitle>Informações:</ContactTitle>
								<EditButton enabled={!editedInfo}>
									<div onClick={handleEditClick}>Editar</div>
								</EditButton>
							</ContactPanel>
							<form onSubmit={handleSubmit(onSubmit)}>
								<Detail>
									<Description>Nome:</Description>
									<DescriptionInfo>
										<Input
											defaultValue={store.getState().user.name}
											type="text"
											edited={editedInfo}
											disabled={disableInput}
											{...register('name', { required: true })}
										/>
										<small>
											{errors.name && (
												<span style={{ color: 'red' }}>
													<br />O campo nome é obrigatório
												</span>
											)}
										</small>
									</DescriptionInfo>
								</Detail>

								<Detail>
									<Description>Sobrenome:</Description>
									<DescriptionInfo>
										<Input
											defaultValue={store.getState().user.surname}
											type="text"
											edited={editedInfo}
											disabled={disableInput}
											{...register('surname', { required: true })}
										/>
										<small>
											{errors.name && (
												<span style={{ color: 'red' }}>
													<br />O campo nome é obrigatório
												</span>
											)}
										</small>
									</DescriptionInfo>
								</Detail>

								<Detail>
									<Description>Celular:</Description>
									<DescriptionInfo>
										<Input
											defaultValue={store.getState().user.cel}
											type="text"
											edited={editedInfo}
											disabled={disableInput}
											{...register('cel', { required: true })}
										/>
										<small>
											{errors.cel && (
												<span style={{ color: 'red' }}>
													<br />O campo telefone é obrigatório
												</span>
											)}
										</small>
									</DescriptionInfo>
								</Detail>

								<Detail>
									<Description>E-mail:</Description>
									<DescriptionInfo>
										<FixedInfo>{store.getState().user.email}</FixedInfo>
									</DescriptionInfo>
								</Detail>

								<Detail>
									<Description>Matrícula:</Description>
									<DescriptionInfo>
										<FixedInfo>{store.getState().user.register}</FixedInfo>
									</DescriptionInfo>
								</Detail>

								<Detail>
									<Description>Área:</Description>
									<DescriptionInfo>
										<FixedInfo>{store.getState().user.department}</FixedInfo>
									</DescriptionInfo>
								</Detail>

								<Detail>
									<SaveButton enabled={editedInfo} type="submit">
										Salvar
									</SaveButton>
								</Detail>
							</form>
							<Detail>
								<SaveButton enabled={!editedInfo} onClick={onChangePassClick}>
									Alterar senha
								</SaveButton>
							</Detail>
						</Contact>
					</Info>
				</PanelUser>
			</Container>
			<VersionInfo />
		</>
	)
}

ConfigUser.propTypes = {
	history: PropTypes.any
}

export default ConfigUser
