import React from 'react'
import './styles.scss'

export const OrderItemsHeader = () => (
	<div className="itens-list-header">
		<u className="itens-list-order-item itens-list-order-options"></u>
		<ul className="itens-list-order-item itens-list-order-product-code">
			<span>Código</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-description">
			<span>Descrição</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-accessories">
			<span>Acessórios</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-amount">
			<span>Quant.</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-table-price">
			<span>Valor tabela (R$)</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-unit-price">
			<span>Valor unitário (R$)</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-total-net-price">
			<span>Total merc. (R$)</span>
		</ul>
		<ul className="itens-list-order-item itens-list-order-more-options" />
	</div>
)
