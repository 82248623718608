import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { registerFollowUp, uploadFile } from 'services'
import UploadComponent from 'shared/UploadComponent'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import {
	Characters,
	DtFollowUp,
	Footer,
	Form,
	Header,
	InputArea,
	LabelName,
	Message,
	RequestInfo
} from './styles'
import './styles.scss'

interface Props {
	show: boolean
	equipment?: Equipment
	client?: Client
	serviceOrderId: number
	count: number
	handleChanges: (type: any, changes: any, worked: boolean) => void
	handleCancel: () => void
}

const InternalObs: React.FC<Props> = ({
	show,
	client,
	equipment,
	serviceOrderId,
	handleCancel,
	count
}) => {
	const [files, setFiles] = useState<any[]>([])
	const [text, setText] = useState<string>('')
	const [msg, setMsg] = useState<number>(500)
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()
	const user = store.getState().user
	const url_so = window.location.href
	const [isLoading, setIsLoading] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState<any[]>([])

	useEffect(() => {
		return () => {
			uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview))
		}
	}, [uploadedFiles])

	const uploaded = (files: any[]) => {
		setUploadedFiles(files.filter((file) => file.error !== true))
	}

	const onSubmit = (info: FieldValues) => {
		setIsLoading(true)

		const technicalAssistanceFile = {
			message: text,
			url: 'Visualize o atendimento na timeline: ' + url_so,
			files: files
		}

		const uploadFiles = uploadedFiles.filter((file) => file.error !== true)

		const dtFollowUP = info.followUp
		const techAssist = {
			serviceOrderId,
			technicalAssistanceFile,
			user,
			dtFollowUP,
			uploadFiles
		}
		registerFollowUp(techAssist)
			.then(() => {
				setIsLoading(false)
				onClose()
				toast.success('Observação interna adicionada com sucesso.')
				setTimeout(() => {
					window.location.reload()
				}, 600)
			})
			.catch(() => {
				setIsLoading(false)
				toast.error('Erro ao adicionar observação interna.')
			})
	}

	const countMsg = (e: any) => {
		if (text?.length < 500) {
			setText(e.target.value.substring(0, 500))
			if (
				e?.nativeEvent?.inputType == 'insertText' ||
				e?.nativeEvent?.inputType == 'insertFromPaste'
			) {
				setMsg(500 - (text?.length + 1))
			} else {
				setMsg(500 - (text?.length - 1))
			}
		} else {
			if (
				e?.nativeEvent?.inputType == 'insertText' ||
				e?.nativeEvent?.inputType == 'insertFromPaste'
			) {
				setMsg(0)
			} else {
				setText(e.target.value?.substring(0, 500))
				setMsg(500 - (text?.length + 1))
			}
		}
	}

	const handleCancelUpload = () => {
		uploadedFiles.map((file, index) => {
			if (index >= 0) {
				uploadFile.delete(`upload/${file.id}`)
			}
		})
	}

	const onClose = () => {
		$(`.internalObs`).toggle()
		$('.actionOpt').css('display', 'none')
		$('input:text, textarea').val('')
		$(`#fileList`).html('')
		handleCancel()
	}

	return (
		<Modal
			show={show}
			onClose={() => {
				handleCancelUpload()
				onClose()
			}}
			title="Observação Interna"
			showClose={true}
		>
			<div className="service-order-internal-obs">
				<div className="service-order-internal-obs-main-contain">
					<Header>
						<Equipments client={client} equipment={equipment} />
					</Header>

					<form>
						<RequestInfo>
							<Form>
								<div style={{ width: '35%' }}>
									<LabelName>Dt. Follow-up</LabelName>
									<DtFollowUp
										type="date"
										{...register('followUp', { required: false })}
									/>
								</div>
								<Message>
									<LabelName>Mensagem</LabelName>
									<InputArea
										{...register('message', { required: true })}
										onChange={(e) => {
											countMsg(e)
										}}
										value={text}
									/>
									<small>
										{errors.message && (
											<span style={{ color: 'red', display: 'flex' }}>
												O campo mensagem é obrigatório
											</span>
										)}
									</small>
								</Message>
								<Characters>{msg} caracteres restantes</Characters>
								<UploadComponent
									onUploaded={uploaded}
									keyUpload={`${serviceOrderId}-${count + 1}`}
								/>
							</Form>
						</RequestInfo>
					</form>
				</div>
				<Footer>
					<Button
						isLoading={isLoading}
						style={{ width: '120px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Salvar"
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						isLoading={isLoading}
						style={{ width: '120px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={() => {
							handleCancelUpload()
							onClose()
						}}
					/>
				</Footer>
			</div>
			<LoadingProgress show={isLoading} text="Carregando..." />
		</Modal>
	)
}

export default InternalObs
