import React from 'react'
import './styles.scss'

interface SvgProps {
	width: string
	height: string
	fill?: string
}

const CheckIcon: React.FC<SvgProps> = ({ fill, width, height }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 50 40"
		>
			<g transform="translate(-352 -8)" fill={fill}>
				<rect
					className="a"
					width="50"
					height="40"
					transform="translate(352 8)"
				/>
				<path
					d="M10.189,26.509l-9.75-9.75a1.5,1.5,0,0,1,0-2.121l2.121-2.121a1.5,1.5,0,0,1,2.121,0l6.568,6.568L25.318,5.017a1.5,1.5,0,0,1,2.121,0l2.121,2.121a1.5,1.5,0,0,1,0,2.121l-17.25,17.25A1.5,1.5,0,0,1,10.189,26.509Z"
					transform="translate(362 12.237)"
				/>
			</g>
		</svg>
	)
}

export default CheckIcon
