import { IAccessories } from 'module/orderManagement/models/IAccessories'
import { handleAccessoriesType } from 'module/orderManagement/utils/constants'
import React from 'react'
import './styles.scss'

interface Props {
	accessoriesList?: any
}

export const ItensDetailsListAccessoriesBodyMobileViewingMode = ({
	accessoriesList
}: Props) => {
	return (
		<div className="new-accessories-body viewing">
			<div className="new-accessories-body-list">
				{accessoriesList.map((item: IAccessories, index: number) => {
					return (
						<div key={index} className="new-accessories-body-item">
							<div className="accessories-details-list">
								<ul className="accessories-details-list-item accessories-details-list-description">
									<label htmlFor={`${index}`}>
										<span className="accessories-details-list-title">
											Produto:
										</span>
										<span>{`${item?.code} - ${item.description}`}</span>
									</label>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-table-price">
									<span className="accessories-details-list-title">
										Valor tabela:
										<span>
											{item?.table_price.toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
												minimumFractionDigits: 2
											}) || '-'}
										</span>
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-amount">
									<span className="accessories-details-list-title">
										Quantidade:
										<span>{item?.amount}</span>
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-commission">
									<span className="accessories-details-list-title">
										Base comissa?:
										<span>{handleAccessoriesType(item?.commission)}</span>
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-unit-price">
									<span className="accessories-details-list-title">
										Valor un.:
										<span>
											{item?.unit_price.toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
												minimumFractionDigits: 2
											})}
										</span>
									</span>
								</ul>

								<ul className="accessories-details-list-item accessories-details-list-total-price">
									<span className="accessories-details-list-title">
										Valor total:
										<span>
											{item?.total_price.toLocaleString('pt-br', {
												style: 'currency',
												currency: 'BRL',
												minimumFractionDigits: 2
											})}
										</span>{' '}
									</span>
								</ul>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}
