import LoginInput from 'models/LoginInput'
import LoadingProgress from 'module/technicalServices/components/Shared/Loadings/LoadingProgress'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { RecoveryPass } from 'services'
import { signInRequest } from 'store/modules/auth/actions'
import FormValidator from '../../FormFields/FormValidator'
import PasswordField from '../../PasswordField'
import './styles.scss'

const NewPassForm: React.FC = () => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const dispatch = useDispatch()
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<LoginInput>()

	function getQueryVariable(data: string) {
		const query = window.location.search.substring(1)
		const vars = query?.split('&')

		for (let i = 0; i < vars?.length; i++) {
			const pair = vars[i]?.split('=')

			if (pair[0] == data) {
				return decodeURI(pair[1])
			}
		}
		return false
	}

	const onSubmit = (data: LoginInput) => {
		const auth = getQueryVariable('code_auth')
		const email = getQueryVariable('email')
		setIsLoading(true)
		if (data.newPassword === data.confirmPassword) {
			const newData = {
				email: email,
				password: data.newPassword,
				code_auth: auth
			}
			console.log(data)

			RecoveryPass(newData)
				.then((response) => {
					setIsLoading(false)
					if (response.status === 200) {
						toast.success('Senha alterada com sucesso!')
						dispatch(signInRequest(email, data.newPassword))
					}
				})
				.catch((error) => {
					setIsLoading(false)
					toast.error('Erro ao atualizar a nova senha.')
				})
		} else {
			toast.error('As senhas não conferem!')
		}
	}

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div>
					<div className="form-item-content">
						<label>Digite uma nova senha</label>
						<PasswordField
							register={register('newPassword', {
								required: true,
								minLength: 8,
								maxLength: 20
							})}
							placeholder="Crie uma senha"
						/>
						<FormValidator
							{...{
								formName: 'newPassword',
								validatorName: 'Senha',
								errors,
								isDirty
							}}
						/>
					</div>
					<div className="form-item-content">
						<label>Confirme a nova senha</label>
						<PasswordField
							register={register('confirmPassword', {
								required: true,
								minLength: 8,
								maxLength: 20
							})}
							placeholder="Confirme sua senha"
						/>
						<FormValidator
							{...{
								formName: 'confirmPassword',
								validatorName: 'Senha',
								errors,
								isDirty
							}}
						/>
					</div>
				</div>
				<div className="new-pass-options">
					<span>
						Sua senha deve ter entre 8 e 20 caracteres, ter ao menos uma letra
						maiúscula, uma minúscula, um número e um caractere especial.
					</span>
				</div>
				<div className="new-pass-form-actions">
					<button className="new-pass-submit" type="submit">
						ENTRAR
					</button>
				</div>
			</form>
		)
	}

	return (
		<div className="new-pass-form">
			{formContent()}
			<LoadingProgress show={isLoading} text="Salvando nova senha..." />
		</div>
	)
}

export default NewPassForm
