import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import React, { useContext } from 'react'
import Paginator from 'shared/Paginator'
import EmptyList from 'shared/components/EmptyList'
import ClientsActions from './ClientsActions'
import ClientsListBody from './ClientsListBody'
import ClientsListHeader from './ClientsListHeader'
import './styles.scss'

const ClientListWeb = () => {
	const { isLoading, totalPages, currentPage, onPageChange } =
		useContext(ClientContextElement)

	return (
		<div className="clients">
			<ClientsActions />
			<div className="clients-table">
				{totalPages ? (
					<>
						<ClientsListHeader />
						<ClientsListBody />
					</>
				) : (
					<EmptyList show text="Cliente não encontrado" />
				)}
			</div>
			<Paginator
				total={totalPages}
				selected={currentPage}
				onPageChange={onPageChange}
				isLoading={isLoading}
			/>
		</div>
	)
}
export default ClientListWeb
