import ListParams from 'models/ListParams'
import React, { createContext, ReactNode, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { getListFamilies } from 'services'
import {
	defaultListParams,
	FilterField,
	FilterValue,
	updateListParams
} from 'utils/ContextUtils'

export const ProductFamilyContext = createContext({
	isLoading: false,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	familyList: [] as any[],
	handleGetFamilyList: () => {},
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {}
})

const defaultParams = defaultListParams()

export const ProductFamilyProvider = ({
	children
}: {
	children: ReactNode
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [familyList, setFamilyList] = useState<any[]>([] as any[])
	const listParams = useRef<ListParams>(defaultParams)

	const handleGetFamilyList = () => {
		const { currentPage, searchValue } = { ...listParams.current }
		setIsLoading(true)
		getListFamilies({
			term: searchValue || '',
			limit: 20,
			offset: currentPage - 1
		})
			.then((response) => {
				updateParams(Math.ceil(response?.data?.total / 20), 'count')
				setFamilyList(response?.data?.families)
			})
			.catch(() => toast.error('Erro ao buscar lista de produtos'))
			.finally(() => setIsLoading(false))
	}

	const onSearchParams = (term: string) => {
		updateParams(1, 'page')
		updateParams(term, 'search')
		handleGetFamilyList()
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		handleGetFamilyList()
	}

	const value = {
		isLoading,
		familyList,
		handleGetFamilyList,
		onSearchParams,
		onPageChange,
		...listParams.current
	}

	return (
		<ProductFamilyContext.Provider value={value}>
			{children}
		</ProductFamilyContext.Provider>
	)
}
