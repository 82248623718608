import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { history } from 'routes/history'
import MobileNavigator from 'shared/components/MobileNavigator'
import { store } from 'store'
import closeIcon from '../../../../../assets/image/close-icon.svg'
import menuIcon from '../../../../../assets/image/menu-icon.svg'
import './styles.scss'

type TParams = {
	path: string
}

const OrderMenuTabsMobile = ({ path }: TParams) => {
	const { sc5010 } = useContext(OrderDetailsContextElement)
	const [partOrderFollowUpTab, setPartOrderFollowUpTab] =
		useState<boolean>(false)
	const [partOrderPendencyTab, setPartOrderPendencyTab] =
		useState<boolean>(false)
	const [partOrderDetailsTab, setPartOrderDetailsTab] = useState<boolean>(false)
	const [partOrderHistoryTab, setPartOrderHistoryTab] = useState<boolean>(false)
	const [partOrderStorageTab, setPartOrderStorageTab] = useState<boolean>(false)
	const [partOrderChecklistTab, setPartOrderChecklistTab] =
		useState<boolean>(false)
	const [partOrderClientTab, setPartOrderClientTab] = useState<boolean>(false)
	const [partOrderUser, setPartOrderUser] = useState<boolean>(false)
	const [showSidebar, setShowSidebar] = useState<boolean>(false)

	const { roles } = store.getState().user

	useEffect(() => {
		roles.includes('partsOrderUser') && setPartOrderUser(true)
	}, [roles])

	useEffect(() => {
		switch (path) {
			case `/order/:branch/:orderCode/client/:clientCode/:clientStoreCode`:
				setPartOrderClientTab(true)
				break
			case '/order/:branch/:orderCode/details':
				setPartOrderDetailsTab(true)
				break
			case `/order/:branch/:orderCode/client/:clientCode/:clientStoreCode/history`:
				setPartOrderHistoryTab(true)
				break
			case '/order/:branch/:orderCode/followUp':
				setPartOrderFollowUpTab(true)
				break
			case '/order/:branch/:orderCode/pendency':
				setPartOrderPendencyTab(true)
				break
			case '/order/:branch/:orderCode/storage':
				setPartOrderStorageTab(true)
				break
			case '/order/:branch/:orderCode/checklist':
				setPartOrderChecklistTab(true)
				break
			default:
				setPartOrderDetailsTab(true)
				break
		}
	}, [path])

	return sc5010?.c5Num && sc5010?.sa1010?.a1Cod && sc5010?.sa1010?.a1Loja ? (
		<div className="order-menu-tabs">
			<div className="order-menu-button">
				<MobileNavigator action={() => history.push('/orders')} />
				<img
					src={showSidebar ? closeIcon : menuIcon}
					alt="menu"
					onClick={() => setShowSidebar(!showSidebar)}
				/>
			</div>

			<div
				className={`order-menu-container ${showSidebar ? 'open' : ''}`}
				onClick={() => setShowSidebar(!showSidebar)}
			>
				<div className="order-menu-sidebar">
					<div
						className={`order-menu-options ${
							partOrderClientTab && 'order-menu-option-selected'
						}`}
						onClick={() =>
							history.push(
								`/order/${sc5010.c5Filial}/${sc5010.c5Num}/client/${sc5010.sa1010.a1Cod}/${sc5010.sa1010.a1Loja}`
							)
						}
					>
						<span>CLIENTE</span>
					</div>
					<div
						className={`order-menu-options ${
							partOrderDetailsTab && 'order-menu-option-selected'
						}`}
						onClick={() =>
							history.push(`/order/${sc5010.c5Filial}/${sc5010.c5Num}/details`)
						}
					>
						<span>DETALHES</span>
					</div>
					<div
						className={`order-menu-options ${
							partOrderHistoryTab && 'order-menu-option-selected'
						}`}
						onClick={() =>
							history.push(
								`/order/${sc5010.c5Filial}/${sc5010.c5Num}/client/${sc5010.sa1010.a1Cod}/${sc5010.sa1010.a1Loja}/history`
							)
						}
					>
						<span>HISTÓRICO</span>
					</div>
					<div
						className={`order-menu-options ${
							partOrderFollowUpTab && 'order-menu-option-selected'
						}`}
						onClick={() =>
							history.push(`/order/${sc5010.c5Filial}/${sc5010.c5Num}/followUp`)
						}
					>
						<span>FOLLOW UP</span>
					</div>
					<div
						className={`order-menu-options ${
							partOrderPendencyTab && 'order-menu-option-selected'
						}`}
						onClick={() =>
							history.push(`/order/${sc5010.c5Filial}/${sc5010.c5Num}/pendency`)
						}
					>
						<span>PENDÊNCIAS</span>
					</div>
					<div
						className={`order-menu-options ${
							partOrderChecklistTab && 'order-menu-option-selected'
						}`}
						onClick={() =>
							history.push(
								`/order/${sc5010.c5Filial}/${sc5010.c5Num}/checklist`
							)
						}
					>
						<span>CHECKLIST</span>
					</div>
					{partOrderUser && (
						<div
							className={`order-menu-options ${
								partOrderStorageTab && 'order-menu-option-selected'
							}`}
							onClick={() =>
								history.push(
									`/order/${sc5010.c5Filial}/${sc5010.c5Num}/storage`
								)
							}
						>
							<span>ARMAZÉNS</span>
						</div>
					)}
				</div>
			</div>
		</div>
	) : (
		<></>
	)
}
export default OrderMenuTabsMobile
