import Equipment from 'models/Equipment'
import { RequisitionError } from 'models/RequisitionError'
import React, { createContext } from 'react'
import {
	EquipmentContextType,
	EquipmentContextValue
} from './EquipmentContextType'

export const EquipmentContextElement = createContext<EquipmentContextType>(
	EquipmentContextValue
)

const EquipmentProvider = (props: any) => {
	const onRequestEquipment = (
		serialNumber: string,
		callback: (equipment?: Equipment, hasError?: RequisitionError) => void
	) => {}

	const providerValues = () => {
		return { onRequestEquipment }
	}

	return (
		<EquipmentContextElement.Provider value={providerValues()}>
			{props.children}
		</EquipmentContextElement.Provider>
	)
}

export default EquipmentProvider
