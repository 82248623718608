import arrow from 'assets/image/Caminho 401.svg'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import Menu from 'module/technicalServices/components/Menu'
import SocketContext from 'module/technicalServices/context/SocketContext/SocketContext'
import React, { useContext, useState } from 'react'
import { useDispatch } from 'react-redux'
import CompNotification from 'shared/Notification'
import UserDropdown from 'shared/UserDropdown'
import { store } from 'store'
import { updateMenuShowStatus } from 'store/modules/user/actions'
import { DeviceType } from 'utils/Constants'
import MobileMenu from './Mobile'
import './styles.scss'

type TParams = {
	module:
		| 'order_management'
		| 'technical_services'
		| 'human_resources'
		| 'home'
		| 'config_user'
		| 'register'
		| 'cs'
		| 'install-and-search'
		| 'products_management'
	children?: React.ReactNode
}

const DefaultLayout: React.FC<TParams> = ({ module, children }) => {
	const [menuShowing, setMenuShowing] = useState<boolean>(
		!store.getState().user.menuShow
	)
	const { deviceType } = useContext(DeviceContextElement)

	const dispatch = useDispatch()

	const changeShowMenu = () => {
		setMenuShowing(!menuShowing)
		dispatch(updateMenuShowStatus(menuShowing))
	}

	const compNotificationShow = () => {
		switch (module) {
			case 'human_resources':
				return <></>
			case 'technical_services':
				return <CompNotification />
			case 'order_management':
				return <CompNotification />
			default:
				return <></>
		}
	}

	return (
		<SocketContext>
			{deviceType !== DeviceType.MOBILE ? (
				<div className="main-content-module">
					<nav id="nav">
						<div className="sideMenu" id="side_menu">
							<Menu {...{ menuShowing, module }} />
							<div className="btnShowMenu" onClick={changeShowMenu}>
								<button className="arrowBtn">
									{menuShowing ? (
										<>
											<img
												className="arrow"
												width="8px"
												height="15px"
												src={arrow}
												alt=""
											/>
										</>
									) : (
										<>
											<img
												className="arrow"
												width="8px"
												height="15px"
												style={{ transform: 'rotate(180deg)' }}
												src={arrow}
												alt=""
											/>
										</>
									)}
								</button>
							</div>
						</div>
					</nav>
					<header className="navHorizontal" />
					{compNotificationShow()}
					<UserDropdown />
					<div className={`section ${menuShowing ? '' : 'section-menu-hide'}`}>
						{children}
					</div>
				</div>
			) : (
				<>
					<div className={`main-page-component`}>{children}</div>
					<MobileMenu {...{ menuShowing, module }} />
				</>
			)}
		</SocketContext>
	)
}

export default DefaultLayout
