import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import React, { useContext } from 'react'
import Paginator from 'shared/Paginator'
import EmptyList from 'shared/components/EmptyList'
import OrdersActions from './OrdersActions'
import OrdersListBody from './OrdersListBody'
import OrdersListHeader from './OrdersListHeader'
import './styles.scss'

const OrderListWeb = () => {
	const { isLoading, totalPages, currentPage, onPageChange } =
		useContext(OrderContextElement)

	return (
		<div className="orders">
			<OrdersActions />
			<div className="orders-table">
				{totalPages ? (
					<>
						<OrdersListHeader />
						<OrdersListBody />
					</>
				) : (
					<EmptyList show text="Pedido não encontrado" />
				)}
			</div>
			<Paginator
				total={totalPages}
				selected={currentPage}
				onPageChange={onPageChange}
				isLoading={isLoading}
			/>
		</div>
	)
}
export default OrderListWeb
