import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { resendEmailTech } from 'services/index'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { Footer, Header } from './styles'
import './styles.scss'

interface Props {
	show: boolean
	onClose: (value: boolean) => void
	equipment?: Equipment
	client?: Client
	techAssistId?: number
}

const ResendEmail: React.FC<Props> = ({
	show,
	onClose,
	client,
	equipment,
	techAssistId
}) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onResendEmail = () => {
		if (techAssistId) {
			setIsLoading(true)
			resendEmailTech(techAssistId, { email_tech: null })
				.then(() => {
					setIsLoading(true)
					handleOnClose()
					toast.success('Email enviado com sucesso.')
				})
				.catch(() => {
					setIsLoading(true)
					toast.error('Erro ao encerrar ordem de serviço.')
				})
		}
	}

	const handleOnClose = () => {
		onClose(false)
	}

	return (
		<Modal
			show={show}
			onClose={handleOnClose}
			title="Reenviar e-mail"
			showClose={true}
		>
			<div className="service-order-re-send-email">
				<Header>
					<Equipments client={client} equipment={equipment} />
				</Header>

				<div style={{ margin: '20px 0px', fontSize: '18px' }}>
					Deseja reenviar o e-mail do atendimento para o técnico?
				</div>

				<Footer>
					<Button
						isLoading={isLoading}
						style={{ width: '120px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Enviar"
						onClick={onResendEmail}
					/>
					<Button
						isLoading={isLoading}
						style={{ width: '120px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={handleOnClose}
					/>
				</Footer>
			</div>
			<LoadingProgress show={isLoading} text="Carregando..." />
		</Modal>
	)
}

export default ResendEmail
