import PrevSchedules from 'models/PrevSchedules'
import { StepType } from 'models/StepTypeEnum'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdCheck } from 'react-icons/md'
import { toast } from 'react-toastify'
import {
	createNewServiceOrder,
	techAssist,
	updateEquipPrev
} from 'services/index'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import { clearNumericField, normalizePhoneNumber } from 'utils/Pipe'
import ProductFamily from '../ProductFamily'
import SearchTech from './SearchTech'
import './styles.css'

type TechInfo = {
	name: string
	code_prp: string
}

interface Props {
	schedules: PrevSchedules[]
	handleChanges: (worked: boolean) => void
	handleCancel: () => void
}

type Info = { contact: string; occupation: string; message: string }

type ProdFamily = {
	id: number
	family_prod?: string
	serial_number: string
	index: number
}

const ScheduleOs: React.FC<Props> = ({
	schedules,
	handleChanges,
	handleCancel
}) => {
	const [technicianInfo, setTechnicianInfo] = useState<TechInfo>({
		name: '',
		code_prp: ''
	})
	const [isLoading, setIsLoading] = useState(false)
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		watch
	} = useForm()
	const [productFamily, setProductFamily] = useState<ProdFamily>({
		id: -1,
		family_prod: '',
		serial_number: '',
		index: -1
	})
	const [listProductFamilyPrev, setListProductFamilyPrev] = useState<
		PrevSchedules[]
	>([])
	const user = store.getState().user
	const inputValue = watch('phone')

	useEffect(() => {
		setValue('phone', normalizePhoneNumber(inputValue))
	}, [inputValue])

	async function onSubmit(data: any) {
		if (technicianInfo?.code_prp) {
			setIsLoading(true)
			Promise.all(
				schedules.map(async (schedule, index) => {
					const os = {
						equipment: {
							serial_number: schedule.equip_serial
						},
						service: {
							created_by: 'ADMIN_APP',
							origin: 'ADMIN_APP',
							originOS: 'ADMIN_APP',
							contato: data.contact,
							phone: clearNumericField(data.phone),
							type: 'PREVENTIVA',
							address: schedule.loc_street,
							neighborhood: schedule.loc_neighborhood,
							city: schedule.loc_city,
							state_sigla: schedule.loc_state_sigla,
							zip_code: schedule.loc_zip_code,
							client_code: schedule.client_code,
							email: data.email,
							defect: '000056'
						},
						client: {
							cod_protheus: schedule.cod_protheus
						},
						user_id: user.profile,
						step_type: StepType.CREATE_OS
					}

					const response = await createNewServiceOrder(os)
					if (response.status !== 200) {
						handleChanges(false)
						setIsLoading(false)
						toast.error('Houve um erro ao cadastrar preventiva.')
					}

					const technical = {
						service_order: response.data.serviceOrderCode,
						technician: {
							name: technicianInfo.name,
							code_prp: technicianInfo?.code_prp
						},
						appointment_date: data.date,
						part_shipping_date: '',
						closing_date: '',
						user_id: user.profile,
						step_type: StepType.NEW_VISIT
					}
					const resp_ta = await techAssist(technical)
					if (resp_ta.status !== 200) {
						handleChanges(false)
						setIsLoading(false)
						toast.error('Houve um erro ao agendar preventiva.')
					}

					const prevDate = new Date(data.date)
					prevDate.setDate(prevDate.getDate() + 90)
					const update = {
						next_preventive: prevDate
					}

					const resp_update = await updateEquipPrev(
						update,
						os.equipment.serial_number
					)
					if (resp_update.status !== 200) {
						handleChanges(false)
						setIsLoading(false)
						toast.error('Houve um erro ao agendar preventiva.')
					}
				})
			).then(() => {
				handleChanges(true)
				setIsLoading(false)
				$('.scheduleOs').toggle()
			})
		}
	}

	useEffect(() => {
		setListProductFamilyPrev(schedules)
	}, [schedules])

	const equipList = () => {
		return schedules.map((item: PrevSchedules, index: number) => (
			<div className="list-item-content" key={index}>
				<div className="list-item client">{item.nome_fantasia}</div>
				<div className="list-item equip-name">{item.equip_name}</div>
				<div className="list-item serial">{item.equip_serial}</div>
				<div className="list-item location">{`${item.loc_street}, ${item.loc_neighborhood}, ${item.loc_city}`}</div>
				<div
					id="test"
					style={{
						width: '15%',
						display: 'flex',
						flexDirection: 'row-reverse'
					}}
				>
					<div>
						{item.family_prod?.trim() == '' ? (
							<div onClick={() => updateFamilyProd(item)}>
								{prevFamilyProd()}
							</div>
						) : (
							<MdCheck />
						)}
					</div>
				</div>
			</div>
		))
	}

	const onChangeFamilyProd = (familyProd: any, equipment: any) => {
		listProductFamilyPrev[equipment.index].family_prod = familyProd
	}

	const prevFamilyProd = () => {
		const equipment = productFamily
		return (
			<ProductFamily
				show={true}
				equipment={equipment}
				onSelectProductFamily={(e) => onChangeFamilyProd(e, equipment)}
			/>
		)
	}

	const updateFamilyProd = (item?: any) => {
		const indexSchedules = schedules.findIndex(
			(index) => index.equip_serial === item.equip_serial
		)
		const payload = {
			id: item.equip_id,
			family_prod: item.family_prod,
			serial_number: item.equip_serial,
			index: indexSchedules
		}
		setProductFamily(payload)
	}

	const buttonSchedule = () => {
		return !listProductFamilyPrev.filter((e) => !e.family_prod?.trim())
			?.length ? (
			<button
				disabled={isLoading || !technicianInfo?.code_prp}
				onClick={handleSubmit(onSubmit)}
				value=""
			>
				Agendar
			</button>
		) : (
			<button disabled={true} onClick={handleSubmit(onSubmit)} value="">
				Agendar
			</button>
		)
	}

	return (
		<Modal
			title="Agendar Visita"
			onClose={() => handleCancel()}
			show={true}
			showClose={true}
		>
			<div className="schedule-os-contain" style={{ position: 'relative' }}>
				<div className="schedule-os-preventive-content">
					<div className="schedule-os-preventive-form">
						<div className="form-item-inline">
							<div>
								<SearchTech
									onTechSelected={(tech: TechInfo) => {
										setTechnicianInfo(tech)
									}}
									register={register}
								/>
								<small>
									{errors.tech && (
										<span
											style={{ color: 'red', display: 'flex', margin: 'auto' }}
										>
											O campo técnico é obrigatório
										</span>
									)}
								</small>
							</div>
						</div>
						<div className="form-item-inline">
							<div>
								<Input
									name="email"
									variant="light"
									type="email"
									innerRef={{ ...register('email', { required: true }) }}
									placeholder="E-mail"
								/>
								<small>
									{errors.email?.type === 'required' && (
										<span style={{ color: 'red', display: 'flex' }}>
											O campo e-mail é obrigatório
										</span>
									)}
								</small>
							</div>
						</div>
						<div className="form-item-columns">
							<div>
								<Input
									name="contact"
									variant="light"
									type="text"
									innerRef={{ ...register('contact', { required: true }) }}
									placeholder="Contato"
								/>
								<small>
									{errors.contact && (
										<span style={{ color: 'red', display: 'flex' }}>
											O campo contato é obrigatório
										</span>
									)}
								</small>
							</div>
							<div>
								<Input
									name="phone"
									variant="light"
									type="text"
									autoComplete="off"
									placeholder="(00) 00000-0000"
									innerRef={{
										...register('phone', { required: true, minLength: 15 })
									}}
								/>
								<small>
									{errors.phone && errors.phone.type === 'required' && (
										<span style={{ color: 'red', display: 'flex' }}>
											O campo telefone é obrigatório
										</span>
									)}
									{errors.phone && errors.phone.type === 'minLength' && (
										<span style={{ color: 'red', display: 'flex' }}>
											O tamanho do campo telefone está incorreto
										</span>
									)}
								</small>
							</div>
							<div>
								<input type="date" {...register('date', { required: true })} />
								<small>
									{errors.date && (
										<span style={{ color: 'red', display: 'flex' }}>
											O campo data é obrigatório
										</span>
									)}
								</small>
							</div>
						</div>
					</div>
					<div className="schedule-os-preventive-list">
						<div className="schedule-os-preventive-list-header">
							<div className="list-header client">Cliente</div>
							<div className="list-header equip-name">Equipamento</div>
							<div className="list-header serial">N/S</div>
							<div className="list-header location">Endereço</div>
							<div style={{ width: '15%' }}></div>
						</div>
						<div className="schedule-os-preventive-list-items">
							{equipList()}
						</div>
					</div>
				</div>
				<div className="schedule-os-preventive-footer">{buttonSchedule()}</div>
			</div>
			<LoadingProgress show={isLoading} text="Carregando..." />
		</Modal>
	)
}

export default ScheduleOs
