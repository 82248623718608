import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'shared/components/Button'
import Divider from 'shared/components/Divider'
import Modal from 'shared/components/Modal'
import AddressForm from '../NewOrder/AddressForm'
import './styles.scss'

interface Props {
	show: boolean
	client?: Client
	onClose: (state: boolean) => void
	equipment?: Equipment
	onClick: (data: IChangeAddressForm, addressDefault: boolean) => void
}

export interface IChangeAddressForm {
	zip_code: string
	city: string
	state_sigla: string
	street: string
	neighborhood: string
	number: string
	complement: string
	name?: string
}

const ChangeAddress = ({
	show,
	onClose,
	client,
	equipment,
	onClick
}: Props) => {
	const [addressDefault, setAddressDefault] = useState<boolean>(false)

	const handleAddressDefault = (value: boolean) => {
		setAddressDefault(value)
	}

	const form = useForm<IChangeAddressForm>()
	const { handleSubmit } = form

	function onSubmit(data: IChangeAddressForm) {
		onClick(data, addressDefault)
		if (data) {
			onClose(!show)
		}
	}

	const handleOnClose = () => {
		onClose(!show)
	}

	return (
		<Modal
			show={show}
			onClose={handleOnClose}
			title="Alterar endereço"
			showClose={true}
		>
			<div className="service-order-change-address">
				<div className="service-order-change-address-main-contain">
					<div className="address-form-header">
						<Equipments client={client} equipment={equipment} />
					</div>
					<Divider title="Novo endereço" />

					<div className="address-form-body">
						<AddressForm {...{ handleAddressDefault }} form={form} />
					</div>
				</div>
				<div className="service-order-change-address-action">
					<Button
						style={{ width: '108px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Salvar"
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						style={{ width: '108px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={handleOnClose}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ChangeAddress
