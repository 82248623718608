import { Order } from 'module/orderManagement/domain/models/order'
import React from 'react'
import Modal from 'shared/components/Modal'
import { OrderClientInstallmentViewingMode } from './NewOrderClientInstallment/OrderClientInstallmentViewingMode'
import './styles.scss'

interface OrderClientInstallmentsProps {
	show: boolean
	onClose: () => void
	order?: Order
}

export const OrderClientInstallmentsViewingMode = ({
	show,
	onClose,
	order
}: OrderClientInstallmentsProps) => {
	return (
		<>
			<Modal
				title="PARCELAS"
				showClose={true}
				enableAwayClose={true}
				show={show}
				onClose={onClose}
			>
				<div className="installments-container viewing">
					<div className="installments-details-body">
						{order?.installments?.map((item: any, index: number) => {
							return (
								<OrderClientInstallmentViewingMode
									parcel={item}
									key={index}
									index={index}
								/>
							)
						})}
					</div>
				</div>
			</Modal>
		</>
	)
}
