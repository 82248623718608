import React from 'react'
import './styles.scss'

const ClientsListHeader = () => {
	return (
		<div className="clients-list-header">
			<ul className="clients-list-item clients-list-legend" />
			<ul className="clients-list-item clients-list-client-code">
				<span>Código</span>
			</ul>
			<ul className="clients-list-item clients-list-company-name">
				<span>Razão Social</span>
			</ul>
			<ul className="clients-list-item clients-list-company-register">
				<span>CNPJ / CPF</span>
			</ul>
			<ul className="clients-list-item clients-list-last-order">
				<span>Último pedido</span>
			</ul>
			<ul className="clients-list-item clients-list-issue-date">
				<span>Emissão</span>
			</ul>
			<ul className="clients-list-item clients-list-salesperson">
				<span>Representante</span>
			</ul>
			<ul className="clients-list-item clients-list-more-options" />
		</div>
	)
}
export default ClientsListHeader
