import React, { ReactNode } from 'react'
import './styles.scss'

interface ISearchRootProps extends React.ComponentPropsWithoutRef<'input'> {
	children: ReactNode
}

export const SearchRoot = ({ children }: ISearchRootProps) => (
	<div className="search-component">{children}</div>
)
