import { OrderContextElement } from 'module/orderManagement/context/OrderContext/OrderContext'
import React, { useContext } from 'react'
import Paginator from 'shared/Paginator'
import EmptyList from 'shared/components/EmptyList'
import OrdersActions from './OrdersActions'
import { default as OrdersListBody } from './OrdersListBody'
import './styles.scss'

const OrderListMobile = () => {
	const { isLoading, totalPages, currentPage, onPageChange } =
		useContext(OrderContextElement)

	return (
		<>
			<div className="orders">
				<OrdersActions />
				<div className="orders-table">
					{totalPages ? (
						<OrdersListBody />
					) : (
						<EmptyList show text="Pedido não encontrado" />
					)}
				</div>
			</div>
			<Paginator
				total={totalPages}
				selected={currentPage}
				onPageChange={onPageChange}
				isLoading={isLoading}
			/>
		</>
	)
}
export default OrderListMobile
