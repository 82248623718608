import styled from 'styled-components'

export const Wrapper = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const Content = styled.div`
	img {
		max-width: 100%;
		vertical-align: middle;
		display: inline-block;
	}

	label {
		display: block;
		font-weight: bold;
	}

	input {
		display: block;
		width: 100%;
		padding: 8px 12px;
		margin-bottom: 10px;
		margin-top: 5px;
		font-size: 14px;
		line-height: 1.428571429;
		color: #333333;
		vertical-align: middle;
		background-color: #ffffff;
		border: 1px solid #cccccc;
	}

	button {
		margin-top: 20px;
		display: inline-block;
		padding: 9px 15px;
		background-color: #3898ec;
		color: white;
		border: 0;
		line-height: inherit;
		text-decoration: none;
		cursor: pointer;
		border-radius: 0;
		-webkit-appearance: button;
	}
`
