export interface guidelineType {
	name:
		| 'perfection'
		| 'knowledge'
		| 'human'
		| 'harmony'
		| 'constancy'
		| 'client'
		| 'pro_solutions'
		| 'protagonism'
		| 'urgency'
		| 'evolution'
	label:
		| 'Humano'
		| 'Constância'
		| 'Pró-soluções'
		| 'Urgência'
		| 'Conhecimento'
		| 'Harmonia'
		| 'Cliente'
		| 'Protagonismo'
		| 'Perfeição'
		| 'Evolução'
}

export const guidelines: guidelineType[] = [
	{ name: 'human', label: 'Humano' },
	{ name: 'constancy', label: 'Constância' },
	{ name: 'pro_solutions', label: 'Pró-soluções' },
	{ name: 'urgency', label: 'Urgência' },
	{ name: 'knowledge', label: 'Conhecimento' },
	{ name: 'harmony', label: 'Harmonia' },
	{ name: 'client', label: 'Cliente' },
	{ name: 'protagonism', label: 'Protagonismo' },
	{ name: 'perfection', label: 'Perfeição' },
	{ name: 'evolution', label: 'Evolução' }
]
