import React from 'react'
import './styles.scss'

interface Params {
	show: boolean
	text?: string
}

const LoadingProgress: React.FC<Params> = ({ show, text }) => {
	const mainContent = () => {
		return (
			<div className="modal-main-backdrop">
				<div className="loading-main-content">
					<div className="loading-main">
						<div className="loading-spinner">
							<div className="spinners">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div>
						<span>{text ? text : 'Carregando informações...'}</span>
					</div>
				</div>
			</div>
		)
	}

	return show ? mainContent() : <></>
}

export default LoadingProgress
