import produce from 'immer'

const INITIAL_STATE = {
	technicians: null,
	pageInfo: {
		pageLimit: 10,
		offset: null,
		searchTerm: '',
		stateChecked: [],
		statusChecked: []
	},
	pageNumber: 1
}

export default function techs(state = INITIAL_STATE, action) {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@techs/GOT_TECHNICIANS':
				draft.technicians = action.payload.technicians
				draft.pageInfo = action.payload.pageInfo
				draft.pageNumber = action.payload.pageNumber
				break
			case '@techs/GOT_TECH_CHANGE_PAGE':
				draft.pageNumber = action.payload.pageNumber
				break
			case '@techs/SET_SEARCH_TERM':
				draft.pageInfo.searchTerm = action.payload.term
				break
			case '@auth/SIGN_OUT': {
				draft.technicians = null
				draft.pageInfo.pageLimit = 10
				draft.pageInfo.offset = null
				draft.pageInfo.searchTerm = ''
				draft.pageInfo.stateChecked = []
				draft.pageInfo.statusChecked = []
				draft.pageNumber = 1
				break
			}
			default:
				return state
		}
	})
}
