import React, { useState } from 'react'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'

interface Params {
	register: any
	placeholder: string
}

const PasswordField: React.FC<Params> = ({ register, placeholder }) => {
	const [showPass, setShowPass] = useState<boolean>(false)

	const showPassToggle = () => {
		setShowPass(!showPass)
	}

	const showPassToggleContent = () => (
		<div onClick={showPassToggle}>
			{!showPass ? (
				<MdVisibility size={20} color="var(--primary-pure)" />
			) : (
				<MdVisibilityOff size={20} color="var(--primary-pure)" />
			)}
		</div>
	)

	return (
		<div className="toggle-input">
			<input
				{...{ ...register, placeholder }}
				maxLength={20}
				type={showPass ? 'text' : 'password'}
			/>
			{showPassToggleContent()}
		</div>
	)
}

export default PasswordField
