import { ClickAwayListener } from '@material-ui/core'
import React from 'react'
import { MdClose } from 'react-icons/md'

interface Params {
	show: boolean
	title: string
	showClose?: boolean
	awayClose?: boolean
	onClose: () => void
	children?: React.ReactNode
}

const ModalWeb: React.FC<Params> = ({
	show,
	title,
	showClose,
	awayClose,
	onClose,
	children
}) => {
	const showCloseButton = () =>
		!showClose ? (
			<></>
		) : (
			<div className="modal-header-close-content" onClick={onClose}>
				<MdClose />
			</div>
		)

	const mainChildren = () => (
		<div className="modal-component">
			<div className="modal-header-content">
				<span>{title}</span>
				{showCloseButton()}
			</div>
			<div className="modal-main-content">{children}</div>
		</div>
	)

	const handleAwayContent = () => {
		return awayClose ? (
			<ClickAwayListener onClickAway={onClose} mouseEvent="onMouseUp">
				{mainChildren()}
			</ClickAwayListener>
		) : (
			mainChildren()
		)
	}

	const mainContent = () => (
		<div className="modal-main-backdrop">{handleAwayContent()}</div>
	)

	return show ? mainContent() : <></>
}

export default ModalWeb
