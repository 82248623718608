import RequestResponse from 'models/RequestResponse'
import { Transfer } from 'models/Transfer'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmModal from 'shared/Modals/ConfirmModal'
import LoadingProgress from 'shared/components/LoadingProgress'
import { store } from 'store'
import { TransferTabOptions } from 'utils/Constants'
import TransferForm from './TransferForm'
import TransferInfo from './TransferInfo'
import TransferTabs from './TransferTabs'
import './styles.scss'

interface Props {
	transfer: Transfer
	onSuccessUpdate: () => void
}

const TransferInProgress: React.FC<Props> = ({ transfer, onSuccessUpdate }) => {
	const { onSelectTransfer, transferSelected } = useContext(
		TransfersDetailsContextElement
	)
	const { onMeetTransfer } = useContext(TransfersContextElement)

	const [isLoading, setLoading] = useState<boolean>(false)
	const [showMeetModal, setShowMeetModal] = useState<boolean>(false)
	const [tabSelected, setTabSelected] = useState<TransferTabOptions>(
		TransferTabOptions.INFO
	)

	useEffect(() => {
		onSelectTransfer(transfer)
	}, [])

	const onMeetClick = () => {
		setShowMeetModal(true)
	}

	const onMeetCancel = () => {
		setShowMeetModal(false)
	}

	const meetTransferClick = () => {
		if (transferSelected) {
			setLoading(true)
			const userId = store.getState().user.profile
			onMeetTransfer(transferSelected.id, userId, onMeetComplete, true)
		}
	}

	const onMeetComplete = (request: RequestResponse) => {
		setLoading(false)
		onMeetCancel()
		if (request.reportError) {
			toast.error('Erro ao alterada transferência')
		} else {
			onSelectTransfer(request.response)
			toast.success('Transferência alterada com sucesso!')
		}
	}

	const canGoToTransfer = (tab: TransferTabOptions) => {
		return (
			(tab === TransferTabOptions.INIT_TRANSFER && transferSelected?.userId) ||
			tab === TransferTabOptions.INFO
		)
	}

	const onTabClick = (tab: TransferTabOptions) => {
		if (canGoToTransfer(tab)) {
			setTabSelected(tab)
		}
	}

	const onNextClick = () => {
		const tab = TransferTabOptions.INIT_TRANSFER
		if (canGoToTransfer(tab)) {
			setTabSelected(tab)
		}
	}

	const meetModalContent = () => {
		return (
			<ConfirmModal
				title="TRANSFERÊNCIA"
				onCancel={onMeetCancel}
				onConfirm={meetTransferClick}
				show={showMeetModal}
				text="Deseja atender essa transferência?"
			/>
		)
	}

	const onIsLoading = (value: boolean) => {
		setLoading(value)
	}

	const loadingContent = () => {
		return <LoadingProgress show={isLoading} text="Carregando..." />
	}

	const mainContent = () => {
		if (transferSelected) {
			switch (tabSelected) {
				case TransferTabOptions.INFO:
					return <TransferInfo {...{ onNextClick, onMeetClick }} />
				case TransferTabOptions.INIT_TRANSFER:
					return <TransferForm {...{ onClose: onSuccessUpdate, onIsLoading }} />
			}
		}
	}

	return (
		<>
			<section
				id="joyride-transfer-details"
				className="transfers-details-progress-content"
			>
				<TransferTabs {...{ tabSelected, onTabClick }} />
				{mainContent()}
				{meetModalContent()}
			</section>
			{loadingContent()}
		</>
	)
}

export default TransferInProgress
