import React, { useState } from 'react'
import { DeviceType } from 'utils/Constants'
import { getDeviceType } from 'utils/Screen'
import ModalMobile from './Mobile'
import ModalWeb from './Web'
import './styles.scss'

interface Params {
	show: boolean
	showClose?: boolean
	title: string
	subTitle?: string
	defaultTitle?: string
	enableAwayClose?: boolean
	onClose: () => void
	children?: React.ReactNode
}

const Modal: React.FC<Params> = ({
	show,
	showClose,
	title,
	subTitle,
	defaultTitle,
	enableAwayClose,
	children,
	onClose
}) => {
	const [deviceType, setDeviceType] = useState<DeviceType>(getDeviceType())

	const mainContent = () => {
		return deviceType === DeviceType.MOBILE ? (
			<ModalMobile
				{...{ show, title, subTitle, defaultTitle, children, onClose }}
			/>
		) : (
			<ModalWeb
				{...{
					show,
					title,
					subTitle,
					defaultTitle,
					showClose,
					children,
					onClose,
					enableAwayClose
				}}
			/>
		)
	}

	return show ? mainContent() : <></>
}

export default Modal
