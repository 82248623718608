import OrderMenuTabs from 'module/orderManagement/components/OrderMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import { handleProtheusCommentaryTypes } from 'module/orderManagement/utils/constants'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import EmptyList from 'shared/components/EmptyList'
import { formatDateAndHour } from 'utils/Pipe'
import AttachmentsGuide from './AttachmentsGuide'
import TimelineGuide from './TimelineGuide'
import './styles.scss'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
}

const OrderFollowUpTab: React.FC<TParams> = ({ match }) => {
	const { order, prpOrderDetails, onOrderChange, sc5010 } = useContext(
		OrderDetailsContextElement
	)
	const { sa1010 } = useContext(ClientDetailsContextElement)

	const { orderCode, branch } = match.params
	const [guide, setGuide] = useState<'timeline' | 'discount' | 'attachments'>(
		'timeline'
	)
	const [arrayObservations, setArrayObservations] = useState<any[]>([])

	const parseObservations = (text: string, prop: string): any[] => {
		const clearText = text?.replace(/-{40,}/g, '').replace(/<[^>]*>/g, '')

		let objetos = [] as any[]
		if (!text) {
			return objetos
		}

		const regex =
			/(\d{1,2}\/\d{1,2}\/\d{2,4} - \d{2}:\d{2}:\d{2} - [^\n]+)\n(?:Por: ([^\n]+)\n)?([\s\S]*?)(?=\n\d{1,2}\/\d{1,2}\/\d{2,4} - \d{2}:\d{2}:\d{2} - [^\n]+|$)/g

		const matches = [...clearText.matchAll(regex)]

		objetos = matches.map((match) => {
			const data = match[1]?.split(' - ')[0]
			const hour = match[1]?.split(' - ')[1]
			const user =
				match[1]?.split(' - ')[2].trim() === 'PRP'
					? match[2] || 'PRP'
					: match[1]?.split(' - ')[2]
			return {
				type: handleProtheusCommentaryTypes(prop),
				createdAt: formatDateAndHour(data, hour),
				user: { name: user },
				content: match[3]
			}
		})
		setArrayObservations((prevArray) => [...prevArray, ...objetos])
		return objetos
	}

	useEffect(() => {
		for (const prop in sc5010) {
			if (timelineGuide.includes(prop)) {
				const sc5 = sc5010 as any
				parseObservations(sc5[prop], prop)
			}
		}
	}, [sc5010])

	useEffect(() => {
		if (order?.code !== orderCode) {
			onOrderChange(orderCode, branch)
		}
	}, [orderCode])

	return order && sa1010 && prpOrderDetails ? (
		<>
			<OrderMenuTabs path={match.path} />
			<TabTitles />
			<div className="order-follow-up-tab-guide-selector">
				<span onClick={() => setGuide('timeline')}>TIMELINE</span>
				<span onClick={() => setGuide('attachments')}>ANEXOS</span>
			</div>
			{guide === 'timeline' && (
				<TimelineGuide
					prpOrder={prpOrderDetails}
					array={arrayObservations}
					sc5010={sc5010}
				/>
			)}
			{guide === 'attachments' && (
				<AttachmentsGuide prpOrder={prpOrderDetails} />
			)}
		</>
	) : (
		<>
			<OrderMenuTabs path={match.path} />
			<EmptyList
				show={!(order && sa1010)}
				text={'Nenhuma informação do pedido encontrada!'}
			/>
		</>
	)
}
export default OrderFollowUpTab

const timelineGuide: any = ['c5Obspcp', 'c5Follow', 'c5Obsexp2', 'c5Obsmemo']
