import Client from 'models/Client'
import Equipment from 'models/Equipment'
import React from 'react'
import './styles.css'

interface Params {
	equipment: Equipment
	financialBlock: boolean
	generalBlock: boolean
	client: Client
}

const ServiceOrderEquipInfo: React.FC<Params> = ({
	client,
	financialBlock,
	generalBlock,
	equipment
}) => {
	const financialBlockContent = () => {
		return financialBlock ? <div>Cliente com bloqueio financeiro</div> : <></>
	}

	const generalBlockContent = () => {
		return generalBlock ? <div>Cliente com bloqueio geral</div> : <></>
	}

	const clientStatusContent = () => {
		return (
			<div className="service-order-client-status-info">
				{generalBlockContent()}
				{financialBlockContent()}
			</div>
		)
	}

	return (
		<>
			<div className="service-order-equipment-info">
				<div>
					<span title={equipment.name || ''}>
						<b>Nome: </b> {equipment.name}
					</span>
					<span>
						<b>Número de Série: </b> {equipment.serial_number}
					</span>
				</div>
				<div>
					<span
						title={`${client?.cod_protheus} - ${client?.nome_fantasia}` || ''}
					>
						<b>Cliente: </b>
						{`${client?.cod_protheus} - ${client?.nome_fantasia}`}
					</span>
					<span>
						<b>CNPJ: </b> {client.cpfCnpj}
					</span>
				</div>
			</div>
			{clientStatusContent()}
		</>
	)
}

export default ServiceOrderEquipInfo
