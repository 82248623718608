import { ClickAwayListener } from '@material-ui/core'
import React from 'react'
import './styles.scss'

type Props = {
	onToggleTooltip: () => void
}

export const TooltipInfo = ({ onToggleTooltip }: Props) => {
	return (
		<ClickAwayListener onClickAway={onToggleTooltip}>
			<div className="m-tooltip" onClick={onToggleTooltip}>
				<span onClick={onToggleTooltip}>x</span>
				<p>
					O <strong>número de série</strong> está localizado em uma das laterais
					do equipamento.
				</p>
			</div>
		</ClickAwayListener>
	)
}
