import { Button, Select } from '@praticabr/ppsa'
import React from 'react'
import { onUpdateFile } from 'services'
import Modal from 'shared/components/Modal'
import './styles.scss'

type AttachmentCategoryProps = {
	onClose: () => void
	file: any
}

const fileAttachmentCategories = [
	{ value: '', label: 'Geral' },
	{ value: 'attachmentsFinance', label: 'Financeiro' },
	{ value: 'attachmentsShipping', label: 'Expedição' },
	{ value: 'attachmentsOrder', label: 'ADM de Pedidos' }
]

export const AttachmentCategoryModal = ({
	onClose,
	file
}: AttachmentCategoryProps) => {
	const [selectedCategory, setSelectedCategory] = React.useState(
		file?.attachmentCategory || file?.attachment_category || ''
	)

	const onSubmit = () => {
		onUpdateFile({ id: file.id, attachment_category: selectedCategory }).then(
			() => {
				onClose()
			}
		)
	}

	return (
		<Modal show={true} title={'Editar Categoria do Anexo'} {...{ onClose }}>
			<div className="attachment-category-modal">
				<div className="header">
					<p>
						Caso a categoria seja alterada para uma que você não tenha
						permissão, você e outros usuários sem a permissão também não poderão
						visualizar o anexo.
					</p>
				</div>
				<div className="container">
					<div className="description">
						<p>{file?.attachmentName || file?.name}</p>
					</div>
					<div className="category">
						<Select
							options={fileAttachmentCategories}
							selectedOption={[selectedCategory || '']}
							onSelectOptions={(option) => setSelectedCategory(option[0])}
						/>
					</div>
				</div>
				<div className="action">
					<Button
						title="Salvar"
						variant="confirmation"
						size="lg"
						onClick={onSubmit}
					/>
					<Button
						title="Cancelar"
						variant="cancellation"
						size="lg"
						onClick={onClose}
					/>
				</div>
			</div>
		</Modal>
	)
}
