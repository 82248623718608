import React from 'react'

export const ProductsCardIcon = () => {
	return (
		<svg
			width="70"
			height="70"
			viewBox="0 0 70 70"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<linearGradient id="gradient" x1="0" x2="0" y1="0" y2="1">
				<stop offset="0%" stopColor="#4F8FE9" stopOpacity="0.6" />
				<stop offset="45%" stopColor="#396BC9" stopOpacity="0.6" />
				<stop offset="100%" stopColor="#5e50ae" stopOpacity="0.6" />
			</linearGradient>
			<rect fill="url(#gradient)" />
			<path
				d="M53.1481 58.6091H47.3407L48.1704 64.3364H52.3185L53.1481 58.6091Z"
				fill="#D9D9D9"
			/>
			<path
				d="M22.6593 58.6091H16.8519L17.6815 64.3364H21.8296L22.6593 58.6091Z"
				fill="#D9D9D9"
			/>
			<path d="M15.4 19.95H54.6V48.5864H15.4V19.95Z" fill="url(#gradient)" />
			<path
				d="M15.4 50.0182H54.6V57.1773C54.6 57.968 53.95 58.6091 53.1481 58.6091H16.8519C16.05 58.6091 15.4 57.968 15.4 57.1773V50.0182Z"
				fill="url(#gradient)"
			/>
			<path
				d="M15.4 5.63182C15.4 4.84105 16.05 4.2 16.8519 4.2H53.1481C53.95 4.2 54.6 4.84105 54.6 5.63182V18.5182H15.4V5.63182Z"
				fill="url(#gradient)"
			/>
			<path
				d="M25.563 8.49545C25.563 7.70468 26.213 7.06364 27.0148 7.06364H42.9852C43.787 7.06364 44.437 7.70468 44.437 8.49545V14.2227C44.437 15.0135 43.787 15.6545 42.9852 15.6545H27.0148C26.213 15.6545 25.563 15.0135 25.563 14.2227V8.49545Z"
				fill="#f8f9fa"
			/>
			<path
				d="M29.9185 42.8591C29.9185 42.0683 30.5685 41.4273 31.3704 41.4273H38.6296C39.4315 41.4273 40.0815 42.0683 40.0815 42.8591V44.2909C40.0815 45.0817 39.4315 45.7227 38.6296 45.7227H31.3704C30.5685 45.7227 29.9185 45.0817 29.9185 44.2909V42.8591Z"
				fill="#f8f9fa"
			/>
			<path d="M15.4 48.5864H54.6V50.0182H15.4V48.5864Z" fill="#f8f9fa" />
			<path d="M15.4 18.5182H54.6V19.95H15.4V18.5182Z" fill="#f8f9fa" />
			<path
				d="M50.2444 11.3591C50.2444 12.1499 49.5944 12.7909 48.7926 12.7909C47.9908 12.7909 47.3407 12.1499 47.3407 11.3591C47.3407 10.5683 47.9908 9.92727 48.7926 9.92727C49.5944 9.92727 50.2444 10.5683 50.2444 11.3591Z"
				fill="#f8f9fa"
			/>
			<path
				d="M51.6963 24.2455C51.6963 25.827 50.3963 27.1091 48.7926 27.1091C47.1889 27.1091 45.8889 25.827 45.8889 24.2455C45.8889 22.6639 47.1889 21.3818 48.7926 21.3818C50.3963 21.3818 51.6963 22.6639 51.6963 24.2455Z"
				fill="#B5B4B4"
			/>
			<path
				d="M24.1111 24.2455C24.1111 25.827 22.8111 27.1091 21.2074 27.1091C19.6037 27.1091 18.3037 25.827 18.3037 24.2455C18.3037 22.6639 19.6037 21.3818 21.2074 21.3818C22.8111 21.3818 24.1111 22.6639 24.1111 24.2455Z"
				fill="#B5B4B4"
			/>
			<path
				d="M19.7556 24.2455C19.7556 23.4547 20.4056 22.8136 21.2074 22.8136H48.7926C49.5944 22.8136 50.2444 23.4547 50.2444 24.2455C50.2444 25.0362 49.5944 25.6773 48.7926 25.6773H21.2074C20.4056 25.6773 19.7556 25.0362 19.7556 24.2455Z"
				fill="#f8f9fa"
			/>
			<path
				d="M21.8296 64.3364H17.6815L17.3807 65.3748C17.1157 66.2895 17.8121 67.2 18.7767 67.2H20.7346C21.6992 67.2 22.3956 66.2895 22.1306 65.3748L21.8296 64.3364Z"
				fill="url(#gradient)"
			/>
			<path
				d="M52.3185 64.3364H48.1704L47.8696 65.3748C47.6046 66.2895 48.301 67.2 49.2656 67.2H51.2235C52.1881 67.2 52.8845 66.2895 52.6195 65.3748L52.3185 64.3364Z"
				fill="url(#gradient)"
			/>
			<path
				d="M24.1111 58.6091H45.8889C45.8889 59.3999 45.2377 60.0409 44.4359 60.0409C37.2872 60.0409 32.7128 60.0409 25.5641 60.0409C24.7623 60.0409 24.1111 59.3999 24.1111 58.6091Z"
				fill="#D9D9D9"
			/>
			<path
				d="M36.2594 39.9955H35.9811C36.0805 39.651 36.1837 39.3063 36.2801 38.9612H36.5574L36.2594 39.9955Z"
				fill="#f8f9fa"
			/>
			<path
				d="M32.7775 39.1475C32.7157 39.3459 32.5191 39.4912 32.2854 39.4931C32.108 39.4931 32.0121 39.4931 31.8346 39.4931L31.6897 39.9952H31.3704L31.7836 38.5636H32.5471C32.7165 38.5636 32.8538 38.6907 32.8538 38.8475C32.8538 38.8716 32.8505 38.895 32.8444 38.9173L32.7775 39.1475ZM32.1548 39.3374C32.3132 39.3374 32.4468 39.2397 32.4893 39.1058L32.4915 39.0978L32.5423 38.9226C32.5442 38.9164 32.5458 38.9102 32.547 38.9042C32.5501 38.8965 32.5525 38.8884 32.554 38.8802C32.5566 38.8703 32.558 38.8601 32.558 38.8495C32.558 38.778 32.4954 38.72 32.4181 38.72H32.4087C32.4034 38.72 32.398 38.7202 32.3927 38.7208C32.3861 38.7203 32.3794 38.72 32.3726 38.72H32.0579L31.8797 39.3373L32.1548 39.3374Z"
				fill="#f8f9fa"
			/>
			<path
				d="M36.1256 39.0821H35.7855L35.5219 39.9955H35.244C35.3304 39.6964 35.4211 39.3818 35.5076 39.0826L35.1317 39.0822L35.1672 38.9614C35.487 38.9626 35.8424 38.9614 36.1623 38.9614C36.1487 39.0068 36.1391 39.0366 36.1256 39.0821Z"
				fill="#f8f9fa"
			/>
			<path
				d="M33.817 39.4637L33.878 39.2525C33.883 39.2339 33.8855 39.2147 33.8855 39.1954C33.8855 39.066 33.7722 38.9611 33.6323 38.9611H32.9751L32.6766 39.9952C32.7695 39.9952 32.8624 39.9952 32.9554 39.9952C33.0416 39.6964 33.1291 39.3809 33.219 39.0819H33.5058C33.5675 39.0819 33.6175 39.1282 33.6175 39.1853C33.6175 39.192 33.6168 39.1986 33.6155 39.2051C33.6148 39.2096 33.6136 39.2143 33.6123 39.2188C33.5919 39.2864 33.5737 39.3553 33.5534 39.4222C33.534 39.4862 33.467 39.5304 33.3958 39.5304L33.3942 39.5303C33.3579 39.5303 33.3188 39.5303 33.2775 39.5303L33.1309 39.6659H33.4095L33.4545 39.9952H33.6006C33.6366 39.9951 33.7183 39.9872 33.7868 39.9237L34.695 39.0819H34.7319L34.5032 39.8742H34.1868L34.0543 39.9952H34.7507L35.0492 38.9609H34.6402L33.6815 39.8496L33.6609 39.6348C33.736 39.5998 33.7926 39.5381 33.817 39.4637Z"
				fill="#f8f9fa"
			/>
			<path
				d="M36.595 39.2389C36.6427 39.0751 36.8032 38.9613 36.9865 38.9613H37.3325C37.4723 38.9613 37.5858 39.0662 37.5858 39.1956C37.5858 39.2149 37.5833 39.234 37.5778 39.253L37.567 39.2911H37.2915C37.2989 39.2636 37.3079 39.2348 37.3143 39.2072C37.3299 39.1442 37.2751 39.0812 37.205 39.0822H37.2007L37.0412 39.0814C36.967 39.0811 36.9045 39.1281 36.8864 39.1914C36.8341 39.3741 36.7804 39.5569 36.7285 39.7395C36.709 39.8082 36.7645 39.8745 36.8401 39.8743C36.9345 39.8743 37.0289 39.8743 37.1231 39.8743C37.1977 39.874 37.2653 39.8459 37.3141 39.8006L38.2198 38.9612H38.6296C38.5301 39.3059 38.4306 39.6507 38.3311 39.9953C38.0994 39.9953 37.8677 39.9953 37.6361 39.9953L37.7667 39.8743H38.0837L38.3124 39.0821H38.276L37.4396 39.859C37.3833 39.9108 37.2737 39.9953 37.0864 39.9953H36.7122C36.5533 39.9953 36.4099 39.8813 36.472 39.666L36.595 39.2389Z"
				fill="#f8f9fa"
			/>
			<path
				d="M35.2343 38.6946H34.9274L34.7804 38.8309H35.0872L35.2343 38.6946Z"
				fill="#f8f9fa"
			/>
		</svg>
	)
}
