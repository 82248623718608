import React from 'react'
import './styles.scss'

interface SvgProps extends React.SVGProps<SVGSVGElement> {
	width: string
	height: string
	fill?: string
	id?: string
}

const AddIcon: React.FC<SvgProps> = ({ fill }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="50"
			height="40"
			viewBox="0 0 50 40"
		>
			<defs></defs>
			<g transform="translate(-1603 -7423)" fill={fill}>
				<rect
					className="a"
					width="50"
					height="40"
					transform="translate(1603 7423)"
				/>
				<path
					d="M-1138-5977a14.9,14.9,0,0,1-10.607-4.393A14.9,14.9,0,0,1-1153-5992a14.9,14.9,0,0,1,4.394-10.606A14.9,14.9,0,0,1-1138-6007a14.9,14.9,0,0,1,10.607,4.394A14.9,14.9,0,0,1-1123-5992a14.9,14.9,0,0,1-4.394,10.606A14.9,14.9,0,0,1-1138-5977Zm-6-16a1,1,0,0,0-1,1,1,1,0,0,0,1,1h5v5a1,1,0,0,0,1,1,1,1,0,0,0,1-1v-5h5a1,1,0,0,0,1-1,1,1,0,0,0-1-1h-5v-5a1,1,0,0,0-1-1,1,1,0,0,0-1,1v5Z"
					transform="translate(2766 13435)"
				/>
			</g>
		</svg>
	)
}

export default AddIcon
