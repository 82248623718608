import LoginForm from 'module/entryPoint/components/Account/LoginForm'
import PageTitle from 'module/entryPoint/components/PageTitle'
import PraticaImage from 'module/entryPoint/components/PraticaImage'
import SideBar from 'module/entryPoint/components/SideBar'
import React from 'react'
import './styles.scss'

const Login: React.FC = () => {
	return (
		<>
			<PageTitle title="Prática Produtos" single />
			<section className="login-main-content">
				<SideBar>
					<LoginForm />
				</SideBar>
				<PraticaImage />
			</section>
		</>
	)
}

export default Login
