import Department from 'models/Department'
import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import {
	GetByRaMat,
	createNewUser,
	getAllDepartments,
	getPrpUsers,
	getUserPrpOverview,
	updateUserById
} from 'services'
import { store } from 'store'
import { setEmployeesParams } from 'store/modules/humanResources/actions'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'
import { UpdateEmployeeSchema } from '../schemas/update-employee-schema'

export interface EmployeesContextType {
	employee: any | null
	employees: any[]
	departments: Department[]
	isLoading: boolean
	sra010: any | null
	pageLimit: number
	totalPages: number
	currentPage: number
	onUpdateList: () => void
	filtersParams?: FilterData
	onSelectEmployee: (employeeId: number) => void
	onCleanData: () => void
	onGetSra010: (raMat: string) => void
	onUpdateEmployee: (data: {
		userId: number
		data: UpdateEmployeeSchema
		callback?: () => void
	}) => void
	onRegisterEmployee: (data: { data: any; callback?: () => void }) => void
	onChangeParams: ({
		page,
		limit,
		search,
		filter
	}: {
		page?: number
		limit?: number
		search?: string
		filter?: FilterData
	}) => void
}

export const EmployeesContextElement = createContext<EmployeesContextType>({
	employee: null,
	employees: [],
	departments: [],
	isLoading: false,
	sra010: null,
	pageLimit: 10,
	totalPages: 1,
	currentPage: 0,
	onUpdateList: () => {},
	onSelectEmployee: () => {},
	onCleanData: () => {},
	onGetSra010: () => {},
	onUpdateEmployee: () => {},
	onRegisterEmployee: () => {},
	onChangeParams: () => {}
})

const defaultParams = defaultListParams(
	store.getState()?.humanResources?.employees?.listEmployeesParams,
	false
)

export default function EmployeesProvider(props: any) {
	const [employee, setEmployee] = useState<any | null>(null)
	const [sra010, setSra010] = useState<any | null>(null)
	const [employees, setEmployees] = useState<any[]>([])
	const [departments, setDepartments] = useState<Department[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const listParams = useRef<ListParams>(defaultParams)

	const dispatch = useDispatch()
	useEffect(() => {
		setIsLoading(true)
		getAllDepartments()
			.then((response) => response.data && setDepartments(response.data))
			.catch(() =>
				toast.error('Houve um erro ao buscar lista de departamentos!')
			)
			.finally(() => setIsLoading(false))
		onRequesEmployees(listParams.current)
	}, [])

	const onRequesEmployees = ({
		currentPage,
		pageLimit,
		searchValue
	}: ListParams) => {
		setIsLoading(true)
		getPrpUsers({
			limit: pageLimit,
			offset: currentPage - 1,
			search: searchValue
		})
			.then((response) => {
				const { users, count } = response.data
				setEmployees(users)
				updateParams(Math.ceil(count / pageLimit), 'count')
			})
			.catch(() => {
				toast.error('Erro ao buscar lista de usuários')
				clearParams(true)
			})
			.finally(() => setIsLoading(false))
	}

	const onRequestSra010 = (raMat: string) => {
		setSra010(null)
		setIsLoading(true)
		GetByRaMat({ raMat })
			.then((response) => setSra010(response.data))
			.catch(() => toast.error('Erro ao buscar colaborador'))
			.finally(() => setIsLoading(false))
	}

	const onSelectEmployee = (employeeId: number) => {
		setEmployee(null)
		setIsLoading(true)
		getUserPrpOverview(employeeId)
			.then((response) => setEmployee(response.data))
			.catch(() => toast.error('Erro ao buscar informações do usuário'))
			.finally(() => setIsLoading(false))
	}

	const onUpdateEmployee = ({
		userId,
		data,
		callback
	}: {
		userId: number
		data: UpdateEmployeeSchema
		callback?: () => void
	}) => {
		setIsLoading(true)
		updateUserById({ userId, data })
			.then(() => {
				onRequesEmployees(listParams.current)
				toast.success('Usuário atualizado com sucesso!')
				callback && callback()
			})
			.catch(() => toast.error('Erro ao atualizar informações do usuário!'))
			.finally(() => setIsLoading(false))
	}

	const onRegisterEmployee = ({
		data,
		callback
	}: {
		data: any
		callback?: () => void
	}) => {
		setIsLoading(true)
		createNewUser(data)
			.then((response) => {
				if (response.data.statusCode === 203) {
					return toast.info(response.data.message)
				}
				onRequesEmployees(listParams.current)
				toast.success('Colaborador registrado com sucesso!')
				callback && callback()
			})
			.catch(() => toast.error('Erro ao atualizar informações do colaborador!'))
			.finally(() => setIsLoading(false))
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(setEmployeesParams(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(setEmployeesParams(listParams.current))
	}

	const onChangeParams = (newParams: {
		page?: number
		limit?: number
		search?: string
		filter?: FilterData
	}) => {
		updateParams(newParams.search || '', 'search')
		newParams.page && updateParams(newParams.page, 'page')
		newParams.limit && updateParams(newParams.limit, 'limit')
		newParams.filter && updateParams(newParams.filter, 'filter')

		onRequesEmployees({ ...listParams.current, ...newParams })
	}

	const providerValues = () => ({
		employee,
		employees,
		departments,
		isLoading,
		sra010,
		...listParams.current,
		onChangeParams,
		onSelectEmployee,
		onRegisterEmployee,
		onUpdateEmployee,
		onCleanData: () => {
			setEmployee(null)
			setSra010(null)
		},
		onGetSra010: onRequestSra010,
		onUpdateList: () => onRequesEmployees(listParams.current)
	})

	return (
		<EmployeesContextElement.Provider value={providerValues()}>
			{props.children}
		</EmployeesContextElement.Provider>
	)
}
