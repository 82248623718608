import styled from 'styled-components'

export const Container = styled.div`
	width: 60%;
	margin-right: auto;
	margin-left: auto;
	padding-top: 20px;
`

export const Title = styled.div`
	display: block;
	margin-bottom: 37px;
	font-size: 18px;
	line-height: 21px;
	font-weight: 500;
`

export const Input = styled.input`
	margin-bottom: 0px;
	border-radius: 3px;
	display: block;
	width: 100%;
	height: 38px;
	padding: 8px 12px;
	font-size: 14px;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const Button = styled.button`
	border-radius: 3px !important;
	background-color: #004687 !important;
	margin-top: 30px !important;
`

export const InfoName = styled.div`
	width: 130px;
	margin-right: 15px;
	padding-right: 5px;
	font-size: 20px;
	line-height: 21px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
	flex-grow: 0;
	flex-shrink: 0;
`

export const Info = styled.div`
	width: auto;
	padding-right: 15px;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.45);
	border-radius: 3px;
	background-color: transparent;
	font-size: 20px;
	line-height: 21px;
	font-weight: 400;
	text-align: left;
`

export const TitleSenha = styled.div`
	width: auto;
	line-height: 25px;
	font-weight: 500;
	margin-right: 15px;
	padding-right: 5px;
	font-size: 20px;
	line-height: 25px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
	flex-grow: 0;
	flex-shrink: 0;
`
