import { Order } from 'module/orderManagement/domain/models/order'
import {
	formatCurrency,
	handleCarrierType,
	handleFreightTypes,
	handlePartialDeliveryReleaseTypes,
	handleShipmentReleaseType
} from 'module/orderManagement/utils/constants'
import React from 'react'

export const ShippingDetailsViewingMode = ({ order }: { order: Order }) => (
	<>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Transportadora</header>
			<span>
				{order?.freight?.shipping_company
					? handleCarrierType(order?.freight?.shipping_company)
					: '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Tipo de frete</header>
			<span>
				{order?.freight?.type ? handleFreightTypes(order?.freight?.type) : '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Entrega Parcial</header>
			<span>
				{order?.freight?.partial_delivery
					? handlePartialDeliveryReleaseTypes(order?.freight?.partial_delivery)
					: '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Libera expedição</header>
			<span>
				{order?.freight?.release_shipment
					? handleShipmentReleaseType(order?.freight?.release_shipment)
					: '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Valor do frete</header>
			<span>
				{order?.freight?.cost ? formatCurrency(order?.freight?.cost) : '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Frete embutido</header>
			<span>
				{order?.freight?.embedded
					? formatCurrency(order?.freight?.embedded)
					: '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Frete comercial</header>
			<span>
				{order?.freight?.commercial
					? formatCurrency(order?.freight?.commercial)
					: '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Tipo de volume</header>
			<span>
				{order?.freight?.package_type ? order?.freight?.package_type : '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Quantidade de volumes</header>
			<span>
				{order?.freight?.package_amount ? order?.freight?.package_amount : '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Peso líquido</header>
			<span>
				{order?.freight?.net_weight ? order?.freight?.net_weight : '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Peso bruto</header>
			<span>
				{order?.freight?.gross_weight ? order?.freight?.gross_weight : '-'}
			</span>
		</div>
		<div className="new-order-shipping-details-information-contain-information">
			<header>Redespacho</header>
			<span>
				{order?.freight?.reDelivery_shipping_company
					? handleCarrierType(order?.freight?.reDelivery_shipping_company)
					: '-'}
			</span>
		</div>
	</>
)
