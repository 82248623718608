import { MoreOptionsItem } from 'models/MoreOptionsItem'
import RequestResponse from 'models/RequestResponse'
import { Transfer } from 'models/Transfer'
import MoreOptions from 'module/technicalServices/components/MoreOptions'
import { TransfersContextElement } from 'module/technicalServices/context/Transfers/TransfersContext/TransfersContext'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmModal from 'shared/Modals/ConfirmModal'
import LoadingProgress from 'shared/components/LoadingProgress'
import { store } from 'store'
import { TransferOrigin, TransferStatus } from 'utils/Constants'
import './styles.scss'

interface Params {
	transfer: Transfer
	onItemClick: (transfer: Transfer) => void
}

const TransfersListItemMobile: React.FC<Params> = ({
	transfer,
	onItemClick
}) => {
	const { onMeetTransfer, onRemoveTransfer } = useContext(
		TransfersContextElement
	)

	const [isLoading, setLoading] = useState<boolean>(false)
	const [loadingMessage, setLoadingMessage] = useState<string>('')

	const [showMeetModal, setShowMeetModal] = useState<boolean>(false)
	const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false)

	const onMeetClick = () => {
		setLoadingMessage('Atualizando Transferência')
		setShowMeetModal(true)
	}

	const onMeetCancel = () => {
		setShowMeetModal(false)
	}

	const meetTransfer = () => {
		setLoading(true)
		const userId = store.getState().user.profile
		onMeetTransfer(transfer.id, userId, onMeetComplete)
	}

	const onMeetComplete = (request: RequestResponse) => {
		setLoading(false)
		onMeetCancel()
		if (request.reportError) {
			toast.error('Erro ao alterada transferência')
		} else {
			toast.success('Transferência alterada com sucesso!')
		}
	}

	const onRemoveClick = () => {
		setLoadingMessage('Excluindo Transferência')
		setShowRemoveModal(true)
	}

	const onRemoveCancel = () => {
		setShowRemoveModal(false)
	}

	const removeTransfer = () => {
		setLoading(true)
		onRemoveTransfer(transfer.id, onRemoveComplete)
	}

	const onRemoveComplete = (request: RequestResponse) => {
		setLoading(false)
		onRemoveCancel()
		if (request.reportError) {
			toast.error('Erro ao remover solicitação de transferência')
		} else {
			toast.success('Transferência removida com sucesso!')
		}
	}

	const transferDateContent = () => {
		return transfer.transferAt ? (
			<span>{moment(transfer.transferAt).format('DD/MM/YYYY')}</span>
		) : (
			<span>--</span>
		)
	}

	const onClick = () => {
		try {
			transfer.transferPayloadParsed = JSON.parse(transfer.transferPayload)
			onItemClick(transfer)
		} catch (error) {
			toast.error('Falha ao carregar detalhes da transferência')
		}
	}

	const meetModalContent = () => {
		return (
			<ConfirmModal
				title="TRANSFERÊNCIA"
				onCancel={onMeetCancel}
				onConfirm={meetTransfer}
				show={showMeetModal}
				text="Deseja atender essa transferência?"
			/>
		)
	}

	const removeModalContent = () => {
		return (
			<ConfirmModal
				title="TRANSFERÊNCIA"
				onCancel={onRemoveCancel}
				onConfirm={removeTransfer}
				show={showRemoveModal}
				text="Deseja remover essa transferência?"
			/>
		)
	}

	const loadingContent = () => {
		return <LoadingProgress show={isLoading} text="Carregando..." />
	}

	const menuOptionsContent = () => {
		const menuOptionsList = [
			{ label: 'Atender', onClick: onMeetClick },
			{ label: 'Excluir', onClick: onRemoveClick }
		] as MoreOptionsItem[]
		return <MoreOptions options={menuOptionsList} />
	}

	const originContent = () => {
		switch (transfer.origin) {
			case TransferOrigin.APP:
				return 'Aplicativo'
			default:
				return 'Manual'
		}
	}

	const statusContent = () => {
		switch (transfer.status) {
			case TransferStatus.OPEN:
				return 'Pendente'
			case TransferStatus.IN_PROGRESS:
				return 'Em andamento'
			case TransferStatus.FINISHED:
				return 'Concluída'
			default:
				return 'Removida'
		}
	}

	return (
		<>
			<div className="transfer-list-item" onClick={onClick}>
				<div className="transfer-info">
					<div className="transfer-info-item">
						<span className="transfer-info-title">N/S:</span>
						<span className="transfer-info">{transfer.serialNumber}</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Canal:</span>
						<span className="transfer-info">{originContent()}</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Dt. Solicitação:</span>
						<span className="transfer-info">
							{moment(transfer.createdAt).format('DD/MM/YYYY')}
						</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Cliente atual:</span>
						<span className="transfer-info">{transfer.currentClientName}</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Equipamento:</span>
						<span className="transfer-info">{transfer.equipmentName}</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Dt. Transferência:</span>
						<span className="transfer-info">{transferDateContent()}</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Status:</span>
						<span className="transfer-info">{statusContent()}</span>
					</div>
					<div className="transfer-info-item">
						<span className="transfer-info-title">Atendente:</span>
						<span className="transfer-info">
							{transfer.userName ? transfer.userName : `-`}
						</span>
					</div>
				</div>
				<div id="joyride-transfer-options" className="options">
					{menuOptionsContent()}
				</div>
			</div>
			{meetModalContent()}
			{removeModalContent()}
			{loadingContent()}
		</>
	)
}

export default TransfersListItemMobile
