import { Radio, Tooltip } from '@material-ui/core'
import Client from 'models/Client'
import React from 'react'

import './styles.css'

interface Props {
	clients: Client[]
	clientSelectedId: number
	searchValue: string
	onChange: (id: number) => void
}
const PreventiveClientsList: React.FC<Props> = ({
	clients,
	clientSelectedId,
	onChange
}) => {
	return (
		<div className="client-list">
			{clients?.length ? (
				clients.map((client: Client) => {
					const address =
						client.street +
						', ' +
						client.neighborhood +
						', ' +
						client.city +
						' - ' +
						client.state
					return (
						<div className="client-item" key={client.id}>
							<div className="client-radio">
								<Radio
									checked={clientSelectedId === client.id}
									onChange={() => {
										onChange(client.id)
									}}
									value={client.id}
									name="radio-button-demo"
								/>
							</div>
							<div className="client-info">
								<Tooltip
									title={`${client.nome_fantasia}`}
									interactive
									arrow={true}
									placement={'right-start'}
								>
									<div className="client-name">{client.nome_fantasia}</div>
								</Tooltip>
								<Tooltip
									title={`${client.cpfCnpj?.replace(
										/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
										'$1.$2.$3/$4-$5'
									)}`}
									interactive
									arrow={true}
									placement={'right-start'}
								>
									<div className="client-cnpj">
										{client.cpfCnpj?.replace(
											/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
											'$1.$2.$3/$4-$5'
										)}
									</div>
								</Tooltip>
								<Tooltip
									title={`${address}`}
									interactive
									arrow={true}
									placement={'right-start'}
								>
									<div className="client-address">{address}</div>
								</Tooltip>

								<div className="client-equips">{`${client.equipmentsCount} equipamentos`}</div>
							</div>
						</div>
					)
				})
			) : (
				<></>
			)}
		</div>
	)
}

export default PreventiveClientsList
