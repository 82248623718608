import Client from 'models/Client'
import Equipment from 'models/Equipment'
import DefectSelector from 'module/technicalServices/components/DefectSelector'
import Equipments from 'module/technicalServices/components/Equipments'
import OptionsTypeOs from 'module/technicalServices/components/TypeOsSelector'
import React, { useEffect, useState } from 'react'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import { Footer, Header, RequestDetails } from './styles'
import './styles.scss'

interface Props {
	equipment?: Equipment
	client?: Client
	infoOs?: any
	onClick: (selectedTypeOS: string, defect: string) => void
	onClose: () => void
}

const Change_OS_Type: React.FC<Props> = ({
	client,
	equipment,
	onClick,
	infoOs,
	onClose
}) => {
	const [selectedTypeOS, setSelectedTypeOS] = useState<string>(infoOs.type)
	const [defect, setDefect] = useState<string>(infoOs.defect)

	useEffect(() => {
		setSelectedTypeOS(infoOs.type)
		setDefect(infoOs.defect)
	}, [infoOs])

	//set value to selectedTypeOS by clicking on one of the OS Types from the list
	function onSelectType(selectedTypeOS: string) {
		const data = JSON.parse(selectedTypeOS)
		setSelectedTypeOS(data.value)
	}

	//set value to defect by clicking on one of the Defects from the list
	function handleDefectSelect(defect: string) {
		setDefect(defect)
	}

	//validates that the OS type and the defect are not empty and sends values via onClick
	function onSubmit() {
		if (selectedTypeOS !== '' && defect !== '') {
			setTimeout(() => {
				onClick(selectedTypeOS, defect)
			}, 500)
			onClose()
		}
	}

	//checks if equipment exists and returns OS Type select
	const optionsTypeContent = () => {
		return equipment ? (
			<OptionsTypeOs
				selectedType={infoOs.type}
				serialNumber={equipment.serial_number}
				onSelectType={onSelectType}
			/>
		) : (
			<></>
		)
	}

	return (
		<Modal
			show={true}
			onClose={onClose}
			title="Alterar tipo da OS"
			showClose={true}
		>
			<div className="service-order-change-os-type">
				<Header>
					<Equipments client={client} equipment={equipment} />
				</Header>

				<RequestDetails>
					<div className="field-title">Tipo de serviço:</div>
					{optionsTypeContent()}
					<div className="form-area">
						<div className="field-title">Def. Relatado:</div>
						<DefectSelector
							selectedDefect={infoOs.defect}
							onSelectDefect={handleDefectSelect}
						/>
					</div>
				</RequestDetails>
				<Footer>
					<Button
						style={{ width: '120px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Salvar"
						onClick={onSubmit}
					/>
					<Button
						style={{ width: '120px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={onClose}
					/>
				</Footer>
			</div>
		</Modal>
	)
}

export default Change_OS_Type
