import { OrderDetailsContextElement } from 'module/orderManagement/context/OrderDetailsContext/OrderDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Button from 'shared/components/Button'
import { store } from 'store'
import './styles.scss'

type TParams = {
	path: string
}

const OrderMenuTabsWeb = ({ path }: TParams) => {
	const { sc5010 } = useContext(OrderDetailsContextElement)
	const [partOrderFollowUpTab, setPartOrderFollowUpTab] =
		useState<boolean>(false)
	const [partOrderPendencyTab, setPartOrderPendencyTab] =
		useState<boolean>(false)
	const [partOrderDetailsTab, setPartOrderDetailsTab] = useState<boolean>(false)
	const [partOrderHistoryTab, setPartOrderHistoryTab] = useState<boolean>(false)
	const [partOrderStorageTab, setPartOrderStorageTab] = useState<boolean>(false)
	const [partOrderChecklistTab, setPartOrderChecklistTab] =
		useState<boolean>(false)
	const [partOrderClientTab, setPartOrderClientTab] = useState<boolean>(false)
	const [partOrderUser, setPartOrderUser] = useState<boolean>(false)
	const { roles } = store.getState().user

	useEffect(() => {
		roles.includes('partsOrderUser') && setPartOrderUser(true)
	}, [roles])

	useEffect(() => {
		switch (path) {
			case `/order/:branch/:orderCode/client/:clientCode/:clientStoreCode`:
				setPartOrderClientTab(true)
				break
			case '/order/:branch/:orderCode/details':
				setPartOrderDetailsTab(true)
				break
			case `/order/:branch/:orderCode/client/:clientCode/:clientStoreCode/history`:
				setPartOrderHistoryTab(true)
				break
			case '/order/:branch/:orderCode/followUp':
				setPartOrderFollowUpTab(true)
				break
			case '/order/:branch/:orderCode/pendency':
				setPartOrderPendencyTab(true)
				break
			case '/order/:branch/:orderCode/storage':
				setPartOrderStorageTab(true)
				break
			case '/order/:branch/:orderCode/checklist':
				setPartOrderChecklistTab(true)
				break
			default:
				setPartOrderDetailsTab(true)
				break
		}
	}, [path])

	return sc5010?.c5Num && sc5010?.sa1010?.a1Cod && sc5010?.sa1010?.a1Loja ? (
		<>
			<div className="order-menu-tabs-switch">
				<div className="action-bt-back">
					<NavLink to={`/orders`}>
						<span>&lt; Voltar</span>
					</NavLink>
				</div>
				<div className="order-menu-tabs">
					<NavLink
						to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/client/${sc5010.sa1010.a1Cod}/${sc5010.sa1010.a1Loja}`}
					>
						<Button
							size="md"
							variant={partOrderClientTab ? 'outline' : 'outline-dark'}
							title="CLIENTE"
						/>
					</NavLink>
				</div>
				<div className="order-menu-tabs">
					<NavLink to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/details`}>
						<Button
							size="md"
							variant={partOrderDetailsTab ? 'outline' : 'outline-dark'}
							title="DETALHES"
						/>
					</NavLink>
				</div>
				<div className="order-menu-tabs">
					<NavLink
						to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/client/${sc5010.sa1010.a1Cod}/${sc5010.sa1010.a1Loja}/history`}
					>
						<Button
							size="md"
							variant={partOrderHistoryTab ? 'outline' : 'outline-dark'}
							title="HISTÓRICO"
						/>
					</NavLink>
				</div>
				<div className="order-menu-tabs ">
					<NavLink to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/followUp`}>
						<Button
							size="md"
							variant={partOrderFollowUpTab ? 'outline' : 'outline-dark'}
							title="FOLLOW UP"
						/>
					</NavLink>
				</div>
				<div className="order-menu-tabs ">
					<NavLink to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/pendency`}>
						<Button
							size="md"
							variant={partOrderPendencyTab ? 'outline' : 'outline-dark'}
							title="PENDÊNCIAS"
						/>
					</NavLink>
				</div>
				<div className="order-menu-tabs">
					<NavLink to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/checklist`}>
						<Button
							size="md"
							variant={partOrderChecklistTab ? 'outline' : 'outline-dark'}
							title="CHECKLIST"
						/>
					</NavLink>
				</div>
				{partOrderUser && (
					<div className="order-menu-tabs">
						<NavLink to={`/order/${sc5010.c5Filial}/${sc5010.c5Num}/storage`}>
							<Button
								size="md"
								variant={partOrderStorageTab ? 'outline' : 'outline-dark'}
								title="ARMAZÉNS"
							/>
						</NavLink>
					</div>
				)}
			</div>
		</>
	) : (
		<></>
	)
}
export default OrderMenuTabsWeb
