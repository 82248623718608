import { FilterData } from 'models/FilterData'
import { RequisitionError } from 'models/RequisitionError'
import ClientListItem from 'module/orderManagement/models/ClientListItem'

export interface ClientContextType {
	clientList: ClientListItem[]
	isLoading: boolean
	hasError?: RequisitionError
	pageLimit: number
	totalPages: number
	currentPage: number
	itemFilter?: FilterData
	filtersParams?: FilterData
	onUpdateList: () => void
	onPageChange: (page: number) => void
	onLimitChange: (newLimit: number) => void
	onSearchClient: (searchValue: string) => void
	onFilterChange: (newFilter: FilterData) => void
}

export const ClientContextValue = {
	clientList: [],
	hasError: {} as RequisitionError,
	isLoading: {} as boolean,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	itemFilter: {} as FilterData,
	filtersParams: {} as FilterData,
	onUpdateList: () => {},
	onPageChange: () => {},
	onLimitChange: () => {},
	onSearchClient: () => {},
	onFilterChange: () => {}
}
