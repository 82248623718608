import Filter from 'assets/image/Filter.svg'
import financialBlockIcon from 'assets/image/financial-block.svg'
import financialNotBlockIcon from 'assets/image/financial-not-block.svg'
import { ClientContextElement } from 'module/orderManagement/context/ClientContext/ClientContext'
import React, { useContext, useState } from 'react'
import { history } from 'routes/history'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import Search from 'shared/components/Search'
import ClientsFilter from '../ClientsFilter'
import './styles.scss'

const ClientsActions = () => {
	const { onSearchClient } = useContext(ClientContextElement)
	const [clientsFilterShowModal, setClientsFilterShowModal] =
		useState<boolean>(false)

	return (
		<div className="clients-actions">
			<div className="clients-search-and-filter">
				<Search
					variant="light"
					placeholder="Procurar Cliente"
					onChangeValues={onSearchClient}
					removeSpecialCharacters={true}
				/>
				<img
					src={Filter}
					alt="filter icon"
					onClick={() => setClientsFilterShowModal(true)}
				/>
			</div>
			<div className="clients-legends">
				<span>
					<img src={financialBlockIcon} alt="financial block icon" /> Com
					bloqueio financeiro
				</span>
				<span>
					<img src={financialNotBlockIcon} alt="financial not block icon" /> Sem
					bloqueio financeiro
				</span>
			</div>
			<div className="clients-bt-new-order">
				<Button
					size="lg"
					title="NOVO CLIENTE"
					variant="confirmation-solid"
					onClick={() => history.push('/clients/new-client')}
				/>
			</div>
			<Modal
				title="FILTROS"
				showClose={true}
				enableAwayClose={true}
				show={clientsFilterShowModal}
				onClose={() => setClientsFilterShowModal(false)}
			>
				<ClientsFilter onClose={() => setClientsFilterShowModal(false)} />
			</Modal>
		</div>
	)
}
export default ClientsActions
