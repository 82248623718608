import LoginInput from 'models/LoginInput'
import LoadingProgress from 'module/technicalServices/components/Shared/Loadings/LoadingProgress'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { history } from 'routes/history'
import { requestNewPass } from 'services'
import FormValidator from '../../FormFields/FormValidator'
import ResetPassSuccess from '../ResetPassSuccess'
import './styles.scss'

const ResetPassForm: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty }
	} = useForm<LoginInput>()
	const [email, setEmail] = useState<string>('')
	const [isCompleted, setIsCompleted] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const onSubmit = (data: LoginInput) => {
		setIsLoading(true)
		requestNewPass(data)
			.then(() => {
				setEmail(data.email)
				setIsCompleted(false)
				setIsLoading(false)
				history.goBack()
				toast.success(
					'Um link de redefinição de senha foi enviado para seu E-mail!'
				)
			})
			.catch(() => {
				toast.error('Erro ao solicitar restauração de senha!')
				setIsLoading(false)
			})
	}

	const sendAgain = () => {
		if (email) {
			const data = { email: email }
			requestNewPass(data)
		} else {
			setIsCompleted(false)
		}
	}

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div>
					<div className="form-item-content">
						<label>Confirme seu e-mail</label>
						<input
							{...register('email', {
								required: true,
								minLength: 8,
								maxLength: 50
							})}
							placeholder="Informe seu e-mail"
							maxLength={50}
						/>
						<FormValidator
							{...{
								formName: 'email',
								validatorName: 'e-mail',
								errors,
								isDirty
							}}
						/>
					</div>
				</div>
				<div className="reset-pass-form-actions">
					<button className="reset-pass-submit" type="submit">
						CONFIRMAR
					</button>
					<button
						className="reset-pass-cancel"
						type="button"
						onClick={() => {
							history.goBack()
						}}
					>
						CANCELAR
					</button>
				</div>
			</form>
		)
	}

	return (
		<div className="reset-pass-form">
			{formContent()}
			<LoadingProgress
				show={isLoading}
				text="Enviando e-mail de recuperação..."
			/>
			<ResetPassSuccess
				show={!isLoading && isCompleted}
				sendAgain={sendAgain}
			/>
		</div>
	)
}

export default ResetPassForm
