import ResetPassForm from 'module/entryPoint/components/Account/ResetPass'
import PageTitle from 'module/entryPoint/components/PageTitle'
import PraticaImage from 'module/entryPoint/components/PraticaImage'
import SideBar from 'module/entryPoint/components/SideBar'
import React from 'react'
import './styles.scss'

const ResetPass: React.FC = () => {
	return (
		<>
			<PageTitle title="Restaurar senha" single />
			<section className="reset-pass-main-content">
				<SideBar>
					<ResetPassForm></ResetPassForm>
				</SideBar>
				<PraticaImage />
			</section>
		</>
	)
}

export default ResetPass
