import { InputAdornment, TextField } from '@material-ui/core'
import $ from 'jquery'
import Client from 'models/Client'
import Equipments from 'models/Equipment'
import Location from 'models/Locations'
import ConfirmChanges from 'module/technicalServices/components/ConfirmPrevChange'
import EquipModalPrev from 'module/technicalServices/components/EquipsModalPreventive'
import LocalModalPrev from 'module/technicalServices/components/LocalModalPreventive'
import AdsForm from 'module/technicalServices/components/NewOrder/AddressForm'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdSearch } from 'react-icons/md'
import { createLocation, removePreventive, updatePreventive } from 'services'
import SuccessMsg from 'shared/SuccessMsg'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { clearNumericField } from 'utils/Pipe'
import { IChangeAddressForm } from '../ChangeAddress'
import './style.scss'
import {
	Abas,
	City,
	ContractContent,
	EquipNameCol,
	EquipmentsContract,
	Header,
	HeaderTitle,
	LocalName,
	LocationCol,
	LocationContent,
	Name,
	Neighborhood,
	PreventiveCol,
	SearchEquip,
	SerialNumCol,
	State,
	Street,
	TabelaEquips,
	TabelaEquipsContent,
	TitleAddForm,
	ZipCode
} from './styles'

const TAB = {
	CONTRACT: 0,
	EQUIPS: 1,
	LOCATIONS: 2
}

interface Props {
	client: Client
	hasUpdated: () => void
}

const ModalEditContract: React.FC<Props> = ({ client, hasUpdated }) => {
	const [inContract, setInContract] = useState<number>()
	const [locations, setLocations] = useState<Location[]>()
	const [useCount, setUseCount] = useState<boolean>(true)
	const [update, setUpdate] = useState<boolean>(false)
	const [hasRemoved, setHasRemoved] = useState<boolean>(false)
	const [finishedUp, setFinishedUp] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [tabSelected, setTabSelected] = useState<number>(TAB.CONTRACT)
	const [status, setStatus] = useState<string>('')
	const [searchTerm, setSearchTerm] = useState<any>('')
	const [cleanDate, setCleanDate] = useState<string>('')
	const [inputDate, setInputDate] = useState<string>('')
	const [message, setMessage] = useState<string>('')
	const [newDate, setNewDate] = useState<Date>()
	const [format, setFormat] = useState<boolean>(true)

	const [equipments, setEquipments] = useState<Equipments[]>()
	const [clientEquips, setClientEquips] = useState<Equipments[]>()

	const form = useForm<IChangeAddressForm>()
	const {
		handleSubmit,
		register,
		formState: { errors },
		reset
	} = form

	useEffect(() => {
		if (message) {
			$('.success-message').toggle()
		}
	}, [message])

	const countEquipNumber = (array: Equipments[]) => {
		let count = 0
		array.map((item) => {
			if (item.preventive == 1) {
				count++
			}
		})

		setInContract(count)
	}

	if (client && useCount == true) {
		setUseCount(false)
		countEquipNumber(client.equipments)

		setLocations(client.locations)
		setEquipments(client.equipments)
		setClientEquips(client.equipments)
	}

	const formatDate = (end_date: number) => {
		const toString = end_date?.toString()
		const arrayData = toString?.split('-')

		setCleanDate(
			arrayData[2].slice(0, 2) + '/' + arrayData[1] + '/' + arrayData[0]
		)
		setInputDate(
			arrayData[0] + '-' + arrayData[1] + '-' + arrayData[2].slice(0, 2)
		)
	}

	if (format && client.preventive) {
		formatDate(client.preventive.end_date)
		setFormat(false)
	}

	const updateCount = (value: number, previous: number) => {
		let count = inContract
		if (count) {
			if (value == 1 && previous == 0) {
				count++
			} else if (value == 0 && previous == 1) {
				count--
			}
		}
		setInContract(count)
	}

	const updateEquip = (
		preventive: number,
		location: number,
		serial: string
	) => {
		if (equipments && equipments?.length) {
			const equips = [...equipments]
			equips.forEach((e) => {
				if (e.serial_number === serial) {
					e.preventive = preventive
					e.location_id = location
				}
			})

			setEquipments(equips)
		}

		if (clientEquips && clientEquips?.length) {
			const cliEquips = [...clientEquips]
			cliEquips.forEach((e) => {
				if (e.serial_number === serial) {
					e.preventive = preventive
					e.location_id = location
				}
			})

			setClientEquips(cliEquips)
		}
	}

	const updateLocations = (index: number) => {
		const locals = locations

		if (locals) {
			locals.splice(index, 1)
			setLocations(locals)
		}
	}

	const submitNewLocation = (data: IChangeAddressForm) => {
		const newLocation: any = data
		newLocation.client_code = client?.id
		newLocation.zip_code = clearNumericField(data.zip_code)
		newLocation.street = `${data.street}, ${data.number}`

		$('.confirmAdd').toggle()
		createLocation(newLocation)
			.then((resp) => {
				const local = locations
				if (local) {
					local.push(resp.data.locationCreated)
					setLocations(local)
					reset({
						name: '',
						zip_code: '',
						city: '',
						state_sigla: '',
						neighborhood: '',
						street: '',
						number: '',
						complement: ''
					})
					if (update == false) {
						setUpdate(true)
					} else {
						setUpdate(false)
					}
					setUpdate(true)
				}

				setStatus('true')
				setFinishedUp(true)
			})
			.catch((err) => {
				setStatus('false')
				setFinishedUp(true)
			})
	}

	const onClose = () => {
		setHasRemoved(false)
		setUpdate(false)
		setLoading(false)
		hasUpdated()
		if (searchTerm) {
			$(`.searchEquip${client?.cpfCnpj}`)
				.find('input')
				.val('')
			setDel()
		}
		$(`.mod${client?.cpfCnpj}`).toggle()
	}

	let delay = false
	let searching = false

	const setDel = async () => {
		delay = true

		if (searching == false) {
			searching = true
			setSear()
		}
	}

	const setSear = () => {
		if (delay == false && searching == true) {
			searching = false
			setSearchTerm(
				$(`.searchEquip${client?.cpfCnpj}`)
					.find('input')
					.val()
			)
			searchEquipFunction(
				$(`.searchEquip${client?.cpfCnpj}`)
					.find('input')
					.val()
			)
		} else if (delay == true) {
			delay = false
			setTimeout(() => {
				delay
				setSear()
			}, 600)
		}
	}

	const searchEquipFunction = (term: any) => {
		if (term && term?.length) {
			const arrayEquip: Equipments[] = []
			if (equipments?.length) {
				equipments.map((item) => {
					if (
						item?.name?.toLowerCase()?.includes(term?.toLowerCase()) ||
						item?.serial_number?.toLowerCase()?.includes(term?.toLowerCase())
					) {
						arrayEquip.push(item)
					}
				})
				setEquipments(arrayEquip)
			}
		} else {
			setEquipments(clientEquips)
		}
	}

	const onDateChange = (newDate: string) => {
		setNewDate(new Date(newDate))
	}

	const onRemoveContract = () => {
		if (client.preventive) {
			setLoading(true)
			removePreventive(client.id)
				.then((res) => {
					client.preventive = res.data
					setLoading(false)
					setHasRemoved(true)
					setMessage('Contrato removido com sucesso!')
				})
				.catch(() => {
					setLoading(false)
					setHasRemoved(false)
					setMessage('Houve um erro ao remover contrato!')
				})
		} else {
			setMessage('Houve um erro ao remover contrato!')
		}
	}

	const submitNewContract = () => {
		if (newDate && client.preventive) {
			setLoading(true)
			const payload = {
				clientId: client.id,
				endDate: newDate
			}
			updatePreventive(payload)
				.then((res) => {
					const newPrevContract = res.data.prevUpdated || res.data.preventive
					formatDate(newPrevContract.end_date)
					setFormat(false)
					setLoading(false)
					setNewDate(undefined)
					setMessage('Contrato atualizado com sucesso!')
				})
				.catch(() => {
					setLoading(false)
					setNewDate(undefined)
					setMessage('Houve um erro ao atualizar contrato!')
				})
		} else {
			setMessage('Houve um erro ao atualizar contrato!')
		}
	}

	const onCloseModal = () => {
		setMessage('')
		if (hasRemoved) {
			hasUpdated()
			onClose()
		}
	}

	const equipsContractContent = () => {
		return (
			<div className={tabSelected === TAB.EQUIPS ? `show` : `hide`}>
				<ContractContent>
					<EquipmentsContract>
						{inContract} equipamentos de {clientEquips?.length} estão no
						contrato
					</EquipmentsContract>
					<TextField
						id="search"
						placeholder="Buscar equipamento"
						className={`searchEquip${client?.cpfCnpj}`}
						InputProps={{
							endAdornment: (
								<InputAdornment position="start">
									<MdSearch />
								</InputAdornment>
							)
						}}
						onChange={() => {
							setDel()
						}}
					/>
					<TabelaEquips>
						<Header>
							<EquipNameCol>Equipments</EquipNameCol>
							<SerialNumCol>N/S</SerialNumCol>
							<PreventiveCol>Preventiva</PreventiveCol>
							<LocationCol>Localização</LocationCol>
						</Header>
						<TabelaEquipsContent>
							{equipments ? (
								equipments.map((item, index) => (
									<EquipModalPrev
										key={index}
										name={item.name}
										serial={item.serial_number}
										preventive={item.preventive}
										location={item.location_id}
										locations={locations}
										updateCount={updateCount}
										updateEquip={updateEquip}
									/>
								))
							) : (
								<></>
							)}
						</TabelaEquipsContent>
					</TabelaEquips>
				</ContractContent>
			</div>
		)
	}

	const locationContractContent = () => {
		return (
			<div className={tabSelected === TAB.LOCATIONS ? `show` : `hide`}>
				<LocationContent>
					<SearchEquip className={`addBtn${client?.cpfCnpj}`}>
						<a
							onClick={() => {
								$(`.addBtn${client?.cpfCnpj}`).css('height', '0px')
								$(`.equips${client?.cpfCnpj}`).css('height', '0px')
								$(`.addBtn${client?.cpfCnpj}`).css('left', '-2000px')
								$(`.equips${client?.cpfCnpj}`).css('left', '-2000px')
								$(`.addLocal${client?.cpfCnpj}`).css('left', '0px')
							}}
						>
							Adicionar localização
						</a>
					</SearchEquip>

					<TabelaEquips className={`equips${client?.cpfCnpj}`}>
						<Header>
							<LocalName>Nome</LocalName>
							<ZipCode>CEP</ZipCode>
							<Street>Rua</Street>
							<Neighborhood>Bairro</Neighborhood>
							<City>Cidade</City>
							<State>Estado</State>
						</Header>

						{locations ? (
							update == false ? (
								<TabelaEquipsContent>
									{locations.map((item, index) => (
										<LocalModalPrev
											key={index}
											updateLocations={updateLocations}
											index={index}
											client={client}
											id={item.id}
											name={item.name}
											zip_code={item.zip_code}
											street={item.street}
											neighborhood={item.neighborhood}
											city={item.city}
											state={item.state_sigla}
											original={item.original}
										/>
									))}
								</TabelaEquipsContent>
							) : (
								<TabelaEquipsContent>
									{locations.map((item, index) => (
										<LocalModalPrev
											key={index}
											updateLocations={updateLocations}
											index={index}
											client={client}
											id={item.id}
											name={item.name}
											zip_code={item.zip_code}
											street={item.street}
											neighborhood={item.neighborhood}
											city={item.city}
											state={item.state_sigla}
											original={item.original}
										/>
									))}
								</TabelaEquipsContent>
							)
						) : (
							''
						)}
					</TabelaEquips>
					<div
						className={`addLocal${client?.cpfCnpj}`}
						style={{
							position: 'relative',
							left: '2000px',
							transition: 'left 500ms ease',
							height: '420px'
						}}
					>
						<TitleAddForm>Adicionar localização</TitleAddForm>
						<div className="name-location">
							<header style={{ justifyContent: 'left' }}>Nome</header>
							<Input
								name="name"
								variant="light"
								placeholder="Nome"
								innerRef={{
									...register('name', {
										required: {
											value: true,
											message: 'Campo nome é obrigatório!'
										}
									})
								}}
								errors={errors.name}
							/>
						</div>
						<AdsForm form={form} />
						<div className="client-content-footer">
							<Button
								style={{ width: '108px' }}
								variant="confirmation-gradient"
								size="lg"
								title="Salvar"
								onClick={handleSubmit(submitNewLocation)}
							/>
							<Button
								style={{ width: '108px' }}
								variant="cancellation-gradient"
								size="lg"
								title="Cancelar"
								color="secondary"
								onClick={() => {
									$(`.addBtn${client?.cpfCnpj}`).css('left', '0px')
									$(`.equips${client?.cpfCnpj}`).css('left', '0px')
									$(`.addLocal${client?.cpfCnpj}`).css('left', '2000px')
									$(`.addBtn${client?.cpfCnpj}`).css('height', 'auto')
									$(`.equips${client?.cpfCnpj}`).css('height', 'auto')
									reset({
										name: '',
										zip_code: '',
										city: '',
										state_sigla: '',
										neighborhood: '',
										street: '',
										number: '',
										complement: ''
									})
								}}
							/>
						</div>
					</div>
				</LocationContent>
			</div>
		)
	}

	const clientContractContent = () => {
		return (
			<div className={tabSelected === TAB.CONTRACT ? `show` : `hide`}>
				<div className={`locationCont${client?.cpfCnpj}`}>
					<div className="client-content">
						<div className="cols-2">
							<div className="client-content-item">
								<label>Nome Fantasia: </label>
								<span>{client.nome_fantasia}</span>
							</div>
							<div className="client-content-item">
								<label>Razão Social: </label>
								<span>{client.razao_social}</span>
							</div>
						</div>
						<div className="cols-2">
							<div className="client-content-item">
								<label>CNPJ: </label>
								<span>
									{client.cpfCnpj?.replace(
										/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
										'$1.$2.$3/$4-$5'
									)}
								</span>
							</div>
							<div className="client-content-item">
								<label>Fim do Contrato: </label>
								<span>{cleanDate}</span>
							</div>
						</div>
						<div className="cols-2">
							<div className="client-content-item">
								<label>Total Equipamentos: </label>
								<span>{clientEquips?.length}</span>
							</div>
							<div className="client-content-item">
								<label>Equipamentos no contrato: </label>
								<span>{inContract}</span>
							</div>
						</div>
						<div className="remove-contract">
							<span onClick={onRemoveContract}>Excluir Contrato</span>
						</div>
					</div>
					{inputDate && (
						<div className="contract-change-date">
							<div className="form-field">
								<label>Alterar término de contrato:</label>
								<TextField
									id="date"
									type="date"
									defaultValue={inputDate}
									InputLabelProps={{
										shrink: true
									}}
									onChange={(e) => onDateChange(e.target.value)}
								/>
							</div>
						</div>
					)}
					<div className="client-content-footer">
						<Button
							style={{ width: '108px' }}
							variant="confirmation-gradient"
							size="lg"
							title="Confirmar"
							onClick={submitNewContract}
							disabled={!newDate}
						/>
						<Button
							style={{ width: '108px' }}
							variant="cancellation-gradient"
							size="lg"
							title="Cancelar"
							onClick={onClose}
						/>
					</div>
				</div>
			</div>
		)
	}

	return (
		<Modal
			title="Editar contrato"
			onClose={onClose}
			show={true}
			showClose={true}
		>
			<div className="edit-preventive-contract-contain">
				<HeaderTitle>
					<Name>{client?.nome_fantasia}</Name>
				</HeaderTitle>
				<Abas>
					<div
						className={`tab-item ${
							tabSelected === TAB.CONTRACT ? 'selected' : ''
						}`}
						onClick={() => setTabSelected(TAB.CONTRACT)}
					>
						Contrato
					</div>
					<div
						className={`tab-item ${
							tabSelected === TAB.EQUIPS ? 'selected' : ''
						}`}
						onClick={() => setTabSelected(TAB.EQUIPS)}
					>
						Equipamentos
					</div>
					<div
						className={`tab-item ${
							tabSelected === TAB.LOCATIONS ? 'selected' : ''
						}`}
						onClick={() => setTabSelected(TAB.LOCATIONS)}
					>
						Localizações
					</div>
				</Abas>
				{clientContractContent()}
				{equipsContractContent()}
				{locationContractContent()}
				<div className={`modal confirmAdd`}>
					{finishedUp ? (
						<ConfirmChanges
							changeType="added"
							status={status}
							id={0}
							client={client?.cpfCnpj}
						/>
					) : (
						<>
							<div className="modal loading" style={{ display: 'block' }}>
								<div className="loading-content">
									<LoadingProgress show={!finishedUp} text="Carregando..." />
								</div>
							</div>
						</>
					)}
				</div>

				{!loading ? (
					<></>
				) : (
					<>
						<div className="modal loading" style={{ display: 'block' }}>
							<div className="loading-content">
								{' '}
								<LoadingProgress show={loading} text="Carregando..." />
							</div>
						</div>
					</>
				)}

				<div className="modal success-message" style={{ display: 'none' }}>
					<SuccessMsg
						result={true}
						msg={message}
						resetStatus={() => {
							onCloseModal()
						}}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default ModalEditContract
