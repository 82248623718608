import React from 'react'
import { history } from '../../../routes/history'
import MobileNavigator from '../MobileNavigator'

import './styles.scss'

interface Params {
	title?: string
	subTitle?: string
	defaultTitle?: string
	action?: () => void
}

const MobileHeader: React.FC<Params> = ({
	title,
	subTitle,
	defaultTitle,
	action
}) => {
	const pageTitle = () => {
		const titleContent = title ? <h4>{title}</h4> : <h4>{defaultTitle}</h4>

		const subTitleContent = subTitle ? <h5>{subTitle}</h5> : <></>

		return (
			<div className="page-title-content">
				{titleContent}
				{subTitleContent}
			</div>
		)
	}

	const navigatorAction = () => {
		if (action) {
			action()
		} else {
			history.goBack()
		}
	}

	return (
		<section className="mobile-page-header-content">
			<MobileNavigator action={navigatorAction} />
			{pageTitle()}
		</section>
	)
}

export default MobileHeader
