import produce from 'immer'

const INITIAL_STATE = {
	usersPrp: {
		listUsersParams: {
			pageLimit: 19,
			totalPages: null,
			currentPage: 1,
			searchValue: '',
			itemFilter: {
				status: true
			}
		}
	},
	logs: {
		listLogsParams: {
			pageLimit: 19,
			totalPages: null,
			currentPage: 1,
			searchValue: '',
			itemFilter: {
				status: true
			}
		}
	}
}

const adminSystem = (state = INITIAL_STATE, action) => {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@auth/SIGN_OUT':
				draft.usersPrp.listUsersParams.pageLimit = 19
				draft.usersPrp.listUsersParams.totalPages = null
				draft.usersPrp.listUsersParams.currentPage = 1
				draft.usersPrp.listUsersParams.searchValue = ''
				draft.usersPrp.listUsersParams.itemFilter.status = true
				draft.logs.listLogsParams.pageLimit = 19
				draft.logs.listLogsParams.totalPages = null
				draft.logs.listLogsParams.currentPage = 1
				draft.logs.listLogsParams.searchValue = ''
				draft.logs.listLogsParams.itemFilter.status = true
				break
			case '@adminSystem/SET_USERS_PRP_LIST_PARAMS':
				draft.usersPrp.listUsersParams = action.payload.listUsersParams
				break
			case '@adminSystem/SET_LOGS_LIST_PARAMS':
				draft.logs.listLogsParams = action.payload.listLogsParams
				break
			default:
				return state
		}
	})
}

export default adminSystem
