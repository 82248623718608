import ClientMenuTabs from 'module/orderManagement/components/ClientMenuTabs'
import TabTitles from 'module/orderManagement/components/TabTitles'
import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import EmptyList from 'shared/components/EmptyList'
import { store } from 'store'
import { CompareClientLogModal } from './CompareClientLogModal'
import './styles.scss'

type ClientUpdateLogProps = RouteComponentProps & {
	match: {
		params: {
			clientCode: string
			clientStoreCode: string
		}
	}
}

export default function ClientUpdateLogs({ match }: ClientUpdateLogProps) {
	const { roles } = store.getState().user
	const { sa1010, clientPrp, onSelectClient } = useContext(
		ClientDetailsContextElement
	)
	const { clientCode, clientStoreCode } = match.params
	const [selectedLog, setSelectedLog] = useState<any>()
	const [lastUpdateSuccess, setLastUpdateSuccess] = useState<any>()
	const digitalEndeavourManager = roles.includes('digitalEndeavourManager')
	const orderManager = roles.includes('orderManager')

	useEffect(() => {
		if (!orderManager && !digitalEndeavourManager) {
			window.location.replace(`/client/${clientCode}/${clientStoreCode}`)
		}

		if (sa1010?.a1Cod !== clientCode) {
			onSelectClient({ a1Cod: clientCode, a1Loja: clientStoreCode })
		}
	}, [clientCode, clientStoreCode])

	useEffect(() => {
		if (selectedLog && clientPrp) {
			const successfulUpdates = clientPrp.logs.filter(
				(d) => d.status && d.id < selectedLog.id
			)
			setLastUpdateSuccess(successfulUpdates[0])
		}
	}, [selectedLog])

	return (
		<>
			<ClientMenuTabs path={match.path} />
			<TabTitles />

			{clientPrp?.logs?.length ? (
				<div className="client-update-log-timeline-guide-main-contain">
					{clientPrp?.logs?.map((log) => (
						<div
							key={log?.id}
							className="event"
							onClick={() => {
								setSelectedLog(log)
							}}
						>
							<div className="map">
								<div className="line" />
								<div className="ball" />
							</div>
							<div className="info">
								<span className="event-date">
									{new Date(log?.createdAt).toLocaleString()}
								</span>
								<span className="event-title">Cliente Alterado</span>
								<span className="event-content">
									{log?.user?.name} {log?.user?.surname}
								</span>
							</div>
						</div>
					))}
				</div>
			) : (
				<EmptyList show text="Logs do cliente não encontrado" />
			)}
			{selectedLog && (
				<CompareClientLogModal
					compare={[selectedLog, lastUpdateSuccess]}
					onClose={() => {
						setSelectedLog(null)
						setLastUpdateSuccess(null)
					}}
				/>
			)}
		</>
	)
}
