export const getOsChangePage = (pageNumber) => {
	return {
		type: '@serviceOrders/GOT_OS_CHANGE_PAGE',
		payload: {
			pageNumber
		}
	}
}

export const setFilterSelectedServiceOrders = (filterSelected) => {
	return {
		type: '@serviceOrders/SET_FILTER_SELECTED_SERVICE_ORDERS',
		payload: {
			filterSelected
		}
	}
}

export const setUrlOrders = (url) => {
	return {
		type: '@serviceOrders/SET_URL_ORDERS',
		payload: {
			url
		}
	}
}

export const changeSearchTerm = (term) => {
	return {
		type: '@serviceOrders/SET_SEARCH_TERM',
		payload: {
			term
		}
	}
}
export const changeStatusChecked = (statusChecked) => {
	return {
		type: '@serviceOrders/SET_STATUS_CHECKED',
		payload: {
			statusChecked
		}
	}
}
export const changeStateChecked = (stateChecked) => {
	return {
		type: '@serviceOrders/SET_STATE_CHECKED',
		payload: {
			stateChecked
		}
	}
}
export const changeTypeOSChecked = (typeOSChecked) => {
	return {
		type: '@serviceOrders/SET_TYPE_OS_CHECKED',
		payload: {
			typeOSChecked
		}
	}
}
export const setDefaultFilters = () => {
	return {
		type: '@serviceOrders/SET_DEFAULT_FILTERS'
	}
}
