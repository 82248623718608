import React from 'react'
import './styles.scss'

interface AlertIconProps {
	width: string
	height: string
	fill?: string
}

const AlertIcon = ({ fill, width, height }: AlertIconProps) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="14 7 23  27"
		>
			<g transform="translate(-1603 -7423)" fill={fill}>
				<rect
					className="a"
					width="50"
					height="40"
					transform="translate(1603 7423)"
				/>
				<path
					d="M25.563,13.063a12.5,12.5,0,1,1-12.5-12.5A12.5,12.5,0,0,1,25.563,13.063Zm-12.5,2.52A2.319,2.319,0,1,0,15.381,17.9,2.319,2.319,0,0,0,13.063,15.583Zm-2.2-8.334.374,6.855a.6.6,0,0,0,.6.572h2.447a.6.6,0,0,0,.6-.572l.374-6.855a.6.6,0,0,0-.6-.638H11.465A.6.6,0,0,0,10.861,7.249Z"
					transform="translate(1615.438 7430.438)"
				/>
			</g>
		</svg>
	)
}

export default AlertIcon
