import React from 'react'
import { history } from 'routes/history'
import { RouteList } from 'utils/Constants'

import './styles.scss'

interface Props {
	show: boolean
	sendAgain: () => void
}

const ResetPassSuccess: React.FC<Props> = ({ show, sendAgain }) => {
	const mainContent = () => {
		return (
			<div className="reset-pass-completed">
				<h4>
					UM E-MAIL DE RECUPERAÇÃO DE SENHA FOI ENVIADO PARA O ENDEREÇO
					INFORMADO.
				</h4>
				<span
					onClick={() => {
						sendAgain()
					}}
				>
					Reenviar
				</span>
				<span
					onClick={() => {
						history.replace(RouteList.HOME)
					}}
				>
					Voltar
				</span>
			</div>
		)
	}

	return show ? mainContent() : <></>
}

export default ResetPassSuccess
