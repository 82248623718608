import React from 'react'
import { StepItem, TransferSteps } from 'utils/Constants'

interface Props {
	isStart: boolean
	isFinish: boolean
	position: number
	actualStep: number
	stepItem: StepItem
	onStepClick?: (step: TransferSteps) => void
}

const StepProgressItem: React.FC<Props> = ({
	isStart,
	isFinish,
	position,
	actualStep,
	stepItem,
	onStepClick
}) => {
	const onClick = () => {
		if (onStepClick) {
			onStepClick(stepItem.step)
		}
	}

	const contentClass = () => {
		const mainClass = 'step-progress-item-content'
		const startClass = isStart ? 'start-step-progress-item' : ''
		const finishClass = isFinish ? 'finish-step-progress-item' : ''
		const positionPassed =
			position <= actualStep ? 'passed-step-progress-item' : ''
		const stopPosition =
			position === actualStep ? 'stoped-step-progress-item' : ''
		const stepReturn = onStepClick ? 'return-step-progress-item' : ''
		return `${mainClass} ${startClass} ${finishClass} ${positionPassed} ${stopPosition} ${stepReturn}`
	}

	const handleProgressLine = () => {
		return !isFinish ? <div className="progress-line"></div> : <></>
	}

	return (
		<div className={contentClass()} onClick={onClick}>
			{handleProgressLine()}
			<div className="progress-step">
				<div className="progress-dot"></div>
				<div className="progress-label">
					<span>{stepItem.label}</span>
				</div>
			</div>
		</div>
	)
}

export default StepProgressItem
