import React from 'react'

interface Props extends React.SVGProps<SVGSVGElement> {}

export const HelpSvg: React.FC<Props> = () => {
	return (
		<>
			<svg
				width="100%"
				height="100%"
				viewBox="0 0 30 30"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M15 30C12.0333 30 9.13319 29.1203 6.66645 27.472C4.19972 25.8238 2.27713 23.4811 1.14181 20.7403C0.00649917 17.9994 -0.290551 14.9834 0.288227 12.0736C0.867006 9.16393 2.29562 6.49119 4.3934 4.3934C6.49119 2.29562 9.16393 0.867006 12.0736 0.288227C14.9834 -0.290551 17.9994 0.00649917 20.7403 1.14181C23.4811 2.27713 25.8238 4.19972 27.472 6.66645C29.1203 9.13319 30 12.0333 30 15C29.9952 18.9768 28.4134 22.7893 25.6013 25.6013C22.7893 28.4134 18.9768 29.9952 15 30ZM15 1.65841C12.3613 1.65841 9.78182 2.44088 7.58781 3.90687C5.39379 5.37286 3.68377 7.45654 2.67397 9.8944C1.66418 12.3323 1.39997 15.0148 1.91476 17.6028C2.42955 20.1908 3.70021 22.5681 5.56607 24.4339C7.43193 26.2998 9.80917 27.5705 12.3972 28.0852C14.9852 28.6 17.6678 28.3358 20.1056 27.326C22.5435 26.3162 24.6271 24.6062 26.0931 22.4122C27.5591 20.2182 28.3416 17.6387 28.3416 15C28.3375 11.4629 26.9305 8.07177 24.4294 5.57063C21.9282 3.06949 18.5371 1.66253 15 1.65841Z"
					fill="black"
				/>
				<path
					d="M13.5312 23.748V20.808H16.4688V23.748H13.5312ZM13.5456 19.3392C13.5456 14.6532 17.91 14.9856 17.91 12.0756C17.9088 11.3034 17.602 10.5631 17.0568 10.0163C16.5116 9.46953 15.7722 9.16067 15 9.15719C14.2327 9.16831 13.4999 9.47765 12.9567 10.0197C12.4136 10.5617 12.1027 11.2939 12.09 12.0612H9.18003C9.18082 10.5176 9.79476 9.0376 10.8868 7.94671C11.4275 7.40655 12.0693 6.97818 12.7756 6.68606C13.4818 6.39394 14.2387 6.24379 15.003 6.24419C15.7673 6.24458 16.5241 6.39551 17.23 6.68836C17.936 6.9812 18.5773 7.41023 19.1175 7.95095C19.6577 8.49166 20.086 9.13348 20.3782 9.83974C20.6703 10.546 20.8204 11.3029 20.82 12.0672C20.82 15.7068 16.4544 16.1196 16.4544 19.338L13.5456 19.3392Z"
					fill="black"
				/>
			</svg>
		</>
	)
}
