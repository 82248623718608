import styled from 'styled-components'

interface Props {
	edited: boolean
}

interface ButtonProps {
	enabled: boolean
}

export const Container = styled.div`
	background-color: #dbdbdb;
	margin-left: auto;
	margin-right: auto;
	max-width: 940px;
`

export const PanelUser = styled.div`
	margin-top: 30px;
`

export const Title = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 40px;
	font-weight: 600;
`

export const TitleText = styled.div`
	margin-left: 10px;
	line-height: 40px;
`

export const Info = styled.div`
	margin-top: 75px;
`

export const Contact = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 124px;
	padding-right: 20px;
	padding-left: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
`

export const ContactPanel = styled.div`
	display: flex;
	width: 50%;
	margin-bottom: 16px;
	-webkit-box-align: center;
	-webkit-align-items: center;
`

export const ContactTitle = styled.div`
	padding-right: 5px;
	margin-right: 20px;
	font-size: 20px;
	line-height: 20px;
	font-weight: 700;
	text-align: left;
`

export const Detail = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 20px;
	margin-bottom: 10px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
`

export const Description = styled.div`
	width: 160px;
	padding-right: 5px;

	line-height: 20px;
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	text-align: left;
`

export const DescriptionInfo = styled.div`
	width: 100%;
`

export const EditButton = styled.button<ButtonProps>`
	display: ${(p) => (p.enabled ? 'block' : 'none')};
	color: #1851a1;
	font-weight: 500;
	border: none !important;
	outline: none !important;
	border-width: 0px;
	border-style: none;
	border-color: transparent;
	border-image: none;
	background-color: transparent;
	cursor: default;
`

export const SaveButton = styled.button<ButtonProps>`
	display: ${(p) => (p.enabled ? 'block' : 'none')};
	color: #1851a1;
	font-weight: 500;
	border: none !important;
	outline: none !important;
	border-width: 0px;
	border-style: none;
	border-color: transparent;
	border-image: none;
	background-color: transparent;
	cursor: default;
`

export const Input = styled.input<Props>`
	background: ${(p) => (p.edited ? '#fff' : 'none')};
	border: ${(p) => (p.edited ? '1' : 'none')};
	padding-right: 15px;
	padding-left: 15px;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.45);
	border-radius: 4px;
	line-height: 21px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
	font-size: 20px;
	border: none;
	outline: none;
`

export const FixedInfo = styled.div`
	padding: 0px 15px;
	border-style: none;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.45);
	border-radius: 4px;
	line-height: 21px;
	font-style: normal;
	font-weight: 500;
	text-align: left;
	font-size: 20px;
	border: none;
	outline: none;
`
