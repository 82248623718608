import { InputAdornment, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'
import { MdSearch } from 'react-icons/md'
import { toast } from 'react-toastify'
import { getTechListForPreventive } from 'services'

type TechInfo = {
	name: string
	code_prp: string
}

interface Params {
	register: any
	onTechSelected: (tech: TechInfo) => void
}

const SearchTech: React.FC<Params> = ({ register, onTechSelected }) => {
	const [isLoading, setIsLoading] = useState(false)
	const [techList, setTechList] = useState([])

	let timer = 0
	const onSearch = async (value: string) => {
		clearTimeout(timer)
		timer = window.setTimeout(() => {
			onSearchTech(value)
			clearTimeout(timer)
		}, 600)
	}

	const onSearchTech = (searchValue: string) => {
		setIsLoading(true)
		getTechListForPreventive(searchValue)
			.then((resp) => {
				setIsLoading(false)
				setTechList(
					resp.data.techList.filter(
						(e: any) => e.technician_profile !== 'Field_chef_autonomous'
					)
				)
			})
			.catch((err) => {
				setIsLoading(false)
				toast.error('Houve um erro ao buscar técnicos!')
			})
	}

	const inputProps = (params: any) => {
		const props = { ...params.InputProps }
		props.endAdornment = (
			<InputAdornment position="end">
				<MdSearch />
			</InputAdornment>
		)

		return props
	}

	const mainContent = () => {
		return (
			<Autocomplete
				id="tech-search"
				freeSolo
				disableClearable
				options={techList}
				getOptionLabel={(tech: any) => `${tech?.name} - ${tech?.code_prp}`}
				onChange={(e, value) => onTechSelected(value)}
				className="tech-search-field"
				loading={isLoading}
				loadingText="Buscando técnicos..."
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder="Procurar técnico"
						margin="normal"
						variant="outlined"
						{...register('tech', { required: true })}
						onChange={(e: any) => {
							onSearch(e.target.value)
						}}
						InputProps={{ ...inputProps(params), type: 'search' }}
					/>
				)}
			/>
		)
	}

	return mainContent()
}

export default SearchTech
