import produce from 'immer'

const INITIAL_STATE = {
	clientsMenu: {
		listClientParams: {
			pageLimit: 19,
			totalPages: null,
			currentPage: 1,
			searchValue: '',
			itemFilter: {
				finalDate: null,
				initialDate: null,
				federatedState: [],
				salesPersonCode: [],
				financialStatus: []
			}
		},
		filterSelected: {
			id: null,
			financialStatus: [],
			federatedState: [],
			salesPersonCode: []
		}
	},

	ordersMenu: {
		listOrderParams: {
			pageLimit: 19,
			searchValue: '',
			totalPages: null,
			currentPage: 1,
			itemFilter: {
				orderStatus: [],
				finalDate: null,
				initialDate: null,
				orderCategory: [],
				orderBranch: ['01'],
				pendencyStatus: [],
				federatedState: [],
				salesPersonCode: []
			}
		},
		filterSelected: {
			id: null,
			orderStatus: [],
			orderCategory: [],
			orderBranch: [],
			pendencyStatus: undefined,
			federatedState: [],
			salesPersonCode: [],
			pendencyCode: [],
			pendencyType: [],
			pendencyIssueResolverTeam: [],
			pendencyIssueResolver: undefined
		}
	},

	listOrderHistoryParams: {
		pageLimit: 17,
		searchValue: '',
		itemFilter: null,
		totalPages: null,
		currentPage: null
	}
}

const orderManagement = (state = INITIAL_STATE, action) => {
	return produce(state, (draft) => {
		switch (action.type) {
			case '@auth/SIGN_OUT': {
				draft.clientsMenu.listClientParams.pageLimit = 19
				draft.clientsMenu.listClientParams.totalPages = null
				draft.clientsMenu.listClientParams.currentPage = 1
				draft.clientsMenu.listClientParams.searchValue = ''
				draft.clientsMenu.listClientParams.itemFilter.finalDate = null
				draft.clientsMenu.listClientParams.itemFilter.initialDate = null
				draft.clientsMenu.listClientParams.itemFilter.federatedState = []
				draft.clientsMenu.listClientParams.itemFilter.salesPersonCode = []
				draft.clientsMenu.listClientParams.itemFilter.financialStatus = []
				draft.clientsMenu.filterSelected.id = null
				draft.clientsMenu.filterSelected.financialStatus = []
				draft.clientsMenu.filterSelected.federatedState = []
				draft.clientsMenu.filterSelected.salesPersonCode = []
				draft.ordersMenu.listOrderParams.pageLimit = 19
				draft.ordersMenu.listOrderParams.searchValue = ''
				draft.ordersMenu.listOrderParams.totalPages = null
				draft.ordersMenu.listOrderParams.currentPage = 1
				draft.ordersMenu.listOrderParams.itemFilter.orderStatus = []
				draft.ordersMenu.listOrderParams.itemFilter.finalDate = null
				draft.ordersMenu.listOrderParams.itemFilter.initialDate = null
				draft.ordersMenu.listOrderParams.itemFilter.orderCategory = []
				draft.ordersMenu.listOrderParams.itemFilter.orderBranch = ['01']
				draft.ordersMenu.listOrderParams.itemFilter.pendencyStatus = []
				draft.ordersMenu.listOrderParams.itemFilter.federatedState = []
				draft.ordersMenu.listOrderParams.itemFilter.salesPersonCode = []
				draft.ordersMenu.filterSelected.id = null
				draft.ordersMenu.filterSelected.orderStatus = []
				draft.ordersMenu.filterSelected.orderCategory = []
				draft.ordersMenu.filterSelected.orderBranch = []
				draft.ordersMenu.filterSelected.pendencyStatus = undefined
				draft.ordersMenu.filterSelected.federatedState = []
				draft.ordersMenu.filterSelected.salesPersonCode = []
				draft.ordersMenu.filterSelected.pendencyCode = []
				draft.ordersMenu.filterSelected.pendencyType = []
				draft.ordersMenu.filterSelected.pendencyIssueResolverTeam = []
				draft.ordersMenu.filterSelected.pendencyIssueResolver = undefined
				draft.listOrderHistoryParams.pageLimit = 17
				draft.listOrderHistoryParams.searchValue = ''
				draft.listOrderHistoryParams.itemFilter = null
				draft.listOrderHistoryParams.totalPages = null
				draft.listOrderHistoryParams.currentPage = null
				break
			}
			case '@orderManagement/SET_CLIENTS_LIST_PARAMS':
				draft.clientsMenu.listClientParams = action.payload.listClientParams
				break
			case '@orderManagement/SET_CLIENTS_LIST_PARAMS_FILTERS':
				draft.clientsMenu.filterSelected = action.payload.clientFilter
				break
			case '@orderManagement/SET_CLIENTS_LIST_PARAMS_FILTERS_CLEAR':
				draft.clientsMenu.filterSelected.id = null
				draft.clientsMenu.filterSelected.federatedState = []
				draft.clientsMenu.filterSelected.salesPersonCode = []
				draft.clientsMenu.filterSelected.financialStatus = []
				break
			case '@orderManagement/SET_ORDERS_LIST_PARAMS':
				draft.ordersMenu.listOrderParams = action.payload.listOrderParams
				break
			case '@orderManagement/SET_ORDERS_LIST_PARAMS_FILTERS':
				draft.ordersMenu.filterSelected = action.payload.orderFilter
				break
			case '@orderManagement/SET_ORDERS_LIST_PARAMS_FILTERS_CLEAR':
				draft.ordersMenu.filterSelected.id = null
				draft.ordersMenu.filterSelected.federatedState = []
				draft.ordersMenu.filterSelected.orderBranch = []
				draft.ordersMenu.filterSelected.orderCategory = []
				draft.ordersMenu.filterSelected.orderStatus = []
				draft.ordersMenu.filterSelected.salesPersonCode = []
				draft.ordersMenu.filterSelected.pendencyStatus = []
				break
			case '@orderManagement/SET_ORDER_HISTORY_LIST_PARAMS':
				draft.listOrderHistoryParams = action.payload.listOrderHistoryParams
				break
			default:
				return state
		}
	})
}

export default orderManagement
