import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import RequestResponse from 'models/RequestResponse'
import { RequisitionError } from 'models/RequisitionError'
import { Transfer, TransferInput, TransferUpdate } from 'models/Transfer'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createTransfer, getTransfers, updateTransfer } from 'services'
import { store } from 'store'
import { updateTransferFilters } from 'store/modules/user/actions'
import { TransferUpdateTypes } from 'utils/Constants'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'
import {
	TransfersContextType,
	TransfersContextValue
} from './TransfersContextType'

export const TransfersContextElement = createContext<TransfersContextType>(
	TransfersContextValue
)

const defaultParams = defaultListParams(
	store.getState().user?.listTransferParams,
	false
)

const TransfersProvider = (props: any) => {
	const [transferList, setTransferList] = useState<Transfer[]>([])
	const [isLoading, setLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<RequisitionError>()
	const listParams = useRef<ListParams>(defaultParams)

	const dispatch = useDispatch()

	useEffect(() => {
		requestTransfers()
	}, [])

	const requestTransfers = () => {
		setLoading(true)
		const { currentPage, pageLimit, searchValue } = { ...listParams.current }

		const offset = (currentPage - 1) * pageLimit
		getTransfers(pageLimit, offset, searchValue)
			.then((res) => {
				const { count, transfers } = res.data.response
				updateParams(Math.ceil(count / pageLimit), 'count')
				setTransferList(transfers)
				setLoading(false)
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				clearParams(true)
				setHasError(reportError)
				setLoading(false)
			})
	}

	const onUpdateList = () => {
		requestTransfers()
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		requestTransfers()
	}

	const onLimitChange = (newLimit: number) => {
		updateParams(newLimit, 'limit')
		clearParams()
		requestTransfers()
	}

	const onSearchEquip = (search: string) => {
		updateParams(search, 'search')
		clearParams()
		requestTransfers()
	}

	const onFilterChange = (newFilter: FilterData) => {
		updateParams(newFilter, 'filter')
		clearParams()
		requestTransfers()
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(updateTransferFilters(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(updateTransferFilters(listParams.current))
	}

	const onCreateTransfer = (
		transferInput: TransferInput,
		callback: (request: RequestResponse) => void
	) => {
		createTransfer(transferInput)
			.then((res) => {
				const { transfer } = res.data
				callback({ response: transfer })
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				callback({ reportError })
			})
	}

	const onRemoveTransfer = (
		transferId: number,
		callback: (request: RequestResponse) => void
	) => {
		const transferUpdate = {
			id: transferId,
			type: TransferUpdateTypes.REMOVE
		} as TransferUpdate
		updateTransfer(transferUpdate)
			.then((res) => {
				requestTransfers()
				callback(res.data.transfer)
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				callback({ reportError })
			})
	}

	const onMeetTransfer = (
		transferId: number,
		userId: number,
		callback: (request: RequestResponse) => void,
		cancelRefrash = false
	) => {
		const transferUpdate = {
			id: transferId,
			userId,
			type: TransferUpdateTypes.MEET_UPDATE
		} as TransferUpdate
		updateTransfer(transferUpdate)
			.then((res) => {
				if (!cancelRefrash) {
					requestTransfers()
				}
				callback({ response: res.data.transfer })
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				callback({ reportError })
			})
	}

	const providerValues = () => {
		return {
			transferList,
			isLoading,
			hasError,
			...listParams.current,
			onUpdateList,
			onPageChange,
			onLimitChange,
			onSearchEquip,
			onFilterChange,
			onCreateTransfer,
			onRemoveTransfer,
			onMeetTransfer
		}
	}

	return (
		<TransfersContextElement.Provider value={providerValues()}>
			{props.children}
		</TransfersContextElement.Provider>
	)
}

export default TransfersProvider
