import { RequisitionError } from 'models/RequisitionError'
import IAvailablePendency from 'module/orderManagement/models/IAvailablePendency'

export interface BaseOrderManagementContextType {
	availablePendencyList: IAvailablePendency
	hasError?: RequisitionError
	baseListsIsLoading: boolean
	priceTableList: any[]
}

export const BaseOrderManagementContextValue = {
	availablePendencyList: {} as IAvailablePendency,
	hasError: {} as RequisitionError,
	baseListsIsLoading: {} as boolean,
	priceTableList: [] as any
}
