import React from 'react'
import handleCleanCacheCookies from 'utils/handle-clean-cache-cookies'
import projectData from '../../../package.json'
import './styles.css'

const VersionInfo: React.FC = () => {
	const forceRefresh = () => {
		handleCleanCacheCookies()
	}

	return (
		<>
			<div className="version-info" onClick={forceRefresh}>
				{`Versão: ${projectData.version}`}
			</div>
		</>
	)
}

export default VersionInfo
