import { api } from 'services'
import { UserJacForms, changeUserJac } from '../models/users-jac'
import { createClientJacSchema } from '../schemas/client-jac-schema'

//UsersApp Api
export const getListUsersJac = async (data: {
	limit: number
	offset: number
	term: string
}) => {
	const limit = `&limit=${data.limit}`
	const skip = `&skip=${data.offset}`
	const term = `&term=${data.term}`
	return api.get(`UsersApps/getUsersJAC?${limit}${skip}${term}`)
}

export const getUsersJacById = async (data: changeUserJac) =>
	api.get(`UsersApps/getUsersJACById?userId=${data.id}`)

export const createUserJac = async (data: createClientJacSchema) =>
	api.post(`UsersApps/createUserJAC`, data)

export const updateUserJac = async (data: UserJacForms) =>
	api.put(`UsersApps/putUserJAC`, data)

export const deleteUserJac = async (id: number) =>
	api.delete(`UsersApps/deleteUserJACById?id=${id}`)
