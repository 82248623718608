import Equipment from 'models/Equipment'
import React from 'react'
import { MdSearch } from 'react-icons/md'
import { toast } from 'react-toastify'
import { getSerialInfo } from 'services'
import { Input } from '../styles'
import './styles.css'

interface Params {
	equipment: Equipment
	onSelectEquip: (equipment: any, client: any) => void
	setLoading: (value: boolean) => void
	deviation?: any
}

const ServiceOrderEquipEdit: React.FC<Params> = ({
	equipment,
	onSelectEquip,
	setLoading,
	deviation
}) => {
	const onEquipSelect = () => {
		const serial_number = $('.service-order-equipment-serial').val()

		if (serial_number) {
			setLoading(true)
			getSerialInfo(
				{
					serialNumber: serial_number,
					clientCode: '',
					equipmentRecno: ''
				},
				'ADMIN_APP'
			)
				.then((response) => {
					const data = response?.data
					if (data?.client && data?.equipment) {
						const clientSelected = {
							...data.client,
							cod_protheus: data.client?.code,
							nome_fantasia: data.client?.name,
							cpfCnpj: data.client.cpf_cnpj
						}

						const equipmentSelected = {
							name: data.equipment?.description,
							serial_number: data.equipment?.serial_number,
							...data.equipment
						}

						onSelectEquip(equipmentSelected, clientSelected)
					} else {
						toast.error('Equipamento não encontrado')
						setLoading(false)
					}
				})
				.catch(() => {
					toast.error('Erro ao buscar equipamento')
					setLoading(false)
				})
		} else {
			toast.error('Verifique o campo de N/S')
			setLoading(false)
		}
	}

	return (
		<div className="service-order-equipment-edit-form">
			<Input
				className="service-order-equipment-serial"
				name="serial_number"
				defaultValue={equipment.serial_number}
				type="text"
			/>
			<button type="button" onClick={onEquipSelect}>
				<MdSearch />
			</button>
		</div>
	)
}

export default ServiceOrderEquipEdit
