import React from 'react'

interface Params {
	errors: any
	isDirty: boolean
	formName: string
	validatorName?: string
}

const FormValidator: React.FC<Params> = ({
	errors,
	isDirty,
	formName,
	validatorName
}) => {
	const validatorContent = () => {
		const formError = errors[formName]
		if ((formError && isDirty) || formError) {
			switch (formError.type) {
				case 'required':
					return (
						<div className="form-item-content-validator">
							<span>
								{validatorName
									? `* O campo ${validatorName} é obrigatório!`
									: `* Campo obrigatório!`}
							</span>
						</div>
					)
				case 'maxLength':
					return (
						<div className="form-item-content-validator">
							<span>* Limite de máximo de caracteres atingido!</span>
						</div>
					)
				case 'minLength':
					if (validatorName === 'Senha') {
						return (
							<div className="form-item-content-validator">
								<span>* Sua senha deve conter no mínimo 8 caracteres!</span>
							</div>
						)
					}
					return (
						<div className="form-item-content-validator">
							<span>* Limite de minimo de caracteres atingido!</span>
						</div>
					)
				case 'pattern':
					return (
						<div className="form-item-content-validator">
							<span>
								{validatorName
									? `* O campo ${validatorName} não atende o formato correto!`
									: `* O Campo acima está fora do padrão!`}
							</span>
						</div>
					)
				default:
					return (
						<div className="form-item-content-validator">
							<span>
								{validatorName
									? `* Verifique o campo ${validatorName}!`
									: `* Verifique o campo acima!`}
							</span>
						</div>
					)
			}
		} else {
			return <></>
		}
	}

	return validatorContent()
}

export default FormValidator
