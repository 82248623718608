import { Order } from 'module/orderManagement/domain/models/order'
import {
	FreightTypes,
	PartialDeliveryReleaseTypes,
	ShipmentReleaseTypes
} from 'module/orderManagement/utils/constants'
import React, { useEffect } from 'react'
import Input from 'shared/components/Input'
import Select from 'shared/components/Select'
import { store } from 'store'

type ShippingDetailsEditingModeProps = {
	order?: Order
	form: any
}

export const ShippingDetailsEditingMode = ({
	order,
	form
}: ShippingDetailsEditingModeProps) => {
	const { carrierList } = store.getState().baseLists

	const {
		register,
		formState: { errors },
		setValue,
		getValues
	} = form

	useEffect(() => {
		order?.freight?.type && setValue('freight_type', order?.freight?.type)
		order?.freight?.reDelivery_shipping_company &&
			setValue(
				're_delivery_shipping_company',
				order?.freight?.reDelivery_shipping_company
			)
		order?.freight?.shipping_company &&
			setValue('shipping_company', order?.freight?.shipping_company)
		order?.freight?.partial_delivery &&
			setValue('partial_delivery', order?.freight?.partial_delivery)
		order?.freight?.release_shipment &&
			setValue('release_shipment', order?.freight?.release_shipment)
		order?.freight?.cost && setValue('freight_cost', order?.freight?.cost)
		order?.freight?.embedded &&
			setValue('embedded_shipping', order?.freight?.embedded)
		order?.freight?.commercial &&
			setValue('commercial_freight', order?.freight?.commercial)
		order?.freight?.package_type &&
			setValue('package_type', order?.freight?.package_type)
		order?.freight?.package_amount &&
			setValue('package_amount', order?.freight?.package_amount)
		order?.freight?.net_weight &&
			setValue('net_weight', order?.freight?.net_weight)
		order?.freight?.gross_weight &&
			setValue('gross_weight', order?.freight?.gross_weight)
	}, [
		order?.freight?.type,
		order?.freight?.reDelivery_shipping_company,
		order?.freight?.shipping_company,
		order?.freight?.partial_delivery,
		order?.freight?.release_shipment,
		order?.freight?.cost,
		order?.freight?.embedded,
		order?.freight?.commercial,
		order?.freight?.package_type,
		order?.freight?.package_amount,
		order?.freight?.net_weight,
		order?.freight?.gross_weight
	])

	return (
		<>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Transportadora</header>
				<Select
					id="shipping_company"
					options={carrierList}
					onSelectOptions={(option: any) => {
						setValue('shipping_company', option[0])
					}}
					selectedOption={[getValues('shipping_company')]}
					innerRef={{ ...register('shipping_company') }}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Tipo de frete</header>
				<Select
					id="freight_type"
					options={FreightTypes}
					selectedOption={[getValues('freight_type')]}
					onSelectOptions={(option) => {
						setValue('freight_type', option[0])
					}}
					innerRef={{
						...register('freight_type', {
							required: {
								value: true,
								message: 'O campo Tipo de frete é obrigatório!'
							}
						})
					}}
					errors={errors.freight_type}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Entrega Parcial</header>
				<Select
					id="partial_delivery"
					options={PartialDeliveryReleaseTypes}
					onSelectOptions={(option) => {
						setValue('partial_delivery', option[0])
					}}
					selectedOption={[getValues('partial_delivery')]}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Libera expedição</header>
				<Select
					id="release_shipment"
					options={ShipmentReleaseTypes}
					onSelectOptions={(option) => {
						setValue('release_shipment', option[0])
					}}
					selectedOption={[getValues('release_shipment')]}
					innerRef={{ ...register('release_shipment') }}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Valor do frete</header>
				<Input
					name="freight_cost"
					money
					variant="light"
					innerRef={{
						...register('freight_cost', {
							required: { value: true, message: 'Valor mínimo 0' },
							valueAsNumber: true,
							min: { value: 0, message: 'Valor mínimo 0' },
							max: {
								value: 999999999.99,
								message: 'Valor máximo 999999999.99'
							}
						})
					}}
					errors={errors.freight_cost}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Frete embutido</header>
				<Input
					name="embedded_shipping"
					money
					variant="light"
					innerRef={{
						...register('embedded_shipping', {
							required: { value: true, message: 'Valor mínimo é 0!' },
							valueAsNumber: true,
							min: { value: 0, message: 'Valor mínimo é 0!' },
							max: {
								value: 99999999.99,
								message: 'Valor máximo 99999999.99!'
							}
						})
					}}
					errors={errors.embedded_shipping}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Frete comercial</header>
				<Input
					name="commercial_freight"
					money
					variant="light"
					innerRef={{
						...register('commercial_freight', {
							required: { value: true, message: 'Valor mínimo 0' },
							valueAsNumber: true,
							min: { value: 0, message: 'Valor mínimo 0' },
							max: {
								value: 9999999999.99,
								message: 'Valor máximo 999999999.99'
							}
						})
					}}
					errors={errors.commercial_freight}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Tipo de volume</header>
				<Input
					name="package_type"
					variant="light"
					innerRef={{ ...register('package_type') }}
					maxLength={10}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Quantidade de volumes</header>
				<Input
					name="package_amount"
					variant="light"
					innerRef={{
						...register('package_amount', {
							required: { value: true, message: 'Valor mínimo 0' },
							valueAsNumber: true,
							min: { value: 0, message: 'Valor mínimo 0' },
							max: { value: 99999, message: 'Valor máximo 99999' }
						})
					}}
					errors={errors.package_amount}
					type={'number'}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Peso líquido</header>
				<Input
					name="net_weight"
					variant="light"
					innerRef={{
						...register('net_weight', {
							required: { value: true, message: 'Valor mínimo 0' },
							valueAsNumber: true,
							min: { value: 0, message: 'Valor mínimo 0' },
							max: {
								value: 999999999.99,
								message: 'Valor máximo 999999999.99'
							}
						})
					}}
					errors={errors.net_weight}
					type={'number'}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Peso bruto</header>
				<Input
					name="gross_weight"
					variant="light"
					innerRef={{
						...register('gross_weight', {
							required: { value: true, message: 'Valor mínimo 0' },
							valueAsNumber: true,
							min: { value: 0, message: 'Valor mínimo 0' },
							max: {
								value: 999999999.99,
								message: 'Valor máximo 999999999.99'
							}
						})
					}}
					errors={errors.gross_weight}
					type={'number'}
				/>
			</div>
			<div className="new-order-shipping-details-information-contain-information">
				<header>Redespacho</header>
				<Select
					id="re_delivery_shipping_company"
					options={carrierList}
					onSelectOptions={(option: any) => {
						setValue('re_delivery_shipping_company', option[0])
					}}
					selectedOption={[getValues('re_delivery_shipping_company')]}
					innerRef={{ ...register('re_delivery_shipping_company') }}
				/>
			</div>
		</>
	)
}
