import OrderManagementIcon from 'module/entryPoint/pages/FeaturesHome/orderManagementCard/OrderManagementIcon'
import React from 'react'
import { history } from 'routes/history'
import Button from 'shared/components/Button'
import './styles.scss'

const OrderManagementCard = () => {
	return (
		<div
			className="feature-card"
			onClick={() => {
				history.push(`/orders`)
			}}
		>
			<div className="order-management-card-title">
				<h4>ADMINISTRAÇÃO DE PEDIDOS</h4>
				<div className="human-resources-config-icon">
					<OrderManagementIcon />
				</div>
			</div>
			<div className="order-management-card-main-contain">
				<div className="service-content">
					<p className="subtext">Resumo da semana:</p>
					<div className="info-card">
						00 pedidos faturados | 00 clientes cadastrados
					</div>
				</div>
				<div className="summary2">
					<strong>Atenção!</strong>
					<br></br>
					<div className="last-service">Você possui 00 pedidos pendentes</div>
				</div>
			</div>
			<div className="order-management-card-actions">
				<Button
					title="Novo Cliente"
					variant="confirmation-solid"
					size="md"
					disabled={true}
					style={{ cursor: 'not-allowed' }}
				/>
				<Button
					title="Novo Pedido"
					variant="confirmation-solid"
					size="md"
					disabled={true}
					style={{ cursor: 'not-allowed' }}
				/>
			</div>
		</div>
	)
}

export default OrderManagementCard
