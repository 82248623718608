import { TransferClient } from 'models/Transfer'
import React from 'react'
import { normalizeCGC, phoneMask } from 'utils/Pipe'
import './styles.scss'

interface Props {
	client?: TransferClient
}

const TransferClientInfo: React.FC<Props> = ({ client }) => {
	return (
		<div className="transfer-client-info">
			<div>
				<span title={client?.name || ''}>
					<b>Nome Fantasia:</b>
					{client?.name}
				</span>
				<span title={client?.razaoSocial || ''}>
					<b>Razão Social:</b>
					{client?.razaoSocial}
				</span>
				<span>
					<b>CPF/CNPJ:</b>
					{normalizeCGC(client?.cpfCnpj)}
				</span>
			</div>
			<div>
				<span>
					<b>Contato:</b>
					{client?.contact}
				</span>
				<span title={client?.email || ''}>
					<b>E-mail:</b>
					{client?.email}
				</span>
				<span>
					<b>Telefone:</b>
					{phoneMask(client?.phone)}
				</span>
			</div>
		</div>
	)
}

export default TransferClientInfo
