import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import Pendency from 'module/orderManagement/models/Pendency'
import React, { useContext } from 'react'
import { ViewType } from 'utils/Constants'
import AllPendencyListBodyMobile from './mobile'
import AllPendencyListBodyWeb from './web'

type TParams = {
	pendencies: Pendency[]
	onSelectPendency: (checkedPendency: any[]) => void
}

const AllPendencyListBody = ({ pendencies, onSelectPendency }: TParams) => {
	const { viewType } = useContext(DeviceContextElement)
	return (
		<>
			{viewType !== ViewType.MOBILE ? (
				<AllPendencyListBodyWeb
					pendencies={pendencies}
					onSelectPendency={onSelectPendency}
				/>
			) : (
				<AllPendencyListBodyMobile
					pendencies={pendencies}
					onSelectPendency={onSelectPendency}
				/>
			)}
		</>
	)
}

export default AllPendencyListBody
