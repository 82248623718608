import styled from 'styled-components'

export const Header = styled.div`
	font-size: 24px;
	line-height: 26px;
	font-weight: 700;
	text-align: left;
`

export const Footer = styled.div`
	width: 100%;
	bottom: 0px;
	display: flex;
	column-gap: 20px;
	position: absolute;
	justify-content: center;
`

export const RequestInfo = styled.div`
	width: 100%;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	display: flex;
`

export const Form = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`

export const LabelName = styled.div`
	display: flex;
	margin-bottom: 0px;
	padding-right: 5px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-align: left;
`

export const InputArea = styled.textarea`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100%;
	flex-direction: column;
	width: 100%;
	height: 100px;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
`

export const Message = styled.div`
	margin: 0 0 0px;
	display: flex;
	flex-direction: column;
`

export const Characters = styled.div`
	font-size: 9px;
	margin-top: -10px;
	margin-bottom: 10px;
	align-self: flex-end;
`

export const DtFollowUp = styled.input`
	display: flex;
	margin-top: 5px;
	border-radius: 3px;
	width: 100%;
	flex-direction: row;
	padding-right: 10px;
	padding-left: 20px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	height: 38px;
`
