import { RequisitionError } from 'models/RequisitionError'
import Sc5010 from 'models/Sc5010'
import { Order, StorageItems } from 'module/orderManagement/domain/models/order'
import { OrderProductList } from 'module/orderManagement/models/OrderModel'
import Pendency from 'module/orderManagement/models/Pendency'
import {
	AddNewFollowUpProps,
	PrpOrder
} from 'module/orderManagement/models/PrpOrderModel'
import {
	IPendencyCreateCloudRequest,
	IPendencyDeleteCloudRequest,
	IPendencyUpdateCloudRequest
} from 'module/orderManagement/models/cloudTotvsRequisitionModels/IPendencyCloudRequisition'
import {
	createPrpOrderEvent,
	createZZF010,
	deleteZZF010,
	getOrder,
	getOrderByCode,
	getProductByValue,
	updateC5Libfin,
	updateZZF010
} from 'module/orderManagement/services'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getOrderPrpOverview, getSc5010Overview } from 'services'
import { ClientDetailsContextElement } from '../ClientDetailsContext/ClientDetailsContext'
import { OrderHistoryContextElement } from '../OrderHistoryContext/OrderHistoryContext'
import {
	OrderDetailsContextType,
	OrderDetailsContextValue
} from './OrderDetailsContextType'

export const OrderDetailsContextElement =
	createContext<OrderDetailsContextType>(OrderDetailsContextValue)

const OrderProvider = (props: any) => {
	const { onSelectClient } = useContext(ClientDetailsContextElement)
	const { onUpdateList } = useContext(OrderHistoryContextElement)
	const [orderCode, setOrderCode] = useState<{
		orderCode: string
		branch: string
	}>()
	const [sc5010, setSc5010] = useState<Sc5010 | null>(null)
	const [orderIsLoading, setOrderIsLoading] = useState<boolean>(false)
	const [libFinIsLoading, setLibFinIsLoading] = useState<boolean>(false)
	const [sc5IsLoading, setSc5IsLoading] = useState<boolean>(false)
	const [orderPrpIsLoading, setOrderPrpIsLoading] = useState<boolean>(false)
	const [hasError, setHasError] = useState<RequisitionError>()
	const [availableProductChange, setAvailableProductChange] =
		useState<OrderProductList[]>()
	const [order, setOrder] = useState<Order>()
	const [prpOrderDetails, setPrpOrderDetails] = useState<PrpOrder | null>(null)
	const [orderItems, setOrderItems] = useState<StorageItems[]>([])
	const [pendencies, setPendencies] = useState<Pendency[]>([])
	let AutCard = ''

	useEffect(() => {
		if (orderCode?.orderCode && orderCode?.branch) {
			requestOrder()
			requestSc5010Overview()
		}
	}, [orderCode?.orderCode, orderCode?.branch])

	useEffect(() => {
		if (sc5010) {
			requestOrderPrp(`${sc5010.c5Filial}${sc5010.c5Num}`)
		}
	}, [sc5010?.c5Num])

	const requestOrder = () => {
		if (orderCode?.orderCode && orderCode.branch) {
			setOrderIsLoading(true)
			getOrderByCode(orderCode.orderCode, orderCode.branch)
				.then((res) => {
					onUpdateList(res?.data?.order?.client?.code)
					setOrder(res?.data?.order)
					setOrderItems(res?.data?.order?.stock)
					setPendencies(res?.data?.order?.pendencies)
					onSelectClient({
						a1Cod: res?.data?.order?.client?.code,
						a1Loja: res?.data?.order?.client?.branch
					})
					AutCard = res?.data?.order?.credit_card_authorization
				})
				.catch((err) => {
					const error = err?.request?.response?.error
					const reportError = {
						message: error?.message,
						code: error?.statusCode,
						error: error?.request
					}
					setHasError(reportError)
				})
				.finally(() => {
					setOrderIsLoading(false)
					requestOrderApi()
				})
		}
	}

	const requestOrderPrp = (protheusOrder: string) => {
		if (protheusOrder) {
			setOrderPrpIsLoading(true)
			getOrderPrpOverview(protheusOrder)
				.then((res) => setPrpOrderDetails(res.data))
				.catch(() => setPrpOrderDetails(null))
				.finally(() => setOrderPrpIsLoading(false))
		}
	}

	const requestSc5010Overview = () => {
		if (orderCode?.orderCode && orderCode.branch) {
			setSc5IsLoading(true)
			getSc5010Overview({
				c5Num: orderCode.orderCode,
				c5Filial: orderCode.branch
			})
				.then((res) => setSc5010(res.data))
				.catch(() => setSc5010(null))
				.finally(() => setSc5IsLoading(false))
		}
	}

	const requestOrderApi = () => {
		if (orderCode?.orderCode && orderCode.branch) {
			setOrderIsLoading(true)
			getOrder(orderCode.branch, orderCode.orderCode)
				.then((res) => {
					const { order } = res.data
					setOrder({
						...order,
						credit_card_authorization: AutCard
					})
					setOrderIsLoading(false)
				})
				.catch((err) => console.log('Error on request order on Protheus', err))
				.finally(() => setOrderIsLoading(false))
		}
	}

	const onUpdateOrder = () => {
		setOrder(undefined)
		requestOrder()
	}
	const onUpdateOrderPrp = (protheusOrder: string) => {
		setPrpOrderDetails(null)
		requestOrderPrp(protheusOrder)
	}

	const onOrderChange = (newOrderCode: string, branch: string) => {
		setOrderItems([])
		setPendencies([])
		setOrder(undefined)
		setPrpOrderDetails(null)
		setOrderCode({ orderCode: newOrderCode, branch })
	}

	const onAddCommentary = (
		data: AddNewFollowUpProps,
		callback: (hasError?: RequisitionError) => void
	) => {
		setOrderPrpIsLoading(true)
		createPrpOrderEvent(data)
			.then(() => {
				callback()
				onUpdateOrder()
			})
			.catch((err) => {
				const { error } = err.response.data
				const reportError = {
					message: error?.message,
					code: error?.statusCode,
					error: error
				}
				setHasError(reportError)
				callback(reportError)
			})
			.finally(() => setOrderPrpIsLoading(false))
	}

	const onUpdateC5Libfin = (params: {
		protheusOrder: string
		c5Libfin: string
	}) => {
		setLibFinIsLoading(true)
		updateC5Libfin(params)
			.then(() => {
				onUpdateOrder()
				requestSc5010Overview()
				toast.success('Liberação financeira atualizada com sucesso!')
			})
			.catch((err) => {
				const { error } = err.response.data
				toast.error(error?.message || 'Erro ao atualizar liberação financeira')
			})
			.finally(() => setLibFinIsLoading(false))
	}

	const onCreateZZF010 = (
		data: IPendencyCreateCloudRequest,
		callback: (hasError?: RequisitionError) => void
	) => {
		setOrderIsLoading(true)
		createZZF010(data)
			.then(() => {
				callback()
				onUpdateOrder()
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
				callback(reportError)
			})
			.finally(() => setOrderIsLoading(false))
	}

	const onDeleteZZF010 = (
		data: IPendencyDeleteCloudRequest,
		callback: (hasError?: RequisitionError) => void
	) => {
		setOrderIsLoading(true)
		deleteZZF010(data)
			.then(() => {
				callback()
				onUpdateOrder()
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
				callback(reportError)
			})
			.finally(() => setOrderIsLoading(false))
	}

	const onUpdateZZF010 = (
		data: IPendencyUpdateCloudRequest,
		callback: (hasError?: RequisitionError) => void
	) => {
		setOrderIsLoading(true)
		updateZZF010(data)
			.then(() => {
				callback()
				onUpdateOrder()
			})
			.catch((err) => {
				const { error } = err.request.response
				const reportError = {
					message: error.message,
					code: error.statusCode,
					error: error.request
				}
				setHasError(reportError)
				callback(reportError)
			})
			.finally(() => setOrderIsLoading(false))
	}

	const searchProduct = (value: string) => {
		if (value) {
			getProductByValue(value)
				.then((res) => {
					const { products } = res.data
					setAvailableProductChange(products)
				})
				.catch((err) => console.log(err))
		}
	}

	const providerValues = () => {
		return {
			availableProductChange,
			order,
			sc5010,
			prpOrderDetails,
			pendencies,
			storage: orderItems,
			onOrderChange,
			onUpdateOrder,
			onUpdateOrderPrp,
			onCreateZZF010,
			onDeleteZZF010,
			onUpdateZZF010,
			onAddCommentary,
			onUpdateC5Libfin,
			libFinIsLoading,
			orderIsLoading,
			orderPrpIsLoading,
			sc5IsLoading,
			searchProduct,
			hasError
		}
	}

	return (
		<OrderDetailsContextElement.Provider value={providerValues()}>
			{props.children}
		</OrderDetailsContextElement.Provider>
	)
}

export default OrderProvider
