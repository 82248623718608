import { api } from 'services'

// product
export const getSb1010Details = async (codeProtheus: string) =>
	api.get(`Sb1010s/overview`, { params: { b1Cod: codeProtheus } })

export const getProductDetails = async (codeProtheus: string) =>
	api.get(`Products/overview`, { params: { codeProtheus } })

export const postProductFile = async (uploadedFile: any) =>
	api.post(`Products/postFiles`, uploadedFile)

// product_family
export const getSzr010Details = async (codeProtheus: string) =>
	api.get(`Szr010s/overview`, { params: { zrCodigo: codeProtheus } })

export const getProductFamilyDetails = async (codeProtheus: string) =>
	api.get(`ProductFamilies/overview`, { params: { codeProtheus } })

export const postProductFamilyFile = async (uploadedFile: any) =>
	api.post(`ProductFamilies/postFiles`, uploadedFile)

export const customDeleteFile = (payload: any) => {
	api.post(`Files/customDeleteFile`, payload)
}
