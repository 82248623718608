import { CircularProgress } from '@material-ui/core'
import React from 'react'

import './styles.scss'

type Props = {
	show: boolean
}

export const LoadingProgress = ({ show }: Props) => {
	const mainContent = () => {
		return (
			<div className="loading-main-content">
				<div className="loading-main">
					<CircularProgress size={15} />
				</div>
			</div>
		)
	}

	return show ? mainContent() : <></>
}
