import $ from 'jquery'
import { BaseOrderManagementElement } from 'module/orderManagement/context/BaseOrderManagementContext/BaseOrderManagementContext'
import React, { MouseEvent, useContext, useEffect, useState } from 'react'
import { CondPag, Transp, tpService } from './functions'
import {
	AddressLabel,
	AddressSpan,
	Div_Info,
	Info_Title,
	ItensSelect,
	Padding,
	TextCust
} from './styles'
import './styles.css'

interface Props {
	client_address: string
	lastTechAddress: string
}

const PartInfo: React.FC<Props> = ({ client_address, lastTechAddress }) => {
	const { priceTableList } = useContext(BaseOrderManagementElement)
	const [newPriceTabList, setNewPriceTabList] = useState<any[]>([])

	let obs = ''
	let tpServ = ''
	let cond = ''
	let tabprice = ''

	let Transportadora = ''

	const handleAddress = (e: MouseEvent<HTMLInputElement>) => {
		if (e.currentTarget.id == 'other-address') {
			$(`#other`).css('display', 'block')
		} else if (e.currentTarget.id == 'tech-address') {
			$(`#other`).css('display', 'none')
		} else if (e.currentTarget.id == 'main-address') {
			$(`#other`).css('display', 'none')
		}
	}

	useEffect(() => {
		const newList = [...priceTableList]
		newList.unshift({ value: '', label: 'Selecione', available: '1' })
		setNewPriceTabList(newList)
	}, [])

	const retDescription = (html: any) => {
		if (html !== '') {
			return html?.charAt(0)?.toUpperCase() + html?.slice(1)?.toLowerCase()
		}
	}

	return (
		<>
			<Div_Info>
				<Info_Title>Endereço para envio: </Info_Title>
				<ItensSelect>
					<AddressLabel>
						<input
							type="radio"
							data-name="main-address"
							id="main-address"
							name="radio"
							value="Radio"
							className="w-form-formradioinput_ radio-button_ w-radio-input_"
							onClick={handleAddress}
						/>
						<AddressSpan id="main-address-val">{client_address}</AddressSpan>
					</AddressLabel>

					<AddressLabel>
						<input
							type="radio"
							data-name="tech-address"
							id="tech-address"
							name="radio"
							value="Radio"
							className="w-form-formradioinput_ radio-button_ w-radio-input_"
							onClick={handleAddress}
						/>
						<AddressSpan id="tech-address-val">{lastTechAddress}</AddressSpan>
					</AddressLabel>
				</ItensSelect>

				<Info_Title>Transportadora: </Info_Title>

				<Padding>
					<select id="transp" name="transp" className="select-field_ w-select_">
						{Transp.map((item) => (
							<option key={item.value} value={item.value}>
								{(Transportadora = item.label)}
							</option>
						))}
					</select>
				</Padding>

				<Info_Title>Tipo de serviço do pedido: </Info_Title>

				<Padding>
					<select
						id="tpService"
						name="tpservice"
						className="select-field_ w-select_"
					>
						{tpService.map((item) => (
							<option key={item.value} value={item.value}>
								{(tpServ = item.label)}
							</option>
						))}
					</select>
				</Padding>

				<Info_Title>Forma de pagamento: </Info_Title>

				<Padding>
					<select
						id="condpag"
						name="condpag"
						className="select-field_ w-select_"
					>
						{CondPag.map((item) => (
							<option key={item.value} value={item.value}>
								{(cond = item.label)}
							</option>
						))}
					</select>
				</Padding>

				<Info_Title>Tabela de Preço: </Info_Title>

				<Padding>
					<select
						id="tabprice"
						name="tabprice"
						className="select-field_ w-select_"
					>
						{newPriceTabList
							.filter((item) => item.available === '1')
							.map((item) => (
								<option
									key={item.value}
									value={`${item.value} - ${item.label}`}
								>
									{retDescription((tabprice = item.label))}
								</option>
							))}
					</select>
				</Padding>

				<Info_Title>Mensagem para Nota Fiscal: </Info_Title>

				<Padding>
					<div>
						<TextCust
							id="obs"
							className={`obs`}
							name="obs"
							onChange={(e) => {
								obs = e.target.value
							}}
						/>
					</div>
				</Padding>
			</Div_Info>
		</>
	)
}

export default PartInfo
