import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import {
	handleClientChannelType,
	handleClientSegmentType,
	handleClientType,
	handleGroupClientType,
	handleIndicatedClassificationType,
	handlePersonType,
	handleRegionType,
	handleSalespersonType,
	handleValidatedStateRegistrationType
} from 'module/orderManagement/utils/constants'
import React, { useContext } from 'react'
import Skeleton from 'react-loading-skeleton'
import { normalizeCGC } from 'utils/Pipe'
import './styles.scss'

export const ClientInformationViewingMode = () => {
	const { sa1010, sa1010IsLoading } = useContext(ClientDetailsContextElement)

	return (
		<div className="client-information">
			<div className="client-information-contain-information">
				<header>Razão social</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Nome ? (
						sa1010?.a1Nome
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Nome fantasia</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Nreduz ? (
						sa1010?.a1Nreduz
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Código protheus</header>
				<span>
					{sa1010IsLoading ? <Skeleton /> : sa1010?.a1Cod ? sa1010?.a1Cod : '-'}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Tipo de pessoa</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Pessoa ? (
						handlePersonType(sa1010?.a1Pessoa)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>CNPJ/CPF</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Cgc ? (
						normalizeCGC(sa1010?.a1Cgc)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>IE</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Inscr ? (
						sa1010?.a1Inscr
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>IE Validada</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Valie ? (
						handleValidatedStateRegistrationType(sa1010?.a1Valie)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Segmento</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Ytipocl ? (
						handleClientSegmentType(sa1010?.a1Ytipocl)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Tipo de cliente</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Tipo ? (
						handleClientType(sa1010?.a1Tipo)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Canal cliente</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Canal ? (
						handleClientChannelType(sa1010?.a1Canal)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Class. indicada</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Classif ? (
						handleIndicatedClassificationType(sa1010?.a1Classif)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>SUFRAMA</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Suframa ? (
						sa1010?.a1Suframa
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Grupo cliente</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Grppra ? (
						handleGroupClientType(sa1010?.a1Grppra)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Vendedor</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Repres ? (
						`${handleSalespersonType(sa1010?.a1Repres)}`
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Região</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Regiao ? (
						handleRegionType(sa1010?.a1Regiao)
					) : (
						'-'
					)}
				</span>
			</div>
			<div className="client-information-contain-information">
				<header>Bloqueio financeiro</header>
				<span>
					{sa1010IsLoading ? (
						<Skeleton />
					) : sa1010?.a1Xblfin === 'S' ? (
						'Sim'
					) : sa1010?.a1Xblfin === 'N' ? (
						'Não'
					) : (
						'-'
					)}
				</span>
			</div>
		</div>
	)
}
