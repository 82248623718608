import React from 'react'
import wind from './images/wind.png'

import './styles.scss'
interface Params {
	show: boolean
	text: string
}

const EmptyList: React.FC<Params> = ({ show, text }) => {
	const mainContent = () => {
		return (
			<div className="empty-main-content">
				<div className="empty-main">
					<img src={wind} />
					<span>{text} :)</span>
				</div>
			</div>
		)
	}

	return show ? mainContent() : <></>
}

export default EmptyList
