export const setUsersPrpParams = (listUsersParams) => {
	return {
		type: '@adminSystem/SET_USERS_PRP_LIST_PARAMS',
		payload: {
			listUsersParams
		}
	}
}

export const setLogsParams = (listLogsParams) => {
	return {
		type: '@adminSystem/SET_LOGS_LIST_PARAMS',
		payload: {
			listLogsParams
		}
	}
}
