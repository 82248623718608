import Filter from 'models/Filter'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import FilterOs from 'module/technicalServices/components/FilterOs'
import React, { useContext, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getFilters, removeFilter } from 'services/index'
import ConfirmModal from 'shared/Modals/ConfirmModal'
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import { ViewType } from 'utils/Constants'
import FilterListMobile from './Mobile'
import FilterListWeb from './Web'
import './styles.scss'

interface Props {
	show: boolean
	handleChanges: (value: boolean) => void
	onClose: (value: boolean) => void
}

const FilterView: React.FC<Props> = ({ show, handleChanges, onClose }) => {
	const { viewType } = useContext(DeviceContextElement)
	const [filters, setFilters] = useState<Filter[]>([])
	const [filterSelected, setFilterSelected] = useState<Filter>()
	const [hasEditingFilter, setHasEditingFilter] = useState<boolean>(false)
	const [showConfirmDeleteModal, setShowConfirmDeleteModal] =
		useState<boolean>(false)
	const [anchorRefFilter, setAnchorRefFilter] =
		React.useState<null | HTMLElement>(null)

	const user = store.getState().user

	const callOpenApi = () => {
		const response = getFilters(user.profile, 'technical_services')
		response.then((response) => {
			const filters = response.data
			filters.map((item) => {
				item.stateList = JSON.parse(item.filter_state)
				item.statusList = JSON.parse(item.filter_status)
				item.typeOsList = JSON.parse(item.filter_type_os)
				item.accountTypeList = JSON.parse(item.filter_type_account)
			})
			setFilters(filters)
		})
	}

	useEffect(() => {
		setFilterSelected(undefined)
		callOpenApi()
	}, [])

	const handleChangesEdit = (worked: boolean) => {
		if (worked) {
			toast.success('Filtro salvo com sucesso.')
			callOpenApi()
			setFilterSelected(undefined)
			setHasEditingFilter(false)
			handleChanges(true)
			onClose(false)
		} else {
			toast.error('Erro ao salvar o filtro.')
			setHasEditingFilter(false)
			handleChanges(false)
		}
	}

	const handleChangesRemove = (filter: Filter) => {
		setShowConfirmDeleteModal(true)
		setFilterSelected(filter)
	}

	const handleOnConfirmDelete = () => {
		if (filterSelected)
			removeFilter(filterSelected.id)
				.then(() => {
					toast.success('Filtro removido com sucesso.')
					setFilterSelected(undefined)
					setShowConfirmDeleteModal(false)
					callOpenApi()
				})
				.catch(() => {
					toast.error('Erro ao remover o filtro.')
				})
	}

	const handleOnCancelConfirmDelete = () => {
		setFilterSelected(undefined)
		setShowConfirmDeleteModal(false)
	}

	const handleSelectedFilter = (filter: Filter | undefined) => {
		setAnchorRefFilter(null)
		setFilterSelected(filter)
		setHasEditingFilter(true)
	}

	const handleFilterList = () => {
		return viewType !== ViewType.MOBILE ? (
			<FilterListWeb
				filters={filters}
				handleChangesEdit={handleChangesEdit}
				handleChangesRemove={handleChangesRemove}
			/>
		) : (
			<FilterListMobile
				filters={filters}
				handleChangesEdit={handleChangesEdit}
				handleChangesRemove={handleChangesRemove}
			/>
		)
	}

	return (
		<>
			<Modal
				title="Meus filtros"
				onClose={() => onClose(false)}
				showClose={true}
				show={show}
			>
				<div className="filter-view-contain">
					{!!filters?.length && handleFilterList()}
					<div className="filter-view-footer-contain">
						<Button
							style={{ width: '126px' }}
							onClick={() => handleSelectedFilter(undefined)}
							title="Novo filtro"
							size="lg"
							variant="confirmation-gradient"
						/>
						<Button
							style={{ width: '126px' }}
							onClick={() => onClose(false)}
							title="Cancelar"
							size="lg"
							variant="cancellation-gradient"
						/>
					</div>
				</div>
			</Modal>
			<FilterOs
				show={hasEditingFilter}
				handleChanges={handleChangesEdit}
				handleCancel={() => setHasEditingFilter(false)}
				filter={filterSelected}
			/>
			<ConfirmModal
				title="EXCLUIR FILTRO"
				cancelLabel=" Cancelar "
				onCancel={handleOnCancelConfirmDelete}
				show={showConfirmDeleteModal}
				onConfirm={handleOnConfirmDelete}
			>
				<span>Deseja excluir o filtro {filterSelected?.name}? </span>
			</ConfirmModal>
		</>
	)
}

export default FilterView
