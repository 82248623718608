export const osTypes = [
	{ value: '', label: '' },
	{ value: 'CONEXÃO PREMIUM', label: 'Conexão Premium' },
	{ value: 'CORRETIVA PREVENTIVA', label: 'Corretiva Preventiva' },
	{ value: 'CORTESIA 1 MES', label: 'Cortesia 1 Mês' },
	{ value: 'CORTESIA COMERCIAL', label: 'Cortesia Comercial' },
	{ value: 'CORTESIA SERVICO TECNICO', label: 'Cortesia Serviço Técnico' },
	{ value: 'ERRO ADMINISTRATIVO', label: 'Erro Administrativo' },
	{ value: 'FORA DE GARANTIA', label: 'Fora de Garantia' },
	{ value: 'GARANTIA ESTENDIDA', label: 'Garantia Estendida' },
	{ value: 'GARANTIA REVENDA', label: 'Garantia Revenda' },
	{ value: 'GARANTIA', label: 'Garantia' },
	{ value: 'IMPRODUTIVO', label: 'Improdutivo' },
	{ value: 'INSTALACAO REVENDA', label: 'Instalação Revenda' },
	{ value: 'INSTALACAO', label: 'Instalação' },
	{ value: 'LOCACOES', label: 'Locações' },
	{ value: 'ORCAMENTOS', label: 'Orçamentos' },
	{ value: 'OUTRAS SOLICITACOES', label: 'Outras Solicitações' },
	{ value: 'PRE-INSTALACAO', label: 'Pre-Instalação' },
	{ value: 'PREVENTIVA', label: 'Preventiva' },
	{ value: 'RECALL', label: 'Recall' },
	{ value: 'RETORNO AO CLIENTE', label: 'Retorno ao Cliente' },
	{ value: 'SEMI NOVOS', label: 'Semi Novos' }
]
export const osTypesChefs = [
	{ value: 'ATENDIMENTO DE URGENCIA', label: 'Atendimento de urgência' },
	{
		value: 'ATENDIMENTO GERAL CONCESSIONARIA',
		label: 'Atendimento geral concessionária'
	},
	{
		value: 'ATENDIMENTO CONCESSIONARIA DEMO',
		label: 'Solicitação de atendimento concessionária Demo'
	},
	{
		value: 'ATENDIMENTO CONCESSIONARIA TESTE',
		label: 'Solicitação de atendimento concessionária Teste'
	},
	{
		value: 'ATENDIMENTO EPP DEMONSTRACAO',
		label: 'Solicitação de atendimento Epp Demonstração'
	},
	{
		value: 'DEMONSTRACAO OU TESTE PARA FILIAIS',
		label: 'Demonstração ou teste para filiais'
	},
	{
		value: 'OUTRAS SOLICITACOES CANAL CHEF',
		label: 'Outra solicitações canal chef'
	},
	{
		value: 'SOLICITACAO DE ATENDIMENTO VIA ASSISTENCIA TECNICA',
		label: 'Solicitação de atendimento via assistência técnica'
	},
	{
		value: 'SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO',
		label: 'Solicitação de atendimento via erro zero'
	},
	{
		value: 'SOLICITACAO DE ATENDIMENTO VIA QUALIDADE',
		label: 'Solicitação de atendimento via qualidade'
	},
	{
		value: 'SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO',
		label: 'Solicitação de atendimento via erro zero'
	},
	{
		value: 'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF+DESPESAS)',
		label: 'Solicitação de atendimento bonificação (chef+despesas'
	},
	{
		value: 'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF)',
		label: 'Solicitação de atendimento bonificação (chef)'
	},
	{
		value: 'SOLICITACAO DE TREINAMENTO POLITICA',
		label: 'Solicitação de atendimento politica'
	},
	{
		value: 'SOLICITACAO DE TREINAMENTO REVENDA',
		label: 'Solicitação de atendimento Revenda'
	},
	{
		value: 'SOLICITACAO DE TREINAMENTO REVENDA (CHEF+DESPESAS)',
		label: 'Solicitação de atendimento Revenda (chef+despesas)'
	},
	{
		value: 'SOLICITACAO DE TREINAMENTO REVENDA (CHEF)',
		label: 'Solicitação de atendimento Revenda (chef)'
	},
	{
		value: 'VISITA DO CHEF VIA GERENTE/COORDENADOR',
		label: 'Visita do chef via Gerente/Coordenador'
	},
	{
		value: 'VISITA DO CHEF VIA CONCESSIONARIA',
		label: 'Visita do chef via concessionária'
	}
]

export const osTypesAlt = [
	{ value: '', label: '' },
	{ value: 'CORTESIA 1 MES', label: 'Cortesia 1 Mês' },
	{ value: 'CORRETIVA PREVENTIVA', label: 'Corretiva Preventiva' },
	{ value: 'GARANTIA', label: 'Garantia' },
	{ value: 'ERRO ADMINISTRATIVO', label: 'Erro Administrativo' },
	{ value: 'ORCAMENTOS', label: 'Orçamentos' },
	{ value: 'GARANTIA ESTENDIDA', label: 'Garantia Estendida' },
	{ value: 'GARANTIA REVENDA', label: 'Garantia Revenda' },
	{ value: 'LOCACOES', label: 'Locações' },
	{ value: 'OUTRAS SOLICITACOES', label: 'Outras Solicitações' },
	{ value: 'PRE-INSTALACAO', label: 'Pre-Instalação' },
	{ value: 'PREVENTIVA', label: 'Preventiva' },
	{ value: 'RECALL', label: 'Recall' },
	{ value: 'RETORNO AO CLIENTE', label: 'Retorno ao Cliente' },
	{ value: 'SEMI NOVOS', label: 'Semi Novos' }
]
