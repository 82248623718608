import { ClientDetailsContextElement } from 'module/orderManagement/context/ClientDetailsContext/ClientDetailsContext'
import React, { useContext, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Button from 'shared/components/Button'
import { store } from 'store'
import './styles.scss'

type TParams = {
	path: string
}

const ClientMenuTabsWeb = ({ path }: TParams) => {
	const { sa1010 } = useContext(ClientDetailsContextElement)
	const [financeDetailsTab, setFinanceDetailsTab] = useState<boolean>(false)
	const [partOrderHistoryTab, setPartOrderHistoryTab] = useState<boolean>(false)
	const [partOrderClientTab, setPartOrderClientTab] = useState<boolean>(false)
	const [partOrderLogsTab, setPartOrderLogsTab] = useState(false)
	const [attachmentsTab, setAttachmentsTab] = useState<boolean>(false)
	const { roles } = store.getState().user
	const digitalEndeavourManager = roles.includes('digitalEndeavourManager')
	const orderManager = roles.includes('orderManager')

	useEffect(() => {
		switch (path) {
			case `/client/:clientCode/:clientStoreCode`:
				setPartOrderClientTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/history`:
				setPartOrderHistoryTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/logs`:
				setPartOrderLogsTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/attachments`:
				setAttachmentsTab(true)
				break
			case `/client/:clientCode/:clientStoreCode/financeDetails`:
				setFinanceDetailsTab(true)
				break
			default:
				setPartOrderClientTab(true)
				break
		}
	}, [path])

	return (
		<>
			<div className="client-menu-tabs-switch">
				<div className="action-bt-back">
					<NavLink to={`/clients`}>
						<span>&lt; Voltar</span>
					</NavLink>
				</div>
				<div className="client-menu-tabs-larger">
					<NavLink to={`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}`}>
						<Button
							size="md"
							title="DADOS CADASTRAIS"
							variant={partOrderClientTab ? 'outline' : 'outline-dark'}
						/>
					</NavLink>
				</div>
				<div className="client-menu-tabs">
					<NavLink to={`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/history`}>
						<Button
							size="md"
							title="HISTÓRICO"
							variant={partOrderHistoryTab ? 'outline' : 'outline-dark'}
						/>
					</NavLink>
				</div>
				<div className="client-menu-tabs">
					<NavLink
						to={`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/attachments`}
					>
						<Button
							size="md"
							title="ANEXOS"
							variant={attachmentsTab ? 'outline' : 'outline-dark'}
						/>
					</NavLink>
				</div>
				<div className="client-menu-tabs">
					<NavLink
						to={`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/financeDetails`}
					>
						<Button
							size="md"
							title="FINANCEIRO"
							variant={financeDetailsTab ? 'outline' : 'outline-dark'}
						/>
					</NavLink>
				</div>
				{(digitalEndeavourManager || orderManager) && (
					<div className="client-menu-tabs">
						<NavLink to={`/client/${sa1010?.a1Cod}/${sa1010?.a1Loja}/logs`}>
							<Button
								size="md"
								title="LOGS"
								variant={partOrderLogsTab ? 'outline' : 'outline-dark'}
							/>
						</NavLink>
					</div>
				)}
			</div>
		</>
	)
}
export default ClientMenuTabsWeb
