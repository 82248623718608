import PrevIcon from 'assets/image/ADM-Order-1.svg'
import ClientsIcon from 'assets/image/Clients.svg'
import TransIcon from 'assets/image/Download-3.svg'
import RhIcon from 'assets/image/HR.svg'
import OrdersIcon from 'assets/image/Orders.svg'
import ServiceIcon from 'assets/image/Service.svg'
import TechIcon from 'assets/image/Tecnicos.svg'
import BuscaIcon from 'assets/image/buscaIcon.svg'
import FamilyIcon from 'assets/image/family.svg'
import JacIcon from 'assets/image/jac.svg'
import LogsIcon from 'assets/image/logs.svg'
import ProductIcon from 'assets/image/miniconv.svg'
import Logo from 'assets/image/new-logo-branco.webp'
import VersionIcon from 'assets/image/version.svg'
import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { store } from 'store'
import './styles.css'

interface Props {
	menuShowing: boolean
	module:
		| 'order_management'
		| 'technical_services'
		| 'human_resources'
		| 'home'
		| 'config_user'
		| 'register'
		| 'cs'
		| 'install-and-search'
		| 'products_management'
}

const Menu: React.FC<Props> = ({ menuShowing, module }) => {
	const ordersUrl = `/os/${
		store.getState().serviceOrders?.url === 'os'
			? ''
			: store.getState().serviceOrders?.url
	}`
	const currentOrderManagementMenu = location.pathname.split('/')[1]

	useEffect(() => {
		changeShowMenu()
	}, [menuShowing])

	const changeShowMenu = () => {
		if (menuShowing) {
			;(document.querySelector('.content') as HTMLDivElement).style.width =
				'250px'
			;(
				document.querySelector('.nav-menu-container') as HTMLDivElement
			).style.width = '250px'
			;(document.querySelector('.content') as HTMLDivElement).style.transition =
				'width 0.5s'
			;(
				document.querySelector('.nav-menu-container') as HTMLDivElement
			).style.transition = 'width 0.5s'
		} else {
			;(document.querySelector('.content') as HTMLDivElement).style.width =
				'100px'
			;(
				document.querySelector('.nav-menu-container') as HTMLDivElement
			).style.width = '100px'
		}
	}

	const optionsMenuShow = () => {
		switch (module) {
			case 'human_resources':
				return (
					<NavLink
						to={`/human_resources/collaborators`}
						activeClassName="link-active"
					>
						<img width="30px" src={RhIcon} alt="collaborators" />
						<span
							className={`${menuShowing ? '' : 'back-off-span'}`}
							style={{ color: 'var(--text-high-pure)' }}
						>
							Colaboradores
						</span>
					</NavLink>
				)
			case 'technical_services':
				return (
					<>
						<NavLink to={ordersUrl} activeClassName="link-active">
							<img
								className="icons"
								src={ServiceIcon}
								alt="service order list"
							/>
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								ATENDIMENTOS
							</span>
						</NavLink>
						<NavLink
							to="/transfers"
							activeClassName="link-active"
							id="joyride-equipment-transfers"
						>
							<img
								className="icons"
								src={TransIcon}
								alt="equipment transfers"
							/>
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								TRANSFERÊNCIAS
							</span>
						</NavLink>
						<NavLink to="/preventive" activeClassName="link-active">
							<img
								className="icons"
								src={PrevIcon}
								alt="maintenance of preventive contracts"
							/>
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								PREVENTIVAS
							</span>
						</NavLink>
						<NavLink to="/technicians" activeClassName="link-active">
							<img className="icons" src={TechIcon} alt="technical" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								TÉCNICOS
							</span>
						</NavLink>
					</>
				)
			case 'order_management':
				return (
					<>
						<NavLink
							to="/orders"
							className={
								currentOrderManagementMenu === 'orders' ||
								currentOrderManagementMenu === 'order'
									? 'link-active'
									: ''
							}
						>
							<img className="icons" src={OrdersIcon} alt="sales orders icon" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Pedidos
							</span>
						</NavLink>
						<NavLink
							to="/clients"
							className={
								currentOrderManagementMenu === 'clients' ||
								currentOrderManagementMenu === 'client'
									? 'link-active'
									: ''
							}
						>
							<img className="icons" src={ClientsIcon} alt="client list icon" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Clientes
							</span>
						</NavLink>
					</>
				)
			case 'cs':
				return (
					<>
						<NavLink to={`/cs/logs`} activeClassName="link-active">
							<img width="30px" src={LogsIcon} alt="logs" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Logs
							</span>
						</NavLink>
						<NavLink to={`/cs/users`} activeClassName="link-active">
							<img width="30px" src={ClientsIcon} alt="users" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Usuários
							</span>
						</NavLink>
						<NavLink to={`/cs/usersJAC`} activeClassName="link-active">
							<img width="30px" src={JacIcon} alt="users-jac" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Usuários JAC
							</span>
						</NavLink>
						<NavLink to={`/cs/versionApps`} activeClassName="link-active">
							<img width="30px" src={VersionIcon} alt="versions" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Versão Apps
							</span>
						</NavLink>
						<NavLink to={`/cs/busca`} activeClassName="link-active">
							<img width="30px" src={BuscaIcon} alt="users" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								App Busca
							</span>
						</NavLink>
					</>
				)
			case 'products_management':
				return (
					<>
						<NavLink
							to="/products"
							className={
								currentOrderManagementMenu === 'products' ? 'link-active' : ''
							}
						>
							<img
								className="icons"
								src={ProductIcon}
								alt="sales orders icon"
							/>
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Produtos
							</span>
						</NavLink>
						<NavLink
							to="/productFamily"
							className={
								currentOrderManagementMenu === 'productFamily'
									? 'link-active'
									: ''
							}
						>
							<img className="icons" src={FamilyIcon} alt="sales orders icon" />
							<span
								style={{ color: 'var(--text-high-pure)' }}
								className={`${menuShowing ? '' : 'back-off-span'}`}
							>
								Familias
							</span>
						</NavLink>
					</>
				)
			default:
				return (
					<NavLink to="/home" activeClassName="link-active">
						<img src={Logo} alt="Prática" className="menu-logo-pratica" />
					</NavLink>
				)
		}
	}

	return (
		<header className="header-container">
			<div className="content">
				<div className="nav-content">
					<div
						className={`nav-brand-content advance ${
							menuShowing ? '' : 'back-off-logo'
						}`}
					>
						<NavLink to="/home" activeClassName="link-active">
							<img src={Logo} alt="Prática" className="menu-logo-pratica" />
						</NavLink>
					</div>
				</div>
				<div className="nav-menu-container">
					<div className="nav-actions-content">{optionsMenuShow()}</div>
				</div>
			</div>
		</header>
	)
}

export default Menu
