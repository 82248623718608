import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import down from 'assets/image/arrow_downward-24px.svg'
import up from 'assets/image/arrow_upward-24px.svg'
import States from 'assets/js/fs.json'
import $ from 'jquery'
import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext, useEffect, useState } from 'react'
import { MdFilterList } from 'react-icons/md'
import 'react-loading-skeleton/dist/skeleton.css'
import { useDispatch } from 'react-redux'
import { ListFilter } from 'shared/ListFilter'
import { store } from 'store'
import {
	changeStateChecked,
	changeStatusChecked,
	changeTypeOSChecked,
	getOsChangePage
} from 'store/modules/serviceOrders/actions'
import { ViewType } from 'utils/Constants'
import './styles.scss'

type Props = {
	setters: {
		setStatusChecked: (value: string[]) => void
		setTypeOSChecked: (value: string[]) => void
		setStateChecked: (value: string[]) => void
		setPageNumber: (value: number) => void
		setFieldFilter: (value: string) => void
		setOrderFilter: (value: string) => void
	}
	states: {
		statusChecked: string[]
		typeOSChecked: string[]
		stateChecked: string[]
	}
}
const statusList = [
	'Agendar',
	'Aguardar Visita',
	'Cancelar',
	'Efetivar Pedido',
	'Em acompanhamento',
	'Faturar Pedido',
	'Fazer Follow-up',
	'Finalizado',
	'Pedido Faturado',
	'Excluída'
]

const typeOSList = [
	'ASSISTENCIA',
	'ATENDIMENTO GERAL CONCESSIONARIA',
	'ATENDIMENTO CONCESSIONARIA DEMO',
	'ATENDIMENTO CONCESSIONARIA TESTE',
	'ATENDIMENTO DE URGENCIA',
	'ATENDIMENTO EPP DEMONSTRACAO',
	'CONEXÃO PREMIUM',
	'CORRETIVA PREVENTIVA',
	'CORTESIA 1 MES',
	'CORTESIA COMERCIAL',
	'CORTESIA SERVICO TECNICO',
	'DEMONSTRACAO OU TESTE PARA FILIAIS',
	'ERRO ADMINISTRATIVO',
	'FORA DE GARANTIA',
	'GARANTIA ESTENDIDA',
	'GARANTIA REVENDA',
	'GARANTIA',
	'IMPRODUTIVO',
	'INSTALACAO REVENDA',
	'INSTALACAO',
	'ORCAMENTOS',
	'OUTRAS SOLICITACOES CANAL CHEF',
	'OUTRAS SOLICITACOES',
	'PRE-INSTALACAO',
	'PREVENTIVA',
	'RECALL',
	'RETORNO AO CLIENTE',
	'SEMI NOVOS',
	'SOLICITACAO DE ATENDIMENTO CONCESSIONARIA DEMO',
	'SOLICITACAO DE ATENDIMENTO CONCESSIONARIA TESTE',
	'SOLICITACAO DE ATENDIMENTO EPP DEMONSTRACAO',
	'SOLICITACAO DE ATENDIMENTO VIA ASSISTENCIA TECNICA',
	'SOLICITACAO DE ATENDIMENTO VIA ERRO ZERO',
	'SOLICITACAO DE ATENDIMENTO VIA QUALIDADE',
	'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF)',
	'SOLICITACAO DE TREINAMENTO BONIFICADO (CHEF+DESPESA)',
	'SOLICITACAO DE TREINAMENTO POLITICA',
	'SOLICITACAO DE TREINAMENTO REVENDA (CHEF)',
	'SOLICITACAO DE TREINAMENTO REVENDA (CHEF+DESPESAS)',
	'SOLICITACAO DE TREINAMENTO REVENDA',
	'VISITA DO CHEF VIA CONCESSIONARIA',
	'VISITA DO CHEF VIA GERENTE/COORDENADOR'
]

const ServiceOrderListHeader = ({ setters, states }: Props) => {
	const { viewType } = useContext(DeviceContextElement)
	const [orderFollowUpDate, setOrderFollowUpDate] =
		React.useState<boolean>(false)
	const [orderCreatedDate, setOrderCreatedDate] = React.useState<boolean>(false)
	const anchorRefStatus = React.useRef<HTMLButtonElement>(null)
	const anchorRefState = React.useRef<HTMLButtonElement>(null)
	const anchorRefOs = React.useRef<HTMLButtonElement>(null)
	const [openStatus, setOpenStatus] = React.useState(false)
	const [openState, setOpenState] = React.useState(false)
	const [openOs, setOpenOs] = React.useState(false)
	const [stateList] = useState<string[]>([])
	const dispatch = useDispatch()

	useEffect(() => {
		getStates()
	}, [])

	function getStates() {
		return States.map((item) => stateList.push(item.initials))
	}

	const changePageByFilterChanges = (value: number) => {
		dispatch(getOsChangePage(value))
		setters.setPageNumber(store.getState().serviceOrders.pageNumber)
	}

	function handleToggleState(element: string) {
		const currentIndex = states.stateChecked.indexOf(element)
		const newChecked = [...states.stateChecked]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		changePageByFilterChanges(1)
		dispatch(changeStateChecked(newChecked))
		setters.setStateChecked(newChecked)
	}

	function handleToggleStatus(element: string) {
		const currentIndex = states.statusChecked.indexOf(element)
		const newChecked = [...states.statusChecked]
		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		changePageByFilterChanges(1)
		dispatch(changeStatusChecked(newChecked))
		setters.setStatusChecked(newChecked)
	}

	const handleCloseState = (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRefState.current &&
			anchorRefState.current.contains(event.target as HTMLElement)
		) {
			return
		}
		setOpenState(false)
	}

	const handleCloseStatus = (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRefStatus.current &&
			anchorRefStatus.current.contains(event.target as HTMLElement)
		) {
			return
		}
		setOpenStatus(false)
	}

	function handleToggleTypeOS(element: string) {
		const currentIndex = states.typeOSChecked.indexOf(element)
		const newChecked = [...states.typeOSChecked]

		if (currentIndex === -1) {
			newChecked.push(element)
		} else {
			newChecked.splice(currentIndex, 1)
		}
		changePageByFilterChanges(1)
		dispatch(changeTypeOSChecked(newChecked))
		setters.setTypeOSChecked(newChecked)
	}

	const handleCloseOs = (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRefOs.current &&
			anchorRefOs.current.contains(event.target as HTMLElement)
		) {
			return
		}
		setOpenOs(false)
	}

	const serviceOrderListHeaderMobile = () => {
		return (
			<div className="service-orders-list-header">
				<ul className="service-orders-list-item service-orders-list-state">
					<span>Estado</span>
					<button
						style={{ backgroundColor: 'transparent' }}
						ref={anchorRefState}
						aria-controls={openState ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={() => setOpenState((prevOpen) => !prevOpen)}
					>
						<MdFilterList
							fontSize="small"
							style={{
								color:
									states.stateChecked?.length === 0 ? 'lightgray' : '#333333'
							}}
						/>
					</button>
					<Popper
						style={{ zIndex: 1 }}
						open={openState}
						anchorEl={anchorRefState.current}
						role={undefined}
						keepMounted={true}
					>
						<ClickAwayListener onClickAway={handleCloseState}>
							<Paper>
								<ListFilter
									items={stateList}
									checked={states.stateChecked}
									onClick={handleToggleState}
								></ListFilter>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</ul>
				<ul className="service-orders-list-item service-orders-list-status">
					<span>Status</span>
					<button
						style={{ backgroundColor: 'transparent' }}
						ref={anchorRefStatus}
						aria-controls={openStatus ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={() => setOpenStatus((prevOpen) => !prevOpen)}
					>
						<MdFilterList
							fontSize="small"
							style={{
								color:
									states.statusChecked?.length === 0 ? 'lightgray' : '#333333'
							}}
						/>
					</button>
					<Popper
						style={{ zIndex: 1 }}
						open={openStatus}
						anchorEl={anchorRefStatus.current}
						role={undefined}
						keepMounted={true}
					>
						<ClickAwayListener onClickAway={handleCloseStatus}>
							<Paper>
								<ListFilter
									items={statusList}
									checked={states.statusChecked}
									onClick={handleToggleStatus}
								></ListFilter>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</ul>
				<ul className="service-orders-list-item service-orders-list-type">
					<span>Tipo de os</span>
					<button
						style={{ backgroundColor: 'transparent' }}
						ref={anchorRefOs}
						aria-controls={openOs ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={() => setOpenOs((prevOpen) => !prevOpen)}
					>
						<MdFilterList
							fontSize="small"
							style={{
								color:
									states.typeOSChecked?.length === 0 ? 'lightgray' : '#333333'
							}}
						/>
					</button>
					<Popper
						style={{ zIndex: 1 }}
						open={openOs}
						anchorEl={anchorRefOs.current}
						role={undefined}
						keepMounted={true}
					>
						<ClickAwayListener onClickAway={handleCloseOs}>
							<Paper>
								<ListFilter
									items={typeOSList}
									checked={states.typeOSChecked}
									onClick={handleToggleTypeOS}
								></ListFilter>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</ul>
			</div>
		)
	}
	const serviceOrderListHeaderWeb = () => {
		return (
			<div className="service-orders-list-header">
				<ul className="service-orders-list-item service-orders-list-created_at">
					<span>Data</span>
					<a
						onClick={() => {
							setOrderCreatedDate(!orderCreatedDate)
							setters.setFieldFilter('created_at')
							setters.setOrderFilter(orderCreatedDate ? 'desc' : 'asc')
							$('#createdAt').css('filter', 'invert(1%)')
							$('#appointmentDate').css('filter', 'invert(70%)')
						}}
					>
						{orderCreatedDate ? (
							<img
								id="createdAt"
								style={{ height: '20px', width: '20px', paddingLeft: '5px' }}
								src={up}
							></img>
						) : (
							<img
								id="createdAt"
								style={{ height: '20px', width: '20px', paddingLeft: '5px' }}
								src={down}
							></img>
						)}
					</a>
				</ul>
				<ul className="service-orders-list-item service-orders-list-order">
					<span>Protocolo</span>
				</ul>
				<ul className="service-orders-list-item service-orders-list-state">
					<span>Estado</span>
					<button
						style={{ backgroundColor: 'transparent' }}
						ref={anchorRefState}
						aria-controls={openState ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={() => setOpenState((prevOpen) => !prevOpen)}
					>
						<MdFilterList
							fontSize="small"
							style={{
								color:
									states.stateChecked?.length === 0 ? 'lightgray' : '#333333'
							}}
						/>
					</button>
					<Popper
						style={{ zIndex: 1 }}
						open={openState}
						anchorEl={anchorRefState.current}
						role={undefined}
						keepMounted={true}
					>
						<ClickAwayListener onClickAway={handleCloseState}>
							<Paper>
								<ListFilter
									items={stateList}
									checked={states.stateChecked}
									onClick={handleToggleState}
								></ListFilter>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</ul>
				<ul className="service-orders-list-item service-orders-list-company-name">
					<span>Cliente</span>
				</ul>
				<ul className="service-orders-list-item service-orders-list-equipment-name">
					<span>Equipamento</span>
				</ul>
				<ul className="service-orders-list-item service-orders-list-equipment-serie">
					<span>N/S</span>
				</ul>
				<ul className="service-orders-list-item service-orders-list-status">
					<span>Status</span>
					<button
						style={{ backgroundColor: 'transparent' }}
						ref={anchorRefStatus}
						aria-controls={openStatus ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={() => setOpenStatus((prevOpen) => !prevOpen)}
					>
						<MdFilterList
							fontSize="small"
							style={{
								color:
									states.statusChecked?.length === 0 ? 'lightgray' : '#333333'
							}}
						/>
					</button>
					<Popper
						style={{ zIndex: 1 }}
						open={openStatus}
						anchorEl={anchorRefStatus.current}
						role={undefined}
						keepMounted={true}
					>
						<ClickAwayListener onClickAway={handleCloseStatus}>
							<Paper>
								<ListFilter
									items={statusList}
									checked={states.statusChecked}
									onClick={handleToggleStatus}
								></ListFilter>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</ul>
				<ul className="service-orders-list-item service-orders-list-type">
					<span>Tipo de os</span>
					<button
						style={{ backgroundColor: 'transparent' }}
						ref={anchorRefOs}
						aria-controls={openOs ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						onClick={() => setOpenOs((prevOpen) => !prevOpen)}
					>
						<MdFilterList
							fontSize="small"
							style={{
								color:
									states.typeOSChecked?.length === 0 ? 'lightgray' : '#333333'
							}}
						/>
					</button>
					<Popper
						style={{ zIndex: 1 }}
						open={openOs}
						anchorEl={anchorRefOs.current}
						role={undefined}
						keepMounted={true}
					>
						<ClickAwayListener onClickAway={handleCloseOs}>
							<Paper>
								<ListFilter
									items={typeOSList}
									checked={states.typeOSChecked}
									onClick={handleToggleTypeOS}
								></ListFilter>
							</Paper>
						</ClickAwayListener>
					</Popper>
				</ul>
				<ul className="service-orders-list-item service-orders-list-follow-up">
					<span>Follow up</span>
					<a
						onClick={() => {
							setOrderFollowUpDate(!orderFollowUpDate)
							setters.setFieldFilter('followup_date')
							setters.setOrderFilter(orderFollowUpDate ? 'desc' : 'asc')
							$('#createdAt').css('filter', 'invert(70%)')
							$('#FollowUpDate').css('filter', 'invert(1%)')
						}}
					>
						{orderFollowUpDate ? (
							<img
								id="FollowUpDate"
								style={{ height: '20px', width: '20px', paddingLeft: '5px' }}
								src={up}
							/>
						) : (
							<img
								id="FollowUpDate"
								style={{ height: '20px', width: '20px', paddingLeft: '5px' }}
								src={down}
							/>
						)}
					</a>
				</ul>
				<ul className="service-orders-list-item service-orders-list-more-options" />
			</div>
		)
	}

	return viewType !== ViewType.MOBILE
		? serviceOrderListHeaderWeb()
		: serviceOrderListHeaderMobile()
}
export default ServiceOrderListHeader
