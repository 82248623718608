import React from 'react'
import SearchSvg from '../../../../assets/SvgIcons/SearchSvg'
import { Search } from '../search'

type Props = {
	text: string
	onChangeValues: (value: string) => void
}

export const SearchHeader = ({ text, onChangeValues }: Props) => {
	return (
		<Search.root>
			<Search.icon>
				<SearchSvg />
			</Search.icon>
			<Search.input {...{ onChangeValues }} placeholder={text} />
		</Search.root>
	)
}
