import React from 'react'
import { useForm } from 'react-hook-form'
import { FieldValues } from 'react-hook-form/dist/types'
import Button from 'shared/components/Button'
import Input from 'shared/components/Input'
import Modal from 'shared/components/Modal'
import './styles.scss'

type TParams = {
	show: boolean
	mailParams: {
		body: string
		subject: string
		mailto?: string
	}
	onClose: (show: boolean) => void
}

const EmailSendConfirm = ({ mailParams, show, onClose }: TParams) => {
	const { body, subject, mailto } = mailParams
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm({ defaultValues: { mailto: mailto } })

	const onSubmit = (data: FieldValues) => {
		const { mailto } = data
		window.open(`mailto:${mailto}?subject=${subject}&body=${body}`, '_top')
		onClose(false)
	}

	return (
		<Modal
			title="CONFIRME OS DADOS DO DESTINATÁRIO"
			show={show}
			onClose={() => onClose(false)}
			enableAwayClose={true}
			showClose={true}
		>
			<div className="email-contain">
				<div className="email-contain-form">
					<label htmlFor="mailto" className="email-contain-text">
						E-mail:
					</label>
					<Input
						name="mailto"
						type="email"
						variant="light"
						autoComplete="off"
						errors={errors.mailto}
						innerRef={{
							...register('mailto', {
								required: {
									value: true,
									message: 'O campo e-mail é obrigatório!'
								}
							})
						}}
					/>
				</div>
				<div className="email-contain-actions">
					<Button
						size="lg"
						title="CONFIRMAR"
						variant="confirmation-gradient"
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						size="lg"
						title="CANCELAR"
						variant="cancellation-gradient"
						onClick={() => onClose(false)}
					/>
				</div>
			</div>
		</Modal>
	)
}

export default EmailSendConfirm
