import Client from 'models/Client'
import Equipment from 'models/Equipment'
import { ServiceOrderUpdate } from 'models/ServiceOrderUpdate'
import { Tooltip } from 'module/orderManagement/components/Tooltip'
import { EquipmentLegendStatus } from 'module/technicalServices/utils/constants'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaCircle } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { getClientInfo } from 'services'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import ServiceOrderEquipEdit from './ServiceOrderEquipEdit'
import ServiceOrderEquipInfo from './ServiceOrderEquipInfo'
import {
	Change,
	Description,
	Footer,
	Header,
	Info,
	Line,
	SerialNumber,
	Title
} from './styles'
import './styles.css'

interface Params {
	equipment: Equipment
	client: Client
	fBlock: boolean
	gBlock: boolean
	onClose: () => void
	onUpdate: (updatedPayload: ServiceOrderUpdate) => void
	deviation?: any
}

const ServiceOrderEquipEditModal: React.FC<Params> = ({
	client,
	equipment,
	fBlock,
	gBlock,
	onClose,
	onUpdate,
	deviation
}) => {
	const [equipmentData, setEquipmentData] = useState<any>(equipment)
	const [clientData, setClientData] = useState<any>(client)
	const [generalBlock, setGeneralBlock] = useState<boolean>(gBlock)
	const [financialBlock, setFinancialBlock] = useState<boolean>(fBlock)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	console.log({ client, equipment, clientData, equipmentData })

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()

	useEffect(() => {
		getClientInfo(clientData?.cod_protheus)
			.then((resp) => {
				const gStatus = resp?.data[0]?.general_status
				const fStatus = resp?.data[0]?.financial_status
				setGeneralBlock(gStatus === '1')
				setFinancialBlock(fStatus === 'S')
				setIsLoading(false)
			})
			.catch((error) => {
				console.log('Erro ao verificar status do cliente: ', error)
				toast.error('Erro ao verificar status do cliente.')
				setIsLoading(false)
			})
	}, [clientData])

	const onSelectEquip = (equipment: any, client: any) => {
		setEquipmentData(equipment)
		setClientData(client)
	}

	const onSubmit = (data: any) => {
		const equipment = {
			name: equipmentData?.name,
			model: equipmentData?.model,
			serial_number: equipmentData?.serial_number,
			code_prp: equipmentData?.product_code,
			date_end_warranty: equipmentData?.date_end_warranty,
			type_code: equipmentData?.type_code,
			sale_date: equipmentData?.sale_date
		}

		const client = {
			cod_protheus: clientData?.code,
			razao_social: clientData?.razao_social,
			street: clientData?.address,
			neighborhood: clientData?.neighborhood,
			city: clientData?.city,
			zip_code: clientData?.zip_code,
			state: clientData?.state_sigla,
			nome_fantasia: clientData?.name,
			cpfCnpj: clientData?.cpf_cnpj,
			keyAccount: clientData?.key_account,
			data_criacao: Date?.now()
		}

		const updatedPayload = {
			equipment,
			client
		}

		onUpdate(updatedPayload)
	}

	const handleEquipInfo = () => {
		return (
			<ServiceOrderEquipInfo
				{...{
					generalBlock,
					financialBlock,
					client: clientData,
					equipment: equipmentData
				}}
			/>
		)
	}

	return (
		<Modal
			show={true}
			onClose={onClose}
			title="Alterar equipamento"
			showClose={true}
		>
			<div className="main-update-equip-container">
				<Header>
					<SerialNumber>
						<Description>Número de série:</Description>
						<Info>{equipment?.serial_number}</Info>
					</SerialNumber>
					<Title className="align-header">
						{equipment?.legend &&
							EquipmentLegendStatus?.map((legend) => {
								return (
									legend?.value === equipment?.legend && (
										<Tooltip
											info={legend?.label}
											mobile={false}
											key={legend?.value}
										>
											<FaCircle color={legend?.color} size={15} />
										</Tooltip>
									)
								)
							})}

						{equipment?.name}
					</Title>
					<Line></Line>
				</Header>

				<form onSubmit={handleSubmit(onSubmit)}>
					<Change>
						<div style={{ marginBottom: '10px' }}>Número de Série:</div>
						<ServiceOrderEquipEdit
							equipment={equipmentData}
							onSelectEquip={onSelectEquip}
							setLoading={setIsLoading}
							deviation={deviation}
						/>
						<LoadingProgress show={isLoading} text="Carregando..." />

						{!isLoading && handleEquipInfo()}
					</Change>
				</form>
				<Footer>
					<Button
						style={{ width: '120px' }}
						variant="confirmation-gradient"
						size="lg"
						title="Salvar"
						disabled={
							((equipmentData?.id === equipment.id ||
								equipmentData?.equipment_id === equipment.id) &&
								(client?.cod_protheus === clientData?.code ||
									client?.cod_protheus === clientData?.cod_protheus)) ||
							generalBlock
						}
						onClick={handleSubmit(onSubmit)}
					/>
					<Button
						style={{ width: '120px' }}
						variant="cancellation-gradient"
						size="lg"
						title="Cancelar"
						onClick={onClose}
					/>
				</Footer>
			</div>
		</Modal>
	)
}

export default ServiceOrderEquipEditModal
