import React from 'react'
import './styles.scss'

type Props = {
	progress: number
	width: number
}

export const ProgressBarCircular = ({ progress, width }: Props) => {
	return (
		<>
			<progress value={progress} max="100" />
		</>
	)
}
