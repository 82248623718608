import { Transfer, TransferClient, TransferEquipment } from 'models/Transfer'
import TransferClientInfo from 'module/technicalServices/components/Shared/Transfers/TransferClientInfo'
import TransferEquipmentInfo from 'module/technicalServices/components/Shared/Transfers/TransferEquipmentInfo'
import React from 'react'
import { TransferStatus } from 'utils/Constants'
import { parseDate } from 'utils/Pipe'
import './styles.scss'

interface Props {
	transfer: Transfer
}

const TransferFinished: React.FC<Props> = ({ transfer }) => {
	const clientContent = () => {
		const status = transfer.status
		const client = transfer.transferPayloadParsed.prpInfo.newClient
		const transferInfo = transfer.transferPayloadParsed.transferInfo
		const transferClient = {
			id: client?.id,
			name: client?.nome_fantasia,
			razaoSocial: client?.razao_social,
			contact: transferInfo.name,
			cpfCnpj: transferInfo.cpfCnpj,
			email: transferInfo.email,
			phone: transferInfo.phone
		} as TransferClient

		const contentTitle =
			status === TransferStatus.FINISHED
				? 'Cliente Atual'
				: 'Cliente de Destino'

		return (
			<section className="transfers-equip-info-content">
				<div className="transfers-info-header">
					<h6>{contentTitle}</h6>
				</div>
				<TransferClientInfo {...{ client: transferClient }} />
			</section>
		)
	}

	const equipContent = () => {
		const currentClient = transfer.transferPayloadParsed.prpInfo.currentClient
		const equipment = transfer.transferPayloadParsed.prpInfo.equipment
		const equipData = {
			id: equipment?.id,
			name: equipment?.name,
			serialNumber: equipment?.serial_number,
			saleDate: equipment?.sale_date,
			warrantDate: equipment?.date_end_warranty,
			currentClient: currentClient?.nome_fantasia,
			cpfCnpj: currentClient?.cpfCnpj,
			clientCode: currentClient?.cod_protheus
		} as TransferEquipment

		return (
			<section className="transfers-equip-info-content">
				<div className="transfers-info-header">
					<h6>Equipamento</h6>
				</div>
				<TransferEquipmentInfo
					{...{ equipment: equipData, status: TransferStatus.FINISHED }}
				/>
			</section>
		)
	}

	const transferContent = () => {
		const { description, userName, transferAt } = transfer

		return (
			<section className="transfers-action-content">
				<div className="transfer-info-content">
					<div className="transfer-info-content-columns">
						<div>
							<span>
								<b>Transferido por:</b>
								{userName}
							</span>
						</div>
						<div>
							<span>
								<b>Transferido em:</b>
								{parseDate(transferAt || '', 'DD/MM/YYYY')}
							</span>
						</div>
					</div>
					<div className="transfer-info-content-row">
						<span>
							<b>Observação:</b>
							{description}
						</span>
					</div>
				</div>
			</section>
		)
	}

	return (
		<section className="transfers-details-finished-content">
			{clientContent()}
			{equipContent()}
			{transferContent()}
		</section>
	)
}

export default TransferFinished
