import { DeviceContextElement } from 'module/entryPoint/context/DeviceContext/DeviceContext'
import React, { useContext } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ViewType } from 'utils/Constants'
import AllBalanceStorageListBodyMobile from './mobile'
import AllBalanceStorageListBodyWeb from './web'

type TParams = RouteComponentProps & {
	match: {
		params: {
			orderCode: string
			branch: string
		}
	}
	billed: boolean
}

const AllBalanceStorageListBody: React.FC<TParams> = ({
	match,
	billed,
	...rest
}) => {
	const { viewType } = useContext(DeviceContextElement)
	return viewType !== ViewType.MOBILE ? (
		<AllBalanceStorageListBodyWeb match={match} {...rest} billed={billed} />
	) : (
		<AllBalanceStorageListBodyMobile match={match} {...rest} billed={billed} />
	)
}

export default AllBalanceStorageListBody
