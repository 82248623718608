import { combineReducers } from 'redux'

import adminSystem from './adminSystem/reducer'
import auth from './auth/reducer'
import baseLists from './baseLists/reducer'
import humanResources from './humanResources/reducer'
import orderManagement from './orderManagement/reducer'
import serviceOrders from './serviceOrders/reducer'
import techs from './technician/reducer'
import user from './user/reducer'

export default combineReducers({
	adminSystem,
	auth,
	baseLists,
	humanResources,
	orderManagement,
	serviceOrders,
	techs,
	user
})
