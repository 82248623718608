import { FilterData } from 'models/FilterData'
import ListParams from 'models/ListParams'
import React, { createContext, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { getLogOverview, getLogs } from 'services'
import { store } from 'store'
import { setLogsParams } from 'store/modules/adminSystem/actions'
import {
	FilterField,
	FilterValue,
	clearListParams,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'

export interface LogsContextType {
	log: any | null
	logs: any[]
	isLoading: boolean
	pageLimit: number
	totalPages: number
	currentPage: number
	onUpdateList: () => void
	filtersParams?: FilterData
	onSelectLog: (logId: number) => void
	onChangeParams: ({
		page,
		limit,
		search,
		filter
	}: {
		page?: number
		limit?: number
		search?: string
		filter?: FilterData
	}) => void
}

export const LogsContextElement = createContext<LogsContextType>({
	log: null,
	logs: [],
	isLoading: false,
	pageLimit: 10,
	totalPages: 1,
	currentPage: 0,
	onUpdateList: () => {},
	onSelectLog: () => {},
	onChangeParams: () => {}
})

const defaultParams = defaultListParams(
	store.getState().adminSystem.logs.listLogsParams,
	false
)

export default function LogsProvider(props: any) {
	const [log, setLog] = useState<any | null>(null)
	const [logs, setLogs] = useState<any[]>([])
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const listParams = useRef<ListParams>(defaultParams)

	const dispatch = useDispatch()
	useEffect(() => onRequesLogs(listParams.current), [])

	const onRequesLogs = ({
		currentPage,
		pageLimit,
		searchValue
	}: ListParams) => {
		setIsLoading(true)
		getLogs({
			limit: pageLimit,
			offset: currentPage - 1,
			search: searchValue
		})
			.then((response) => {
				const { logs, count } = response.data
				setLogs(logs)
				updateParams(Math.ceil(count / pageLimit), 'count')
			})
			.catch(() => {
				toast.error('Erro ao buscar lista de usuários')
				clearParams(true)
			})
			.finally(() => setIsLoading(false))
	}

	const onSelectLog = (logId: number) => {
		setLog(null)
		setIsLoading(true)
		getLogOverview(logId)
			.then((response) => setLog(response.data))
			.catch(() => toast.error('Erro ao buscar informações do usuário'))
			.finally(() => setIsLoading(false))
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
		dispatch(setLogsParams(listParams.current))
	}

	const clearParams = (clearAll = false) => {
		listParams.current = clearListParams(listParams.current, clearAll)
		dispatch(setLogsParams(listParams.current))
	}

	const onChangeParams = (newParams: {
		page?: number
		limit?: number
		search?: string
		filter?: FilterData
	}) => {
		updateParams(newParams.search || '', 'search')
		newParams.page && updateParams(newParams.page, 'page')
		newParams.limit && updateParams(newParams.limit, 'limit')
		newParams.filter && updateParams(newParams.filter, 'filter')

		onRequesLogs({ ...listParams.current, ...newParams })
	}

	const providerValues = () => ({
		log,
		logs,
		isLoading,
		onSelectLog,
		onChangeParams,
		...listParams.current,
		onUpdateList: () => onRequesLogs(listParams.current)
	})

	return (
		<LogsContextElement.Provider value={providerValues()}>
			{props.children}
		</LogsContextElement.Provider>
	)
}
