import { TransferClient, TransferContact } from 'models/Transfer'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import FormValidator from 'module/technicalServices/components/Shared/Forms/FormValidator'
import React from 'react'
import { useForm } from 'react-hook-form'

interface Props {
	client?: TransferClient
	onSelectContact: (client: any) => void
}

const TransferContactStep: React.FC<Props> = ({ client, onSelectContact }) => {
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<TransferContact>()

	const formContent = () => {
		return (
			<form
				className="main-form-content"
				onSubmit={handleSubmit(onSelectContact)}
			>
				<div className="transfer-contact-form">
					<div className="form-item-row">
						<div className="form-item-content">
							<label>Contato</label>
							<input
								placeholder="Nome para contato"
								defaultValue={client?.contact}
								{...register('name', { required: true, maxLength: 50 })}
							/>
							<FormValidator {...{ errors, formName: 'name' }} />
						</div>
						<div className="form-item-content">
							<label>Telefone</label>
							<input
								placeholder="Telefone para contato"
								defaultValue={client?.phone}
								{...register('phone', { required: true, maxLength: 14 })}
							/>
							<FormValidator {...{ errors, formName: 'phone' }} />
						</div>
					</div>
					<div className="form-item-content">
						<label>E-mail</label>
						<input
							placeholder="E-mail para contato"
							defaultValue={client?.email}
							{...register('email', { required: true, maxLength: 250 })}
						/>
						<FormValidator {...{ errors, formName: 'email' }} />
					</div>
					<CustomButtom type="submit" label="Avançar" context="primary" />
				</div>
			</form>
		)
	}

	return (
		<div className="transfer-contact-content">
			<div className="transfer-equip-header">
				<h4>Contato</h4>
				<p>Confira as informações de contato para tranferência.</p>
			</div>
			{formContent()}
		</div>
	)
}

export default TransferContactStep
