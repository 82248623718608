import React from 'react'
import Button from 'shared/components/Button'
import { NewModal } from '../../_newModal'
import './styles.scss'

type Props = {
	onConfirm?: () => void
	onReturn: () => void
	requester: 'search' | 'install'
}

export const ModalNumSerieNotFound = ({
	onConfirm,
	onReturn,
	requester
}: Props) => {
	return (
		<NewModal onClose={onReturn} awayClose={true}>
			<div className="modal-not-found-numserie">
				<div className="title-modal-no-numserie">
					<p>Atenção</p>
					<div>
						Desculpe, não encontramos o número de série inserido. Gostaria de
						tentar inserir um novo ou pesquisar pelo seu endereço?
					</div>
				</div>

				<div className="container-address-confirm-actions">
					{requester === 'search' && (
						<Button
							size="lg"
							variant="outline"
							type="button"
							title="Usar meu endereço"
							onClick={onConfirm}
						/>
					)}
					<Button
						size="lg"
						variant="confirmation-solid"
						type="button"
						title="Tente novamente"
						onClick={onReturn}
					/>
				</div>
			</div>
		</NewModal>
	)
}
