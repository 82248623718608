import Equipment from 'models/Equipment'
import { RequisitionError } from 'models/RequisitionError'

export interface EquipmentContextType {
	onRequestEquipment: (
		serialNumber: string,
		callback: (equipment?: Equipment, hasError?: RequisitionError) => void
	) => void
}

export const EquipmentContextValue = {
	onRequestEquipment: () => {}
}
