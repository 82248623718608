import { FormControl, FormGroup } from '@material-ui/core'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import RequestResponse from 'models/RequestResponse'
import { TransferUpdate } from 'models/Transfer'
import CustomButtom from 'module/technicalServices/components/Shared/Forms/CustomButton'
import CustomDatePicker from 'module/technicalServices/components/Shared/Forms/CustomDatePicker'
import FormValidator from 'module/technicalServices/components/Shared/Forms/FormValidator'
import { TransfersDetailsContextElement } from 'module/technicalServices/context/Transfers/TransfersDetailsContext/TransfersDetailsContext'
import moment from 'moment'
import React, { useContext, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ConfirmModal from 'shared/Modals/ConfirmModal'
import JoyRide from 'shared/components/JoyRide'
import { store } from 'store'
import { TransferUpdateTypes } from 'utils/Constants'
import {
	StepsTransferDetailsTransferTab,
	handleStepTitles
} from 'utils/Constants/joyrideSteps'
import ClientSelectField from './ClientSelectField'
import EquipmentSelectField from './EquipmentSelectField'
import './styles.scss'

interface Props {
	onClose: () => void
	onIsLoading: (value: boolean) => void
}

const TransferForm: React.FC<Props> = ({ onClose, onIsLoading }) => {
	const { onTransferUpdate, transferSelected } = useContext(
		TransfersDetailsContextElement
	)

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()

	const [transferUpdate, setTransferUpdate] = useState<TransferUpdate>()

	const [isClientChecked, setIsClientChecked] = useState(false)
	const [isEquipChecked, setIsEquipChecked] = useState(false)

	const equipment = transferSelected?.transferPayloadParsed.prpInfo.equipment
	const currentClient =
		transferSelected?.transferPayloadParsed.prpInfo.currentClient
	const newClient = transferSelected?.transferPayloadParsed.prpInfo.newClient

	const [saleDate, setSaleDate] = useState<MaterialUiPickersDate>(
		moment(equipment?.sale_date).toDate()
	)
	const [warrantDate, setWarrantDate] = useState<MaterialUiPickersDate>(
		moment(equipment?.date_end_warranty).toDate()
	)

	const [showConfirmAction, setShowConfirmAction] = useState<boolean>(false)
	const [tutorials, setTutorials] = useState(store.getState().user.tutorials)

	useMemo(() => {
		store.subscribe(() => setTutorials(store.getState().user.tutorials))
	}, [tutorials])

	const onSubmit = (data: any) => {
		if (validateTransfer()) {
			const payload = {
				id: transferSelected?.id,
				cpfCnpj: newClient?.cpfCnpj,
				serialNumber: equipment?.serial_number,
				description: data.description,
				saleDate: moment(saleDate).format('YYYYMMDD'),
				warrantDate: moment(warrantDate).format('YYYYMMDD'),
				userId: store.getState().user.profile,
				type: TransferUpdateTypes.TYPE_UPDATE
			} as TransferUpdate
			setTransferUpdate(payload)
			setShowConfirmAction(true)
		} else {
			toast.error('Verifique os campos de transferência!')
		}
	}

	const onShowConfirmClose = () => {
		setShowConfirmAction(false)
	}

	const onConfirmTransfer = () => {
		if (transferUpdate) {
			onIsLoading(true)
			setShowConfirmAction(false)
			onTransferUpdate(transferUpdate, onComplete)
		}
	}

	const onComplete = (request: RequestResponse) => {
		onIsLoading(false)
		if (request.reportError) {
			toast.error('Erro ao realizar transferência de equipamento')
		} else {
			toast.success('Transferência realizada com sucesso!')
			onClose()
		}
	}

	const validateTransfer = () => {
		if (!isClientChecked || !isEquipChecked || !saleDate || !warrantDate) {
			return false
		}

		return true
	}

	const onWarrantDateSelect = (value: MaterialUiPickersDate) => {
		setWarrantDate(value)
	}

	const onSaleDateSelect = (value: MaterialUiPickersDate) => {
		setSaleDate(value)
	}

	const confirmModalContent = () => {
		const equipmentMessage = <b>{equipment?.serial_number}</b>
		const newClientMessage = <b>{newClient?.nome_fantasia}</b>
		const currentClientMessage = <b>{currentClient?.nome_fantasia}</b>

		const mainMessageContent = (
			<>
				<p>
					Você está transferindo o equipamento de N/S {equipmentMessage} do
					cliente {currentClientMessage} para o cliente {newClientMessage}. Ao
					realizar essa ação, o novo proprietário será notificado!
				</p>
				<p>Deseja concluir essa transferência?</p>
			</>
		)

		return (
			<ConfirmModal
				title="TRANSFERÊNCIA"
				onCancel={onShowConfirmClose}
				onConfirm={onConfirmTransfer}
				show={showConfirmAction}
			>
				{mainMessageContent}
			</ConfirmModal>
		)
	}

	const handleConfirmContent = () => {
		return showConfirmAction ? confirmModalContent() : <></>
	}

	const formContent = () => {
		return (
			<form className="main-form-content" onSubmit={handleSubmit(onSubmit)}>
				<div className="form-row-content">
					<FormControl>
						<FormGroup>
							<ClientSelectField
								{...{
									isClientChecked,
									onClientCheckedChange: () =>
										setIsClientChecked(!isClientChecked)
								}}
							/>
							<EquipmentSelectField
								{...{
									isEquipChecked,
									onEquipCheckedChange: () => setIsEquipChecked(!isEquipChecked)
								}}
							/>
						</FormGroup>
					</FormControl>
				</div>
				<div className="form-row-content">
					<CustomDatePicker
						label="Data de Venda"
						value={saleDate}
						placeholder="DD/MM/AAAA"
						onDateSelect={onSaleDateSelect}
					/>
					<CustomDatePicker
						label="Data de Garantia"
						value={warrantDate}
						placeholder="DD/MM/AAAA"
						onDateSelect={onWarrantDateSelect}
					/>
				</div>
				<div className="form-item-content">
					<label>Observação</label>
					<textarea {...register('description', { required: true })} />
					<FormValidator {...{ errors, formName: 'description' }} />
				</div>
				<div className="transfers-info-footer">
					<CustomButtom
						type="submit"
						label="Confirmar"
						context="primary"
						disabled={
							!isClientChecked || !isEquipChecked || !saleDate || !warrantDate
						}
					/>
				</div>
			</form>
		)
	}

	return (
		<>
			<section
				id="joyride-transfercontent-form"
				className="transfers-form-content"
			>
				{formContent()}
			</section>
			{handleConfirmContent()}
			{tutorials?.includes(
				handleStepTitles(StepsTransferDetailsTransferTab)
			) && <JoyRide steps={StepsTransferDetailsTransferTab} />}
		</>
	)
}

export default TransferForm
