import closePic from 'assets/image/close.png'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import TechnicalAssists from 'models/TechnicalAssists'
import Equipments from 'module/technicalServices/components/Equipments'
import FileModal from 'module/technicalServices/components/FileModal'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import Moment from 'react-moment'
import { toast } from 'react-toastify'
import { getBase64, registerAuthorization, uploadFile } from 'services/index'
import UploadComponent from 'shared/UploadComponent'
import Button from 'shared/components/Button'
import LoadingProgress from 'shared/components/LoadingProgress'
import Modal from 'shared/components/Modal'
import { store } from 'store'
import {
	AddAuth,
	Characters,
	DateCreation,
	Footer,
	Form,
	Header,
	HeaderTechAssist,
	ImageFile,
	InputArea,
	LabelName,
	Message,
	ObservationAuth,
	RequestInfo,
	TableContent,
	TableTechAssist
} from './styles'
import './styles.scss'

interface Props {
	equipment?: Equipment
	client?: Client
	serviceOrderId: number
	authTechAssists: TechnicalAssists[]
	handleChanges: (type: any, changes: any, worked: boolean) => void
	handleCancel: () => void
}

const AuthOs: React.FC<Props> = ({
	client,
	equipment,
	serviceOrderId,
	authTechAssists,
	handleChanges,
	handleCancel
}) => {
	const [files, setFiles] = useState<any[]>([[]])
	const [text, setText] = useState<string>('')
	const [msg, setMsg] = useState<number>(500)
	const [base64, setBase64] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isLoadingUpdateAttachment, setIsLoadingUpdateAttachment] =
		useState<boolean>(true)
	const [addAuth, setAddAuth] = useState<boolean>(false)
	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()
	const user = store.getState().user
	const [uploadedFiles, setUploadedFiles] = useState<any[]>([])

	moment.tz.setDefault('UTC')

	useEffect(() => {
		return () => {
			uploadedFiles.forEach((file) => URL.revokeObjectURL(file.preview))
		}
	}, [uploadedFiles])

	const uploaded = (files: any[]) => {
		setUploadedFiles(files.filter((file) => file.error !== true))
	}

	const onSubmit = (info: FieldValues) => {
		setIsLoading(true)
		const technicalAssistanceFile = {
			message: text,
			files: files
		}

		const uploadFiles = uploadedFiles.filter((file) => file.error !== true)

		const techAssist = {
			serviceOrderId,
			technicalAssistanceFile,
			user,
			uploadFiles
		}

		registerAuthorization(techAssist)
			.then(() => {
				setIsLoading(false)
				setAddAuth(false)
				toast.success('Autorização adicionada com sucesso.')
				onClose()
				setTimeout(() => {
					window.location.reload()
				}, 600)
			})
			.catch(() => {
				setIsLoading(false)
				toast.error('Erro ao adicionar autorização.')
			})
	}

	const countMsg = (e: any) => {
		if (text?.length < 500) {
			setText(e.target.value.substring(0, 500))
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setMsg(500 - (text?.length + 1))
			} else {
				setMsg(500 - (text?.length - 1))
			}
		} else {
			if (
				e.nativeEvent.inputType == 'insertText' ||
				e.nativeEvent.inputType == 'insertFromPaste'
			) {
				setMsg(0)
			} else {
				setText(e.target.value.substring(0, 500))
				setMsg(500 - (text?.length + 1))
			}
		}
	}

	const handleCancelUpload = () => {
		uploadedFiles.map((file, index) => {
			if (index >= 0) {
				uploadFile.delete(`upload/${file.id}`)
			}
		})
	}

	const onClose = () => {
		$('input:text, textarea').val('')
		$(`#fileList`).html('')
		$(`.newAuth`).toggle()
		$('.actionOpt').css('display', 'none')
		handleCancel()
	}

	const uploadFileContain = () => {
		return (
			<div style={{ overflow: 'auto' }} className={`modal image_file`}>
				{isLoadingUpdateAttachment ? (
					<div>
						<LoadingProgress show={isLoadingUpdateAttachment} />
					</div>
				) : (
					<>
						<FileModal
							base64={base64}
							id_img={undefined}
							id_tech_assist={undefined}
						/>
						<button
							style={{
								backgroundColor: 'rgba(0,0,0,0)',
								position: 'fixed',
								top: '18px',
								right: '250px'
							}}
							onClick={() => {
								$('body').removeClass('modal-open')
								setIsLoadingUpdateAttachment(true)
								$(`.image_file`).toggle()
							}}
						>
							<img src={closePic}></img>
						</button>
					</>
				)}
			</div>
		)
	}

	const addAuthMainContain = () => {
		return addAuth ? (
			<form style={{ position: 'relative' }}>
				<RequestInfo>
					<Form>
						<Message>
							<LabelName>Mensagem</LabelName>
							<InputArea
								{...register('message', { required: true })}
								onChange={(e) => {
									countMsg(e)
								}}
								value={text}
							/>
							<small>
								{errors.message && (
									<span style={{ color: 'red', display: 'flex' }}>
										O campo mensagem é obrigatório
									</span>
								)}
							</small>
						</Message>
						<Characters>{msg} caracteres restantes</Characters>
						<UploadComponent
							onUploaded={uploaded}
							keyUpload={`${serviceOrderId}`}
						/>
					</Form>
				</RequestInfo>
			</form>
		) : (
			<>
				<AddAuth className={`addBtn${client?.cpfCnpj}`}>
					<a onClick={() => setAddAuth(true)}>Adicionar autorização</a>
				</AddAuth>

				<TableTechAssist className={`techs${client?.cpfCnpj}`}>
					<HeaderTechAssist>
						<ObservationAuth>Observação</ObservationAuth>
						<DateCreation>Data</DateCreation>
						<ImageFile>Anexo</ImageFile>
					</HeaderTechAssist>

					<TableContent>
						{authTechAssists &&
							authTechAssists.map((tech, index) => (
								<div
									style={{ flexDirection: 'row', display: 'flex' }}
									key={index}
								>
									<ObservationAuth>{tech.message} </ObservationAuth>
									<DateCreation>
										<Moment format="DD/MM/YYYY">{tech.created_at}</Moment>
									</DateCreation>
									<ImageFile>
										<div>
											{!!tech.tech_assistance_files && (
												<>
													{tech.tech_assistance_files?.map((file, index) => (
														<>
															<button
																id={file.id.toString()}
																style={{
																	border: 'none',
																	padding: '0px',
																	backgroundColor: 'rgba(0,0,0,0)',
																	color: 'hsla(215.05, 62.91%, 49.93%, 1)',
																	marginRight: '10px'
																}}
																onClick={() => {
																	$('body').addClass('modal-open')
																	getBase64(file.id).then((response) => {
																		const { data } = response
																		const base64Str = data[0].img_base64
																		setBase64(base64Str)
																		setIsLoadingUpdateAttachment(false)
																	})
																	$(`.image_file`).toggle()
																}}
															>
																{file.name}
															</button>
														</>
													))}
												</>
											)}
											{!!tech.files && (
												<div>
													{tech.files.map((file, index) => (
														<>
															<div>
																<a
																	style={{
																		border: 'none',
																		padding: '0px',
																		backgroundColor: 'rgba(0,0,0,0)',
																		color: 'hsla(215.05, 62.91%, 49.93%, 1)',
																		marginRight: '10px'
																	}}
																	href={file.link}
																	target="_blank"
																	rel="noreferrer"
																>
																	{file.name}
																</a>
															</div>
														</>
													))}
												</div>
											)}
										</div>
									</ImageFile>
								</div>
							))}
					</TableContent>
				</TableTechAssist>
			</>
		)
	}

	const addAuthFooterContain = () => {
		return addAuth ? (
			<>
				<Button
					isLoading={isLoading}
					style={{ width: '120px' }}
					variant="confirmation-gradient"
					size="lg"
					title="Salvar"
					onClick={handleSubmit(onSubmit)}
				/>
				<Button
					isLoading={isLoading}
					style={{ width: '120px' }}
					variant="cancellation-gradient"
					size="lg"
					title="Cancelar"
					onClick={() => {
						setAddAuth(false)
						handleCancelUpload()
					}}
				/>
			</>
		) : (
			<Button
				style={{ width: '120px' }}
				variant="cancellation-gradient"
				size="lg"
				title="Fechar"
				onClick={() => {
					handleCancelUpload()
					onClose()
				}}
			/>
		)
	}

	return (
		<Modal
			show={true}
			onClose={() => {
				handleCancelUpload()
				onClose()
			}}
			title="Autorizações"
			showClose={true}
		>
			<div className="service-order-os-authorization">
				<Header>
					<Equipments client={client} equipment={equipment} />
				</Header>
				{addAuthMainContain()}
				<Footer>{addAuthFooterContain()}</Footer>
				{uploadFileContain()}
			</div>
			<LoadingProgress show={isLoading} text="Carregando" />
		</Modal>
	)
}

export default AuthOs
