import React, { ReactNode, createContext, useEffect, useRef } from 'react'
import { updateUserToken } from 'services'
import { apiSocket } from 'services/webSocket'
import { store } from 'store'

export const AuthContext = createContext({
	user: {} as any
})

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const userRef = useRef(store.getState().user)

	useEffect(() => {
		const auth = store.getState().auth
		if (auth.token) {
			navigator.serviceWorker
				.register('/service-worker.js')
				.then(async (serviceWorker) => {
					let subscription = await serviceWorker.pushManager.getSubscription()

					if (!subscription) {
						const publicKeyResponse = await apiSocket.get('/push/public-key')

						subscription = await serviceWorker.pushManager.subscribe({
							userVisibleOnly: true,
							applicationServerKey: publicKeyResponse.data.publicKey
						})
					}
					updateUserToken(subscription, userRef.current.profile).catch((err) =>
						console.log(err)
					)

					return Notification.requestPermission()
				})
				.then(async (permission) => {
					if (permission === 'granted') {
						console.log('Notification permission granted.')
					} else {
						console.log('Unable to get permission to notify.')
					}
				})
				.catch((err) => {
					console.log('Error during service worker registration:', err)
				})
		}
	}, [])

	const value = {
		user: userRef.current
	}

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
