import { IAccessories } from 'module/orderManagement/models/IAccessories'
import { handleAccessoriesType } from 'module/orderManagement/utils/constants'
import React from 'react'
import './styles.scss'

interface Props {
	accessoriesList?: any
}

export const ItensDetailsListAccessoriesBodyWebViewingMode = ({
	accessoriesList
}: Props) => {
	return (
		<>
			<div className="new-accessories-body-list" style={{ height: '490px' }}>
				{accessoriesList.map((item: IAccessories, index: number) => {
					return (
						<div key={index} className="new-accessories-body-item">
							<div className="accessories-details-list">
								<ul className="accessories-details-list-item accessories-details-list-code">
									<label htmlFor={`${index}`}>
										<span>{item?.code}</span>
									</label>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-description">
									<label htmlFor={`${index}`}>
										<span>{item?.description}</span>
									</label>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-amount">
									<span>{item?.amount}</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-commission">
									<span>{handleAccessoriesType(item?.commission)}</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-unit-price">
									<span>
										{item?.unit_price.toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
											minimumFractionDigits: 2
										})}
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-table-price">
									<span>
										{item?.table_price.toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
											minimumFractionDigits: 2
										})}
										.
									</span>
								</ul>
								<ul className="accessories-details-list-item accessories-details-list-total-price">
									<span>
										{item?.total_price.toLocaleString('pt-br', {
											style: 'currency',
											currency: 'BRL',
											minimumFractionDigits: 2
										})}
									</span>
								</ul>
							</div>
						</div>
					)
				})}
			</div>
		</>
	)
}
