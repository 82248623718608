import React, { useEffect, useRef } from 'react'

interface PopperProps {
	children: React.ReactNode
	reference: React.RefObject<HTMLElement>
	placement: 'top' | 'bottom' | 'left' | 'right'
}

export const Popper = ({ children, reference, placement }: PopperProps) => {
	const popperRef = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const popperRect = popperRef.current!.getBoundingClientRect()
		if (!reference.current || !popperRef.current) return

		const calculatePosition = () => {
			if (!popperRect) return

			const referenceRect = reference.current!.getBoundingClientRect()
			let top = 0
			let left = 0

			switch (placement) {
				case 'top':
					top = referenceRect.top - popperRect.height
					left =
						referenceRect.left + referenceRect.width / 2 - popperRect.width / 2
					break
				case 'bottom':
					top = referenceRect.top + referenceRect.height
					left =
						referenceRect.left + referenceRect.width / 2 - popperRect.width / 2
					break
				case 'left':
					top =
						referenceRect.top + referenceRect.height / 2 - popperRect.height / 2
					left = referenceRect.left - popperRect.width
					break
				case 'right':
					top =
						referenceRect.top + referenceRect.height / 2 - popperRect.height / 2
					left = referenceRect.left + referenceRect.width
					break
				default:
					throw new Error(`Invalid placement: ${placement}`)
			}

			const viewportWidth = window.innerWidth
			const viewportHeight = window.innerHeight

			if (top < 0) top = 0
			if (left < 0) left = 0
			if (top + popperRect.height > viewportHeight)
				top = viewportHeight - popperRect.height
			if (left + popperRect.width > viewportWidth)
				left = viewportWidth - popperRect.width

			popperRef.current!.style.top = `${top}px`
			popperRef.current!.style.left = `${left}px`
		}

		calculatePosition()

		window.addEventListener('resize', calculatePosition)

		return () => {
			window.removeEventListener('resize', calculatePosition)
		}
	}, [reference, placement])

	return (
		<div
			ref={popperRef}
			className="popper-component"
			style={{
				position: 'fixed',
				zIndex: 1500
			}}
		>
			{children}
		</div>
	)
}
