import { Button } from '@material-ui/core'
import $ from 'jquery'
import Client from 'models/Client'
import Equipment from 'models/Equipment'
import Technician from 'models/Technician'
import Equipments from 'module/technicalServices/components/Equipments'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getSerialInfo, updateTech } from 'services'
import SelectTech, { ITechnicianForm } from '../NewOrder/SelectTech'
import LoadingProgress from '../Shared/Loadings/LoadingProgress'
import {
	Container,
	Description,
	Footer,
	Header,
	IdTech,
	InfoTech,
	Tech,
	TechName
} from './styles'

interface Props {
	type?: any
	equipment?: Equipment
	client?: Client
	addressOS?: string
	technician?: Technician
	state_sigla?: string
	serviceOrderNumber?: string
	appointmentDate?: any
	assist_id?: any
	handleChanges: (type: any, changes: any, worked: boolean) => void
	deviation: any
}

const ChangeTech: React.FC<Props> = ({
	client,
	equipment,
	addressOS,
	technician,
	state_sigla,
	handleChanges,
	assist_id,
	type,
	deviation
}) => {
	const [type_code, setType_Code] = useState<string>('')
	const [name, setName] = useState<string>('')
	const [id, setId] = useState<string>('')
	const [codPrp, setCodePRP] = useState<string>('')
	const [enableModal, setEnableModal] = useState<boolean>(false)
	const [loading, setLoading] = useState(false)
	const [changeTec, setChangeTec] = useState<ITechnicianForm | null>(null)
	const [inputTec, setInputTec] = useState(false)
	const [clientCode, setClientCode] = useState<string>('')
	const [prod, setProd] = useState<string>('')
	const [warranty, setWarranty] = useState<string>('')
	const [phone, setPhone] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [address, setAddress] = useState<string>('')
	const [otherAddress, setOtherAddress] = useState(false)

	useEffect(() => {
		if (technician) {
			setId(technician?.code_prp)
			setName(technician?.name)
			setPhone(technician?.phone)
		}
	}, [])

	useEffect(() => {
		if (
			!!equipment?.serial_number &&
			!!client?.cod_protheus &&
			!!deviation?.id
		) {
			const callOpenApi = () => {
				getSerialInfo(
					{
						serialNumber: equipment.serial_number,
						clientCode: client.cod_protheus,
						equipmentRecno: deviation?.id
					},
					'ADMIN_APP'
				)
					.then((response) => {
						const { data } = response

						if (data?.code === 200) {
							setClientCode(data.client?.code)
							setProd(data?.equipment?.description)
							setType_Code(data?.equipment?.type_code)
							setCodePRP(data?.equipment?.product_code)
							setWarranty(data?.equipment?.date_end_warranty)
						} else if (data?.code === 404) {
							$(`.form-1`).css('display', 'none')
							toast.error(
								'Tivemos um problema localizando o equipamento. Tente novamente mais tarde'
							)
						}
					})
					.catch((error) => {
						if (error?.message === 'Network Error') {
							$(`.form-1`).css('display', 'none')
							toast.error(
								'O serviço de busca da base instalada está indisponível no momento'
							)
						}
					})
			}

			callOpenApi()
		}
	}, [equipment?.serial_number, deviation?.id])

	const select_tech = () => {
		setLoading(true)
		setInputTec(false)
		setName('')
		setId('')
		$(`.tech-1`).css('display', 'inline')
		setEnableModal(true)
	}

	function handleToggleStatus(element: ITechnicianForm) {
		if (!element) {
			setLoading(false)
		} else {
			setName(element?.name)
			setId(element?.code_prp)
			setPhone(element?.phone)
			setEmail(element?.email)
			setAddress(element?.address)
			setChangeTec(element)
			setLoading(false)
			setOtherAddress(false)
		}
	}

	function formValidator(TechID: any) {
		if (TechID === '') {
			setInputTec(true)
			return false
		} else {
			return true
		}
	}

	function onSubmit() {
		const cID = id?.replace('ID:', '')
		if (formValidator(cID)) {
			const data = {
				code_prp: cID,
				assist_id: assist_id,
				name: name,
				phone: phone
			}
			$(`.changeTech`).toggle()
			updateTech(data)
				.then(function (response: any) {
					const changes = response?.data?.tech
					handleChanges('changeTech', changes, true)
				})
				.catch((err) => {
					console.log(err)
					toast.error('Houve um erro alterar o técnico')
				})
		}
	}

	const onCancel = () => {
		$(`.changeTech`).toggle()
		$('body').removeClass('modal-open')
	}

	const showTechListModal = () => {
		return (
			<SelectTech
				onSelectTechnician={handleToggleStatus}
				selectedTechnician={changeTec}
				onClose={setEnableModal}
				show={enableModal}
				type={type}
				type_code={type_code}
				state_sigla={state_sigla}
				currentAddress={addressOS ? addressOS : ''}
				buttonReturn={false}
			/>
		)
	}

	return (
		<>
			<Container>
				<Header>
					<div style={{ paddingLeft: '20px', paddingTop: '20px' }}>
						Alterar técnico
					</div>
					<Equipments client={client} equipment={equipment} />
				</Header>
				<div>
					<p
						style={{
							fontSize: '12px',
							textAlign: 'justify',
							padding: '5px 20px 0px 20px'
						}}
					>
						<b>Atenção: </b>Ao alterar o técnico abaixo, altere-o manualmente
						também no Field Control. Não é possível alterar um técnico autônomo
						para uma empresa e vice-versa. Caso isso seja necessário, uma nova
						OS deverá ser criada.
					</p>
				</div>

				{!loading ? (
					<Tech onClick={select_tech} style={{ cursor: 'pointer' }}>
						<InfoTech>
							<TechName>{name}</TechName>
							<IdTech>ID: {id}</IdTech>
							<Description>{phone}</Description>
						</InfoTech>
					</Tech>
				) : (
					<LoadingProgress show={loading} text="Carregando" />
				)}

				<Footer>
					<Button
						style={{ marginLeft: '20px' }}
						variant="contained"
						color="primary"
						onClick={onSubmit}
					>
						Salvar
					</Button>

					<Button color="secondary" onClick={onCancel}>
						Cancelar
					</Button>
				</Footer>
			</Container>
			{showTechListModal()}
		</>
	)
}

export default ChangeTech
