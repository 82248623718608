import React from 'react'
import { Container } from './styles'

interface Props {
	base64: string
	id_tech_assist?: number
	id_img?: number
}

const FileModal: React.FC<Props> = ({ base64, id_tech_assist, id_img }) => {
	return (
		<Container>
			<img src={base64}></img>
		</Container>
	)
}

export default FileModal
