import ListParams from 'models/ListParams'
import React, { ReactNode, createContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import {
	FilterField,
	FilterValue,
	defaultListParams,
	updateListParams
} from 'utils/ContextUtils'
import {
	GetUsersJac,
	UserJacForms,
	changeUserJac,
	getUserJac
} from '../models/users-jac'
import { createClientJacSchema } from '../schemas/client-jac-schema'
import {
	createUserJac,
	deleteUserJac,
	getListUsersJac,
	getUsersJacById,
	updateUserJac
} from '../services'

export const UsersJacContext = createContext({
	list: {} as GetUsersJac | undefined,
	isLoading: false,
	pageLimit: {} as number,
	totalPages: {} as number,
	currentPage: {} as number,
	userJac: {} as getUserJac | null,
	setUserJac: {} as React.Dispatch<getUserJac | null>,
	handleCreateUserJAC: (
		data: createClientJacSchema,
		callback: () => void
	) => {},
	handleUpdateUserJAC: (
		data: any,
		valueBoolean: {
			resetPass: boolean
			resetToken: boolean
		},
		callback: () => void
	) => {},
	handleDeleteUserJAC: (id: number, callback: () => void) => {},
	onSearchParams: (searchTerm: string) => {},
	onPageChange: (page: number) => {},
	onGetUsersJAC: () => {},
	onGetUserJAC: (user: changeUserJac) => {}
})

const defaultParams = defaultListParams()

export const UsersJacProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [usersJacList, setUsersJacList] = useState<GetUsersJac>()
	const [userJac, setUserJac] = useState<getUserJac | null>(null)

	const listParams = useRef<ListParams>(defaultParams)

	const handleGetUsersJAC = async () => {
		try {
			const { currentPage, searchValue } = { ...listParams.current }
			setIsLoading(true)

			const response = await getListUsersJac({
				limit: 20,
				offset: currentPage - 1,
				term: searchValue || ''
			})
			updateParams(Math.ceil(response.data.total / 20), 'count')
			setUsersJacList(response.data.usersJac)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
			toast.error('Erro ao buscar lista de usuários JAC!')
		}
	}

	const onSearchParams = (term: string) => {
		updateParams(1, 'page')
		updateParams(term, 'search')
		handleGetUsersJAC()
	}

	const updateParams = (value: FilterValue, field: FilterField) => {
		listParams.current = updateListParams(value, field, listParams.current)
	}

	const onPageChange = (page: number) => {
		updateParams(page, 'page')
		handleGetUsersJAC()
	}

	const onGetUserJAC = (user: changeUserJac) => {
		setIsLoading(true)
		getUsersJacById(user)
			.then((response) => {
				setUserJac(response.data.userJac)
				setIsLoading(false)
			})
			.catch(() => {
				toast.error('Erro ao buscar o usuário!')
				setIsLoading(false)
			})
	}

	const handleCreateUserJAC = (
		data: createClientJacSchema,
		callback: () => void
	) => {
		setIsLoading(true)
		createUserJac(data)
			.then(() => {
				handleGetUsersJAC()
				toast.success('Sucesso ao criar o usuário!')
				callback()
			})
			.catch(() => {
				toast.error('Erro ao atualizar ao criar o usuário!')
			})
			.finally(() => setIsLoading(false))
	}

	const handleUpdateUserJAC = (
		data: UserJacForms,
		valueBoolean: { resetPass: boolean; resetToken: boolean },
		callback: () => void
	) => {
		setIsLoading(true)
		if (valueBoolean.resetPass) data.resetPass = true
		if (valueBoolean.resetToken) data.resetToken = true
		if (!userJac) return
		if (!compareStores(data.store, userJac?.store)) {
			delete data?.store
		}
		updateUserJac(data)
			.then(() => {
				handleGetUsersJAC()
				toast.success('Sucesso ao atualizar o usuário!')
				callback()
			})
			.catch(() => {
				toast.error('Erro ao atualizar o usuário!')
			})
			.finally(() => setIsLoading(false))
	}

	const compareStores = (a?: string[], b?: string[]) => {
		let diff = false
		if (!a || !b) return diff
		a.map((row) => {
			if (!b.includes(row)) diff = true
		})

		return diff
	}

	const handleDeleteUserJAC = (id: number, callback: () => void) => {
		setIsLoading(true)
		if (!id) return
		deleteUserJac(id)
			.then(() => {
				handleGetUsersJAC()
				toast.success('Sucesso ao deletar o usuário!')
				callback()
			})
			.catch(() => {
				toast.error('Erro ao deletar o usuário!')
				callback()
			})
			.finally(() => setIsLoading(false))
	}

	const value = {
		list: usersJacList,
		...listParams.current,
		userJac,
		isLoading,
		setUserJac,
		onPageChange,
		onSearchParams,
		handleCreateUserJAC,
		handleUpdateUserJAC,
		handleDeleteUserJAC,
		onGetUsersJAC: handleGetUsersJAC,
		onGetUserJAC
	}

	return (
		<UsersJacContext.Provider value={value}>
			{children}
		</UsersJacContext.Provider>
	)
}
