import React, { ReactNode } from 'react'

interface ISearchIconProps {
	children: ReactNode
}

export const SearchIcon = ({ children }: ISearchIconProps) => (
	<div id="search-icon" style={{ width: '20px', height: '20px' }}>
		{children}
	</div>
)
